import React, { FunctionComponent, useState } from 'react';
import Modal from 'react-modal';
import { ErrorModel } from '../../api/ErrorDisplay';
import { Permission } from '../../auth/getPermissions';

import AuthorizedAction from '../common/AuthorizedAction';
import { modalStyles } from '../layout/Modal';
import EditMetricForm from './EditMetricForm';
import { Metric, useEditMetric } from './metricsApi';

interface EditMetricProps {
  metric: Metric;
  connectionId: string | undefined;
  contractedPartyId: string | undefined;
}

const EditMetric: FunctionComponent<EditMetricProps> = ({
  metric,
  connectionId,
  contractedPartyId,
}) => {
  const [editMetricIsOpen, setEditMetricIsOpen] = useState(false);
  const closeEditMetric = () => setEditMetricIsOpen(false);
  const openEditMetric = () => setEditMetricIsOpen(true);

  const { mutateAsync, error } = useEditMetric();
  const submit = async (metric: Metric): Promise<any> => {
    try {
      await mutateAsync(metric);
      closeEditMetric();
    } catch (e: any) {
      closeEditMetric();
      console.error(e);
    }
  };
  return (
    <>
      <AuthorizedAction
        onClick={() => {
          openEditMetric();
        }}
        permission={Permission.METRIC_EDIT}
      >
        Edit
      </AuthorizedAction>

      <Modal
        isOpen={editMetricIsOpen}
        onRequestClose={closeEditMetric}
        style={modalStyles}
        shouldCloseOnOverlayClick={false}
      >
        <div>
          <h2>Edit Peak Demand</h2>
          <EditMetricForm
            submitFunction={submit}
            close={closeEditMetric}
            metric={metric}
            connectionId={connectionId}
            contractedPartyId={contractedPartyId}
            error={error as unknown as ErrorModel}
          />
        </div>
      </Modal>
    </>
  );
};

export default EditMetric;
