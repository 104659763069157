import React, { Fragment, FunctionComponent, useState } from 'react';
import Modal from 'react-modal';
import { Link } from 'react-router-dom';
import EasiChargeGrid from './EasiChargeGrid';
import {
  buildEasiGrid,
  createRange,
  MonthlySpotDetails,
  monthlySpotFromRateList,
  MonthSpotPercentage,
  rateListFromEasiGrid,
} from './easiUtils';
import PlanActionsBar from '../PlanActionsBar';
import UpdatePlan from '../UpdatePlan';
import {
  planScopeDisplayConversion,
  planStateDisplayConversion,
  useEditChargeV2,
} from '../planApi';
import { Connection } from '../../connections/connectionsApi';
import Card from '../../layout/Card';
import { CardRow } from '../../layout/ColumnCard';
import { modalStyles } from '../../layout/Modal';
import Page, { PageHeader } from '../../layout/Page';
import { getDefaultQualifiers } from '../../qualifiers/qualifiersApi';
import { FieldOption, formatDateForDisplay } from '../../../util/helper-func';
import caretRight from '../../../icons/caret-right.svg';

import '../../common/Tabs.scss';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useListData } from 'list-data/ListDataContext';
import { Charge, Plan, PlanUsage, QualifierLink } from '../../../api/openapi/rating-config';
import getConfig from '../../../config/getConfig';

interface EasiDetailsProps {
  connection?: Connection;
  plan: Plan;
  usage: PlanUsage | undefined;
  supplyPeriodId?: string;
  contractedPartyId?: string;
}

const EasiPlanDetails: FunctionComponent<EasiDetailsProps> = ({
  connection,
  plan,
  usage,
  supplyPeriodId,
  contractedPartyId,
}) => {
  const {
    fbau370AddTimezoneParameterToPlanDefinition, // LD client key for fbau-370-add-timezone-parameter-to-plan-definition
    oci710PlanJurisdictions, // LD client key for oci-710-plan-jurisdictions
  } = useFlags();
  const { shareablePlans } = getConfig();

  const [editPlanIsOpen, setEditPlanIsOpen] = useState(false);

  const closeEditPlan = () => setEditPlanIsOpen(false);

  const { charges, planState } = plan;
  const initialCharge = charges
    ? charges?.find((c) => c.flowDirection === 'INTO') ?? charges[0]
    : ({} as Charge);

  const [charge, setCharge] = useState<Charge>(initialCharge);
  const schedule = charge.schedules?.[0];

  const range = createRange(plan.startDate, plan.endDate);

  const { workingDayQualifier, nonWorkingDayQualifier } = getDefaultQualifiers();

  const workingDayRates = schedule?.rates?.filter((r) =>
    r.rateQualifiers?.some((qualifier) => qualifier.qualifierId === workingDayQualifier.qualifierId)
  );

  const workingDayMonthlySpot: MonthlySpotDetails = monthlySpotFromRateList(workingDayRates, range);

  const nonWorkingDayRates = schedule?.rates?.filter((r) =>
    r.rateQualifiers?.some(
      (qualifier) => qualifier.qualifierId === nonWorkingDayQualifier.qualifierId
    )
  );

  const nonWorkingDayMonthlySpot: MonthlySpotDetails = monthlySpotFromRateList(
    nonWorkingDayRates,
    range
  );

  const [marketfunction] = useListData(['MARKET_FUNCTION']);
  const isConnectionSpecificPlan = plan?.planScope === 'CONNECTION_SPECIFIC';
  const jurisdictions = marketfunction?.data[
    'urn:flux:rating-config:market:jurisdictions'
  ] as FieldOption[];

  const { mutateAsync, isLoading: updatingCharge } = useEditChargeV2();

  const saveGrid = async (
    gridToSave: string[][],
    qualifier: QualifierLink,
    monthlySpotRates: MonthSpotPercentage,
    adjustmentToUse: Array<string>
  ): Promise<object> => {
    const otherGridRates =
      qualifier.qualifierId === nonWorkingDayQualifier.qualifierId
        ? workingDayRates
        : nonWorkingDayRates;

    return mutateAsync({
      charge: {
        ...charge,
        adjustmentToUse,
        schedules: [
          {
            ...schedule,
            rates: [
              ...(otherGridRates ? otherGridRates : []),
              ...rateListFromEasiGrid(
                gridToSave,
                qualifier,
                monthlySpotRates,
                range,
                plan.structure
              ),
            ],
          },
        ],
      },
      planId: plan.id,
      chargeId: charge.id,
    } as any);
  };

  const resolveJurisdiction = (label?: string) => {
    return jurisdictions?.find((it) => it.label === label)?.label || '';
  };
  const jurisdiction = resolveJurisdiction(plan.jurisdiction);

  return (
    <>
      <PageHeader
        isSticky={true}
        showBack={!connection}
        title={
          connection
            ? () => (
                <>
                  <Link
                    className="page__title-link"
                    to={`/connections/${connection?.id}/supply-periods/${supplyPeriodId}/contracted-parties/${contractedPartyId}`}
                  >
                    {connection?.connectionId}
                  </Link>
                  <img src={caretRight} alt="" />
                  {plan.planName}
                </>
              )
            : plan.planName
        }
      >
        <PlanActionsBar
          editId="edit-easi-plan"
          editTestId="edit-easi-plan"
          hideAddCharge={true}
          onEditPlan={() => setEditPlanIsOpen(true)}
          plan={plan}
          usage={usage}
        />
      </PageHeader>
      <Page>
        <Card className="apl-display-flex apl-flex-row">
          <div style={{ width: '90%' }}>
            <div className="half_xl">
              <CardRow
                id="easi-provider"
                rowData={{ label: 'Provider', value: plan.providerName || '' }}
              />
              <CardRow
                id="easi-provider-reference"
                rowData={{ label: 'Provider reference', value: plan.providerReference || '' }}
              />
              {oci710PlanJurisdictions && jurisdiction && (
                <CardRow
                  id="easi-jurisdiction"
                  rowData={{
                    label: 'Jurisdiction',
                    value: jurisdiction,
                  }}
                />
              )}
              <CardRow
                id="easi-start-date"
                rowData={{ label: 'Start date', value: formatDateForDisplay(plan.startDate) }}
              />
              <CardRow
                id="easi-end-date"
                rowData={{ label: 'End date', value: formatDateForDisplay(plan.endDate) }}
              />
              <CardRow
                id="easi-plan-scope"
                rowData={{
                  label: 'Plan scope',
                  value: planScopeDisplayConversion(plan?.planScope),
                }}
              />
              <CardRow
                id="easi-plan-state"
                rowData={{
                  label: 'Plan status',
                  value: planStateDisplayConversion(plan?.planState),
                }}
              />
              {fbau370AddTimezoneParameterToPlanDefinition && (
                <CardRow
                  id="easi-plan-timezone"
                  rowData={{ label: 'Timezone', value: plan.timezone || '' }}
                />
              )}
              {shareablePlans && isConnectionSpecificPlan && (
                <CardRow
                  id="easi-plan-shareable"
                  rowData={{
                    label: 'Shareable',
                    value: (plan?.shareable === true ? 'Yes' : 'No') || 'Yes',
                  }}
                />
              )}
            </div>
          </div>
          <Modal
            isOpen={editPlanIsOpen}
            onRequestClose={closeEditPlan}
            shouldCloseOnOverlayClick={false}
            style={modalStyles}
          >
            <UpdatePlan close={closeEditPlan} planId={plan.id} plan={plan} />
          </Modal>
        </Card>
        {plan?.endDate && (
          <Card className="apl-pb-none">
            <nav className="tabs card__inner">
              <ul className="tabs__list">
                {charges &&
                  charges
                    .sort((a, b) => {
                      const aFlow = a?.flowDirection?.toLowerCase() ?? '';
                      const bFlow = b?.flowDirection?.toLowerCase() ?? '';

                      return aFlow < bFlow ? -1 : aFlow > bFlow ? 1 : 0;
                    })
                    .map((c) => (
                      <li className="tabs__list-item" key={`tab-${c.flowDirection}`}>
                        <a
                          className={
                            charge.id === c.id
                              ? 'tabs__list-link tabs__list-link--active'
                              : 'tabs__list-link'
                          }
                          onClick={() => setCharge(c)}
                        >
                          {c.flowDirection === 'INTO' ? 'Into connection' : 'Out of connection'}
                        </a>
                      </li>
                    ))}
              </ul>
            </nav>
            {charges?.map((c) => {
              if (charge.id !== c.id) {
                return null;
              }

              return (
                <Fragment key={`${c.id}-${c.flowDirection}`}>
                  <EasiChargeGrid
                    charge={c}
                    disableEdit={planState === 'ARCHIVED'}
                    disableSave={updatingCharge}
                    easiGrid={buildEasiGrid(workingDayRates, range, plan.structure)}
                    monthlySpotDetails={workingDayMonthlySpot}
                    qualifier={workingDayQualifier}
                    range={range}
                    saveFn={saveGrid}
                    structure={plan.structure}
                    title="Working days"
                  />
                  <EasiChargeGrid
                    charge={c}
                    disableEdit={planState === 'ARCHIVED'}
                    disableSave={updatingCharge}
                    easiGrid={buildEasiGrid(nonWorkingDayRates, range, plan.structure)}
                    monthlySpotDetails={nonWorkingDayMonthlySpot}
                    qualifier={nonWorkingDayQualifier}
                    range={range}
                    saveFn={saveGrid}
                    structure={plan.structure}
                    title="Non working days"
                  />
                </Fragment>
              );
            })}
          </Card>
        )}
      </Page>
    </>
  );
};

export default EasiPlanDetails;
