/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PublishedErrorList } from '../models/PublishedErrorList';
import type { UrnFilterType } from '../models/UrnFilterType';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ErrorsService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Returns a paginated list of application errors
     * @param urns A list of urns for which Errors should be included or excluded, according to the urnFilterType parameter.
     * @param urnFilterType Indicates whether Errors with urns matching the urns parameter should be included or excluded. Defaults to INCLUDE.
     * @param limit The maximum number of Errors to return. Defaults to 1000.
     * @param offset Result index to start the list from. Defaults to 0.
     * @param sortBy String of comma-separated sort commands to sort the Errors by.
     * Each command can contain a sort column and a sort direction, separated by ".".
     * E.g. "name.ASC'
     *
     * @param fromDate Include Errors with a _publishedAt date of after or including the supplied date
     * @param toDate Include Errors with a _publishedAt date of before or including the supplied date
     * @param showSystemErrors config
     * @returns PublishedErrorList A paginated list of all application errors
     * @throws ApiError
     */
    public getErrors(
        urns?: Array<string>,
        urnFilterType?: UrnFilterType,
        limit?: number,
        offset?: number,
        sortBy?: string,
        fromDate?: string,
        toDate?: string,
        showSystemErrors?: boolean,
    ): CancelablePromise<PublishedErrorList> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v2/errors',
            query: {
                'urns': urns,
                'urnFilterType': urnFilterType,
                'limit': limit,
                'offset': offset,
                'sortBy': sortBy,
                'fromDate': fromDate,
                'toDate': toDate,
                'showSystemErrors': showSystemErrors,
            },
        });
    }

}
