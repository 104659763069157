/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { MeasurementPoint } from '../models/MeasurementPoint';
import type { MeasurementPoints } from '../models/MeasurementPoints';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class MeasurementPointsService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Paginated list of Measurement Points
     * @param scopedTo
     * @param externalIdentifier
     * @param usageChargeFilter
     * @param startsAt
     * @param endsAt
     * @param isSystemCreated
     * @param limit
     * @param offset
     * @returns MeasurementPoints List of Measurement Points for the given query params
     * @throws ApiError
     */
    public pageMeasurementPoints(
        scopedTo?: string,
        externalIdentifier?: string,
        usageChargeFilter?: string,
        startsAt?: string,
        endsAt?: string,
        isSystemCreated?: boolean,
        limit: number = 100,
        offset?: number,
    ): CancelablePromise<MeasurementPoints> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/measurement-points',
            query: {
                'scopedTo': scopedTo,
                'externalIdentifier': externalIdentifier,
                'usageChargeFilter': usageChargeFilter,
                'startsAt': startsAt,
                'endsAt': endsAt,
                'isSystemCreated': isSystemCreated,
                'limit': limit,
                'offset': offset,
            },
            errors: {
                400: `Bad-Request`,
                401: `Unauthorized`,
                404: `The specified resource was not found`,
                500: `Internal-Server-Error`,
            },
        });
    }

    /**
     * Creates a new Measurement Point
     * @param requestBody Details of the new Measurement Point to be created.
     * @returns MeasurementPoint Measurement Point created successfully. Returns details of the new Measurement Point.
     * @throws ApiError
     */
    public createMeasurementPoint(
        requestBody: MeasurementPoint,
    ): CancelablePromise<MeasurementPoint> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/measurement-points',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad-Request`,
                422: `Unprocessable-Entity-Error`,
            },
        });
    }

    /**
     * Retrieves a Measurement Point by ID
     * @param id Id of the Measurement Point to be retrieved
     * @returns MeasurementPoint Details of the Measurement Point
     * @throws ApiError
     */
    public getMeasurementPointById(
        id: string,
    ): CancelablePromise<MeasurementPoint> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/measurement-points/{id}',
            path: {
                'id': id,
            },
            errors: {
                404: `The specified resource was not found`,
            },
        });
    }

    /**
     * Updates an existing Measurement Point
     * @param id Id of the Measurement Point to be updated
     * @param requestBody Updated details for the Measurement Point
     * @returns MeasurementPoint Measurement Point updated successfully. Returns Measurement Point details, with the updates applied.
     * @throws ApiError
     */
    public updateMeasurementPoint(
        id: string,
        requestBody: MeasurementPoint,
    ): CancelablePromise<MeasurementPoint> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/measurement-points/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad-Request`,
                404: `The specified resource was not found`,
                422: `Unprocessable-Entity-Error`,
            },
        });
    }

    /**
     * Delete an existing MeasurementPoint
     * @param id Id of the Measurement Point
     * @returns void
     * @throws ApiError
     */
    public deleteMeasurementPoint(
        id: string,
    ): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/measurement-points/{id}',
            path: {
                'id': id,
            },
            errors: {
                404: `The specified resource was not found`,
            },
        });
    }

}
