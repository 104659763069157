import React, { ChangeEvent, useEffect, useState } from 'react';
import cx from 'classnames';
import { Toast } from '@elastic/eui/src/components/toast/global_toast_list';
import LoadingSpinner from '../../common/LoadingSpinner';
import {
  SupplyPeriodPut,
  useSupplyPeriodDetailsV3,
  useUpdateSupplyperiodV3,
} from '../connectionsApi';

interface RatingEngineFieldProps {
  id: string;
  onStatusUpdate: (data: Toast) => void;
}

const RatingEngineField = ({ id, onStatusUpdate }: RatingEngineFieldProps) => {
  const { data, isError } = useSupplyPeriodDetailsV3(id, {
    enabled: !!id,
  });

  const [selectedEngine, setSelectedEngine] = useState<string | undefined>(undefined);

  const { mutateAsync, isLoading: mutateLoading } = useUpdateSupplyperiodV3(id);

  useEffect(() => {
    if (data && data.preferredRatingEngine) {
      setSelectedEngine(data.preferredRatingEngine);
    }
  }, [data]);

  const handleRatingEngineChange = async (e: ChangeEvent<HTMLSelectElement>) => {
    const value = e.target.value as 'urn:flux:rating:engine:flux' | 'urn:flux:rating:engine:flux';
    setSelectedEngine(value);

    const body: SupplyPeriodPut = { preferredRatingEngine: value };
    try {
      const result = await mutateAsync(body);

      if (result?.preferredRatingEngine === value) {
        onStatusUpdate({
          title: 'Rating engine updated.',
          color: 'success',
          id: 'RatingEngineUpdate',
        });
      } else {
        onStatusUpdate({
          text: 'Please try again.',
          title: 'Rating engine failed to update.',
          color: 'danger',
          id: 'RatingEngineUpdate',
        });
      }
    } catch (error) {
      onStatusUpdate({
        text: 'An error occurred. Please try again.',
        title: 'Rating engine failed to update.',
        color: 'danger',
        id: 'RatingEngineUpdate',
      });
    }
  };

  return (
    <div
      className={cx(
        'apl-display-flex',
        'apl-flex-row',
        'apl-align-items-center',
        'rating-engine-field'
      )}
    >
      <div className="apl-field-v1 apl-display-flex apl-flex-column apl-align-items-start">
        <label className="apl-field__label apl-mr" htmlFor="rating-engine-field">
          Rating Engine
        </label>
        <div
          className={cx(
            'invoice-format-field__container',
            'apl-display-flex',
            'apl-flex-row',
            'apl-align-items-center'
          )}
        >
          {isError ? (
            'Not available'
          ) : (
            <select
              className="apl-select-v1_0 apl-mr-s"
              disabled={mutateLoading}
              id="rating-engine-field"
              onChange={handleRatingEngineChange}
              style={{ width: '100px' }}
              value={selectedEngine || ''}
            >
              <option value="urn:flux:rating:engine:flux">Flux</option>
              <option value="urn:flux:rating:engine:gorilla">Gorilla</option>
            </select>
          )}
          {mutateLoading && <LoadingSpinner />}
        </div>
      </div>
    </div>
  );
};

export default RatingEngineField;
