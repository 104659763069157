import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import apiClient from '../../api/apiClient';
import { handleRequest, telemetryClient } from 'api';
import { MeasurementPoint, UsageSummaryV2Response } from '../../api/openapi/telemetry';
import { findOffsetPeriods, getDiffOffset } from '../../util/helper-func';
import {
  itemsByMeasurementPoint,
  measurementsByUnit,
} from '../connections/metering/MeteringSummaryHelper';
import getConfig from '../../config/getConfig';

const baseEndpoint = '/usage';

export interface UsageResponseDetail {
  _itemType: string;
  intervalReadings: IntervalReading[];
  totalsToConnection: UsageTotals;
  totalsFromConnection: UsageTotals;
}

export interface IntervalReading {
  meter_id: string;
  stream_id: string;
  register_id: string;
  month: string;
  reading_quality: ReadingQuality;
  day_of_reading: string;
  interval_number: number;
  interval_start: string;
  interval_end: string;
  is_energy_going_to_connection: boolean;
  timezone: string;
  version: number;
  current: boolean;
  connection_id: string;
  measurements: Measurement[];
  filename: string;
}

export type ReadingQuality =
  | 'urn:flux:telemetry:usage:accuracy:usage:actual'
  | 'urn:flux:telemetry:usage:accuracy:usage:calculated'
  | 'urn:flux:telemetry:usage:accuracy:usage:forecast';

export interface Measurement {
  unit: string;
  value: string;
}

export interface UsageTotals {
  totalCount: number;
  totalValues: Measurement[];
}

interface UsageDetailsParams {
  connectionId: string | undefined;
  endsAt: string;
  isEnergyGoingToConnection?: boolean;
  limit?: number;
  meterId?: string;
  measurementPointId?: string | undefined;
  startsAt: string;
  duration?: string;
}

interface UsageDetailsParamsV2 {
  connectionId: string | undefined;
  startsAt: string;
  endsAt: string;
  limit?: number;
  meterId?: string;
  isEnergyGoingToConnection?: boolean;
  usageChargeFilter?: string | undefined;
  measurementPointId?: string | undefined;
  duration?: string;
  getForecasted?: boolean;
}

//===================== V2 Interfaces ===========================================================//

export interface UsageDetailV2Response {
  _itemType: string;
  connectionId: string;
  items: UsageDetailItem[];
  totalsToConnection: UsageTotals;
  totalsFromConnection: UsageTotals;
}

export interface UsageDetailItem {
  measurementPoint: MeasurementPointGroupInfo;
  intervalReadings: IntervalReadingV2[];
}

export interface MeasurementPointGroupInfo {
  _ref: string;
  id: string;
  meter: string;
  register: string;
  stream: string;
  isEnergyGoingToConnection: boolean;
  usageChargeFilter: string;
}

export interface IntervalReadingV2 {
  intervalNumber: number;
  intervalStarts: string;
  intervalEnds: string;
  readingQuality: string;
  measurements: Measurement[];
  filename: string;
}

// ==============================================================================================//

export const useUsageDetails = (params: UsageDetailsParams, options?: Record<string, unknown>) => {
  const { connectionId, endsAt, isEnergyGoingToConnection, limit, meterId, startsAt } = params;

  return useQuery(
    ['usage-detail', connectionId, endsAt, isEnergyGoingToConnection, limit, meterId, startsAt],
    async () => {
      const search = new URLSearchParams();

      (Object.keys(params) as Array<keyof UsageDetailsParams>).forEach((key) => {
        if (params[key] !== undefined) {
          search.set(key, `${params[key]}`);
        }
      });

      return apiClient('telemetry').get(`${baseEndpoint}/detail?${search.toString()}`);
    },
    {
      ...options,
    }
  );
};

export const useUsageDetailsV2 = (
  params: UsageDetailsParamsV2,
  options?: Record<string, unknown>
) => {
  const {
    connectionId,
    endsAt,
    isEnergyGoingToConnection,
    limit,
    measurementPointId,
    startsAt,
    getForecasted,
  } = params;

  return useQuery(
    [
      'usage-detail',
      connectionId,
      endsAt,
      isEnergyGoingToConnection,
      limit,
      measurementPointId,
      startsAt,
      getForecasted,
    ],
    async () => {
      const search = new URLSearchParams();

      (Object.keys(params) as Array<keyof UsageDetailsParams>).forEach((key) => {
        if (params[key] !== undefined) {
          search.set(key, `${params[key]}`);
        }
      });

      return apiClient('telemetry').get(`/v2${baseEndpoint}/detail?${search.toString()}`);
    },
    {
      ...options,
    }
  );
};

export interface UsageResponseSummary {
  _itemType: string;
  summaries: UsageSummary[];
}

export interface UsageSummary {
  isEnergyGoingToConnection: boolean;
  summaries: UsageSummaryRow[];
  totals: UsageTotals;
  numberOfCompleteSummaries: number;
}

export interface UsageSummaryRow {
  summaryPeriod: string;
  summaryValues: Measurement[];
  numberOfReadingsContributed: number;
}

interface UsageSummaryParams {
  connectionId: string | undefined;
  endsAt: string;
  isEnergyGoingToConnection?: boolean;
  limit: number;
  meterId?: string;
  startsAt: string;
}

export const useUsageSummary = (params: UsageSummaryParams, options?: Record<string, unknown>) => {
  const { connectionId, endsAt, isEnergyGoingToConnection, limit, meterId, startsAt } = params;

  return useQuery(
    ['usage-summary', connectionId, endsAt, isEnergyGoingToConnection, limit, meterId, startsAt],
    async () => {
      const search = new URLSearchParams();

      (Object.keys(params) as Array<keyof UsageSummaryParams>).forEach((key) => {
        if (params[key] !== undefined) {
          search.set(key, `${params[key]}`);
        }
      });

      return apiClient('telemetry').get(`${baseEndpoint}/summary?${search.toString()}`);
    },
    {
      ...options,
    }
  );
};

/**
 * Usage Summary V2
 * This method will call usageSummaryV2 API
 * - in case dayLightSaving = true on the config file, it is required to do 3 calls to usage summary
 * ir order to get the monthly data..
 *    1 - for the initial offset (24h)
 *    2 - for the offset changing day ( 23h / 25h ) depending on weather the month enters or leaves daylight saving
 *    3 - for the final offset (24h)
 *
 * - in case dayLightSaving = false the method only query once usageSummaryV2 API for intervals of
 * 24h summaries (as daylight saving is ignored)
 *
 * Not every country adopt daylight savings, and for those who do, not every company (file importer)
 * will
 *
 * @param params
 * @param options
 */
export const useUsageSummaryV2 = (
  params: UsageDetailsParams,
  options?: Record<string, unknown>
) => {
  const {
    connectionId,
    endsAt,
    isEnergyGoingToConnection,
    limit,
    measurementPointId,
    startsAt,
    duration,
  } = params;

  return useQuery(
    [
      'usage-summary',
      connectionId,
      endsAt,
      isEnergyGoingToConnection,
      limit,
      measurementPointId,
      startsAt,
      duration,
    ],

    async () => {
      const { dayLightSaving } = getConfig();
      const daylightSavingQueries = dayLightSaving && getDiffOffset(startsAt, endsAt) != 0;

      if (daylightSavingQueries) {
        const response = new Array<UsageSummaryV2Response>();
        const offsetPeriods = findOffsetPeriods(startsAt, endsAt);

        for (const period of offsetPeriods) {
          params.endsAt = period.endDate;
          params.startsAt = period.startDate;
          params.duration = `${24 + period.getOffsetDiff()}h`;

          const usageSummaryResponse = await callUseUsageSummaryV2(params);
          response.push(usageSummaryResponse);
        }

        // Because we may call the API more than once, due to daylight savings we need to turn all responses into one
        const reducedResponse: UsageSummaryV2Response = response.reduce(
          (merge: UsageSummaryV2Response, current: UsageSummaryV2Response) => {
            merge._itemType = current._itemType;
            merge.totalsToConnection.totalCount += current.totalsToConnection.totalCount;
            merge.totalsToConnection.totalValues = measurementsByUnit([
              ...merge.totalsToConnection.totalValues,
              ...current.totalsToConnection.totalValues,
            ]);

            merge.totalsFromConnection.totalCount += current.totalsFromConnection.totalCount;
            merge.totalsFromConnection.totalValues = measurementsByUnit([
              ...merge.totalsFromConnection.totalValues,
              ...current.totalsFromConnection.totalValues,
            ]);

            merge.numberOfCompleteSummaries += current.numberOfCompleteSummaries;
            merge.items = itemsByMeasurementPoint([...merge.items, ...current.items]);
            return merge;
          }
        );

        return reducedResponse;
      } else {
        return callUseUsageSummaryV2(params);
      }
    },
    {
      ...options,
    }
  );
};

const callUseUsageSummaryV2 = (params: any) => {
  // Generate url with query parameters
  const search = new URLSearchParams();

  (Object.keys(params) as Array<keyof UsageDetailsParams>).forEach((key) => {
    if (params[key] !== undefined) {
      search.set(key, `${params[key]}`);
    }
  });

  return apiClient('telemetry').get(`/v2${baseEndpoint}/summary?${search.toString()}`);
};

export interface UsageMeters {
  _itemType: string;
  meterIds: string[];
}

interface UsageMetersParams {
  startsAt: string;
  endsAt: string;
  connectionId: string | undefined;
}

export const useUsageMeters = (params: UsageMetersParams, options?: Record<string, unknown>) => {
  const { startsAt, endsAt, connectionId } = params;

  return useQuery(
    ['usage-meters', startsAt, endsAt, connectionId],
    async () => {
      const search = new URLSearchParams();

      (Object.keys(params) as Array<keyof UsageMetersParams>).forEach((key) => {
        if (params[key]) {
          search.set(key, `${params[key]}`);
        }
      });

      return apiClient('telemetry').get(`${baseEndpoint}/meters?${search.toString()}`);
    },
    {
      ...options,
    }
  );
};

export const useUsageMetersV2 = (params: UsageMetersParams, options?: Record<string, unknown>) => {
  const { startsAt, endsAt, connectionId } = params;

  return useQuery(
    ['usage-meters', startsAt, endsAt, connectionId],
    async () => {
      const search = new URLSearchParams();

      (Object.keys(params) as Array<keyof UsageMetersParams>).forEach((key) => {
        if (params[key]) {
          search.set(key, `${params[key]}`);
        }
      });

      return apiClient('telemetry').get(`/v2${baseEndpoint}/meters?${search.toString()}`);
    },
    {
      ...options,
    }
  );
};

/**
 * Method to fetch a paginated list of measurement points for a given set of search parameters.
 *
 * @param scopedTo the connection ID the measurement point is scoped to.
 * @param externalIdentifier the URN containing identifier information like ICP/NMI and meter number.
 * @param usageChargeFilter the usage charge filter ID assigned to the measurement point.
 * @param startsAt the start date range.
 * @param endsAt the end date range.
 * @param isSystemCreated system generated flag.
 * @param limit the limit of measurement points per page.
 * @param offset the page number.
 * @returns a paginated list of measurement points for a given set of search parameters.
 */
export const useMeasurementPoints = (
  {
    scopedTo,
    externalIdentifier,
    usageChargeFilter,
    startsAt,
    endsAt,
    isSystemCreated,
    limit,
    offset,
  }: {
    scopedTo?: string;
    externalIdentifier?: string;
    usageChargeFilter?: string;
    startsAt?: string;
    endsAt?: string;
    isSystemCreated?: boolean;
    limit?: number;
    offset?: number;
  },
  options?: Record<string, unknown>
) => {
  return useQuery(
    [
      'measurement-points',
      scopedTo,
      externalIdentifier,
      usageChargeFilter,
      startsAt,
      endsAt,
      isSystemCreated,
      limit,
      offset,
    ],
    async () =>
      await handleRequest(
        telemetryClient.measurementPoints.pageMeasurementPoints(
          scopedTo,
          externalIdentifier,
          usageChargeFilter,
          startsAt,
          endsAt,
          isSystemCreated,
          limit,
          offset
        )
      ),
    {
      ...options,
    }
  );
};

/**
 * Method to create a measurement point
 *
 * @param measurementPoint to persist
 * @returns void
 */
export const useCreateMeasurementPoint = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (measurementPoint: MeasurementPoint) =>
      await handleRequest(
        telemetryClient.measurementPoints.createMeasurementPoint(measurementPoint)
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['measurement-points']);
      },
    }
  );
};

/**
 * Method to edit a measurement point
 *
 * @param measurementPoint to persist
 * @returns void
 */
export const useUpdateMeasurementPoint = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (measurementPoint: MeasurementPoint) =>
      await handleRequest(
        telemetryClient.measurementPoints.updateMeasurementPoint(
          measurementPoint.id ?? '',
          measurementPoint
        )
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['measurement-points']);
      },
    }
  );
};
