import React, { FunctionComponent, useState } from 'react';
import Modal from 'react-modal';
import { ErrorModel } from '../../../api/ErrorDisplay';
import { Permission } from '../../../auth/getPermissions';
import { formatDateForDisplay } from '../../../util/helper-func';
import AuthorizedAction from '../../common/AuthorizedAction';
import { modalStyles } from '../../layout/Modal';
import { SupplyPeriodBody, SupplyPeriodSummary, useUpdateSupplyPeriod } from '../connectionsApi';
import SupplyPeriodForm from './SupplyPeriodForm';

interface SupplyPeriodRowProps {
  connectionId: string | undefined;
  index: number;
  supplyPeriod: SupplyPeriodSummary;
}

const SupplyPeriodRow: FunctionComponent<SupplyPeriodRowProps> = ({
  connectionId,
  supplyPeriod,
}) => {
  const [updateSupplyPeriodIsOpen, setUpdateSupplyPeriodIsOpen] = useState(false);
  const closeUpdateSupplyPeriod = () => setUpdateSupplyPeriodIsOpen(false);
  const openUpdateSupplyPeriod = () => setUpdateSupplyPeriodIsOpen(true);

  const {
    mutateAsync,
    isLoading: isSubmitting,
    error,
  } = useUpdateSupplyPeriod(connectionId, supplyPeriod.id, {
    enabled: connectionId && !!supplyPeriod?.id,
  });

  const updateSupplyPeriod = async (
    contractedParty: string,
    startDate: string,
    endDate: string
  ) => {
    const newSupplyPeriod: SupplyPeriodBody = {
      contractedParty,
      startDate,
      endDate,
    } as SupplyPeriodBody;

    const result = await mutateAsync(newSupplyPeriod);

    if (result) {
      closeUpdateSupplyPeriod();
    }
  };

  return (
    <tr>
      <td>
        {formatDateForDisplay(supplyPeriod.startDate)}
        {' - '}
        {formatDateForDisplay(supplyPeriod.endDate)}
      </td>
      <td>{supplyPeriod.contractedParty}</td>
      <td>{supplyPeriod.plansCount}</td>
      <td>{supplyPeriod.status}</td>
      <td>
        <AuthorizedAction
          onClick={openUpdateSupplyPeriod}
          permission={Permission.CONNECTION_EDIT}
          testId="update-supply-period"
        >
          Edit dates
        </AuthorizedAction>
      </td>
      <Modal
        isOpen={updateSupplyPeriodIsOpen}
        onRequestClose={closeUpdateSupplyPeriod}
        shouldCloseOnOverlayClick={false}
        style={modalStyles}
      >
        <SupplyPeriodForm
          close={closeUpdateSupplyPeriod}
          error={error as ErrorModel}
          existingSupplyPeriod={supplyPeriod}
          isUpdating={isSubmitting}
          submitFunction={updateSupplyPeriod}
          title="Edit supply dates"
        />
      </Modal>
    </tr>
  );
};

export default SupplyPeriodRow;
