import React, { FunctionComponent, useMemo } from 'react';
import ErrorDisplay, { ErrorModel } from '../../../api/ErrorDisplay';
import {
  EquationLink,
  LinkConnectionsRequest,
  useEquations,
} from '../../telemetry-calculator/teleCalcApi';
import moment from 'moment/moment';
import getConfig from '../../../config/getConfig';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import Alert, { AlertEnum } from '../../layout/Alert';
import { FormRow } from '../../form';
import FieldWrapper, { FieldWidth, selectClassNameBuilder } from '../../form/FieldWrapper';
import { ErrorText } from '../../layout/Errors';
import FluxDatePicker from '../../form/DatePicker';
import AuthorizedAction from '../../common/AuthorizedAction';
import { Permission } from '../../../auth/getPermissions';
import { isNil } from 'ramda';
import { generateTimeIntervals } from '../../../util/helper-func';
import { date, object, string } from 'yup';
import { isBefore, isDate } from 'date-fns';
import { LoadingPage } from '../../layout/Page';

interface EquationLinkFormProps {
  id: string;
  connectionId: string;
  close: () => void;
  error?: ErrorModel;
  isUpdating: boolean;
  equationLink?: EquationLink;
  submitFunction: (linkConnectionsRequest: LinkConnectionsRequest) => Promise<any>;
}

const EquationLinkForm: FunctionComponent<EquationLinkFormProps> = ({
  id,
  close,
  error,
  isUpdating,
  equationLink,
  submitFunction,
}) => {
  const {
    data: equations,
    isInitialLoading: loadingEquations,
    error: useEquationsError,
  } = useEquations();

  const isEdit = !isNil(equationLink);
  const intervals = useMemo(() => generateTimeIntervals(30, 'HH:mm', false).concat(['24:00']), []);
  const equationLinkSchema = object().shape({
    equation: string().label('Equation').required('Equation is required'),
    startDate: date()
      .typeError('Must be a valid date')
      .label('Starts At')
      .max(new Date('9999-12-31'))
      .required('Start date is required'),
    fromTime: string().label('From time').required(),
    endDate: date()
      .nullable()
      .typeError('Must be a valid date')
      .label('Ends At')
      .max(new Date('9999-12-31'))
      .when('startDate', (startDate: Date, schema: any) => {
        if (isDate(startDate) && isBefore(startDate, new Date('9999-12-31'))) {
          return schema.min(startDate, 'End date must be after start date');
        } else {
          return schema;
        }
      }),
    toTime: string()
      .label('To time')
      .nullable()
      .when('endDate', (value, schema) => {
        return schema.required();
      }),
  });

  if (loadingEquations) {
    return <LoadingPage />;
  }
  return (
    <>
      <Formik
        initialValues={{
          equation: '',
          startDate: '',
          endDate: '',
          fromTime: intervals.slice().shift(),
          toTime: intervals.slice().pop(),
        }}
        validationSchema={equationLinkSchema}
        onSubmit={({ equation, startDate, endDate, fromTime, toTime }) => {
          const startsAt =
            startDate !== ''
              ? moment.tz(`${startDate} ${fromTime}`, getConfig().marketTimezone).toISOString()
              : '';
          const endsAt =
            endDate !== ''
              ? moment.tz(`${endDate} ${toTime}`, getConfig().marketTimezone).toISOString()
              : undefined;
          return submitFunction({
            equationId: equation,
            connections: [
              {
                id,
                startsAt,
                endsAt,
              },
            ],
          });
        }}
      >
        {({ dirty, isValid, errors, touched }) => (
          <Form className="apl-form-layout-v1">
            <>
              {error && (
                <Alert type={AlertEnum.DANGER}>
                  {isEdit ? 'Could not update equation link' : 'Could not link to equation'}
                </Alert>
              )}
              {useEquationsError && (
                <Alert type={AlertEnum.DANGER}>{'Unable to load equations'}</Alert>
              )}
            </>
            <FormRow>
              <FieldWrapper
                fieldWidth={FieldWidth.HALF}
                htmlFor="equation-name"
                label="Equation Name"
              >
                <Field
                  autoComplete="off"
                  data-testid="equation"
                  name="equation"
                  id="equation"
                  className={selectClassNameBuilder('equations', errors, touched)}
                  as="select"
                >
                  <option value="" />
                  {equations?.equations?.map((equation) => {
                    return (
                      <option
                        key={equation.id}
                        value={equation.id}
                        data-testid={`equation-${equation.id}`}
                      >
                        {`${equation.name} ${equation.expression}`}
                      </option>
                    );
                  })}
                </Field>
                <ErrorMessage component={ErrorText} name="equation" />
              </FieldWrapper>
            </FormRow>
            <FormRow>
              <FieldWrapper fieldWidth={FieldWidth.HALF} htmlFor="start-date" label="Starts at">
                <Field
                  autoComplete="off"
                  data-testid="start-date"
                  name="startDate"
                  id="start-date"
                  component={FluxDatePicker}
                />
                <ErrorMessage component={ErrorText} name="startDate" />
              </FieldWrapper>
              <FieldWrapper fieldWidth={FieldWidth.HALF} htmlFor="end-date" label="Ends at">
                <Field
                  autoComplete="off"
                  data-testid="end-date"
                  name="endDate"
                  id="end-date"
                  component={FluxDatePicker}
                />
                <ErrorMessage component={ErrorText} name="endDate" />
              </FieldWrapper>
            </FormRow>
            <FormRow>
              <FieldWrapper htmlFor="from-time" label="From time" fieldWidth={FieldWidth.HALF}>
                <Field
                  className="apl-select-v1_0"
                  data-testid="from-time-field"
                  name="fromTime"
                  id="from-time"
                  as="select"
                >
                  {intervals.slice(0, -1).map((value) => (
                    <option key={`from-time-${value}`} value={value}>
                      {value}
                    </option>
                  ))}
                </Field>
                <ErrorMessage component={ErrorText} name="fromTime" />
              </FieldWrapper>
              <FieldWrapper htmlFor="to-time" label="To time" fieldWidth={FieldWidth.HALF}>
                <Field
                  className="apl-select-v1_0"
                  data-testid="to-time-field"
                  name="toTime"
                  id="to-time"
                  as="select"
                >
                  {intervals.slice(1).map((value) => (
                    <option key={`to-time-${value}`} value={value}>
                      {value}
                    </option>
                  ))}
                </Field>
                <ErrorMessage component={ErrorText} name="toTime" />
              </FieldWrapper>
            </FormRow>
            <div className="apl-display-flex apl-justify-content-end">
              <button
                data-testid="cancel-button"
                className="apl-button-v1"
                onClick={close}
                disabled={isUpdating}
                type="button"
              >
                Cancel
              </button>
              <AuthorizedAction
                extraClasses="is-primary"
                isDisabled={isUpdating || !isValid || !dirty}
                permission={isEdit ? Permission.METRIC_EDIT : Permission.METRIC_CREATE}
                testId="save-button"
                title={isUpdating || !isValid || !dirty ? '' : undefined}
                type="submit"
              >
                Save
              </AuthorizedAction>
            </div>
            <ErrorDisplay error={error} />
          </Form>
        )}
      </Formik>
    </>
  );
};

export default EquationLinkForm;
