import cx from 'classnames';
import React from 'react';
import './Pagination.scss';

const getRange = (start: number, end: number) => {
  return Array(end - start + 1)
    .fill(0)
    .map((_, i) => i + start);
};

/**
 * Generates a list of page numbers trucated to a fixed size
 *
 * @param {number} currentPage - the current page
 * @param {number} pageCount - the total number of pages
 * @returns {Array<number | string>} - list of page numbers trucated to a fixed size
 *
 * @see {@link https://gist.github.com/kottenator/9d936eb3e4e3c3e02598?permalink_comment_id=3238804#gistcomment-3238804}
 *
 * @example
 * // returns [1, 2, 3, 4, 5, 6, 7]
 * generatePageNumbers(3, 7);
 *
 * @example
 * // returns [1, 2, 3, 4, 5, '...', 60]
 * generatePageNumbers(2, 60);
 *
 * @example
 * // returns [1, '...', 56, 57, 58, 59, 60]
 * generatePageNumbers(58, 60);
 *
 * @example
 * // returns [1, '...', 19, 20, 21, '...', 60]
 * generatePageNumbers(20, 60);
 */
const generatePageNumbers = (currentPage: number, pageCount: number): Array<number | string> => {
  if (pageCount < currentPage) {
    return [];
  }

  let delta: number;
  if (pageCount <= 7) {
    // delta === 7: [1 2 3 4 5 6 7]
    delta = 7;
  } else {
    // delta === 2: [1 ... 4 5 6 ... 10]
    // delta === 4: [1 2 3 4 5 ... 10]
    delta = currentPage > 4 && currentPage < pageCount - 3 ? 2 : 4;
  }

  const range = {
    start: Math.round(currentPage - delta / 2),
    end: Math.round(currentPage + delta / 2),
  };

  if (range.start - 1 === 1 || range.end + 1 === pageCount) {
    range.start += 1;
    range.end += 1;
  }

  let pages: Array<number | string> =
    currentPage > delta
      ? getRange(Math.min(range.start, pageCount - delta), Math.min(range.end, pageCount))
      : getRange(1, Math.min(pageCount, delta + 1));

  const withEllipses = (value: number, pair: Array<number | string>) =>
    pages.length + 1 !== pageCount ? pair : [value];

  if (pages[0] !== 1) {
    pages = withEllipses(1, [1, '...']).concat(pages);
  }

  if (pages[pages.length - 1] < pageCount) {
    pages = pages.concat(withEllipses(pageCount, ['...', pageCount]));
  }

  return pages;
};

interface PaginationProps {
  onPageClick: (page: number) => void;
  page: number;
  pages: number;
}

const Pagination = ({ onPageClick, page, pages }: PaginationProps): JSX.Element => {
  // create an array that represents all the page numbers
  const pageNumbers = generatePageNumbers(page, pages);

  return (
    <div
      className={cx(
        'pagination',
        'apl-px',
        'apl-py-s',
        'apl-display-flex',
        'apl-flex-row',
        'apl-justify-content-start',
        'apl-align-items-center'
      )}
    >
      <button
        className="pagination__link button--link apl-mr-xs"
        disabled={page === 0 || pages < page}
        onClick={() => onPageClick(page - 1)}
        style={{ fontWeight: 600 }}
      >
        Previous
      </button>
      {pageNumbers.map((pageNumber: number | string, idx: number) =>
        pageNumber !== '...' ? (
          <button
            className={cx({
              pagination__button: true,
              'pagination__button--first': pageNumber === 1,
              'pagination__button--last': pageNumber === pages,
            })}
            disabled={page === (pageNumber as number) - 1}
            key={`pagination-button-${pageNumber}`}
            onClick={() => onPageClick((pageNumber as number) - 1)}
          >
            {pageNumber}
          </button>
        ) : (
          <button key={`pagination-button-ellipsis-${idx}`} className="pagination__button" disabled>
            {pageNumber}
          </button>
        )
      )}
      <button
        className="pagination__link button--link apl-ml-xs"
        disabled={page === pages - 1 || pages < page}
        onClick={() => onPageClick(page + 1)}
        style={{ fontWeight: 600 }}
      >
        Next
      </button>
    </div>
  );
};

export default Pagination;
