/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type PlanEventV1 = {
    _type?: PlanEventV1._type;
    _version?: string;
    _publishedAt?: string;
    id?: string;
    status?: PlanEventV1.status;
    provider?: {
        id?: string;
    };
};

export namespace PlanEventV1 {

    export enum _type {
        URN_FLUX_EVENT_RATING_PLAN_CREATED = 'urn:flux:event:rating:plan:created',
        URN_FLUX_EVENT_RATING_PLAN_DELETED = 'urn:flux:event:rating:plan:deleted',
        URN_FLUX_EVENT_RATING_PLAN_ACTIVATED = 'urn:flux:event:rating:plan:activated',
        URN_FLUX_EVENT_RATING_PLAN_UPDATED = 'urn:flux:event:rating:plan:updated',
        URN_FLUX_EVENT_RATING_PLAN_DEACTIVATED = 'urn:flux:event:rating:plan:deactivated',
    }

    export enum status {
        URN_FLUX_RATING_PLAN_STATUS_ACTIVE = 'urn:flux:rating:plan:status:active',
        URN_FLUX_RATING_PLAN_STATUS_INACTIVE = 'urn:flux:rating:plan:status:inactive',
    }


}

