/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { SummarisationGroup } from '../models/SummarisationGroup';
import type { SummarisationGroupBody } from '../models/SummarisationGroupBody';
import type { SummarisationGroupList } from '../models/SummarisationGroupList';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class SummarisationGroupsService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Returns a list of all Summarisation Groups
     * @returns SummarisationGroupList A list of all Summarisation Groups
     * @throws ApiError
     */
    public getSummarisationGroups(): CancelablePromise<SummarisationGroupList> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v2/summarisation-groups',
        });
    }

    /**
     * Creates a new SummarisationGroup
     * @param requestBody Details of the new SummarisationGroup to be created.
     * @returns SummarisationGroup SummarisationGroup created successfully. Returns details of the new SummarisationGroup.
     * @throws ApiError
     */
    public postSummarisationGroups(
        requestBody: SummarisationGroupBody,
    ): CancelablePromise<SummarisationGroup> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/summarisation-groups',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                404: `SummarisationGroup does not exist`,
                422: `Could not process request due to a validation error. See SummarisationGroupBody schema for validation rules.`,
            },
        });
    }

    /**
     * Retrieves a Summarisation Group by Id
     * @param summarisationGroupId Id of the SummarisationGroup requested
     * @returns SummarisationGroup Details of the SummarisationGroup
     * @throws ApiError
     */
    public getSummarisationGroupById(
        summarisationGroupId: string,
    ): CancelablePromise<SummarisationGroup> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v2/summarisation-groups/{summarisationGroupId}',
            path: {
                'summarisationGroupId': summarisationGroupId,
            },
            errors: {
                404: `SummarisationGroup not found`,
            },
        });
    }

}
