/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type ConnectionEvent = {
    _version: string;
    _type: ConnectionEvent._type;
    _publishedAt: string;
    /**
     * An system generated identifier used for tracing the event as it flows through the system
     */
    _correlationId: string;
    id: string;
    supplyAgreementId?: string;
    /**
     * The external id for the connection. In NZ this will be the ICP
     */
    externalId: string;
    connectionTypes?: Array<{
        validFrom?: string;
        validTo?: string;
        types?: Array<'urn:flux:network:connection:electricity:nz:nhh' | 'urn:flux:network:connection:electricity:nz:uml' | 'urn:flux:network:connection:electricity:nz:duml' | 'urn:flux:network:connection:electricity:nz:tou' | 'urn:flux:network:connection:electricity:nz:grid_direct' | 'urn:flux:network:connection:electricity:nz:residual_load' | 'urn:flux:network:connection:electricity:nz:gateway_icp'>;
    }>;
    /**
     * External identifiers must match the type of utility and are validated
     */
    utilityType?: ConnectionEvent.utilityType;
    /**
     * The number of meters that should be configured for this connection. It will be 0 in the case of UML connections.
     */
    expectedMeterCount: number;
    /**
     * A four letter code representing the network company
     */
    networkCode?: string;
    /**
     * A list of external reference codes that signifies the pricing that applies to the connection config. In NZ this will be the price category code.
     */
    tariffCodes: Array<{
        validFrom?: string;
        validTo?: string;
        type?: string;
        code?: string;
    }>;
    usageDataFidelities?: Array<{
        validFrom: string;
        validTo?: string;
        fidelityType: 'urn:flux:network:connection:data_fidelity:raw' | 'urn:flux:network:connection:data_fidelity:loss_adjusted';
    }>;
    /**
     * A specific loss factor for this connection, overrides lossFactors
     */
    customLossFactor?: number;
    /**
     * Codes to denote loss factors that should be applied to the meter usage
     */
    lossFactors?: Array<{
        validFrom?: string;
        validTo?: string;
        code?: string;
    }>;
    priceNode: {
        id?: string;
        validFrom?: string;
        validTo?: string;
    };
    nominatedChargeAttributes: Array<{
        validFrom?: string;
        validTo?: string;
        type?: string;
        value?: number;
        unit?: string;
    }>;
    meters: Array<{
        externalReference?: string;
        validFrom?: string;
        validTo?: string;
    }>;
    nominatedConsumption?: Array<{
        validFrom?: string;
        validTo?: string;
        value?: number;
        unit?: string;
        traderData?: string;
    }>;
};

export namespace ConnectionEvent {

    export enum _type {
        NETWORK_CONNECTION_UPDATED = 'network.connection.updated',
    }

    /**
     * External identifiers must match the type of utility and are validated
     */
    export enum utilityType {
        ELECTRICITY = 'electricity',
        GAS = 'gas',
    }


}

