/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { RatingCalculationMetricsResponse } from '../models/RatingCalculationMetricsResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class RatingCalculatorMetricsService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @param connectionId ID of the connection
     * @param startsAt by default now -15 minutes, specifies start date (in UTC) for a range in which the metrics will be selected
     * @param endsAt by default now, specifies end date (in UTC) for a range in which the metrics will be selected,
     * @returns RatingCalculationMetricsResponse OK
     * @throws ApiError
     */
    public metrics(
        connectionId?: string,
        startsAt?: string,
        endsAt?: string,
    ): CancelablePromise<RatingCalculationMetricsResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/metrics',
            query: {
                'connectionId': connectionId,
                'startsAt': startsAt,
                'endsAt': endsAt,
            },
            errors: {
                400: `Generic error response`,
                401: `Generic error response`,
                500: `Generic error response`,
            },
        });
    }

}
