/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class PermissionsService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Returns a list of the users permissions
     * @returns string A list of permissions for the user
     * @throws ApiError
     */
    public getPermissions(): CancelablePromise<Array<string>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v2/permissions',
        });
    }

}
