/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { UsageChargeFilterPageResponse } from '../models/UsageChargeFilterPageResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class UsageChargeFiltersService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Paginated list of Usage Charge Filters
     * @param filterName
     * @param limit
     * @param offset
     * @returns UsageChargeFilterPageResponse OK
     * @throws ApiError
     */
    public pageUsageChargeFilters(
        filterName?: string,
        limit: number = 20,
        offset?: number,
    ): CancelablePromise<UsageChargeFilterPageResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/usage-charge-filters',
            query: {
                'filterName': filterName,
                'limit': limit,
                'offset': offset,
            },
            errors: {
                400: `Bad-Request`,
                404: `The specified resource was not found`,
            },
        });
    }

}
