import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import apiClient from '../../api/apiClient';

export type BillingDuration =
  | 'urn:flux:billing:duration:week'
  | 'urn:flux:billing:duration:fortnight'
  | 'urn:flux:billing:duration:month'
  | 'urn:flux:billing:duration:quarterly';

export type BillingAcceptabilityUsage =
  | 'urn:flux:billing:acceptability:usage:actual'
  | 'urn:flux:billing:acceptability:usage:final';

export type BillingHoldStatus =
  | 'urn:flux:billing:hold_status:on_hold'
  | 'urn:flux:billing:hold_status:no';

export type InvoiceFormat =
  | 'urn:flux:billing:invoice_format:bundled'
  | 'urn:flux:billing:invoice_format:unbundled';

export interface SupplyAgreementConfiguration {
  startDate: string;
  endDate: string;
  billingPeriod: {
    duration: BillingDuration;
    startDay: number;
  };
  acceptability: {
    ratedUsageQuality: BillingAcceptabilityUsage;
    ratedPriceQuality: BillingAcceptabilityUsage;
  };
  holdStatus: BillingHoldStatus;
  invoiceFormat: InvoiceFormat;
}

const baseEndpoint = '/billing/configuration';

export const useBillingConfigurationSupplyAgreement = (
  id: string,
  options?: Record<string, unknown>
) => {
  return useQuery(
    ['billing-config-supply-agreement', id],
    async () => {
      return apiClient('billing').get(`${baseEndpoint}/supply_agreements/${id}`);
    },
    { ...options }
  );
};

export const useBillingConfigurationSupplyAgreementUpdate = (
  id: string,
  options?: Record<string, unknown>
) => {
  const queryClient = useQueryClient();

  return useMutation(
    (config: SupplyAgreementConfiguration) =>
      apiClient('billing').put(
        `${baseEndpoint}/supply_agreements/${id}`,
        JSON.stringify({
          ...config,
        })
      ),
    {
      ...options,
      onSuccess: () => {
        queryClient.invalidateQueries(['billing-config-supply-agreement', id]);
      },
    }
  );
};
