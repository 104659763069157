import getConfig from './config/getConfig';

const config = getConfig();

export const isLocal = () => 'local' === config.environment;

export const isDev = () => 'development' === config.environment;

export const isTest = () => 'test' === config.environment;

export const isProd = () => 'production' === config.environment;

export const useJurisdictionCode = () => 'JURISDICTION' === config.lossFactorKey;
