import React, { useEffect, useMemo, useState } from 'react';
import cx from 'classnames';
import { endOfMonth, format, formatISO, startOfMonth } from 'date-fns';
import { useErrorTranslation } from 'hooks';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { generateDateOptions } from '../../util/helper-func';
import AdvancedPagination from '../common/AdvancedPagination';
import EmptyTableRow from '../common/EmptyTableRow';
import Card from '../layout/Card';
import Page, { PageHeader } from '../layout/Page';
import { exceptionCategory, generateCategories, generateSourceLabels } from './errorCategories';
import { PublishedError, PublishedErrorList, SourceType, useParallelErrors } from './errorsApi';
import apiClient from '../../api/apiClient';
import getConfig from 'config/getConfig';

const config = getConfig();

const labelFormat = 'MMMM yyyy';
const valueFormat = 'yyyy-MM-dd';

const ErrorFeed = () => {
  const { translateError } = useErrorTranslation();

  const allCategories = useMemo(
    () =>
      config.showSystemErrors
        ? generateCategories().concat(exceptionCategory)
        : generateCategories(),
    []
  );
  const sourceLabels = useMemo(() => generateSourceLabels(), []);

  const options = useMemo(() => generateDateOptions(true, 24, labelFormat, valueFormat), []);

  const navigate = useNavigate();
  const { search } = useLocation();

  const query = search ? new URLSearchParams(search) : null;
  const queryDate = query && query.get('date');
  const initialDate = queryDate !== null ? new Date(queryDate) : new Date();

  const [date, setDate] = useState<Date>(initialDate);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(25);

  // download csv
  const [isCsvLoading, setIsCsvLoading] = useState(false);

  const { tab } = useParams<{ tab: string }>();

  // allows testing of each tab
  const [currentTab, setCurrentTab] = useState(tab ?? '');

  const offset = page !== 0 ? page * limit : page;

  const sourceIndex = allCategories.findIndex((category) => category.url === currentTab);

  // reset offset to 0 when changing categories
  useEffect(() => setPage(0), [currentTab]);

  const allData = useParallelErrors(
    allCategories.map((c, index) => ({
      fromDate: formatISO(startOfMonth(date), { representation: 'date' }),
      limit: sourceIndex === index ? limit : 1,
      offset: sourceIndex === index ? offset : 0,
      urnFilterType: c.tabName === 'Other' ? 'EXCLUDE' : 'INCLUDE',
      urns: c.tabName === 'All' ? undefined : c.urns,
      toDate: formatISO(endOfMonth(date), { representation: 'date' }),
      showSystemErrors: config.showSystemErrors,
    }))
  );

  // Sorry, knitted with a hot needle!
  const handleDownloadCsvClick = async () => {
    setIsCsvLoading(true);

    const fromDate = formatISO(startOfMonth(date), { representation: 'date' });
    const toDate = formatISO(endOfMonth(date), { representation: 'date' });

    const search = new URLSearchParams();
    search.set('fromDate', fromDate);
    search.set('toDate', toDate);
    search.set('limit', '5000');
    search.set('offset', '0');
    search.set('urnFilterType', 'INCLUDE');

    try {
      // get all errors from backend
      const response = await apiClient().get(`/errors?${search.toString()}`);

      // extract fields and format to csv
      let csvResult = response.publishedErrors
        .map(
          (r: any) =>
            `"${formatSource(r.urn)}", "${formatPublihedAt(r._publishedAt)}", "${formatMessage(r)}"`
        )
        .join('\n');

      // prepend header
      csvResult = 'data:text/csv;charset=utf-8, "source", "publishedAt", "message"\n' + csvResult;

      const filename = `errors_${fromDate}.csv`;
      csvResult = encodeURI(csvResult).replaceAll('#', '%23');

      const link = document.createElement('a');
      link.setAttribute('href', csvResult);
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (err: any) {
      console.error(`Error downloading csv data: ${err.message}`);
    } finally {
      setIsCsvLoading(false);
    }
  };

  const isError = allData.find((response) => response.isError) !== undefined;
  const isLoading = allData.find((response) => response.isInitialLoading) !== undefined;

  const formatMessage = (error: PublishedError) => {
    const msg = translateError(error);
    return msg.replace(/(?:\r\n|\r|\n)/g, ' ').replaceAll(/\{\{.*\}\}/g, '<Not Supplied>');
  };

  const formatSource = (sourceUrn?: string) => {
    const foundSourceType = (Object.keys(sourceLabels) as SourceType[]).find((sourceUrnPrepend) =>
      sourceUrn?.includes(sourceUrnPrepend)
    );
    return foundSourceType ? sourceLabels[foundSourceType] : 'Other';
  };

  const formatPublihedAt = (publishedAt: string) => {
    return format(new Date(publishedAt), 'dd-MM-yyyy HH:mm:ss');
  };

  const data =
    !isError && !isLoading && sourceIndex !== -1
      ? (allData?.[sourceIndex].data as PublishedErrorList)
      : null;

  return (
    <>
      <PageHeader backLink="/dashboard" title="Error feed" />
      <Page>
        <Card className="apl-py-none">
          <div
            className="card__inner table-filter apl-field-v1 apl-display-flex apl-flex-row apl-align-items-center"
            style={{
              borderBottom: '1px solid #D3D8DA',
              marginBottom: '15px',
            }}
          >
            <label className="apl-field__label apl-mb-none" htmlFor="date-field">
              Show:
            </label>
            <select
              className="apl-select-v1_0 apl-ml"
              id="date-field"
              onChange={(event) => setDate(new Date(event?.target.value))}
              value={`${format(date, valueFormat)}`}
            >
              {options.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>
          <nav className="tabs card__inner">
            <ul className="tabs__list">
              {allCategories.map((category, index) => {
                const categoryData = allData[index];
                const count =
                  !categoryData.isLoading && !categoryData.isError
                    ? (categoryData.data as PublishedErrorList).totalCount
                    : '0';

                return (
                  <li className="tabs__list-item" key={category.tabName}>
                    <a
                      className={cx({
                        'tabs__list-link': true,
                        'tabs__list-link--active': currentTab === category.url,
                      })}
                      data-testid={`tab-for-${category.url}`}
                      onClick={() => {
                        setCurrentTab(category.url);
                        navigate(`../${category.url}`, { relative: 'path' });
                      }}
                    >
                      {category.tabName} ({count})
                    </a>
                  </li>
                );
              })}
            </ul>
          </nav>
          <table className="apl-table-v1 apl-mb-none">
            {isError && <EmptyTableRow message="Sorry, there was an error" />}
            {isLoading && (
              <tbody>
                <tr>
                  <td colSpan={3}>Loading...</td>
                </tr>
              </tbody>
            )}
            {data && data.totalCount > 0 && (
              <>
                <thead>
                  <tr>
                    <th>Source</th>
                    <th>Message</th>
                    <th>Time</th>
                  </tr>
                </thead>
                <tbody>
                  {data.publishedErrors.map((error, index) => {
                    return (
                      <tr key={`error-feed-${index}`}>
                        <td>{formatSource(error.urn) ?? ''}</td>
                        <td style={{ whiteSpace: 'break-spaces' }}>
                          {translateError(error).replaceAll(/\{\{.*\}\}/g, '<Not Supplied>')}
                        </td>
                        <td>{formatPublihedAt(error._publishedAt)}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </>
            )}
            {data && data.totalCount === 0 && <EmptyTableRow message="There are no errors." />}
          </table>
          {data && (
            <div className="card__inner">
              <AdvancedPagination
                limit={limit}
                limitOptions={[25, 50]}
                onLimitChange={setLimit}
                onPageChange={setPage}
                page={page}
                totalItems={data.totalCount}
              />
              {config.showErrorDownload && (
                <div className="apl-display-flex apl-flex-column apl-align-items-end apl-mx-s apl-my-s">
                  <button className="apl-button-v1" onClick={handleDownloadCsvClick}>
                    {isCsvLoading ? 'Loading...' : 'Download CSV'}
                  </button>
                </div>
              )}
            </div>
          )}
        </Card>
      </Page>
    </>
  );
};

export default ErrorFeed;
