import React from 'react';
import { EuiCard, EuiFlexGroup, EuiFormLabel, EuiText, EuiTitle, EuiFlexItem } from '@elastic/eui';

interface PlanLinkHistorySupplyPeriodProps {
  contractedPartyName: string;
  supplyPeriodStartDate: string;
  supplyAgreementStatus: string;
}

const PlanLinkHistorySupplyPeriod = ({
  contractedPartyName,
  supplyPeriodStartDate,
  supplyAgreementStatus,
}: PlanLinkHistorySupplyPeriodProps) => {
  const renderContractedParty = (contractedPartyName: string) => {
    return (
      <EuiFlexItem style={{ marginRight: '50px' }}>
        <EuiFormLabel htmlFor="contracted-party-field">Contracted party</EuiFormLabel>
        <EuiFlexGroup alignItems="center" justifyContent="spaceBetween">
          <EuiFlexItem grow={false}>
            <EuiTitle size="s">
              <p id="contracted-party-field">{contractedPartyName}</p>
            </EuiTitle>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    );
  };

  const renderSupplyPeriod = (supplyPeriodStartDate: string) => {
    return (
      <EuiFlexItem style={{ marginRight: '50px' }}>
        <EuiFormLabel htmlFor="supply-period-field">Supply period</EuiFormLabel>
        <EuiFlexGroup alignItems="center" justifyContent="spaceBetween">
          <EuiFlexItem grow={false}>
            <EuiTitle size="s">
              <p id="contracted-party-field">{supplyPeriodStartDate}</p>
            </EuiTitle>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    );
  };

  const renderSupplyAgreementStatus = (supplyAgreementStatus: string) => {
    return (
      <EuiFlexItem style={{ marginRight: '50px' }}>
        <EuiFormLabel htmlFor="supply-agreement-status-field">Supply agreement status</EuiFormLabel>
        <EuiFlexGroup alignItems="center" direction="column">
          <EuiFlexItem grow={false}>
            <EuiText id="supply-agreement-status-field" size="m">
              {supplyAgreementStatus}
            </EuiText>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    );
  };

  return (
    <EuiFlexGroup direction="row" gutterSize="s">
      <EuiFlexItem>
        <EuiCard title="">
          <EuiFlexGroup direction="row" alignItems="center" gutterSize="s">
            <EuiFlexItem grow={false}>{renderContractedParty(contractedPartyName)}</EuiFlexItem>
            <EuiFlexItem grow={false}>{renderSupplyPeriod(supplyPeriodStartDate)}</EuiFlexItem>
            <EuiFlexItem grow={false}>
              {renderSupplyAgreementStatus(supplyAgreementStatus)}
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiCard>
      </EuiFlexItem>
    </EuiFlexGroup>
  );
};

export default PlanLinkHistorySupplyPeriod;
