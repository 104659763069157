/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { RatingRecord } from '../models/RatingRecord';
import type { RatingRecordList } from '../models/RatingRecordList';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class TestService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Lists rating records.
     * @returns RatingRecordList OK
     * @throws ApiError
     */
    public listRatingRecords(): CancelablePromise<RatingRecordList> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/test/rating-records',
            errors: {
                401: `Generic error response`,
                500: `Generic error response`,
            },
        });
    }

    /**
     * Create rating record
     * @param requestBody
     * @returns RatingRecord Created
     * @throws ApiError
     */
    public createRatingRecord(
        requestBody: RatingRecord,
    ): CancelablePromise<RatingRecord> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/test/rating-records',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Generic error response`,
                500: `Generic error response`,
            },
        });
    }

    /**
     * Gets a rating record.
     * @param id
     * @returns RatingRecord OK
     * @throws ApiError
     */
    public getRatingRecord(
        id: string,
    ): CancelablePromise<RatingRecord> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/test/rating-records/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `Generic error response`,
                404: `Generic error response`,
                500: `Generic error response`,
            },
        });
    }

    /**
     * Deletes the rating record with the specified id
     * @param id
     * @returns void
     * @throws ApiError
     */
    public deleteRatingRecord(
        id: string,
    ): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/test/rating-records/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `Generic error response`,
                404: `Generic error response`,
                500: `Generic error response`,
            },
        });
    }

}
