import React, { FunctionComponent, useState } from 'react';
import Modal from 'react-modal';
import AuthorizedAction from '../common/AuthorizedAction';
import { modalStyles } from '../layout/Modal';
import { Permission } from '../../auth/getPermissions';
import { useDeleteProvisioningExclusion } from './provisioningApi';
import { Form, Formik } from 'formik';

interface RemoveExclusionProps {
  exclusionId: string;
}

const RemoveExclusion: FunctionComponent<RemoveExclusionProps> = ({ exclusionId }) => {
  const [removeExclusionIsOpen, setRemoveExclusionIsOpen] = useState(false);

  const closeRemoveExclusion = () => setRemoveExclusionIsOpen(false);

  const openRemoveExclusion = () => setRemoveExclusionIsOpen(true);

  const { mutateAsync: deleteProvisioningExclusion } = useDeleteProvisioningExclusion();

  const handleDelete = async () => {
    const { id } = await deleteProvisioningExclusion({ exclusionId });

    if (id) {
      closeRemoveExclusion();
    }
  };

  return (
    <>
      <AuthorizedAction
        testId="remove exclusion button"
        onClick={openRemoveExclusion}
        permission={Permission.PROVIDERS_EDIT}
      >
        Remove exclusion
      </AuthorizedAction>
      <Modal
        isOpen={removeExclusionIsOpen}
        onRequestClose={closeRemoveExclusion}
        style={modalStyles}
        shouldCloseOnOverlayClick={false}
      >
        <Formik initialValues={{}} onSubmit={handleDelete}>
          {() => (
            <Form className="apl-form-layout-v1">
              <div>Are you sure you want to delete this exclusion?</div>
              <div className="apl-display-flex apl-justify-content-end">
                <button className="apl-button-v1" onClick={closeRemoveExclusion} type={'button'}>
                  Cancel
                </button>
                <AuthorizedAction
                  extraClasses="is-primary"
                  permission={Permission.PROVIDERS_EDIT}
                  removeDefaultWeight={true}
                  type="submit"
                >
                  Confirm
                </AuthorizedAction>
              </div>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
};

export default RemoveExclusion;
