import { Toast } from '@elastic/eui/src/components/toast/global_toast_list';
import create from 'zustand';

interface Store {
  notifications: Toast[];
  addNotification: (n: Toast) => void;
  removeNotification: (toast: Toast) => void;
}

export interface ToastNotificationBody {
  message?: string;
  title: string;
  type: NotificationType;
}

export const makeErrorToastNotificationBody = (
  responseStatus: number,
  responseBodyMessage: string | undefined,
  responseBodyError: string | undefined,
  showErrorResponseBodyError: boolean
): Toast => {
  let title = 'Sorry, there was an error.';
  let message = responseBodyMessage;
  if (showErrorResponseBodyError && !message) {
    message = responseBodyError;
  }

  if (responseStatus == 404 && showErrorResponseBodyError) {
    title = "Sorry, but we couldn't find the requested resource.";
  } else if (responseStatus == 504) {
    title = 'Sorry, the server is busy. Please try again later.';
  }
  message = message ? message.substring(0, 256) : message;
  return { title, text: message, id: 'Normal Fail', color: 'danger' };
};

export type ToastNotification = ToastNotificationBody & {
  id: string;
};

export type NotificationType = 'info' | 'error' | 'success';

export const useStore = create<Store>((set, get) => ({
  notifications: [],
  addNotification: (toast: Toast) => {
    get().notifications.forEach((notification) => {
      if (notification.id == toast.id) {
        get().removeNotification(notification);
      }
    });
    set({ notifications: [...get().notifications, toast] });
  },
  removeNotification: (toast: Toast) =>
    set({
      notifications: [...get().notifications].filter((item) => item.id !== toast.id),
    }),
}));
