/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type CoreNetworkConnectionUpdated = {
    /**
     * 1.4.0
     */
    _version: string;
    _type: CoreNetworkConnectionUpdated._type;
    _publishedAt: string;
    /**
     * An system generated identifier used for tracing the event as it flows through the system
     */
    _correlationId: string;
    id: string;
    /**
     * The external id for the connection. In NZ this will be the ICP
     */
    externalId: string;
    /**
     * External identifiers must match the type of utility and are validated
     */
    utilityType?: CoreNetworkConnectionUpdated.utilityType;
    /**
     * The number of meters that should be configured for this connection. It will be 0 in the case of UML connections.
     */
    expectedMeterCount: number;
    /**
     * A four letter code representing the network company
     */
    networkCode?: string;
    /**
     * A list of external reference codes that signifies the pricing that applies to the connection config. In NZ this will be the price category code.
     */
    tariffCodes: Array<{
        /**
         * The earliest date/time this tariff becomes valid
         */
        validFrom?: string;
        /**
         * The earliest date/time this tariff ceases to be valid
         */
        validTo?: string;
        type?: string;
        code?: string;
    }>;
    /**
     * Indicates if the data provided is raw or already loss adjusted
     */
    usageDataFidelities?: Array<{
        /**
         * The earliest date/time this usageDataFidelity becomes valid
         */
        validFrom?: string;
        /**
         * The earliest date/time this usageDateFidelity ceases to be valid
         */
        validTo?: string;
        fidelityType?: 'urn:flux:network:connection:data_fidelity:raw' | 'urn:flux:network:connection:data_fidelity:loss_adjusted';
    }>;
    /**
     * The loss factor overridden by this custom loss factor
     */
    customLossFactor?: number;
    /**
     * Codes to denote loss factors that should be applied to the meter usage
     */
    lossFactors?: Array<{
        /**
         * The earliest date/time this lossFactor becomes valid
         */
        validFrom?: string;
        /**
         * The earliest date/time this lossFactor ceases to be valid
         */
        validTo?: string;
        code?: string;
    }>;
    priceNode?: {
        id?: string;
        /**
         * URN of the external identifier e.g. `urn:flux:price_node:gxp:ABY0111`
         */
        externalReference?: string;
        /**
         * The earliest date/time this priceNode becomes valid
         */
        validFrom?: string;
        /**
         * The earliest date/time this priceNode ceases to be valid
         */
        validTo?: string;
    };
    priceNodes?: Array<{
        id?: string;
        /**
         * URN of the external identifier e.g. `urn:flux:price_node:gxp:ABY0111`
         */
        externalReference?: string;
        /**
         * The earliest date/time this priceNode becomes valid
         */
        validFrom?: string;
        /**
         * The earliest date/time this priceNode ceases to be valid
         */
        validTo?: string;
    }>;
    nominatedChargeAttributes: Array<{
        /**
         * The earliest date/time this nominatedChargeAttribute becomes valid
         */
        validFrom?: string;
        /**
         * The earliest date/time this nominatedChargeAttribute ceases to be valid
         */
        validTo?: string;
        type?: string;
        value?: number;
        unit?: string;
    }>;
    meters: Array<{
        externalReference?: string;
        /**
         * The earliest date/time this meter becomes valid
         */
        validFrom?: string;
        /**
         * The earliest date/time this meter ceases to be valid
         */
        validTo?: string;
        registers?: Array<{
            /**
             * Unique identifier of the register
             */
            id?: string;
            /**
             * Urn of the connection this register belongs to.
             */
            scopedTo?: string;
            /**
             * Source urn identifier based on the connection, meter and register. Mapping based on urn:flux:network:connection:externalId:meter:externalReference:register:serial:stream:TBC dataStreamType
             */
            externalIdentifier?: string;
            /**
             * The earliest date/time this Register becomes valid
             */
            validFrom: string;
            /**
             * The earliest date/time this Register ceases to be valid
             */
            validTo?: string;
            /**
             * Uniquely identifies the register within the meter
             */
            serial: string;
            /**
             * Whether the register measures energy flowing into the connection or out of the connection
             */
            supportedEnergyFlowDirection: 'urn:flux:network:connection:flow-direction:into-connection' | 'urn:flux:network:connection:flow-direction:out-of-connection';
            /**
             * The register multiplier
             */
            multiplier?: number;
            /**
             * The register metered tariff
             */
            meteredTariff?: string;
            /**
             * The register purpose
             */
            purpose?: 'urn:flux:network:connection:meter:register:controlled-load' | 'urn:flux:network:connection:meter:register:non-settlement' | 'urn:flux:network:connection:meter:register:primary-load';
        }>;
    }>;
    /**
     * Identifiers that can be used when there is no meter, e.g. for sites that get derived metering data
     */
    meterDataIdentifiers?: Array<{
        /**
         * The earliest date/time this meterDataIdentifier becomes valid
         */
        validFrom?: string;
        /**
         * The earliest date/time this meterDataIdentifier ceases to be valid
         */
        validTo?: string;
        /**
         * The identifier field that can be used when there is no meter
         */
        identifier?: string;
    }>;
    nominatedConsumption?: Array<{
        /**
         * The earliest date/time this nominatedConsumption becomes valid
         */
        validFrom?: string;
        /**
         * The earliest date/time this nominatedConsumption ceases to be valid
         */
        validTo?: string;
        value?: number;
        unit?: string;
        traderData?: string;
    }>;
    /**
     * How the connection is configured to measure and consume energy
     */
    connectionTypes?: Array<{
        /**
         * The earliest date/time this connection type valid
         */
        validFrom?: string;
        /**
         * The earliest date/time this connection type ceases to be valid
         */
        validTo?: string;
        types?: Array<'urn:flux:network:connection:electricity:nz:nhh' | 'urn:flux:network:connection:electricity:nz:uml' | 'urn:flux:network:connection:electricity:nz:duml' | 'urn:flux:network:connection:electricity:nz:tou' | 'urn:flux:network:connection:electricity:nz:grid_direct' | 'urn:flux:network:connection:electricity:nz:residual_load' | 'urn:flux:network:connection:electricity:nz:gateway_icp'>;
    }>;
    /**
     * NSP code and network area supplier tariff code
     */
    networkSupplyPoints?: Array<{
        /**
         * The earliest date/time this network supply point valid
         */
        validFrom?: string;
        /**
         * The earliest date/time this network supply point ceases to be valid
         */
        validTo?: string;
        /**
         * Unique code identifying the network supply point for a connection
         */
        code?: string;
        /**
         * Network area supplier tariff code
         */
        supplierTariffCode?: string;
    }>;
    jurisdictions?: Array<{
        /**
         * Jurisdiction code e.g. `NSW`
         */
        code?: string;
        /**
         * The earliest date/time this jurisdiction becomes valid
         */
        validFrom?: string;
        /**
         * The earliest date/time this jurisdiction ceases to be valid
         */
        validTo?: string;
    }>;
    /**
     * The point of connection at which a distribution network connects to a transmission network.
     */
    pointsOfConnection?: Array<{
        /**
         * The URN that identifies the point of connection e.g. `urn:flux:nz:network:point-of-connection:ABY0111`
         */
        identifier?: string;
        /**
         * The earliest date/time this pointsOfConnection becomes valid
         */
        validFrom?: string;
        /**
         * The earliest date/time this pointsOfConnection ceases to be valid
         */
        validTo?: string;
    }>;
};

export namespace CoreNetworkConnectionUpdated {

    export enum _type {
        NETWORK_CONNECTION_UPDATED = 'network.connection.updated',
    }

    /**
     * External identifiers must match the type of utility and are validated
     */
    export enum utilityType {
        ELECTRICITY = 'electricity',
        GAS = 'gas',
    }


}

