import React, { useEffect, useMemo, useState } from 'react';
import {
  differenceInCalendarDays,
  endOfMonth,
  format,
  getDaysInMonth,
  isWithinInterval,
  startOfMonth,
  subMonths,
} from 'date-fns';
import { Link, useParams } from 'react-router-dom';
import EmptyTableRow from '../../common/EmptyTableRow';
import { Widget } from '../../dashboard/Dashboard';
import { Measurement, UsageSummary, useUsageSummary } from '../../telemetry/telemetryUsageApi';
import {
  capitaliseFirstText,
  FieldOption,
  generateDateOptionsBaseFromSupplyPeriod,
} from '../../../util/helper-func';
import { SupplyPeriodSummary } from '../connectionsApi';

export type FlowDirection = 'IN' | 'OUT';

export const generateFlowOptions = () => {
  return ['IN', 'OUT'].map((direction) => ({
    label: capitaliseFirstText(direction),
    value: direction,
  }));
};

export const findMeasurement = (unit: string, measurements: Measurement[]) => {
  const measurement =
    measurements && measurements.length ? measurements.find((m) => m.unit === unit)?.value : null;

  return measurement ?? '-';
};

interface MetricDataComponentProps {
  supplyPeriod: SupplyPeriodSummary;
}

const MeteringDataComponent = ({ supplyPeriod }: MetricDataComponentProps) => {
  const flowOptions = useMemo(() => generateFlowOptions(), []);
  const [fromOptions, setFromOptions] = useState<FieldOption[]>([]);
  const [from, setFrom] = useState<string>('');
  const [flow, setFlow] = useState<FlowDirection>('IN');
  const [startsAt, setStartsAt] = useState<string>('');
  const [endsAt, setEndsAt] = useState<string>('');

  useEffect(() => {
    const dateOptions = generateDateOptionsBaseFromSupplyPeriod(supplyPeriod);
    setFromOptions(dateOptions);
    setFrom(getStartDateFromOption(dateOptions));
  }, [JSON.stringify(supplyPeriod)]);

  const { id } = useParams<{ id: string }>();

  const isSupplyPeriodCancelled = supplyPeriod.status === 'CANCELLED';

  const {
    data,
    isInitialLoading: isLoading,
    isError,
  } = useUsageSummary(
    {
      startsAt: startsAt,
      endsAt: endsAt,
      connectionId: id,
      limit: 31,
      isEnergyGoingToConnection: flow === 'IN',
    },
    {
      enabled: !!startsAt && !!endsAt && !isSupplyPeriodCancelled,
    }
  );

  // adjust the metering data date range base from the supply period
  useEffect(() => {
    if (from) {
      // create a date range object for the selected month
      const monthRange = { start: new Date(from), end: endOfMonth(new Date(from)) };
      // if the supply period start date is within the selected month,
      // use it as startAt value for getting metering data.
      const supplyPeriodStartDate = supplyPeriod.startDate;
      if (supplyPeriodStartDate && isWithinInterval(new Date(supplyPeriodStartDate), monthRange)) {
        setStartsAt(format(new Date(supplyPeriodStartDate), 'yyyy-MM-dd'));
      } else {
        setStartsAt(format(monthRange.start, 'yyyy-MM-dd'));
      }
      // if the supply period end date is within the selected month,
      // use it as endAt value for getting metering data.
      const supplyPeriodEndDate = supplyPeriod.endDate;
      if (supplyPeriodEndDate && isWithinInterval(new Date(supplyPeriodEndDate), monthRange)) {
        setEndsAt(format(new Date(supplyPeriodEndDate), 'yyyy-MM-dd'));
      } else {
        setEndsAt(format(monthRange.end, 'yyyy-MM-dd'));
      }
    }
  }, [from, supplyPeriod]);

  const hasSummaries = data?.summaries?.[0]?.summaries?.length > 0;

  return (
    <Widget className="widget--full" title="Metering data" width="full">
      <div className="table-filter apl-display-flex apl-flex-row apl-align-items-center apl-justify-content-between">
        <div
          className="apl-display-flex apl-flex-row apl-align-items-center"
          style={{
            paddingLeft: '5px',
          }}
        >
          <div className="apl-field-v1 apl-display-flex apl-flex-row apl-align-items-center apl-mr-xs">
            <label className="apl-field__label apl-mr-xs apl-mb-none" htmlFor="from-field">
              Show
            </label>
            <select
              className="apl-select-v1_0"
              id="from-field"
              name="from"
              data-testid="from"
              onChange={(e) => setFrom(e.target.value)}
              value={from}
            >
              {fromOptions.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>
          <div className="apl-field-v1 apl-display-flex apl-flex-row apl-align-items-center">
            <label className="apl-field__label apl-mr-xs apl-mb-none" htmlFor="flow-field">
              Flow
            </label>
            <select
              className="apl-select-v1_0"
              id="flow-field"
              name="flow"
              onChange={(e) => setFlow(e.target.value as FlowDirection)}
              value={flow}
            >
              {flowOptions.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
      <table className="apl-table-v1 apl-width-full" style={{ borderRadius: 0 }}>
        {isLoading && (
          <tbody>
            <tr>
              <td>Loading...</td>
            </tr>
          </tbody>
        )}
        {isError && (
          <tbody>
            <tr>
              <td>Sorry, there was an error.</td>
            </tr>
          </tbody>
        )}
        {data && hasSummaries && !isSupplyPeriodCancelled && (
          <>
            <thead>
              <tr>
                <th className="apl-text-left" rowSpan={2} style={{ verticalAlign: 'bottom' }}>
                  Month
                </th>
                <th className="apl-text-left" rowSpan={2} style={{ verticalAlign: 'bottom' }}>
                  Complete days
                </th>
                <th className="apl-text-left" rowSpan={2} style={{ verticalAlign: 'bottom' }}>
                  Incomplete days
                </th>
                <th className="apl-text-right" colSpan={3}>
                  Metered consumption
                </th>
                <th className="apl-text-right" colSpan={3}>
                  Loss adjusted consumption
                </th>
              </tr>
              <tr>
                <th className="apl-text-right" style={{ verticalAlign: 'bottom' }}>
                  kWh
                </th>
                <th className="apl-text-right" style={{ verticalAlign: 'bottom' }}>
                  kVAh
                </th>
                <th className="apl-text-right" style={{ verticalAlign: 'bottom' }}>
                  kVArh
                </th>
                <th className="apl-text-right" style={{ verticalAlign: 'bottom' }}>
                  kWh
                </th>
                <th className="apl-text-right" style={{ verticalAlign: 'bottom' }}>
                  kVAh
                </th>
                <th className="apl-text-right" style={{ verticalAlign: 'bottom' }}>
                  kVArh
                </th>
              </tr>
            </thead>
            <tbody>
              {data.summaries.map((summary: UsageSummary) => {
                const firstRow = summary?.summaries?.length ? summary.summaries[0] : null;
                const month = firstRow ? firstRow.summaryPeriod : '';
                const monthAsDate = month ? new Date(month) : null;
                const displayMonth = monthAsDate ? format(monthAsDate, 'MMM yyyy') : '-';
                const daysInMonth =
                  startsAt && endsAt
                    ? differenceInCalendarDays(new Date(endsAt), new Date(startsAt)) + 1
                    : monthAsDate
                    ? getDaysInMonth(monthAsDate)
                    : 30;

                return (
                  <tr key={`month-summary-${month}`}>
                    <td className="apl-text-left">
                      <Link
                        className="apl-color-primary"
                        to={`/connections/${id}/metering/month?date=${month}&flow=${flow}&supply_period=${supplyPeriod.id}`}
                      >
                        {displayMonth}
                      </Link>
                    </td>
                    <td className="apl-text-left">
                      {`${summary.numberOfCompleteSummaries}/${daysInMonth}`}
                    </td>
                    <td className="apl-text-left">
                      {daysInMonth - summary.numberOfCompleteSummaries}
                    </td>
                    <td className="apl-text-right">
                      {findMeasurement('kWh', summary.totals.totalValues)}
                    </td>
                    <td className="apl-text-right">
                      {findMeasurement('kVAh', summary.totals.totalValues)}
                    </td>
                    <td className="apl-text-right">
                      {findMeasurement('kVArh', summary.totals.totalValues)}
                    </td>
                    <td className="apl-text-right">
                      {findMeasurement('loss_adjusted_kWh', summary.totals.totalValues)}
                    </td>
                    <td className="apl-text-right">
                      {findMeasurement('loss_adjusted_kVAh', summary.totals.totalValues)}
                    </td>
                    <td className="apl-text-right">
                      {findMeasurement('loss_adjusted_kVArh', summary.totals.totalValues)}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </>
        )}
        {((data && !hasSummaries) || isSupplyPeriodCancelled) && (
          <EmptyTableRow message="Sorry, no metering data found." />
        )}
      </table>
    </Widget>
  );
};

export default MeteringDataComponent;

/**
 * Returns either '', the first entry or second entry of the given array.
 * @param fromOptions
 */
const getStartDateFromOption = (fromOptions: FieldOption[]): string => {
  if (!fromOptions || fromOptions.length === 0) {
    return '';
  }
  const startLastMonth = format(startOfMonth(subMonths(new Date(), 1)), 'yyyy-MM-dd');
  const from = fromOptions.find((option) => option.value === startLastMonth);
  if (from) {
    return from.value;
  }
  return fromOptions[0].value;
};
