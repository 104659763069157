import React, { useEffect, useState } from 'react';
import {
  differenceInMonths,
  isAfter,
  isBefore,
  isSameDay,
  startOfMonth,
  subSeconds,
} from 'date-fns';
import useDeepCompareEffect, { useDeepCompareEffectNoCheck } from 'use-deep-compare-effect';
import CreditNoteRow from './CreditNoteRow';
import InvoiceRow from './InvoiceRow';
import { Connection, SupplyPeriodSummary } from '../connectionsApi';
import { CreditNote, useGetCreditNotes } from '../../billing/billingCreditNotesApi';
import {
  useGetInvoices,
  Invoice,
  InvoicesResult,
  INVOICE_STATUS_REVERSED,
} from '../../billing/billingInvoicesApi';
import EmptyTableRow from '../../common/EmptyTableRow';
import { generateDateOptions, newMarketDate } from '../../../util/helper-func';
import searchIcon from '../../../icons/search.svg';
import { useFlags } from 'launchdarkly-react-client-sdk';

const isInvoice = (item: CreditNote | Invoice) => 'status' in item;
const isBctiInvoice = (item: Invoice) => {
  return item.data?.isCustomerGenerated;
};
const isFinanceInvoice = (item: Invoice) => {
  return item.data?.invoiceType === 'finance';
};
const getInvoiceNumber = (item: Invoice) => item?.data?.invoiceNumber ?? '';
const getCreditNoteNumber = (item: CreditNote) => item?.creditNoteNumber ?? '';

type ActivityType = 'all' | 'invoices' | 'credit-notes' | 'bcti' | 'finance-invoices';

interface InvoicesComponentProps {
  connection: Connection;
  onInvoiceUpdate: (hasInvoices: boolean) => void;
  period: SupplyPeriodSummary;
}

/*
This is a stopgap to fetch all of the invoices and credit notes.

The reason being we need to know the earliest and latest dates for invoices
and credit notes because these could fall outside of the supply period's start and end dates
due to backdated switch losses, move out, downgrade etc., See MX-11477.

Ideally we should get some supply period start and end dates which encompass those scenarios.

That way we can make use of the server-side paging and filtering instead and still receive
all of the invoices and credit notes for a given external supply agreement ID.
*/
const RECORD_LIMIT = 100000;

const InvoicesComponent = ({ connection, onInvoiceUpdate, period }: InvoicesComponentProps) => {
  const [activityType, setActivityType] = useState<ActivityType>('all');
  const [search, setSearch] = useState('');
  const [from, setFrom] = useState('');
  const [to, setTo] = useState('');
  const [fromOptions, setFromOptions] = useState<Array<{ label: string; value: string }>>([]);
  const [toOptions, setToOptions] = useState<Array<{ label: string; value: string }>>([]);
  const [combinedItems, setCombinedItems] = useState<(Invoice | CreditNote)[] | null>(null);
  const [combinedItemsFiltered, setCombinedItemsFiltered] = useState<
    (Invoice | CreditNote)[] | undefined
  >();
  const [earliestCombinedDate, setEarliestCombinedDate] = useState<Date | undefined>();
  const [latestCombinedDate, setLatestCombinedDate] = useState<Date | undefined>();
  const [range, setRange] = useState<number>();

  const {
    cfd866OnBillFinance, // LD Client Key is  cfd-866-on-bill-finance
  } = useFlags();

  const {
    data: invoiceData,
    isError: invoiceDataError,
    isInitialLoading: invoiceDataLoading,
  } = useGetInvoices(
    {
      externalSupplyAgreementId: period.externalSupplyAgreementId,
      limit: RECORD_LIMIT,
    },
    {
      enabled: !!period && !!period.externalSupplyAgreementId,
    }
  );

  const {
    data: creditNoteData,
    isError: creditNoteDataError,
    isInitialLoading: creditNoteDataLoading,
  } = useGetCreditNotes(
    {
      externalSupplyAgreementId: period.externalSupplyAgreementId,
      limit: RECORD_LIMIT,
    },
    {
      enabled: !!period && !!period.externalSupplyAgreementId,
    }
  );

  useEffect(() => {
    const invoiceCount =
      invoiceData &&
      invoiceData.items.filter((item: Invoice) => item.status !== INVOICE_STATUS_REVERSED).length;

    onInvoiceUpdate(invoiceCount === 0);
  }, [invoiceData, period.id]);

  // used for no results message
  const activityTypeNice =
    activityType === 'credit-notes'
      ? 'credit notes'
      : activityType === 'invoices'
      ? 'invoices'
      : activityType === 'finance-invoices'
      ? 'finance invoices'
      : 'items';

  useDeepCompareEffectNoCheck(() => {
    // we need to merge invoices and credit notes together and then filter items
    // based on the current activity type selected
    if (invoiceData && creditNoteData) {
      setCombinedItems(
        (invoiceData as InvoicesResult).items
          .filter((item) => item.status !== INVOICE_STATUS_REVERSED)
          .concat(creditNoteData.items) as Array<Invoice | CreditNote>
      );
    }
  }, [invoiceData, creditNoteData]);

  const now = new Date();

  useDeepCompareEffectNoCheck(() => {
    if (combinedItems && combinedItems.length > 0) {
      // start date is calculated based on the earliest start date
      // found in the combined items' contexts.
      setEarliestCombinedDate(
        newMarketDate(
          Math.min(
            // eslint-disable-next-line no-unsafe-optional-chaining
            ...combinedItems?.map((item) =>
              item.context && item.context.startsAt
                ? new Date(item.context.startsAt).getTime()
                : new Date(0).getTime()
            )
          )
        )
      );

      // end date is calculated based on the latest end date
      // found in the combined items' contexts.
      setLatestCombinedDate(
        // need to subtract 1 second to align with InvoiceRow and CreditNoteRow end dates subtracting 1 second.
        subSeconds(
          newMarketDate(
            Math.max(
              //eslint-disable-next-line no-unsafe-optional-chaining
              ...combinedItems?.map((item) =>
                item.context && item.context.endsAt
                  ? new Date(item.context.endsAt).getTime()
                  : now.getTime()
              )
            )
          ),
          1
        )
      );
    } else {
      // we need to reset if combinedItems is empty so the date
      // options will be reloaded when moving to a supply period
      // or contracted party with no invoices/credit notes.
      setEarliestCombinedDate(undefined);
      setLatestCombinedDate(undefined);
    }
  }, [combinedItems, period]);

  useEffect(() => {
    if (!earliestCombinedDate) {
      // fallback for earliest date
      setEarliestCombinedDate(newMarketDate(period.startDate));
    }
  }, [earliestCombinedDate]);

  useEffect(() => {
    if (!latestCombinedDate) {
      // fallback for latest date
      setLatestCombinedDate(newMarketDate(now.getTime()));
    }
  }, [latestCombinedDate]);

  useEffect(() => {
    // calculate how many months between end and start date if end date is after
    // start date, otherwise we assume the period is in the future with no end
    // date so we should just show the future start month as the only from and to
    // options to choose from
    if (
      latestCombinedDate &&
      earliestCombinedDate &&
      isAfter(latestCombinedDate, earliestCombinedDate)
    ) {
      setRange(
        differenceInMonths(startOfMonth(latestCombinedDate), startOfMonth(earliestCombinedDate))
      );
    } else {
      setRange(0);
    }
  }, [latestCombinedDate, earliestCombinedDate]);

  useEffect(() => {
    // populate the from options if we have an earliest start date
    if (earliestCombinedDate) {
      setFromOptions(
        generateDateOptions(
          true,
          range,
          'MMMM yyyy',
          "yyyy-MM-dd'T'HH:mm:ssXXX",
          latestCombinedDate
        )
      );
    }
  }, [earliestCombinedDate, range, latestCombinedDate]);

  useEffect(() => {
    if (latestCombinedDate) {
      // populate the to options if we have a latest end date
      setToOptions(
        generateDateOptions(
          false,
          range,
          'MMMM yyyy',
          "yyyy-MM-dd'T'HH:mm:ssXXX",
          latestCombinedDate,
          true
        )
      );
    }
  }, [latestCombinedDate, range]);

  // run this useEffect variant everytime the list of from options change
  // and reset the default selected from value to the earliest option
  useDeepCompareEffect(() => {
    // set the selected from value once we have a set of from options
    if (fromOptions && fromOptions.length > 0) {
      setFrom(fromOptions[fromOptions.length - 1].value);
    }
  }, [fromOptions]);

  // run this useEffect variant everytime the list of to options change
  // and reset the default selected to value to the latest option
  useDeepCompareEffect(() => {
    // set the selected to value once we have a set of to options
    if (toOptions && toOptions.length > 0) {
      setTo(toOptions[0].value);
    }
  }, [toOptions]);

  useEffect(() => {
    // filter the combined items (invoices and credit notes) based on the
    // search term and if the item/s is/are within the selected date ranges
    setCombinedItemsFiltered(
      combinedItems
        ?.filter((item) => {
          switch (activityType) {
            case 'credit-notes':
              return (
                !isInvoice(item) &&
                getCreditNoteNumber(item as CreditNote)
                  .toLowerCase()
                  .includes(search.toLowerCase())
              );
            case 'invoices':
              return (
                isInvoice(item) &&
                !isBctiInvoice(item as Invoice) &&
                !isFinanceInvoice(item as Invoice) &&
                getInvoiceNumber(item as Invoice)
                  .toLowerCase()
                  .includes(search.toLowerCase())
              );
            case 'bcti':
              return (
                isInvoice(item) &&
                isBctiInvoice(item as Invoice) &&
                getInvoiceNumber(item as Invoice)
                  .toLowerCase()
                  .includes(search.toLowerCase())
              );
            case 'finance-invoices':
              return (
                isInvoice(item) &&
                isFinanceInvoice(item as Invoice) &&
                getInvoiceNumber(item as Invoice)
                  .toLowerCase()
                  .includes(search.toLowerCase())
              );
            case 'all':
            default:
              return isInvoice(item)
                ? getInvoiceNumber(item as Invoice)
                    .toLowerCase()
                    .includes(search.toLowerCase())
                : getCreditNoteNumber(item as CreditNote)
                    .toLowerCase()
                    .includes(search.toLowerCase());
          }
        })
        ?.filter((item) => {
          const startsAtDate = newMarketDate(item.context?.startsAt ?? 0);
          const endsAtDate = subSeconds(newMarketDate(item.context?.endsAt ?? 0), 1);

          const fromDate = newMarketDate(new Date(from).getTime());
          const toDate = subSeconds(newMarketDate(new Date(to).getTime()), 1);

          return (
            (isAfter(startsAtDate, fromDate) || isSameDay(startsAtDate, fromDate)) &&
            (isBefore(endsAtDate, toDate) || isSameDay(endsAtDate, toDate))
          );
        })
    );
  }, [combinedItems, activityType, search, from, to]);

  return (
    <div>
      <div className="table-filter apl-display-flex apl-flex-row apl-align-items-center apl-justify-content-between">
        <div
          className="apl-display-flex apl-flex-row apl-align-items-center"
          style={{
            paddingLeft: '5px',
          }}
        >
          <div className="apl-field-v1 apl-display-flex apl-flex-row apl-align-items-center apl-mr">
            <label className="apl-field__label apl-mr apl-mb-none" htmlFor="activity-type-field">
              Show
            </label>
            <select
              className="apl-select-v1_0"
              id="activity-type-field"
              name="activity-type"
              onChange={(e) => setActivityType(e.target.value as ActivityType)}
              value={activityType}
            >
              <option value="all">All activity</option>
              <option value="credit-notes">Credit notes</option>
              <option value="invoices">Invoices</option>
              <option value="bcti">BCTI</option>
              {cfd866OnBillFinance && <option value="finance-invoices">Finance invoices</option>}
            </select>
          </div>
          <div className="apl-field-v1 apl-display-flex apl-flex-row apl-align-items-center apl-mr">
            <label className="apl-field__label apl-mr apl-mb-none" htmlFor="from-field">
              from
            </label>
            <select
              className="apl-select-v1_0"
              data-testid="from date filter dropdown"
              id="from-field"
              name="from"
              onChange={(e) => setFrom(e.target.value)}
              value={from}
            >
              {fromOptions.length > 0 &&
                fromOptions.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
            </select>
          </div>
          <div className="apl-field-v1 apl-display-flex apl-flex-row apl-align-items-center apl-mr">
            <label className="apl-field__label apl-mr apl-mb-none" htmlFor="to-field">
              to
            </label>
            <select
              className="apl-select-v1_0"
              data-testid="to date filter dropdown"
              id="to-field"
              name="to"
              onChange={(e) => setTo(e.target.value)}
              value={to}
            >
              {toOptions.length > 0 &&
                toOptions.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
            </select>
          </div>
        </div>
        <div style={{ paddingRight: '5px' }}>
          <div className="search-filter">
            <input
              className="apl-text-input-v1 search-filter__input"
              onChange={(e) => setSearch(e.target.value)}
              placeholder="Find an invoice"
              type="text"
              value={search}
            />
            <img alt="search icon" className="search-filter__icon" src={searchIcon} />
          </div>
        </div>
      </div>
      <table
        className="apl-table-v1 apl-width-full is-plain zebra-table"
        style={{
          borderRadius: 0,
        }}
      >
        {(invoiceDataLoading || creditNoteDataLoading) && (
          <tbody>
            <tr>
              <td>Loading...</td>
            </tr>
          </tbody>
        )}
        {(invoiceDataError || creditNoteDataError) && (
          <EmptyTableRow message="Sorry, there was an error" />
        )}
        {combinedItemsFiltered && combinedItemsFiltered.length > 0 && (
          <>
            <thead>
              <tr>
                <th>Name</th>
                <th>Type</th>
                <th>Date created</th>
                <th>Billing period</th>
                <th>Total amount</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {combinedItemsFiltered
                ?.sort((a, b) => {
                  const dateA = a?.context?.startsAt
                    ? newMarketDate(new Date(a.context.startsAt).getTime())
                    : newMarketDate(new Date(0).getTime());
                  const dateB = b?.context?.startsAt
                    ? newMarketDate(new Date(b.context.startsAt).getTime())
                    : newMarketDate(new Date(0).getTime());

                  return isAfter(dateA, dateB) ? -1 : 1;
                })
                ?.map((item, index) =>
                  isInvoice(item) ? (
                    <InvoiceRow
                      key={`${(item as Invoice).data.invoiceNumber}-${index}`}
                      index={index + 1}
                      connection={connection.id}
                      period={period}
                      {...(item as Invoice)}
                    />
                  ) : (
                    <CreditNoteRow
                      key={`credit-note-${(item as CreditNote).invoiceNumber}-${index}`}
                      index={index + 1}
                      connection={connection.id}
                      {...(item as CreditNote)}
                    />
                  )
                )}
            </tbody>
          </>
        )}
        {(!combinedItemsFiltered || combinedItemsFiltered.length === 0) && (
          <EmptyTableRow message={`Sorry, there are no ${activityTypeNice} for this period.`} />
        )}
      </table>
    </div>
  );
};

export default InvoicesComponent;
