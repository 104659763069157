import React, { FunctionComponent } from 'react';
import { formatISO } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import { ErrorModel } from '../../api/ErrorDisplay';

import { createCharge, createRange, MonthSpotPercentage } from './easi/easiUtils';
import { sanitiseChargeBody, usePlanCreationV2 } from './planApi';
import { Charge, Plan, PlanBody } from 'api/openapi/rating-config';
import PlanForm from './PlanForm';
import { useListData } from 'list-data/ListDataContext';
import { ChargeTypesConfig } from 'list-data/charge-types/chargeTypesConfigUtils';

interface AddPlanProps {
  close: () => void;
  selectedProviderId: string | undefined;
}

const createEasi144Charges = (
  startDate: string,
  endDate: string | undefined,
  chargeTypesConfig: ChargeTypesConfig
): Charge[] => {
  const monthlySpot: MonthSpotPercentage = {};
  const range = createRange(startDate, endDate);

  range.forEach((date) => {
    const dateAsString = formatISO(date, { representation: 'date' });

    // make sure the monthly spot prices have initial value 0
    monthlySpot[dateAsString] = '0';
  });

  const easi144Charge = sanitiseChargeBody(
    createCharge(startDate, '', monthlySpot, startDate, endDate),
    chargeTypesConfig
  );

  return [easi144Charge] as Charge[];
};

const AddPlan: FunctionComponent<AddPlanProps> = ({ close, selectedProviderId }) => {
  const navigate = useNavigate();

  const [chargeTypesList] = useListData(['CHARGE_TYPES']);
  const chargeTypesConfig: ChargeTypesConfig = chargeTypesList?.data as ChargeTypesConfig;

  const { mutateAsync, isLoading: isSubmitting, error } = usePlanCreationV2();

  const createPlan = async (plan: PlanBody) => {
    switch (plan.structure) {
      case 'EASI_144':
        plan.charges = createEasi144Charges(plan.startDate, plan?.endDate, chargeTypesConfig);
        break;
      default:
        plan.charges = [];
    }

    try {
      const { id } = await mutateAsync(plan as Plan);

      if (id) {
        navigate(`/plans/${id}`);
      }
    } catch (e: any) {
      console.error(e);
    }
  };

  return (
    <div className="apl-form-layout-v1" data-testid="add-plan-form">
      <h2>Add plan</h2>

      <PlanForm
        selectedProviderId={selectedProviderId}
        isUpdating={isSubmitting}
        submitFunction={createPlan}
        close={close}
        error={error as ErrorModel}
      />
    </div>
  );
};

export default AddPlan;
