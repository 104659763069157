/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DiscountVariations } from '../models/DiscountVariations';
import type { Error } from '../models/Error';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class DiscountVariationsService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}


    /**
     * Get the discount variation with the specified scope.
     * @param scopedTo The scope of the discount-variation to get.
     * @returns DiscountVariation OK
     * @returns Error Error
     * @throws ApiError
     */
    public getDiscountVariations(scopedTo: string): CancelablePromise<DiscountVariations | Error> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/billing/discount-variations',
            query: {
                'scopedTo': scopedTo,
            },
        });
    }


}
