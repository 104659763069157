import React, { useContext } from 'react';

export type AppUpdateContextProps = {
  isUpdateAvailable: boolean;
  updateAssets: React.MouseEventHandler<HTMLButtonElement>;
};

const defaultAppUpdateContextProps: AppUpdateContextProps = {
  isUpdateAvailable: false,
  updateAssets: () => {
    // do nothing
  },
};

export type AppUpdateContextType = AppUpdateContextProps | undefined;

export const AppUpdateContext = React.createContext<AppUpdateContextProps | undefined>(
  defaultAppUpdateContextProps
);

export const useAppUpdate = (): AppUpdateContextProps => {
  const context = useContext(AppUpdateContext);
  if (context === undefined) {
    throw new Error('useAppUpdate must be used within a AppUpdateProvider');
  }
  return context;
};
