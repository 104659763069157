/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type RangeToken = {
    tokenType: RangeToken.tokenType;
};

export namespace RangeToken {

    export enum tokenType {
        RANGE = 'Range',
        OPERATOR = 'Operator',
        MULTI_RANGE_ITEM = 'MultiRangeItem',
    }


}

