import { useTranslation } from 'react-i18next';

export type CurrencyUnitGrammaticalNumber = {
  singular: string;
  plural: string;
};

export type CurrencyUnit = {
  major: CurrencyUnitGrammaticalNumber;
  minor: CurrencyUnitGrammaticalNumber;
};

/**
 * Hook that builds on the i18n translation hook useTranslation
 * to return the currency units for a specific monetary system.
 *
 * @returns {CurrencyUnit} an object containing the currency units for a monetary system
 */
export default function useCurrencyUnitTranslation(): CurrencyUnit {
  const { t: translate } = useTranslation();

  const currencyUnit: CurrencyUnit = translate('monetary.currencyUnit', { returnObjects: true });

  return currencyUnit;
}
