/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Indicates if the data provided is raw or already loss adjusted
 */
export type DataFidelity = {
    validFrom: string;
    validTo?: string;
    fidelityType: DataFidelity.fidelityType;
};

export namespace DataFidelity {

    export enum fidelityType {
        URN_FLUX_NETWORK_CONNECTION_DATA_FIDELITY_RAW = 'urn:flux:network:connection:data_fidelity:raw',
        URN_FLUX_NETWORK_CONNECTION_DATA_FIDELITY_LOSS_ADJUSTED = 'urn:flux:network:connection:data_fidelity:loss_adjusted',
    }


}

