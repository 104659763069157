import React, { useState } from 'react';
import Modal from 'react-modal';
import { useParams } from 'react-router-dom';
import { usePlanSummary } from './planSummaryApi';
import PlanSummaryList from './PlanSummaryList';
import AuthorizedAction from '../common/AuthorizedAction';
import { modalStyles } from '../layout/Modal';
import Page, { ErrorPage, LoadingPage, PageHeader } from '../layout/Page';
import AddPlan from '../plans/AddPlan';
import { Permission } from '../../auth/getPermissions';

export default () => {
  const { providerId } = useParams<{ providerId: string }>();
  const {
    data,
    isInitialLoading: isLoading,
    isError,
  } = usePlanSummary(providerId, { enabled: !!providerId });

  const [addPlanIsOpen, setIsOpen] = useState(false);
  const closeAddPlan = () => setIsOpen(false);
  const openAddPlan = () => setIsOpen(true);
  if (isLoading) {
    return <LoadingPage />;
  }

  if (isError) {
    return <ErrorPage />;
  }

  return (
    <>
      <PageHeader
        backLink="/providers"
        title={data && data.providerName ? `${data.providerName} plans` : ''}
      >
        <AuthorizedAction
          extraClasses="is-primary"
          onClick={() => openAddPlan()}
          permission={Permission.PLAN_EDIT}
        >
          Add plan
        </AuthorizedAction>
      </PageHeader>
      <Page>
        {data?.planSummaries.length > 0 ? (
          <PlanSummaryList planSummaries={data.planSummaries} />
        ) : (
          <div>
            <h3>No Plans</h3>
          </div>
        )}

        <Modal
          isOpen={addPlanIsOpen}
          onRequestClose={closeAddPlan}
          shouldCloseOnOverlayClick={false}
          style={modalStyles}
        >
          <AddPlan selectedProviderId={providerId} close={closeAddPlan} />
        </Modal>
      </Page>
    </>
  );
};
