import React from 'react';
import Page from './Page';

const NotAuthorised = () => (
  <Page>
    <p>Sorry, you do not have permission to view this page.</p>
  </Page>
);

export default NotAuthorised;
