import React from 'react';
import { useParams } from 'react-router-dom';
import '../dashboard/missing-plans/SearchFilter.scss';
import { ErrorPage, LoadingPage } from '../layout/Page';
import { Provider, useProviders } from './providersApi';
import LossFactorCodes from './LossFactorCodes';

const LossFactorCodesProvider = () => {
  const { providerId } = useParams<{ providerId: string }>();

  const {
    data: providerData,
    isInitialLoading: providerDataLoading,
    isError: providerDataError,
  } = useProviders();

  const provider = providerData && providerData.find((p: Provider) => p.id === providerId);
  const providerName = provider?.name ?? providerId;
  const providerCodes = provider?.code;

  if (providerDataLoading) {
    return <LoadingPage />;
  }

  if (providerDataError) {
    return <ErrorPage />;
  }

  return (
    <LossFactorCodes
      backLink={`/providers`}
      title={`${providerName} loss factor codes`}
      lossFactorKeyOptions={[]}
      isProviderSet={true}
      providerCodes={providerCodes}
    />
  );
};

export default LossFactorCodesProvider;
