import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { SupplyPeriodSummary, useConnectionDetails } from './connectionsApi';
import Page, { ErrorPage, LoadingPage, PageHeader } from '../layout/Page';
import { differenceInDays, format } from 'date-fns';
import Card from '../layout/Card';
import EmptyTableRow from '../common/EmptyTableRow';
import { groupBy, sortByDate } from '../../util/helper-func';
import apiClient from '../../api/apiClient';
import { Invoice } from 'components/billing/billingInvoicesApi';

interface ContractedParty {
  ownerId: string;
  contractedParty: string;
  startDate: string;
  endDate: string;
}

/**
 * This selector page gets called in case someone uses the connection/{id} instead of the
 * connection/{id}/contracted-party/{id} URL. This is to make sure that the user get the
 * chosen contracted party in the ConnectionDashboard displayed. This avoids privacy issues.
 * 1. Load all SupplyPeriods (includes the owner id = contractedParty)
 * 2. Group all SupplyPeriods by owner id if they are adjacent and get the earliest start date and the latest end date
 * 2. If there is only one owner with continuous SupplyPeriod(s) then redirect to ConnectionDashboard
 * 3. Otherwise, sort list with the newest date on top and display list
 */
const ContractedPartySelector = () => {
  const [contractedParties, setContractedParties] = useState<ContractedParty[]>();
  const [supplyPeriodData, setSupplyPeriodData] = useState<SupplyPeriodSummary[]>();
  const [supplyPeriodLoading, setSupplyPeriodLoading] = useState(true);
  const [supplyPeriodError, setSupplyPeriodError] = useState(false);

  const navigate = useNavigate();

  // get connection id from path
  const { id } = useParams<{ id: string }>();

  // load connection data to get the none UUID connection id
  const {
    data: connectionData,
    isError: connectionDataError,
    isInitialLoading: connectionDataLoading,
  } = useConnectionDetails(id, { enabled: !!id });

  // load all SupplyPeriods for this connection and update contracted parties
  useEffect(() => {
    const loadSupplyPeriods = async () => {
      const url = `/connections/${id}/supply-periods`;

      try {
        const supplyPeriods = await apiClient().get(url);
        let supplyPeriodSummaries: SupplyPeriodSummary[] = supplyPeriods.supplyPeriodSummaries;

        supplyPeriodSummaries = await filterCancelledSupplyPeriodsWithoutInvoice(
          supplyPeriodSummaries
        );

        const supplyPeriodWith = await Promise.all(
          supplyPeriodSummaries.map(async (supplyPeriod: SupplyPeriodSummary) => {
            let supplyPeriodPresentation = null;
            if (supplyPeriod.externalSupplyAgreementId) {
              supplyPeriodPresentation = await apiClient('presentation').get(
                `/external-billing/v1/presentation/${supplyPeriod.externalSupplyAgreementId}`
              );
            }
            return {
              ...supplyPeriod,
              contractedParty: supplyPeriodPresentation?.parties?.owner?.name,
            };
          })
        );
        setSupplyPeriodData(supplyPeriodWith);
        setSupplyPeriodLoading(false);
      } catch (err) {
        console.error(err); // todo: needs a better error handling?
        setSupplyPeriodError(true);
      }
    };
    loadSupplyPeriods();
  }, [id]);

  // prepare contracted parties for display
  useEffect(() => {
    if (supplyPeriodData?.length) {
      // group supply periods by owner
      const groupedByOwner = groupBy(supplyPeriodData, (x: SupplyPeriodSummary) => x.owner);

      // extract the earliest start and latest end date per owner id
      let grouped: ContractedParty[] = [];
      groupedByOwner.forEach(
        (supplyPeriods, ownerId) =>
          (grouped = grouped.concat(createContractedParty(ownerId, supplyPeriods)))
      );

      const sortedGrouped = grouped.sort((groupA: ContractedParty, groupB: ContractedParty) =>
        sortByDate(groupA.endDate, groupB.endDate, false)
      );

      setContractedParties(sortedGrouped);
    }
  }, [supplyPeriodData?.toString()]);

  useEffect(() => {
    // cut the rest and jump immediately, do not remember this URL in the history otherwise goBack might not work.
    if (contractedParties && isOnlyOneContractedParty(contractedParties)) {
      navigate(`/connections/${id}/contracted-parties/${contractedParties[0].ownerId}`, {
        replace: true,
      });
    }
  }, [contractedParties]);

  if (connectionDataError || supplyPeriodError) {
    return <ErrorPage />;
  }

  if (connectionDataLoading || supplyPeriodLoading) {
    return <LoadingPage />;
  }

  return (
    <>
      <PageHeader
        title={
          connectionData && `Contracted parties for Connection: ${connectionData[0].connectionId}`
        }
      ></PageHeader>
      <Page>
        <Card>
          <table className="apl-table-v1 half_xl">
            <thead>
              <tr>
                <th>Contracted Party</th>
                <th>Start Date</th>
                <th>End Date</th>
              </tr>
            </thead>
            {!contractedParties ? (
              <EmptyTableRow message="Sorry, connection or no contracted parties found." />
            ) : (
              <tbody>
                {contractedParties.map((cp: ContractedParty, index: number) => (
                  <tr key={index}>
                    <td>
                      {cp.ownerId ? (
                        <Link
                          className="apl-color-primary"
                          data-testid={`view-connection-${index + 1}`}
                          to={`/connections/${id}/contracted-parties/${cp.ownerId}`}
                        >
                          {cp.contractedParty}
                        </Link>
                      ) : (
                        `${cp.contractedParty} - Can't link to Connection Dashboard, contracted party id is missing`
                      )}
                    </td>
                    <td>{cp.startDate ? format(new Date(cp.startDate), 'd MMM yyyy') : ''}</td>
                    <td>{cp.endDate ? format(new Date(cp.endDate), 'd MMM yyyy') : ''}</td>
                  </tr>
                ))}
              </tbody>
            )}
          </table>
        </Card>
      </Page>
    </>
  );
};

export default ContractedPartySelector;

/**
 * Creates a ContractedParty object by extracting data from the given SupplyPeriods.
 * @param ownerId
 * @param supplyPeriods
 */
const createContractedParty = (
  ownerId: string,
  supplyPeriods: SupplyPeriodSummary[]
): ContractedParty[] => {
  // sort supply periods by endDate
  const sortedSupplyPeriods = supplyPeriods.sort(
    (spA: SupplyPeriodSummary, spB: SupplyPeriodSummary) =>
      sortByDate(spA.endDate, spB.endDate, false)
  );

  // merge supplyPeriods dates if possible
  const mergedSupplyPeriods: ContractedParty[] = [];
  let contractedParty: ContractedParty | null = null;

  for (const sp of sortedSupplyPeriods) {
    if (!contractedParty) {
      contractedParty = getContractedParty(sp, ownerId);
    } else {
      const diff = differenceInDays(new Date(contractedParty.startDate), new Date(sp.endDate));
      if (diff > 1) {
        mergedSupplyPeriods.push({ ...contractedParty });
        contractedParty = getContractedParty(sp, ownerId);
      } else {
        contractedParty.startDate = sp.startDate;
      }
    }
  }
  // don't forget the last one
  if (contractedParty) {
    mergedSupplyPeriods.push(contractedParty);
  }

  return mergedSupplyPeriods;
};

/**
 * Helper to create a ContractedParty object from the given arguments
 * @param supplyPeriod
 * @param ownerId
 */
const getContractedParty = (supplyPeriod: SupplyPeriodSummary, ownerId: string) => {
  return {
    contractedParty: supplyPeriod.contractedParty,
    ownerId,
    startDate: supplyPeriod.startDate,
    endDate: supplyPeriod.endDate,
  };
};

/**
 * Returns true in case the given array contains only one contracted party
 * @param contractedParties
 */
const isOnlyOneContractedParty = (contractedParties: ContractedParty[]) => {
  const unique = new Set(contractedParties.map((item) => item.ownerId));
  return unique.size === 1;
};

/**
 * This method is used to filter cancelled supply periods without any invoices
 * @param supplyPeriodSummaries
 * @returns
 */
const filterCancelledSupplyPeriodsWithoutInvoice = async (
  supplyPeriodSummaries: SupplyPeriodSummary[]
) => {
  // collect all externalSupplyAgreementIds for cancelled supply periods
  const externalSupplyAgreementIds: string[] = supplyPeriodSummaries
    .filter(
      (period: SupplyPeriodSummary) =>
        period.externalSupplyAgreementId && period.status === 'CANCELLED'
    )
    .map((period: SupplyPeriodSummary) => period.externalSupplyAgreementId);

  try {
    if (externalSupplyAgreementIds?.length) {
      // get invoices for all the cancelled supply periods
      const search = new URLSearchParams();
      search.set('limit', '100000');
      externalSupplyAgreementIds?.forEach((id) => search.append('externalSupplyAgreementIds', id));
      const invoicesData = await apiClient('billing').get(`/billing/invoices?${search.toString()}`);

      supplyPeriodSummaries = supplyPeriodSummaries.filter(
        (supplyPeriod: SupplyPeriodSummary) =>
          // include active supply periods
          supplyPeriod.status === 'VALID' ||
          // otherwise if supply period is cancelled, include it if there is an invoice
          invoicesData.items.find(
            (invoice: Invoice) =>
              invoice.data?.externalSupplyAgreementId === supplyPeriod.externalSupplyAgreementId
          )
      );
    }
  } catch (error) {
    console.error('Error getting biling invoice data', error);
  }

  return supplyPeriodSummaries;
};
