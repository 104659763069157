/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BaseHttpRequest } from './core/BaseHttpRequest';
import type { OpenAPIConfig } from './core/OpenAPI';
import { AxiosHttpRequest } from './core/AxiosHttpRequest';

import { AccountConfigurationService } from './services/AccountConfigurationService';
import { ApiDocsService } from './services/ApiDocsService';
import { BillingPartyService } from './services/BillingPartyService';
import { BillingReadinessService } from './services/BillingReadinessService';
import { ChargesService } from './services/ChargesService';
import { CreditNoteService } from './services/CreditNoteService';
import { InvoiceService } from './services/InvoiceService';
import { MonitoringService } from './services/MonitoringService';
import { SummaryMetricsService } from './services/SummaryMetricsService';
import { SupplyAgreementConfigurationService } from './services/SupplyAgreementConfigurationService';
import { TestCacheEntriesService } from './services/TestCacheEntriesService';
import { TestCreditNoteService } from './services/TestCreditNoteService';
import { TestInvoiceService } from './services/TestInvoiceService';
import { TestSupplyAgreementService } from './services/TestSupplyAgreementService';
import { TestSupplyAgreementAttributesService } from './services/TestSupplyAgreementAttributesService';
import { TestSupplyAgreementDiscountsService } from './services/TestSupplyAgreementDiscountsService';
import {DiscountVariationsService} from "./services/DiscountVariationsService";

type HttpRequestConstructor = new (config: OpenAPIConfig) => BaseHttpRequest;

export class BillingLifecycleApiClient {

    public readonly accountConfiguration: AccountConfigurationService;
    public readonly apiDocs: ApiDocsService;
    public readonly billingParty: BillingPartyService;
    public readonly billingReadiness: BillingReadinessService;
    public readonly charges: ChargesService;
    public readonly creditNote: CreditNoteService;
    public readonly discountVariations: DiscountVariationsService;
    public readonly invoice: InvoiceService;
    public readonly monitoring: MonitoringService;
    public readonly summaryMetrics: SummaryMetricsService;
    public readonly supplyAgreementConfiguration: SupplyAgreementConfigurationService;
    public readonly testCacheEntries: TestCacheEntriesService;
    public readonly testCreditNote: TestCreditNoteService;
    public readonly testInvoice: TestInvoiceService;
    public readonly testSupplyAgreement: TestSupplyAgreementService;
    public readonly testSupplyAgreementAttributes: TestSupplyAgreementAttributesService;
    public readonly testSupplyAgreementDiscounts: TestSupplyAgreementDiscountsService;

    public readonly request: BaseHttpRequest;

    constructor(config?: Partial<OpenAPIConfig>, HttpRequest: HttpRequestConstructor = AxiosHttpRequest) {
        this.request = new HttpRequest({
            BASE: config?.BASE ?? 'http://localhost:8093',
            VERSION: config?.VERSION ?? '1',
            WITH_CREDENTIALS: config?.WITH_CREDENTIALS ?? false,
            CREDENTIALS: config?.CREDENTIALS ?? 'include',
            TOKEN: config?.TOKEN,
            USERNAME: config?.USERNAME,
            PASSWORD: config?.PASSWORD,
            HEADERS: config?.HEADERS,
            ENCODE_PATH: config?.ENCODE_PATH,
        });

        this.accountConfiguration = new AccountConfigurationService(this.request);
        this.apiDocs = new ApiDocsService(this.request);
        this.billingParty = new BillingPartyService(this.request);
        this.discountVariations = new DiscountVariationsService(this.request);
        this.billingReadiness = new BillingReadinessService(this.request);
        this.charges = new ChargesService(this.request);
        this.creditNote = new CreditNoteService(this.request);
        this.invoice = new InvoiceService(this.request);
        this.monitoring = new MonitoringService(this.request);
        this.summaryMetrics = new SummaryMetricsService(this.request);
        this.supplyAgreementConfiguration = new SupplyAgreementConfigurationService(this.request);
        this.testCacheEntries = new TestCacheEntriesService(this.request);
        this.testCreditNote = new TestCreditNoteService(this.request);
        this.testInvoice = new TestInvoiceService(this.request);
        this.testSupplyAgreement = new TestSupplyAgreementService(this.request);
        this.testSupplyAgreementAttributes = new TestSupplyAgreementAttributesService(this.request);
        this.testSupplyAgreementDiscounts = new TestSupplyAgreementDiscountsService(this.request);
    }
}

