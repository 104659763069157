import React, { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';

import { DateData } from './missingPlansUtil';
import { ChargeTag } from '../../connections/connectionsApi';
import { useMissingPlansSummary } from '../../reports/reportsApi';

import './Tabs.scss';

interface TabProps {
  chargeTag: ChargeTag;
  date: DateData;
  month: string | undefined;
  attributes: string[];
}

const Tab = ({ chargeTag, date, month, attributes }: TabProps) => {
  const {
    data,
    isError,
    isInitialLoading: isLoading,
  } = useMissingPlansSummary(date.firstDOM, date.lastDOM, chargeTag, attributes);
  return (
    <li className="tabs__list-item" key={`month-tab-${date.month}`}>
      <Link
        className={
          date.monthShort === month ? 'tabs__list-link tabs__list-link--active' : 'tabs__list-link'
        }
        to={`../${date.monthShort}`}
        relative="path"
      >
        {date.month} (
        {isError
          ? '?'
          : isLoading
          ? '...'
          : data?.missingPlans && data?.missingPlans[0]?.results?.totalConnections}
        )
      </Link>
    </li>
  );
};

interface ConnectionsMissingPlanTabsProps {
  chargeTag: ChargeTag;
  dates: DateData[];
  month: string | undefined;
  attributes: string[];
}

const ConnectionsMissingPlanTabs: FunctionComponent<ConnectionsMissingPlanTabsProps> = ({
  chargeTag,
  dates,
  month,
  attributes,
}) => {
  return (
    <nav className="tabs card__inner">
      <ul className="tabs__list">
        {dates.map((date) => (
          <Tab
            chargeTag={chargeTag}
            date={date}
            key={date.monthShort}
            month={month}
            attributes={attributes}
          />
        ))}
      </ul>
    </nav>
  );
};

export default ConnectionsMissingPlanTabs;
