/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreditNote } from '../models/CreditNote';
import type { CreditNoteResult } from '../models/CreditNoteResult';
import type { Error } from '../models/Error';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class CreditNoteService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @param limit
     * @param offset
     * @param externalSupplyAgreementId The external Supply Agreement ID
     * @param startsAt
     * @param endsAt
     * @returns CreditNoteResult OK
     * @returns Error Error
     * @throws ApiError
     */
    public getCreditNotes(
        limit: number = 20,
        offset?: number,
        externalSupplyAgreementId?: string,
        startsAt?: string,
        endsAt?: string,
    ): CancelablePromise<CreditNoteResult | Error> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/billing/credit-notes',
            query: {
                'limit': limit,
                'offset': offset,
                'externalSupplyAgreementId': externalSupplyAgreementId,
                'startsAt': startsAt,
                'endsAt': endsAt,
            },
        });
    }

    /**
     * @param id
     * @returns CreditNote OK
     * @returns Error Error
     * @throws ApiError
     */
    public getCreditNoteById(
        id: string,
    ): CancelablePromise<CreditNote | Error> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/billing/credit-notes/{id}',
            path: {
                'id': id,
            },
        });
    }

}
