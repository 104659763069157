import { format } from 'date-fns';
import React, { FunctionComponent, useState } from 'react';
import { Link } from 'react-router-dom';
import SideNav from './SideNav';
import FluxLogo from '../../flux-styles/flux-logo-white.svg';
import './Header.scss';
import { isDev, isLocal, isProd, isTest } from 'environment';
import getConfig from 'config/getConfig';

const Header: FunctionComponent = () => {
  const [showNav, setShowNav] = useState(false);
  const uiConfig = getConfig();
  const backgroundColour = isProd()
    ? 'red'
    : isTest()
    ? 'orange'
    : isLocal()
    ? 'green'
    : isDev()
    ? 'blue'
    : 'black';
  return (
    <header className="header">
      <SideNav closeNav={() => setShowNav(false)} showNav={showNav} />
      <div className="header__left">
        <div
          className={
            showNav ? 'header__menu-button header__menu-button--active' : 'header__menu-button'
          }
          data-testid="menu-button"
          onClick={() => setShowNav(!showNav)}
          role="button"
        >
          Menu
        </div>
      </div>
      <div className="header__center">
        <Link className="apl-align-items-center apl-display-flex" to={'/'}>
          <img data-testid="flux-logo" alt="flux logo" src={FluxLogo} />
        </Link>
      </div>
      <div className="header__right" style={{ alignItems: 'center' }}>
        {uiConfig.client == 'merx' ? (
          <svg
            fill="none"
            height="30"
            viewBox="0 0 30 30"
            width="30"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="15" cy="15" fill="#00edb5" r="15"></circle>
            <g fill="#0033a1">
              <path d="m20.844 14.4701 3.1565 3.961-1.9186 1.4854-4.5799-5.7559h2.785c.2476 0 .4333.1238.557.3095z"></path>
              <path d="m18.7405 18.6779-1.7329-2.1662-1.6092 7.1793 2.3519.5571 1.114-4.8894c.0619-.2476 0-.4951-.1238-.6808z"></path>
              <path d="m14.2231 20.2876.6189-2.7231-6.62227 3.1564 1.05214 2.228 4.51803-2.1661c.2475-.1238.3713-.3095.4332-.4952z"></path>
              <path d="m10.1369 17.7497 2.5375-1.2378-6.62226-3.1564-1.05214 2.228 4.51802 2.1662c.18567.0619.43323.0619.61888 0z"></path>
              <path d="m9.57973 12.984 2.53757 1.2378-1.6092-7.17932-2.35185.55702 1.11403 4.8894c0 .1856.12378.3713.30945.4951z"></path>
              <path d="m12.9823 9.57991.6189 2.72319 4.5799-5.75583-1.9186-1.54727-3.1564 3.961c-.1238.18567-.1857.43324-.1238.61891z"></path>
              <path d="m17.7495 10.1367-1.7329 2.1662h7.3031v-2.41374h-5.075c-.1238 0-.3714.06189-.4952.24754z"></path>
            </g>
          </svg>
        ) : uiConfig.client == 'blue' ? (
          <svg
            fill="white"
            height="30"
            viewBox="0 0 30 30"
            width="30"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g transform="scale(0.35)">
              <path
                d="M45.263 0.063c-0.771-0.041-1.548-0.063-2.331-0.063C19.221 0 0 19.222 0 42.933c0 17.723 10.738 32.937 26.064 39.491l12.058-33.205c0.296-0.817-0.309-1.682-1.179-1.681H16.16c-0.906 0-1.513-0.933-1.146-1.762L45.263 0.063zm40.602 42.87c0-17.708-10.72-32.911-26.025-39.475L47.894 31.004c-0.392 0.831 0.215 1.787 1.134 1.787h0.001l13.56-0.005c1.028 0 1.619 1.169 1.01 1.997l-35.409 48.124c4.874 1.923 10.185 2.979 15.742 2.979 23.711 0 42.933-19.222 42.933-42.933"
                fill="#59caef"
              />
            </g>
          </svg>
        ) : (
          <></>
        )}
        <div
          style={{
            backgroundColor: backgroundColour,
            display: 'flex',
            margin: 10,
            padding: 10,
            alignItems: 'center',
            textAlign: 'right',
            justifyContent: 'center',
            color: 'white',
            borderRadius: 25,
            fontWeight: 600,
          }}
        >
          <h1>
            {isProd()
              ? 'You are in Prod'
              : isTest()
              ? 'You are in Test'
              : isLocal()
              ? 'You are running Locally'
              : isDev()
              ? 'You are in Sandbox'
              : 'I dont know what your running this in'}
          </h1>
        </div>
        {isLocal() || isDev() ? (
          <a
            className="header__date-time"
            href={
              'https://git.fluxfederation.com/powerapps/commercial-industrial/-/environments?page=1&scope=available'
            }
            target="_blank"
          >
            Sandbox Deployment Info
          </a>
        ) : (
          <></>
        )}
        <div className="header__date-time">{format(new Date(), 'dd MMM yyyy')}</div>
      </div>
    </header>
  );
};

export default Header;
