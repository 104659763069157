/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { Charge } from './Charge';
import type { PlanScope } from './PlanScope';
import type { PlanState } from './PlanState';
import { PlanTagReference } from './PlanTagReference';

export type PlanBody = {
    /**
     * Must be unique among Plans with the same Provider.
     */
    planName: string;
    /**
     * Must be a valid ID of an existing Provider.
     */
    providerId: string;
    shareable: boolean;
    structure: PlanBody.structure;
    /**
     * The jurisdiction within which this plan is effective.
     */
    jurisdiction?: string;
    planState?: PlanState;
    planScope?: PlanScope;
    /**
     * If PlanScope is SHARED, then the providerReference must be unique among Plans that have the same Provider and have overlapping dates.
     */
    providerReference?: string;
    startDate: string;
    /**
     * If end date is provided, it must be after start date.
     */
    endDate?: string;
    charges?: Array<Charge>;
    /**
     * Timezone being used in the Market.
     */
    timezone?: string;
    tags?: PlanTagReference[]
};

export namespace PlanBody {

    export enum structure {
        SIMPLE_CHARGE = 'SIMPLE_CHARGE',
        EASI_144 = 'EASI_144',
        EASI_48 = 'EASI_48',
    }


}

