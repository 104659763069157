/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BaseSupplyAgreementConfiguration } from '../models/BaseSupplyAgreementConfiguration';
import type { Error } from '../models/Error';
import type { SupplyAgreementConfiguration } from '../models/SupplyAgreementConfiguration';
import type { SupplyAgreementConfigurationsResult } from '../models/SupplyAgreementConfigurationsResult';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class SupplyAgreementConfigurationService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @param limit
     * @param offset
     * @param account
     * @param connectionId
     * @param externalConnectionId
     * @returns SupplyAgreementConfigurationsResult OK
     * @returns Error Error
     * @throws ApiError
     */
    public getSupplyAgreements(
        limit: number = 20,
        offset?: number,
        account?: string,
        connectionId?: string,
        externalConnectionId?: string,
    ): CancelablePromise<SupplyAgreementConfigurationsResult | Error> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/billing/configuration/supply_agreements',
            query: {
                'limit': limit,
                'offset': offset,
                'account': account,
                'connectionId': connectionId,
                'externalConnectionId': externalConnectionId,
            },
        });
    }

    /**
     * @param id
     * @returns SupplyAgreementConfiguration OK
     * @returns Error Error
     * @throws ApiError
     */
    public getSupplyAgreementsById(
        id: string,
    ): CancelablePromise<SupplyAgreementConfiguration | Error> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/billing/configuration/supply_agreements/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id
     * @param requestBody
     * @returns SupplyAgreementConfiguration OK
     * @returns Error Error
     * @throws ApiError
     */
    public putSupplyAgreementsById(
        id: string,
        requestBody: BaseSupplyAgreementConfiguration,
    ): CancelablePromise<SupplyAgreementConfiguration | Error> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/billing/configuration/supply_agreements/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
