import React, { FunctionComponent, useState } from 'react';
import Modal from 'react-modal';
import AuthorizedAction from '../common/AuthorizedAction';
import { modalStyles } from '../layout/Modal';
import { Permission } from '../../auth/getPermissions';
import { useDeleteObservance } from './observancesApi';
import { Form, Formik } from 'formik';

interface RemoveHolidayProps {
  observanceId: string;
}

const RemoveHoliday: FunctionComponent<RemoveHolidayProps> = ({ observanceId }) => {
  const [removeHolidayIsOpen, setRemoveHolidayIsOpen] = useState(false);

  const closeRemoveHoliday = () => setRemoveHolidayIsOpen(false);
  const openRemoveHoliday = () => setRemoveHolidayIsOpen(true);

  const { mutateAsync } = useDeleteObservance();

  const handleDelete = async () => {
    const { id } = await mutateAsync({ observanceId });

    if (id) {
      closeRemoveHoliday();
    }
  };

  return (
    <>
      <AuthorizedAction
        onClick={() => {
          openRemoveHoliday();
        }}
        permission={Permission.OBSERVANCE_EDIT}
      >
        Remove holiday
      </AuthorizedAction>
      <Modal
        isOpen={removeHolidayIsOpen}
        onRequestClose={closeRemoveHoliday}
        style={modalStyles}
        shouldCloseOnOverlayClick={false}
      >
        <Formik initialValues={{}} onSubmit={() => handleDelete()}>
          {() => (
            <Form className="apl-form-layout-v1">
              <div>Are you sure you want to delete this holiday?</div>
              <div className="apl-display-flex apl-justify-content-end">
                <button className="apl-button-v1" onClick={closeRemoveHoliday} type={'button'}>
                  Cancel
                </button>
                <AuthorizedAction
                  extraClasses="is-primary"
                  permission={Permission.OBSERVANCE_EDIT}
                  removeDefaultWeight={true}
                  type="submit"
                >
                  Confirm
                </AuthorizedAction>
              </div>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
};

export default RemoveHoliday;
