import cx from 'classnames';
import React from 'react';
import firstPageIcon from '../../icons/first-page.svg';
import lastPageIcon from '../../icons/last-page.svg';
import nextPageIcon from '../../icons/next-page.svg';
import prevPageIcon from '../../icons/prev-page.svg';
import './AdvancedPagination.scss';

interface AdvancedPaginationProps {
  limit: number;
  limitOptions: number[];
  onLimitChange: (limit: number) => void;
  onPageChange: (page: number) => void;
  page: number;
  totalItems: number;
}

const AdvancedPagination = ({
  limit,
  limitOptions,
  onLimitChange,
  onPageChange,
  page,
  totalItems,
}: AdvancedPaginationProps) => {
  const offset = page === 0 ? 1 : page * limit + 1;

  const prev = page === 0 ? 0 : page - 1;

  const lastPage = totalItems > limit ? Math.ceil(totalItems / limit) - 1 : 0;

  const next = page === lastPage ? lastPage : page + 1;

  const lastItem = offset + limit - 1;

  return (
    <div
      className={cx(
        'adv-pagination',
        'apl-display-flex',
        'apl-flex-row',
        'apl-align-items-center',
        'apl-py',
        'apl-px-s'
      )}
    >
      <div className={cx('apl-field-v1', 'apl-display-flex', 'apl-align-items-center', 'apl-mr-l')}>
        <label
          className="apl-field__label apl-mr-s apl-mb-none"
          htmlFor="limit-field"
          style={{ fontWeight: 600 }}
        >
          Rows per page
        </label>
        <select
          className="apl-select-v1_0"
          id="limit-field"
          name="limit"
          onChange={(event) => onLimitChange(parseInt(event.target.value))}
          value={limit}
        >
          {limitOptions.map((v) => (
            <option key={v} value={v}>
              {v}
            </option>
          ))}
        </select>
      </div>
      <p className="apl-my-none apl-mr">
        {offset}-{lastItem > totalItems ? totalItems : lastItem} of {totalItems}
      </p>
      <div className="adv-pagination__buttons apl-display-flex apl-flex-row">
        <button
          className={cx('adv-pagination__button', 'apl-button-v1', 'apl-display-flex', 'apl-mr-xs')}
          disabled={page === 0}
          onClick={() => onPageChange(0)}
        >
          <img src={firstPageIcon} />
        </button>
        <button
          className={cx('adv-pagination__button', 'apl-button-v1', 'apl-display-flex', 'apl-mr-xs')}
          disabled={page === 0}
          onClick={() => onPageChange(prev)}
        >
          <img src={prevPageIcon} />
        </button>
        <button
          className={cx('adv-pagination__button', 'apl-button-v1', 'apl-display-flex', 'apl-mr-xs')}
          disabled={page === lastPage}
          onClick={() => onPageChange(next)}
        >
          <img src={nextPageIcon} />
        </button>
        <button
          className={cx('adv-pagination__button', 'apl-button-v1', 'apl-display-flex', 'apl-mr-xs')}
          disabled={page === lastPage}
          onClick={() => onPageChange(lastPage)}
        >
          <img src={lastPageIcon} />
        </button>
      </div>
    </div>
  );
};

export default AdvancedPagination;
