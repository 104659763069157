import React, { FunctionComponent, useState } from 'react';
import Modal from 'react-modal';
import AuthorizedAction from '../common/AuthorizedAction';
import { modalStyles } from '../layout/Modal';
import { Permission } from '../../auth/getPermissions';
import { CreateProvisioningExclusionRequest, useAddProvisioningExclusion } from './provisioningApi';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { Provider, useProviders } from 'components/providers/providersApi';
import FieldWrapper, { FieldWidth, inputClassNameBuilder } from 'components/form/FieldWrapper';
import { ErrorText } from 'components/layout/Errors';
import { object, string } from 'yup';
import Alert, { AlertEnum } from '../layout/Alert';
import { ErrorModel } from '../../api/ErrorDisplay';

const addExclusionProviderIdValidationSchema = object().shape({
  providerReference: string().label('Provider reference').trim().required(),
  providerId: string().label('Provider').trim().required(),
});

const AddExclusion: FunctionComponent = () => {
  const [addExclusionIsOpen, setAddExclusionIsOpen] = useState(false);

  const { data: providers } = useProviders();

  const closeAddExclusion = () => setAddExclusionIsOpen(false);

  const openAddExclusion = () => setAddExclusionIsOpen(true);

  const { error: exclusionError, mutateAsync: addProvisioningExclusion } =
    useAddProvisioningExclusion();

  const submit = async (data: CreateProvisioningExclusionRequest): Promise<any> => {
    try {
      await addProvisioningExclusion({ data });
      closeAddExclusion();
    } catch (e: any) {
      console.error(e);
    }
  };

  return (
    <>
      <AuthorizedAction
        testId="add exclusion button"
        extraClasses="is-primary"
        onClick={openAddExclusion}
        permission={Permission.PROVIDERS_EDIT}
      >
        Add exclusion
      </AuthorizedAction>
      <Modal
        isOpen={addExclusionIsOpen}
        onRequestClose={closeAddExclusion}
        style={modalStyles}
        shouldCloseOnOverlayClick={false}
      >
        <div>
          <h2>Add exclusion</h2>
          <Formik
            initialValues={{
              providerReference: '',
              providerId: undefined,
            }}
            onSubmit={(exclusionForm) => {
              const observanceBody: CreateProvisioningExclusionRequest = {
                providerReference: exclusionForm.providerReference.trim(),
                providerId: exclusionForm.providerId,
              };
              return submit(observanceBody);
            }}
            validationSchema={addExclusionProviderIdValidationSchema}
          >
            {({ errors, isSubmitting, isValid, touched }) => (
              <Form className="apl-form-layout-v1">
                <>
                  {exclusionError && (
                    <Alert type={AlertEnum.DANGER}>
                      {(exclusionError as ErrorModel).message ?? 'Sorry, there was an error.'}
                    </Alert>
                  )}
                  <FieldWrapper
                    htmlFor="provider-reference"
                    label="Provider Reference"
                    fieldWidth={FieldWidth.FULL}
                  >
                    <Field
                      autoComplete="off"
                      data-testid="provider reference field"
                      name="providerReference"
                      id="provider-reference"
                      className={inputClassNameBuilder('providerReference', errors, touched)}
                    />
                    <ErrorMessage component={ErrorText} name="providerReference" />
                  </FieldWrapper>
                  <FieldWrapper
                    htmlFor="provider-field"
                    label="Provider"
                    fieldWidth={FieldWidth.FULL}
                  >
                    <Field
                      autoComplete="off"
                      className="apl-select-v1_0"
                      data-testid="provider field"
                      name="providerId"
                      id="provider-field"
                      as="select"
                    >
                      <option />
                      {(providers as Provider[] | undefined)?.map((p: Provider, index: number) => (
                        <option key={index} value={p.id}>
                          {p.name}
                        </option>
                      ))}
                    </Field>
                    <ErrorMessage component={ErrorText} name="providerId" />
                  </FieldWrapper>
                  <div className="apl-display-flex apl-justify-content-end">
                    <button
                      data-testid="cancel button"
                      className="apl-button-v1"
                      onClick={closeAddExclusion}
                      type="button"
                    >
                      Cancel
                    </button>
                    <AuthorizedAction
                      extraClasses="is-primary"
                      isDisabled={isSubmitting || !isValid}
                      permission={Permission.PROVIDERS_EDIT}
                      testId="add exclusion button"
                      type="submit"
                    >
                      Save
                    </AuthorizedAction>
                  </div>
                </>
              </Form>
            )}
          </Formik>
        </div>
      </Modal>
    </>
  );
};

export default AddExclusion;
