import React from 'react';
import infoIcon from '../../icons/info.svg';

interface EmptyTableRowProps {
  message: string;
}

const EmptyTableRow = ({ message }: EmptyTableRowProps) => {
  return (
    <tbody>
      <tr>
        <td
          style={{
            padding: '85px 0',
            textAlign: 'center',
          }}
        >
          <img src={infoIcon} alt="info icon" />
          <p className="apl-mt-xs" style={{ margin: '0 auto', maxWidth: '175px' }}>
            {message}
          </p>
        </td>
      </tr>
    </tbody>
  );
};

export default EmptyTableRow;
