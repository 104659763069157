import getConfig from 'config/getConfig';
import {
  BillingLifecycleApiClient,
  ApiError as BillingLifecycleApiError,
} from './openapi/billing-lifecycle-api/';
import {
  LossFactorManagerClient,
  ApiError as LossFactorManagerApiError,
} from './openapi/loss-factor-manager/';
import {
  RatingCalculatorClient,
  ApiError as RatingCalculatorApiError,
} from './openapi/rating-calculator';
import { RatingConfigClient, ApiError as RatingConfigApiError } from './openapi/rating-config';
import {
  RatingConfigReportClient,
  ApiError as RatingConfigReportApiError,
} from './openapi/rating-config-report/';
import {
  SupplyAgreementAdaptorClient,
  ApiError as SupplyAgreementAdaptorApiError,
} from './openapi/supply-agreement-adaptor/';
import { TelemetryClient, ApiError as TelemetryApiError } from './openapi/telemetry/';
import { getAccessToken } from 'auth/authClient';
import { makeErrorToastNotificationBody, useStore } from 'store';
type ApiError =
  | BillingLifecycleApiError
  | LossFactorManagerApiError
  | RatingCalculatorApiError
  | RatingConfigApiError
  | RatingConfigReportApiError
  | SupplyAgreementAdaptorApiError
  | TelemetryApiError;

/**
 * Method to handle API requests.
 * @param request the API request.
 * @returns the resolved request or rejection if a failure occurred.
 *
 * @example
 * const data = await handleRequest(ratingConfigClient.listData.getListData());
 */
async function handleRequest<T>(request: Promise<T>) {
  try {
    return await request;
  } catch (error) {
    return handleError(<ApiError>error);
  }
}

/**
 * Method to handle API errors.
 * @param error the API error.
 * @returns a rejected promise.
 */
export function handleError(error: ApiError) {
  console.error(error);

  if (error.status === 401) {
    window.location.assign(`${process.env.PUBLIC_URL}/unauthorised`);
    return Promise.reject(error.body);
  }

  const { showErrorResponseBodyError } = getConfig();
  // show toast notification about the error
  const notification = makeErrorToastNotificationBody(
    error.status,
    error.body.message,
    error.body.error,
    showErrorResponseBodyError
  );
  useStore.getState().addNotification(notification);
  return Promise.reject(error.body);
}

const config = getConfig();
const tokenFunc = () => getAccessToken(config);

/**
 * The billing-lifecycle-api client
 */
const billingLifecycleApiClient = new BillingLifecycleApiClient({
  BASE: config.billingApiBasePath,
  TOKEN: tokenFunc,
});

/**
 * The loss-factor-manager client
 */
const lossFactorManagerClient = new LossFactorManagerClient({
  BASE: config.lossFactorApiBasePath,
  TOKEN: tokenFunc,
});

/**
 * The rating-calculator client
 */
const ratingCalculatorClient = new RatingCalculatorClient({
  BASE: config.ratingCalculatorApiBasePath,
  TOKEN: tokenFunc,
});

/**
 * The rating-config client
 */
const ratingConfigClient = new RatingConfigClient({
  BASE: config.apiBasePath,
  TOKEN: tokenFunc,
});

/**
 * The rating-config-report client
 */
const ratingConfigReportClient = new RatingConfigReportClient({
  BASE: config.reportApiBasePath,
  TOKEN: tokenFunc,
});

/**
 * The supply-agreement-adaptor client
 */
const supplyAgreementAdaptorClient = new SupplyAgreementAdaptorClient({
  BASE: config.presentationApiBasePath,
  TOKEN: tokenFunc,
});

/**
 * The telemetry client
 */
const telemetryClient = new TelemetryClient({
  BASE: config.telemetryApiBasePath,
  TOKEN: tokenFunc,
});

export {
  handleRequest,
  billingLifecycleApiClient,
  lossFactorManagerClient,
  ratingCalculatorClient,
  ratingConfigClient,
  ratingConfigReportClient,
  supplyAgreementAdaptorClient,
  telemetryClient,
};
