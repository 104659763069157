import { useCallback, useEffect, useRef } from 'react';

interface UseStorageEventListener {
  listener: EventListener;
  options?: AddEventListenerOptions;
}

const useStorageEventListener = ({ listener, options }: UseStorageEventListener): void => {
  const savedListener = useRef<EventListener>();

  useEffect(() => {
    savedListener.current = listener;
  }, [listener]);

  const handleEventListener = useCallback((event: Event) => {
    savedListener.current?.(event);
  }, []);

  useEffect(() => {
    window?.addEventListener('storage', handleEventListener, options);
    return () => window?.removeEventListener('storage', handleEventListener);
  }, [options, handleEventListener]);
};

export default useStorageEventListener;
