import { useQuery } from '@tanstack/react-query';
import apiClient from '../../api/apiClient';
import { InvoiceContext, UnitValue } from './billingInvoicesApi';

const baseEndpoint = '/billing/credit-notes';

export interface CreditNoteResult {
  items: CreditNote[];
  total: number;
  _links: Links[];
}

export interface CreditNote {
  context?: InvoiceContext;
  creditNoteNumber?: string;
  invoiceNumber: string;
  issuedAt: string;
  pdfUrl?: string;
  amount: {
    currency: string;
    value: UnitValue;
  };
  _links: Links;
}

export interface Links {
  rel: string;
  href: string;
}

export interface CreditNotesQueryParams {
  externalSupplyAgreementId?: string;
  endsAt?: string;
  limit?: number;
  offset?: number;
  startsAt?: string;
}

export const useGetCreditNotes = (
  params: CreditNotesQueryParams,
  options?: Record<string, unknown>
) => {
  const { externalSupplyAgreementId, endsAt, startsAt } = params;

  const search = new URLSearchParams();

  (Object.keys(params) as Array<keyof CreditNotesQueryParams>).forEach((key) => {
    if (params[key]) {
      search.set(key, params[key] as string);
    }
  });

  return useQuery(
    ['credit-notes', externalSupplyAgreementId, endsAt, startsAt],
    async () => {
      return apiClient('billing').get(`${baseEndpoint}?${search.toString()}`);
    },
    {
      ...options,
    }
  );
};
