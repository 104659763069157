import React from 'react';
import cx from 'classnames';
import { SupplyPeriodSummary } from '../connectionsApi';
import { Widget } from '../../dashboard/Dashboard';
import warningIcon from '../../../icons/warning-orange.svg';

interface MissingInvoiceProps {
  period?: SupplyPeriodSummary;
}

const MissingInvoice = ({ period }: MissingInvoiceProps) => {
  const search = new URLSearchParams();

  if (period) {
    search.set('external_supply_agreement_id', period.externalSupplyAgreementId);
    search.set('contracted_party', period.owner);
  }

  if (period?.startDate) {
    search.set('from', period.startDate);
  }

  if (period?.endDate) {
    search.set('to', period.endDate);
  }

  return (
    <Widget>
      <div
        className={cx(
          'apl-display-flex',
          'apl-flex-row',
          'apl-align-items-center',
          'apl-justify-content-between'
        )}
      >
        <div className={cx('apl-display-flex', 'apl-flex-row', 'apl-align-items-center')}>
          <img className="apl-mr" src={warningIcon} />
          <p className="apl-my-none" style={{ fontSize: '14px' }}>
            An invoice could not be automatically generated for the billing period
          </p>
        </div>
      </div>
    </Widget>
  );
};

export default MissingInvoice;
