/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { RangeToken } from './RangeToken';

export type MultiRangeItem = (RangeToken & {
    type: MultiRangeItem.type;
    from: string;
    to?: string;
});

export namespace MultiRangeItem {

    export enum type {
        TIME_RANGE = 'TIME_RANGE',
    }


}

