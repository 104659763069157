import { isEmpty } from 'ramda';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import apiClient from '../../api/apiClient';
import { MultiRangeQualifierBody, QualifierLink } from '../../api/openapi/rating-config';

export interface Operator {
  operatorType: string;
  tokenType: string;
}

export interface QualifierDetailsType extends MultiRangeQualifierBody {
  id: string;
  providerName: string;
}

export interface Qualifier {
  id: string;
  providerName: string;
  providerId: string;
  name: string;
}

const qualifiersBaseEndpoint = '/qualifiers';

export const useQualifier = (
  qualifierId: string | undefined,
  options?: Record<string, unknown>
) => {
  return useQuery(
    ['qualifier', qualifierId],
    async () => {
      const url = `${qualifiersBaseEndpoint}/${qualifierId}`;
      return apiClient().get(url);
    },
    { ...options }
  );
};

export const useQualifierList = () => {
  return useQuery(
    ['qualifier'],
    async () => {
      const url = `${qualifiersBaseEndpoint}`;
      return apiClient().get(url);
    },
    {
      staleTime: 30000,
    }
  );
};

interface QualifierSearchParams {
  search: string;
  sortColumn: string;
  sortOrder: string;
  offset: number;
  limit: number;
}

export const useQualifierSearch = (params: QualifierSearchParams) => {
  const { search, sortColumn, sortOrder, offset, limit } = params;

  return useQuery(['qualifier', search, sortColumn, sortOrder, offset, limit], async () => {
    const query = new URLSearchParams();

    if (!isEmpty(search)) {
      query.set('qualifier-name', search);
    }

    if (!isEmpty(sortColumn)) {
      query.set('sortBy', `${sortColumn}.${sortOrder}`);
      query.set('offset', `${offset}`);
      query.set('limit', `${limit}`);
    }

    return apiClient().get(`${qualifiersBaseEndpoint}?${query.toString()}`);
  });
};

interface UpdateQualifiersArgs {
  multiRangeQualifierBody: MultiRangeQualifierBody;
  qualifierId: string | undefined;
}

export const useUpdateQualifier = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ multiRangeQualifierBody, qualifierId }: UpdateQualifiersArgs) => {
      return apiClient().put(`/qualifiers/${qualifierId}`, JSON.stringify(multiRangeQualifierBody));
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['qualifier']);
      },
    }
  );
};

interface AddQualifiersArgs {
  data: MultiRangeQualifierBody;
}

export const useAddQualifier = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ data }: AddQualifiersArgs) => {
      return apiClient().post(qualifiersBaseEndpoint, JSON.stringify(data));
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['qualifier']);
      },
    }
  );
};

export const timeRanges = [
  {
    key: 'MONTH_RANGE',
    value: 'Month',
  },
  {
    key: 'TIME_RANGE',
    value: 'Time',
  },
  {
    key: 'DAY_OF_WEEK_RANGE',
    value: 'Day of week',
  },
  {
    key: 'WORKING_DAY_RANGE',
    value: 'Working day',
  },
  {
    key: 'NON_WORKING_DAY_RANGE',
    value: 'Non working day',
  },
];

export const rangeTypeToValuesList = (type: string): string[] => {
  switch (type) {
    case 'MONTH_RANGE':
      return rangeValuesMap.MONTH_RANGE;
    case 'DAY_OF_WEEK_RANGE':
      return rangeValuesMap.DAY_OF_WEEK_RANGE;
    case 'TIME_RANGE':
      return rangeValuesMap.TIME_RANGE;
    default:
      return [];
  }
};

interface RangeValues {
  MONTH_RANGE: string[];
  DAY_OF_WEEK_RANGE: string[];
  TIME_RANGE: string[];
}

const rangeValuesMap: RangeValues = {
  MONTH_RANGE: [
    'JANUARY',
    'FEBRUARY',
    'MARCH',
    'APRIL',
    'MAY',
    'JUNE',
    'JULY',
    'AUGUST',
    'SEPTEMBER',
    'OCTOBER',
    'NOVEMBER',
    'DECEMBER',
  ],
  DAY_OF_WEEK_RANGE: ['MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY', 'SUNDAY'],
  TIME_RANGE: [
    '00:00',
    '00:30',
    '01:00',
    '01:30',
    '02:00',
    '02:30',
    '03:00',
    '03:30',
    '04:00',
    '04:30',
    '05:00',
    '05:30',
    '06:00',
    '06:30',
    '07:00',
    '07:30',
    '08:00',
    '08:30',
    '09:00',
    '09:30',
    '10:00',
    '10:30',
    '11:00',
    '11:30',
    '12:00',
    '12:30',
    '13:00',
    '13:30',
    '14:00',
    '14:30',
    '15:00',
    '15:30',
    '16:00',
    '16:30',
    '17:00',
    '17:30',
    '18:00',
    '18:30',
    '19:00',
    '19:30',
    '20:00',
    '20:30',
    '21:00',
    '21:30',
    '22:00',
    '22:30',
    '23:00',
    '23:30',
  ],
};

export const getDefaultQualifiers = (): Record<string, QualifierLink> => {
  return {
    workingDayQualifier: {
      qualifierId: '851bd4a1-a096-4e2d-afd1-3c82975e0224',
      name: 'Working day',
    },
    nonWorkingDayQualifier: {
      qualifierId: '7b9236af-a345-4acd-a7a4-86f4f2023051',
      name: 'Non working day',
    },
  };
};

export const timeTypeDisplayMap = {
  DATE_TIME_RANGE: 'Date',
  DATE_RANGE: 'Date',
  MONTH_RANGE: 'Month',
  MONTH_DAY_RANGE: 'Day of month',
  DAY_OF_WEEK_RANGE: 'Day of week',
  TIME_RANGE: 'Time',
  WORKING_DAY_RANGE: 'Working day',
  NON_WORKING_DAY_RANGE: 'Non working day',
};
