import React, { useState } from 'react';
import Modal from 'react-modal';
import { Widget } from 'components/dashboard/Dashboard';
import { Permission } from 'auth/getPermissions';
import AuthorizedAction from 'components/common/AuthorizedAction';
import { useMeasurementPoints } from 'components/telemetry/telemetryUsageApi';
import { buildScopedToQueryField } from './measurementPointHelpers';
import MeasurementPoint from './MeasurementPoint';
import AddMeasurementPoint from './AddMeasurementPoint';
import Pagination from 'components/common/Pagination';
import { Connection } from 'api/openapi/rating-config';
import { modalStyles } from '../../layout/Modal';

interface MeasurementPointsSummaryProps {
  connection: Connection;
}

function MeasurementPointsSummary({ connection }: MeasurementPointsSummaryProps) {
  const [page, setPage] = useState(0);
  const [modalOpen, setModalOpen] = useState(false);

  const limit = 10;
  const offset = page === 0 ? 0 : limit * page;

  const scopedToQueryField = buildScopedToQueryField(connection.id ?? '');
  const {
    data,
    isError,
    isInitialLoading: isLoading,
  } = useMeasurementPoints({
    scopedTo: scopedToQueryField,
    limit: limit,
    offset: offset,
  });

  const pages =
    data && data.totalCount && data.totalCount > limit ? Math.ceil(data.totalCount / limit) : 1;

  const closeModal = () => {
    setModalOpen(false);
  };

  return (
    <>
      <Widget
        headerComponent={
          <h3 className="apl-h3 widget__title apl-mb-none">
            <div className="apl-display-flex apl-align-items-start">
              <span className="apl-mr">
                Measurement <br />
                points
              </span>
              <AuthorizedAction
                testId="Add new measurement point button"
                extraClasses="apl-ml"
                permission={Permission.METRIC_CREATE}
                onClick={() => {
                  setModalOpen(true);
                }}
              >
                Add new measurement point
              </AuthorizedAction>
            </div>
          </h3>
        }
        width="full"
      >
        {isLoading && <p className="apl-ml">Loading...</p>}
        {isError && <p className="apl-ml">Sorry, there was an error.</p>}
        {!isLoading &&
          !isError &&
          (data && data.items && data.items.length > 0 ? (
            <>
              <ul className="widget__list">
                {data.items.map((measurementPoint) => (
                  <li key={measurementPoint.id} className="widget__list-item">
                    <MeasurementPoint
                      id={connection.id ?? ''}
                      measurementPoint={measurementPoint}
                      connectionId={connection.connectionId ?? ''}
                    />
                  </li>
                ))}
              </ul>
              <div>
                <Pagination onPageClick={setPage} page={page} pages={pages} />
              </div>
            </>
          ) : (
            <p className="apl-ml">Sorry, no measurement points found.</p>
          ))}
      </Widget>
      <Modal
        isOpen={modalOpen}
        onRequestClose={closeModal}
        shouldCloseOnOverlayClick={false}
        style={modalStyles}
      >
        <AddMeasurementPoint
          connectionId={connection.connectionId ?? ''}
          id={connection.id ?? ''}
          close={closeModal}
        />
      </Modal>
    </>
  );
}

export default MeasurementPointsSummary;
