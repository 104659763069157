import { addYears, endOfYear, format, formatISO, startOfYear } from 'date-fns';
import React, { FunctionComponent, useMemo, useState } from 'react';
import Modal from 'react-modal';
import { Permission } from '../../auth/getPermissions';
import searchIcon from '../../icons/search.svg';
import { generateYearlyDateOptions, sortByDate, sortByStr } from '../../util/helper-func';
import AuthorizedAction from '../common/AuthorizedAction';
import '../dashboard/missing-plans/SearchFilter.scss';
import Card from '../layout/Card';
import { modalStyles } from '../layout/Modal';
import Page, { ErrorPage, LoadingPage, PageHeader } from '../layout/Page';
import './../../flux-styles/paginator.scss';
import AddHoliday from './AddHoliday';
import { Observance, observanceTypeMapper, useAllObservances } from './observancesApi';
import RemoveHoliday from './RemoveHoliday';
import UpdateHoliday from './UpdateHoliday';

const labelFormat = 'yyyy';
const valueFormat = 'yyyy-MM-dd';

const HolidayList: FunctionComponent = () => {
  const [searchHoliday, setSearchHoliday] = useState('');
  const [searchProvider, setSearchProvider] = useState('');
  const [sortAsc, setSortAsc] = useState(true);
  const [sortCol, setSortCol] = useState('name');

  const dateOptions = useMemo(
    () =>
      // TODO: how to know how many years to go back?? check with Justyna, Andrew
      generateYearlyDateOptions(true, 2, labelFormat, valueFormat, addYears(new Date(), 1)),
    []
  );

  const initialDate = addYears(new Date(), 1);
  const [date, setDate] = useState<Date>(initialDate);
  const [addHolidayIsOpen, setAddHolidayIsOpen] = useState(false);
  const closeAddHoliday = () => setAddHolidayIsOpen(false);
  const openAddHoliday = () => setAddHolidayIsOpen(true);

  const { data, isLoading, isError } = useAllObservances({
    from: formatISO(startOfYear(date), { representation: 'date' }),
    to: formatISO(endOfYear(date), { representation: 'date' }),
    provider: '',
  });

  if (isLoading) {
    return <LoadingPage />;
  }

  if (isError) {
    return <ErrorPage />;
  }

  return (
    <>
      <PageHeader title="Holidays"></PageHeader>
      <Page>
        <Card className="apl-pt-none">
          <div className="apl-display-flex">
            <div className="apl-display-flex apl-flex-grow-1 apl-align-items-center apl-justify-content-start">
              <div className="apl-field-v1 apl-display-flex apl-align-items-center apl-mr-l apl-py">
                <label className="apl-field__label apl-mr-s apl-mb-none" htmlFor="date-field">
                  Year:
                </label>
                <select
                  className="apl-select-v1_0"
                  data-testid="date filter field"
                  id="date-field"
                  onChange={(event) => setDate(new Date(event?.target.value))}
                  value={`${format(date, valueFormat)}`}
                >
                  {dateOptions.map((dateOption) => (
                    <option key={dateOption.value} value={dateOption.value}>
                      {dateOption.label}
                    </option>
                  ))}
                </select>
              </div>
              <div className="apl-field-v1 apl-mr-l apl-py">
                <div className="search-filter">
                  <input
                    className="apl-text-input-v1 search-filter__input"
                    data-testid="holiday filter field"
                    onChange={(event) => setSearchHoliday(event.target.value)}
                    placeholder="Find a holiday"
                    type="text"
                  />
                  <img alt="search icon" className="search-filter__icon" src={searchIcon} />
                </div>
              </div>
              <div className="apl-field-v1 apl-mr-l apl-py">
                <div className="search-filter">
                  <input
                    className="apl-text-input-v1 search-filter__input"
                    data-testid="provider filter field"
                    onChange={(event) => setSearchProvider(event.target.value)}
                    placeholder="Find a provider"
                    type="text"
                  />
                  <img alt="search icon" className="search-filter__icon" src={searchIcon} />
                </div>
              </div>
            </div>
            <div className="apl-display-flex apl-flex-grow-1 apl-align-items-center apl-justify-content-end">
              <AuthorizedAction
                onClick={() => openAddHoliday()}
                permission={Permission.OBSERVANCE_EDIT}
              >
                Add holiday
              </AuthorizedAction>
            </div>
          </div>
          <table className="apl-table-v1">
            <thead>
              <tr>
                <th
                  className={
                    sortCol === 'name'
                      ? `is-sortable ${sortAsc ? 'is-sorted-asc' : 'is-sorted-desc'}`
                      : ''
                  }
                  onClick={() => {
                    setSortCol('name');
                    setSortAsc(!sortAsc);
                  }}
                >
                  Holiday
                </th>
                <th
                  className={
                    sortCol === 'actual-date'
                      ? `is-sortable ${sortAsc ? 'is-sorted-asc' : 'is-sorted-desc'}`
                      : ''
                  }
                  onClick={() => {
                    setSortCol('actual-date');
                    setSortAsc(!sortAsc);
                  }}
                >
                  Actual date
                </th>
                <th
                  className={
                    sortCol === 'observed-date'
                      ? `is-sortable ${sortAsc ? 'is-sorted-asc' : 'is-sorted-desc'}`
                      : ''
                  }
                  onClick={() => {
                    setSortCol('observed-date');
                    setSortAsc(!sortAsc);
                  }}
                >
                  Observed date
                </th>
                <th
                  className={
                    sortCol === 'type'
                      ? `is-sortable ${sortAsc ? 'is-sorted-asc' : 'is-sorted-desc'}`
                      : ''
                  }
                  onClick={() => {
                    setSortCol('type');
                    setSortAsc(!sortAsc);
                  }}
                >
                  Type
                </th>
                <th
                  className={
                    sortCol === 'location'
                      ? `is-sortable ${sortAsc ? 'is-sorted-asc' : 'is-sorted-desc'}`
                      : ''
                  }
                  onClick={() => {
                    setSortCol('location');
                    setSortAsc(!sortAsc);
                  }}
                >
                  Location
                </th>
                <th>Providers</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {data.observances
                .filter((observance: Observance) =>
                  observance.name.toUpperCase().includes(searchHoliday.toUpperCase())
                )
                .filter((observance: Observance) =>
                  searchProvider !== ''
                    ? observance.providers?.some((provider) =>
                        provider.name.toUpperCase().includes(searchProvider.toUpperCase())
                      )
                    : observance
                )
                .sort((observanceA: Observance, observanceB: Observance) => {
                  switch (sortCol) {
                    case 'name':
                      return sortByStr(observanceA.name, observanceB.name, sortAsc);
                    case 'actual-date':
                      return sortByDate(observanceA?.actualDate, observanceB?.actualDate, sortAsc);
                    case 'observed-date':
                      return sortByDate(
                        observanceA?.observedDate,
                        observanceB?.observedDate,
                        sortAsc
                      );
                    case 'type':
                      return sortByStr(
                        observanceA.observanceType,
                        observanceB.observanceType,
                        sortAsc
                      );
                    case 'location':
                      return sortByStr(observanceA.location, observanceB.location, sortAsc);
                    default:
                      return;
                  }
                })
                .map((observance: Observance) => {
                  const {
                    id,
                    name,
                    actualDate,
                    location,
                    observedDate,
                    observanceType,
                    providers,
                  } = observance;

                  const testId = name.replace(/ /g, '-').toLowerCase();

                  return (
                    <tr data-testid={`${testId}-row`} key={testId}>
                      <td>{name}</td>
                      <td>{actualDate}</td>
                      <td>{observedDate}</td>
                      <td>{observanceTypeMapper(observanceType)}</td>
                      <td>{location}</td>
                      <td style={{ maxWidth: '200px' }}>
                        {providers &&
                          providers.length > 0 &&
                          providers.map((provider) => (
                            <span
                              key={provider.id}
                              className="badge apl-ml-s"
                              style={{ margin: '2.5px 0' }}
                            >
                              {provider.name}
                            </span>
                          ))}
                      </td>
                      <td>
                        <UpdateHoliday observance={observance} observanceId={id} />
                        <RemoveHoliday observanceId={id} />
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </Card>
        <Modal
          isOpen={addHolidayIsOpen}
          onRequestClose={closeAddHoliday}
          shouldCloseOnOverlayClick={false}
          style={modalStyles}
        >
          <AddHoliday close={closeAddHoliday} />
        </Modal>
      </Page>
    </>
  );
};

export default HolidayList;
