import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { handleRequest, ratingConfigClient } from 'api';
import { Charge, PlanBody, PlanScope, PlanState, PlanStateBody } from 'api/openapi/rating-config';
import {
  chargeRequiresApplicableTimePeriod,
  chargeRequiresFlowDirection,
  chargeRequiresHistoricalMeasure,
  chargeRequiresIntervalSize,
  chargeRequiresLookbackMonths,
  chargeRequiresLossAdjustment,
  chargeRequiresMinimumChargeable,
  chargeRequiresMinimumThresholdMethod,
  chargeRequiresPowerFactorThreshold,
  chargeRequiresSummarisationFunction,
  chargeRequiresTopNPeriods,
  chargeRequiresUsageChargeFilter,
  chargeRequiresReadingQuality,
  chargeRequiresIsTaxed,
  ChargeTypesConfig,
} from 'list-data/charge-types/chargeTypesConfigUtils';
import ct from 'countries-and-timezones';
import { getMarketFunction } from '../../list-data/getMarketFunction';

/* ===== Start v3 endpoints ===== */
export const useListPlanLinkPurposes = () => {
  return useQuery(
    ['plan-link-purposes'],
    async () => await handleRequest(ratingConfigClient.plans.listPlanLinkPurposes())
  );
};
/* ===== End v3 endpoints ===== */
/* ===== Start v2 endpoints ===== */

export const usePlanRatesV2 = (planId: string | undefined, fromDate: string, toData: string) => {
  return useQuery(
    ['plan-rates', planId, fromDate, toData],
    async () =>
      await handleRequest(ratingConfigClient.plans.getRatesTable(planId ?? '', fromDate, toData))
  );
};

export const usePlanV2 = (planId: string | undefined) => {
  const data = useQuery(
    ['plan', planId],
    async () => await handleRequest(ratingConfigClient.plans.getPlanV2ById(planId ?? ''))
  );
  return data;
};

export const usePlanWithDeletedChargeV2 = (planId: string | undefined) => {
  const data = useQuery(
    ['plan', planId],
    async () => await handleRequest(ratingConfigClient.plans.getPlanV2ById(planId ?? '', true))
  );
  return data;
};

export const usePlanCreationV2 = () => {
  return useMutation(
    async (planBody: PlanBody) =>
      await handleRequest(ratingConfigClient.plans.createPlanV2(planBody))
  );
};

export const usePlanUsageV2 = (planId: string | undefined) => {
  return useQuery(
    ['plan-usage', planId],
    async () => await handleRequest(ratingConfigClient.plans.getPlanUsage(planId ?? ''))
  );
};

export const useUpdatePlanV2 = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async ({ planId, plan }: { planId: string; plan: PlanBody }) =>
      await handleRequest(ratingConfigClient.plans.updatePlanV2(planId, plan)),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['plan']);
      },
    }
  );
};

export const useUpdatePlanStateV2 = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async ({ planId, planState }: { planId: string; planState: PlanStateBody }) =>
      await handleRequest(ratingConfigClient.plans.updatePlanStateV2(planId, planState)),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['plan']);
      },
    }
  );
};

export const useAddChargesToPlanV2 = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async ({ planId, charges }: { planId: string; charges: Charge[] }) =>
      await handleRequest(ratingConfigClient.plans.addChargesV2ToPlan(planId, charges)),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['plan']);
      },
    }
  );
};

export const useEditChargeV2 = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async ({ planId, chargeId, charge }: { planId: string; chargeId: string; charge: Charge }) =>
      await handleRequest(ratingConfigClient.plans.updateChargeV2(planId, chargeId, charge)),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['plan']);
      },
    }
  );
};

export const useDeleteChargeV2 = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async ({ planId, chargeId }: { planId: string; chargeId: string }) =>
      await handleRequest(ratingConfigClient.plans.deleteChargeV2(planId, chargeId)),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['plan']);
      },
    }
  );
};

export const usePlanTags = () => {
  const data = useQuery(
    ['plan-tags'],
    async () => await handleRequest(ratingConfigClient.plans.getPlanLevelTags())
  );
  return data;
};

/* ===== End v2 endpoints ===== */

export const useReferencePlan = (marketFunctionFlags: any) => {
  return useQuery(['reference-plan'], async () => {
    return Promise.resolve({
      planStructures,
      timezones: resolveTimezone(marketFunctionFlags),
    });
  });
};

export const sanitiseChargeBody = (
  chargeBody: Charge,
  chargeTypesConfig: ChargeTypesConfig
): Charge => {
  if (!chargeRequiresApplicableTimePeriod(chargeBody.chargeType, chargeTypesConfig)) {
    chargeBody?.schedules?.forEach((e) => {
      e?.rates?.forEach((r) => {
        r.applicableTimePeriods = [];
      });
    });
  }

  if (!chargeRequiresPowerFactorThreshold(chargeBody.chargeType, chargeTypesConfig)) {
    delete chargeBody.powerFactor;
  }

  if (!chargeRequiresTopNPeriods(chargeBody.chargeType, chargeTypesConfig)) {
    delete chargeBody.topPeriods;
  }

  if (!chargeRequiresSummarisationFunction(chargeBody.chargeType, chargeTypesConfig)) {
    delete chargeBody.sumFunction;
  }

  if (!chargeRequiresFlowDirection(chargeBody.chargeType, chargeTypesConfig)) {
    delete chargeBody.flowDirection;
  }

  if (!chargeRequiresLossAdjustment(chargeBody.chargeType, chargeTypesConfig)) {
    delete chargeBody.adjustmentToUse;
  }

  if (!chargeRequiresLookbackMonths(chargeBody.chargeType, chargeTypesConfig)) {
    delete chargeBody.lookbackMonths;
  }

  if (!chargeRequiresMinimumChargeable(chargeBody.chargeType, chargeTypesConfig)) {
    delete chargeBody.minimumChargeable;
  }

  if (!chargeRequiresMinimumThresholdMethod(chargeBody.chargeType, chargeTypesConfig)) {
    delete chargeBody.minimumThresholdMethod;
  }

  if (!chargeRequiresHistoricalMeasure(chargeBody.chargeType, chargeTypesConfig)) {
    delete chargeBody.historicalMeasure;
  }

  if (!chargeRequiresUsageChargeFilter(chargeBody.chargeType, chargeTypesConfig)) {
    delete chargeBody.usageChargeFilter;
  }

  if (!chargeRequiresIntervalSize(chargeBody.chargeType, chargeTypesConfig)) {
    delete chargeBody.intervalSize;
  }

  if (!chargeRequiresReadingQuality(chargeBody.chargeType, chargeTypesConfig)) {
    delete chargeBody.readingQuality;
  }

  if (!chargeRequiresIsTaxed(chargeBody.chargeType, chargeTypesConfig)) {
    delete chargeBody.isTaxed;
  }

  return chargeBody;
};

const planStructures = [
  {
    id: 'EASI_144',
    value: 'Easi 144',
  },
  {
    id: 'EASI_48',
    value: 'Easi 48',
  },
  {
    id: 'SIMPLE_CHARGE',
    value: 'Simple charge',
  },
];

export enum CountryEnum {
  NZ = 'NZ',
  AU = 'AU',
  GB = 'GB',
  UK = 'UK',
  US = 'US',
  EU = 'EU',
}

export const resolveTimezone = (marketFunctionFlags: any) => {
  const currentMarket = getMarketFunction(
    'urn:flux:rating-config:market',
    undefined,
    marketFunctionFlags
  );
  switch (currentMarket) {
    case String(CountryEnum.AU): {
      const timezones_au = ct.getCountry(CountryEnum.AU);
      const timezones = timezones_au.timezones.filter((e) => e !== 'Antarctica/Macquarie');
      return timezones;
    }
    case String(CountryEnum.NZ): {
      const timezones_nz = ct.getCountry(CountryEnum.NZ);
      const timezones = timezones_nz.timezones.filter((e) => e !== 'Pacific/Chatham');
      return timezones;
    }
    case String(CountryEnum.GB || CountryEnum.UK): {
      const timezones_uk = ct.getCountry(CountryEnum.GB);
      return timezones_uk.timezones;
    }
    case String(CountryEnum.US): {
      const timezones_us = ct.getCountry(CountryEnum.US);
      return timezones_us.timezones;
    }
    case String(CountryEnum.EU): {
      const timezones_gr = ct.getCountry('GR');
      // Hack as greece is the only EU contry we support
      // This should probably work on timezones not country codes
      return timezones_gr.timezones;
    }
    default: {
      const timezones_nz = ct.getCountry(CountryEnum.NZ);
      const timezones = timezones_nz.timezones.filter((e) => e !== 'Pacific/Chatham');
      return timezones;
    }
  }
};

export const planStateDisplayConversion = (planState: PlanState | undefined) => {
  switch (planState) {
    case PlanState.DRAFT:
      return 'Draft';
    case PlanState.PUBLISHED:
      return 'Published';
    case PlanState.DEPRECATED:
      return 'Deprecated';
    case PlanState.ARCHIVED:
      return 'Archived';
    default:
      return planState ? planState : '';
  }
};

export const planScopeDisplayConversion = (planScope: PlanScope | undefined) => {
  switch (planScope) {
    case PlanScope.SHARED:
      return 'Shared';
    case PlanScope.CONNECTION_SPECIFIC:
      return 'Connection Specific';
    default:
      return planScope ? planScope : '';
  }
};
