/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { Interval } from './Interval';
import type { Period } from './Period';

export type IntervalDataReceived = {
    _type: string;
    _version: string;
    _publishedAt: string;
    /**
     * -| Indicates the source of the trigger, this can be used by downstream clients to determine if this was from a resync request or from new telemetry readings
     */
    _triggeredSource?: IntervalDataReceived._triggeredSource;
    connectionId?: string;
    deviceRef: string;
    meterCompany?: string;
    externalConnectionId?: string;
    period: Period;
    intervals: Array<Interval>;
};

export namespace IntervalDataReceived {

    /**
     * -| Indicates the source of the trigger, this can be used by downstream clients to determine if this was from a resync request or from new telemetry readings
     */
    export enum _triggeredSource {
        URN_FLUX_TELEMETRY_TRIGGER_RESYNC_REQUEST = 'urn:flux:telemetry:trigger:resync-request',
        URN_FLUX_TELEMETRY_TRIGGER_USAGE_RECEIVED = 'urn:flux:telemetry:trigger:usage-received',
    }


}

