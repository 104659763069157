import React from 'react';
import { groupBy } from 'ramda';
import { Link, useParams } from 'react-router-dom';
import { GroupedCharges } from './ChargeSetDetails';
import DiscountsTable from './DiscountsTable';
import InvoiceSummary from './InvoiceSummary';
import PlanTable from './PlanTable';
import { useConnectionDetails } from '../connectionsApi';
import { LineItem, useBillingInvoice } from '../../billing/billingInvoicesApi';
import Page, { ErrorPage, LoadingPage, PageHeader } from '../../layout/Page';
import caretRight from '../../../icons/caret-right.svg';
import { useSearchQuery } from '../../../hooks';
import { useExternalBillingPresentation } from '../../core/coreExternalBillingApi';

const InvoiceDetails = () => {
  const { id, invoiceId } = useParams<{ id: string; invoiceId: string }>();

  // ContractedParty lives in a different domain than SupplyAgreement hence we need a separate
  // call to get this information. It should NOT be in the SupplyAgreement, however it could be
  // managed by rating-config to avoid additional web service calls.
  const queryParams = useSearchQuery();
  const supplyAgreementId = queryParams.get('agreementId');
  const supplyPeriodId = queryParams.get('spid');
  const { data: externalData } = useExternalBillingPresentation(supplyAgreementId ?? '', {
    enabled: !!supplyAgreementId,
  });

  const {
    data: connectionData,
    isError: connectionDataError,
    isInitialLoading: connectionDataLoading,
  } = useConnectionDetails(id, { enabled: !!id });

  const {
    data,
    isError,
    isInitialLoading: isLoading,
  } = useBillingInvoice(invoiceId, { enabled: !!invoiceId });

  if (connectionDataLoading || isLoading) {
    return <LoadingPage />;
  }

  if (connectionDataError || isError) {
    return <ErrorPage />;
  }

  const groupFn = groupBy((item) => (item as LineItem).planId || '');
  const groupedCharges = data?.data?.lineItems?.length
    ? (groupFn(data.data.lineItems) as GroupedCharges)
    : null;

  const discounts = data?.data?.lineItems?.length
    ? (data.data.lineItems.filter(
        // TODO remove reference to summarisationGroup and urn:flux:billing:line-item-tag:discount:v1 when these are retired
        (item: LineItem) =>
          item.planId === null &&
          (item.summarisationGroup === 'DISCOUNT' ||
            item.lineItemTags?.includes('urn:flux:billing:line-item-tag:type:discount') ||
            item.lineItemTags?.includes('urn:flux:billing:line-item-tag:discount:v1'))
      ) as unknown as LineItem[])
    : [];

  const connectionId = connectionData[0]?.connectionId ?? id;

  const contractedParty =
    externalData && externalData?.parties?.owner?.name ? externalData.parties.owner.name : '-';
  const contractedPartyId =
    externalData && externalData?.parties?.owner?.id ? externalData.parties.owner.id : undefined;

  return (
    <>
      <PageHeader
        title={() => (
          <>
            <Link
              className="page__title-link"
              to={`/connections/${id}/supply-periods/${supplyPeriodId}/contracted-parties/${contractedPartyId}`}
            >
              {connectionId}
            </Link>
            <img src={caretRight} alt="" />
            {`${data.data.invoiceNumber} Invoice charges`}
          </>
        )}
      />
      <Page>
        <InvoiceSummary
          totals={data.data.totals}
          context={data.context}
          contractedParty={contractedParty}
        />
        {groupedCharges &&
          Object.keys(groupedCharges)
            // it is a string because we are using Object.keys
            .filter((planId) => planId !== 'null' && planId !== '')
            .map((planId, index) => (
              <PlanTable
                key={`plan-table-${planId}-${index}`}
                index={index}
                planId={planId}
                lineItems={groupedCharges[planId]}
                spid={supplyPeriodId ? supplyPeriodId : undefined}
                cpid={contractedPartyId}
              />
            ))}
        {discounts && discounts.length > 0 && <DiscountsTable discounts={discounts} />}
      </Page>
    </>
  );
};

export default InvoiceDetails;
