import { parseURN } from 'util/helper-func';
import { MeasurementPoint, UsageSummaryV2Item } from '../../../api/openapi/telemetry';

const MEASUREMENT_POINT_SCOPED_TO_URN_PATTERN = 'urn:flux:network:connection:';

/**
 * Method to build a URN used for querying measurement points for a given connection ID (from Core).
 *
 * @param connectionAcronym nmi or icp
 * @param connectionId the connection to query.
 * @returns a URN used to query measurement points.
 * @example
 * // returns "urn:flux:network:connection:nmi:XX322266298"
 * buildScopedToQueryField("nmi", "XX322266298");
 */
function buildScopedToQueryField(connectionId: string) {
  return MEASUREMENT_POINT_SCOPED_TO_URN_PATTERN + connectionId;
}

/**
 *
 * @param externalIdentifier
 * @returns
 */
function buildMeterMeasurementPointIdentifier(externalIdentifier: string) {
  // Physical MP identifier is a concatenation parsed from the urn of external identifier, separated by a colon
  //    - AU: meter:register:stream
  //    - NZ: meter:stream
  let identifierArr: string[] = [];

  const parsedURN = parseURN(externalIdentifier);

  if (parsedURN.identifiers) {
    const identifiers = parsedURN.identifiers;

    if (identifiers.includes('meter')) {
      const meterIdentifierIndex = identifiers.indexOf('meter');
      identifierArr = [...identifierArr, identifiers[meterIdentifierIndex + 1]];
    }

    if (identifiers.includes('register')) {
      const registerIdentifierIndex = identifiers.indexOf('register');
      identifierArr = [...identifierArr, identifiers[registerIdentifierIndex + 1]];
    }

    if (identifiers.includes('stream')) {
      const streamIdentifierIndex = identifiers.indexOf('stream');
      identifierArr = [...identifierArr, identifiers[streamIdentifierIndex + 1]];
    }
  }
  return identifierArr.join(':');
}

/**
 *  constructs the externalIdentifier field which could be either
 *  urn:flux:network:connection:nmi:{NMI}:meter:{meter}:register:{register} for AU; or,
 *  urn:flux:network:connection:icp:{ICP}:meter:{meter} for NZ.
 * @param connectionAcronym nmi or icp
 * @param connectionId the connection ID
 * @param meterSerialNumber the meter serial number
 * @param register the register
 * @returns an external identifier built from the provided fields
 */
function buildExternalIdentifier(
  connectionAcronym: string,
  connectionId: string,
  meterSerialNumber: string,
  register: string
) {
  let identifierArr: string[] = [];

  if (connectionId) {
    identifierArr = [...identifierArr, `${connectionAcronym}:${connectionId}`];
  }

  if (meterSerialNumber) {
    identifierArr = [...identifierArr, `meter:${meterSerialNumber}`];
  }

  if (register) {
    identifierArr = [...identifierArr, `register:${register}`];
  }

  return MEASUREMENT_POINT_SCOPED_TO_URN_PATTERN + identifierArr.join(':');
}

export class ItemXMeasurementPoint {
  item: UsageSummaryV2Item;
  measurementPoint?: MeasurementPoint;

  constructor(newItem: UsageSummaryV2Item, newMeasurementPoint?: MeasurementPoint) {
    this.item = newItem;
    this.measurementPoint = newMeasurementPoint;
  }
}

function itemXMeasurementPointComparator(
  itemXMPA: ItemXMeasurementPoint,
  itemXMPB: ItemXMeasurementPoint
) {
  const { item: itemA, measurementPoint: measurementPointA } = itemXMPA;
  const { item: itemB, measurementPoint: measurementPointB } = itemXMPB;

  // Merx wont have measurement point
  if (measurementPointA && measurementPointB) {
    if (
      (measurementPointA.isSystemCreated && measurementPointB.isSystemCreated) ||
      (!measurementPointA.isSystemCreated && !measurementPointB.isSystemCreated)
    ) {
      if (itemA.measurementPoint.register && itemB.measurementPoint.register) {
        return registersComparator(
          itemA.measurementPoint.register,
          itemB.measurementPoint.register
        );
      }
    } else if (measurementPointA.isSystemCreated && !measurementPointB.isSystemCreated) {
      return -1;
    } else if (!measurementPointA.isSystemCreated && measurementPointB.isSystemCreated) {
      return 1;
    }

    if (
      itemXMPA.measurementPoint?.externalIdentifier == null &&
      itemXMPB.measurementPoint?.externalIdentifier == null
    ) {
      return 0;
    } else if (itemXMPA.measurementPoint?.externalIdentifier == null) {
      return 1;
    } else if (itemXMPB.measurementPoint?.externalIdentifier == null) {
      return -1;
    }
  }

  // We must have measurement points or else we do not sort
  return 0;
}

function registersComparator(registerA: string, registerB: string): number {
  if (registerA && registerB) {
    if (registerA > registerB) {
      return 1;
    }

    if (registerA < registerB) {
      return -1;
    }
  } else if (registerA && !registerB) {
    return 1;
  } else if (!registerB && registerB) {
    return -1;
  }

  return 0;
}

export {
  buildMeterMeasurementPointIdentifier,
  buildScopedToQueryField,
  buildExternalIdentifier,
  itemXMeasurementPointComparator,
};
