import { ErrorMessage, Field, Form, Formik, FormikValues } from 'formik';
import React, { FunctionComponent } from 'react';
import { object, string } from 'yup';
import { ProviderSummary, useCreateProvider, useUpdateProvider } from './providersApi';
import AuthorizedAction from '../common/AuthorizedAction';
import { FormRow } from '../form';
import FieldWrapper, { FieldWidth } from '../form/FieldWrapper';
import { ErrorText } from '../layout/Errors';
import Alert, { AlertEnum } from '../layout/Alert';
import { ErrorModel } from '../../api/ErrorDisplay';
import { Permission } from '../../auth/getPermissions';
import { getMarketFunction } from '../../list-data/getMarketFunction';
import { useListData } from '../../list-data/ListDataContext';
import { MarketMap } from '../connections/connectionsApi';
import { useFlags } from 'launchdarkly-react-client-sdk';

interface ProviderFormProps {
  onAfterSubmit: () => void;
  onCancel: () => void;
  provider?: ProviderSummary;
}

const validationSchema = object().shape({
  codes: string().label('Code(s)').max(255),
  defaultChargeTag: string().label('Charge component').required(),
  providerName: string().label('Provider name').required().max(255),
});

const ProviderForm: FunctionComponent<ProviderFormProps> = ({
  onAfterSubmit,
  onCancel,
  provider,
}) => {
  const [marketFunctionFlags] = useListData(['MARKET_FUNCTION']);

  const currentMarket = getMarketFunction(
    'urn:flux:rating-config:market',
    undefined,
    marketFunctionFlags
  );

  // feature flag for this
  const {
    cfd866OnBillFinance, // LD client key for cfd-866-on-bill-finance
  } = useFlags();

  const {
    error: createError,
    isLoading: createLoading,
    mutateAsync: createMutateAsync,
  } = useCreateProvider();

  const {
    error: updateError,
    isLoading: updateLoading,
    mutateAsync: updateMutateAsync,
  } = useUpdateProvider();

  const handleCreate = async (data: FormikValues) => {
    const result = await createMutateAsync({
      code: data.codes.trim(),
      defaultChargeTag: data.defaultChargeTag,
      name: data.providerName.trim(),
    });

    if (result) {
      onAfterSubmit();
    }
  };

  const handleUpdate = async (id: string, data: FormikValues) => {
    const result = await updateMutateAsync({
      code: data.codes.trim(),
      defaultChargeTag: data.defaultChargeTag,
      id,
      name: data.providerName.trim(),
    });

    if (result) {
      onAfterSubmit();
    }
  };

  return (
    <Formik
      initialValues={{
        providerName: provider?.providerName ?? '',
        codes: provider?.code ?? '',
        defaultChargeTag: provider?.defaultChargeTag ?? '',
      }}
      onSubmit={(data) => (provider ? handleUpdate(provider.providerId, data) : handleCreate(data))}
      validationSchema={validationSchema}
    >
      {({ dirty, isValid }) => (
        <Form
          className="apl-form-layout-v1"
          data-testid={provider ? 'update-provider-form' : 'create-provider-form'}
        >
          <>
            <h2>{provider ? 'Edit provider' : 'Add a provider'}</h2>
            {createError && (
              <Alert type={AlertEnum.DANGER}>
                {(createError as ErrorModel).message ?? 'Sorry, there was an error.'}
              </Alert>
            )}
            {updateError && (
              <Alert type={AlertEnum.DANGER}>
                {(updateError as ErrorModel).message ?? 'Sorry, there was an error.'}
              </Alert>
            )}
            <FieldWrapper
              fieldWidth={FieldWidth.FULL}
              htmlFor="provider-name-field"
              label="Provider name"
            >
              <Field
                autoComplete="off"
                className="apl-text-input-v1"
                data-testid="provider-name-field"
                id="provider-name-field"
                name="providerName"
              />
              <ErrorMessage component={ErrorText} name="providerName" />
            </FieldWrapper>
            <FormRow>
              <FieldWrapper
                fieldWidth={FieldWidth.HALF}
                htmlFor="expected-charge-tags-field"
                label="Code(s)"
                optional={true}
              >
                <Field
                  autoComplete="off"
                  className="apl-text-input-v1"
                  data-testid="codes-field"
                  id="codes-field"
                  name="codes"
                />
                <div
                  className="apl-field__help-text apl-mt-xs"
                  style={{
                    fontSize: '0.825rem',
                  }}
                >
                  Separate codes with a comma
                </div>
                <ErrorMessage component={ErrorText} name="codes" />
              </FieldWrapper>
              <FieldWrapper
                fieldWidth={FieldWidth.HALF}
                htmlFor="default-charge-tag-field"
                label="Charge component"
              >
                <Field
                  as="select"
                  autoComplete="off"
                  className="apl-select-v1_0"
                  data-testid="default-charge-tag-field"
                  id="default-charge-tag-field"
                  name="defaultChargeTag"
                >
                  <option value="">Please select</option>
                  <option value="ENERGY">Energy</option>
                  <option value="NETWORK">Network</option>
                  <option value="LEVY">Levy</option>
                  <option value="EQUIPMENT">Equipment</option>
                  <option value="ENERGYFEE">Energy fee</option>
                  {currentMarket === MarketMap.AU_MARKET && <option value="METRIC">Metric</option>}
                  {currentMarket === MarketMap.AU_MARKET && cfd866OnBillFinance && (
                    <option value="FINANCE">Finance</option>
                  )}
                </Field>
                <ErrorMessage component={ErrorText} name="defaultChargeTag" />
              </FieldWrapper>
            </FormRow>
            <div className="apl-display-flex apl-justify-content-end">
              <button
                data-testid="cancel-button"
                className="apl-button-v1"
                onClick={() => onCancel()}
                disabled={createLoading || updateLoading}
                type="button"
              >
                Cancel
              </button>
              <AuthorizedAction
                extraClasses="is-primary"
                isDisabled={createLoading || updateLoading || !isValid || !dirty}
                permission={Permission.PROVIDERS_EDIT}
                removeDefaultWeight={true}
                testId="save-provider-button"
                type="submit"
              >
                Save
              </AuthorizedAction>
            </div>
          </>
        </Form>
      )}
    </Formik>
  );
};

export default ProviderForm;
