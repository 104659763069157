import { useQuery } from '@tanstack/react-query';
import apiClient from '../../api/apiClient';

const baseEndpoint = '/loss-factors';

export interface LossFactorPageResponse {
  _itemType: LossFactorItemType;
  totalCount: number;
  items: LossFactor[];
  _links: Links;
}

type LossFactorItemType = 'loss-factor';

export interface LossFactor {
  id: string;
  validFrom?: string;
  validTo?: string;
  startsAt: string;
  endsAt: string;
  externalIdentifiers: string[];
  energyGoingToConnectionMultiplier: number;
  energyComingFromConnectionMultiplier: number;
  uploadedAt: string;
  lastUpdatedInSource: string;
}

interface Links {
  self: string;
  next: string;
  prev: string;
}

export interface LossFactorParams {
  startsAt?: string;
  endsAt?: string;
  externalIdentifiers?: string[];
  limit?: number;
  offset?: number;
}

export const useLossFactorCodes = (params: LossFactorParams, options?: Record<string, unknown>) => {
  const { startsAt, endsAt, externalIdentifiers, limit, offset } = params;

  return useQuery(
    ['loss-factor', startsAt, endsAt, externalIdentifiers, limit, offset],
    async () => {
      const search = new URLSearchParams();

      (Object.keys(params) as Array<keyof LossFactorParams>).forEach((key) => {
        if (params[key] !== undefined) {
          search.set(key, `${params[key]}`);
        }
      });

      return apiClient('loss-factor').get(`${baseEndpoint}?${search.toString()}`);
    },
    {
      ...options,
    }
  );
};

// get ALL loss factors API query
export const useAllLossFactorCodes = async () => {
  const apiResponse = await apiClient('loss-factor').get(`/loss-factors`); // initial zero offset paginated apiResponse
  // get all the pages and add it to the initial apiResponse
  let apiResponseNextPageLink = apiResponse._links.next;
  while (apiResponseNextPageLink != undefined) {
    const apiResponseNextPage = await apiClient('loss-factor').get(
      apiResponseNextPageLink.substring(apiResponseNextPageLink.lastIndexOf('/'))
    );
    apiResponse.items = apiResponse.items.concat(apiResponseNextPage.items); // append additional results to response
    apiResponseNextPageLink = apiResponseNextPage._links.next;
  }
  apiResponse._links.next = undefined; // apiResponse now has all the pages, so no next page to be linked
  return apiResponse;
};
