import React, { FunctionComponent } from 'react';
import { ErrorModel } from '../../../api/ErrorDisplay';
import EquationLinkForm from './EquationLinkForm';
import { Connection } from '../../../api/openapi/rating-config';
import {
  LinkConnectionsRequest,
  useCreateEquationLinks,
} from '../../telemetry-calculator/teleCalcApi';

interface AddEquationLinkProps {
  connection: Connection;
  close: () => void;
}

const AddEquationLink: FunctionComponent<AddEquationLinkProps> = ({ connection, close }) => {
  const { mutateAsync, isLoading: isSubmitting, error } = useCreateEquationLinks();
  const linkToConnections = async (linkConnectionRequest: LinkConnectionsRequest) => {
    const result = await mutateAsync(linkConnectionRequest);
    if (result) {
      close();
    }
  };

  return (
    <div className="apl-form-layout-v1" data-testid="link-to-equation-form">
      <h2>Link connection to usage equation</h2>
      <EquationLinkForm
        id={connection.id as string}
        connectionId={connection.connectionId as string}
        close={close}
        error={error as ErrorModel}
        isUpdating={isSubmitting}
        submitFunction={linkToConnections}
      />
    </div>
  );
};

export default AddEquationLink;
