import {
  EuiButton,
  EuiCallOut,
  EuiDatePicker,
  EuiFlexGrid,
  EuiFlexItem,
  EuiFormRow,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiSelect,
  EuiText,
} from '@elastic/eui';
import React from 'react';
import { useState } from 'react';
import { useStore } from 'store';
import { SupplyPeriod, SupplyPeriodSummary } from '../connectionsApi';
import { format, formatISO, max, min, subDays } from 'date-fns';
import { generateTimeIntervals, newMarketDate } from 'util/helper-func';
import moment from 'moment';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useTriggerRating } from 'components/rating-calculator/ratingsApi';
import { RequestRange } from './RequestChargesForm';

export default function RequestNewCharges({
  supplyPeriod,
  id,
  initialDates,
  updateFunction,
}: {
  supplyPeriod: SupplyPeriod | SupplyPeriodSummary;
  id: string;
  initialDates?: { start: string; end: string };
  updateFunction?: (data: RequestRange) => unknown;
}) {
  const today = newMarketDate();
  const endDate = supplyPeriod.endDate ? new Date(supplyPeriod.endDate) : null;
  const closeDate = min([endDate ?? today, today]);
  const {
    fbau920RequestChargesCallInTheUiToUseConfiguredTimezoneInsteadOfBrowserTimezone,
    inc6589DisableChargeSetsWhenCancelled, //inc-6589-disable-charge-sets-when-cancelled
  } = useFlags();
  const [modalOpen, setModalOpen] = useState(false);
  const intervals = generateTimeIntervals(30, 'HH:mm', false).concat(['24:00']);

  const [formData, setFormData] = useState<{
    startAt: string | undefined;
    endAt: string | undefined;
    fromTime: string;
    toTime: string;
  }>({
    startAt: initialDates?.start,
    endAt: initialDates?.end,
    fromTime: intervals[0],
    toTime: intervals[intervals.length - 1],
  });

  function adjustTime(date: Date): string {
    return new Date(2 * date.valueOf() - newMarketDate(date).valueOf()).toISOString();
  }

  function splitTime(value: string): [number, number] {
    const [hour, minutes] = value.split(':').map((n) => parseInt(n));
    return [hour, minutes];
  }
  const { error, isLoading, mutateAsync } = useTriggerRating();
  const isValid = () => formData.startAt && formData.endAt && formData.startAt < formData.endAt;
  const handleSubmit = async () => {
    if (formData.startAt && formData.endAt && formData.fromTime && formData.toTime) {
      const endAtDate = new Date(formData.endAt);
      endAtDate.setHours(...splitTime(formData.toTime), 0, 0);
      const startAtDate = new Date(formData.startAt);
      startAtDate.setHours(...splitTime(formData.fromTime), 0, 0);
      const startAt =
        fbau920RequestChargesCallInTheUiToUseConfiguredTimezoneInsteadOfBrowserTimezone
          ? adjustTime(startAtDate)
          : formatISO(startAtDate, { representation: 'complete' });

      const endAt = fbau920RequestChargesCallInTheUiToUseConfiguredTimezoneInsteadOfBrowserTimezone
        ? adjustTime(endAtDate)
        : formatISO(endAtDate, { representation: 'complete' });

      // make the request
      setModalOpen(false);
      updateFunction && updateFunction({ startAt, endAt });
      const { error } = await mutateAsync({
        scopedTo: id,
        startAt,
        endAt,
        purpose: 'urn:flux:rating:purpose:billable',
      });

      if (error) {
        useStore.getState().addNotification({
          color: 'danger',
          title: 'Failed to request charges.',
          id: 'charge-set-failed',
        });
      } else {
        useStore.getState().addNotification({
          color: 'success',
          title:
            'New charges requested for period ' +
            format(startAtDate, 'd MMM yyyy') +
            ' to ' +
            format(subDays(endAtDate, 1), 'd MMM yyyy') +
            '.',
          id: 'charge-set-created',
        });
      }
    }
  };
  return (
    <>
      <EuiButton
        data-testid="openButton"
        className="apl-button-v1"
        onClick={() => setModalOpen(true)}
        color="text"
        disabled={
          inc6589DisableChargeSetsWhenCancelled &&
          (!supplyPeriod || supplyPeriod.status == 'CANCELLED')
        }
      >
        Request new charges
      </EuiButton>
      {modalOpen && (
        <EuiModal onClose={() => setModalOpen(false)} style={{ padding: 10 }}>
          <EuiModalHeader>
            <EuiModalHeaderTitle data-testid="titleElement">
              Request new charges
            </EuiModalHeaderTitle>
          </EuiModalHeader>
          <EuiModalBody>
            <EuiText style={{ padding: 10 }}>
              {'Allowable date range: ' +
                format(new Date(supplyPeriod.startDate), 'dd/MM/yyyy') +
                ' to ' +
                format(
                  supplyPeriod?.endDate ? new Date(supplyPeriod.endDate) : today,
                  'dd/MM/yyyy'
                )}
            </EuiText>
            <>
              {error && (
                <EuiCallOut title="Error" color="danger" iconType="alert">
                  Sorry, there was an error
                </EuiCallOut>
              )}
            </>
            <EuiFlexGrid gutterSize="xl" columns={2} style={{ padding: 10 }}>
              <EuiFlexItem>
                <EuiFormRow label="From date" fullWidth data-testid="fromDate">
                  <EuiDatePicker
                    dateFormat="DD/MM/yyyy"
                    selected={formData.startAt ? moment(formData.startAt) : undefined}
                    onChange={(date) =>
                      setFormData({
                        ...formData,
                        startAt: max([
                          date?.toDate() ?? 0,
                          new Date(supplyPeriod.startDate),
                        ])?.toISOString(),
                      })
                    }
                    fullWidth
                    data-testid="fromDatePicker"
                    isInvalid={
                      !!(formData.startAt && formData.endAt && formData.startAt > formData.endAt)
                    }
                    id="from-date"
                    minDate={moment(supplyPeriod.startDate)}
                    maxDate={
                      formData.endAt
                        ? moment(min([new Date(formData.endAt), closeDate]))
                        : moment(closeDate)
                    }
                  />
                </EuiFormRow>
              </EuiFlexItem>
              <EuiFlexItem>
                <EuiFormRow label="To date" fullWidth data-testid="toDate">
                  <EuiDatePicker
                    dateFormat="DD/MM/yyyy"
                    name="To Date"
                    className="toDate"
                    selected={formData.endAt ? moment(formData.endAt) : undefined}
                    onChange={(date) =>
                      setFormData({
                        ...formData,
                        endAt: min([closeDate, date?.toDate() ?? closeDate])?.toISOString(),
                      })
                    }
                    fullWidth
                    isInvalid={
                      !!(formData.startAt && formData.endAt && formData.startAt > formData.endAt)
                    }
                    id="to-date"
                    minDate={
                      formData.startAt
                        ? moment(
                            min([new Date(formData.startAt), new Date(supplyPeriod.startDate)])
                          )
                        : moment(supplyPeriod.startDate)
                    }
                    maxDate={moment(closeDate)}
                  />
                </EuiFormRow>
              </EuiFlexItem>
              <EuiFlexItem>
                <EuiFormRow label="From time" fullWidth data-testid="fromTime">
                  <EuiSelect
                    options={intervals.slice(0, -1).map((value) => ({ value, text: value }))}
                    value={formData.fromTime}
                    onChange={(e) => setFormData({ ...formData, fromTime: e.target.value })}
                    fullWidth
                    id="from-time"
                  />
                </EuiFormRow>
              </EuiFlexItem>
              <EuiFlexItem>
                <EuiFormRow label="To time" fullWidth data-testid="toTime">
                  <EuiSelect
                    options={intervals.slice(1).map((value) => ({ value, text: value }))}
                    value={formData.toTime}
                    onChange={(e) => setFormData({ ...formData, toTime: e.target.value })}
                    fullWidth
                    id="to-time"
                  />
                </EuiFormRow>
              </EuiFlexItem>
            </EuiFlexGrid>
          </EuiModalBody>
          <EuiModalFooter>
            <EuiButton onClick={() => setModalOpen(false)} type="button" data-testid="cancelButton">
              Cancel
            </EuiButton>
            <EuiButton
              onClick={handleSubmit}
              fill
              isDisabled={isLoading || !isValid()}
              type="submit"
              data-testid="requestButton"
            >
              Request
            </EuiButton>
          </EuiModalFooter>
        </EuiModal>
      )}
    </>
  );
}
