import React, { FunctionComponent, useState } from 'react';
import Modal from 'react-modal';
import { Link, useParams } from 'react-router-dom';
import SupplyPeriodForm from './SupplyPeriodForm';
import SupplyPeriodRow from './SupplyPeriodRow';
import {
  SupplyPeriodBody,
  SupplyPeriodSummary,
  useSupplyPeriodCreation,
  useSupplyPeriodSummary,
} from '../connectionsApi';
import AuthorizedAction from '../../common/AuthorizedAction';
import { modalStyles } from '../../layout/Modal';
import Page, { PageHeader } from '../../layout/Page';
import { ErrorModel } from '../../../api/ErrorDisplay';
import { Permission } from '../../../auth/getPermissions';
import { isTest, isProd } from '../../../environment';
import caretRight from '../../../icons/caret-right.svg';

const ConnectionSummary: FunctionComponent = () => {
  const { id, contractedPartyId } = useParams<{ id: string; contractedPartyId: string }>();

  const [createSupplyPeriodIsOpen, setCreateSupplyPeriodIsOpen] = useState(false);
  const closeCreateSupplyPeriod = () => setCreateSupplyPeriodIsOpen(false);
  const openCreateSupplyPeriod = () => setCreateSupplyPeriodIsOpen(true);

  const {
    data,
    isInitialLoading: isLoading,
    isError,
  } = useSupplyPeriodSummary(id, { enabled: !!id });

  const {
    mutateAsync,
    isLoading: isSubmitting,
    error,
  } = useSupplyPeriodCreation(id, { enabled: !!id });

  if (isLoading) {
    return <p>Is loading</p>;
  }

  if (isError) {
    return <p>Something went wrong</p>;
  }

  const createSupplyPeriod = async (
    contractedParty: string,
    startDate: string,
    endDate: string
  ) => {
    const newSupplyPeriod: SupplyPeriodBody = {
      contractedParty,
      startDate,
      endDate,
    } as SupplyPeriodBody;

    const result = await mutateAsync(newSupplyPeriod);

    if (result) {
      closeCreateSupplyPeriod();
    }
  };

  // backlink can either be /connections or /connections/:id/contracted-parties/:contractedPartyId
  let backLink = '/connections';

  // need a copy of data otherwise the cached useQuery data gets altered and causes trouble when going back
  const displayData = { ...data };
  if (contractedPartyId) {
    displayData.supplyPeriodSummaries = data.supplyPeriodSummaries.filter(
      (sp: SupplyPeriodSummary) => sp.owner === contractedPartyId
    );
    backLink = `/connections/${id}`;
  }

  return (
    <>
      <PageHeader
        backLink={backLink}
        title={() => (
          <>
            <Link className="page__title-link" to={`/connections/${displayData.id}`}>
              {displayData.connectionId}
            </Link>
            <img src={caretRight} alt="" />
            Supply periods
          </>
        )}
      >
        {!(isTest() || isProd()) && (
          <AuthorizedAction
            extraClasses="is-primary"
            onClick={openCreateSupplyPeriod}
            permission={Permission.CONNECTION_EDIT}
            testId="new-supply-period"
          >
            Add new supply period
          </AuthorizedAction>
        )}
      </PageHeader>
      <Page>
        {displayData.supplyPeriodSummaries.length > 0 ? (
          <table className="apl-table-v1 half_xl">
            <thead>
              <tr>
                <th>Supply period</th>
                <th>Contracted party</th>
                <th>Plans</th>
                <th>Status</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {displayData.supplyPeriodSummaries.map((s: SupplyPeriodSummary, index: number) => (
                <SupplyPeriodRow connectionId={id} index={index} key={index} supplyPeriod={s} />
              ))}
            </tbody>
          </table>
        ) : (
          <div>
            <h3>No Supply Periods</h3>
          </div>
        )}
        <Modal
          isOpen={createSupplyPeriodIsOpen}
          onRequestClose={closeCreateSupplyPeriod}
          shouldCloseOnOverlayClick={false}
          style={modalStyles}
        >
          <SupplyPeriodForm
            close={closeCreateSupplyPeriod}
            error={error as ErrorModel}
            isUpdating={isSubmitting}
            submitFunction={createSupplyPeriod}
            title="New supply period"
          />
        </Modal>
      </Page>
    </>
  );
};

export default ConnectionSummary;
