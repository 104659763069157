import { format, subDays } from 'date-fns';
import React, { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';
import { MissingPlanItem } from '../../reports/reportsApi';

const ConnectionMissingPlanRow = (item: MissingPlanItem) => {
  const { externalConnectionIdentifier, id, supplyPeriod, pricingGap } = item;

  // format date for display in table
  const dateFormat = 'd MMM yyyy';

  const supplyPeriodStart = supplyPeriod.startsAt
    ? format(new Date(supplyPeriod.startsAt), dateFormat)
    : '';

  const supplyPeriodEnd = supplyPeriod.endsAt
    ? format(new Date(supplyPeriod.endsAt), dateFormat)
    : '';

  const expiryDate =
    pricingGap.expiringPlan && pricingGap.expiringPlan.id
      ? format(subDays(new Date(pricingGap.gapStartsAt), 1), dateFormat)
      : '-';

  const link = supplyPeriod.owner
    ? `/connections/${id}/contracted-parties/${supplyPeriod.owner}`
    : `/connections/${id}`;

  return (
    <tr className="connection-missing-plan__row">
      <td>
        <Link className="apl-color-primary" to={link}>
          {externalConnectionIdentifier}
        </Link>
      </td>
      <td>{`${supplyPeriodStart} - ${supplyPeriodEnd}`}</td>
      <td>{pricingGap.expiringPlan?.name ?? '-'}</td>
      <td>{expiryDate}</td>
    </tr>
  );
};

interface ConnectionsMissingPlanTableProps {
  data: MissingPlanItem[];
}

const ConnectionsMissingPlanTable: FunctionComponent<ConnectionsMissingPlanTableProps> = ({
  data,
}) => {
  return (
    <table className="apl-table-v1">
      <thead>
        <tr>
          <th>Connection ID</th>
          <th>Supply period</th>
          <th>Plan</th>
          <th>Expiry date</th>
        </tr>
      </thead>
      <tbody>
        {data &&
          data.map((link, index) => {
            // to create a unique key per line item
            const keyParts = ['plan-link', index];
            return <ConnectionMissingPlanRow key={`${keyParts.join('-')}`} {...link} />;
          })}
      </tbody>
    </table>
  );
};

export default ConnectionsMissingPlanTable;
