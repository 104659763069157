import { isBefore, isDate } from 'date-fns';
import { ErrorMessage, Field, Form, Formik, FieldArray } from 'formik';
import { isEmpty, isNil } from 'ramda';
import React, { ChangeEvent, FunctionComponent } from 'react';
import { date, object, string, array } from 'yup';
import { QualifierDetailsType, rangeTypeToValuesList, timeRanges } from './qualifiersApi';
import AuthorizedAction from '../common/AuthorizedAction';
import { FormRow } from '../form';
import ButtonAsLink from '../form/ButtonAsLink';
import FluxDatePicker from '../form/DatePicker';
import FieldWrapper, { FieldWidth, inputClassNameBuilder } from '../form/FieldWrapper';
import Alert, { AlertEnum } from '../layout/Alert';
import { ErrorText } from '../layout/Errors';
import { Provider, useProviders } from '../providers/providersApi';
import { ErrorModel } from '../../api/ErrorDisplay';
import { Permission } from '../../auth/getPermissions';
import { capitaliseFirstText } from '../../util/helper-func';
import {
  Range,
  MultiRangeQualifierBody,
  Operator,
  RangeToken,
} from '../../api/openapi/rating-config';

const qualifierSchema = object().shape({
  name: string().label('Qualifier name').trim().required().max(200),
  type: string().label('Qualifier type').required(),
  expressionList: array().of(
    object({
      from: string().label('From').required(),
      to: string().label('To').required(),
    })
  ),
  startDate: date()
    .typeError('Must be a valid date')
    .max(new Date('9999-12-31'))
    .label('Start date')
    .required(),
  endDate: date()
    .typeError('Must be a valid date')
    .max(new Date('9999-12-31'))
    .label('End date')
    .when('startDate', (startDate: Date, schema: any) => {
      if (isDate(startDate) && isBefore(startDate, new Date('9999-12-31'))) {
        return schema.min(startDate, 'End date must be after start date');
      } else {
        return schema;
      }
    }),
});

interface QualifierFormProps {
  close: () => void;
  error?: ErrorModel;
  isUpdating: boolean;
  qualifier?: QualifierDetailsType;
  submitFunction: (qualifierBody: MultiRangeQualifierBody) => Promise<any>;
}

const QualifierForm: FunctionComponent<QualifierFormProps> = ({
  close,
  error,
  qualifier,
  submitFunction,
}) => {
  const isEdit = !isNil(qualifier);
  const {
    data: providers,
    isError: providerError,
    isInitialLoading: loadingProviders,
  } = useProviders();

  const filteredTimeRanges = timeRanges.filter(
    (data) => data.key != 'WORKING_DAY_RANGE' && data.key != 'NON_WORKING_DAY_RANGE'
  );

  if (providerError) {
    return <p>Error</p>;
  }

  if (loadingProviders) {
    return <p data-testid="loading">Loading</p>;
  }

  /**
   *
   * @param timeRanges
   * @param qualifierType
   * @returns
   * This method builds Expression Range array based on the api spec.
   * sample:
   * [
   *  {Time Range},
   *  {Time Range},
   *  {Operator},
   *  {Time Range}
   * ]
   */
  const buildExpressionRange = (timeRanges: (Range | Operator)[], qualifierType: Range.type) => {
    const expressionArray: Array<Range | Operator> = [];

    if (timeRanges !== null) {
      // this is a single time range, categorised as a non multi-hump qualifier
      if (timeRanges.length === 1) {
        const singleRange: Range = {
          type: qualifierType,
          from: (timeRanges[0] as Range).from,
          to: (timeRanges[0] as Range).to,
          tokenType: RangeToken.tokenType.RANGE,
        };
        expressionArray.push(singleRange);
      } else if (timeRanges.length > 1) {
        // this has more than one time ranges so categorised as a multi-hump qualifier
        timeRanges.forEach((item, index) => {
          const from = (item as Range).from;
          const to = (item as Range).to;

          if (from || to) {
            if (index === 0 || index === 1) {
              // first two items should be operands according to reverse polish notation so pushing range objects to the array
              const topRange: Range = {
                type: qualifierType,
                from: from,
                to: to,
                tokenType: RangeToken.tokenType.RANGE,
              };
              expressionArray.push(topRange);
            } else {
              // after first two elements, every two-element block should consists with operator then operand according to reverse polish notation
              // so pushing operator and range objects together to the array

              const operator: Operator = {
                operatorType: Operator.operatorType.UNION,
                tokenType: RangeToken.tokenType.OPERATOR,
              };
              expressionArray.push(operator);

              const tailRange: Range = {
                type: qualifierType,
                from: from,
                to: to,
                tokenType: RangeToken.tokenType.RANGE,
              };
              expressionArray.push(tailRange);
            }
          }
        });

        // last element should always be an operator so pushing operator to the array
        const tailOperator: Operator = {
          operatorType: Operator.operatorType.UNION,
          tokenType: RangeToken.tokenType.OPERATOR,
        };
        expressionArray.push(tailOperator);
      }
    }

    return expressionArray;
  };

  return (
    <Formik
      initialValues={{
        name: isEdit ? `${qualifier?.name}` : '',
        providerId: isEdit && qualifier?.providerId ? `${qualifier?.providerId}` : '',

        type: isEdit ? `${(qualifier?.expressionList?.[0] as Range).type}` : '',
        // from: isEdit ? `${(qualifier?.expressionList[0] as unknown as Range).from}` : '',
        // to: isEdit ? `${(qualifier?.expressionList[0] as unknown as Range).to}` : '',
        expressionList: qualifier?.expressionList
          ? qualifier.expressionList.filter(
              (o) => (o as unknown as Operator).operatorType !== 'UNION'
            )
          : [
              {
                tokenType: 'Range',
                from: '',
                to: '',
                operatorType: null,
                type: '',
              },
            ],
        startDate: isEdit ? `${qualifier?.startDate}` : '',
        endDate: isEdit && qualifier?.endDate ? `${qualifier?.endDate}` : '',
      }}
      onSubmit={(qualifierForm) => {
        const multiRangeQualifierBody: MultiRangeQualifierBody = {
          endDate: qualifierForm.endDate,
          startDate: qualifierForm.startDate,
          name: qualifierForm.name,
          expressionList: buildExpressionRange(
            qualifierForm.expressionList as (Range | Operator)[],
            qualifierForm.type as Range.type
          ),
          providerId: !isEmpty(qualifierForm.providerId) ? qualifierForm.providerId : undefined,
        };

        return submitFunction(multiRangeQualifierBody);
      }}
      validationSchema={qualifierSchema}
    >
      {({ errors, touched, dirty, isSubmitting, isValid, values, handleChange, setFieldValue }) => (
        <Form className="apl-form-layout-v1">
          {error && (
            <Alert type={AlertEnum.DANGER}>
              {isEdit ? 'Could not update qualifier' : 'Could not create qualifier'}
            </Alert>
          )}
          <FieldWrapper
            htmlFor="qualifier-name"
            label="Qualifier name"
            fieldWidth={FieldWidth.FULL}
          >
            <Field
              autoComplete="off"
              data-testid="qualifier-name-field"
              name="name"
              id="qualifier-name"
              className={inputClassNameBuilder('name', errors, touched)}
            />
            <ErrorMessage component={ErrorText} name="name" />
          </FieldWrapper>

          <FormRow>
            <FieldWrapper htmlFor="provider-field" label="Provider" fieldWidth={FieldWidth.HALF}>
              <Field
                autoComplete="off"
                data-testid="provider-field"
                name="providerId"
                id="provider-field"
                className={inputClassNameBuilder('providerId', errors, touched)}
                as="select"
              >
                <option value="">All</option>
                {(providers as Provider[] | undefined)?.map((p: Provider, index: number) => (
                  <option key={index} value={p.id}>
                    {p.name}
                  </option>
                ))}
              </Field>
              <ErrorMessage component={ErrorText} name="providerId" />
            </FieldWrapper>
            <FieldWrapper htmlFor="type-field" label="Type" fieldWidth={FieldWidth.HALF}>
              <Field
                autoComplete="off"
                data-testid="type-field"
                name="type"
                id="type-field"
                className={inputClassNameBuilder('type', errors, touched)}
                as="select"
                onChange={(e: ChangeEvent) => {
                  handleChange(e);

                  // reset range values
                  setFieldValue('expressionList.0.to', '');
                  setFieldValue('expressionList.0.from', '');
                }}
              >
                <option value="" />
                {filteredTimeRanges.map(({ value, key }, index: number) => (
                  <option key={index} value={key}>
                    {value}
                  </option>
                ))}
              </Field>
              <ErrorMessage component={ErrorText} name="type" />
            </FieldWrapper>
          </FormRow>

          <FormRow>
            <FieldWrapper
              htmlFor="start-date-field"
              label="Start date"
              fieldWidth={FieldWidth.HALF}
            >
              <Field
                autoComplete="off"
                data-testid="start-date-field"
                name="startDate"
                id="start-date-field"
                className={inputClassNameBuilder('startDate', errors, touched)}
                component={FluxDatePicker}
              />
              <ErrorMessage component={ErrorText} name="startDate" />
            </FieldWrapper>
            <FieldWrapper htmlFor="end-date-field" label="End date" fieldWidth={FieldWidth.HALF}>
              <Field
                autoComplete="off"
                data-testid="end-date-field"
                name="endDate"
                id="end-date-field"
                component={FluxDatePicker}
                className={inputClassNameBuilder('endDate', errors, touched)}
              />
              <ErrorMessage component={ErrorText} name="endDate" />
            </FieldWrapper>
          </FormRow>

          {values.type !== 'WORKING_DAY_RANGE' &&
            values.type !== 'NON_WORKING_DAY_RANGE' &&
            values.type !== 'TIME_RANGE' && (
              <FormRow>
                <FieldWrapper htmlFor="from-field" label="From" fieldWidth={FieldWidth.HALF}>
                  <Field
                    autoComplete="off"
                    data-testid="from-field"
                    name="expressionList.0.from"
                    id="from-field"
                    className={inputClassNameBuilder('from', errors, touched)}
                    as="select"
                  >
                    <option value="" />
                    {rangeTypeToValuesList(values['type']).map((value, index: number) => (
                      <option key={index} value={value}>
                        {values['type'] === 'TIME_RANGE' ? value : capitaliseFirstText(value)}
                      </option>
                    ))}
                  </Field>
                  <ErrorMessage component={ErrorText} name="expressionList.0.from" />
                </FieldWrapper>
                <FieldWrapper htmlFor="to-field" label="To" fieldWidth={FieldWidth.HALF}>
                  <Field
                    autoComplete="off"
                    data-testid="to-field"
                    name="expressionList.0.to"
                    id="to-field"
                    className={inputClassNameBuilder('to', errors, touched)}
                    as="select"
                  >
                    <option value="" />
                    {rangeTypeToValuesList(values['type']).map((value, index: number) => (
                      <option key={index} value={value}>
                        {capitaliseFirstText(value)}
                      </option>
                    ))}
                  </Field>
                  <ErrorMessage component={ErrorText} name="expressionList.0.to" />
                </FieldWrapper>
              </FormRow>
            )}

          {values.type === 'TIME_RANGE' && (
            <FieldArray
              name="expressionList"
              render={(arrayHelpers) => (
                <div>
                  <div className="apl-display-flex apl-flex-row apl-mb-l">
                    <h3 className="form__subheading apl-mb-none">Time</h3>
                    <ButtonAsLink
                      data-testid="add-time-range"
                      disabled={false}
                      type="button"
                      className="apl-my-m apl-ml-s"
                      onClick={() =>
                        arrayHelpers.push({
                          tokenType: 'Range',
                          from: '',
                          to: '',
                          operatorType: null,
                          type: 'TIME_RANGE',
                        })
                      }
                    >
                      Add another time
                    </ButtonAsLink>
                  </div>
                  {(values.expressionList as any)
                    .filter((o: { operatorType: string }) => o.operatorType !== 'UNION')
                    .map((r: any, index: number) => (
                      <FormRow key={index}>
                        <FieldWrapper
                          htmlFor={`from-field-${index}`}
                          label="From"
                          fieldWidth={FieldWidth.THIRD}
                          className="apl-mb-l"
                        >
                          <Field
                            autoComplete="off"
                            data-testid={`from-field-${index}`}
                            name={`expressionList.${index}.from`}
                            id={`from-field-${index}`}
                            className={inputClassNameBuilder(`from-${index}`, errors, touched)}
                            as="select"
                          >
                            <option value="" />
                            {rangeTypeToValuesList(values['type']).map((value, index: number) => (
                              <option key={index} value={value}>
                                {values['type'] === 'TIME_RANGE'
                                  ? value
                                  : capitaliseFirstText(value)}
                              </option>
                            ))}
                          </Field>
                          <ErrorMessage
                            component={ErrorText}
                            name={`expressionList.${index}.from`}
                          />
                        </FieldWrapper>
                        <FieldWrapper
                          htmlFor={`to-field-${index}`}
                          label="To"
                          fieldWidth={FieldWidth.THIRD}
                        >
                          <Field
                            autoComplete="off"
                            data-testid={`to-field-${index}`}
                            name={`expressionList.${index}.to`}
                            id={`to-field-${index}`}
                            className={inputClassNameBuilder(`to-${index}`, errors, touched)}
                            as="select"
                          >
                            <option value="" />
                            {rangeTypeToValuesList(values['type']).map((value, index: number) => (
                              <option key={index} value={value}>
                                {capitaliseFirstText(value)}
                              </option>
                            ))}
                          </Field>
                          <ErrorMessage component={ErrorText} name={`expressionList.${index}.to`} />
                        </FieldWrapper>

                        <div
                          style={{
                            marginRight: '6px',
                            textAlign: 'right',
                            width: '30px',
                            marginTop: '24px',
                            marginLeft: '30px',
                          }}
                        >
                          {index !== 0 && (
                            <button
                              disabled={false}
                              onClick={() => arrayHelpers.remove(index)}
                              className="form__delete-rate"
                              type="button"
                            >
                              <span className="material-icons" style={{ fontSize: '18px' }}>
                                close
                              </span>
                            </button>
                          )}
                        </div>
                      </FormRow>
                    ))}
                </div>
              )}
            />
          )}

          <div className="apl-display-flex apl-justify-content-end">
            <button
              data-testid="cancel-button"
              className="apl-button-v1"
              onClick={close}
              type="button"
            >
              Cancel
            </button>
            <AuthorizedAction
              extraClasses="is-primary"
              isDisabled={isSubmitting || !isValid || (!dirty && isEdit)}
              permission={Permission.QUALIFIERS_EDIT}
              testId="add-plan-button"
              type="submit"
            >
              {isEdit ? 'Save' : 'Next'}
            </AuthorizedAction>
          </div>
        </Form>
      )}
    </Formik>
  );
};
export default QualifierForm;
