import React, { FunctionComponent, useState } from 'react';
import Modal from 'react-modal';
import { Widget } from 'components/dashboard/Dashboard';
import { Permission } from 'auth/getPermissions';
import AuthorizedAction from 'components/common/AuthorizedAction';
import { Connection } from 'api/openapi/rating-config';
import { modalStyles } from '../../layout/Modal';
import { useEquationLinks } from '../../telemetry-calculator/teleCalcApi';
import EquationLinkItem from './EquationLinkItem';
import AddEquationLink from './AddEquationLink';

interface ConnectionEquationLinkProps {
  connection: Connection;
}

const EquationLinksSummary: FunctionComponent<ConnectionEquationLinkProps> = ({ connection }) => {
  const [modalOpen, setModalOpen] = useState(false);

  const {
    data,
    isError,
    isInitialLoading: isLoading,
  } = useEquationLinks({
    connection: connection.id,
  });

  const closeModal = () => {
    setModalOpen(false);
  };

  return (
    <>
      <Widget
        className="apl-mt-none"
        headerComponent={
          <h3 className="apl-h3 widget__title widget__title--inline apl-mb-none">
            Equation Links
            <AuthorizedAction
              testId="link-to-equation"
              extraClasses="apl-ml-l"
              permission={Permission.METRIC_CREATE}
              onClick={() => {
                setModalOpen(true);
              }}
            >
              Link to equation
            </AuthorizedAction>
          </h3>
        }
        width="full"
      >
        {isLoading && <p className="apl-ml">Loading...</p>}
        {isError && <p className="apl-ml">Sorry, there was an error.</p>}
        {!isLoading &&
          !isError &&
          (data && data.items && data.items.length > 0 ? (
            <>
              <ul className="widget__list">
                {data.items.map((equationLink) => (
                  <li key={equationLink.id} className="widget__list-item">
                    {
                      <EquationLinkItem
                        equationLink={equationLink}
                        connectionId={connection.connectionId ?? ''}
                      />
                    }
                  </li>
                ))}
              </ul>
            </>
          ) : (
            <p className="apl-ml">Sorry, no equation links found.</p>
          ))}
      </Widget>
      <Modal
        isOpen={modalOpen}
        onRequestClose={closeModal}
        shouldCloseOnOverlayClick={false}
        style={modalStyles}
      >
        {<AddEquationLink connection={connection} close={closeModal} />}
      </Modal>
    </>
  );
};

export default EquationLinksSummary;
