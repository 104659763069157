import { MetricConfigEnum } from './metricsApi';

/**
 *  constructs the metric externalIdentifier field, for example
 *  urn:flux:network:connection:{connection ID}:contracted-party:{contracted party ID}
 * @param connectionId the connection ID
 * @param contractedParty the contracted party ID
 * @returns an external identifier built from the provided fields
 */
function buildExternalIdentifier(
  connectionId: string | undefined,
  contractedParty: string | undefined
) {
  let identifierArr: string[] = [];

  if (connectionId) {
    identifierArr = [...identifierArr, connectionId];
  }

  if (contractedParty) {
    identifierArr = [...identifierArr, `contracted-party:${contractedParty}`];
  }

  return [MetricConfigEnum.metricConnectionUrn, ...identifierArr].join(':');
}

export { buildExternalIdentifier };
