import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useConnectionDetails, usePlanLinkHistory } from '../connections/connectionsApi';
import PlanLinkHistoryTable from './PlanLinkHistoryTable';
import { PlanLinkV3 } from '../../api/openapi/rating-config';
import { ratingConfigClient } from '../../api';
import Page, { PageHeader } from '../layout/Page';
import PlanLinkHistorySupplyPeriod from './PlanLinkHistorySupplyPeriod';
import EmptyTableRow from '../common/EmptyTableRow';
import Card from '../layout/Card';

export interface PlanLinkHistoryState {
  connectionId: string;
  supplyPeriodStartDate: string;
  supplyAgreementStatus: string;
  contractedParty: string;
}

export type EnrichedPlanLinkV3 = PlanLinkV3 & {
  providerId: string;
  providerName: string;
};

export type EnrichedPlanLinks = {
  planLinks: Array<EnrichedPlanLinkV3>;
};

const PlanLinkHistory = () => {
  const { supplyPeriodId } = useParams();

  const location = useLocation();
  const state = location.state as PlanLinkHistoryState;
  const connectionId = state?.connectionId;
  const supplyPeriodStartDate = state?.supplyPeriodStartDate;
  const supplyAgreementStatus = state?.supplyAgreementStatus;
  const contractedParty = state?.contractedParty;

  const {
    data: planLinkHistoryData,
    isInitialLoading: planLinkHistoryLoading,
    isError: planLinkHistoryError,
  } = usePlanLinkHistory({
    supplyPeriodId: supplyPeriodId,
    includeRemoved: true,
    options: { enabled: !!supplyPeriodId },
  });

  const {
    data: connectionData,
    isError: connectionDataError,
    isInitialLoading: connectionDataLoading,
  } = useConnectionDetails(connectionId, { enabled: !!connectionId });

  const [enrichedPlanLinks, setEnrichedPlanLinks] = useState<EnrichedPlanLinks | null>(null);
  const [providerNamesLoading, setProviderNamesLoading] = useState(false);
  const [providerNamesError, setProviderNamesError] = useState<string | null>(null);

  useEffect(() => {
    if (planLinkHistoryData) {
      const fetchProviderNames = async () => {
        setProviderNamesLoading(true);
        try {
          const enrichedLinks: EnrichedPlanLinkV3[] = await Promise.all(
            planLinkHistoryData.items.map(async (link) => {
              try {
                const planData = await ratingConfigClient.plans.getPlanV2ById(link.planId || '');
                return {
                  ...link,
                  providerName: planData?.providerName || 'Unknown Plan', // Use providerName or fallback
                  providerId: planData?.providerId,
                };
              } catch (error) {
                return {
                  ...link,
                  providerName: 'Unknown', // Fallback in case of an error
                  providerId: '',
                };
              }
            })
          );
          setEnrichedPlanLinks({ planLinks: enrichedLinks });
          setProviderNamesError(null);
        } catch (error) {
          setProviderNamesError('Failed to enrich plan links.');
        } finally {
          setProviderNamesLoading(false);
        }
      };

      fetchProviderNames();
    }
  }, [JSON.stringify(planLinkHistoryData)]);

  if (planLinkHistoryLoading || connectionDataLoading || providerNamesLoading) {
    return <div>Loading...</div>; // Loading state
  }

  if (planLinkHistoryError || connectionDataError || providerNamesError) {
    return (
      <Card>
        <div className="apl-display-flex apl-justify-content-center">
          <EmptyTableRow message="Sorry, but we were unable to retrieve the plan link history. Please try again later." />
        </div>
      </Card>
    );
  }

  return (
    <>
      <PageHeader
        title={
          (connectionData?.[0]?.connectionId || 'Connection details') +
          ' > Plan link history for ' +
          supplyPeriodStartDate
        }
      />
      <Page>
        {enrichedPlanLinks && (
          <>
            <PlanLinkHistorySupplyPeriod
              contractedPartyName={contractedParty}
              supplyPeriodStartDate={supplyPeriodStartDate}
              supplyAgreementStatus={supplyAgreementStatus}
            />
            {enrichedPlanLinks && <PlanLinkHistoryTable data={enrichedPlanLinks} />}
          </>
        )}
      </Page>
    </>
  );
};

export default PlanLinkHistory;
