import { SourceType } from './errorsApi';

interface CategoryData {
  tabName: string;
  url: string;
  urns?: SourceType[];
}

interface CategoryDetails {
  label: string;
  urns: SourceType[];
}

export const generateSourceLabels = (): Record<SourceType, string> => ({
  // Rating Config Import
  'urn:flux:rating-config:csv-plan-importer': 'Import Plan',
  'urn:flux:rating-config:generic-plan-importer': 'Import Generic Plan',
  'urn:flux:rating-config:plan-importer': 'Import Plan',
  'urn:flux:rating-config:plan-link-importer': 'Import Plan Link',
  'urn:flux:rating-config:rates-update-importer': 'Import Rates Update',
  'urn:flux:rating-config:plan-migration': 'Plan Migration',
  // Rating Config Provisioning
  'urn:flux:rating-config:connection-updated': 'Provision Connection',
  'urn:flux:rating-config:provision-energy-plan': 'Provision Energy Plan',
  'urn:flux:rating-config:supply-agreement-cancelled': 'Cancel Supply Agreement',
  'urn:flux:rating-config:supply-agreement-updated': 'Provision Supply Agreement',
  // Telemetry
  'urn:flux:telemetry:file-importer': 'Import Telemetry File',
  'urn:flux:telemetry:telemetry-data-manager': 'Import Telemetry Data',
  'urn:flux:msats:meter-data': 'Import MSATS Meter File', //Australian Telemetry files
  'urn:flux:telemetry:telemetry-calculator': 'Telemetry Calculator',
  // Billing
  'urn:flux:billing': 'Billing',
  'urn:flux:error:billing': 'Billing',
  // Rating Calculator
  'urn:flux:rating-calculator:connection-config-sync': 'Connection Sync',
  'urn:flux:rating-calculator:data-pipeline-result-receiver': 'Rating Calculation',
  'urn:flux:rating-calculator:plan-sync': 'Plan Sync',
  'urn:flux:rating-calculator:cumulative-data-sync': 'Gorilla Data Sync',
  'urn:flux:rating-calculator:telemetry-data-sync': 'Telemetry Sync',
  ':exception': 'Exception',
});

export function getCategoryLabelByUrn(urn: string): string {
  const sourceLabels = generateSourceLabels();
  for (const key in sourceLabels) {
    if (urn.startsWith(key.toString())) {
      return sourceLabels[key as SourceType].toString();
    }
  }
  return 'Other';
}

export const exceptionCategory = [
  { tabName: 'Exceptions', url: 'exception', urns: [':exception'] },
] as CategoryData[];

export const generateCategories = (): CategoryData[] => {
  const categories: Record<string, CategoryDetails> = {
    'plan-csv-import': {
      label: 'Plan CSV Import',
      urns: [
        'urn:flux:rating-config:csv-plan-importer',
        'urn:flux:rating-config:generic-plan-importer',
        'urn:flux:rating-config:plan-link-importer',
        'urn:flux:rating-config:rates-update-importer',
        'urn:flux:rating-config:plan-migration',
      ],
    },
    telemetry: {
      label: 'Telemetry',
      urns: [
        'urn:flux:telemetry:file-importer',
        'urn:flux:telemetry:telemetry-data-manager',
        'urn:flux:msats:meter-data',
        'urn:flux:telemetry:telemetry-calculator',
      ],
    },
    'provisioning-errors': {
      label: 'Provisioning Errors',
      urns: [
        'urn:flux:rating-config:connection-updated',
        'urn:flux:rating-config:provision-energy-plan',
        'urn:flux:rating-config:supply-agreement-cancelled',
        'urn:flux:rating-config:supply-agreement-updated',
      ],
    },
    'billing-errors': {
      label: 'Billing Errors',
      urns: ['urn:flux:billing', 'urn:flux:error:billing'],
    },
  };

  const baseCategories = Object.keys(categories).map((key) => ({
    tabName: categories[key].label,
    url: key,
    urns: categories[key].urns,
  }));

  const allUrns: SourceType[] = baseCategories.reduce(
    (prev, cur) => prev.concat(cur.urns as SourceType[]),
    [] as SourceType[]
  );

  return ([{ tabName: 'All', url: 'all' }] as CategoryData[])
    .concat(baseCategories)
    .concat([{ tabName: 'Other', url: 'other', urns: allUrns }]);
};
