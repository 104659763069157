import { useQuery } from '@tanstack/react-query';
import { UnitValue } from './billingInvoicesApi';
import apiClient from '../../api/apiClient';

const baseEndpoint = '/billing/summaries';

export interface Summary {
  summaries: SummaryData[];
}

export interface SummaryData {
  startDate: string;
  endDate: string;
  amount: {
    total: UnitValue;
    currency: string;
  };
  invoices: number;
  activeConnections: number;
  billingParties: number;
}

interface BillingSummariesParams {
  startDate: string;
  endDate: string;
  attributes?: string[];
}

export const useBillingSummaries = (params: BillingSummariesParams) => {
  const { startDate, endDate, attributes } = params;

  const search = new URLSearchParams({
    startDate,
    endDate,
  });
  attributes?.forEach((atr) => search.append('attributes', atr));

  return useQuery(['billing-summaries', startDate, endDate, attributes], async () =>
    apiClient('billing').get(`${baseEndpoint}?${search.toString()}`)
  );
};
