/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ProcessedReadingFile } from '../models/ProcessedReadingFile';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class TestService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @param source Source unique id
     * @returns ProcessedReadingFile OK
     * @throws ApiError
     */
    public getProcessedReadingFileBySource(
        source: string,
    ): CancelablePromise<ProcessedReadingFile> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/test/sources/{source}/processed-reading-file',
            path: {
                'source': source,
            },
            errors: {
                404: `No processed reading file from given source`,
            },
        });
    }

}
