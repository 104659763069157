import { useQuery } from '@tanstack/react-query';
import apiClient from '../../api/apiClient';

const basePath = '/external-billing/v1';

export interface ExternalBillingPresentationResponse {
  id: string;
  accountNumber: string;
  taxIdentifier: string;
  address: Address;
  parties: Parties;
  customerReference: string;
}

export interface Address {
  id: string;
  _links: ResourceLinks;
}

export interface Parties {
  owner: Party;
}

export interface Party {
  id: string;
  name: string;
  givenName: string;
  familyName: string;
  _links: ResourceLinks;
}

export interface ResourceLinks {
  self: string;
}

export const useExternalBillingPresentation = (
  id: string | undefined,
  options?: Record<string, unknown>
) => {
  return useQuery(
    ['presentation', id],
    async () => apiClient('presentation').get(`${basePath}/presentation/${id}`),
    {
      ...options,
    }
  );
};
