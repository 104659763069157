import React, { FunctionComponent } from 'react';
import { ProvisioningExclusionSummary, useProvisioningExclusions } from './provisioningApi';
import Page, { ErrorPage, LoadingPage, PageHeader } from 'components/layout/Page';
import Card from 'components/layout/Card';
import RemoveExclusion from './RemoveExclusion';
import AddExclusion from './AddExclusion';
import AddExclusionPlan from './AddExclusionPlan';

const ProvisioningExclusions: FunctionComponent = () => {
  const { data, isInitialLoading: isLoading, isError } = useProvisioningExclusions();

  if (isLoading) {
    return <LoadingPage />;
  }

  if (isError) {
    return <ErrorPage />;
  }

  return (
    <>
      <PageHeader showBack={true} title="Manage provisioning exclusions"></PageHeader>
      <Page>
        <Card className="provisioning-exclusions half_xl">
          <div className="apl-display-flex apl-pb-s">
            <div className="apl-display-flex apl-flex-grow-1 apl-align-items-center apl-justify-content-end">
              <AddExclusion />
            </div>
          </div>
          <table className="apl-table-v1">
            <thead>
              <tr>
                <th>Provider Reference</th>
                <th>Provider</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {data &&
                data
                  .filter((pes: ProvisioningExclusionSummary) => pes?.provider?.id != undefined)
                  .map((pes: ProvisioningExclusionSummary) => {
                    return (
                      <tr key={pes.id}>
                        <td>{pes.providerReference}</td>
                        <td>{pes?.provider?.id ? pes?.provider?.name : 'All'}</td>
                        <td>
                          <RemoveExclusion exclusionId={pes.id} />
                        </td>
                      </tr>
                    );
                  })}
            </tbody>
          </table>
        </Card>
        <Card className="provisioning-exclusions half_xl">
          <div className="apl-display-flex apl-pb-s">
            <div className="apl-display-flex apl-flex-grow-1 apl-align-items-center">
              <h5>Consumer Pricing Plan Exclusion</h5>
            </div>
            <div className="apl-display-flex apl-flex-grow-1 apl-align-items-center apl-justify-content-end">
              <AddExclusionPlan />
            </div>
          </div>
          <table className="apl-table-v1" aria-labelledby="tableTitle">
            <thead>
              <tr>
                <th>Consumer Pricing Plan</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {data &&
                data
                  .filter((pes: ProvisioningExclusionSummary) => pes?.provider?.id == undefined)
                  .map((pes: ProvisioningExclusionSummary) => {
                    return (
                      <tr key={pes.id}>
                        <td>{pes.providerReference}</td>
                        <td>
                          <RemoveExclusion exclusionId={pes.id} />
                        </td>
                      </tr>
                    );
                  })}
            </tbody>
          </table>
        </Card>
      </Page>
    </>
  );
};

export default ProvisioningExclusions;
