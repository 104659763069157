/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type HeartbeatStatus = {
    status?: HeartbeatStatus.status;
};

export namespace HeartbeatStatus {

    export enum status {
        OK = 'OK',
        DEGRADED = 'DEGRADED',
        UNHEALTHY = 'UNHEALTHY',
        STARTING = 'STARTING',
    }


}

