/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BillingReadiness } from '../models/BillingReadiness';
import type { BillingReadinessResult } from '../models/BillingReadinessResult';
import type { DateTimeType } from '../models/DateTimeType';
import type { Error } from '../models/Error';
import type { NotReadyEnum } from '../models/NotReadyEnum';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class BillingReadinessService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @param limit
     * @param offset
     * @param latestOnly
     * @param notReady
     * @param startDate
     * @param endDate
     * @param connection
     * @param externalSupplyAgreementIds
     * @param attributes
     * @returns BillingReadinessResult OK
     * @returns Error Error
     * @throws ApiError
     */
    public getBillingReadinessList(
        limit: number = 20,
        offset?: number,
        latestOnly?: boolean,
        notReady?: Array<NotReadyEnum>,
        startDate?: string,
        endDate?: string,
        connection?: string,
        externalSupplyAgreementIds?: Array<string>,
        attributes?: Array<string>,
    ): CancelablePromise<BillingReadinessResult | Error> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/billing/readiness',
            query: {
                'limit': limit,
                'offset': offset,
                'latest_only': latestOnly,
                'not_ready': notReady,
                'start_date': startDate,
                'end_date': endDate,
                'connection': connection,
                'externalSupplyAgreementIds': externalSupplyAgreementIds,
                'attributes': attributes,
            },
        });
    }

    /**
     * @param id
     * @returns BillingReadiness OK
     * @returns Error Error
     * @throws ApiError
     */
    public getBillingReadiness(
        id: string,
    ): CancelablePromise<BillingReadiness | Error> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/billing/readiness/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * returns the latest billing readiness for a connection
     * @param id
     * @param startAt
     * @param endAt
     * @returns BillingReadiness OK
     * @returns Error Error
     * @throws ApiError
     */
    public getLatestBillingReadiness(
        id: string,
        startAt?: DateTimeType,
        endAt?: DateTimeType,
    ): CancelablePromise<BillingReadiness | Error> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/billing/readiness/supply_agreement/{id}',
            path: {
                'id': id,
            },
            query: {
                'startAt': startAt,
                'endAt': endAt,
            },
        });
    }

}
