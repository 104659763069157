import React, { createContext, useContext, useEffect, useState, FunctionComponent } from 'react';
import getPermissions, { Permission } from './getPermissions';
import { useConfig } from '../config/ConfigContext';
import { Config } from '../config/getConfig';
import { getTokenData, isTokenStillValid } from './authClient';

const PermissionContext = createContext<Permission[]>([]);

PermissionContext.displayName = 'PermissionContext';
interface PermissionContextProviderProps {
  children?: React.ReactNode;
}
const PermissionContextProvider: FunctionComponent<PermissionContextProviderProps> = ({
  children,
}: PermissionContextProviderProps) => {
  const [permissions, setPermissions] = useState<Permission[]>([]);
  const config: Config = useConfig() as Config;

  useEffect(() => {
    async function fetchPermissions() {
      const { auth, expires } = getTokenData();
      if (isTokenStillValid(auth, expires)) {
        const userPermissions = await getPermissions();
        setPermissions(userPermissions);
      } else {
        setPermissions([]);
        console.error(new Error('Token is not valid or has expired'));
      }
    }

    if (config.useAuth === false) {
      // this is based on the rating-api/RatingConfigPermission.java enum so if
      // updated, will need to update here also
      setPermissions([
        Permission.CONNECTION_EDIT,
        Permission.CONNECTION_VIEW,
        Permission.OBSERVANCE_EDIT,
        Permission.OBSERVANCE_VIEW,
        Permission.PLAN_EDIT,
        Permission.PLAN_VIEW,
        Permission.PLAN_ARCHIVE,
        Permission.PROVIDERS_EDIT,
        Permission.PROVIDERS_VIEW,
        Permission.QUALIFIERS_EDIT,
        Permission.QUALIFIERS_VIEW,
        Permission.PLAN_EXPORT_IMPORT,
        Permission.METRIC_VIEW,
        Permission.METRIC_CREATE,
        Permission.METRIC_EDIT,
        Permission.METRIC_DELETE,
      ]);
    } else {
      fetchPermissions();
    }
  }, [config.useAuth]);

  return <PermissionContext.Provider value={permissions}>{children}</PermissionContext.Provider>;
};

const usePermission = () => {
  const context: Permission[] = useContext(PermissionContext);

  return context;
};

export { PermissionContextProvider, usePermission };
