/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ListData } from '../models/ListData';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ListDataService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Return a collection of list data
     * @param listNames
     * @returns ListData Collection of list data
     * @throws ApiError
     */
    public getListData(
        listNames?: Array<string>,
    ): CancelablePromise<Array<ListData>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v2/list-data',
            query: {
                'listNames': listNames,
            },
        });
    }

}
