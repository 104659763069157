/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { SummaryAggregatedV2Response } from '../models/SummaryAggregatedV2Response';
import type { UsageDetailV2Response } from '../models/UsageDetailV2Response';
import type { UsageMeters } from '../models/UsageMeters';
import type { UsageSummaryV2Response } from '../models/UsageSummaryV2Response';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class UsageV2Service {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Find summarised reading records between two dates, for a connection, grouped by meter, registers and flow direction
     * @param startsAt
     * @param endsAt
     * @param connectionId
     * @returns SummaryAggregatedV2Response OK
     * @throws ApiError
     */
    public findUsageSummaryAggregatedV2(
        startsAt: string,
        endsAt: string,
        connectionId: string,
    ): CancelablePromise<SummaryAggregatedV2Response> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v2/usage/summary/aggregated',
            query: {
                'startsAt': startsAt,
                'endsAt': endsAt,
                'connectionId': connectionId,
            },
            errors: {
                400: `Bad-Request`,
                401: `Unauthorized`,
                404: `The specified resource was not found`,
                500: `Internal-Server-Error`,
            },
        });
    }

    /**
     * Find all reading records between two utc dates, for a connection, and optionally a meter and flow direction
     * @param connectionId
     * @param startsAt The start of the period of usage data to return in UTC.
     * @param endsAt The end of the period of usage data to return in UTC.
     * @param limit The max number of readings to return.  There are 288 5-minute intervals in a day.
     * @param meterId
     * @param isEnergyGoingToConnection
     * @param usageChargeFilter the filter name associated with a measurement point
     * @param measurementPointId the measurement point id associated with this incoming usage reading
     * @param getForecasted whether or not to get forecasted data. When true, only forecasted data is returned. When false, no forecasted data is returned
     * @returns UsageDetailV2Response OK
     * @throws ApiError
     */
    public findUsageDetailV2(
        connectionId: string,
        startsAt: string,
        endsAt: string,
        limit?: number,
        meterId?: string,
        isEnergyGoingToConnection?: boolean,
        usageChargeFilter?: string,
        measurementPointId?: string,
        getForecasted: boolean = false,
    ): CancelablePromise<UsageDetailV2Response> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v2/usage/detail',
            query: {
                'connectionId': connectionId,
                'startsAt': startsAt,
                'endsAt': endsAt,
                'limit': limit,
                'meterId': meterId,
                'isEnergyGoingToConnection': isEnergyGoingToConnection,
                'usageChargeFilter': usageChargeFilter,
                'measurementPointId': measurementPointId,
                'getForecasted': getForecasted,
            },
            errors: {
                400: `Bad-Request`,
                404: `The specified resource was not found`,
            },
        });
    }

    /**
     * Find summarised reading records between two utc dates, for a connection, and optionally a meter and flow direction
     * @param startsAt
     * @param endsAt
     * @param connectionId
     * @param limit The max number of records to return.  There are at most 31 days in a month.
     * @param meterId
     * @param isEnergyGoingToConnection
     * @param usageChargeFilter the filter name associated with a measurement point
     * @param measurementPointId the measurement point id associated with this incoming usage reading
     * @returns UsageSummaryV2Response OK
     * @throws ApiError
     */
    public findUsageSummaryV2(
        startsAt: string,
        endsAt: string,
        connectionId: string,
        limit: number,
        meterId?: string,
        isEnergyGoingToConnection?: boolean,
        usageChargeFilter?: string,
        measurementPointId?: string,
    ): CancelablePromise<UsageSummaryV2Response> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v2/usage/summary',
            query: {
                'startsAt': startsAt,
                'endsAt': endsAt,
                'connectionId': connectionId,
                'limit': limit,
                'meterId': meterId,
                'isEnergyGoingToConnection': isEnergyGoingToConnection,
                'usageChargeFilter': usageChargeFilter,
                'measurementPointId': measurementPointId,
            },
            errors: {
                400: `Bad-Request`,
                404: `The specified resource was not found`,
            },
        });
    }

    /**
     * Find the meters that had usage between two dates, for a connection
     * @param startsAt
     * @param endsAt
     * @param connectionId
     * @returns UsageMeters OK
     * @throws ApiError
     */
    public findUsageMetersV2(
        startsAt: string,
        endsAt: string,
        connectionId: string,
    ): CancelablePromise<UsageMeters> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v2/usage/meters',
            query: {
                'startsAt': startsAt,
                'endsAt': endsAt,
                'connectionId': connectionId,
            },
            errors: {
                400: `Bad-Request`,
                404: `The specified resource was not found`,
            },
        });
    }

}
