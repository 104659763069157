/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class TestCacheEntriesService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Deletes the application cache entries.
     * @returns any Error
     * @throws ApiError
     */
    public testCacheEntries(): CancelablePromise<{
        /**
         * The overarching description of the error
         */
        urn: string;
        attributes?: Array<{
            attribute?: string;
            urn?: string;
        }>;
    }> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/test/cache-entries',
        });
    }

}
