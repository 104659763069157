import React, { FunctionComponent } from 'react';
import { ErrorModel } from '../../../api/ErrorDisplay';
import { MeasurementPoint as MeasurementPointType } from 'api/openapi/telemetry';
import MeasurementPointForm from './MeasurementPointForm';
import { useCreateMeasurementPoint } from 'components/telemetry/telemetryUsageApi';

interface AddMeasurementPointProps {
  id: string;
  connectionId: string;
  close: () => void;
}

const AddMeasurementPoint: FunctionComponent<AddMeasurementPointProps> = ({
  id,
  connectionId,
  close,
}) => {
  const { mutateAsync, isLoading: isSubmitting, error } = useCreateMeasurementPoint();
  const addMeasurementPoint = async (newMeasurementPoint: MeasurementPointType) => {
    const result = await mutateAsync(newMeasurementPoint);

    if (result) {
      close();
    }
  };

  return (
    <div className="apl-form-layout-v1" data-testid="add-measurement-point-form">
      <h2>Add New Measurement Point</h2>

      <MeasurementPointForm
        id={id}
        connectionId={connectionId}
        close={close}
        error={error as ErrorModel}
        isUpdating={isSubmitting}
        submitFunction={addMeasurementPoint}
        readOnly={false}
      />
    </div>
  );
};

export default AddMeasurementPoint;
