/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Error } from '../models/Error';
import type { TestSupplyAgreement } from '../models/TestSupplyAgreement';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class TestSupplyAgreementService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @param requestBody
     * @returns TestSupplyAgreement OK
     * @returns Error Error
     * @throws ApiError
     */
    public testCreateSupplyAgreement(
        requestBody: TestSupplyAgreement,
    ): CancelablePromise<TestSupplyAgreement | Error> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/test/configuration/supply-agreements',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
