import React from 'react';
import { redirectToLogin } from './authClient';
import { EuiButton } from '@elastic/eui';
import Alert, { AlertEnum } from 'components/layout/Alert';

const Unauthorised = () => {
  return (
    <Alert type={AlertEnum.WARNING}>
      Sorry, your session may have expired.
      <EuiButton onClick={redirectToLogin}>
        Click the button to refresh your session token.
      </EuiButton>
    </Alert>
  );
};

export default Unauthorised;
