import cx from 'classnames';
import React, { FunctionComponent } from 'react';
import { Permission } from '../../auth/getPermissions';
import { usePermission } from '../../auth/PermissionContext';
import { EuiButton } from '@elastic/eui';

interface AuthorizedActionProps {
  extraClasses?: string;
  id?: string;
  isDisabled?: boolean;
  onClick?: () => void;
  permission: Permission;
  removeDefaultClass?: boolean;
  removeDefaultWeight?: boolean;
  testId?: string;
  title?: string;
  type?: 'submit' | 'button' | 'reset';
  children?: React.ReactNode;
}

const AuthorizedAction: FunctionComponent<AuthorizedActionProps> = ({
  children,
  extraClasses,
  id,
  isDisabled,
  onClick,
  permission,
  removeDefaultClass,
  removeDefaultWeight,
  testId,
  title,
  type,
}) => {
  // map against current user permissions
  const permissions = usePermission();
  const disabled = isDisabled ? isDisabled : !permissions.includes(permission);
  const tooltip = title ?? 'Sorry, you do not have the required permission.';

  return (
    <EuiButton
      className={cx(
        {
          'apl-button-v1': !removeDefaultClass,
        },
        extraClasses
      )}
      data-testid={testId ?? undefined}
      isDisabled={disabled}
      id={id}
      onClick={onClick}
      style={{
        fontWeight: removeDefaultWeight ? 400 : 600,
      }}
      title={disabled ? tooltip : undefined}
      type={type}
      color="text"
    >
      {children}
    </EuiButton>
  );
};

export default AuthorizedAction;
