import { isEmpty, isNil } from 'ramda';
import React, { FunctionComponent } from 'react';
import { ErrorText } from '../components/layout/Errors';

export interface ErrorModel {
  message: string;
  type: 'SERVER';
  status: number;
  errors: Error[] | null;
}

export interface Error {
  property: string;
  message: string;
}

interface ErrorDisplayProps {
  error?: ErrorModel;
}

const ErrorDisplay: FunctionComponent<ErrorDisplayProps> = ({ error }) => {
  if (isNil(error) || isEmpty(error.errors)) {
    return null;
  }

  return (
    <div className="apl-flex-column apl-display-flex">
      {error?.errors?.length &&
        error.errors.map((e, index: number) => (
          <ErrorText key={index}>
            {e.property ? `${getFieldNameFromPath(e.property)}: ${e.message}` : e.message}
          </ErrorText>
        ))}
    </div>
  );
};

const getFieldNameFromPath = (path: string) => {
  return path.split('.').pop();
};

export default ErrorDisplay;
