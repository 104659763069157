/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ChargeTag } from '../models/ChargeTag';
import type { ConnectionsMissingPlans } from '../models/ConnectionsMissingPlans';
import type { ConnectionsMissingPlansSummary } from '../models/ConnectionsMissingPlansSummary';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ConnectionMissingPlansReportService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Returns a summary of connection counts that has been provision but gaps in pricing
     * @param startsAt The start date of the queried range
     * @param endsAt The end date of the queried range
     * @param tag The pricing charge type to look for
     * @param attributes The attributes of the connection to filter on
     * @returns ConnectionsMissingPlansSummary Successfully retrieve the summary report
     * @throws ApiError
     */
    public getMissingPlansSummary(
        startsAt: string,
        endsAt: string,
        tag: Array<ChargeTag>,
        attributes?: Array<string>,
    ): CancelablePromise<ConnectionsMissingPlansSummary> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/connection-reports/missing-plans-summary',
            query: {
                'startsAt': startsAt,
                'endsAt': endsAt,
                'tag': tag,
                'attributes': attributes,
            },
        });
    }

    /**
     * Returns a list of connections that has been provision and the information on gaps in pricing
     * @param startsAt The start date of the queried range
     * @param endsAt The end date of the queried range
     * @param tag The pricing charge type to look for
     * @param attributes The attributes of the connection to filter on
     * @returns ConnectionsMissingPlans Successfully retrieve the report
     * @throws ApiError
     */
    public getMissingPlans(
        startsAt: string,
        endsAt: string,
        tag: Array<ChargeTag>,
        attributes?: Array<string>,
    ): CancelablePromise<ConnectionsMissingPlans> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/connection-reports/missing-plans',
            query: {
                'startsAt': startsAt,
                'endsAt': endsAt,
                'tag': tag,
                'attributes': attributes,
            },
        });
    }

    /**
     * Batch queue request to create missing plan report for all connections in the system.
     * @param supplyPeriod Optionally constrain the missing plan report to be created to particular supply periods
     * @returns void
     * @throws ApiError
     */
    public createMissingPlan(
        supplyPeriod?: Array<string>,
    ): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/connection-reports/missing-plans',
            query: {
                'supplyPeriod': supplyPeriod,
            },
        });
    }

    /**
     * Create missing plan report for a supply period
     * @param supplyPeriodId Id of the supply period to create report for
     * @returns ConnectionsMissingPlans Successfully retrieve the report
     * @throws ApiError
     */
    public createMissingPlanForSupplyPeriod(
        supplyPeriodId: string,
    ): CancelablePromise<ConnectionsMissingPlans> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/connection-reports/missing-plans/supply-periods/{supplyPeriodId}',
            path: {
                'supplyPeriodId': supplyPeriodId,
            },
        });
    }

    /**
     * Create missing plan report for a connection
     * @param connectionId Id of the connection to create report for
     * @returns ConnectionsMissingPlans Successfully retrieve the report
     * @throws ApiError
     */
    public createMissingPlanForConnection(
        connectionId: string,
    ): CancelablePromise<ConnectionsMissingPlans> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/connection-reports/missing-plans/connection/{connectionId}',
            path: {
                'connectionId': connectionId,
            },
        });
    }

}
