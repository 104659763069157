import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Widget } from './Dashboard';
import ConnectionSearch from '../connections/ConnectionSearch';

const ConnectionSearchWidget = () => {
  const navigate = useNavigate();

  return (
    <Widget>
      <ConnectionSearch
        handleOnKeyUp={(event) => {
          if (event.key === 'Enter') {
            const value = event.currentTarget.value;

            navigate(`/connections?id=${value}`);
          }
        }}
      />
    </Widget>
  );
};

export default ConnectionSearchWidget;
