export enum SummarisationFunctionValue {
  Average = 'AVERAGE',
  AverageOfDailyHighest = 'AVERAGE_OF_DAILY_HIGHEST',
  HighestOfDailyAverage = 'HIGHEST_OF_DAILY_AVERAGE',
}

export type SummarisationFunction = {
  label: string;
  value: '' | SummarisationFunctionValue;
};

export type SummarisationFunctionOptions = SummarisationFunction[];

/**
 * Helper to get the display value from the stored value.
 * @param value
 */

/**
 * Method to get the summarisation function label given its value.
 * @param value the value.
 * @param sumFunctionOptions the summarisation function options.
 * @returns the summarisation function label.
 */
function getSumFunctionLabelByValue(
  value: string | undefined | null,
  sumFunctionOptions: SummarisationFunctionOptions
): string {
  if (!value) {
    return '';
  }
  const item = sumFunctionOptions.find((item) => item.value === value);
  return item ? item.label : '';
}

export { getSumFunctionLabelByValue };
