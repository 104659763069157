import { useQuery } from '@tanstack/react-query';
import { handleRequest, ratingConfigClient } from '../../api';

export interface ConnectionErrorsQueryParams {
  connectionId: string;
  from?: string | undefined;
  to?: string | undefined;
}

export const useGetConnectionErrors = (
  connectionId: string,
  from: Date,
  to: Date,
  useV3: boolean
) => {
  if (useV3) {
    return useGetConnectionErrorsV3(connectionId, from, to);
  } else {
    return useGetConnectionErrorsV2(connectionId, from, to);
  }
};

const useGetConnectionErrorsV2 = (connectionId: string, from: Date, to: Date) => {
  return useQuery(
    ['connectionErrors'],
    async () =>
      await handleRequest(
        ratingConfigClient.connections.getConnectionErrors(
          connectionId,
          from.toISOString().slice(0, 10),
          to.toISOString().slice(0, 10)
        )
      )
  );
};

const useGetConnectionErrorsV3 = (connectionId: string, from: Date, to: Date) => {
  return useQuery(
    ['connectionErrors'],
    async () =>
      await handleRequest(
        ratingConfigClient.connections.getConnectionErrorsV3(
          connectionId,
          from.toISOString(),
          to.toISOString()
        )
      )
  );
};
