import React from 'react';
import { Link } from 'react-router-dom';
import Card from '../layout/Card';
import Page, { PageHeader } from '../layout/Page';

const SettingsMenu = () => {
  return (
    <>
      <PageHeader showBack={true} title="Settings"></PageHeader>
      <Page>
        <Card className="apl-pt-none third_xl">
          <div className="card__inner table-filter apl-py">
            <h3>Flexibill settings</h3>
          </div>
          <Link className="apl-color-primary" to={`/settings/holidays`}>
            Manage holidays
          </Link>
        </Card>
      </Page>
    </>
  );
};

export default SettingsMenu;
