import { useTranslation } from 'react-i18next';

export type MassUnitGrammaticalNumber = {
  singular: string;
  plural: string;
  abbreviation: string;
};

export type MassUnit = {
  base: MassUnitGrammaticalNumber;
  major: MassUnitGrammaticalNumber;
  minor: MassUnitGrammaticalNumber;
};

/**
 * Hook that builds on the i18n translation hook useTranslation
 * to return the weight units for a specific mass system.
 *
 * @returns {MassUnit} an object containing the weight units for a mass system
 */
export default function useMassUnitTranslation(unit: string): MassUnit {
  const { t: translate } = useTranslation();
  let massUnit = {};

  switch (unit) {
    case 'G':
      massUnit = translate('mass.gram', { returnObjects: true });
      break;
    case 'T':
      massUnit = translate('mass.tonne', { returnObjects: true });
      break;
    case 'P':
      massUnit = translate('mass.pound', { returnObjects: true });
      break;
    case 'O':
      massUnit = translate('mass.ounce', { returnObjects: true });
      break;
    case 'S':
      massUnit = translate('mass.stone', { returnObjects: true });
      break;
    default:
      break;
  }
  return massUnit as MassUnit;
}
