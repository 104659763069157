import { FieldInputProps, FormikProps } from 'formik';
import React, { FunctionComponent, useMemo } from 'react';
import Select, { MultiValue, Options, StylesConfig } from 'react-select';
import { Provider } from './providersApi';

interface Option {
  label: string;
  value: string;
}

interface ProvidersMultiSelectProps {
  providers: Provider[];
  defaultProviders: Provider[];
  disabled: boolean;
  field: FieldInputProps<Array<string>>;
  form: FormikProps<any>;
}

const ProviderMultiSelect: FunctionComponent<ProvidersMultiSelectProps> = ({
  providers,
  defaultProviders,
  disabled,
  field,
  form,
  ...rest
}) => {
  const providersOptions: Options<Option> = useMemo(
    () => providers.map((provider) => ({ label: provider.name, value: provider.id })),
    [providers]
  );

  const defaultProvidersIds =
    defaultProviders && defaultProviders.length
      ? defaultProviders.map((defaultProvider) => defaultProvider.id)
      : [];
  const defaultValue =
    defaultProvidersIds.length > 0
      ? providersOptions.filter((providersOption: Option) =>
          defaultProvidersIds.includes(providersOption.value)
        )
      : [];

  const onChange = (option: MultiValue<Option>) => {
    form.setFieldValue(
      field.name,
      option.map((item: Option) => item.value)
    );
  };

  const onBlur = () => {
    form.setTouched({
      ...form.touched,
      providers: true,
    });
  };

  const customStyles: StylesConfig<Option> = {
    control: (provided: Record<string, any>) => ({
      ...provided,
      ...(form.errors.providers &&
        form.touched.providers && {
          borderColor: 'var(--danger-color)',
          '&:hover': {
            borderColor: 'var(--danger-color)',
          },
        }),
    }),
    option: (provided: Record<string, any>) => ({
      ...provided,
      color: 'var(--content-color)',
      '&:hover': {
        color: 'var(--base-color)',
        backgroundColor: 'var(--primary-color)',
      },
    }),
    multiValue: (provided: Record<string, any>) => ({
      ...provided,
      color: 'var(--secondary-color-strong)',
      backgroundColor: 'var(--secondary-bg-strong)',
      borderRadius: '2.5px',
    }),
    multiValueRemove: (provided: Record<string, any>) => ({
      ...provided,
      '&:hover': {
        color: 'var(--danger-color-strong)',
        backgroundColor: 'var(--danger-bg-strong)',
      },
    }),
  };

  return (
    <div data-testid="provider multi select">
      <Select
        defaultValue={defaultValue}
        isDisabled={disabled}
        isMulti
        menuPlacement="top"
        name="providers"
        onChange={onChange}
        onBlur={onBlur}
        options={providersOptions}
        placeholder="Select providers"
        styles={customStyles}
        {...rest}
      />
    </div>
  );
};

export default ProviderMultiSelect;
