import React, { FunctionComponent, useState } from 'react';
import { isDate, isPast } from 'date-fns';
import Modal from 'react-modal';
import ChangeStateForm from './ChangeStateForm';
import { useUpdatePlanStateV2 } from './planApi';
import AuthorizedAction from '../common/AuthorizedAction';
import { modalStyles } from '../layout/Modal';
import { ErrorModel } from '../../api/ErrorDisplay';
import { Permission } from '../../auth/getPermissions';
import { Plan, PlanState } from 'api/openapi/rating-config';

interface SimplePlanDetailsProps {
  plan: Plan | undefined;
  planId: string;
}

const ChangeState: FunctionComponent<SimplePlanDetailsProps> = ({ plan, planId }) => {
  const [changeStateIsOpen, setChangeStateIsOpen] = useState(false);
  const closeChangeState = () => setChangeStateIsOpen(false);
  const openChangeState = () => setChangeStateIsOpen(true);

  const { mutateAsync, error } = useUpdatePlanStateV2();

  const changePlanState = async (currentPlanState: string | undefined) => {
    const newPlanState =
      currentPlanState === PlanState.DRAFT ? PlanState.PUBLISHED : PlanState.DEPRECATED;

    try {
      const { id } = await mutateAsync({ planState: { planState: newPlanState }, planId: planId });

      if (id) {
        closeChangeState();
      }
    } catch (e) {
      // @todo add error handling
    }
  };

  const endDate = plan?.endDate ? new Date(plan.endDate) : null;
  const isDeprecateDisabled = endDate ? !isDate(endDate) || !isPast(endDate) : true;

  return (
    <>
      {plan?.planState === PlanState.DRAFT ? (
        <AuthorizedAction
          extraClasses="is-primary apl-ml-s"
          onClick={openChangeState}
          permission={Permission.PLAN_EDIT}
          testId="publish-plan-button"
        >
          Publish plan
        </AuthorizedAction>
      ) : plan?.planState === 'PUBLISHED' ? (
        <AuthorizedAction
          extraClasses="is-primary apl-ml-s"
          isDisabled={isDeprecateDisabled}
          onClick={openChangeState}
          permission={Permission.PLAN_EDIT}
          testId="deprecate-plan-button"
          title={isDeprecateDisabled ? 'Sorry, this is not allowed.' : undefined}
        >
          Deprecate plan
        </AuthorizedAction>
      ) : (
        <div></div>
      )}
      <Modal
        isOpen={changeStateIsOpen}
        onRequestClose={closeChangeState}
        style={modalStyles}
        shouldCloseOnOverlayClick={false}
      >
        <ChangeStateForm
          planState={plan?.planState}
          close={closeChangeState}
          submitFunction={changePlanState}
          error={error as ErrorModel}
        />
      </Modal>
    </>
  );
};

export default ChangeState;
