import React, { FunctionComponent, useState } from 'react';
import Modal from 'react-modal';
import AuthorizedAction from '../common/AuthorizedAction';
import { modalStyles } from '../layout/Modal';
import { Permission } from '../../auth/getPermissions';
import { CreateProvisioningExclusionRequest, useAddProvisioningExclusion } from './provisioningApi';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import FieldWrapper, { FieldWidth, inputClassNameBuilder } from 'components/form/FieldWrapper';
import { ErrorText } from 'components/layout/Errors';
import { object, string } from 'yup';

const addExclusionValidationSchema = object().shape({
  providerReference: string().label('Consumer pricing plan').trim().required(),
});

const AddExclusionPlan: FunctionComponent = () => {
  const [addExclusionPlanIsOpen, setAddExclusionPlanIsOpen] = useState(false);

  const closeAddExclusionPlan = () => setAddExclusionPlanIsOpen(false);

  const openAddExclusionPlan = () => setAddExclusionPlanIsOpen(true);

  const { mutateAsync: addProvisioningExclusion } = useAddProvisioningExclusion();

  const submit = async (data: CreateProvisioningExclusionRequest): Promise<any> => {
    try {
      await addProvisioningExclusion({ data });
      closeAddExclusionPlan();
    } catch (e: any) {
      console.error(e);
    }
  };

  return (
    <>
      <AuthorizedAction
        testId="add exclusion plan button"
        extraClasses="is-primary"
        onClick={openAddExclusionPlan}
        permission={Permission.PROVIDERS_EDIT}
      >
        Add exclusion
      </AuthorizedAction>
      <Modal
        isOpen={addExclusionPlanIsOpen}
        onRequestClose={closeAddExclusionPlan}
        style={modalStyles}
        shouldCloseOnOverlayClick={false}
      >
        <div>
          <h2>Add exclusion</h2>
          <Formik
            initialValues={{
              providerReference: '',
            }}
            onSubmit={(exclusionForm) => {
              const observanceBody: CreateProvisioningExclusionRequest = {
                providerReference: exclusionForm.providerReference.trim(),
                providerId: undefined,
              };
              return submit(observanceBody);
            }}
            validationSchema={addExclusionValidationSchema}
          >
            {({ errors, isSubmitting, isValid, touched }) => (
              <Form className="apl-form-layout-v1">
                <FieldWrapper
                  htmlFor="provider-reference"
                  label="Consumer Pricing Plan"
                  fieldWidth={FieldWidth.FULL}
                >
                  <Field
                    autoComplete="off"
                    data-testid="consumer pricing plan"
                    name="providerReference"
                    id="provider-reference"
                    className={inputClassNameBuilder('providerReference', errors, touched)}
                  />
                  <ErrorMessage component={ErrorText} name="providerReference" />
                </FieldWrapper>
                <div className="apl-display-flex apl-justify-content-end">
                  <button
                    data-testid="cancel button"
                    className="apl-button-v1"
                    onClick={closeAddExclusionPlan}
                    type="button"
                  >
                    Cancel
                  </button>
                  <AuthorizedAction
                    extraClasses="is-primary"
                    isDisabled={isSubmitting || !isValid}
                    permission={Permission.PROVIDERS_EDIT}
                    testId="add exclusion plan button"
                    type="submit"
                  >
                    Save
                  </AuthorizedAction>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </Modal>
    </>
  );
};

export default AddExclusionPlan;
