import { differenceInMonths, endOfMonth, formatISO, startOfMonth } from 'date-fns';
import { isEmpty } from 'ramda';
import { getDefaultQualifiers } from '../../qualifiers/qualifiersApi';
import { Charge, QualifierLink, Rate, RateSchedule } from '../../../api/openapi/rating-config';
import { marketTimezoneAddMonths } from 'util/helper-func';

export const months: Month[] = [
  'JANUARY',
  'FEBRUARY',
  'MARCH',
  'APRIL',
  'MAY',
  'JUNE',
  'JULY',
  'AUGUST',
  'SEPTEMBER',
  'OCTOBER',
  'NOVEMBER',
  'DECEMBER',
];

export type Month =
  | 'JANUARY'
  | 'FEBRUARY'
  | 'MARCH'
  | 'APRIL'
  | 'MAY'
  | 'JUNE'
  | 'JULY'
  | 'AUGUST'
  | 'SEPTEMBER'
  | 'OCTOBER'
  | 'NOVEMBER'
  | 'DECEMBER';

export const easi144TimePeriods = ['00:00', '04:00', '08:00', '12:00', '16:00', '20:00'];

export const easi48TimePeriods = ['00:00', '08:00'];

export interface MonthlySpotDetails {
  monthSpotPercentage: MonthSpotPercentage;
  // true if one or more months have rates within them that differ, should always be false
  containsInvalidSpotRates: boolean;
}

export interface MonthSpotPercentage {
  [date: string]: string;
}

export const createRange = (startDate: string, endDate: string | undefined): Date[] => {
  const startAsDate = new Date(startDate);
  const monthStart = startOfMonth(startAsDate);
  const monthCount = endDate ? differenceInMonths(new Date(endDate), startAsDate) + 1 : 36;

  return Array.from(Array(monthCount)).map((item, i) =>
    i === 0 ? monthStart : marketTimezoneAddMonths(monthStart, i)
  );
};

export const createDefaultSpotPercentages = (range: Date[]): MonthSpotPercentage => {
  const monthlySpot: MonthSpotPercentage = {};

  range.forEach((date) => {
    const dateAsString = formatISO(date, { representation: 'date' });

    // make sure the monthly spot prices have initial value 0
    monthlySpot[dateAsString] = '0';
  });

  return monthlySpot;
};

export const createCharge = (
  validFrom: string,
  validTo: string,
  monthlySpot: MonthSpotPercentage | undefined,
  startDate: string,
  endDate: string | undefined
): Charge => {
  const range = createRange(startDate, endDate);
  const spotPercentages = monthlySpot ?? createDefaultSpotPercentages(range);

  const schedule = createSchedule(validFrom, validTo, spotPercentages, range);

  return {
    chargeType: Charge.chargeType.VOLUME,
    chargeBasis: 'c/kWh',
    schedules: [schedule],
    chargeReference: '',
    sumFunction: undefined,
    flowDirection: Charge.flowDirection.INTO,
    chargeName: '',
    billingDescriptor: '', // Remove with fbau635BillingCategoryForInvoicePresentation
    display: {
      category: '',
      descriptor: '',
    },
    powerFactor: undefined,
    topPeriods: undefined,
  } as Charge;
};

const createSchedule = (
  validFrom: string,
  validTo: string,
  monthlySpot: MonthSpotPercentage,
  range: Date[]
): RateSchedule => {
  const { nonWorkingDayQualifier, workingDayQualifier } = getDefaultQualifiers();

  const workingDays = createRates(workingDayQualifier, monthlySpot, range);

  const nonWorkingDays = createRates(nonWorkingDayQualifier, monthlySpot, range);

  return {
    validFrom,
    validTo,
    rates: [...workingDays, ...nonWorkingDays],
  };
};

const createRates = (
  qualifier: QualifierLink,
  monthlySpot: MonthSpotPercentage,
  range: Date[]
): Rate[] => {
  const rates: Rate[] = [];

  range.forEach((date) => {
    const monthFrom = formatISO(date, { representation: 'date' });
    const monthTo = formatISO(endOfMonth(date), { representation: 'date' });

    for (const [index, startTime] of easi144TimePeriods.entries()) {
      const endTime = endTimeFromIndex(index);

      rates.push(createRate(monthFrom, monthTo, startTime, endTime, qualifier, monthlySpot));
    }
  });

  return rates;
};

const createRate = (
  monthFrom: string,
  monthTo: string,
  startTime: string,
  endTime: string,
  qualifier: QualifierLink,
  monthlySpot: MonthSpotPercentage
): Rate => {
  return {
    applicableTimePeriods: [],
    rate: '0.0000',
    rateQualifiers: [qualifier],
    spotPercentage: Number(monthlySpot[monthFrom]),
    summarisationGroup: '',
    timeRanges: [
      {
        tokenType: 'Range',
        type: 'DATE_RANGE',
        from: monthFrom,
        to: monthTo,
      },
      {
        tokenType: 'Range',
        type: 'TIME_RANGE',
        from: startTime,
        to: endTime,
      },
    ],
  } as unknown as Rate;
};

export const buildEasiGrid = (
  rates: Rate[] | undefined,
  range: Date[],
  structure = 'EASI_144'
): string[][] => {
  const grid: string[][] = [];
  const timePeriods = structure === 'EASI_144' ? easi144TimePeriods : easi48TimePeriods;

  for (const [indexTime, startTime] of timePeriods.entries()) {
    // find all the rates that have the TIME_RANGE that matches this startTime
    const ratesForStartTime = rates?.filter(findRateMatchingRangeFrom(startTime));

    // add this time range into the grid as a row
    grid[indexTime] = [];

    range.forEach((date, indexMonthYear) => {
      const dateAsString = formatISO(date, { representation: 'date' });

      grid[indexTime][indexMonthYear] =
        (ratesForStartTime?.find(findRateMatchingRangeFrom(dateAsString))
          ?.rate as unknown as string) ?? '';
    });
  }

  return grid;
};

/**
 * Used as a predicate to filter down rates by matching a rate.timeRanges.from
 * value to the provided value.
 */
export const findRateMatchingRangeFrom =
  (from: string) =>
  (r: Rate): boolean =>
    r.timeRanges?.find((range) => range.from === from) !== undefined;

export const rateListFromEasiGrid = (
  rateGrid: string[][],
  qualifier: QualifierLink,
  monthlySpot: MonthSpotPercentage,
  range: Date[],
  structure = 'EASI_144'
): Rate[] => {
  const rates: Rate[] = [];
  const timePeriods = structure === 'EASI_144' ? easi144TimePeriods : easi48TimePeriods;

  for (const [indexTime, startTime] of timePeriods.entries()) {
    const endTime = endTimeFromIndex(indexTime, structure);

    range.forEach((date, indexMonthYear) => {
      const rateValue = rateGrid[indexTime][indexMonthYear];
      const monthFrom = formatISO(date, { representation: 'date' });
      const monthTo = formatISO(endOfMonth(date), { representation: 'date' });

      const rate: Rate = {
        applicableTimePeriods: [],

        rate: rateValue,
        rateQualifiers: [qualifier],
        spotPercentage: Number(monthlySpot[monthFrom]),
        // we rely on PlanServiceImpl.java in the rating-api to fill this out correctly for us so
        // we do not have to duplicate the logic already implemented there
        summarisationGroup: '',
        timeRanges: [
          {
            tokenType: 'Range',
            from: monthFrom,
            to: monthTo,
            type: 'DATE_RANGE',
          },
          {
            tokenType: 'Range',
            from: startTime,
            to: endTime,
            type: 'TIME_RANGE',
          },
        ],
      } as unknown as Rate;

      rates.push(rate);
    });
  }

  return rates;
};

const endTimeFromIndex = (index: number, structure = 'EASI_144'): string => {
  const timePeriods = structure === 'EASI_144' ? easi144TimePeriods : easi48TimePeriods;

  return index < timePeriods.length - 1 ? timePeriods[index + 1] : timePeriods[0];
};

export const monthlySpotFromRateList = (
  rates: Rate[] | undefined,
  range: Date[]
): MonthlySpotDetails => {
  const spotPercentages: MonthSpotPercentage = {};
  let containsInvalidSpotRates = false;

  // loop through each month in the object
  range.forEach((date) => {
    const dateAsString = formatISO(date, { representation: 'date' });
    const ratesForMonth = rates ? rates.filter(findRateMatchingRangeFrom(dateAsString)) : [];

    // checking to see if the spot rates are in an invalid state, all spot
    // rates should be equal for a month
    if (!allValuesInArrayAreEqual(ratesForMonth.map((r) => r.spotPercentage))) {
      containsInvalidSpotRates = true;
    }

    spotPercentages[dateAsString] = `${ratesForMonth?.reduce(
      // find the maximum of the rates for the month
      (previous, nextRate) => Math.max(previous, nextRate?.spotPercentage || 0),
      0
    )}`;
  });

  return {
    monthSpotPercentage: spotPercentages,
    containsInvalidSpotRates,
  };
};

function allValuesInArrayAreEqual<T>(arrayToCheck: T[]): boolean {
  if (isEmpty(arrayToCheck) || arrayToCheck.length === 1) {
    return true;
  }
  // check every element in the array matches the first element
  return arrayToCheck.every((v) => v === arrayToCheck[0]);
}
