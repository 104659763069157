/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum PlanState {
    DRAFT = 'DRAFT',
    PUBLISHED = 'PUBLISHED',
    DEPRECATED = 'DEPRECATED',
    ARCHIVED = 'ARCHIVED',
}
