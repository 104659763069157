import React, { FunctionComponent } from 'react';
import { ErrorModel } from '../../api/ErrorDisplay';

import AddMetricForm from './AddMetricForm';
import { Metric, useAddMetricData } from './metricsApi';

interface AddMetricProps {
  close: () => void;
  connectionId: string | undefined;
  contractedPartyId: string | undefined;
}

const AddMetric: FunctionComponent<AddMetricProps> = ({
  close,
  connectionId,
  contractedPartyId,
}) => {
  const { mutateAsync: addMetric, error: addMetricError } = useAddMetricData();
  const submit = async (data: Metric): Promise<any> => {
    try {
      await addMetric(data);
      close();
    } catch (e: any) {
      close();
      console.error(e);
    }
  };

  return (
    <div>
      <h2>Add New Peak Demand</h2>
      <AddMetricForm
        submitFunction={submit}
        close={close}
        connectionId={connectionId}
        contractedPartyId={contractedPartyId}
        error={addMetricError as unknown as ErrorModel}
      />
    </div>
  );
};

export default AddMetric;
