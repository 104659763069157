/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type SupplyAgreementCancelled = {
    _type?: SupplyAgreementCancelled._type;
    _version?: string;
    _publishedAt?: string;
    /**
     * The consumer id from core
     */
    id?: string;
    /**
     * The date that the supply agreement was cancelled.
     */
    cancelledAt?: string;
    connection?: {
        /**
         * The ICP uuid from core.
         */
        id?: string;
    };
};

export namespace SupplyAgreementCancelled {

    export enum _type {
        LEDGER_SUPPLY_AGREEMENT_CANCELLED = 'ledger.supply_agreement.cancelled',
    }


}

