import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { addDays, endOfMonth, format, isValid } from 'date-fns';
import MeteringConsumptionSummary from './MeteringConsumptionSummary';
import { SupplyPeriodSummary, useSupplyPeriodSummary } from '../connectionsApi';
import { FlowDirection } from '../dashboard/MeteringDataComponent';
import Card from '../../layout/Card';
import Page, { PageHeader } from '../../layout/Page';
import { useMeasurementPoints, useUsageDetailsV2 } from '../../telemetry/telemetryUsageApi';
import { capitaliseFirstText, newUTCDate, startOfDayFormatted } from '../../../util/helper-func';
import { useExternalBillingPresentation } from 'components/core/coreExternalBillingApi';

import caretRight from '../../../icons/caret-right.svg';
import '../../common/Table.scss';
import { LabelValue } from 'components/layout/ColumnCard';
import { IntervalReadingV2, UsageDetailItem } from '../../../api/openapi/telemetry';
import { getMeasurementPointFlow, getMeasurementPointOptions } from './MeteringSummaryHelper';
import { buildScopedToQueryField } from '../measurement-points/measurementPointHelpers';
import MeteringDailySummaryItemV2 from './MeteringDailySummaryItemV2';

const dateFormat = 'yyyy-MM-dd';

const MeteringDailySummary = () => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);

  const supplyPeriodId = searchParams.get('supply_period');
  const dateParam = searchParams.get('date');
  const initialDate = dateParam ? newUTCDate(dateParam) : new Date();
  const initialMeasurementPointType: string = searchParams.get('measurementPointType') || 'Meter';
  const initialMeasurementPointId: string | undefined =
    searchParams.get('measurementPointId') || undefined;
  const initialFlow: FlowDirection = (searchParams.get('flow') as FlowDirection) || 'IN';
  const [measurementPointType, setMeasurementPointType] = useState<string>(
    initialMeasurementPointType
  );
  const [measurementPointId, setMeasurementPointId] = useState<string | undefined>(
    initialMeasurementPointId
  );
  const [date, setDate] = useState<Date>(initialDate);
  const [flow] = useState<FlowDirection>(initialFlow);

  const { id } = useParams<{ id: string }>();

  const formattedDate = format(date, dateFormat);

  // The time in a day always fixed, it's not up to browser or libraries do choose
  const formattedStartDate = startOfDayFormatted(date);
  const formattedEndDate = startOfDayFormatted(addDays(date, 1));

  const {
    data: supplyPeriodData,
    isError: supplyPeriodDataError,
    isInitialLoading: supplyPeriodDataLoading,
  } = useSupplyPeriodSummary(id, { enabled: !!id });

  const scopedToQueryField = buildScopedToQueryField(id ?? '');
  const {
    data: measurementPointData,
    isError: measurementPointDataError,
    isInitialLoading: measurementPointDataLoading,
  } = useMeasurementPoints({
    scopedTo: scopedToQueryField,
    startsAt: formattedStartDate,
    endsAt: formattedEndDate,
    limit: 50,
    offset: 0,
  });

  const {
    data: telemetryData,
    isError: telemetryDataError,
    isInitialLoading: telemetryDataLoading,
  } = useUsageDetailsV2(
    {
      connectionId: id,
      endsAt: formattedEndDate,
      startsAt: formattedStartDate,
      measurementPointId: measurementPointId,
    },
    {
      enabled: !!id && !!measurementPointId,
    }
  );

  // find the supply period base from the supply_period param and use it to limit
  // the date options available
  const supplyPeriod: SupplyPeriodSummary =
    !supplyPeriodDataLoading &&
    supplyPeriodData &&
    supplyPeriodId &&
    supplyPeriodData?.supplyPeriodSummaries?.find(
      (period: SupplyPeriodSummary) => period.id === supplyPeriodId
    );

  // pull the contracted party name from core external billing
  const periodAgreementId = supplyPeriod?.externalSupplyAgreementId;
  const { data: externalData } = useExternalBillingPresentation(periodAgreementId ?? '', {
    enabled: !!periodAgreementId,
  });
  const contractedParty = externalData?.parties?.owner?.name ?? '-';
  const contractedPartyId =
    externalData && externalData?.parties?.owner?.id ? externalData.parties.owner.id : undefined;

  useEffect(() => {
    // update the URL whenever measurement point type, measurement point or date changes
    const params = new URLSearchParams();

    measurementPointType && params.set('measurementPointType', measurementPointType);
    measurementPointId && params.set('measurementPointId', measurementPointId);
    params.set('date', formattedDate);
    params.set('flow', flow);
    if (supplyPeriodId) {
      params.set('supply_period', supplyPeriodId);
    }

    navigate({ search: params.toString() }, { replace: true });
  }, [measurementPointType, measurementPointId, formattedDate, flow]);

  const connectionId = supplyPeriodData?.connectionId ?? id;

  let measurementPointOptions = getMeasurementPointOptions(
    measurementPointType,
    measurementPointData
  );

  const handleMeasurementPointTypeChange = (measurementPointType: string) => {
    setMeasurementPointType(measurementPointType);
    //reload the select box every time the measurementPointType changes
    measurementPointOptions = getMeasurementPointOptions(
      measurementPointType,
      measurementPointData
    );
    //auto-select the first
    setMeasurementPointId(measurementPointOptions?.[0]?.value);
  };

  const totals =
    getMeasurementPointFlow(measurementPointId, measurementPointData, flow) === 'IN'
      ? telemetryData?.totalsToConnection
      : telemetryData?.totalsFromConnection;

  let monthlyMeteringLink = `/connections/${id}/metering/month?date=${formattedDate}&measurementPointType=${measurementPointType}`;
  if (measurementPointId) {
    monthlyMeteringLink += `&measurementPointId=${measurementPointId}`;
  }
  if (supplyPeriodId) {
    monthlyMeteringLink += `&supply_period=${supplyPeriodId}`;
  }

  return (
    <>
      <PageHeader
        title={() => (
          <>
            <Link
              className="page__title-link"
              to={`/connections/${id}/supply-periods/${supplyPeriodId}/contracted-parties/${contractedPartyId}`}
            >
              {connectionId}
            </Link>
            <img src={caretRight} alt="" />
            <Link className="page__title-link" to={monthlyMeteringLink}>
              Monthly metering data
            </Link>
            <img src={caretRight} alt="" />
            Daily metering data
          </>
        )}
      />
      <Page>
        <Card>
          <div className="apl-display-flex apl-flex-row">
            <div className="apl-field-v1 apl-display-flex apl-flex-column apl-align-items-start">
              <label className="apl-field__label apl-mr" htmlFor="contracted-party-field">
                Contracted party
              </label>
              <p className="apl-my-none apl-h3" id="contracted-party-field">
                {contractedParty}
              </p>
            </div>
          </div>
        </Card>
        <Card>
          <div className="card__inner apl-display-flex apl-flex-row apl-align-items-center apl-justify-content-between apl-px">
            <div className="apl-display-flex apl-flex-row apl-align-items-center">
              <div className="apl-field-v1 apl-display-flex apl-flex-row apl-align-items-center apl-mr-s">
                <label className="apl-field__label apl-mr-s apl-mb-none" htmlFor="type-field">
                  Type
                </label>
                <br />
                <select
                  className="apl-select-v1_0 apl-mb-none"
                  data-testid="type field"
                  id="type-field"
                  name="meterType"
                  onChange={(e) => handleMeasurementPointTypeChange(e.target.value)}
                  value={measurementPointType}
                >
                  <option value="Meter">Meter</option>
                  <option value="Calculated">Calculated</option>
                </select>
              </div>
              <div className="apl-field-v1 apl-display-flex apl-flex-row apl-align-items-center apl-mr-s">
                <label className="apl-field__label apl-mr-s apl-mb-none" htmlFor="meter-field">
                  Measurement Point
                </label>
                <select
                  className="apl-select-v1_0 apl-mb-none"
                  data-testid="meter field"
                  id="meter-field"
                  name="meter"
                  onChange={(e) => setMeasurementPointId(e.target.value)}
                  value={measurementPointId}
                >
                  {measurementPointOptions.map((option: LabelValue) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
              </div>
              <div className="apl-form-layout-v1 apl-field-v1 apl-display-flex apl-flex-row apl-align-items-center apl-mr-s">
                <label className="apl-field__label apl-mr-s apl-mb-none" htmlFor="date-field">
                  Date
                </label>
                <input
                  className="apl-text-input-v1 apl-mb-none"
                  data-testid="date field"
                  id="date-field"
                  name="date"
                  onChange={(event) => {
                    const updated = newUTCDate(event.target.value);

                    if (isValid(updated)) {
                      setDate(updated);
                    }
                  }}
                  type="date"
                  min={supplyPeriod?.startDate}
                  max={supplyPeriod?.endDate ?? format(endOfMonth(new Date()), dateFormat)}
                  value={formattedDate}
                />
              </div>
              <p className="apl-my-none apl-mr-s">
                <strong>Flow:</strong>{' '}
                {`${capitaliseFirstText(
                  getMeasurementPointFlow(measurementPointId, measurementPointData, flow)
                )}`}
              </p>
              <p className="apl-my-none apl-display-flex apl-flex-row">
                <span style={{ fontWeight: 600 }}>Intervals:</span>
                &nbsp;
                {telemetryData?.items?.flatMap((i: UsageDetailItem) => i.intervalReadings)
                  ?.length ?? 0}
              </p>
            </div>
          </div>
        </Card>
        <MeteringConsumptionSummary
          flow={getMeasurementPointFlow(measurementPointId, measurementPointData, flow)}
          measurements={totals?.totalValues}
          title="Daily total consumption"
        />
        <Card className="card--square-top apl-p-none">
          <div className="table--scrolling">
            <table
              className="apl-table-v1 table--nowrap apl-mb-none"
              style={{
                minWidth: '100%',
              }}
            >
              <thead>
                <tr>
                  <th
                    className="apl-text-left sticky-col sticky-col--no-shadow"
                    rowSpan={2}
                    style={{ verticalAlign: 'bottom' }}
                  >
                    Time
                  </th>
                  <th className="apl-text-left" rowSpan={2} style={{ verticalAlign: 'bottom' }}>
                    Interval
                  </th>
                  <th className="apl-text-right" colSpan={3}>
                    Raw consumption
                  </th>

                  <th className="apl-text-right" colSpan={3}>
                    Distribution loss adjusted consumption
                  </th>
                  <th className="apl-text-left" rowSpan={2} style={{ verticalAlign: 'bottom' }}>
                    Quality
                  </th>
                  <th className="apl-text-left" rowSpan={2} style={{ verticalAlign: 'bottom' }}>
                    Power factor
                  </th>
                  {measurementPointType === 'Meter' && (
                    <th className="apl-text-left" rowSpan={2} style={{ verticalAlign: 'bottom' }}>
                      Source
                    </th>
                  )}
                  <th className="apl-text-left" rowSpan={2} style={{ verticalAlign: 'bottom' }}>
                    Usage Charge Filter
                  </th>
                  <th className="apl-text-left" rowSpan={2} style={{ verticalAlign: 'bottom' }}>
                    Actions
                  </th>
                </tr>
                <tr>
                  <th className="apl-text-right" style={{ verticalAlign: 'bottom' }}>
                    kWh
                  </th>
                  <th className="apl-text-right" style={{ verticalAlign: 'bottom' }}>
                    kVAh
                  </th>
                  <th className="apl-text-right" style={{ verticalAlign: 'bottom' }}>
                    kVArh
                  </th>
                  <th className="apl-text-right" style={{ verticalAlign: 'bottom' }}>
                    kWh
                  </th>
                  <th className="apl-text-right" style={{ verticalAlign: 'bottom' }}>
                    kVAh
                  </th>
                  <th className="apl-text-right" style={{ verticalAlign: 'bottom' }}>
                    kVArh
                  </th>
                </tr>
              </thead>
              {(supplyPeriodDataLoading || telemetryDataLoading || measurementPointDataLoading) && (
                <tbody>
                  <tr>
                    <td>Loading...</td>
                  </tr>
                </tbody>
              )}
              {(supplyPeriodDataError || telemetryDataError || measurementPointDataError) && (
                <tbody>
                  <tr>
                    <td>Sorry, there was an error.</td>
                  </tr>
                </tbody>
              )}
              {telemetryData && (
                <tbody>
                  {telemetryData?.items?.map((item: UsageDetailItem) => {
                    return item.intervalReadings
                      ?.sort(
                        (readingA: IntervalReadingV2, readingB: IntervalReadingV2) =>
                          (readingB.intervalNumber as number) - (readingA.intervalNumber as number)
                      )
                      .map((reading: IntervalReadingV2, index) => {
                        return (
                          <MeteringDailySummaryItemV2
                            key={index}
                            item={item}
                            reading={reading}
                            measurementPointType={measurementPointType}
                          />
                        );
                      });
                  })}
                </tbody>
              )}
            </table>
          </div>
        </Card>
      </Page>
    </>
  );
};

export default MeteringDailySummary;
