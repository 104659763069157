import React, { useMemo, useState } from 'react';
import cx from 'classnames';
import { useCurrencyTranslation } from 'hooks';
import { format, formatISO, subMonths } from 'date-fns';
import { SupplyPeriodAttributeProps, Widget } from '../Dashboard';
import { SummaryData, useBillingSummaries } from '../../billing/billingSummariesApi';
import {
  generateDateOptions,
  marketStartOfMonth,
  newMarketDate,
  marketTimezoneAddMonths,
} from '../../../util/helper-func';
import downArrow from '../../../icons/down-arrow.svg';
import upArrow from '../../../icons/up-arrow.svg';
import { useFlags } from 'launchdarkly-react-client-sdk';

const calculateDiff = (curr: number, prev: number) => {
  const billedDiff = curr / prev;
  return billedDiff > 1 ? Math.round((billedDiff - 1) * 100) : Math.round((1 - billedDiff) * 100);
};

const getArrow = (curr: number, prev: number) => {
  if (curr - prev === 0) {
    return null;
  } else if (curr > prev) {
    return <img src={upArrow} />;
  } else {
    return <img src={downArrow} />;
  }
};

type BillingSummaryWidgetProps = SupplyPeriodAttributeProps;

const BillingSummaryWidget = (props: BillingSummaryWidgetProps) => {
  const { translateCurrency } = useCurrencyTranslation();

  const {
    oci1904DashboardShowingWrongBillingSummary, // LD Client Key is oci-1904-dashboard-showing-wrong-billing-summary
  } = useFlags();

  const options = useMemo(() => generateDateOptions(true, 24), []);
  const { supplyPeriodAttribute } = props;

  const today = newMarketDate();
  const lastMonth = marketStartOfMonth(
    subMonths(oci1904DashboardShowingWrongBillingSummary ? today : newMarketDate(today), 1)
  );

  const [date, setDate] = useState<Date>(lastMonth);

  // treating current as current billing period which is last month
  const startCurrent = marketStartOfMonth(
    oci1904DashboardShowingWrongBillingSummary ? date : newMarketDate(date)
  );
  const endCurrent = marketStartOfMonth(
    marketTimezoneAddMonths(
      oci1904DashboardShowingWrongBillingSummary ? date : newMarketDate(date),
      1
    )
  );
  const startPrev = marketStartOfMonth(
    subMonths(oci1904DashboardShowingWrongBillingSummary ? date : newMarketDate(date), 1)
  );

  const {
    data,
    isInitialLoading: isLoading,
    isError,
  } = useBillingSummaries({
    startDate: formatISO(startPrev),
    endDate: formatISO(endCurrent),
    attributes: supplyPeriodAttribute,
  });
  const currentData =
    data &&
    data.summaries.find(
      (s: SummaryData) =>
        formatISO(newMarketDate(s.startDate)) === formatISO(newMarketDate(startCurrent))
    );
  const currTotalValue = currentData ? currentData.amount.total.value : 0;

  const prevData =
    data &&
    data.summaries.find(
      (s: SummaryData) =>
        formatISO(newMarketDate(s.startDate)) === formatISO(newMarketDate(startPrev))
    );
  const prevTotalValue = prevData ? prevData.amount.total.value : 0;

  const billedAmount = translateCurrency(currentData?.amount?.total);

  const billedDiff = currentData && prevData ? calculateDiff(currTotalValue, prevTotalValue) : 0;

  const billedDiffAbs = !isNaN(billedDiff) && isFinite(billedDiff) ? Math.abs(billedDiff) : 0;

  return (
    <Widget
      className="apl-pb"
      headerComponent={
        <h3 className="widget__title widget__title--inline apl-h3 apl-mb-none">
          Billing summary
          <select
            className="apl-select-v1_0"
            data-testid="billing-summary-date-field"
            onChange={(event) => setDate(newMarketDate(event?.target.value))}
            value={formatISO(newMarketDate(date), { representation: 'date' })}
          >
            {options.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        </h3>
      }
    >
      {isLoading && <div className="apl-p">Loading...</div>}
      {isError && <div className="apl-p">Sorry, there was an error.</div>}
      {currentData && prevData && (
        <>
          <div
            className={cx(
              'apl-display-flex',
              'apl-flex-column',
              'apl-align-items-center',
              'apl-py-l'
            )}
            style={{
              borderBottom: '1px solid var(--secondary-bg)',
            }}
          >
            <p className="apl-mb-xs">Total billed amount</p>
            <p className="apl-mt-none apl-mb-xs" style={{ fontSize: '32px', fontWeight: 700 }}>
              {billedAmount}
            </p>
            <p className={cx('apl-mt-none', 'apl-display-flex', 'apl-flex-row', 'apl-align-items')}>
              {getArrow(currTotalValue, prevTotalValue)}
              {billedDiffAbs}% from {format(newMarketDate(prevData.startDate), 'MMMM')}
            </p>
          </div>
          <table className="widget__table apl-px-s">
            <thead>
              <tr className="widget__table-row">
                <th className="widget__table-header"></th>
                <th className="widget__table-header apl-text-right apl-pr">
                  {format(newMarketDate(startCurrent), 'MMM')}
                </th>
                <th className="widget__table-header apl-text-right">
                  {format(newMarketDate(startPrev), 'MMM')}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr className="widget__table-row">
                <td className="widget__table-cell">Number of invoices</td>
                <td className={cx('widget__table-cell', 'apl-text-right')}>
                  <div
                    className={cx(
                      'apl-display-flex',
                      'apl-flex-row',
                      'apl-align-items-center',
                      'apl-justify-content-end'
                    )}
                  >
                    <strong>{currentData.invoices}</strong>
                    {getArrow(currentData.invoices, prevData.invoices)}
                  </div>
                </td>
                <td className="widget__table-cell apl-text-right">
                  <strong>{prevData.invoices}</strong>
                </td>
              </tr>
              <tr className="widget__table-row">
                <td className="widget__table-cell">Active connections</td>
                <td className="widget__table-cell apl-text-right">
                  <div
                    className={cx(
                      'apl-display-flex',
                      'apl-flex-row',
                      'apl-align-items-center',
                      'apl-justify-content-end'
                    )}
                  >
                    <strong>{currentData.activeConnections}</strong>
                    {getArrow(currentData.activeConnections, prevData.activeConnections)}
                  </div>
                </td>
                <td className="widget__table-cell apl-text-right">
                  <strong>{prevData.activeConnections}</strong>
                </td>
              </tr>
            </tbody>
          </table>
        </>
      )}
    </Widget>
  );
};

export default BillingSummaryWidget;
