/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * The flag indicating if status of whether the connection can be billed
 */
export enum BillingReadinessStatus {
    URN_FLUX_BILLING_READINESS_READY = 'urn:flux:billing:readiness:ready',
    URN_FLUX_BILLING_READINESS_NOT_READY = 'urn:flux:billing:readiness:not_ready',
    URN_FLUX_BILLING_READINESS_INVOICE_CREATED = 'urn:flux:billing:readiness:invoice_created',
    URN_FLUX_BILLING_READINESS_INVOICE_CREATION_FAILED = 'urn:flux:billing:readiness:invoice_creation_failed',
}
