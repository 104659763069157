/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DateTimeType } from '../models/DateTimeType';
import type { Error } from '../models/Error';
import type { Summary } from '../models/Summary';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class SummaryMetricsService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @param startDate
     * @param endDate
     * @param attributes
     * @returns Summary OK
     * @returns Error Error
     * @throws ApiError
     */
    public getSummaries(
        startDate: DateTimeType,
        endDate: DateTimeType,
        attributes?: Array<string>,
    ): CancelablePromise<Summary | Error> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/billing/summaries',
            query: {
                'startDate': startDate,
                'endDate': endDate,
                'attributes': attributes,
            },
        });
    }

}
