/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { RatingCalculation } from '../models/RatingCalculation';
import type { RatingCalculationRequest } from '../models/RatingCalculationRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class RatingsService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Creates a calculation of a rating request.
     * @param requestBody
     * @returns RatingCalculation OK
     * @throws ApiError
     */
    public postRatings(
        requestBody: RatingCalculationRequest,
    ): CancelablePromise<RatingCalculation> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/ratings',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Generic error response`,
                500: `Generic error response`,
            },
        });
    }

}
