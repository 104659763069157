/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { InvoiceContext } from './InvoiceContext';

export type GenerateInvoiceCommand = ({
    _type?: GenerateInvoiceCommand._type;
} & InvoiceContext);

export namespace GenerateInvoiceCommand {

    export enum _type {
        URN_FLUX_BILLING_LIFECYCLE_COMMAND_GENERATE_INVOICE = 'urn:flux:billing:lifecycle:command:generate_invoice',
    }


}

