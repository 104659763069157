import React, { FunctionComponent, ReactElement } from 'react';
import Card from './Card';

export interface LabelValue {
  label: string;
  value: string;
}

interface ColumnCardProps {
  children?: React.ReactNode;
  button?: React.ReactNode;
  secondaryButton?: React.ReactNode;
  buttonColumnVisible?: boolean;
  content: LabelValue[];
  id: string;
}

const ColumnCard: FunctionComponent<ColumnCardProps> = ({
  children,
  button,
  secondaryButton,
  buttonColumnVisible,
  content,
  id,
}) => {
  return (
    <Card id={id} className="apl-width-full apl-flex-column">
      <div
        className={
          'apl-display-flex apl-flex-row apl-justify-content-between apl-align-items-start'
        }
      >
        <div className="apl-p-s apl-width-full">
          {content.map((row, index) => (
            <CardRow id={id} key={index} rowData={row} />
          ))}
          {children}
        </div>
        <div
          style={{
            width: '10%',
            display: buttonColumnVisible === false ? 'none' : 'block',
          }}
        >
          {secondaryButton}
          {button}
        </div>
      </div>
    </Card>
  );
};

interface CardRowProps {
  rowData: LabelValue;
  id: string;
  suffix?: string;
}

export const CardRow: FunctionComponent<CardRowProps> = ({ rowData, id, suffix }) => {
  const htmlId = `${id}-${rowData.label.split(' ').join('-').toLowerCase()}`;
  return (
    <LabeledRowComponent
      label={rowData.label}
      htmlId={htmlId}
      component={
        <span>
          <strong data-testid={htmlId} id={htmlId}>
            {rowData.value}
          </strong>{' '}
          {suffix}
        </span>
      }
    />
  );
};

interface LabeledComponentProps {
  label: string;
  htmlId: string;
  component: ReactElement;
}

export const LabeledRowComponent: FunctionComponent<LabeledComponentProps> = ({
  htmlId,
  component,
  label,
}) => {
  return (
    <div
      className=" apl-display-flex apl-flex-row"
      style={{
        width: '100%',
      }}
    >
      <div className="apl-width-1/2  apl-display-flex apl-flex-row apl-justify-content-start apl-pb-s">
        <label htmlFor={htmlId}>{label}</label>
      </div>
      <div className="apl-width-1/2 apl-display-flex apl-flex-row apl-justify-content-start">
        {component}
      </div>
    </div>
  );
};

export default ColumnCard;
