import React, { FunctionComponent, useEffect, useState } from 'react';
import Modal from 'react-modal';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { Connection, useAddConnections, useConnections } from './connectionsApi';
import ConnectionSearch from './ConnectionSearch';
import AuthorizedAction from '../common/AuthorizedAction';
import Card from '../layout/Card';
import { modalStyles } from '../layout/Modal';
import Page, { ErrorPage, LoadingPage, PageHeader } from '../layout/Page';
import { useStore } from '../../store';
import { Permission } from '../../auth/getPermissions';
import { isTest, isProd } from '../../environment';
import { ratingConfigClient } from 'api';
const ConnectionList: FunctionComponent = () => {
  const [open, setOpen] = useState(false);

  const { search } = useLocation();
  const navigate = useNavigate();

  const query = new URLSearchParams(search);
  const id = query.get('id');

  const {
    data: connections,
    isInitialLoading: isLoadingConnections,
    isError: errorFetchingConnections,
  } = useConnections();

  // try and find the matching connection if we have requested a search
  useEffect(() => {
    if (!isLoadingConnections) {
      const found =
        id && connections ? connections.filter((c: Connection) => c.connectionId === id) : null;
      if (found && found.length > 1) {
        found.forEach((element: Connection) => {
          ratingConfigClient.connections
            .getConnectionByIdFromCore(element.id)
            .then(() => navigate(`/connections/${element.id}`, { replace: true }))
            .catch(() => {
              if (!isProd) {
                console.debug('This is generally an expected 500');
              }
            });
        });
      } else if (found && found.length == 1) {
        navigate(`/connections/${found[0].id}`, { replace: true });
      } else if (id) {
        useStore.getState().addNotification({
          title: 'Connection ' + id + ' not found.',
          color: 'danger',
          id: 'ConnectionNotFound',
        });
      }
    }
  }, [id, connections, isLoadingConnections]);

  if (isLoadingConnections) {
    return <LoadingPage />;
  }

  if (errorFetchingConnections) {
    console.error(new Error(connections));

    return <ErrorPage />;
  }

  return (
    <>
      <PageHeader title="Connections">
        {!(isTest() || isProd()) && (
          <AuthorizedAction
            extraClasses="is-primary"
            onClick={() => setOpen(true)}
            permission={Permission.CONNECTION_EDIT}
            testId="add-connection-btn"
          >
            Add a connection
          </AuthorizedAction>
        )}
      </PageHeader>
      <Page>
        <Card>
          <div className="card__inner apl-px-s apl-pb-s">
            <ConnectionSearch
              handleOnKeyUp={(event) => {
                if (event.key === 'Enter') {
                  const value = event.currentTarget.value;

                  navigate(`/connections?id=${value}`);
                }
              }}
            />
          </div>
          <table className="apl-table-v1 half_xl">
            <thead>
              <tr>
                <th>Connection ID</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {connections.map((c: Connection, index: number) => (
                <tr key={index}>
                  <td>
                    <Link
                      className="apl-color-primary"
                      data-testid={`view-connection-${index + 1}`}
                      to={`${c.id}`}
                    >
                      {c.connectionId}
                    </Link>
                  </td>
                  <td>
                    <AuthorizedAction
                      testId={`view-supply-periods-button-${index + 1}`}
                      onClick={() => navigate(`/connections/${c.id}/supply-periods`)}
                      permission={Permission.CONNECTION_VIEW}
                    >
                      View supply periods
                    </AuthorizedAction>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </Card>
        <Modal
          isOpen={open}
          onRequestClose={() => setOpen(false)}
          shouldCloseOnOverlayClick={true}
          style={{
            ...modalStyles,
            content: {
              ...modalStyles.content,
              boxSizing: 'border-box',
              minWidth: '375px',
            },
          }}
        >
          <AddConnection onRequestClose={() => setOpen(false)} />
        </Modal>
      </Page>
    </>
  );
};

interface AddConnectionProps {
  onRequestClose: () => void;
}

export const AddConnection: FunctionComponent<AddConnectionProps> = ({ onRequestClose }) => {
  const [connectionId, setConnectionId] = useState('');
  const [id, setId] = useState('');

  const { mutateAsync, isLoading } = useAddConnections();

  const addConnection = async () => {
    await mutateAsync({ id, connectionId, expectedChargeTags: [], autoProvisioning: true });
    onRequestClose();
  };

  return (
    <div className="apl-display-flex apl-flex-column">
      <h2>Add a connection</h2>
      <div className="apl-mb-l">
        <div className="apl-field-v1">
          <label
            className="apl-field__label"
            htmlFor="connection-id-field"
            style={{
              fontWeight: 600,
            }}
          >
            Connection ID
          </label>
          <input
            className="apl-text-input-v1"
            data-testid="add-connection-input"
            id="connection-id-field"
            onChange={(event) => setConnectionId(event.target.value)}
            type="text"
            value={connectionId}
          />
        </div>
      </div>
      <div className="apl-mb-l">
        <div className="apl-field-v1">
          <label
            className="apl-field__label"
            htmlFor="id-field"
            style={{
              fontWeight: 600,
            }}
          >
            ID
          </label>
          <input
            className="apl-text-input-v1"
            data-testid="add-connection-input"
            id="id-field"
            onChange={(event) => setId(event.target.value)}
            type="text"
            value={id}
          />
        </div>
      </div>
      <div className="apl-display-flex apl-flex-row apl-justify-content-end">
        <button
          className="apl-button-v1"
          disabled={isLoading}
          onClick={onRequestClose}
          data-testid="cancel-create-connection-button"
          title={isLoading ? 'Loading...' : undefined}
        >
          Cancel
        </button>
        <AuthorizedAction
          extraClasses="is-primary apl-ml-s"
          isDisabled={isLoading}
          onClick={addConnection}
          testId="create-connection-button"
          permission={Permission.CONNECTION_EDIT}
        >
          Save
        </AuthorizedAction>
      </div>
    </div>
  );
};

export default ConnectionList;
