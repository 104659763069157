/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Charge } from '../models/Charge';
import type { ChargeRateList } from '../models/ChargeRateList';
import type { ChargeV1 } from '../models/ChargeV1';
import type { ImportResult } from '../models/ImportResult';
import type { Plan } from '../models/Plan';
import type { PlanBody } from '../models/PlanBody';
import type { PlanBodyV1 } from '../models/PlanBodyV1';
import type { PlanExport } from '../models/PlanExport';
import type { PlanExportV1 } from '../models/PlanExportV1';
import type { PlanStateBody } from '../models/PlanStateBody';
import type { PlanUsage } from '../models/PlanUsage';
import type { PlanV1 } from '../models/PlanV1';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
import { PlanTagsResponse } from '../models/PlanTagsResponse';

export class PlansService {

    constructor(public readonly httpRequest: BaseHttpRequest) { }

    /**
     * Returns the expanded rate table for a Plan, dates used in the response are UTC.
     * The expanded rate table consists of all rates for a Plan that fall within the requested "Time range", expanded out into a time based sequence of rates per "Time range".
     * @param id Id of the Plan
     * @param fromDate The start date of the requested Time Range to include Rates from
     * @param toDate The end date of the requested Time Range to include Rates from.
     * Validation Rules - A 422 Validation error will be returned if these rules are not met.
     * - The toDate must be after the fromDate.
     * - The toDate must not be more than 6 years after the fromDate.
     *
     * @param boundedByPlanEnd Whether the rates should stop when the plan ends or continue on until toDate
     *
     * @returns ChargeRateList Returns the expanded rate table for the Plan - an array of all rates that fall within the requested range. Dates used in the response are UTC.
     * If the requested range (toDate - fromDate) is outside of the Plan range, the response will be an empty array.
     *
     * @throws ApiError
     */
    public getRatesTable(
        id: string,
        fromDate: string,
        toDate: string,
        boundedByPlanEnd: boolean = true,
    ): CancelablePromise<ChargeRateList> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v2/plans/{id}/rates',
            path: {
                'id': id,
            },
            query: {
                'fromDate': fromDate,
                'toDate': toDate,
                'boundedByPlanEnd': boundedByPlanEnd,
            },
            errors: {
                404: `Plan not Found`,
                422: `Could not process request due to a validation error. See Parameters for validation rules.`,
                500: `Not OK`,
            },
        });
    }

    /**
     * @deprecated
     * Adds Charges to an existing Plan
     * @param id Id of the Plan to add the Charges to
     * @param requestBody List of Charges to be added to the Plan
     * @returns ChargeV1 Charges successfully added to Plan. Returns list of the charges added.
     * @throws ApiError
     */
    public addChargesToPlan(
        id: string,
        requestBody: Array<ChargeV1>,
    ): CancelablePromise<Array<ChargeV1>> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/plans/{id}/charges',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Plan not found`,
                422: `Could not process request due to a validation error. See Charge schema for validation rules.`,
            },
        });
    }

    /**
     * Adds Charges to an existing Plan
     * @param id Id of the Plan to add the Charges to
     * @param requestBody List of Charges to be added to the Plan
     * @returns Charge Charges successfully added to Plan. Returns list of the charges added.
     * @throws ApiError
     */
    public addChargesV2ToPlan(
        id: string,
        requestBody: Array<Charge>,
    ): CancelablePromise<Array<Charge>> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/plans/{id}/charges',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Plan not found`,
                422: `Could not process request due to a validation error. See Charge schema for validation rules.`,
            },
        });
    }

    /**
     * @deprecated
     * Updates an existing charge
     * @param id Id of the Plan containing the Charge
     * @param chargeId Id of the Charge to be updated
     * @param requestBody Updated details for the Charge
     * @returns ChargeV1 Charge updated successfully. Returns Charge details, with the updates applied.
     * @throws ApiError
     */
    public updateCharge(
        id: string,
        chargeId: string,
        requestBody: ChargeV1,
    ): CancelablePromise<ChargeV1> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/v1/plans/{id}/charges/{chargeId}',
            path: {
                'id': id,
                'chargeId': chargeId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `No such Plan, or no such Charge attached to the Plan`,
                422: `Could not process request due to a validation error. See Charge schema for validation rules.`,
            },
        });
    }

    /**
     * @deprecated
     * Deletes an existing Charge
     * @param id Id of the Plan containing the Charge
     * @param chargeId Id of the Charge to be deleted
     * @returns void
     * @throws ApiError
     */
    public deleteCharge(
        id: string,
        chargeId: string,
    ): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/v1/plans/{id}/charges/{chargeId}',
            path: {
                'id': id,
                'chargeId': chargeId,
            },
            errors: {
                404: `No such Plan, or no such Charge attached to the Plan`,
                422: `Could not process request due to a validation error`,
            },
        });
    }

    /**
     * Updates an existing charge
     * @param id Id of the Plan containing the Charge
     * @param chargeId Id of the Charge to be updated
     * @param requestBody Updated details for the Charge
     * @returns Charge Charge updated successfully. Returns Charge details, with the updates applied.
     * @throws ApiError
     */
    public updateChargeV2(
        id: string,
        chargeId: string,
        requestBody: Charge,
    ): CancelablePromise<Charge> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/v2/plans/{id}/charges/{chargeId}',
            path: {
                'id': id,
                'chargeId': chargeId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `No such Plan, or no such Charge attached to the Plan`,
                422: `Could not process request due to a validation error. See Charge schema for validation rules.`,
            },
        });
    }

    /**
     * Deletes an existing Charge
     * @param id Id of the Plan containing the Charge
     * @param chargeId Id of the Charge to be deleted
     * @returns void
     * @throws ApiError
     */
    public deleteChargeV2(
        id: string,
        chargeId: string,
    ): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/v2/plans/{id}/charges/{chargeId}',
            path: {
                'id': id,
                'chargeId': chargeId,
            },
            errors: {
                404: `No such Plan, or no such Charge attached to the Plan`,
                422: `Could not process request due to a validation error`,
            },
        });
    }

    /**
     * @deprecated
     * Creates a new Plan
     * @param requestBody Details of the new Plan to be created.
     * @returns PlanV1 Plan created successfully. Returns details of the new Plan.
     * @throws ApiError
     */
    public createPlan(
        requestBody: PlanBodyV1,
    ): CancelablePromise<PlanV1> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/plans',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                422: `Could not process request due to a validation error. See PlanBody schema for validation rules.`,
            },
        });
    }

    /**
     * Creates a new Plan
     * @param requestBody Details of the new Plan to be created.
     * @returns Plan Plan created successfully. Returns details of the new Plan.
     * @throws ApiError
     */
    public createPlanV2(
        requestBody: PlanBody,
    ): CancelablePromise<Plan> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/plans',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                422: `Could not process request due to a validation error. See PlanBody schema for validation rules.`,
            },
        });
    }

    /**
     * @deprecated
     * Retrieves a Plan by ID
     * @param id Id of the Plan requested
     * @returns PlanV1 Details of the Plan
     * @throws ApiError
     */
    public getPlanById(
        id: string,
    ): CancelablePromise<PlanV1> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/plans/{id}',
            path: {
                'id': id,
            },
            errors: {
                404: `Plan not found`,
            },
        });
    }

    /**
     * @deprecated
     * Updates an existing Plan
     * @param id Id of the Plan to be updated
     * @param requestBody Updated details for the Plan
     * @returns PlanV1 Plan updated successfully. Returns Plan details, with the updates applied.
     * @throws ApiError
     */
    public updatePlan(
        id: string,
        requestBody: PlanBodyV1,
    ): CancelablePromise<PlanV1> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/v1/plans/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                404: `Plan not found`,
                422: `Could not process request due to a validation error. See PlanBody schema for validation rules.`,
            },
        });
    }

    /**
     * Retrieves a Plan by ID
     * @param id Id of the Plan requested
     * @param includeDeleted
     * @returns Plan Details of the Plan
     * @throws ApiError
     */
    public getPlanV2ById(
        id: string,
        includeDeleted: boolean = false,
    ): CancelablePromise<Plan> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v2/plans/{id}',
            path: {
                'id': id,
            },
            query: {
                'includeDeleted': includeDeleted,
            },
            errors: {
                404: `Plan not found`,
            },
        });
    }
    public getPlanLevelTags(): CancelablePromise<PlanTagsResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v2/plan/tags'
        });
    }

    /**
     * Updates an existing Plan
     * @param id Id of the Plan to be updated
     * @param requestBody Updated details for the Plan
     * @returns Plan Plan updated successfully. Returns Plan details, with the updates applied.
     * @throws ApiError
     */
    public updatePlanV2(
        id: string,
        requestBody: PlanBody,
    ): CancelablePromise<Plan> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/v2/plans/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                404: `Plan not found`,
                422: `Could not process request due to a validation error. See PlanBody schema for validation rules.`,
            },
        });
    }

    /**
     * @deprecated
     * Update the planState of a Plan
     * @param id Id of the Plan to update planState for
     * @param requestBody New plan state
     * @returns PlanV1 Plan state updated successfully. Returns Plan details, with the state update applied.
     * @throws ApiError
     */
    public updatePlanState(
        id: string,
        requestBody: PlanStateBody,
    ): CancelablePromise<PlanV1> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/v1/plans/{id}/state',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                404: `Plan not found`,
                422: `Could not process request due to a validation error. See PlanStateBody schema for validation rules.`,
            },
        });
    }

    /**
     * Update the planState of a Plan
     * @param id Id of the Plan to update planState for
     * @param requestBody New plan state
     * @returns Plan Plan state updated successfully. Returns Plan details, with the state update applied.
     * @throws ApiError
     */
    public updatePlanStateV2(
        id: string,
        requestBody: PlanStateBody,
    ): CancelablePromise<Plan> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/v2/plans/{id}/state',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                404: `Plan not found`,
                422: `Could not process request due to a validation error. See PlanStateBody schema for validation rules.`,
            },
        });
    }

    /**
     * Get usage data for given Plan
     * @param id
     * @returns PlanUsage Plan usage data
     * @throws ApiError
     */
    public getPlanUsage(
        id: string,
    ): CancelablePromise<PlanUsage> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v2/plans/{id}/usage',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @deprecated
     * Exports Plans by list of Provider Ids or list of Plan Ids
     * @param providerIds List of Provider ids to export Plans for. At least one Provider Id or one Plan Id is required to process the export.
     * @param planIds List of Plan ids to export Plans for. At least one Provider Id or one Plan Id is required to process the export.
     * @returns PlanExportV1 A plan export object containing a list of Plans, a list of the Qualifiers on those Plans and a list of the Providers for those Plans.
     * @throws ApiError
     */
    public exportPlans(
        providerIds?: Array<string>,
        planIds?: Array<string>,
    ): CancelablePromise<PlanExportV1> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/export',
            query: {
                'providerIds': providerIds,
                'planIds': planIds,
            },
            errors: {
                404: `No Plans were found to match the Plan or Provider Ids provided.`,
            },
        });
    }

    /**
     * Exports Plans by list of Provider Ids or list of Plan Ids
     * @param providerIds List of Provider ids to export Plans for. At least one Provider Id or one Plan Id is required to process the export.
     * @param planIds List of Plan ids to export Plans for. At least one Provider Id or one Plan Id is required to process the export.
     * @returns PlanExport A plan export object containing a list of Plans, a list of the Qualifiers on those Plans and a list of the Providers for those Plans.
     * @throws ApiError
     */
    public exportPlansV2(
        providerIds?: Array<string>,
        planIds?: Array<string>,
    ): CancelablePromise<PlanExport> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v2/export',
            query: {
                'providerIds': providerIds,
                'planIds': planIds,
            },
            errors: {
                404: `No Plans were found to match the Plan or Provider Ids provided.`,
            },
        });
    }

    /**
     * @deprecated
     * Imports Plans from an exported json file
     * @param requestBody Json file data describing the Plans, Qualifiers and Providers to import. Must include at least one Plan or one Provider to import.
     * @returns ImportResult A plan import result object containing details of the succeeding and failing imports for each of the Plans, Qualifiers and Providers.
     * @throws ApiError
     */
    public importPlans(
        requestBody: PlanExportV1,
    ): CancelablePromise<ImportResult> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/import',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Imports Plans from an exported json file
     * @param requestBody Json file data describing the Plans, Qualifiers and Providers to import. Must include at least one Plan or one Provider to import.
     * @returns ImportResult A plan import result object containing details of the succeeding and failing imports for each of the Plans, Qualifiers and Providers.
     * @throws ApiError
     */
    public importPlansV2(
        requestBody: PlanExport,
    ): CancelablePromise<ImportResult> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/import',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    public listPlanLinkPurposes(): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v3/plan-link-purposes',
        });
    }
}