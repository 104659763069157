import { useFlags } from 'launchdarkly-react-client-sdk';
import { getMarketFunction } from 'list-data/getMarketFunction';
import { useListData } from 'list-data/ListDataContext';
import React, { FunctionComponent, useState } from 'react';
import Modal from 'react-modal';
import { Link, useNavigate } from 'react-router-dom';
import { Permission } from '../../auth/getPermissions';
import searchIcon from '../../icons/search.svg';
import AuthorizedAction from '../common/AuthorizedAction';
import Card from '../layout/Card';
import { modalStyles } from '../layout/Modal';
import Page, { ErrorPage, LoadingPage, PageHeader } from '../layout/Page';
import AddPlan from '../plans/AddPlan';
import { ProviderSummary, useProviderSummary } from './providersApi';
import { EuiFlexGroup, EuiFlexItem } from '@elastic/eui';

const ProviderSummaryList: FunctionComponent = () => {
  const { fbau773RemoveViewLossFactorCodesFromAuProvidersPage } = useFlags();

  const [search, setSearch] = useState('');
  const [addPlanIsOpen, setIsOpen] = useState(false);
  const [selectedProviderId, setIsSelectedProviderId] = useState('');
  const [sortAsc, setSortAsc] = useState(true);

  const [marketFunctionFlags] = useListData(['MARKET_FUNCTION']);

  const navigate = useNavigate();

  const { data, isInitialLoading: isLoading, isError } = useProviderSummary();

  const closeAddPlan = () => setIsOpen(false);
  const openAddPlan = () => setIsOpen(true);

  // useListData unreliable and can be undefined until auto-fetched again
  const viewLossFactors =
    marketFunctionFlags != undefined && fbau773RemoveViewLossFactorCodesFromAuProvidersPage
      ? getMarketFunction(
          'urn:flux:rating-config:enable:view-loss-factors',
          undefined,
          marketFunctionFlags
        )
      : true;

  if (isLoading) {
    return <LoadingPage />;
  }

  if (isError) {
    return <ErrorPage />;
  }

  return (
    <>
      <PageHeader title="Providers & plans">
        <AuthorizedAction
          onClick={() => navigate('/qualifiers')}
          permission={Permission.QUALIFIERS_VIEW}
        >
          Manage qualifiers
        </AuthorizedAction>
        <AuthorizedAction
          extraClasses="apl-ml-s"
          onClick={() => navigate('/provisioning/exclusions')}
          // TODO: update permission to provisioning specific permission if needed
          permission={Permission.PROVIDERS_VIEW}
        >
          Manage provisioning exclusions
        </AuthorizedAction>
        <AuthorizedAction
          extraClasses="apl-ml-s"
          onClick={() => navigate('/providers/manage')}
          permission={Permission.PROVIDERS_EDIT}
        >
          Manage providers
        </AuthorizedAction>
      </PageHeader>
      <Page>
        <Card className="apl-pt-none">
          <div className="card__inner table-filter apl-py">
            <div className="table-filter__input search-filter">
              <input
                className="apl-text-input-v1 search-filter__input"
                onChange={(event) => setSearch(event.target.value.toLowerCase())}
                placeholder="Find a provider"
                type="text"
              />
              <img alt="search icon" className="search-filter__icon" src={searchIcon} />
            </div>
          </div>
          <table className="apl-table-v1">
            <thead>
              <tr>
                <th
                  className={sortAsc ? 'is-sortable is-sorted-desc' : 'is-sortable is-sorted-asc'}
                  onClick={() => setSortAsc(!sortAsc)}
                >
                  Provider name
                </th>
                <th>Plans</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {data
                .filter((item: { providerName: string }) =>
                  item.providerName.toLowerCase().includes(search)
                )
                .sort((a: { providerName: string }, b: { providerName: string }) => {
                  const providerNameA = a.providerName;
                  const providerNameB = b.providerName;

                  if (providerNameA < providerNameB) {
                    return sortAsc ? -1 : 1;
                  } else if (providerNameA > providerNameB) {
                    return sortAsc ? 1 : -1;
                  }

                  return 0;
                })
                .map((s: ProviderSummary) => {
                  const { defaultChargeTag, plansCount, providerId, providerName } = s;

                  const testId = providerName.replace(/ /g, '-').toLowerCase();

                  return (
                    <tr data-testid={`${testId}-row`} key={testId}>
                      <td>
                        <Link
                          className="apl-color-primary"
                          data-testid={`${testId}-plans-link`}
                          to={`/providers/${providerId}/plans`}
                        >
                          {providerName}
                        </Link>
                      </td>
                      <td>{plansCount}</td>
                      <td>
                        <EuiFlexGroup gutterSize="s" alignItems="center">
                          <EuiFlexItem grow={false}>
                            <AuthorizedAction
                              onClick={() => {
                                setIsSelectedProviderId(providerId);
                                openAddPlan();
                              }}
                              permission={Permission.PLAN_EDIT}
                            >
                              Add plan
                            </AuthorizedAction>
                          </EuiFlexItem>

                          {viewLossFactors && defaultChargeTag === 'NETWORK' && (
                            <EuiFlexItem grow={false}>
                              <AuthorizedAction
                                onClick={() =>
                                  navigate(`/providers/${providerId}/loss-factor-codes`)
                                }
                                permission={Permission.PROVIDERS_VIEW}
                              >
                                View loss factor codes
                              </AuthorizedAction>
                            </EuiFlexItem>
                          )}
                        </EuiFlexGroup>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </Card>
        <Modal
          isOpen={addPlanIsOpen}
          onRequestClose={closeAddPlan}
          style={modalStyles}
          shouldCloseOnOverlayClick={false}
        >
          <AddPlan selectedProviderId={selectedProviderId} close={closeAddPlan} />
        </Modal>
      </Page>
    </>
  );
};

export default ProviderSummaryList;
