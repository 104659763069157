/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { Artefacts } from './Artefacts';
import type { CreditDataTotal } from './CreditDataTotal';
import type { EventLineItem } from './EventLineItem';
import type { InvoiceContext } from './InvoiceContext';

export type CreditNoteEvent = {
    _type: CreditNoteEvent._type;
    _version: string;
    _correlationId?: string;
    _publishedAt?: string;
    id: string;
    context?: InvoiceContext;
    invoiceUUID: string;
    invoiceNumber: string;
    invoice: string;
    artefacts?: Artefacts;
    issuedAt: string;
    /**
     * The three letter ISO code
     */
    currency?: string;
    taxRate?: number;
    totals?: CreditDataTotal;
    totalRawUsage: number;
    /**
     * Unique identifier for the transaction
     */
    externalTransactionReference?: string;
    lineItems?: Array<EventLineItem>;
    /**
     * Whether or not this is a Buyer Created Tax Invoice
     */
    isCustomerGenerated?: boolean;
    /**
     * The id of the supply agreement linked to the invoice of this credit note
     */
    externalSupplyAgreementId?: string;
};

export namespace CreditNoteEvent {

    export enum _type {
        URN_FLUX_EVENT_BILLING_CREDIT_NOTE_ISSUED = 'urn:flux:event:billing:credit_note:issued',
        URN_FLUX_EVENT_BILLING_CREDIT_NOTE_UPDATED = 'urn:flux:event:billing:credit_note:updated',
    }


}

