import React, { FunctionComponent } from 'react';
import { useParams } from 'react-router-dom';
import EasiPlanDetails from './easi/EasiPlanDetails';
import SimplePlanDetails from './simple/SimplePlanDetails';
import { Connection } from '../connections/connectionsApi';
import { ErrorPage, LoadingPage } from '../layout/Page';
import { usePlanUsageV2, usePlanV2 } from './planApi';

interface PlanDetailsProps {
  connection?: Connection;
  supplyPeriodId?: string;
  contractedPartyId?: string;
}

const PlanDetails: FunctionComponent<PlanDetailsProps> = ({
  connection,
  supplyPeriodId,
  contractedPartyId,
}) => {
  const { planId } = useParams<{ planId: string }>();
  const { data, isError, isInitialLoading: isLoading } = usePlanV2(planId);
  const {
    data: usageData,
    isError: usageDataError,
    isInitialLoading: usageDataLoading,
  } = usePlanUsageV2(planId);

  if (isError || usageDataError) {
    return <ErrorPage />;
  }

  if (isLoading || usageDataLoading) {
    return <LoadingPage />;
  }

  switch (data?.structure) {
    case 'EASI_144':
    case 'EASI_48':
      return (
        <EasiPlanDetails
          connection={connection}
          plan={data}
          usage={usageData}
          supplyPeriodId={supplyPeriodId}
          contractedPartyId={contractedPartyId}
        />
      );

    default:
      return (
        <SimplePlanDetails
          connection={connection}
          plan={data}
          usage={usageData}
          supplyPeriodId={supplyPeriodId}
          contractedPartyId={contractedPartyId}
        />
      );
  }
};

export default PlanDetails;
