import React, { useState } from 'react';
import { RatingStatus } from '../../rating-calculator/ratingsApi';
import { EuiSelect } from '@elastic/eui';

const ratingStatusSelectOptions = [
  {
    label: 'Failed',
    value: 'urn:flux:rating:request:status:failed',
  },
  {
    label: 'Delayed',
    value: 'urn:flux:rating:request:status:in-progress:delayed',
  },
];

function useRatingStatusSelect() {
  const [ratingStatus, setRatingStatus] = useState<RatingStatus>(
    'urn:flux:rating:request:status:failed'
  );

  const RatingStatusSelect = () => {
    return (
      <EuiSelect
        data-testid="rating status select"
        value={ratingStatus}
        onChange={(e) => setRatingStatus(e.currentTarget.value as RatingStatus)}
        options={ratingStatusSelectOptions.map(({ label, value }) => ({ value, text: label }))}
      />
    );
  };

  return { ratingStatus, RatingStatusSelect };
}

export default useRatingStatusSelect;
