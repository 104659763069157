import React, { FunctionComponent } from 'react';
import './Card.scss';

interface CardProps {
  className?: string;
  id?: string;
  children?: React.ReactNode;
}

const Card: FunctionComponent<CardProps> = ({ children, className, id }) => {
  const defaultClasses = 'card apl-my-s';
  const classes = className ? `${className} ${defaultClasses}` : `${defaultClasses}`;

  return (
    <div id={id ? id : `flux-card-${Math.floor(Math.random() * 100)}`} className={classes}>
      {children}
    </div>
  );
};

export default Card;
