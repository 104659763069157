import React from 'react';
import ChangeState from './ChangeState';
import { useUpdatePlanStateV2 } from './planApi';
import AuthorizedAction from '../common/AuthorizedAction';
import ContextMenuButton from '../common/ContextMenuButton';
import LoadingSpinner from '../common/LoadingSpinner';
import { Permission } from '../../auth/getPermissions';
import { useStore } from '../../store';
import { useNavigate } from 'react-router-dom';
import { isProd } from 'environment';
import { Plan, PlanState, PlanUsage } from 'api/openapi/rating-config';

interface PlanActionsBarProps {
  editId?: string;
  editTestId?: string;
  hideAddCharge?: boolean;
  onAddCharge?: () => void;
  onEditPlan: (open: boolean) => void;
  plan: Plan | undefined;
  usage: PlanUsage | undefined;
}

const PlanActionsBar = ({
  editId,
  editTestId,
  hideAddCharge,
  onAddCharge,
  onEditPlan,
  plan,
  usage,
}: PlanActionsBarProps) => {
  const navigate = useNavigate();

  const { mutateAsync, isError, isLoading } = useUpdatePlanStateV2();

  const handleArchivePlan = async () => {
    const confirm = window.confirm('Are you sure you want to archive this plan?');

    if (confirm) {
      const result = await mutateAsync({
        planId: plan?.id || '',
        planState: { planState: PlanState.ARCHIVED },
      });

      if (result && !isError) {
        useStore.getState().addNotification({
          title: 'Plan archived.',
          color: 'success',
          id: 'planArchived',
        });
      }
    }
  };

  const handleRestorePlan = async () => {
    const confirm = window.confirm(
      'Are you sure you want to restore this plan? The plan will restore in a draft state.'
    );

    if (confirm) {
      const result = await mutateAsync({
        planId: plan?.id || '',
        planState: { planState: PlanState.DRAFT },
      });

      if (result && !isError) {
        useStore.getState().addNotification({
          title: 'Plan restored to draft state.',
          color: 'success',
          id: 'planSetToDraft',
        });
      }
    }
  };

  return (
    <>
      {plan?.planState !== PlanState.ARCHIVED && (
        <>
          <ContextMenuButton testIdSuffix={'plan-actions'}>
            <>
              <div className="context-menu__block" style={{ minWidth: '200px' }}>
                <AuthorizedAction
                  isDisabled={!usage || (usage && usage.usage && usage.usage.length > 0)}
                  extraClasses="context-menu__button"
                  onClick={handleArchivePlan}
                  permission={Permission.PLAN_ARCHIVE}
                  removeDefaultClass={true}
                  removeDefaultWeight={true}
                  title={
                    usage?.usage?.length && usage.usage.length > 0
                      ? 'Sorry, this plan is currently in use'
                      : 'Sorry, you do not have permission'
                  }
                >
                  Archive plan {isLoading && <LoadingSpinner />}
                </AuthorizedAction>
              </div>
              {!isProd() && (
                <div className="context-menu__block" style={{ minWidth: '200px' }}>
                  <AuthorizedAction
                    extraClasses="context-menu__button"
                    onClick={() => {
                      navigate(`/plans/${plan?.id}/calendar`);
                    }}
                    permission={Permission.PLAN_VIEW}
                    removeDefaultClass={true}
                    removeDefaultWeight={true}
                  >
                    View calendar
                  </AuthorizedAction>
                </div>
              )}
            </>
          </ContextMenuButton>
          <AuthorizedAction
            id={editId}
            extraClasses="apl-ml-s"
            isDisabled={plan?.planState === PlanState.DEPRECATED}
            onClick={() => onEditPlan(true)}
            permission={Permission.PLAN_EDIT}
            testId={editTestId ? editTestId : 'edit-plan-button'}
            title={
              plan?.planState === PlanState.DEPRECATED
                ? 'Sorry, this plan is deprecated.'
                : undefined
            }
          >
            Edit plan
          </AuthorizedAction>
          {!hideAddCharge && onAddCharge && (
            <AuthorizedAction
              extraClasses="apl-ml-s"
              onClick={onAddCharge}
              permission={Permission.PLAN_EDIT}
              testId="add-simple-plan-charge"
            >
              Add a charge
            </AuthorizedAction>
          )}
          <ChangeState plan={plan} planId={plan?.id || ''} />
        </>
      )}
      {plan?.planState === 'ARCHIVED' && (
        <AuthorizedAction
          extraClasses="is-primary"
          onClick={handleRestorePlan}
          permission={Permission.PLAN_ARCHIVE}
        >
          Restore plan {isLoading && <LoadingSpinner />}
        </AuthorizedAction>
      )}
    </>
  );
};

export default PlanActionsBar;
