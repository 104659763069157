import cx from 'classnames';
import { addYears, format } from 'date-fns';
import React, { useState } from 'react';
import Modal from 'react-modal';
import { Link, useNavigate } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';
import {
  Connection,
  SupplyPeriodDetails,
  SupplyPeriodPlanSummary,
  SupplyPeriodSummary,
  useDeletePlanLink,
  useSupplyPeriodDetails,
  useSupplyPeriodDetailsV3,
} from '../connectionsApi';
import AddConnectionSpecificPlan from '../supply-periods/plan-links/AddConnectionSpecificPlan';
import AddExistingPlan from '../supply-periods/plan-links/AddExistingPlan';
import UpdatePlanLink from '../supply-periods/plan-links/UpdatePlanLink';
import AuthorizedAction from '../../common/AuthorizedAction';
import ContextMenuButton, { ContextMenuButtonRenderProps } from '../../common/ContextMenuButton';
import { Widget } from '../../dashboard/Dashboard';
import { modalStyles } from '../../layout/Modal';
import { Permission } from '../../../auth/getPermissions';
import { Form, Formik } from 'formik';

import './PlanLinksComponent.scss';
import getConfig from 'config/getConfig';
import {
  OfferComparisons,
  useOfferComparisons,
  useTriggerRating,
} from 'components/rating-calculator/ratingsApi';
import {
  formatDateForDisplay,
  marketStartOfMonth,
  marketTimezoneAddMonths,
  newMarketDate,
} from 'util/helper-func';
import { useCurrencyTranslation } from 'hooks';
import AutoProvisioningConfirmation from '../supply-periods/plan-links/AutoProvisioningConfirmation';
import { EuiCheckbox, EuiConfirmModal } from '@elastic/eui';
import { useStore } from 'store';
import { useConfig } from '../../../config/ConfigContext';
import { filterSupplyPeriodPlanSummaries, sortSupplyPeriodPlanSummaries } from './PlanLinksEntry';
import { useListData } from '../../../list-data/ListDataContext';
import { getMarketFunction } from '../../../list-data/getMarketFunction';
import { useUsageDetailsV2 } from 'components/telemetry/telemetryUsageApi';
import { addDaysInZone } from '@developers/flux-date-util';

type ModalType = 'add' | 'update' | 'specific' | 'delete' | 'autoProvisioning';

interface PlanLinksComponentProps {
  connection: Connection;
  supplyPeriod?: SupplyPeriodSummary;
  contractedPartyId?: string;
}

const PlanLinksComponent = ({
  connection,
  supplyPeriod,
  contractedPartyId,
}: PlanLinksComponentProps) => {
  const [marketFunctionFlags] = useListData(['MARKET_FUNCTION']);

  const getTimezone = (): string | undefined => {
    const tz = getMarketFunction(
      'urn:flux:rating-config:market:timezone',
      undefined,
      marketFunctionFlags
    );
    if (!tz || typeof tz === 'boolean') {
      return undefined;
    }
    return String(tz);
  };

  const timezone = getTimezone();

  // the modal needs additional height to display the dropdown properly
  const addModalStyle = {
    ...modalStyles,
    content: {
      ...modalStyles.content,
    },
  };
  const { bestOfferProviderIds, shareablePlans } = getConfig();
  const bestOfferProviderIdsArray = bestOfferProviderIds.split(',').map((id) => id.trim());

  // feature flag for this
  const {
    oci1805BestOfferButton, // LD client key is oci-1805-best-offer-button
    apex234Apex76PlanLinkSoftDelete, // LD client key is apex-234-apex-76-plan-link-soft-delete
  } = useFlags();

  const { nextMonth, nextYear } = getComparisonPeriod();
  const { data: telemetryData, isInitialLoading: telemetryDataLoading } = useUsageDetailsV2({
    connectionId: connection.id,
    startsAt: addDaysInZone(nextYear, -1, timezone ?? '').toISOString(),
    endsAt: nextYear.toISOString(),
    getForecasted: true,
  });

  const isCreateConnectionSpecificPricingMenuVisible = (plan: SupplyPeriodPlanSummary): boolean => {
    if (shareablePlans) {
      return !(plan.planScope == 'CONNECTION_SPECIFIC' && plan.shareable === false);
    } else {
      return plan.planScope != 'CONNECTION_SPECIFIC';
    }
  };

  const planNav = (plan: SupplyPeriodPlanSummary, index: number) => {
    const dateFormat = 'd MMM yyyy';

    if (isPlanNotAvailable(plan)) {
      return;
    }

    const fromDate = new Date(plan.startDate);
    const from = format(fromDate, dateFormat);

    const toDate = plan.endDate ? new Date(plan.endDate) : '';
    const to = toDate ? format(toDate, dateFormat) : '';

    return (
      <div className="widget__list-item" key={plan.id}>
        <div className="plan-link apl-display-flex apl-flex-column">
          <Link
            className="plan-link__info plan-link__link apl-color-primary"
            to={`/connections/${connection.id}/plan/${
              plan.pricingPlanId ?? plan.planId
            }?${planNavParams.toString()}`}
          >
            {plan.planName}
          </Link>
          <p className="plan-link__info">
            {plan.providerName}, {`${from} - ${to}`}
          </p>
          <p
            className={cx(
              'plan-link__info',
              'plan-link__state',
              `plan-link__state--${plan.planState.toLowerCase()}`,
              'apl-display-flex',
              'apl-flex-row',
              'apl-align-items-center'
            )}
          >
            {plan.planState.substr(0, 1) + plan.planState.substring(1).toLowerCase()}
          </p>
        </div>
        <ContextMenuButton align="right" testIdSuffix={`plan-link-${index + 1}`} width="250px">
          {({ closeMenu }: ContextMenuButtonRenderProps) => (
            <>
              <div className="context-menu__block">
                <AuthorizedAction
                  extraClasses="context-menu__button"
                  onClick={() => {
                    closeMenu();
                    setModalType('update');
                    setEditItem(plan);
                    setModalOpen(true);
                  }}
                  permission={Permission.CONNECTION_EDIT}
                  removeDefaultClass={true}
                  removeDefaultWeight={true}
                >
                  Edit plan link
                </AuthorizedAction>
              </div>
              <div className={cx('context-menu__block', 'context-menu__block--small')}>
                <AuthorizedAction
                  extraClasses="context-menu__button"
                  isDisabled={mutateLoading}
                  onClick={() => {
                    if (plan.providerName === 'Metric Provider') {
                      setModalType('delete');
                      setEditItem(plan);
                      setModalOpen(true);
                    } else {
                      handleDelete(plan.id);
                    }
                  }}
                  permission={Permission.CONNECTION_EDIT}
                  removeDefaultClass={true}
                  removeDefaultWeight={true}
                >
                  Remove plan link from supply period
                </AuthorizedAction>
                {isCreateConnectionSpecificPricingMenuVisible(plan) && (
                  <AuthorizedAction
                    extraClasses="context-menu__button"
                    onClick={() => {
                      setModalType('specific');
                      setEditItem(plan);
                      setModalOpen(true);
                    }}
                    permission={Permission.CONNECTION_EDIT}
                    removeDefaultClass={true}
                    removeDefaultWeight={true}
                  >
                    Create connection specific pricing
                  </AuthorizedAction>
                )}
              </div>
            </>
          )}
        </ContextMenuButton>
      </div>
    );
  };

  const isPlanNotAvailable = (plan: SupplyPeriodPlanSummary) => {
    return !plan || (plan.planName === null && plan.planState === null && plan.planScope === null);
  };

  const [modalOpen, setModalOpen] = useState(false);
  const [bestOfferModal, setBestOfferModal] = useState(false);
  const [modalType, setModalType] = useState<ModalType>();
  const [editItem, setEditItem] = useState<SupplyPeriodPlanSummary>();
  const { lobi796ShowPlanLinkPurposeOptions } = useFlags();

  const {
    data,
    isInitialLoading: isLoading,
    isError,
  }: {
    data: SupplyPeriodDetails | undefined;
    isInitialLoading: boolean;
    isError: boolean;
  } = lobi796ShowPlanLinkPurposeOptions
    ? useSupplyPeriodDetailsV3(supplyPeriod?.id, {
        enabled: !!connection?.id && !!supplyPeriod?.id,
      })
    : useSupplyPeriodDetails(connection.id, supplyPeriod?.id, {
        enabled: !!connection?.id && !!supplyPeriod?.id,
      });
  const { translateCurrency } = useCurrencyTranslation();

  const { data: offerComparisons }: { data: OfferComparisons[] | undefined } = useOfferComparisons(
    connection.id,
    marketTimezoneAddMonths(marketStartOfMonth(), 1).toISOString(),
    addYears(marketTimezoneAddMonths(marketStartOfMonth(), 1), 1).toISOString(),
    true
  );

  const { mutateAsync: triggerRating } = useTriggerRating();

  const { mutateAsync, isLoading: mutateLoading } = useDeletePlanLink(
    connection.id,
    supplyPeriod?.id ?? ''
  );

  const config = useConfig();
  const showAutoProvisioning =
    config.showUiElements && /(^|, ?)autoProvisioning($|,)/.test(config.showUiElements);

  // create UML params for back navigation
  const planNavParams = new URLSearchParams();
  if (supplyPeriod) {
    planNavParams.set('spid', supplyPeriod.id);
  }
  if (contractedPartyId) {
    planNavParams.set('cpid', contractedPartyId);
  }

  const closeModal = () => {
    setEditItem(undefined);
    setModalOpen(false);
    setModalType(undefined);
  };

  const handleDelete = async (planLinkId: string) => {
    await mutateAsync({ planLinkId });
  };

  const handleAutoProvisionChange = async () => {
    setModalType('autoProvisioning');
    setModalOpen(true);
  };

  function renderPlanLinks(
    data: SupplyPeriodPlanSummary[],
    comparativePlans: boolean,
    bestOfferProviderIds: string
  ) {
    let filteredPlans = filterSupplyPeriodPlanSummaries(
      data,
      comparativePlans,
      bestOfferProviderIds
    );
    // Temorary dual system, this will be replaced by the purpose check eventually
    const comparativePurposePlans = lobi796ShowPlanLinkPurposeOptions
      ? data.filter((plan) => plan.purpose == 'urn:flux:plan-link-purpose:comparative')
      : [];

    filteredPlans = filteredPlans.filter((plan) => !comparativePurposePlans.includes(plan));
    if (comparativePlans) {
      filteredPlans = filteredPlans.concat(comparativePurposePlans);
    }

    filteredPlans = sortSupplyPeriodPlanSummaries(filteredPlans);

    return filteredPlans.map(planNav);
  }

  const navigate = useNavigate();
  const connectionId = connection.id;
  const supplyPeriodId = supplyPeriod?.id;
  const supplyPeriodStartDate = supplyPeriod?.startDate;
  const supplyAgreementStatus = supplyPeriod?.status;
  const contractedParty = supplyPeriod?.contractedParty;

  const handleNavigate = () => {
    navigate(`/plan-link-history/${supplyPeriodId}`, {
      state: { connectionId, supplyPeriodStartDate, supplyAgreementStatus, contractedParty },
    });
  };

  return (
    <>
      {bestOfferModal && (
        <EuiConfirmModal
          style={{ width: 600 }}
          title="Please ensure plans configuration is correct "
          onCancel={() => setBestOfferModal(false)}
          onConfirm={async () => {
            await triggerRating({
              scopedTo: connection.id,
              startAt: nextMonth.toISOString(),
              endAt: nextYear.toISOString(),
              purpose: 'urn:flux:rating:purpose:comparative',
            });
            useStore.getState().addNotification({
              color: 'success',
              title: 'New Comparative Rating Requested',
              id: 'ComparativeRatingRequested',
            });
            setBestOfferModal(false);
          }}
          cancelButtonText="Cancel"
          confirmButtonText="Calculate Best Offer"
          defaultFocusedButton="confirm"
        >
          <p>
            Please ensure that the billable pricing plans are configured correctly and that all
            eligible comparative plans are linked before proceeding with manually calculating the
            best offer
          </p>
        </EuiConfirmModal>
      )}
      <Widget
        headerComponent={
          <>
            <h3 className="apl-h3 widget__title widget__title--inline apl-mb-none">
              Plans
              {apex234Apex76PlanLinkSoftDelete && (
                <AuthorizedAction
                  extraClasses="apl-ml-l"
                  onClick={() => handleNavigate()}
                  permission={Permission.CONNECTION_EDIT}
                >
                  Plan link history
                </AuthorizedAction>
              )}
              <AuthorizedAction
                extraClasses="apl-ml-l"
                onClick={() => {
                  setModalType('add');
                  setModalOpen(true);
                }}
                permission={Permission.CONNECTION_EDIT}
              >
                Add plan link
              </AuthorizedAction>
            </h3>
            {showAutoProvisioning && (
              <div className="apl-pl apl-pt-s">
                <EuiCheckbox
                  id={'autoProvisionCheckBox'}
                  label="Auto-Provisioning"
                  checked={connection.autoProvisioning === true}
                  onChange={handleAutoProvisionChange}
                />
              </div>
            )}
          </>
        }
        width="full"
      >
        {isLoading && <p>Loading...</p>}
        {isError && <p>Sorry, there was an error.</p>}
        {data && data.plans && (
          <div className="widget__list" data-testid="plan-links-list">
            {renderPlanLinks(data.plans, false, bestOfferProviderIds)}
          </div>
        )}
      </Widget>
      {data &&
        data.plans &&
        data.plans.find(
          (plan: SupplyPeriodPlanSummary) =>
            bestOfferProviderIdsArray.includes(plan.providerId) ||
            plan.purpose == 'urn:flux:plan-link-purpose:comparative'
        ) && (
          <Widget
            className="apl-mt-none"
            headerComponent={
              <h3 className="apl-h3 widget__title widget__title--inline apl-mb-none">
                Comparative Plans
                {oci1805BestOfferButton && (
                  <AuthorizedAction
                    extraClasses="apl-ml-l"
                    title="Best offer calculation is not available because usage forecasting is not completed."
                    onClick={() => setBestOfferModal(true)}
                    permission={Permission.CONNECTION_EDIT}
                    isDisabled={telemetryDataLoading || telemetryData.items.length == 0}
                  >
                    Calculate Best Offer
                  </AuthorizedAction>
                )}
              </h3>
            }
            width="full"
          >
            {isLoading && <p>Loading... </p>}
            {isError && <p>Sorry, there was an error.</p>}
            {offerComparisons && offerComparisons.length > 0 && offerComparisons[0] != null && (
              <div className="widget__list-item" key={'BestOffer'}>
                <div className="plan-link apl-display-flex apl-flex-column">
                  Best Offer Result (as of {formatDateForDisplay(offerComparisons[0].requestedAt)})
                </div>
                <div className="plan-link apl-display-flex apl-flex-column">
                  <span className="badge apl-ml-s">
                    {offerComparisons[0].isCurrentBest
                      ? 'On Best Offer'
                      : offerComparisons[0].best.planName +
                        ' (Savings of ' +
                        translateCurrency(offerComparisons[0].savings) +
                        ')'}
                  </span>
                </div>
              </div>
            )}
            {data && data.plans && (
              <div className="widget__list" data-testid="plan-links-list">
                {renderPlanLinks(data.plans, true, bestOfferProviderIds)}
              </div>
            )}
          </Widget>
        )}
      <Modal
        isOpen={modalOpen}
        onRequestClose={closeModal}
        shouldCloseOnOverlayClick={true}
        style={
          modalType === 'delete' || modalType === 'autoProvisioning' ? modalStyles : addModalStyle
        }
      >
        {modalType === 'update' && editItem && supplyPeriod && (
          <UpdatePlanLink
            close={closeModal}
            connectionId={connection.id}
            planLink={editItem}
            supplyPeriodEndDate={supplyPeriod.endDate}
            supplyPeriodId={supplyPeriod.id}
            supplyPeriodStartDate={supplyPeriod.startDate}
          />
        )}
        {modalType === 'add' && !editItem && supplyPeriod && (
          <AddExistingPlan
            close={closeModal}
            connectionId={connection.id}
            supplyPeriodEndDate={supplyPeriod.endDate}
            supplyPeriodId={supplyPeriod.id}
            supplyPeriodStartDate={supplyPeriod.startDate}
            supplyPeriodDetails={data as SupplyPeriodDetails}
          />
        )}
        {modalType === 'specific' && editItem && supplyPeriod && (
          <AddConnectionSpecificPlan
            connectionId={connection.id}
            planLink={editItem}
            supplyPeriodId={supplyPeriod.id}
            timezone={timezone}
            close={closeModal}
          />
        )}
        {modalType === 'autoProvisioning' && (
          <AutoProvisioningConfirmation connection={connection} close={closeModal} />
        )}
        {modalType === 'delete' && editItem && supplyPeriod && (
          <Formik
            initialValues={{}}
            onSubmit={() => {
              handleDelete(editItem.id);
              closeModal();
            }}
          >
            {() => (
              <Form className="apl-form-layout-v1">
                <div>Are you sure you want to delete this plan link?</div>
                <div>
                  Removing this plan will prevent carbon emissions from being calculated or shown on
                  invoices for this connection.
                </div>
                <div className="apl-display-flex apl-justify-content-end">
                  <button className="apl-button-v1" onClick={closeModal} type={'button'}>
                    Cancel
                  </button>
                  <AuthorizedAction
                    extraClasses="is-primary"
                    permission={Permission.CONNECTION_EDIT}
                    removeDefaultWeight={true}
                    type="submit"
                  >
                    Confirm
                  </AuthorizedAction>
                </div>
              </Form>
            )}
          </Formik>
        )}
      </Modal>
    </>
  );
};

export default PlanLinksComponent;

function getComparisonPeriod() {
  const { bestOfferChangeoverDay } = getConfig();
  const changeOverDay: number | null = getDayOfMonthFromString(bestOfferChangeoverDay);
  // No change-over day configured. Comparison period starts from next month.

  if (changeOverDay == null) {
    return fromMonthForOneYear(1);
  }

  const time = newMarketDate();
  const dayOfMonth = time.getDate();
  const lengthOfMonthInDays = new Date(time.getFullYear(), time.getMonth() + 1, 0).getDate();

  if (changeOverDay <= 0) {
    // Change-over day is the last day of the month or days before the last day of the month.
    if (dayOfMonth < lengthOfMonthInDays + changeOverDay) {
      return fromMonthForOneYear(1);
    }
  } else {
    // Change-over day is a specific day of the month. Use next month if given instant is before the change-over day.
    if (dayOfMonth < changeOverDay) {
      return fromMonthForOneYear(1);
    }
  }
  // The instant is on or after the change-over day, or it is the last day of the month. The comparison period starts
  // the month after the next month.
  return fromMonthForOneYear(2);
}

function fromMonthForOneYear(startMonth: number) {
  const nextMonth = marketTimezoneAddMonths(marketStartOfMonth(), startMonth);
  const nextYear = addYears(nextMonth, 1);
  return { nextMonth, nextYear };
}

function getDayOfMonthFromString(input: string) {
  if (input == '') {
    console.error("Invalid day. Valid values are 1 to 28 or 'last' or 'last-1' to 'last-27'.");
  }

  let inputDay: number;

  if (input.toLowerCase().startsWith('last')) {
    // value starts with last. Parse the number if there is one.
    const minusDays = input.substring(4);
    if (minusDays == '') {
      // It's the last day.
      inputDay = 0;
    } else {
      // Parse the negative number.
      try {
        inputDay = Number.parseInt(minusDays);
      } catch (e) {
        console.error("Invalid day. Valid values are 1 to 28 or 'last' or 'last-1' to 'last-27'.");
        return null;
      }
      if (inputDay < -27 || inputDay > -1) {
        console.error("Invalid day. Valid values are 1 to 28 or 'last' or 'last-1' to 'last-27'.");
        return null;
      }
    }
  } else {
    // day is a specific day of the month.
    try {
      inputDay = Number.parseInt(input);
    } catch (e) {
      console.error("Invalid day. Valid values are 1 to 28 or 'last' or 'last-1' to 'last-27'.");
      return null;
    }

    if (inputDay < 1 || inputDay > 28) {
      console.error("Invalid day. Valid values are 1 to 28 or 'last' or 'last-1' to 'last-27'.");
      return null;
    }
  }

  return inputDay;
}
