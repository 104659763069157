import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import apiClient from '../../api/apiClient';

interface Provider {
  id: string;
  name: string;
}

export interface ProvisioningExclusionSummary {
  id: string;
  provider: Provider;
  providerReference: string;
}

const provisioningBaseEndpoint = '/provisioning';

export const useProvisioningExclusions = () => {
  return useQuery(['provisioning exclusions'], async () => {
    return apiClient().get(`${provisioningBaseEndpoint}/exclusions`);
  });
};

export interface CreateProvisioningExclusionRequest {
  providerReference: string;
  providerId?: string;
}

interface AddProvisioningExclusionArgs {
  data: CreateProvisioningExclusionRequest;
}

export const useAddProvisioningExclusion = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ data }: AddProvisioningExclusionArgs) => {
      return apiClient().post(`${provisioningBaseEndpoint}/exclusions`, JSON.stringify(data));
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['provisioning exclusions']);
      },
    }
  );
};

interface DeleteProvisioningExclusionArgs {
  exclusionId: string;
}

export const useDeleteProvisioningExclusion = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ exclusionId }: DeleteProvisioningExclusionArgs) => {
      return apiClient().del(`${provisioningBaseEndpoint}/exclusions/${exclusionId}`, '');
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['provisioning exclusions']);
      },
    }
  );
};
