import { useState } from 'react';
import { useAllLossFactorCodes } from '../components/telemetry/lossFactorApi';
import { format } from 'date-fns';
import {
  LOSS_FACTOR_CODE_URN_BASE,
  lossFactorKeyUrn,
} from '../components/providers/LossFactorCodes';
import { LossFactorPageResponse } from '../api/openapi/loss-factor-manager';

const dateFormat = 'dd/MM/yyyy';

// ordered array of headers used by the CSV file
export const csvHeaders = [
  'Loss code',
  'Jurisdiction',
  'Consumption loss factor',
  'Generation loss factor',
  'Start date',
  'End date',
  'Start period',
  'End period',
  'Last updated',
  'Registry creation date',
];

/**
 * Hook that returns a function to query the loss factor API to get all loss factors and maps this to a CSV download.
 *
 * @returns {downloadAllLossFactorCsvHandler} download CSV function
 * @returns {isCsvLoading} boolean whether the function completed or is still in progress
 */
export default function downloadAllLossFactorCsvHook() {
  const [isCsvLoading, setIsCsvLoading] = useState(false);

  async function downloadAllLossFactorCsvHandler() {
    try {
      setIsCsvLoading(true);

      // query API to get ALL loss factors
      const apiResponse = await useAllLossFactorCodes();

      const csvData = mapApiData(apiResponse, true, false); // AU loss factors so isProviderSet = false
      // extract fields and format to csv
      if (csvData == null) {
        return;
      }
      let csvResult = csvData
        .map(
          (r: any) =>
            `"${r.rowData[0]}", "${r.rowData[1]}", "${r.rowData[2]}", "${r.rowData[3]}", "${r.rowData[4]}", "${r.rowData[5]}", "${r.rowData[6]}", "${r.rowData[7]}", "${r.rowData[8]}", "${r.rowData[9]}"`
        )
        .join('\n');
      // prepend header
      csvResult =
        `data:text/csv;charset=utf-8, "${csvHeaders[0]}", "${csvHeaders[1]}", "${csvHeaders[2]}", "${csvHeaders[3]}", "${csvHeaders[4]}", "${csvHeaders[5]}", "${csvHeaders[6]}", "${csvHeaders[7]}", "${csvHeaders[8]}", "${csvHeaders[9]}"\n` +
        csvResult;

      const filename = `loss_factors.csv`;
      csvResult = encodeURI(csvResult).replaceAll('#', '%23');
      const link = document.createElement('a');
      link.setAttribute('href', csvResult);
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (err: any) {
      console.error(`Error downloading loss factor csv data: ${err.message}`);
    } finally {
      setIsCsvLoading(false);
    }
  }

  return { downloadAllLossFactorCsvHandler, isCsvLoading };
}

// map api response data to data used by CSV
export function mapApiData(data: any, sortAsc: boolean, isProviderSet: boolean) {
  const dataItems = data == null ? null : (data as LossFactorPageResponse).items;
  return dataItems == null
    ? null
    : dataItems
        .sort((a, b) => {
          const fullCodeA =
            a.externalIdentifiers.find((ext) => ext.includes(LOSS_FACTOR_CODE_URN_BASE)) ?? '';

          const fullCodeB =
            b.externalIdentifiers.find((ext) => ext.includes(LOSS_FACTOR_CODE_URN_BASE)) ?? '';

          if (fullCodeA < fullCodeB) {
            return sortAsc ? -1 : 1;
          } else if (fullCodeA > fullCodeB) {
            return sortAsc ? 1 : -1;
          }

          return 0;
        })
        .map((item, index) => {
          const {
            id,
            validFrom,
            validTo,
            startsAt,
            endsAt,
            externalIdentifiers,
            energyGoingToConnectionMultiplier,
            energyComingFromConnectionMultiplier,
            uploadedAt,
            lastUpdatedInSource,
          } = item;

          const fullCode = externalIdentifiers.find((ext: any) =>
            ext.includes(LOSS_FACTOR_CODE_URN_BASE)
          );

          const code = fullCode ? fullCode.split(':').pop() : '';

          const fullJurisdiction = isProviderSet
            ? undefined
            : externalIdentifiers.find((ext: any) => ext.includes(lossFactorKeyUrn));

          const jurisdiction = isProviderSet
            ? undefined
            : fullJurisdiction
            ? fullJurisdiction.split(':').pop()
            : '';

          const startDate = validFrom ? format(new Date(validFrom), dateFormat) : '';

          const endDate = validTo ? format(new Date(validTo), dateFormat) : '';

          const uploadedAtDate = uploadedAt
            ? format(new Date(uploadedAt), 'dd-MM-yyyy HH:mm:ss')
            : '';

          // row data in order that they are to be displayed
          const rowData: Array<any> = [
            code,
            jurisdiction,
            energyGoingToConnectionMultiplier,
            energyComingFromConnectionMultiplier,
            startDate,
            endDate,
            startsAt,
            endsAt,
            uploadedAtDate,
            lastUpdatedInSource,
          ];
          return {
            id: id,
            index: index,
            rowData: rowData,
          };
        });
}
