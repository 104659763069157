import { endOfMonth, format, formatISO, startOfMonth, subMonths } from 'date-fns';
import { marketTimezoneAddMonths } from 'util/helper-func';

export interface DateData {
  monthShort: string;
  month: string;
  firstDOM: string;
  lastDOM: string;
}

type RepresentationType = 'complete' | 'date' | 'time' | undefined;

export const generateDates = (): DateData[] => {
  const dates = [];
  const today = new Date();
  const previousMonths = 1;
  const nextMonths = 3;

  // add previous months
  for (let diffMonths = previousMonths; diffMonths >= 1; diffMonths--) {
    dates.push(extractMonthData(subMonths(today, diffMonths), 'complete'));
  }

  // add current month
  dates.push(extractMonthData(today, 'complete'));

  // add future months
  for (let diffMonths = 1; diffMonths <= nextMonths; diffMonths++) {
    dates.push(extractMonthData(marketTimezoneAddMonths(today, diffMonths), 'complete'));
  }

  return dates;
};

const extractMonthData = (date: Date, representation: RepresentationType) => ({
  monthShort: format(date, 'MMM').toLowerCase(),
  month: format(date, 'MMMM'),
  firstDOM: formatISO(startOfMonth(date), { representation }),
  lastDOM: formatISO(endOfMonth(date), { representation }),
});
