import { isEmpty } from 'ramda';
import React, {
  createContext,
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from 'react';
import getConfig, { Config } from './getConfig';
import apiClient from '../api/apiClient';
import authClient from '../auth/authClient';

const ConfigContext = createContext<Config>({} as Config);

ConfigContext.displayName = 'ConfigContext';

interface ConfigContextProviderProps {
  children?: React.ReactNode;
}

const ConfigContextProvider: FunctionComponent<ConfigContextProviderProps> = ({ children }) => {
  const [config, setConfig] = useState<Config>({} as Config);

  useEffect(() => {
    setConfig(getConfig());
  }, []);

  if (isEmpty(config)) {
    return null;
  }

  // instantiate config for auth and api clients
  authClient.setConfig(config);
  apiClient().setConfig(config);

  return (
    <ConfigContext.Provider value={config}>{children}</ConfigContext.Provider>
  );
};

const useConfig = () => {
  const context = useContext<Config>(ConfigContext);

  if (context === undefined) {
    throw Error('auth context must be used with auth provider');
  }

  return context;
};

export { useConfig, ConfigContextProvider };
