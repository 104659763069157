/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { HoldStatus } from './HoldStatus';

export type BaseAccountConfiguration = {
    billingParty: string;
    startDate: string;
    endDate?: string;
    paymentTerms: BaseAccountConfiguration.paymentTerms;
    holdStatus?: HoldStatus;
};

export namespace BaseAccountConfiguration {

    export enum paymentTerms {
        URN_FLUX_BILLING_PAYMENT_TERM_20TH_OF_MONTH = 'urn:flux:billing:payment_term:20th_of_month',
        URN_FLUX_BILLING_PAYMENT_TERM_14_DAYS_AFTER_INVOICE = 'urn:flux:billing:payment_term:14_days_after_invoice',
        URN_FLUX_BILLING_PAYMENT_TERM_20TH_OF_FOLLOWING_MONTH = 'urn:flux:billing:payment_term:20th_of_following_month',
    }


}

