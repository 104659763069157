/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { RangeToken } from './RangeToken';

export type Range = (RangeToken & {
    type: Range.type;
    from?: string;
    to?: string;
});

export namespace Range {

    export enum type {
        DATE_TIME_RANGE = 'DATE_TIME_RANGE',
        DATE_RANGE = 'DATE_RANGE',
        MONTH_RANGE = 'MONTH_RANGE',
        MONTH_DAY_RANGE = 'MONTH_DAY_RANGE',
        DAY_OF_WEEK_RANGE = 'DAY_OF_WEEK_RANGE',
        TIME_RANGE = 'TIME_RANGE',
        NON_WORKING_DAY_RANGE = 'NON_WORKING_DAY_RANGE',
        WORKING_DAY_RANGE = 'WORKING_DAY_RANGE',
    }


}

