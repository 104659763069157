/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { Measurement } from './Measurement';

export type SummaryAggregated = {
    /**
     * measurement point
     */
    externalIdentifier?: string;
    /**
     * Whether the register measures energy flowing into the connection or out of the connection
     */
    flowDirection?: SummaryAggregated.flowDirection;
    /**
     * This property value must be in UTC
     */
    periodStart?: string;
    /**
     * This property value must be in UTC
     */
    periodEnd?: string;
    /**
     * summary total usage by period and unit
     */
    usage?: Array<Measurement>;
    /**
     * summary of total loss by period and unit
     */
    lossAdjustedUsage?: Array<Measurement>;
    readingQuality?: string;
};

export namespace SummaryAggregated {

    /**
     * Whether the register measures energy flowing into the connection or out of the connection
     */
    export enum flowDirection {
        URN_FLUX_NETWORK_CONNECTION_FLOW_DIRECTION_INTO_CONNECTION = 'urn:flux:network:connection:flow-direction:into-connection',
        URN_FLUX_NETWORK_CONNECTION_FLOW_DIRECTION_OUT_OF_CONNECTION = 'urn:flux:network:connection:flow-direction:out-of-connection',
    }


}

