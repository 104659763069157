import { useTranslation } from 'react-i18next';

/**
 * Hook that builds on the i18n translation hook useTranslation
 * to return the connection acronym.
 *
 * @returns {string} translated connection acronym
 */
export default function useConnectionAcronymTranslation(): string {
  const { t: translate } = useTranslation();

  return translate('connectionAcronym');
}
