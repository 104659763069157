import React from 'react';
import { useCurrencyTranslation } from 'hooks';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Link, useParams } from 'react-router-dom';
import { LineItem, Usage, UsageV1 } from '../../billing/billingInvoicesApi';
import { usePlanWithDeletedChargeV2 } from '../../plans/planApi';
import { PlanTableUsageField } from './PlanTableUsageField';
import { PlanTableUsageFieldFbau476 } from './PlanTableUsageFieldFbau476';
import { PlanTableUsageFieldFbau1002 } from './PlanTableUsageFieldFbau1002';
import { Charge, Plan } from '../../../api/openapi/rating-config';

type PlanTableProps = {
  index: number;
  lineItems: LineItem[];
  planId: string;
  spid?: string;
  cpid?: string;
};

type PlanTableRowProps = LineItem & {
  charge?: Charge;
  isEnergyPlan: boolean;
  rowIndex: string;
  tableIndex: number;
  spid?: string;
  cpid?: string;
};

const energyPlans = ['EASI_144', 'EASI_48'];
const isEnergyPlan = (structure: any) => {
  return energyPlans.includes(structure);
};

const PlanTableRow = (props: PlanTableRowProps) => {
  const {
    amount,
    charge,
    chargeTags,
    isEnergyPlan,
    planId,
    rate,
    rowIndex,
    subGroupingReference,
    summarisationGroup,
    tableIndex,
    usage,
    spid,
    cpid,
  } = props;

  const {
    fbau476NewLossFactorLabels,
    fbau1002AdjustmentsFromBillingApiUsage,
    nz1683AddCostToUi, // LD Client Key is nz-1683-add-cost-to-ui
  } = useFlags();

  const { translateCurrency } = useCurrencyTranslation();
  const { id } = useParams<{ id: string }>();

  // precision for columns with decimals, excl. usage
  const ratePrecision = 4;

  let toLink = `/connections/${id}/plan/${planId}`;
  const planRowParams = new URLSearchParams();
  if (spid) {
    planRowParams.set('spid', spid);
  }
  if (cpid) {
    planRowParams.set('cpid', cpid);
  }
  toLink = `/connections/${id}/plan/${planId}?${planRowParams.toString()}`;
  /**
   * PROB-1834.
   */
  return (
    <tr>
      <td>
        {charge?.isDeleted ? (
          charge?.chargeName ? (
            charge.chargeName
          ) : // for the easi144 charges that do not have a charge name
          !charge?.chargeName && charge?.chargeType === 'VOLUME' ? (
            'VOLUME'
          ) : (
            '-'
          )
        ) : (
          <Link
            className="apl-color-primary"
            data-testid={`plan-${tableIndex}-charge-link-${rowIndex}`}
            to={toLink}
          >
            {charge?.chargeName
              ? charge.chargeName
              : // for the easi144 charges that do not have a charge name
              !charge?.chargeName && charge?.chargeType === 'VOLUME'
              ? 'VOLUME'
              : '-'}
          </Link>
        )}
      </td>
      <td>
        {charge?.chargeReference
          ? charge.chargeReference
          : charge?.chargeType
          ? charge.chargeType
          : '-'}
      </td>
      {isEnergyPlan && (
        <td>
          {chargeTags && chargeTags.includes('energy:connection:direction:to')
            ? 'in'
            : chargeTags?.includes('energy:connection:direction:from')
            ? 'out'
            : '-'}
        </td>
      )}
      <td data-testid={`expanded-summary--${rowIndex}`}>
        {summarisationGroup ? summarisationGroup : '-'}
      </td>
      <td>{subGroupingReference ? subGroupingReference : '-'}</td>
      <td>
        {fbau1002AdjustmentsFromBillingApiUsage ? (
          <PlanTableUsageFieldFbau1002 usage={usage as Usage} />
        ) : fbau476NewLossFactorLabels ? (
          <PlanTableUsageFieldFbau476 charge={charge} usage={usage as Usage} />
        ) : (
          <PlanTableUsageField usage={usage as UsageV1} />
        )}
      </td>
      <td>
        {rate && rate?.value && rate?.unit
          ? `${rate.value.toFixed(ratePrecision)} ${rate?.unit}`
          : '-'}
      </td>
      <td>{amount?.value ? translateCurrency(amount) : '-'}</td>
      {nz1683AddCostToUi && (
        <>
          <td>{rate && rate?.cost ? `${rate.cost.toFixed(ratePrecision)} ${rate?.unit}` : '-'}</td>
          <td>
            {amount?.cost ? translateCurrency({ value: amount.cost, unit: amount.unit }) : '-'}
          </td>
        </>
      )}
    </tr>
  );
};

/**
 * Sorts an array of LineItems by the summarisation group.
 */
const compareLineItems = (a: LineItem, b: LineItem) => {
  const isSpotA = a.summarisationGroup.startsWith('Spot');
  const isSpotB = b.summarisationGroup.startsWith('Spot');
  if (isSpotA && !isSpotB) {
    return 1;
  } else if (!isSpotA && isSpotB) {
    return -1;
  } else if (isSpotA && isSpotB) {
    //both are spot, sort for Non vs Working within it
    const isNonA = a.summarisationGroup.includes('Non');
    const isNonB = b.summarisationGroup.includes('Non');
    if (isNonA && !isNonB) {
      return 1;
    } else if (!isNonA && isNonB) {
      return -1;
    }
  }

  const isBusinessDayA = a.summarisationGroup.startsWith('Working');
  const isBusinessDayB = b.summarisationGroup.startsWith('Working');
  if (isBusinessDayA && !isBusinessDayB) {
    return -1;
  } else if (!isBusinessDayA && isBusinessDayB) {
    return 1;
  }

  const startTimeA = extractStartTime(a.summarisationGroup);
  const startTimeB = extractStartTime(b.summarisationGroup);
  return startTimeA - startTimeB;
};

/**
 * Tries to extract the start time of a string containing nn:nn. Returns
 * the nn:nn as integer or 9999.
 * @param groupName
 * @returns number
 */
const extractStartTime = (groupName: string) => {
  const idxDay = groupName.indexOf(':');
  if (idxDay === -1) {
    return 99;
  }
  const startTime = groupName.slice(idxDay - 2, idxDay);
  return parseInt(startTime) || 0;
};

const PlanTable = ({ index, lineItems, planId, spid, cpid }: PlanTableProps) => {
  const {
    fbau1272ChangeUnitForCarbonChargesOnInvoiceAndChargesetDetailsScreens,
    nz1683AddCostToUi, // LD Client Key is nz-1683-add-cost-to-ui
  } = useFlags();
  const tableIndex = index + 1;

  lineItems.sort(compareLineItems);

  // need to retrieve plan name from our own api
  const { data, isInitialLoading: isLoading, isError } = usePlanWithDeletedChargeV2(planId);

  // construct an appropriate plan name to display
  const planName = isLoading ? 'Loading...' : isError ? `${planId}` : data?.planName;

  return (
    <div data-testid={`plan-table--${tableIndex}`}>
      <div className="table-filter">
        <h3 className="table-filter__heading">{planName}</h3>
      </div>
      <table className="apl-table-v1 apl-width-full">
        <thead>
          <tr>
            <th>Charge</th>
            <th>Charge reference</th>
            {isEnergyPlan(data?.structure) && <th>Flow direction</th>}
            <th>Bill group</th>
            <th>Meter ID</th>
            <th>Usage</th>
            <th>Rate</th>
            <th>
              {fbau1272ChangeUnitForCarbonChargesOnInvoiceAndChargesetDetailsScreens
                ? 'Total'
                : 'Total price'}
            </th>
            {nz1683AddCostToUi && (
              <>
                <th>Input Cost</th>
                <th>Total Input Cost</th>
              </>
            )}
          </tr>
        </thead>
        <tbody>
          {lineItems &&
            lineItems.map((lineItem, lineItemIndex) => {
              // see if we can find the charge name based on the plan data from
              // our system
              const matchingCharge =
                data && (data as Plan).charges?.find((c) => c.id === lineItem.chargeId);

              return (
                <PlanTableRow
                  charge={matchingCharge}
                  isEnergyPlan={isEnergyPlan(data?.structure)}
                  key={`plan-${tableIndex}-charge-${lineItemIndex + 1}`}
                  rowIndex={`${lineItemIndex + 1}`}
                  tableIndex={tableIndex}
                  spid={spid}
                  cpid={cpid}
                  {...lineItem}
                />
              );
            })}
        </tbody>
      </table>
    </div>
  );
};

export default PlanTable;
