import React, { FunctionComponent } from 'react';
import { Form, Formik } from 'formik';
import AuthorizedAction from '../common/AuthorizedAction';
import ErrorDisplay, { ErrorModel } from '../../api/ErrorDisplay';
import { Permission } from '../../auth/getPermissions';

interface ChangeStateFormProps {
  close: () => void;
  planState: string | undefined;
  submitFunction: (currentPlanState: string | undefined) => Promise<any>;
  error?: ErrorModel;
}

const ChangeStateForm: FunctionComponent<ChangeStateFormProps> = ({
  close,
  planState,
  submitFunction,
  error,
}) => {
  return (
    <Formik initialValues={{}} onSubmit={() => submitFunction(planState)}>
      {() => (
        <Form className="apl-form-layout-v1">
          <div data-testid="confirm-change-state-message">
            Are you sure you want to change the plan state?
          </div>
          <div className="apl-display-flex apl-justify-content-end">
            <button
              data-testid="cancel-change-state-button"
              className="apl-button-v1"
              onClick={close}
              type={'button'}
            >
              Cancel
            </button>
            <AuthorizedAction
              extraClasses="is-primary"
              permission={Permission.PLAN_EDIT}
              removeDefaultWeight={true}
              testId="confirm-change-state-button"
              type="submit"
            >
              Confirm
            </AuthorizedAction>
          </div>
          <ErrorDisplay error={error} />
        </Form>
      )}
    </Formik>
  );
};

export default ChangeStateForm;
