/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type UpdateInvoice = {
    status?: UpdateInvoice.status;
};

export namespace UpdateInvoice {

    export enum status {
        URN_FLUX_BILLING_INVOICE_STATUS_PAID = 'urn:flux:billing:invoice:status:paid',
        URN_FLUX_BILLING_INVOICE_STATUS_REVERSED = 'urn:flux:billing:invoice:status:reversed',
    }


}

