import React from 'react';
import './SplashScreen.scss';

const SplashScreen = () => (
  <div className="splash-screen" data-testid="splash screen">
    <div className="loading-dot">.</div>
  </div>
);

export default SplashScreen;
