import React, { FunctionComponent, useState } from 'react';
import Modal from 'react-modal';
import { Connection, SupplyPeriodDetails, SupplyPeriodSummary } from '../connectionsApi';
import AuthorizedAction from '../../common/AuthorizedAction';
import { Widget } from '../../dashboard/Dashboard';
import { modalStyles } from '../../layout/Modal';
import { Permission } from '../../../auth/getPermissions';
import ExpectedChargeComponentsForm from './ExpectedChargeComponentsForm';
import { EuiBasicTable, EuiModal } from '@elastic/eui';
import { useFlags } from 'launchdarkly-react-client-sdk';

interface ExpectedChargeComponentsProps {
  connection: Connection;
  supplyPeriod?: SupplyPeriodSummary;
  supplyPeriodV3?: SupplyPeriodDetails;
}

const ExpectedChargeComponents: FunctionComponent<ExpectedChargeComponentsProps> = ({
  connection,
  supplyPeriod,
  supplyPeriodV3,
}) => {
  const [modalOpen, setModalOpen] = useState(false);

  const {
    inc6546AddFlowDirectionToExpectedChargeComponents, // key is inc-6546-add-flow-direction-to-expected-charge-components
  } = useFlags();

  if (!supplyPeriod || !supplyPeriodV3) {
    // no point in continuing without supply period but make it pretty
    return (
      <Widget
        className="apl-mt-none"
        headerComponent={
          <h3 className="apl-h3 widget__title widget__title--inline apl-mb-none">
            Expected charge components
          </h3>
        }
        width="full"
      >
        <p className="widget__list-item">Missing Supply Period</p>
      </Widget>
    );
  }

  const allChargeTags = supplyPeriod.expectedChargeTags;

  return (
    <>
      <Widget
        className="apl-mt-none"
        headerComponent={
          <h3 className="apl-h3 widget__title widget__title--inline apl-mb-none">
            Expected charge components
            <AuthorizedAction
              extraClasses="apl-ml-l"
              onClick={() => setModalOpen(true)}
              permission={Permission.CONNECTION_EDIT}
            >
              Edit
            </AuthorizedAction>
          </h3>
        }
        width="full"
      >
        {inc6546AddFlowDirectionToExpectedChargeComponents ? (
          <EuiBasicTable
            style={{ maxWidth: 470 }}
            columns={[
              {
                field: 'tag',
                name: 'Charge Tag',
              },
              {
                field: 'flow',
                name: 'Flow Direction',
              },
            ]}
            items={supplyPeriodV3.expectedChargeComponents ?? []}
          />
        ) : (
          <ul className="widget__list">
            {allChargeTags?.map((tag) => (
              <li className="widget__list-item" key={`expected-charge-tag--${tag.toLowerCase()}`}>
                {tag === 'ENERGYFEE'
                  ? 'Energy fee'
                  : tag.substr(0, 1) + tag.substring(1).toLowerCase()}
              </li>
            ))}
          </ul>
        )}
      </Widget>
      {inc6546AddFlowDirectionToExpectedChargeComponents ? (
        modalOpen && (
          <EuiModal onClose={() => setModalOpen(false)}>
            <ExpectedChargeComponentsForm
              connection={connection}
              supplyPeriod={supplyPeriod}
              supplyPeriodV3={supplyPeriodV3}
              onSubmit={() => setModalOpen(false)}
              onCancel={() => setModalOpen(false)}
            />
          </EuiModal>
        )
      ) : (
        <Modal
          isOpen={modalOpen}
          onRequestClose={() => setModalOpen(false)}
          shouldCloseOnOverlayClick={true}
          style={modalStyles}
        >
          <ExpectedChargeComponentsForm
            connection={connection}
            supplyPeriod={supplyPeriod}
            supplyPeriodV3={supplyPeriodV3}
            onSubmit={() => setModalOpen(false)}
            onCancel={() => setModalOpen(false)}
          />
        </Modal>
      )}
    </>
  );
};

export default ExpectedChargeComponents;
