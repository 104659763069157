/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class TruncateService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @param tableName The name of the table to truncate
     * @returns void
     * @throws ApiError
     */
    public truncate(
        tableName: string,
    ): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/test/truncate/{table-name}',
            path: {
                'table-name': tableName,
            },
        });
    }

}
