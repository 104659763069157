import { ErrorMessage, Field } from 'formik';
import React from 'react';
import FieldWrapper, { FieldWidth } from '../../form/FieldWrapper';
import { FieldOption } from 'util/helper-func';

interface SimpleChargeFormBillingCategoryFieldProps {
  ErrorText: React.FunctionComponent;
  displayOptions: FieldOption[];
}

export const SimpleChargeFormBillingCategoryFieldFBAU635 = ({
  ErrorText,
  displayOptions,
}: SimpleChargeFormBillingCategoryFieldProps) => {
  return (
    <>
      {displayOptions?.length && (
        <FieldWrapper
          htmlFor="billing-category"
          label="Billing category"
          fieldWidth={FieldWidth.QUARTER}
        >
          <Field
            autoComplete="off"
            data-testid="billing-category"
            name="billingCategory"
            id="billing-category"
            className="apl-select-v1_0"
            as="select"
          >
            {displayOptions?.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </Field>
          <ErrorMessage component={ErrorText} name="billingCategory" />
        </FieldWrapper>
      )}
    </>
  );
};
