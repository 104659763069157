/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * The source of the adjustment (distribution or transmission).
 */
export enum AdjustmentSource {
    URN_FLUX_RATING_CHARGE_ADJUSTMENT_TRANSMISSION = 'urn:flux:rating:charge:adjustment:transmission',
    URN_FLUX_RATING_CHARGE_ADJUSTMENT_DISTRIBUTION = 'urn:flux:rating:charge:adjustment:distribution',
    URN_FLUX_RATING_CHARGE_ADJUSTMENT_NONE = 'urn:flux:rating:charge:adjustment:none',
}
