/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { ChargeTags } from './ChargeTags';

export type TestDiscount = {
    name?: string;
    invoiceDescription?: string;
    reference?: string;
    calculationMethod?: TestDiscount.calculationMethod;
    andChargeTagsFilter?: ChargeTags;
    orChargeTagsFilter?: ChargeTags;
    value?: number;
    validFrom?: string;
    validTo?: string;
    overrideValue?: number;
};

export namespace TestDiscount {

    export enum calculationMethod {
        FIXED = 'fixed',
        PERCENTAGE = 'percentage',
    }


}

