import apiClient from '../api/apiClient';

export enum Permission {
  CONNECTION_EDIT = 'CONNECTION_EDIT',
  CONNECTION_VIEW = 'CONNECTION_VIEW',
  OBSERVANCE_EDIT = 'OBSERVANCE_EDIT',
  OBSERVANCE_VIEW = 'OBSERVANCE_VIEW',
  PLAN_EDIT = 'PLAN_EDIT',
  PLAN_VIEW = 'PLAN_VIEW',
  PLAN_ARCHIVE = 'PLAN_ARCHIVE',
  PROVIDERS_EDIT = 'PROVIDERS_EDIT',
  PROVIDERS_VIEW = 'PROVIDERS_VIEW',
  QUALIFIERS_EDIT = 'QUALIFIERS_EDIT',
  QUALIFIERS_VIEW = 'QUALIFIERS_VIEW',
  PLAN_EXPORT_IMPORT = 'PLAN_EXPORT_IMPORT',

  METRIC_CREATE = 'METRIC_CREATE',
  METRIC_EDIT = 'METRIC_EDIT',
  METRIC_VIEW = 'METRIC_VIEW',
  METRIC_DELETE = 'METRIC_DELETE',
}

const getPermissions = async () => {
  return await apiClient().get('/permissions');
};

export default getPermissions;
