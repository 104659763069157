import { RatingCalculationMetricsResponse } from '../../../../api/openapi/rating-calculator/models/RatingCalculationMetricsResponse';

class CalculationMetrics {
  private calculations: RatingCalculationMetricsResponse;

  constructor(calculations: RatingCalculationMetricsResponse) {
    this.calculations = calculations;
  }

  getCalculationCount(): number {
    return this.calculations.calculationsWaiting + this.calculations.calculationsInProgress;
  }
}

export default CalculationMetrics;
