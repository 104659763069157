import React, { FunctionComponent } from 'react';
import { ErrorModel } from '../../api/ErrorDisplay';

import { useUpdatePlanV2 } from './planApi';
import { Plan, PlanBody } from 'api/openapi/rating-config';
import PlanForm from './PlanForm';

interface UpdatePlanProps {
  close: () => void;
  planId: string | undefined;
  plan: Plan | undefined;
}

const UpdatePlan: FunctionComponent<UpdatePlanProps> = ({ close, plan, planId }) => {
  const { mutateAsync, isLoading: isUpdating, error } = useUpdatePlanV2();
  const updatePlan = async (plan: PlanBody) => {
    try {
      await mutateAsync({ plan, planId } as any);
      close();
    } catch (e: any) {
      console.error(e);
    }
  };

  return (
    <div className="apl-form-layout-v1">
      <h2>Update plan</h2>

      <PlanForm
        isUpdating={isUpdating}
        submitFunction={updatePlan}
        close={close}
        plan={plan}
        error={error as ErrorModel}
      />
    </div>
  );
};

export default UpdatePlan;
