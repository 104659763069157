/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * There are currently 3 types of billing line item tags:
 *
 * * Category - the categories and sub categories of the line item. See below for more details.
 * * Display Section - display hint (e.g., for invoice) configured in the Charge. See below for more details.
 * * Energy Flow - if applicable the energy flow direction of energy charged by the line item
 * * Type - the type or source of the line item. Currently there are just 2: discount and charge
 *
 * ## Categories
 *
 * A Line Item can belong to one or more categories.
 *
 * Generally these are derived from the tags on the Charge at the time the Line Item was created.
 *
 * The category tags are versioned to allow for new versions when there is a change in a
 * categorisation scheme. For example a new category could be added that splits an existing
 * category. This gives downstream systems a time to cater for the new categorisation of line items
 * while also being able to detect and manage historical line items that still use an older
 * categorisation scheme.
 *
 * Each category tag will be part of one or more mutually exclusive sets of categories. This means
 * the line item is expected to be tagged with one of the categories from the set (as long as the
 * category is still used). A downstream system can use this to validate that the mapping it uses
 * is still valid for the current values. For example if a line item doesn't have one of
 * `urn:flux:billing:line-item-tag:category:energy:v1`,
 * `urn:flux:billing:line-item-tag:category:network:v1`, or
 * `urn:flux:billing:line-item-tag:category:other:v1` then the set is no longer current and the
 * mapping will need to be updated.
 *
 * ## Current sets
 * `urn:flux:billing:line-item-tag:category:energy:v1` |
 * `urn:flux:billing:line-item-tag:category:network:v1` |
 * `urn:flux:billing:line-item-tag:category:other:v1`
 *
 * `urn:flux:billing:line-item-tag:category:energy:fee:v1` |
 * `urn:flux:billing:line-item-tag:category:energy:other:v1` |
 * `urn:flux:billing:line-item-tag:category:network:v1` |
 * `urn:flux:billing:line-item-tag:category:other:v1`
 *
 * ## Display Section
 * Display hint (e.g., for invoice) configured in the Charge. Is the equivalent concept of a Charge Tag but for
 * tagging how display is controlled. This is mapped from the Charge's Display Section property at the time the
 * Line Item is created. Only one Display Section can be added, as each Charge can only have one Display Section value.
 *
 * Initially, this is being used to configure the Billing Invoice Section that the line item gets displayed under
 * (urn:flux:billing:line-item-tag:display-section:environmental means the line item should be displayed under its own
 * Environmental Section).
 *
 * ## Energy Flow
 *
 * Billing isn't expected to track energy flow and so it is indicated through a tag. Presently an
 * energy flow tag is only used on line items in the
 * urn:flux:billing:line-item-tag:category:energy:other:v1 category
 *
 */
export enum LineItemTag {
    URN_FLUX_BILLING_LINE_ITEM_TAG_CATEGORY_ENERGY_V1 = 'urn:flux:billing:line-item-tag:category:energy:v1',
    URN_FLUX_BILLING_LINE_ITEM_TAG_CATEGORY_NETWORK_V1 = 'urn:flux:billing:line-item-tag:category:network:v1',
    URN_FLUX_BILLING_LINE_ITEM_TAG_CATEGORY_OTHER_V1 = 'urn:flux:billing:line-item-tag:category:other:v1',
    URN_FLUX_BILLING_LINE_ITEM_TAG_CATEGORY_ENERGY_FEE_V1 = 'urn:flux:billing:line-item-tag:category:energy:fee:v1',
    URN_FLUX_BILLING_LINE_ITEM_TAG_CATEGORY_ENERGY_OTHER_V1 = 'urn:flux:billing:line-item-tag:category:energy:other:v1',
    URN_FLUX_BILLING_LINE_ITEM_TAG_DISPLAY_SECTION_ENVIRONMENTAL = 'urn:flux:billing:line-item-tag:display-section:environmental',
    URN_FLUX_BILLING_LINE_ITEM_TAG_DISPLAY_SECTION_REGULATOR = 'urn:flux:billing:line-item-tag:display-section:regulator',
    URN_FLUX_BILLING_LINE_ITEM_TAG_ENERGY_FLOW_TO_CONNECTION = 'urn:flux:billing:line-item-tag:energy-flow:to-connection',
    URN_FLUX_BILLING_LINE_ITEM_TAG_ENERGY_FLOW_FROM_CONNECTION = 'urn:flux:billing:line-item-tag:energy-flow:from-connection',
    URN_FLUX_BILLING_LINE_ITEM_TAG_TYPE_CHARGE = 'urn:flux:billing:line-item-tag:type:charge',
    URN_FLUX_BILLING_LINE_ITEM_TAG_TYPE_DISCOUNT = 'urn:flux:billing:line-item-tag:type:discount',
    URN_FLUX_BILLING_LINE_ITEM_TAG_DISCOUNT_V1 = 'urn:flux:billing:line-item-tag:discount:v1',
}
