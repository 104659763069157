import React, { useEffect, useState } from 'react';
import useRatingStatusSelect from './useRatingStatusSelect';
import { endOfDay, format, startOfDay, subSeconds } from 'date-fns';
import { useLocation } from 'react-router-dom';
import EmptyTableRow from '../../common/EmptyTableRow';
import Pagination from '../../common/Pagination';
import { Connection, ConnectionsBody, useConnections } from '../../connections/connectionsApi';
import Card from '../../layout/Card';
import Page, { PageHeader } from '../../layout/Page';
import {
  RatingCalculation,
  RatingCalculationPageResponse,
  RatingsQueryParams,
  RatingStatus,
  useRatings,
} from '../../rating-calculator/ratingsApi';
import FailedRatingRequestLine from './FailedRatingRequestLine';
import { useFlags } from 'launchdarkly-react-client-sdk';
import searchIcon from '../../../icons/search.svg';

import '../../common/Table.scss';
import '../../page/Buttons.scss';
import './FailedRatingRequests.scss';
import apiClient from '../../../api/apiClient';
import {
  isValidateDate,
  marketEndOfMonth,
  marketStartOfMonth,
  newMarketDate,
} from '../../../util/helper-func';
import { EuiSelect } from '@elastic/eui';

interface LineItem {
  id: string;
  connectionId: string;
  isAvailable: boolean;
  index: number;
  item: RatingCalculation;
}

const sortBy = 'requestedAt.desc';
const limit = 20;

const isStatusFailed = (ratingStatus: RatingStatus): boolean => {
  return ratingStatus === 'urn:flux:rating:request:status:failed';
};

const FailedRatingRequests = () => {
  let notRequestedBy: string[] = [];

  const { ratingStatus, RatingStatusSelect } = useRatingStatusSelect();

  const {
    oci1865FilterForComparativeFailedRatings, // LD Client key i oci-1865-filter-for-comparative-failed-ratings
  } = useFlags();

  // download csv
  const [isCsvLoading, setIsCsvLoading] = useState(false);
  const [isMissingIcpLoading, setIsMissingIcpLoading] = useState(true);
  const [purpose, setPurpose] = useState('billable');

  const status: RatingStatus[] = [ratingStatus];
  const valueFormat = "yyyy-MM-dd'T'HH:mm:ssXXX";

  const [page, setPage] = useState(0);
  const [connectionId, setConnectionId] = useState<string | undefined>();
  const [preparedLines, setPreparedLines] = useState<LineItem[]>([]);
  const [connectionIdNotFound, setConnectionIdNotFound] = useState(false);
  const [isInvalidTo, setIsInvalidTo] = useState(false);
  const [isInvalidFrom, setIsInvalidFrom] = useState(false);

  const { search } = useLocation();
  const query = search ? new URLSearchParams(search) : null;
  const queryDate = query && query.get('date');
  const initialDate = queryDate !== null ? new Date(queryDate) : new Date();

  const [requestedAtFrom, setRequestedAtFrom] = useState(
    format(marketStartOfMonth(initialDate), 'yyyy-MM-dd')
  );
  const [requestedAtTo, setRequestedAtTo] = useState(
    format(subSeconds(marketEndOfMonth(initialDate), 1), 'yyyy-MM-dd')
  );

  const offset = page !== 0 ? page * limit : page;

  notRequestedBy = [
    ...notRequestedBy,
    'urn:flux:service:billing:unbilled-invoices-trigger',
    'urn:flux:service:rating:best-offer-trigger',
  ];

  const params: RatingsQueryParams = {
    connectionId,
    requestedAtFrom: format(startOfDay(newMarketDate(requestedAtFrom)), valueFormat),
    requestedAtTo: format(endOfDay(newMarketDate(requestedAtTo)), valueFormat),
    limit,
    offset,
    sortBy,
    status,
    notRequestedBy,
    purpose,
  };

  if (isStatusFailed(ratingStatus)) {
    params.omitFailedWithSubsequentSuccess = true;
  } else {
    params.omitInProgressWithSubsequentSuccess = true;
    params.omitInProgressReceivedLessThanSecondsAgo = 900;
  }

  const formatConnectionId = (coreICPs: ConnectionsBody[], scopedTo?: string) => {
    const id = scopedTo?.split(':').pop();
    const connectionId =
      connectionData && connectionData.find((c: Connection) => c.id === id)?.connectionId;
    return connectionId ? connectionId : coreICPs.find((c) => c.id === id)?.connectionId;
  };

  const handleDownloadCsvClick = async () => {
    setIsCsvLoading(true);

    const search = new URLSearchParams();
    search.set('requestedAtFrom', format(startOfDay(newMarketDate(requestedAtFrom)), valueFormat));
    search.set('requestedAtTo', format(endOfDay(newMarketDate(requestedAtTo)), valueFormat));
    search.set('limit', '5000'); /* max results supported */
    search.set('offset', '0');
    search.set('status', 'urn:flux:rating:request:status:failed');
    search.set('notRequestedBy', notRequestedBy.join(','));
    search.set('omitFailedWithSubsequentSuccess', 'true');

    try {
      // get all failed ratings from backend
      const response = await apiClient('rating-calculator').get(`/ratings?${search.toString()}`);

      let csvResult;
      const missingItems = getMissingItems(response, connectionData);
      const coreICPs = await getICPsFromCore(missingItems);

      // extract fields and format to csv
      csvResult = response.items
        .map((r: any) => {
          const requestedAt = format(new Date(r.context.requestedAt), 'dd-MM-yyyy HH:mm:ss');
          const errorMsg = r.errors.join(',').replace(/(?:\r\n|\r|\n)/g, ' ');
          const from = r.context.startsAt
            ? format(newMarketDate(r.context.startsAt), 'dd-MM-yyyy')
            : '';
          const to = r.context.endsAt
            ? format(subSeconds(newMarketDate(r.context.endsAt), 1), 'dd-MM-yyyy')
            : '';
          return `"${
            formatConnectionId(coreICPs, r.context.scopedTo) ?? ''
          }", "${requestedAt}", "${from}-${to}", "${errorMsg}"`;
        })
        .join('\n');

      // prepend header
      csvResult =
        'data:text/csv;charset=utf-8, "source", "publishedAt", "requestedBillingPeriod", "message"\n' +
        csvResult;

      const filename = `failed_rating_requests_${requestedAtFrom}.csv`;
      csvResult = encodeURI(csvResult);

      const link = document.createElement('a');
      link.setAttribute('href', csvResult);
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (err: any) {
      console.error(`Error downloading csv data: ${err.message}`);
    } finally {
      setIsCsvLoading(false);
    }
  };

  const { data, isError, isInitialLoading: isLoading } = useRatings(params);

  const { data: connectionData } = useConnections();

  // dealing with missing ICPs - a bit tricky. Need to amend the connectionData
  // doing it that way to increase performance especially for CSV-download.
  useEffect(() => {
    const prepareLineItems = async () => {
      setIsMissingIcpLoading(true);

      const missingItems = getMissingItems(data as RatingCalculationPageResponse, connectionData);

      const additionalConnectionData = await getICPsFromCore(missingItems);

      const lineItems = createLineItems(data, connectionData, additionalConnectionData);

      setIsMissingIcpLoading(false);
      setPreparedLines(lineItems);
    };
    if (data && connectionData) {
      prepareLineItems();
    }
  }, [data, connectionData]);

  const pages =
    data?.totalCount && data.totalCount > limit ? Math.ceil(data.totalCount / limit) : 1;

  const resetPage = () => {
    setPage(0);
  };

  const onSearchFilterKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      const { value } = e.currentTarget;

      // the user will likely search by external connection id so we
      // need to find the matching uuid to send to the /ratings api
      if (preparedLines) {
        const connectionId = connectionData.find(
          (c: { connectionId: string }) => c.connectionId === value
        )?.id;
        if (connectionId || value === '') {
          setConnectionIdNotFound(false);
          setConnectionId(connectionId);
          resetPage();
        } else {
          // display error message
          setConnectionIdNotFound(true);
        }
      }
    }
  };

  const onChangeFrom = (value: string | undefined) => {
    if (value && isValidateDate(value, 'yyyy-MM-dd')) {
      setIsInvalidFrom(false);
      setRequestedAtFrom(value);
      resetPage();
    } else {
      setIsInvalidFrom(true);
    }
  };

  const onChangeTo = (value: string | undefined) => {
    if (value && isValidateDate(value, 'yyyy-MM-dd')) {
      setIsInvalidTo(false);
      setRequestedAtTo(value);
      resetPage();
    } else {
      setIsInvalidTo(true);
    }
  };

  return (
    <>
      <PageHeader backLink="/dashboard" title="Failed rating requests" />
      <Page>
        <Card className="apl-py-none">
          <div className="card__inner">
            <div className="table-filter apl-display-flex apl-flex-row apl-align-items-center apl-justify-content-between">
              <div className="apl-display-flex apl-flex-row apl-align-items-center">
                <div className="apl-field-v1 apl-display-flex apl-flex-row apl-align-items-center apl-mr apl-mr-s">
                  <label className="apl-field__label apl-mr apl-mb-none" htmlFor="from-field">
                    Show failed requests from
                  </label>
                  <div className="apl-display-flex apl-flex-column">
                    <input
                      className="apl-text-input-v1"
                      id="from-field"
                      data-testid="failed requests from input"
                      type="date"
                      onChange={(e) => onChangeFrom(e.target.value)}
                      value={requestedAtFrom}
                    />
                    {isInvalidFrom && <div className={'error'}>Invalid from date</div>}
                  </div>
                </div>
                <div className="apl-field-v1 apl-display-flex apl-flex-row apl-align-items-center apl-mr apl-mr-s">
                  <label className="apl-field__label apl-mr apl-mb-none" htmlFor="to-field">
                    to
                  </label>
                  <div className="apl-display-flex apl-flex-column">
                    <input
                      className="apl-text-input-v1"
                      id="to-field"
                      data-testid="failed requests to input"
                      type="date"
                      onChange={(e) => onChangeTo(e.target.value)}
                      value={requestedAtTo}
                    />
                    {isInvalidTo && <div className={'error'}>Invalid to date</div>}
                  </div>
                </div>
                <div className="apl-field-v1 apl-display-flex apl-flex-row apl-align-items-center apl-mx">
                  <label className="apl-field__label apl-mr apl-mb-none">Status</label>
                  <RatingStatusSelect />
                </div>
                {oci1865FilterForComparativeFailedRatings && (
                  <div className="apl-field-v1 apl-display-flex apl-flex-row apl-align-items-center apl-mx">
                    <label className="apl-field__label apl-mr apl-mb-none">Purpose</label>
                    <EuiSelect
                      data-testid="rating status select"
                      value={purpose}
                      onChange={(e) => setPurpose(e.currentTarget.value)}
                      options={[
                        { text: 'Billable', value: 'billable' },
                        { text: 'Comparative', value: 'comparative' },
                      ]}
                    />
                  </div>
                )}
              </div>
              <div className="search-filter">
                <input
                  className="apl-text-input-v1 search-filter__input"
                  onKeyUp={(e) => onSearchFilterKeyUp(e)}
                  placeholder="Find a connection"
                  type="text"
                />
                <img alt="search icon" className="search-filter__icon" src={searchIcon} />
                {connectionIdNotFound && <div className={'error'}>Not found</div>}
              </div>
            </div>
          </div>
          <table className="apl-table-v1 apl-mb-none">
            {(isLoading || isMissingIcpLoading) && (
              <tbody>
                <tr>
                  <td>Loading...</td>
                </tr>
              </tbody>
            )}
            {isError && (
              <tbody>
                <tr>
                  <td>Sorry there was an error.</td>
                </tr>
              </tbody>
            )}
            {data && data?.items.length > 0 && (
              <>
                <thead>
                  <tr>
                    <th>Connection ID</th>
                    <th>Reason</th>
                    <th>Requested Billing Period</th>
                    <th>Time</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {preparedLines.map((lineItem) => {
                    return (
                      <FailedRatingRequestLine
                        key={`failed-rating-requests-line-${lineItem.index}`}
                        connectionId={lineItem.connectionId}
                        isAvailable={lineItem.isAvailable}
                        index={lineItem.index}
                        item={lineItem.item}
                      />
                    );
                  })}
                </tbody>
              </>
            )}
            {data && data?.items.length === 0 && (
              <EmptyTableRow message="No failed rating requests found." />
            )}
          </table>
          <div className="card__inner">
            <Pagination page={page} onPageClick={setPage} pages={pages} />
            {isStatusFailed(ratingStatus) && (
              <div className="apl-display-flex apl-flex-column apl-align-items-end apl-mx-s apl-my-s">
                <button className="apl-button-v1" onClick={handleDownloadCsvClick}>
                  {isCsvLoading ? 'Loading...' : 'Download CSV'}
                </button>
              </div>
            )}
          </div>
        </Card>
      </Page>
    </>
  );
};

export default FailedRatingRequests;

/**
 * Returns all RatingCalculation items where the connection UUID can't be found in the
 * Rating-API.
 * @param data
 * @param connectionData
 */
const getMissingItems = (data: RatingCalculationPageResponse, connectionData: Connection[]) => {
  return data.items.filter((item) => {
    const id = item.context.scopedTo.split(':').pop();
    return connectionData.every((c) => c.id !== id);
  });
};

/**
 * Returns all connection data from Core (via Rating-API) for the given missing items array.
 * @param missingItems
 */
const getICPsFromCore = async (missingItems: RatingCalculation[]): Promise<ConnectionsBody[]> => {
  try {
    return await Promise.all(
      missingItems.map(async (item) => {
        const id = item.context?.scopedTo?.split(':').pop();
        return await apiClient().get(`/connections/${id}/connectionmessage`);
      })
    );
  } catch (err) {
    console.error(err);
    return [];
  }
};

/**
 * Returns an array of LineItems containing failed rating requests ready for rendering.
 * @param data
 * @param connectionData
 * @param additionalConnectionData
 */
const createLineItems = (
  data: RatingCalculationPageResponse,
  connectionData: Connection[],
  additionalConnectionData: ConnectionsBody[]
): LineItem[] => {
  return data.items.map((item, index) => {
    const id = item.context.scopedTo.split(':').pop();
    let connectionId: string | undefined = connectionData.find(
      (c: Connection) => c.id === id
    )?.connectionId;
    let isAvailable = true;
    if (!connectionId && additionalConnectionData) {
      connectionId = additionalConnectionData.find((conn) => conn.id === id)?.connectionId;
      isAvailable = false;
    }
    if (!connectionId) {
      connectionId = 'N/A';
      isAvailable = false;
    }
    return {
      id,
      connectionId,
      isAvailable,
      index,
      item,
    } as LineItem;
  });
};
