import React, { FunctionComponent } from 'react';
import { useNavigate } from 'react-router-dom';
import { ErrorModel } from '../../api/ErrorDisplay';

import { usePlanCreationV2, usePlanV2 } from './planApi';
import { Charge, PlanBody } from 'api/openapi/rating-config';
import PlanForm from './PlanForm';

interface CopyPlanProps {
  close: () => void;
  planId: string | undefined;
}

const CopyPlan: FunctionComponent<CopyPlanProps> = ({ close, planId }) => {
  const navigate = useNavigate();

  const { mutateAsync, isLoading: isSubmitting, error } = usePlanCreationV2();

  const { isInitialLoading: isLoading, isError, data } = usePlanV2(planId);

  if (isError) {
    return <p>Error loading plan</p>;
  }

  if (isLoading) {
    return <p>Loading plan...</p>;
  }

  const copyPlan = async (plan: PlanBody) => {
    const charges: Charge[] = data?.charges
      ? [...data.charges].map((c) => ({ ...c, id: undefined }))
      : [];

    try {
      const { id } = await mutateAsync({
        ...plan,
        charges,
      } as any);

      if (id) {
        navigate(`/plans/${id}`);
      }
    } catch (e: any) {
      console.error(e);
    }
  };

  switch (data?.structure) {
    case 'SIMPLE_CHARGE':
      return (
        <div className="apl-form-layout-v1">
          <h2>Copy plan</h2>

          <PlanForm
            close={close}
            error={error as ErrorModel}
            isCopy={true}
            isUpdating={isSubmitting}
            plan={data}
            selectedProviderId={data.id}
            submitFunction={copyPlan}
          />
        </div>
      );

    default:
      return (
        <div className="apl-form-layout-v1">
          <h2>Copy plan</h2>
          <p>Copy plan is currently only available for simple plans.</p>
          <button
            className="apl-button-v1"
            data-testid="cancel-button"
            onClick={close}
            type={'button'}
          >
            Cancel
          </button>
        </div>
      );
  }
};

export default CopyPlan;
