import React from 'react';
import { Widget } from '../Dashboard';
import { useRatingCalculatorMetrics } from '../../rating-calculator/ratingsApi';

export const ratingCalculatorMetricsWidgetTitle = 'Rating calculation processing status';

const RatingCalculatorMetricsWidget = () => {
  const { data: metrics, isError, isInitialLoading: isLoading } = useRatingCalculatorMetrics();

  function formatMinutes(value: number): string | null {
    if (value >= 1.5) {
      return `${Math.round(value)} minutes`;
    } else if (value >= 1 && value < 1.5) {
      return '1 minute';
    } else if (value > 0 && value < 1) {
      return 'Less than a minute';
    } else if (value === 0) {
      return null;
    }
    return null;
  }

  return (
    <Widget title={ratingCalculatorMetricsWidgetTitle}>
      {isLoading && <p className="apl-px">Loading...</p>}
      {isError && <p className="apl-px">Sorry, there was an error.</p>}
      {metrics && (
        <ul className="widget__list">
          <li className="widget__list-item">
            Ratings waiting to start<span>{metrics.calculationsWaiting}</span>
          </li>
          <li className="widget__list-item">
            Ratings in progress<span>{metrics.calculationsInProgress}</span>
          </li>
          <li className="widget__list-item">
            Ratings completed in the last 15 minutes<span>{metrics.calculationsProcessed}</span>
          </li>
          {formatMinutes(metrics.estimatedCompletionTimeInMinutes) && (
            <li className="widget__list-item">
              Estimated time until all ratings are completed
              <span>{formatMinutes(metrics.estimatedCompletionTimeInMinutes)}</span>
            </li>
          )}
        </ul>
      )}
    </Widget>
  );
};

export default RatingCalculatorMetricsWidget;
