/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { AttributeError } from './AttributeError';

export type Error = {
    _type: Error._type;
    status: number;
    uri?: string;
    urn?: string;
    message: string;
    attributes?: Array<AttributeError>;
};

export namespace Error {

    export enum _type {
        ERROR = 'error',
    }


}

