import { useField } from 'formik';
import React, { FunctionComponent, useMemo } from 'react';
import CreatableSelect from 'react-select/creatable';
import { typeaheadStyles } from '.';
import FieldWrapper, { FieldWidth } from './FieldWrapper';

interface CreateableLookupProps {
  fieldWidth: FieldWidth;
  handleAddEvent?: (newValue: string) => void;
  hideLabel?: boolean;
  label: string;
  labelFieldName: string; // react-select needs value and label properties to display
  name: string;
  optional: boolean;
  options: any[];
  selectedOption: string;
  style?: object;
  valueFieldName: string; // react-select needs value and label properties to display
}

interface LookupOption {
  label: string;
  value: string;
}

const CreatableLookup: FunctionComponent<CreateableLookupProps> = ({
  fieldWidth,
  handleAddEvent,
  hideLabel,
  label,
  name,
  optional,
  valueFieldName,
  labelFieldName,
  selectedOption,
  options,
  style,
}) => {
  const formattedOptionsArray: LookupOption[] = useMemo(
    () =>
      options.map((item) => ({
        value: item[valueFieldName],
        label: item[labelFieldName],
      })),
    [options]
  );

  const [, , helper] = useField({ name });

  const handleChange = (newValue: any, actionMeta: any) => {
    if (actionMeta.action === 'create-option' && handleAddEvent !== undefined) {
      handleAddEvent(newValue.value);
    }
    helper.setValue(newValue ? newValue.label : '');
  };

  const selected = formattedOptionsArray.find((e) => e.label === selectedOption);

  return (
    <FieldWrapper
      hideLabel={hideLabel}
      htmlFor={name}
      label={label}
      fieldWidth={fieldWidth}
      optional={optional}
      style={style}
    >
      <CreatableSelect
        key={`${name}-${selectedOption}`}
        name={name}
        inputId={`${name}-field`}
        isClearable
        onChange={handleChange}
        options={formattedOptionsArray}
        defaultValue={selected}
        styles={typeaheadStyles}
      />
    </FieldWrapper>
  );
};

export default CreatableLookup;
