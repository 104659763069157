import { FormikErrors, FormikTouched, FormikValues } from 'formik';
import { isNil } from 'ramda';
import React, { FunctionComponent } from 'react';

export enum FieldWidth {
  FULL,
  HALF,
  THIRD,
  QUARTER,
  NONE,
}

interface FieldWrapperProps {
  className?: string;
  fieldWidth: FieldWidth;
  hideLabel?: boolean;
  htmlFor: string;
  label: string;
  locked?: boolean;
  onUpdateLocked?: () => void;
  optional?: boolean;
  style?: object;
  children?: React.ReactNode;
}

const genericClassNameBuilder = (
  name: string,
  errors: FormikErrors<FormikValues>,
  touched: FormikTouched<FormikValues>,
  classType: string
): string => {
  const errorSuffix: string = !isNil(touched[name]) && !isNil(errors[name]) ? 'has-error' : '';
  return `${classType} ${errorSuffix}`;
};

export const selectClassNameBuilder = (
  name: string,
  errors: FormikErrors<FormikValues>,
  touched: FormikTouched<FormikValues>
) => genericClassNameBuilder(name, errors, touched, 'apl-select-v1_0');

export const inputClassNameBuilder = (
  name: string,
  errors: FormikErrors<FormikValues>,
  touched: FormikTouched<FormikValues>
) => genericClassNameBuilder(name, errors, touched, 'apl-text-input-v1');

const FieldWrapper: FunctionComponent<FieldWrapperProps> = ({
  children,
  className,
  fieldWidth,
  hideLabel,
  htmlFor,
  label,
  locked,
  onUpdateLocked,
  optional,
  style,
}) => {
  let widthClass = '';

  switch (fieldWidth) {
    case FieldWidth.FULL:
      widthClass = 'apl-mr-s';
      break;
    case FieldWidth.HALF:
      widthClass = 'apl-width-1/2 ';
      break;
    case FieldWidth.QUARTER:
      widthClass = 'apl-width-1/4';
      break;
    case FieldWidth.THIRD:
      widthClass = 'apl-width-1/3';
      break;
    case FieldWidth.NONE:
      break;
    default:
      console.error('Field with unknown width');
  }

  return (
    <div className={`apl-field-v1 ${widthClass} ${className ? className : ''}`} style={style}>
      {!hideLabel && (
        <label
          className="apl-field__label"
          htmlFor={htmlFor}
          style={{
            marginBottom: '12px',
          }}
        >
          {label} {optional && <span className="apl-field__optional-text">Optional</span>}
          {onUpdateLocked && (
            <button
              className="button--link"
              data-testid={`${locked ? 'unlock' : 'lock'}-${label
                .replace(/ /g, '-')
                .toLowerCase()}-field`}
              onClick={onUpdateLocked}
              style={{ marginLeft: '2px' }}
              type="button"
            >
              {locked ? 'Edit default' : 'Use default'}
            </button>
          )}
        </label>
      )}
      <div className="apl-display-flex apl-flex-column">{children}</div>
    </div>
  );
};

export default FieldWrapper;
