import cx from 'classnames';
import React from 'react';

import './LoadingSpinner.scss';

interface LoadingSpinnerProps {
  extraClasses?: string;
}

const LoadingSpinner = ({ extraClasses }: LoadingSpinnerProps) => {
  return (
    <div className={cx('loading-spinner', extraClasses)}>
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
    </div>
  );
};

export default LoadingSpinner;
