import React, { FunctionComponent, useEffect, useState } from 'react';
import ReactModal from 'react-modal';
import { Link } from 'react-router-dom';
import AddQualifier from './AddQualifier';
import { Qualifier, useQualifierSearch } from './qualifiersApi';
import AuthorizedAction from '../common/AuthorizedAction';
import Pagination from '../common/Pagination';
import Card from '../layout/Card';
import { modalStyles } from '../layout/Modal';
import Page, { ErrorPage, LoadingPage, PageHeader } from '../layout/Page';
import { Permission } from '../../auth/getPermissions';
import searchIcon from '../../icons/search.svg';

import '../dashboard/missing-plans/SearchFilter.scss';
import './../../flux-styles/paginator.scss';

const limit = 10;

const QualifierList: FunctionComponent = () => {
  const [search, setSearch] = useState('');
  const [searchInput, setSearchInput] = useState('');
  const [sortColumn, setSortColumn] = useState('name');
  const [sortOrder, setSortOrder] = useState('ASC');
  const [page, setPage] = useState(0);
  const [sortByName, setSortByName] = useState('is-sortable is-sorted-asc');
  const [sortByProviderName, setSortByProviderName] = useState('');
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const offset = page === 0 ? 0 : limit * page;

  const {
    data,
    isInitialLoading: isLoading,
    isError,
  } = useQualifierSearch({
    search,
    sortColumn,
    sortOrder,
    offset,
    limit,
  });

  const sortBy = (columnName: string) => {
    setSortColumn(columnName);

    if (columnName === 'name') {
      setSortByProviderName('');

      if (sortByName === '') {
        setSortByName('is-sortable is-sorted-asc');
        setSortOrder('ASC');
      } else if (sortByName === 'is-sortable is-sorted-asc') {
        setSortByName('is-sortable is-sorted-desc');
        setSortOrder('DESC');
      } else if (sortByName === 'is-sortable is-sorted-desc') {
        setSortByName('is-sortable is-sorted-asc');
        setSortOrder('ASC');
      }
    } else {
      setSortByName('');

      if (sortByProviderName === '') {
        setSortByProviderName('is-sortable is-sorted-asc');
        setSortOrder('ASC');
      } else if (sortByProviderName === 'is-sortable is-sorted-asc') {
        setSortByProviderName('is-sortable is-sorted-desc');
        setSortOrder('DESC');
      } else if (sortByProviderName === 'is-sortable is-sorted-desc') {
        setSortByProviderName('is-sortable is-sorted-asc');
        setSortOrder('ASC');
      }
    }
  };

  useEffect(() => {
    setPage(0);
  }, [sortColumn]);

  if (isLoading) {
    return <LoadingPage />;
  }

  if (isError) {
    return <ErrorPage />;
  }

  const pages = data.totalCount > limit ? Math.ceil(data.totalCount / limit) : 1;

  return (
    <>
      <PageHeader showBack={true} title="Manage qualifiers">
        <AuthorizedAction
          extraClasses="is-primary"
          onClick={() => setModalIsOpen(true)}
          permission={Permission.QUALIFIERS_EDIT}
        >
          Add qualifier
        </AuthorizedAction>
      </PageHeader>
      <Page>
        <Card className="qualifier-list apl-pb-none half_xl">
          <div className="search-filter apl-mb-s">
            <input
              className="apl-text-input-v1 search-filter__input"
              onChange={(e) => setSearchInput(e.target.value)}
              onKeyUp={(e) => (e.key === 'Enter' ? setSearch(searchInput) : '')}
              placeholder="Search qualifiers"
              type="text"
              value={searchInput}
            />
            <img alt="search icon" className="search-filter__icon" src={searchIcon} />
          </div>
          <table className="apl-table-v1 apl-mb-none">
            <thead>
              <tr>
                <th onClick={() => sortBy('name')} className={`${sortByName}`}>
                  Qualifier name
                </th>
                <th onClick={() => sortBy('providername')} className={`${sortByProviderName}`}>
                  Provider
                </th>
              </tr>
            </thead>
            <tbody>
              {data &&
                data.items.map((q: Qualifier, index: number) => {
                  return (
                    <tr key={index}>
                      <td>
                        <Link
                          className="apl-color-primary"
                          data-testid={`qualifier-link-${index + 1}`}
                          to={`/qualifiers/${q.id}`}
                        >
                          {q.name}
                        </Link>
                      </td>
                      <td data-testid={`qualifier-provider-name-${q.id}`}>
                        {q.providerName ? q.providerName : 'All'}
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
          <div className="card__inner">
            <Pagination onPageClick={setPage} page={page} pages={pages} />
          </div>
        </Card>
        <ReactModal
          onRequestClose={() => setModalIsOpen(false)}
          shouldCloseOnEsc={true}
          shouldCloseOnOverlayClick={false}
          style={modalStyles}
          isOpen={modalIsOpen}
        >
          <AddQualifier close={() => setModalIsOpen(false)} />
        </ReactModal>
      </Page>
    </>
  );
};

export default QualifierList;
