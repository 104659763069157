/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { Links } from './Links';
import type { LossFactor } from './LossFactor';

export type LossFactorPageResponse = {
    _itemType?: LossFactorPageResponse._itemType;
    totalCount?: number;
    items?: Array<LossFactor>;
    _links?: Links;
};

export namespace LossFactorPageResponse {

    export enum _itemType {
        LOSS_FACTOR = 'loss-factor',
    }


}

