import React, { FC, useState } from 'react';
import { Calendar, dateFnsLocalizer, Event } from 'react-big-calendar';
import {
  addYears,
  format,
  getDay,
  isAfter,
  isBefore,
  isValid,
  parse,
  startOfWeek,
  subYears,
} from 'date-fns';
import enNZ from 'date-fns/locale/en-NZ';
import { useParams } from 'react-router-dom';
import { usePlanRatesV2 } from './planApi';
import { ChargeRate } from 'api/openapi/rating-config';
import Page, { ErrorPage } from 'components/layout/Page';
import Card from 'components/layout/Card';
import Alert, { AlertEnum } from 'components/layout/Alert';

import 'react-big-calendar/lib/css/react-big-calendar.css';

const PlanCalendar: FC = () => {
  const { planId } = useParams<{ planId: string }>();

  const now = new Date();

  const [from, setFrom] = useState<Date>(now);
  const [to, setTo] = useState<Date>(now);

  const {
    data,
    isError,
    isInitialLoading: isLoading,
  } = usePlanRatesV2(planId, from.toISOString(), to.toISOString());

  if (isError) {
    return <ErrorPage />;
  }

  const events: Array<Event> = convertApplicableTimePeriodsToEvents(data);

  return (
    <>
      <Alert type={AlertEnum.WARNING}>
        <div className="apl-text-center">
          This calendar is only available for test environments, production users cannot access this
          page.
        </div>
      </Alert>
      <Page>
        <Card>
          <div className="card__inner apl-display-flex apl-flex-row apl-align-items-center apl-justify-content-between apl-px">
            <div className="apl-display-flex apl-flex-row apl-align-items-center">
              <div className="apl-form-layout-v1 apl-field-v1 apl-display-flex apl-flex-row apl-align-items-center apl-mr-s">
                <label className="apl-field__label apl-mr-s apl-mb-none" htmlFor="date-field">
                  from
                </label>
                <input
                  className="apl-text-input-v1 apl-mb-none"
                  type="date"
                  value={format(from, 'yyyy-MM-dd')}
                  onChange={(event) => {
                    const newFrom = new Date(event.target.value);
                    if (isValid(newFrom) && isBefore(newFrom, to)) {
                      setFrom(newFrom);
                    }
                  }}
                  required={true}
                  min={format(subYears(to, 6), 'yyyy-MM-dd')}
                  max={format(to, 'yyyy-MM-dd')}
                />
              </div>
              <div className="apl-form-layout-v1 apl-field-v1 apl-display-flex apl-flex-row apl-align-items-center apl-mr-s">
                <label className="apl-field__label apl-mr-s apl-mb-none" htmlFor="date-field">
                  to
                </label>
                <input
                  className="apl-text-input-v1 apl-mb-none"
                  type="date"
                  value={format(to, 'yyyy-MM-dd')}
                  onChange={(event) => {
                    const newTo = new Date(event.target.value);
                    if (isValid(newTo) && isAfter(newTo, from)) {
                      setTo(newTo);
                    }
                  }}
                  required={true}
                  min={format(from, 'yyyy-MM-dd')}
                  max={format(addYears(from, 6), 'yyyy-MM-dd')}
                />
              </div>
            </div>
          </div>
        </Card>
        <Card>
          {isLoading && <div>Loading...</div>}
          {!isLoading && data && (
            <div data-testid="plan calendar">
              <Calendar
                localizer={localizer}
                events={events}
                style={{ height: 750 }}
                views={['month', 'week', 'work_week', 'day']}
              />
            </div>
          )}
        </Card>
      </Page>
    </>
  );
};

function convertApplicableTimePeriodsToEvents(
  chargeRates: Array<ChargeRate> | undefined
): Array<Event> {
  let events: Array<Event> = [];

  if (!chargeRates) {
    return events;
  }

  for (const chargeRate of chargeRates) {
    if (chargeRate && chargeRate.applicableTimePeriods) {
      for (const applicableTimePeriod of chargeRate.applicableTimePeriods) {
        const { start, end } = applicableTimePeriod;

        const newEvent: Event = {
          title: chargeRate.summarisationGroup,
          start: new Date(start),
          end: new Date(end),
        };

        events = [...events, newEvent];
      }
    }
  }

  return events;
}

const locales = {
  'en-NZ': enNZ,
};

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
});

export default PlanCalendar;
