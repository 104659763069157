import { format as dateFormat, formatISO } from 'date-fns';
import { isNil } from 'ramda';
import React, { FunctionComponent, useState } from 'react';
import {
  easi144TimePeriods,
  easi48TimePeriods,
  MonthlySpotDetails,
  MonthSpotPercentage,
} from './easiUtils';
import TextOverlay from '../../form/TextOverlay';
import Alert, { AlertEnum } from '../../layout/Alert';
import { EditButtonGroup } from '../../page/Buttons';
import { Permission } from '../../../auth/getPermissions';

import './EasiChargeGrid.scss';
import '../../common/Table.scss';
import { useListData } from 'list-data/ListDataContext';
import { LabelData } from 'list-data/loss-factor-labels/lossFactorLabelsUtils';
import { Charge, QualifierLink } from '../../../api/openapi/rating-config';

const isNotInt = (value: string) => {
  return (
    isNaN(Number(value)) ||
    !Number.isInteger(Number(value)) ||
    value.indexOf('.') > 0 ||
    Number(value) > 100 ||
    Number(value) < 0
  );
};

const sanitiseGrid = (grid: string[][]): string[][] => {
  for (const [rowIndex, row] of grid.entries()) {
    for (const [colIndex, value] of row.entries()) {
      grid[rowIndex][colIndex] = Number(value).toFixed(4);
    }
  }

  return grid;
};

// designed to ensure there are no invalid values in the grid
const sanitiseSpotPrices = (spotPrices: MonthSpotPercentage): MonthSpotPercentage => {
  Object.keys(spotPrices).forEach((date) => {
    // ensure that the spot percentage is actually a number, and convert empty string to zero
    const numericMonthlySpot = Number(spotPrices[date]);

    spotPrices[date] =
      isNaN(numericMonthlySpot) || isNil(numericMonthlySpot) ? '0' : `${numericMonthlySpot}`;
  });

  return spotPrices;
};

const timePeriodAtIndex = (index: number, structure: string): string => {
  const timePeriods = structure === 'EASI_144' ? easi144TimePeriods : easi48TimePeriods;

  if (index + 1 === timePeriods.length) {
    return `${timePeriods[index]}-24:00`;
  } else {
    return `${timePeriods[index]}-${timePeriods[index + 1]}`;
  }
};

const thickBottomBorder = { borderBottomWidth: '5px' };

const easiInputStyles = (isEdit: boolean) => ({
  height: 20,
  margin: 0,
  minWidth: 92,
  paddingBottom: isEdit ? '0' : 'inherit',
  paddingTop: isEdit ? '0' : 'inherit',
});

interface EditLossesFieldPropsFBAU593 {
  adjustmentToUse: string | undefined;
  setAdjustmentToUse: (val: string) => void;
  labels: LabelData[];
}

const EditLossesField = ({
  adjustmentToUse,
  setAdjustmentToUse,
  labels,
}: EditLossesFieldPropsFBAU593) => {
  return (
    <div className="apl-field-v1 apl-display-flex apl-flex-row apl-ml-s apl-align-items-center">
      <label className="apl-field__label apl-mr-xs apl-mb-none" htmlFor="losses-field">
        Losses
      </label>
      <select
        className="apl-select-v1_0"
        defaultValue={adjustmentToUse}
        id="losses-field"
        name="adjustmentToUse"
        onChange={(e) => setAdjustmentToUse(e.target.value)}
      >
        {labels.map((label: LabelData) => {
          const value = JSON.stringify(label.value);
          return (
            <option key={value} value={value}>
              {label.label}
            </option>
          );
        })}
      </select>
    </div>
  );
};

interface EasiChargeGridProps {
  charge: Charge;
  disableEdit: boolean;
  disableSave: boolean;
  easiGrid: string[][];
  monthlySpotDetails: MonthlySpotDetails;
  qualifier: QualifierLink;
  range: Date[];
  saveFn: (
    grid: string[][],
    qualifier: QualifierLink,
    monthlySpotRates: MonthSpotPercentage,
    adjustmentToUse: Array<string>
  ) => Promise<object>;
  structure: string;
  title: string;
}

const EasiChargeGrid: FunctionComponent<EasiChargeGridProps> = ({
  charge,
  disableEdit,
  disableSave,
  easiGrid,
  monthlySpotDetails,
  qualifier,
  range,
  saveFn,
  structure,
  title,
}) => {
  const [isEdit, setEdit] = useState(false);

  const defaultAdjustmentToUse = charge.adjustmentToUse
    ? JSON.stringify(charge.adjustmentToUse)
    : ['urn:flux:rating:charge:adjustment:none'];
  const [adjustmentToUse, setAdjustmentToUse] = useState(defaultAdjustmentToUse);
  const [rateState, setRateState] = useState(easiGrid);
  const [monthlySpotRates, setMonthlySpotRates] = useState(monthlySpotDetails.monthSpotPercentage);

  const updateSpotForMonth = (newValue: string, date: string) => {
    // spot percentage must be an integer
    if (isNotInt(newValue)) {
      return;
    }

    setMonthlySpotRates({
      ...monthlySpotRates,
      [date]: newValue,
    });
  };

  const cancel = () => {
    setEdit(false);
    setRateState(easiGrid);
    setMonthlySpotRates(monthlySpotDetails.monthSpotPercentage);
  };

  const save = async () => {
    setRateState(sanitiseGrid(rateState));
    setMonthlySpotRates(sanitiseSpotPrices(monthlySpotRates));

    const adjustmentToUseValue = JSON.parse(adjustmentToUse as string) as Array<string>;

    const result = await saveFn(rateState, qualifier, monthlySpotRates, adjustmentToUseValue);

    if (result) {
      setEdit(false);
    }
  };

  const updateCell = (rowIndex: number, colIndex: number, newValue: string) => {
    if (isNaN(Number(newValue))) {
      return;
    }

    const newState = [...rateState];
    newState[rowIndex][colIndex] = newValue;

    setRateState(newState);
  };

  const [lossFactorLabels] = useListData(['LOSS_FACTOR_LABELS']);
  const labels: LabelData[] = lossFactorLabels?.data as LabelData[];

  /**
   * This method is used to determine the text that will be displayed for the "Losses" field.
   * The text will be determined base from the charge.adjustmentToUse value.
   * @returns lossesValueText
   */
  const getLossesFieldText = (): string => {
    let lossesValueText = '';
    const matchingLabel = labels.find((label) =>
      charge.adjustmentToUse?.every((adjustmentToUse) =>
        label.value.includes(adjustmentToUse.toString())
      )
    );
    lossesValueText = matchingLabel?.label ?? '';

    return lossesValueText;
  };
  return (
    <>
      <div>
        {monthlySpotDetails.containsInvalidSpotRates && (
          <Alert type={AlertEnum.WARNING}>
            <span>
              This charge had different spot rates within a month period, the highest has been
              selected. Saving the plan will save the displayed values.
            </span>
          </Alert>
        )}
        <div className="apl-display-flex apl-flex-row apl-justify-content-between apl-align-items-center">
          <div className="apl-display-flex apl-flex-row apl-align-items-center">
            <h3 className="apl-my">{title}</h3>
            {isEdit ? (
              <EditLossesField
                adjustmentToUse={adjustmentToUse as string}
                setAdjustmentToUse={setAdjustmentToUse}
                labels={labels}
              />
            ) : (
              <h3
                className="apl-ml-xs apl-my-none"
                data-testid={`${title.replace(/ /g, '-').toLowerCase()}-losses-value`}
                style={{ fontWeight: 400 }}
              >
                Losses: {getLossesFieldText()}
              </h3>
            )}
          </div>
          {!disableEdit && (
            <EditButtonGroup
              isDisabled={disableSave}
              isEdit={isEdit}
              onCancel={cancel}
              onEdit={() => setEdit(true)}
              onSave={save}
              permission={Permission.PLAN_EDIT}
              testIdPrefix={qualifier?.name?.split(' ').join('_').toUpperCase()}
            />
          )}
        </div>
      </div>
      <div className="card__inner" style={{ overflow: 'hidden' }}>
        <table
          className="apl-table-v1 apl-mb-none table--scrolling"
          style={{ borderRadius: 0 }}
          data-testid={`${qualifier?.name?.split(' ').join('_').toUpperCase()}-table`}
        >
          <thead>
            <tr>
              <th className="sticky-col sticky-col--no-shadow">Hourly periods</th>
              {range.map((date) => (
                <th key={`col-${date.toISOString()}`}>{dateFormat(date, "MMM ''yy")}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="sticky-col" style={{ ...thickBottomBorder }}>
                Spot market proportion
              </td>
              {range.map((date) => {
                const dateAsString = formatISO(date, { representation: 'date' });
                const spotValueForMonth = monthlySpotRates[dateAsString];

                return (
                  <td
                    style={{
                      ...thickBottomBorder,
                    }}
                    key={date.toISOString() + 'spot-cell'}
                  >
                    {isEdit ? (
                      <span style={{ ...easiInputStyles(isEdit) }}>
                        <TextOverlay text="%">
                          <input
                            autoComplete="off"
                            className="month-input apl-text-input-v1 apl-m-none"
                            style={{ width: '100%', height: '100%' }}
                            value={spotValueForMonth}
                            onChange={(e) => updateSpotForMonth(e.target.value, dateAsString)}
                          />
                        </TextOverlay>
                      </span>
                    ) : (
                      <span style={{ ...easiInputStyles(isEdit) }}>{spotValueForMonth} %</span>
                    )}
                  </td>
                );
              })}
            </tr>
            {rateState.map((row, indexRow) => (
              <tr key={indexRow} data-testid={`hourly-period-${indexRow}`}>
                <td className="sticky-col">{timePeriodAtIndex(indexRow, structure)}</td>
                {row.map((cell, indexCell) => (
                  <td key={indexCell} style={easiInputStyles(isEdit)}>
                    {isEdit ? (
                      <div className="apl-flex-row apl-display-flex apl-align-items-center">
                        <input
                          autoComplete="off"
                          className="month-input apl-text-input-v1 apl-m-none"
                          style={{ width: '100%', height: '100%' }}
                          value={cell}
                          onChange={(e) => updateCell(indexRow, indexCell, e.target.value)}
                        />
                      </div>
                    ) : (
                      <span>{cell ? parseFloat(cell).toFixed(4) : '0.0000'}</span>
                    )}{' '}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default EasiChargeGrid;
