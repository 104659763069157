/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AuthenticationRequest } from '../models/AuthenticationRequest';
import type { AuthenticationResponse } from '../models/AuthenticationResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class AuthenticationService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Exchange the OAuth auth_code, or refresh_token for JWT access and refresh tokens
     * @param requestBody Post authentication code, or refresh token to get JWT/Token payload in response
     * @returns AuthenticationResponse Authentication request successful. Exchanged the OAuth auth_code, or refresh_token for JWT access and refresh tokens.
     * @throws ApiError
     */
    public submitAuthentication(
        requestBody: Array<AuthenticationRequest>,
    ): CancelablePromise<AuthenticationResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/authentication',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
