import React, { useEffect, useMemo, useState } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { endOfMonth, format, isValid } from 'date-fns';
import MeteringConsumptionSummary from './MeteringConsumptionSummary';
import { SupplyPeriodSummary, useSupplyPeriodSummary } from '../connectionsApi';
import {
  findMeasurement,
  FlowDirection,
  generateFlowOptions,
} from '../dashboard/MeteringDataComponent';
import Card from '../../layout/Card';
import Page, { PageHeader } from '../../layout/Page';
import { useUsageDetails, useUsageMeters } from '../../telemetry/telemetryUsageApi';
import { copyToClipboard } from '../../../util/helper-func';
import { useExternalBillingPresentation } from 'components/core/coreExternalBillingApi';

import caretRight from '../../../icons/caret-right.svg';
import copyIcon from '../../../icons/copy.svg';
import '../../common/Table.scss';
import { LabelValue } from 'components/layout/ColumnCard';
import { IntervalReading } from 'api/openapi/telemetry/models/IntervalReading';

const dateFormat = 'yyyy-MM-dd';

const MeteringDailySummary = () => {
  const flowOptions = useMemo(() => generateFlowOptions(), []);

  const navigate = useNavigate();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);

  const supplyPeriodId = searchParams.get('supply_period');
  const dateParam = searchParams.get('date');
  const initialDate = searchParams.has('date') ? new Date(dateParam ?? 0) : new Date();
  const initialMeter: string | undefined = searchParams.get('meter') || undefined;
  const initialFlow: FlowDirection = (searchParams.get('flow') as FlowDirection) || 'IN';

  const [meter, setMeter] = useState<string | undefined>(initialMeter);
  const [date, setDate] = useState<Date>(initialDate);
  const [flow, setFlow] = useState<FlowDirection>(initialFlow);

  const { id } = useParams<{ id: string }>();

  const formattedDate = format(date, dateFormat);

  function getReadingQualityDisplayText(readingQuality: string) {
    switch (readingQuality) {
      case 'urn:flux:telemetry:usage:accuracy:usage:actual':
        return 'Final';
      case 'urn:flux:telemetry:usage:accuracy:usage:calculated':
        return 'Estimated';
      case 'urn:flux:telemetry:usage:accuracy:usage:forecast':
        return 'Forecast';
      default:
        return '-';
    }
  }

  function getConnectionFlowDisplayText(isEnergyGoingToConnection: boolean) {
    if (isEnergyGoingToConnection === true) {
      return 'In';
    } else if (isEnergyGoingToConnection === false) {
      return 'Out';
    } else {
      return '-';
    }
  }

  const {
    data: supplyPeriodData,
    isError: supplyPeriodDataError,
    isInitialLoading: supplyPeriodDataLoading,
  } = useSupplyPeriodSummary(id, { enabled: !!id });

  const {
    data: meterData,
    isError: meterDataError,
    isInitialLoading: meterDataLoading,
  } = useUsageMeters({
    startsAt: formattedDate,
    endsAt: formattedDate,
    connectionId: id,
  });

  const {
    data: telemetryData,
    isError: telemetryDataError,
    isInitialLoading: telemetryDataLoading,
  } = useUsageDetails(
    {
      connectionId: id,
      endsAt: formattedDate,
      startsAt: formattedDate,
      isEnergyGoingToConnection: flow === 'IN',
      meterId: meter,
    },
    {
      enabled: !!id && !!meter,
    }
  );

  // find the supply period base from the supply_period param and use it to limit
  // the date options available
  const supplyPeriod: SupplyPeriodSummary =
    !supplyPeriodDataLoading &&
    supplyPeriodData &&
    supplyPeriodId &&
    supplyPeriodData?.supplyPeriodSummaries?.find(
      (period: SupplyPeriodSummary) => period.id === supplyPeriodId
    );

  // pull the contracted party name from core external billing
  const periodAgreementId = supplyPeriod?.externalSupplyAgreementId;
  const { data: externalData } = useExternalBillingPresentation(periodAgreementId ?? '', {
    enabled: !!periodAgreementId,
  });
  const contractedParty = externalData?.parties?.owner?.name ?? '-';
  const contractedPartyId =
    externalData && externalData?.parties?.owner?.id ? externalData.parties.owner.id : undefined;

  useEffect(() => {
    // update the URL whenever meter, date or flow changes
    const params = new URLSearchParams();

    meter && params.set('meter', meter);
    params.set('date', formattedDate);
    params.set('flow', flow);
    if (supplyPeriodId) {
      params.set('supply_period', supplyPeriodId);
    }

    navigate({ search: params.toString() }, { replace: true });
  }, [meter, formattedDate, flow]);

  useEffect(() => {
    // if the meter was not passed in the query, default to the first meter in the list
    if (meter === undefined && meterData?.meterIds?.[0]) {
      setMeter(meterData.meterIds[0]);
    }
  }, [meterData]);

  const connectionId = supplyPeriodData?.connectionId ?? id;

  const meterOptions = meterData?.meterIds.length
    ? meterData.meterIds.map((meter: string) => ({
        label: meter,
        value: meter,
      }))
    : [];

  const totals =
    flow === 'IN' ? telemetryData?.totalsToConnection : telemetryData?.totalsFromConnection;

  let monthlyMeteringLink = `/connections/${id}/metering/month?date=${formattedDate}&flow=${flow}`;
  if (supplyPeriodId) {
    monthlyMeteringLink += `&supply_period=${supplyPeriodId}`;
  }

  return (
    <>
      <PageHeader
        title={() => (
          <>
            <Link
              className="page__title-link"
              to={`/connections/${id}/supply-periods/${supplyPeriodId}/contracted-parties/${contractedPartyId}`}
            >
              {connectionId}
            </Link>
            <img src={caretRight} alt="" />
            <Link className="page__title-link" to={monthlyMeteringLink}>
              Monthly metering data
            </Link>
            <img src={caretRight} alt="" />
            Daily metering data
          </>
        )}
      />
      <Page>
        <Card>
          <div className="apl-display-flex apl-flex-row">
            <div className="apl-field-v1 apl-display-flex apl-flex-column apl-align-items-start">
              <label className="apl-field__label apl-mr" htmlFor="contracted-party-field">
                Contracted party
              </label>
              <p className="apl-my-none apl-h3" id="contracted-party-field">
                {contractedParty}
              </p>
            </div>
          </div>
        </Card>
        <Card>
          <div className="card__inner apl-display-flex apl-flex-row apl-align-items-center apl-justify-content-between apl-px">
            <div className="apl-display-flex apl-flex-row apl-align-items-center">
              <div className="apl-field-v1 apl-display-flex apl-flex-row apl-align-items-center apl-mr-s">
                <label className="apl-field__label apl-mr-s apl-mb-none" htmlFor="meter-field">
                  Show data for meter
                </label>
                <select
                  className="apl-select-v1_0 apl-mb-none"
                  data-testid="meter field"
                  id="meter-field"
                  name="meter"
                  onChange={(e) => setMeter(e.target.value)}
                  value={meter}
                >
                  {meterOptions.map((option: LabelValue) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
              </div>
              <div className="apl-form-layout-v1 apl-field-v1 apl-display-flex apl-flex-row apl-align-items-center apl-mr-s">
                <label className="apl-field__label apl-mr-s apl-mb-none" htmlFor="date-field">
                  for
                </label>
                <input
                  className="apl-text-input-v1 apl-mb-none"
                  data-testid="date field"
                  id="date-field"
                  name="date"
                  onChange={(event) => {
                    const updated = new Date(event.target.value);

                    if (isValid(updated)) {
                      setDate(updated);
                    }
                  }}
                  type="date"
                  min={supplyPeriod?.startDate}
                  max={supplyPeriod?.endDate ?? format(endOfMonth(new Date()), dateFormat)}
                  value={formattedDate}
                />
              </div>
              <div className="apl-field-v1 apl-display-flex apl-flex-row apl-align-items-center apl-mr-s">
                <label className="apl-field__label apl-mr-s apl-mb-none" htmlFor="flow-field">
                  Flow
                </label>
                <select
                  className="apl-select-v1_0"
                  data-testid="flow field"
                  id="flow-field"
                  name="flow"
                  onChange={(e) => setFlow(e.target.value as FlowDirection)}
                  value={flow}
                >
                  {flowOptions.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
              </div>
              <p className="apl-my-none apl-display-flex apl-flex-row">
                <span style={{ fontWeight: 600 }}>Intervals:</span>
                &nbsp;
                {telemetryData?.intervalReadings?.length ?? 0}
              </p>
            </div>
          </div>
        </Card>
        <MeteringConsumptionSummary
          flow={flow}
          measurements={totals?.totalValues}
          title="Daily total consumption"
        />
        <Card className="card--square-top apl-p-none">
          <div className="table--scrolling">
            <table
              className="apl-table-v1 table--nowrap apl-mb-none"
              style={{
                minWidth: '100%',
              }}
            >
              <thead>
                <tr>
                  <th
                    className="apl-text-left sticky-col sticky-col--no-shadow"
                    rowSpan={2}
                    style={{ verticalAlign: 'bottom' }}
                  >
                    Time
                  </th>
                  <th className="apl-text-left" rowSpan={2} style={{ verticalAlign: 'bottom' }}>
                    Interval
                  </th>
                  <th className="apl-text-right" colSpan={3}>
                    Metered
                  </th>

                  <th className="apl-text-right" colSpan={3}>
                    Loss adjusted
                  </th>
                  <th className="apl-text-left" rowSpan={2} style={{ verticalAlign: 'bottom' }}>
                    Quality
                  </th>
                  <th className="apl-text-left" rowSpan={2} style={{ verticalAlign: 'bottom' }}>
                    Power factor
                  </th>

                  <th className="apl-text-left" rowSpan={2} style={{ verticalAlign: 'bottom' }}>
                    Flow
                  </th>
                  <th className="apl-text-left" rowSpan={2} style={{ verticalAlign: 'bottom' }}>
                    Source
                  </th>
                  <th className="apl-text-left" rowSpan={2} style={{ verticalAlign: 'bottom' }}>
                    Actions
                  </th>
                </tr>
                <tr>
                  <th className="apl-text-right" style={{ verticalAlign: 'bottom' }}>
                    kWh
                  </th>
                  <th className="apl-text-right" style={{ verticalAlign: 'bottom' }}>
                    kVAh
                  </th>
                  <th className="apl-text-right" style={{ verticalAlign: 'bottom' }}>
                    kVArh
                  </th>
                  <th className="apl-text-right" style={{ verticalAlign: 'bottom' }}>
                    kWh
                  </th>
                  <th className="apl-text-right" style={{ verticalAlign: 'bottom' }}>
                    kVAh
                  </th>
                  <th className="apl-text-right" style={{ verticalAlign: 'bottom' }}>
                    kVArh
                  </th>
                </tr>
              </thead>
              {(supplyPeriodDataLoading || telemetryDataLoading || meterDataLoading) && (
                <tbody>
                  <tr>
                    <td>Loading...</td>
                  </tr>
                </tbody>
              )}
              {(supplyPeriodDataError || telemetryDataError || meterDataError) && (
                <tbody>
                  <tr>
                    <td>Sorry, there was an error.</td>
                  </tr>
                </tbody>
              )}
              {telemetryData && (
                <tbody>
                  {telemetryData?.intervalReadings
                    ?.sort(
                      (readingA: IntervalReading, readingB: IntervalReading) =>
                        (readingB.interval_number as number) - (readingA.interval_number as number)
                    )
                    .map((reading: IntervalReading) => {
                      const {
                        filename,
                        interval_end: end,
                        interval_number: number,
                        interval_start: start,
                        is_energy_going_to_connection: isEnergyGoingToConnection,
                        measurements,
                        reading_quality: quality,
                      } = reading;

                      return (
                        <tr key={`interval-${number}`}>
                          <td className="apl-text-left sticky-col">{`${start} - ${end}`}</td>
                          <td className="apl-text-left">{number}</td>

                          <td className="apl-text-right">
                            {measurements ? `${findMeasurement('kWh', measurements)} kWh` : '-'}
                          </td>
                          <td className="apl-text-right">
                            {measurements ? `${findMeasurement('kVAh', measurements)} kVAh` : '-'}
                          </td>
                          <td className="apl-text-right">
                            {measurements ? `${findMeasurement('kVArh', measurements)} kVArh` : '-'}
                          </td>
                          <td className="apl-text-right">
                            {measurements
                              ? `${findMeasurement('loss_adjusted_kWh', measurements)} kWh`
                              : '-'}
                          </td>
                          <td className="apl-text-right">
                            {measurements
                              ? `${findMeasurement('loss_adjusted_kVAh', measurements)} kVAh`
                              : '-'}
                          </td>
                          <td className="apl-text-right">
                            {measurements
                              ? `${findMeasurement('loss_adjusted_kVArh', measurements)} kVArh`
                              : '-'}
                          </td>
                          <td className="apl-text-left">
                            {getReadingQualityDisplayText(quality as string)}
                          </td>
                          <td className="apl-text-left">
                            {measurements ? findMeasurement('power_factor', measurements) : '-'}
                          </td>
                          <td className="apl-text-left">
                            {getConnectionFlowDisplayText(isEnergyGoingToConnection as boolean)}
                          </td>
                          <td className="apl-text-left" data-testid="row-1-source">
                            {filename ?? '-'}
                          </td>
                          <td>
                            {filename && (
                              <button
                                className="apl-button-v1 apl-display-flex"
                                onClick={() => copyToClipboard(filename)}
                              >
                                <img className="apl-mr-xs" alt="copy icon" src={copyIcon} /> Copy
                                source name
                              </button>
                            )}
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              )}
            </table>
          </div>
        </Card>
      </Page>
    </>
  );
};

export default MeteringDailySummary;
