/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type RepublishIntervalDataReceivedCommand = {
    _type: RepublishIntervalDataReceivedCommand._type;
    _version: string;
    _publishedAt?: string;
    _correlationId?: string;
    /**
     * The connection id to republish interval-data-received events for. If null then republish all.
     */
    connectionId?: string;
    /**
     * Only republish interval data with intervals that start on or after this date
     */
    from?: string;
    /**
     * Only republish interval data with intervals that end before or on this date
     */
    to?: string;
};

export namespace RepublishIntervalDataReceivedCommand {

    export enum _type {
        URN_FLUX_TELEMETRY_COMMAND_REPUBLISH_INTERVAL_DATA_RECEIVED = 'urn:flux:telemetry:command:republish-interval-data-received',
    }


}

