/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type ConnectionMessage = {
    /**
     * 2.0.0
     */
    _version: string;
    _type: ConnectionMessage._type;
    _publishedAt: string;
    /**
     * An system generated identifier used for tracing the event as it flows through the system
     */
    _correlationId?: string;
    id: string;
    /**
     * The external id for the connection. In NZ this will be the ICP
     */
    externalId: string;
    /**
     * A four letter code representing the network company
     */
    networkCode?: string;
    /**
     * A list of external reference codes that signifies the pricing that applies to the connection config. In NZ this will be the price category code.
     */
    tariffCodes: Array<{
        /**
         * The earliest date/time this tariff becomes valid
         */
        validFrom: string;
        /**
         * The earliest date/time this tariff ceases to be valid
         */
        validTo: string;
        type: string;
        code: string;
    }>;
    /**
     * How the connection is configured to measure and consume energy
     */
    connectionTypes?: Array<{
        /**
         * The earliest date/time this connection type valid
         */
        validFrom: string;
        /**
         * The earliest date/time this connection type ceases to be valid
         */
        validTo: string;
        types: Array<string>;
    }>;
    jurisdictions?: Array<{
        /**
         * Jurisdiction code e.g. `NSW`
         */
        code: string;
        /**
         * The earliest date/time this jurisdiction becomes valid
         */
        validFrom: string;
        /**
         * The earliest date/time this jurisdiction ceases to be valid
         */
        validTo: string;
    }>;
};

export namespace ConnectionMessage {

    export enum _type {
        NETWORK_CONNECTION_UPDATED = 'network.connection.updated',
    }


}

