import React from 'react';
import ReactDOM from 'react-dom/client';
import './util/initRaygun';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import { AppUpdateProvider } from './components/update/AppUpdateProvider';
import { ConfigContextProvider } from './config/ConfigContext';
import App from './App';
import './index.css';
import './i18n';
import getConfig from 'config/getConfig';
import '@elastic/eui/dist/eui_theme_light.css';
import { EuiProvider } from '@elastic/eui';
import { isLocal } from 'environment';

(async () => {
  const config = getConfig();

  const LDProvider = await asyncWithLDProvider({
    // Sandbox Client ID
    clientSideID: process.env.REACT_APP_LAUNCH_DARKLY_CLIENT_ID || '',
    options: { sendEventsOnlyForVariation: true, ...(isLocal() && { sendEvents: false }) },
    reactOptions: {
      useCamelCaseFlagKeys: true,
    },
    user: {
      key: config.ldUser || process.env.REACT_APP_ENVIRONMENT || 'auth',
      custom: { client: config.client || '' },
    },
  });

  const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

  root.render(
    <React.StrictMode>
      <LDProvider>
        <ConfigContextProvider>
          <AppUpdateProvider>
            <EuiProvider colorMode="light">
              <App />
            </EuiProvider>
          </AppUpdateProvider>
        </ConfigContextProvider>
      </LDProvider>
    </React.StrictMode>
  );
})();
