import { handleRequest, ratingConfigClient } from 'api';
import { ListData } from 'api/openapi/rating-config';

const getListData = async (listNames?: string[]) => {
  const data = await handleRequest(ratingConfigClient.listData.getListData(listNames));

  return data;
};

export type { ListData };
export { getListData };
