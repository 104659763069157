import cx from 'classnames';
import { isNil } from 'ramda';
import React, { FunctionComponent, useState } from 'react';
import ReactModal from 'react-modal';
import { useParams } from 'react-router-dom';
import { Permission } from '../../auth/getPermissions';
import { capitaliseFirstText, formatDateForDisplay } from '../../util/helper-func';
import AuthorizedAction from '../common/AuthorizedAction';
import ColumnCard, { LabelValue } from '../layout/ColumnCard';
import { modalStyles } from '../layout/Modal';
import Page, { ErrorPage, LoadingPage, PageHeader } from '../layout/Page';
import { Operator, QualifierDetailsType, timeTypeDisplayMap, useQualifier } from './qualifiersApi';
import UpdateQualifier from './UpdateQualifier';
import { Range } from '../../api/openapi/rating-config';

const processData = (qualifier: QualifierDetailsType): LabelValue[] => {
  if (isNil(qualifier)) {
    return [];
  }

  const qualifierType = (qualifier?.expressionList?.[0] as Range).type;

  const detailList = [
    {
      label: 'Qualifier name',
      value: qualifier.name,
    },
    {
      label: 'Provider',
      value: qualifier.providerName ? qualifier.providerName : 'All',
    },
    {
      label: 'Type',
      value: qualifierType !== null ? timeTypeDisplayMap[qualifierType] : '',
    },
    {
      label: 'Start date',
      value: formatDateForDisplay(qualifier.startDate),
    },
    {
      label: 'End date',
      value: formatDateForDisplay(qualifier.endDate),
    },
  ];

  if (qualifierType !== 'WORKING_DAY_RANGE' && qualifierType !== 'NON_WORKING_DAY_RANGE') {
    (qualifier.expressionList as Array<Range | Operator>)
      .filter((f) => (f as unknown as Operator).operatorType !== 'UNION')
      .map((i) => {
        detailList.push({
          label: 'Time Range',
          value:
            capitaliseFirstText((i as unknown as Range).from) +
            ' - ' +
            capitaliseFirstText((i as unknown as Range).to),
        });
      });
  }

  return detailList as LabelValue[];
};

const QualifierDetails: FunctionComponent = () => {
  const { qualifierId } = useParams<{ qualifierId: string }>();
  const [isModalOpen, setModalOpen] = useState(false);

  const {
    isInitialLoading: isLoading,
    isError,
    data,
  } = useQualifier(qualifierId, { enabled: !!qualifierId });

  const open = () => setModalOpen(true);
  const close = () => setModalOpen(false);

  if (isLoading) {
    return <LoadingPage />;
  }

  if (isError) {
    return <ErrorPage />;
  }

  return (
    <>
      <PageHeader showBack={true} title="Qualifier details">
        <AuthorizedAction
          // temp fix: until feature flags can be used to toggle this
          isDisabled={true}
          onClick={open}
          permission={Permission.QUALIFIERS_EDIT}
          testId="qualifier-edit-button"
        >
          Edit
        </AuthorizedAction>
      </PageHeader>
      <Page className="apl-display-flex apl-flex-row">
        <div
          className={cx('third_xl', 'apl-display-flex', 'apl-flex-column', 'apl-align-items-start')}
        >
          <ColumnCard content={processData(data)} id="qualifier-card" />
        </div>
        <ReactModal
          isOpen={isModalOpen}
          onRequestClose={close}
          shouldCloseOnEsc={true}
          shouldCloseOnOverlayClick={false}
          style={modalStyles}
        >
          <UpdateQualifier close={close} qualifier={data} qualifierId={qualifierId} />
        </ReactModal>
      </Page>
    </>
  );
};

export default QualifierDetails;
