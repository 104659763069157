import React, { createContext, FunctionComponent, useContext, useEffect, useState } from 'react';
import { getListData, ListData } from './getListData';

const ListDataContext = createContext<ListData[]>([]);

ListDataContext.displayName = 'ListDataContext';

interface ListDataContextProviderProps {
  children?: React.ReactNode;
}

const ListDataContextProvider: FunctionComponent<ListDataContextProviderProps> = ({
  children,
}: ListDataContextProviderProps) => {
  const [listData, setListData] = useState<ListData[]>([]);

  useEffect(() => {
    getListData()
      .then((data) => {
        setListData(data);
      })
      .catch((error) => {
        console.error(new Error(`Error while fetching list data: ${error}`));
      });
  }, []);

  return <ListDataContext.Provider value={listData}>{children}</ListDataContext.Provider>;
};

const useListData = (listNames?: string[]) => {
  const context = useContext<ListData[]>(ListDataContext);
  if (context === undefined) {
    throw Error('list data context must be used with list data provider');
  }
  if (listNames && listNames.length > 0) {
    return [...context.filter((listData) => listNames.includes(listData.name))].sort(
      (listA, listB) => listNames.indexOf(listA.name) - listNames.indexOf(listB.name)
    );
  }

  return context;
};

export { ListDataContextProvider, useListData };
