import { BarExtendedDatum, ResponsiveBar } from '@nivo/bar';
import React, { FunctionComponent } from 'react';

interface ConnectionsMissingPlanGraphProps {
  data: Record<string, unknown>[];
  handleOnClick: (data: BarExtendedDatum) => void;
}

const calculateMax = (data: Record<string, unknown>[]): number => {
  // The highest number in the series multiplied by 1.2 to give a bit of head room
  const highest = Math.max(
    ...data.map(function (o) {
      return o.y as number;
    })
  );
  // Add 10% and round up to the nearest 100. This makes the graph more pleasing to look at.
  const max = Math.ceil((highest * 1.1) / 100) * 100;
  // If nothing else, use 60 as the lowest y value. This was the previous default.
  return Math.max(60, max);
};

const calculateTickValues = (max: number) => {
  return [0, Math.floor(max * 0.25), Math.floor(max * 0.5), Math.floor(max * 0.75), max];
};

const ConnectionsMissingPlanGraph: FunctionComponent<ConnectionsMissingPlanGraphProps> = ({
  data,
  handleOnClick,
}) => {
  // graph config
  const max = calculateMax(data);
  const graphTickValues = calculateTickValues(max);
  const baseColor = 'rgba(11, 122, 174, 0.8)';
  const hoverColor = 'rgba(11, 122, 174, 1)';

  return (
    <ResponsiveBar
      axisBottom={{
        tickPadding: 7,
        tickSize: 0,
      }}
      axisLeft={{
        tickPadding: 10,
        tickSize: 0,
        tickValues: graphTickValues,
      }}
      colors={baseColor}
      data={data}
      enableLabel={false}
      gridYValues={graphTickValues}
      indexBy="x"
      isInteractive={false}
      keys={['y']}
      margin={{
        bottom: 30,
        left: 45,
        right: 5,
        top: 5,
      }}
      maxValue={max}
      onClick={(data) => handleOnClick(data)}
      onMouseEnter={(data, event) => {
        event.currentTarget.style.cursor = 'pointer';
        event.currentTarget.style.fill = hoverColor;
      }}
      onMouseLeave={(data, event) => {
        event.currentTarget.style.fill = baseColor;
      }}
    />
  );
};

export default ConnectionsMissingPlanGraph;
