/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type RecalculatePricingPlanRequest = {
    _correlationId?: string;
    _type?: RecalculatePricingPlanRequest._type;
    _version?: RecalculatePricingPlanRequest._version;
    _publishedAt?: string;
    supplyPeriodId?: string;
    requestedBy?: string;
};

export namespace RecalculatePricingPlanRequest {

    export enum _type {
        URN_FLUX_RATING_CONFIG_REPORT_RECALCULATE_PRICING_GAP = 'urn:flux:rating:config:report:recalculate-pricing-gap',
    }

    export enum _version {
        _1_0_0 = '1.0.0',
    }


}

