import React, { FunctionComponent } from 'react';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { isNil } from 'ramda';
import { array, date, object, string } from 'yup';
import AuthorizedAction from '../common/AuthorizedAction';
import FluxDatePicker from '../form/DatePicker';
import FieldWrapper, { FieldWidth, inputClassNameBuilder } from '../form/FieldWrapper';
import Alert, { AlertEnum } from '../layout/Alert';
import { ErrorText } from '../layout/Errors';
import { useProviders } from '../providers/providersApi';
import { ErrorModel } from '../../api/ErrorDisplay';
import { Permission } from '../../auth/getPermissions';
import { CreateObservanceRequest, Observance, ObservanceType } from './observancesApi';
import ProviderMultiSelect from '../providers/ProviderMultiSelect';

const holidaySchema = object().shape({
  holidayName: string().label('Holiday').trim().required().max(100),
  location: string().when('observanceType', {
    is: ObservanceType.LOCALHOLIDAY,
    then: string().label('Location').required().max(100),
    otherwise: string(),
  }),
  providers: array().when('observanceType', {
    is: ObservanceType.LOCALHOLIDAY,
    then: array().label('Providers').required().min(1),
    otherwise: array(),
  }),
  holidayDate: date()
    .typeError('Must be a valid date')
    .max(new Date('9999-12-31'))
    .label('Actual date')
    .required(),
  observedDate: date()
    .typeError('Must be a valid date')
    .max(new Date('9999-12-31'))
    .label('Observed date')
    .required(),
});

interface HolidayFormProps {
  close: () => void;
  error?: ErrorModel;
  holiday?: Observance;
  submitFunction: any;
}

const HolidayForm: FunctionComponent<HolidayFormProps> = ({
  close,
  error,
  holiday,
  submitFunction,
}) => {
  const isEdit = !isNil(holiday);
  const {
    data: providers,
    isError: providerError,
    isInitialLoading: loadingProviders,
  } = useProviders();

  if (providerError) {
    return <p>Error</p>;
  }

  if (loadingProviders) {
    return <p data-testid="loading">Loading</p>;
  }

  return (
    <Formik
      initialValues={{
        holidayName: isEdit ? `${holiday?.name}` : '',
        observanceType: isEdit ? `${holiday?.observanceType}` : ObservanceType.NATIONALHOLIDAY,
        location: isEdit && holiday?.location ? `${holiday.location}` : '',
        holidayDate: isEdit ? `${holiday?.actualDate}` : '',
        observedDate: isEdit ? `${holiday?.observedDate}` : '',
        providers:
          isEdit && holiday.providers && holiday.providers.length > 0
            ? holiday.providers.map((provider) => provider.id)
            : [],
      }}
      onSubmit={(holidayForm) => {
        const observanceBody: CreateObservanceRequest = {
          name: holidayForm.holidayName,
          observanceType: holidayForm.observanceType as ObservanceType,
          location: holidayForm.location,
          providerIds: holidayForm.providers,
          actualDate: holidayForm.holidayDate,
          observedDate: holidayForm.observedDate,
        };

        return submitFunction(observanceBody);
      }}
      validationSchema={holidaySchema}
    >
      {({ errors, touched, dirty, isSubmitting, isValid, values }) => (
        <Form className="apl-form-layout-v1">
          {error && (
            <Alert type={AlertEnum.DANGER}>
              {isEdit ? 'Could not update holiday' : 'Could not create holiday'}
            </Alert>
          )}
          <FieldWrapper htmlFor="holiday-name" label="Holiday" fieldWidth={FieldWidth.FULL}>
            <Field
              autoComplete="off"
              data-testid="holiday-name-field"
              name="holidayName"
              id="holiday-name"
              className={inputClassNameBuilder('holidayName', errors, touched)}
            />
            <ErrorMessage component={ErrorText} name="holidayName" />
          </FieldWrapper>

          <FieldWrapper
            htmlFor="holiday-date-field"
            label="Actual date"
            fieldWidth={FieldWidth.FULL}
          >
            <Field
              autoComplete="off"
              data-testid="holiday-date-field"
              name="holidayDate"
              id="holiday-date-field"
              className={inputClassNameBuilder('holidayDate', errors, touched)}
              component={FluxDatePicker}
            />
            <ErrorMessage component={ErrorText} name="holidayDate" />
          </FieldWrapper>

          <FieldWrapper
            htmlFor="observed-date-field"
            label="Observed date"
            fieldWidth={FieldWidth.FULL}
          >
            <Field
              autoComplete="off"
              data-testid="observed-date-field"
              name="observedDate"
              id="observed-date-field"
              component={FluxDatePicker}
              className={inputClassNameBuilder('observedDate', errors, touched)}
            />
            <ErrorMessage component={ErrorText} name="observedDate" />
          </FieldWrapper>

          <FieldWrapper htmlFor="observance-type" label="Type" fieldWidth={FieldWidth.FULL}>
            <div className="apl-radio-v1_0" id="observance-type">
              <Field
                className="apl-radio__input"
                data-testid="observance-type-national"
                id="observance-type-national"
                name="observanceType"
                type="radio"
                value={ObservanceType.NATIONALHOLIDAY}
              />
              <label className="apl-radio__label" htmlFor="observance-type-national">
                National
              </label>
              <Field
                className="apl-radio__input"
                data-testid="observance-type-local"
                id="observance-type-local"
                name="observanceType"
                type="radio"
                value={ObservanceType.LOCALHOLIDAY}
              />
              <label className="apl-radio__label" htmlFor="observance-type-local">
                Local
              </label>
            </div>
          </FieldWrapper>

          <FieldWrapper htmlFor="location" label="Location" fieldWidth={FieldWidth.FULL}>
            <Field
              autoComplete="off"
              className={inputClassNameBuilder('location', errors, touched)}
              data-testid="location field"
              disabled={values['observanceType'] !== ObservanceType.LOCALHOLIDAY}
              id="location"
              name="location"
            />
            <ErrorMessage component={ErrorText} name="location" />
          </FieldWrapper>

          <FieldWrapper htmlFor="providers" label="Providers" fieldWidth={FieldWidth.FULL}>
            <Field
              component={ProviderMultiSelect}
              defaultProviders={holiday?.providers}
              disabled={values['observanceType'] !== ObservanceType.LOCALHOLIDAY}
              id="providers"
              name="providers"
              providers={providers}
            />
            <ErrorMessage component={ErrorText} name="providers" />
          </FieldWrapper>

          <div>
            <p style={{ color: 'grey' }}>
              It may take three months before this change is automatically synced to the Gorilla
              rating engine and affects rating calculations. Please contact Flux support if the new
              or changed holiday is less than three months in the future.
            </p>
          </div>
          <div className="apl-display-flex apl-justify-content-end">
            <button
              data-testid="cancel-button"
              className="apl-button-v1"
              onClick={close}
              type="button"
            >
              Cancel
            </button>
            <AuthorizedAction
              extraClasses="is-primary"
              isDisabled={isSubmitting || !isValid || (!dirty && isEdit)}
              permission={Permission.OBSERVANCE_EDIT}
              testId="add-holiday-button"
              type="submit"
            >
              Save
            </AuthorizedAction>
          </div>
        </Form>
      )}
    </Formik>
  );
};
export default HolidayForm;
