/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum ChargeTag {
    ENERGY = 'ENERGY',
    NETWORK = 'NETWORK',
    LEVY = 'LEVY',
    EQUIPMENT = 'EQUIPMENT',
    ENERGYFEE = 'ENERGYFEE',
}
