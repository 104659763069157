import React from 'react';
import { useLocation, useParams } from 'react-router-dom';
import PlanDetails from './PlanDetails';
import { useConnectionDetails } from '../connections/connectionsApi';
import { ErrorPage, LoadingPage } from '../layout/Page';
import { UsageChargeFiltersContextProvider } from '../usage-charge-filters/UsageChargeFiltersContext';

const ConnectionPlanDetails = () => {
  const { id } = useParams<{ id: string }>();

  const { search } = useLocation();
  const query = search ? new URLSearchParams(search) : null;
  const spid = query && query.get('spid');
  const cpid = query && query.get('cpid');

  const {
    data,
    isError,
    isInitialLoading: isLoading,
  } = useConnectionDetails(id, { enabled: !!id });

  if (isError) {
    return <ErrorPage />;
  }

  if (isLoading) {
    return <LoadingPage />;
  }

  return (
    data && (
      <UsageChargeFiltersContextProvider>
        <PlanDetails
          connection={data[0]}
          supplyPeriodId={spid ? spid : undefined}
          contractedPartyId={cpid ? cpid : undefined}
        />
      </UsageChargeFiltersContextProvider>
    )
  );
};

export default ConnectionPlanDetails;
