import { handleRequest, telemetryClient } from 'api';

const getUsageChargeFilters = async (filterName?: string, limit = 100, offset?: number) => {
  const data = await handleRequest(
    telemetryClient.usageChargeFilters.pageUsageChargeFilters(filterName, limit, offset)
  );

  return data;
};

export { getUsageChargeFilters };
