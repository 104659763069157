/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type SupplyPeriodEvent = {
    _correlationId: string;
    _publishedAt: string;
    _type: SupplyPeriodEvent._type;
    _version: string;
    /**
     * Supply Period Id
     */
    id: string;
    /**
     * The id of supply agreement or consumer plan it references
     */
    externalSupplyAgreementId?: string;
    /**
     * The connection id
     */
    connectionId: string;
    contractedPartyId?: string;
    consumerId?: string;
    ownerId?: string;
    /**
     * The start date of the supply period
     */
    startDate: string;
    /**
     * The end date of supply period, or null if it is open-ended
     */
    endDate?: string;
    status: SupplyPeriodEvent.status;
    /**
     * Plans linked in supply period
     */
    planLinks?: Array<{
        /**
         * Plan Link id
         */
        id?: string;
        pricingPlanId?: string;
        startDate?: string;
        endDate?: string;
    }>;
};

export namespace SupplyPeriodEvent {

    export enum _type {
        RATING_CONFIGURATION_SUPPLY_PERIOD_CREATED = 'rating.configuration.supply-period.created',
        RATING_CONFIGURATION_SUPPLY_PERIOD_UPDATED = 'rating.configuration.supply-period.updated',
        RATING_CONFIGURATION_SUPPLY_PERIOD_DELETED = 'rating.configuration.supply-period.deleted',
    }

    export enum status {
        URN_FLUX_RATING_CONFIGURATION_SUPPLY_PERIOD_STATUS_VALID = 'urn:flux:rating:configuration:supply-period:status:valid',
        URN_FLUX_RATING_CONFIGURATION_SUPPLY_PERIOD_STATUS_CANCELLED = 'urn:flux:rating:configuration:supply-period:status:cancelled',
    }


}

