import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import apiClient from '../../api/apiClient';
import { Provider } from '../providers/providersApi';

const observancesBaseEndpoint = '/observances';

export interface Observance {
  id: string;
  name: string;
  observanceType: ObservanceType;
  actualDate: string;
  observedDate: string;
  location: string;
  providerIds?: string[];
}

export enum ObservanceType {
  NATIONALHOLIDAY = 'NATIONAL_HOLIDAY',
  LOCALHOLIDAY = 'LOCAL_HOLIDAY',
  OBSERVANCE = 'OBSERVANCE',
}

export function observanceTypeMapper(observanceType: ObservanceType): string {
  switch (observanceType) {
    case ObservanceType.NATIONALHOLIDAY:
      return 'National';
    case ObservanceType.LOCALHOLIDAY:
      return 'Local';
    case ObservanceType.OBSERVANCE:
      return 'Observance';
    default:
      throw Error('Observance type not supported');
  }
}

export const ObservanceLocationDisplayMap = {
  NATIONAL: 'National',
};

interface AllObservancesParams {
  from: string;
  to: string;
  provider: string;
}

export const useAllObservances = (params: AllObservancesParams) => {
  const { from, to } = params;

  return useQuery(['observances', from, to], async () => observancesFn(params));
};

const observancesFn = (params: AllObservancesParams) => {
  const query = new URLSearchParams();

  (Object.keys(params) as Array<keyof AllObservancesParams>).forEach((key) => {
    if (params[key]) {
      query.set(key, `${params[key]}`);
    }
  });

  return apiClient().get(`${observancesBaseEndpoint}?${query.toString()}`);
};

export interface Observance {
  name: string;
  observanceType: ObservanceType;
  actualDate: string;
  observedDate: string;
  location: string;
  providers?: Provider[];
}

interface AddObservanceArgs {
  data: Observance;
}

export interface CreateObservanceRequest {
  name: string;
  observanceType: ObservanceType;
  actualDate: string;
  observedDate: string;
  location?: string;
  providerIds?: string[];
}

export const useAddObservance = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ data }: AddObservanceArgs) => {
      return apiClient().post(observancesBaseEndpoint, JSON.stringify(data));
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['observances']);
      },
    }
  );
};

interface UpdateObservanceArgs {
  observanceBody: UpdateObservanceRequest;
  observanceId: string;
}

export interface UpdateObservanceRequest extends CreateObservanceRequest {
  id: string;
}

export const useUpdateObservance = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ observanceBody, observanceId }: UpdateObservanceArgs) => {
      return apiClient().put(
        `/${observancesBaseEndpoint}/${observanceId}`,
        JSON.stringify(observanceBody)
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['observances']);
      },
    }
  );
};

interface DeleteObservanceArgs {
  observanceId: string;
}

export const useDeleteObservance = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ observanceId }: DeleteObservanceArgs) => {
      const url = `${observancesBaseEndpoint}/${observanceId}`;

      return apiClient().del(url, '');
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['observances']);
      },
    }
  );
};
