/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreditNote } from '../models/CreditNote';
import type { Error } from '../models/Error';
import type { TestCreditNote } from '../models/TestCreditNote';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class TestCreditNoteService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @param requestBody
     * @returns CreditNote OK
     * @returns Error Error
     * @throws ApiError
     */
    public testCreateCreditNote(
        requestBody: TestCreditNote,
    ): CancelablePromise<CreditNote | Error> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/test/credit-notes',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
