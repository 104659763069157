import { useQuery } from '@tanstack/react-query';
import { InvoiceDataTotal, LineItem } from './billingInvoicesApi';
import { Period } from './billingReadinessApi';
import apiClient from '../../api/apiClient';

const baseEndpoint = '/billing/charges';

export interface ConnectionCharges {
  period: Period;
  lineItems: LineItem[];
  totals: InvoiceDataTotal;
}

export const useConnectionCharges = (id: string | undefined, options?: Record<string, unknown>) => {
  const data = useQuery(
    ['connection-charges'],
    async () => {
      return apiClient('billing').get(`${baseEndpoint}/${id}`);
    },
    { ...options }
  );
  return data;
};
