import React from 'react';
import { ErrorMessage, Field } from 'formik';
import { useListData } from 'list-data/ListDataContext';
import { LabelData } from 'list-data/loss-factor-labels/lossFactorLabelsUtils';
import FieldWrapper, { FieldWidth } from '../../form/FieldWrapper';

interface SimpleChargeFormLossesFieldProps {
  ErrorText: React.FunctionComponent;
}

export const SimpleChargeFormLossesField = ({ ErrorText }: SimpleChargeFormLossesFieldProps) => {
  const [lossFactorLabels] = useListData(['LOSS_FACTOR_LABELS']);
  const labels: LabelData[] = lossFactorLabels?.data as LabelData[];
  return (
    <FieldWrapper htmlFor="adjustmentToUse" label="Losses" fieldWidth={FieldWidth.QUARTER}>
      <Field
        autoComplete="off"
        data-testid="adjustmentToUse"
        name="adjustmentToUse"
        id="adjustmentToUse"
        className="apl-select-v1_0"
        as="select"
      >
        <option value="" />
        {labels.map((label: LabelData) => {
          const value = JSON.stringify(label.value);
          return (
            <option key={value} value={value}>
              {label.label}
            </option>
          );
        })}
      </Field>
      <ErrorMessage component={ErrorText} name="adjustmentToUse" />
    </FieldWrapper>
  );
};
