import { getAccessToken } from '../auth/authClient';
import { handleError as indexHandleError } from './index';
import { Config } from '../config/getConfig';
import { ApiRequestOptions } from './openapi/telemetry/core/ApiRequestOptions';
import { ApiResult } from './openapi/telemetry/core/ApiResult';
import { ApiError } from './openapi/telemetry';

let clientConfig: Config;

const GO_SLOW = 'GO_SLOW';

const setConfig = (config: Config) => (clientConfig = config);

type AppType =
  | 'billing'
  | 'rating-config'
  | 'rating-config-report'
  | 'rating-calculator'
  | 'telemetry-calculator'
  | 'telemetry'
  | 'loss-factor'
  | 'presentation'
  | 'metrics';

const getBasePath = (appType: AppType): string => {
  switch (appType) {
    case 'billing':
      return clientConfig.billingApiBasePath;
    case 'rating-calculator':
      return clientConfig.ratingCalculatorApiBasePath;
    case 'telemetry-calculator':
      return clientConfig.telemetryCalculatorApiBasePath;
    case 'telemetry':
      return clientConfig.telemetryApiBasePath;
    case 'loss-factor':
      return clientConfig.lossFactorApiBasePath;
    case 'presentation':
      return clientConfig.presentationApiBasePath;
    case 'rating-config-report':
      return clientConfig.reportApiBasePath;
    case 'metrics':
      return clientConfig.metricsBasePath;
    case 'rating-config':
    default:
      return clientConfig.apiBasePath;
  }
};

const buildHeaders = (token: any, appType: AppType) => {
  let headers = {};

  switch (appType) {
    case 'rating-config-report':
    case 'rating-config': {
      const goSlow: string = localStorage.getItem(GO_SLOW) || 'false';

      headers = {
        'Content-Type': 'application/json',
        Authorization: token,
      };

      if (goSlow.toUpperCase() === 'TRUE') {
        headers = {
          ...headers,
          'X-Go-Slow': goSlow,
        };
      }

      break;
    }

    case 'presentation':
      headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      };

      break;

    default:
      headers = {
        'Content-Type': 'application/json',
        Authorization: token,
      };

      break;
  }

  return headers;
};

const get = async (appType: AppType, path: string) => {
  const uri = `${getBasePath(appType)}${path}`;
  const token = await getAccessToken(clientConfig);

  const response = await fetch(uri, {
    method: 'GET',
    headers: buildHeaders(token, appType),
  });

  return handleResponse(response);
};

const post = async (appType: AppType, path: string, body: string) => {
  const uri = `${getBasePath(appType)}${path}`;
  const token = await getAccessToken(clientConfig);

  const response = await fetch(uri, {
    method: 'POST',
    headers: buildHeaders(token, appType),
    body,
  });

  return handleResponse(response);
};

const put = async (appType: AppType = 'rating-config', path: string, body: string) => {
  const uri = `${getBasePath(appType)}${path}`;
  const token = await getAccessToken(clientConfig);

  const response = await fetch(uri, {
    method: 'PUT',
    headers: buildHeaders(token, appType),
    body,
  });

  return handleResponse(response);
};

const del = async (appType: AppType, path: string, body: string) => {
  const uri = `${getBasePath(appType)}${path}`;
  const token = await getAccessToken(clientConfig);

  const response = await fetch(uri, {
    method: 'DELETE',
    headers: buildHeaders(token, appType),
    body,
  });

  return handleResponse(response);
};

const handleResponse = async (response: Response): Promise<any> => {
  let responseBody;

  try {
    responseBody = await response.json();
  } catch (e) {
    // delete methods and some errors/apis may not return a response body, so
    // we just return an empty object in these scenarios
    responseBody = {};
  }

  // return the response message as json if no errors
  if (response.ok) {
    return responseBody;
  }

  return handleError(response, responseBody);
};

const handleError = (response: Response, responseBody: any) => {
  // This implementation of handleError is deprecated.
  // Please use the one in ./index.ts
  const apiRequestOptions: ApiRequestOptions = {
    method: 'GET', // arbitrary choice here -- it's not used in upstream error handling.
    url: response.url,
    body: responseBody,
  };

  const apiResult: ApiResult = {
    url: response.url,
    ok: false,
    status: response.status,
    statusText: response.statusText,
    body: responseBody,
  };
  return indexHandleError(new ApiError(apiRequestOptions, apiResult, responseBody.message));
};

const client = (appType: AppType = 'rating-config') => ({
  get: (path: string) => get(appType, path),
  post: (path: string, body: string) => post(appType, path, body),
  put: (path: string, body: string) => put(appType, path, body),
  del: (path: string, body: string) => del(appType, path, body),
  setConfig,
});

export default client;
