/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateObservanceRequest } from '../models/CreateObservanceRequest';
import type { ListObservancesResponse } from '../models/ListObservancesResponse';
import type { ObservanceResponse } from '../models/ObservanceResponse';
import type { UpdateObservanceRequest } from '../models/UpdateObservanceRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ObservancesService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Returns a list of Observances.
     * @param providerId If provided, the list will include Observances specific to the supplied Provider along with Observances not specific to any Provider.
     * @param from Include Observances with an observedDate of after or including the supplied date.
     * @param to Include Observances with an observedDate of before or including the supplied date
     * @returns ListObservancesResponse A list of Observances within the specified range
     * @throws ApiError
     */
    public getObservances(
        providerId?: string,
        from?: string,
        to?: string,
    ): CancelablePromise<ListObservancesResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v2/observances',
            query: {
                'providerId': providerId,
                'from': from,
                'to': to,
            },
        });
    }

    /**
     * Creates a new observance
     * @param requestBody Details of the new Observance to be created.
     * @returns ObservanceResponse Observance created successfully. Returns details of the new Observance.
     * @throws ApiError
     */
    public createObservance(
        requestBody: CreateObservanceRequest,
    ): CancelablePromise<ObservanceResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/observances',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                422: `Could not process request due to a validation error. See PlanBody schema for validation rules.`,
            },
        });
    }

    /**
     * Update an observance
     * @param observanceId The observance ID which to be updated.
     * @param requestBody The new details of the observance.
     * @returns ObservanceResponse Observance updated successfully.
     * @throws ApiError
     */
    public updateObservance(
        observanceId: string,
        requestBody: UpdateObservanceRequest,
    ): CancelablePromise<ObservanceResponse> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/v2/observances/{observanceId}',
            path: {
                'observanceId': observanceId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                404: `Observance not found`,
                422: `Could not process the update due to a validation error.`,
            },
        });
    }

    /**
     * Delete an existing observance
     * @param observanceId Id of the observance to be deleted.
     * @returns void
     * @throws ApiError
     */
    public deleteObservance(
        observanceId: string,
    ): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/v2/observances/{observanceId}',
            path: {
                'observanceId': observanceId,
            },
            errors: {
                404: `No existing observance found`,
                422: `Could not delete the observance due to a validation error`,
            },
        });
    }

}
