import { buildMeterMeasurementPointIdentifier } from '../measurement-points/measurementPointHelpers';
import {
  AggregatedSummary,
  Measurement,
  MeasurementPoint,
  MeasurementPointGroupInfo,
  MeasurementPoints,
  UsageSummaryV2Item,
} from '../../../api/openapi/telemetry';
import { FlowDirection } from '../dashboard/MeteringDataComponent';
import { clone } from 'ramda';

export function getReadingQualityDisplayText(readingQuality: string) {
  switch (readingQuality) {
    case 'urn:flux:telemetry:usage:accuracy:usage:actual':
      return 'Final';
    case 'urn:flux:telemetry:usage:accuracy:usage:calculated':
      return 'Estimated';
    case 'urn:flux:telemetry:usage:accuracy:usage:forecast':
      return 'Forecast';
    default:
      return '-';
  }
}

export function getConnectionFlowDisplayText(isEnergyGoingToConnection: boolean) {
  if (isEnergyGoingToConnection === true) {
    return 'In';
  } else if (isEnergyGoingToConnection === false) {
    return 'Out';
  } else {
    return '-';
  }
}

export function getMeasurementPointOptions(
  measurementPointType: string,
  measurementPointData: MeasurementPoints | undefined
) {
  return measurementPointData?.items?.length
    ? measurementPointData.items
        .filter((measurementPoint) => {
          if (measurementPointType === 'Meter') return measurementPoint.externalIdentifier != null;
          if (measurementPointType === 'Calculated')
            return measurementPoint.externalIdentifier == null;
          return false; //impossible to reach
        })
        .map((measurementPoint: MeasurementPoint) => ({
          label: measurementPoint.externalIdentifier
            ? buildMeterMeasurementPointIdentifier(measurementPoint.externalIdentifier) +
              ' (' +
              measurementPoint.usageChargeFilterName +
              ')'
            : measurementPoint.description
            ? measurementPoint.description + ' (' + measurementPoint.usageChargeFilterName + ')'
            : '',
          value: measurementPoint.id ? measurementPoint.id : '', //impossible blank id
        }))
    : [];
}

export function getMeasurementPointOption(measurementPoint: MeasurementPoint) {
  return {
    label: measurementPoint.externalIdentifier
      ? 'Meter: ' +
        buildMeterMeasurementPointIdentifier(measurementPoint.externalIdentifier) +
        ' (' +
        measurementPoint.usageChargeFilterName +
        ')'
      : 'Calculated: ' +
        measurementPoint.description +
        ' (' +
        measurementPoint.usageChargeFilterName +
        ')',
    value: measurementPoint.id,
  };
}

export const measurementsByUnit = (list: Measurement[]): Array<Measurement> => {
  const map = new Map();

  list.forEach((item) => {
    const element = map.get(item.unit);
    if (!element) {
      map.set(item.unit, clone(item));
    } else {
      element.value = (Number(element.value) + Number(item.value)).toFixed(3);
    }
  });

  return Array.from(map.values());
};

export const itemsByMeasurementPoint = (list: UsageSummaryV2Item[]): Array<UsageSummaryV2Item> => {
  const map = new Map<string, UsageSummaryV2Item>();

  list.forEach((usageSummaryV2Item) => {
    const groupKey = createGroupingKey(usageSummaryV2Item.measurementPoint);

    const element = map.get(groupKey);
    if (!element) {
      map.set(groupKey, clone(usageSummaryV2Item));
    } else {
      usageSummaryV2Item.summaries?.forEach((summary: AggregatedSummary) => {
        if (!element.summaries) {
          element.summaries = new Array<AggregatedSummary>();
        }

        element.summaries.push(summary);
      });

      element.numberOfCompleteSummaries += usageSummaryV2Item.numberOfCompleteSummaries;
    }
  });

  return Array.from(map.values());
};

function createGroupingKey(measurementPoint: MeasurementPointGroupInfo): string {
  return JSON.stringify({
    meter: measurementPoint.meter,
    isEnergyGoingToConnection: measurementPoint.isEnergyGoingToConnection,
    id: measurementPoint.id,
  });
}

export function getMeasurementPointFlow(
  measurementPointId: string | undefined,
  measurementPointData: MeasurementPoints | undefined,
  flow: FlowDirection // fallback value
) {
  if (
    measurementPointData?.items?.find(
      (measurementPoint) => measurementPoint.id === measurementPointId
    )
  ) {
    return measurementPointData?.items?.find(
      (measurementPoint) => measurementPoint.id === measurementPointId
    )?.isEnergyGoingToConnection
      ? 'IN'
      : 'OUT';
  }
  return flow;
}
