/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ChargeSetQueryRequest } from '../models/ChargeSetQueryRequest';
import type { ChargeSetQueryResponse } from '../models/ChargeSetQueryResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class HasExpectedChargesQueriesService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Run a query to determine if a connection has expected charge components.
     * @param requestBody Details of the charge set query
     * @returns ChargeSetQueryResponse The request is successfully processed.
     * @throws ApiError
     */
    public hasExpectedCharges(
        requestBody: ChargeSetQueryRequest,
    ): CancelablePromise<ChargeSetQueryResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/has-expected-charges-queries',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request.`,
                404: `Unable to find the connection base from the connectionId provided.`,
                422: `Could not process request due to a validation error.`,
            },
        });
    }

}
