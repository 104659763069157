/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { RateUpdateImportCharge } from './RateUpdateImportCharge';

export type RateUpdateImport = {
    endDateFlag?: RateUpdateImport.endDateFlag;
    endDate?: string;
    charges?: Array<RateUpdateImportCharge>;
};

export namespace RateUpdateImport {

    export enum endDateFlag {
        Y = 'Y',
        N = 'N',
    }


}

