/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { UsageMeters } from '../models/UsageMeters';
import type { UsageResponseDetail } from '../models/UsageResponseDetail';
import type { UsageResponseSummary } from '../models/UsageResponseSummary';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class UsageService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Find all reading records between two dates, for a connection, and optionally a meter and flow direction
     * @param startsAt
     * @param endsAt
     * @param connectionId
     * @param limit The max number of readings to return.  There are 288 5-minute intervals in a day.
     * @param meterId
     * @param isEnergyGoingToConnection
     * @param usageChargeFilter the filter name associated with a measurement point
     * @param measurementPointId the measurement point id associated with this incoming usage reading
     * @returns UsageResponseDetail OK
     * @throws ApiError
     */
    public findUsageDetail(
        startsAt: string,
        endsAt: string,
        connectionId: string,
        limit?: number,
        meterId?: string,
        isEnergyGoingToConnection?: boolean,
        usageChargeFilter?: string,
        measurementPointId?: string,
    ): CancelablePromise<UsageResponseDetail> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/usage/detail',
            query: {
                'startsAt': startsAt,
                'endsAt': endsAt,
                'connectionId': connectionId,
                'limit': limit,
                'meterId': meterId,
                'isEnergyGoingToConnection': isEnergyGoingToConnection,
                'usageChargeFilter': usageChargeFilter,
                'measurementPointId': measurementPointId,
            },
            errors: {
                400: `Bad-Request`,
                404: `The specified resource was not found`,
            },
        });
    }

    /**
     * Find summarised reading records between two dates, for a connection, and optionally a meter and flow direction
     * @param startsAt
     * @param endsAt
     * @param connectionId
     * @param limit The max number of records to return.  There are at most 31 days in a month.
     * @param meterId
     * @param isEnergyGoingToConnection
     * @param usageChargeFilter the filter name associated with a measurement point
     * @param measurementPointId the measurement point id associated with this incoming usage reading
     * @returns UsageResponseSummary OK
     * @throws ApiError
     */
    public findUsageSummary(
        startsAt: string,
        endsAt: string,
        connectionId: string,
        limit: number,
        meterId?: string,
        isEnergyGoingToConnection?: boolean,
        usageChargeFilter?: string,
        measurementPointId?: string,
    ): CancelablePromise<UsageResponseSummary> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/usage/summary',
            query: {
                'startsAt': startsAt,
                'endsAt': endsAt,
                'connectionId': connectionId,
                'limit': limit,
                'meterId': meterId,
                'isEnergyGoingToConnection': isEnergyGoingToConnection,
                'usageChargeFilter': usageChargeFilter,
                'measurementPointId': measurementPointId,
            },
            errors: {
                400: `Bad-Request`,
                404: `The specified resource was not found`,
            },
        });
    }

    /**
     * Find the meters that had usage between two dates, for a connection
     * @param startsAt
     * @param endsAt
     * @param connectionId
     * @returns UsageMeters OK
     * @throws ApiError
     */
    public findUsageMeters(
        startsAt: string,
        endsAt: string,
        connectionId: string,
    ): CancelablePromise<UsageMeters> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/usage/meters',
            query: {
                'startsAt': startsAt,
                'endsAt': endsAt,
                'connectionId': connectionId,
            },
            errors: {
                400: `Bad-Request`,
                404: `The specified resource was not found`,
            },
        });
    }

}
