import React, { FunctionComponent, useState } from 'react';
import Modal from 'react-modal';
import { ErrorModel } from '../../api/ErrorDisplay';
import { Permission } from '../../auth/getPermissions';

import AuthorizedAction from '../common/AuthorizedAction';
import { modalStyles } from '../layout/Modal';
import HolidayForm from './HolidayForm';
import { Observance, UpdateObservanceRequest, useUpdateObservance } from './observancesApi';

interface UpdateHolidayProps {
  observance: Observance;
  observanceId: string;
}

const UpdateHoliday: FunctionComponent<UpdateHolidayProps> = ({ observance, observanceId }) => {
  const [updateHolidayIsOpen, setUpdateHolidayIsOpen] = useState(false);

  const closeUpdateHoliday = () => setUpdateHolidayIsOpen(false);
  const openUpdateHoliday = () => setUpdateHolidayIsOpen(true);

  const { mutateAsync, error } = useUpdateObservance();
  const submit = async (observanceBody: UpdateObservanceRequest): Promise<any> => {
    try {
      await mutateAsync({ observanceId, observanceBody });
      closeUpdateHoliday();
    } catch (e: any) {
      console.error(e);
    }
  };

  return (
    <>
      <AuthorizedAction
        onClick={() => {
          openUpdateHoliday();
        }}
        permission={Permission.OBSERVANCE_EDIT}
      >
        Edit holiday
      </AuthorizedAction>
      <Modal
        isOpen={updateHolidayIsOpen}
        onRequestClose={closeUpdateHoliday}
        style={modalStyles}
        shouldCloseOnOverlayClick={false}
      >
        <div>
          <h2>Edit holiday</h2>
          <HolidayForm
            submitFunction={submit}
            close={closeUpdateHoliday}
            holiday={observance}
            error={error as ErrorModel}
          />
        </div>
      </Modal>
    </>
  );
};

export default UpdateHoliday;
