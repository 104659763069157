/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { InvoiceApiData } from './InvoiceApiData';
import type { InvoiceContext } from './InvoiceContext';
import type { Links } from './Links';

export type Invoice = {
    context: InvoiceContext;
    status: Invoice.status;
    /**
     * Populated when invoice status is failed to describe why it failed
     */
    messages?: Array<string>;
    data?: InvoiceApiData;
    _links?: Array<Links>;
};

export namespace Invoice {

    export enum status {
        URN_FLUX_BILLING_INVOICE_STATUS_PENDING = 'urn:flux:billing:invoice:status:pending',
        URN_FLUX_BILLING_INVOICE_STATUS_FAILED = 'urn:flux:billing:invoice:status:failed',
        URN_FLUX_BILLING_INVOICE_STATUS_AVAILABLE = 'urn:flux:billing:invoice:status:available',
        URN_FLUX_BILLING_INVOICE_STATUS_PAID = 'urn:flux:billing:invoice:status:paid',
        URN_FLUX_BILLING_INVOICE_STATUS_REVERSED = 'urn:flux:billing:invoice:status:reversed',
    }


}

