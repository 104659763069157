/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { MultiRangeQualifierBody } from '../models/MultiRangeQualifierBody';
import type { QualifierDataList } from '../models/QualifierDataList';
import type { QualifierDetail } from '../models/QualifierDetail';
import type { QualifierPageResponse } from '../models/QualifierPageResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class QualifiersService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Returns a paginated list of all qualifiers meeting the provided filters
     * @param qualifierName Search term to filter qualifierName by.
     * @param provider TODO: this doesnt seem to be used??
     * @param limit The maximum number of Qualifiers to return. Defaults to 1000.
     * @param offset Result index to start the list from. Deafults to 0.
     * @param sortBy String of comma-separated sort commands to sort the Qualifiers by.
     * Each command can contain a sort column and a sort direction, separated by ".".
     * E.g. "name.ASC'
     *
     * @returns QualifierPageResponse A paginated list of all qualifiers meeting the provided filters
     * @throws ApiError
     */
    public getQualifiers(
        qualifierName?: string,
        provider?: string,
        limit?: number,
        offset?: number,
        sortBy?: string,
    ): CancelablePromise<QualifierPageResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v2/qualifiers',
            query: {
                'qualifier-name': qualifierName,
                'provider': provider,
                'limit': limit,
                'offset': offset,
                'sortBy': sortBy,
            },
        });
    }

    /**
     * Creates a new Qualifier
     * @param requestBody Details of the new Qualifier to be created.
     * @returns QualifierDetail Qualifier created successfully. Returns details of the new Qualifier.
     * @throws ApiError
     */
    public postQualifiers(
        requestBody: MultiRangeQualifierBody,
    ): CancelablePromise<QualifierDetail> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/qualifiers',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                404: `Qualifier does not exist.`,
                422: `Could not process request due to a validation error. See QualifierBody schema for validation rules.`,
            },
        });
    }

    /**
     * Retrieves a Qualifier by ID
     * @param qualifierId Id of the Qualifier requested
     * @returns QualifierDetail Details of the Qualifier.
     * @throws ApiError
     */
    public getQualifierById(
        qualifierId: string,
    ): CancelablePromise<QualifierDetail> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v2/qualifiers/{qualifierId}',
            path: {
                'qualifierId': qualifierId,
            },
            errors: {
                404: `Qualifier not found.`,
            },
        });
    }

    /**
     * Updates an existing Qualifier
     * @param qualifierId Id of the existing Qualifier to be updated
     * @param requestBody Updated details for Qualifier
     * @returns QualifierDetail Qualifier updated successfully. Returns Qualifier details, with the updates applied.
     * @throws ApiError
     */
    public putQualifierById(
        qualifierId: string,
        requestBody: MultiRangeQualifierBody,
    ): CancelablePromise<QualifierDetail> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/v2/qualifiers/{qualifierId}',
            path: {
                'qualifierId': qualifierId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                404: `Qualifier not found.`,
                422: `Could not process request due to a validation error. See QualifierBody schema for validation rules.`,
            },
        });
    }

    /**
     * Retrieves Qualifier Data by ID(s)
     * @param ids Ids of the Qualifiers requested
     * @returns QualifierDataList Data of the Qualifier.
     * @throws ApiError
     */
    public getQualifierDataByIds(
        ids: Array<string>,
    ): CancelablePromise<QualifierDataList> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v3/qualifiers',
            query: {
                'ids': ids,
            },
            errors: {
                404: `Qualifier not found.`,
            },
        });
    }

}
