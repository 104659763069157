import React, { MouseEvent, ReactElement, ReactNode, useEffect, useMemo, useState } from 'react';
import * as serviceWorker from '../../serviceWorkerRegistration';
import { AppUpdateContext } from './AppUpdateContext';
import { isProd } from 'environment';

interface AppUpdateProviderProps {
  children: ReactNode;
}

export const AppUpdateProvider = (props: AppUpdateProviderProps): ReactElement => {
  const { children } = props;
  const [waitingServiceWorker, setWaitingServiceWorker] = useState<ServiceWorker | null>(null);
  const [isUpdateAvailable, setUpdateAvailable] = useState<boolean>(false);

  useEffect(() => {
    serviceWorker.register({
      onUpdate: (registration) => {
        setWaitingServiceWorker(registration.waiting);
        setUpdateAvailable(true);
      },
      onWaiting: (waiting) => {
        setWaitingServiceWorker(waiting);
        setUpdateAvailable(true);
      },
      onSuccess: (waiting) => {
        if (!isProd()) {
          console.debug('onSuccess ' + JSON.stringify(waiting));
        }
      },
    });
  }, []);

  useEffect(() => {
    waitingServiceWorker?.addEventListener('statechange', (event) => {
      const sw = event?.target as ServiceWorker;
      if (sw?.state === 'activated') {
        window.location.reload();
      }
    });
  }, [waitingServiceWorker]);

  const value = useMemo(
    () => ({
      isUpdateAvailable,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      updateAssets: (e: MouseEvent<HTMLElement>) => {
        if (waitingServiceWorker) {
          // We send the SKIP_WAITING message to tell the Service Worker
          // to update its cache and flush the old one
          waitingServiceWorker.postMessage({ type: 'SKIP_WAITING' });
        }
      },
    }),
    [isUpdateAvailable, waitingServiceWorker]
  );

  return <AppUpdateContext.Provider value={value}>{children}</AppUpdateContext.Provider>;
};
