import React, { FunctionComponent } from 'react';
import { FormikValues } from 'formik';
import SimpleChargeForm from './SimpleChargeForm';
import { simpleRateListToScheduleList } from './simpleChargeUtils';
import { sanitiseChargeBody, useEditChargeV2 } from '../planApi';
import { useListData } from 'list-data/ListDataContext';
import { ChargeTypesConfig } from 'list-data/charge-types/chargeTypesConfigUtils';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Charge, Plan, Provider, RateSchedule } from '../../../api/openapi/rating-config';

interface EditSimpleChargeProps {
  close: () => void;
  plan: Plan;
  charge: Charge;
  provider: Provider;
}

const EditSimpleCharge: FunctionComponent<EditSimpleChargeProps> = ({
  close,
  plan,
  charge,
  provider,
}) => {
  const { fbau635BillingCategoryForInvoicePresentation, cfd872SteppedTariffs } = useFlags();

  const [chargeTypesList] = useListData(['CHARGE_TYPES']);
  const chargeTypesConfig: ChargeTypesConfig = chargeTypesList?.data as ChargeTypesConfig;

  const { mutateAsync, isLoading, isError: errorEditingCharge, error } = useEditChargeV2();

  const editSimpleCharge =
    () =>
    async ({
      chargeType,
      chargeReference,
      chargeBasis,
      chargeName,
      chargeTags,
      chargeCalculation,
      aggregationMethod,
      billingDescriptor, // Remove with fbau635BillingCategoryForInvoicePresentation
      billingCategory,
      flowDirection,
      powerFactor,
      topPeriods,
      sumFunction,
      adjustmentToUse,
      rates,
      schedules,
      lookbackMonths,
      intervalSize,
      minimumChargeable,
      minimumThresholdMethod,
      historicalMeasure,
      usageChargeFilter,
      readingQuality,
      isTaxed,
    }: FormikValues) => {
      const adjustmentToUseProps =
        typeof adjustmentToUse == 'string' ? { adjustmentToUse: JSON.parse(adjustmentToUse) } : {};

      const displayProps = fbau635BillingCategoryForInvoicePresentation
        ? {
            billingDescriptor,
            display: { category: billingCategory, descriptor: billingDescriptor },
          }
        : { billingDescriptor };

      let schedulesToAdd: Array<RateSchedule>;
      if (cfd872SteppedTariffs) {
        schedulesToAdd = schedules.map((schedule: any) => ({
          ...schedule,
          rates: schedule.rates?.map((rate: any) => ({
            ...rate,
            applicableTimePeriods: rate.applicableTimePeriods?.map((applicableTimePeriod: any) => ({
              qualifierId: applicableTimePeriod.id,
              name: applicableTimePeriod.name,
            })),
            rateQualifiers: rate.rateQualifiers?.map((rateQualifier: any) => ({
              qualifierId: rateQualifier.id,
              name: rateQualifier.name,
            })),
          })),
        }));
      } else {
        schedulesToAdd = simpleRateListToScheduleList(rates);
      }

      const newCharge: Charge = {
        chargeBasis,
        chargeType,
        chargeReference,
        chargeName,
        chargeTags: [chargeTags],
        chargeCalculation,
        aggregationMethod,
        ...displayProps,
        flowDirection: flowDirection,
        powerFactor,
        topPeriods,
        sumFunction,
        ...adjustmentToUseProps,
        schedules: schedulesToAdd,
        lookbackMonths,
        intervalSize,
        minimumChargeable,
        minimumThresholdMethod: minimumThresholdMethod == '' ? undefined : minimumThresholdMethod,
        historicalMeasure,
        usageChargeFilter,
        readingQuality,
        isTaxed,
      };

      const result = await mutateAsync({
        charge: sanitiseChargeBody(newCharge, chargeTypesConfig),
        planId: plan.id,
        chargeId: charge.id,
      } as any);

      if (result) {
        close();
      }
    };

  return (
    <div>
      <h2 className="apl-h2 apl-mt-none">Edit charge</h2>
      <SimpleChargeForm
        close={close}
        charge={charge}
        formAction={editSimpleCharge}
        isLoading={isLoading}
        isChargeError={errorEditingCharge}
        plan={plan}
        qualifierErrorMessage={(error as Error)?.message ?? ''}
        provider={provider}
      />
    </div>
  );
};

export default EditSimpleCharge;
