import React from 'react';
import { isNil } from 'ramda';

export const FluxDatePicker: ({
  field: { name, checked, multiple, onBlur, onChange, value },
  form: { touched, errors },
  ...props
}: {
  field: {
    name: any;
    checked: any;
    multiple: any;
    onBlur: any;
    onChange: any;
    value: any;
  };
  form: { touched: any; errors: any };
  [p: string]: any;
}) => JSX.Element = ({
  field: { name, checked, multiple, onBlur, onChange, value },
  form: { touched, errors },
  ...props
}) => {
  const hasErrorClass = !isNil(touched[name]) && !isNil(errors[name]) ? 'has-error' : '';
  const inputProps = { ...props };
  if ('className' in inputProps) {
    delete inputProps.className;
  }

  return (
    <input
      className={`apl-text-input-v1 ${hasErrorClass}`}
      type="date"
      name={name}
      checked={checked}
      multiple={multiple}
      onBlur={onBlur}
      onChange={onChange}
      value={value}
      {...inputProps}
    />
  );
};

export default FluxDatePicker;
