/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ChargePageResponse } from '../models/ChargePageResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ChargesService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Gets all charges of valid supply periods configured for the supply agreement and given period
     * @param startsAt
     * @param supplyAgreementId Supply Period UUID
     * @param endsAt
     * @param supplyPeriodActiveAt
     * @param onlyBillable
     * @returns ChargePageResponse List of charges
     * @throws ApiError
     */
    public getCharges(
        startsAt: string,
        supplyAgreementId: string,
        endsAt?: string,
        supplyPeriodActiveAt?: string,
        onlyBillable?: string,
    ): CancelablePromise<ChargePageResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v2/charges',
            query: {
                'startsAt': startsAt,
                'endsAt': endsAt,
                'supplyAgreementId': supplyAgreementId,
                'supplyPeriodActiveAt': supplyPeriodActiveAt,
                'onlyBillable': onlyBillable,
            },
            errors: {
                400: `Bad request.`,
                404: `Unable to find any supply periods from the supplyAgreementId and date range provided.`,
            },
        });
    }

}
