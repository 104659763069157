import React, { useState } from 'react';
import {
  EuiBadge,
  EuiBadgeGroup,
  EuiPanel,
  EuiTable,
  EuiTableBody,
  EuiTableHeader,
  EuiTableHeaderCell,
  EuiTableRow,
  EuiTableRowCell,
} from '@elastic/eui';
import { Charge, QualifierLink } from '../../../api/openapi/rating-config';
import { formatDateForDisplay } from '../../../util/helper-func';
import { formatRateUnit } from './simpleChargeUtils';
import { useCurrencyUnitTranslation, useMassUnitTranslation } from '../../../hooks';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Link } from 'react-router-dom';

type SteppedTariffChargeDetailsProps = {
  charge: Charge;
};

type TableItem = {
  rate: string;
  validFrom: string;
  validTo: string;
  summarisationGroup: string;
  minThreshold: number;
  rateQualifiers: JSX.Element;
};

type Column = {
  field: string;
  name: string;
  isSortable?: boolean;
};

enum SortDirection {
  ASC = 'asc',
  DESC = 'desc',
}

function SteppedTariffChargeDetails({ charge }: SteppedTariffChargeDetailsProps) {
  const [sortField, setSortField] = useState<keyof TableItem>('validFrom');
  const [sortDirection, setSortDirection] = useState<SortDirection>(SortDirection.ASC);

  const { fbau721ExpandVolumeChargeToIncludeNewAttributesAndChargeBasis } = useFlags();

  const currencyUnit = useCurrencyUnitTranslation();
  const tonneUnit = useMassUnitTranslation('T');

  const toggleSort = (column: Column) => {
    if (!column.isSortable) {
      return;
    }

    if (column.field === sortField) {
      setSortDirection(
        sortDirection === SortDirection.ASC ? SortDirection.DESC : SortDirection.ASC
      );
    } else {
      setSortField(column.field as keyof TableItem);
      setSortDirection(SortDirection.ASC);
    }
  };

  const columns = [
    {
      field: 'rate',
      name: 'Rate',
      isSortable: true,
    },
    {
      field: 'validFrom',
      name: 'Valid from',
      isSortable: true,
    },
    {
      field: 'validTo',
      name: 'Valid to',
      isSortable: true,
    },
    {
      field: 'summarisationGroup',
      name: 'Summarisation group',
    },
    {
      field: 'minThreshold',
      name: 'Min daily threshold',
    },
    {
      field: 'rateQualifiers',
      name: 'Rate qualifiers',
    },
  ];

  const items = [];
  if (charge && charge.schedules) {
    for (let i = 0; i < charge.schedules.length; i++) {
      const schedule = charge.schedules[i];
      if (schedule.rates) {
        for (let j = 0; j < schedule.rates.length; j++) {
          const rate = schedule.rates[j];
          const item: TableItem = {
            rate: `${rate.rate} ${formatRateUnit({
              chargeBasis: charge?.chargeBasis,
              rate: rate.rate,
              flags: { fbau721ExpandVolumeChargeToIncludeNewAttributesAndChargeBasis },
              tonneUnit: tonneUnit,
              currencyUnit: currencyUnit,
            })}`,
            validFrom: formatDateForDisplay(schedule.validFrom),
            validTo: formatDateForDisplay(schedule?.validTo || ''),
            summarisationGroup: rate?.summarisationGroup || '',
            minThreshold: rate?.minThreshold || 0,
            rateQualifiers: (
              <EuiBadgeGroup gutterSize="s">
                {rate?.rateQualifiers && rate?.rateQualifiers?.length > 0 && (
                  <React.Fragment>
                    {rate.rateQualifiers.map((qualifier: QualifierLink, index: number) => (
                      <EuiBadge key={index}>
                        <Link to={`/qualifiers/${qualifier.qualifierId}`}>{qualifier.name}</Link>
                      </EuiBadge>
                    ))}
                  </React.Fragment>
                )}
              </EuiBadgeGroup>
            ),
          };

          items.push(item);
        }
      }
    }
  }

  const sortedItems = items.slice().sort((a: TableItem, b: TableItem) => {
    if (sortField) {
      if (a[sortField] < b[sortField]) {
        return sortDirection === 'asc' ? -1 : 1;
      }
      if (a[sortField] > b[sortField]) {
        return sortDirection === 'asc' ? 1 : -1;
      }
    }
    return 0;
  });

  return (
    <div>
      <EuiPanel paddingSize="s">
        <EuiTable>
          <EuiTableHeader wrapWithTableRow={false}>
            <EuiTableRow>
              {columns.map((column) => (
                <EuiTableHeaderCell
                  key={column.field}
                  onClick={() => toggleSort(column)}
                  isSorted={sortField === column.field}
                  isSortAscending={sortDirection === 'asc'}
                >
                  {column.name}
                </EuiTableHeaderCell>
              ))}
            </EuiTableRow>
          </EuiTableHeader>

          <EuiTableBody>
            {sortedItems.map((item, index) => (
              <EuiTableRow key={index}>
                {columns.map((column) => (
                  <EuiTableRowCell key={column.field}>
                    {item[column.field as keyof TableItem]}
                  </EuiTableRowCell>
                ))}
              </EuiTableRow>
            ))}
          </EuiTableBody>
        </EuiTable>
      </EuiPanel>
    </div>
  );
}

export default SteppedTariffChargeDetails;
