import cx from 'classnames';
import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import backHoverIcon from '../../icons/back-hover.svg';
import backIcon from '../../icons/back.svg';
import './Page.scss';
import { EuiButton } from '@elastic/eui';

interface PageHeaderProps {
  backLink?: string;
  isSticky?: boolean;
  showBack?: boolean;
  stickyOffset?: number;
  // eslint-disable-next-line @typescript-eslint/ban-types
  title: string | Function | undefined;
  children?: React.ReactNode;
}

interface PageProps {
  className?: string;
  children?: React.ReactNode;
}

export const PageHeader = ({
  backLink,
  children,
  isSticky,
  showBack,
  stickyOffset,
  title,
}: PageHeaderProps) => {
  const [hover, setHover] = useState(false);
  const navigate = useNavigate();

  return (
    <div
      className={cx(
        'page-header',
        'apl-px-l',
        'apl-py',
        'apl-display-flex',
        'apl-flex-row',
        'apl-justify-content-between',
        'apl-align-items-center'
      )}
      style={{
        position: isSticky ? 'sticky' : undefined,
        top: !isSticky ? undefined : stickyOffset ? stickyOffset : 0,
        zIndex: isSticky ? 999 : undefined,
      }}
    >
      <div className="apl-display-flex apl-flex-row apl-align-items-center">
        {(backLink || showBack) && (
          <EuiButton
            onMouseOut={() => setHover(false)}
            onMouseOver={() => setHover(true)}
            onClick={() => (backLink ? navigate(backLink) : navigate(-1))}
            style={{
              fontWeight: 600,
              marginRight: 20,
            }}
          >
            <img alt="back icon" className="apl-mr-xs" src={hover ? backHoverIcon : backIcon} />
            Back
          </EuiButton>
        )}
        <PageTitle>{title instanceof Function ? title() : title}</PageTitle>
      </div>
      {children && (
        <div className={cx('apl-display-flex', 'apl-flex-row', 'apl-align-items-center')}>
          {children}
        </div>
      )}
    </div>
  );
};

export const PageTitle = ({ children, className }: PageProps) => (
  <h1
    className={cx(
      'page__title',
      'apl-display-flex',
      'apl-flex-row',
      'apl-align-items-center',
      className
    )}
    data-testid="page-title"
  >
    {children}
  </h1>
);

export const ErrorPage = () => (
  <Page>
    <p>Sorry, there was an error.</p>
  </Page>
);

export const LoadingPage = () => (
  <Page>
    <p>Loading...</p>
  </Page>
);

const Page = ({ children, className }: PageProps) => {
  const classes = className ? `${className} page` : 'page';

  return <div className={classes}>{children}</div>;
};

export default Page;
