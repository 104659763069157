/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type SupplyAgreement = {
    _version: string;
    _type: SupplyAgreement._type;
    _publishedAt: string;
    /**
     * An system generated identifier used for tracing the event as it flows through the system
     */
    _correlationId: string;
    connection: {
        id?: string;
    };
    id?: string;
    '@id'?: string;
    /**
     * The earliest date/time this supply agreement becomes valid
     */
    validFrom?: string;
    /**
     * The earliest date/time this supply agreement ceases to be valid
     */
    validTo?: string;
    advertisedOffer?: string;
    termsAndConditionsAccepted?: boolean;
    termsAndConditionsAcceptedAt?: string;
    currentSituation?: string;
    requestedSupplyDate?: string;
    readonly pendingSignUp?: boolean;
    status?: SupplyAgreement.status;
    billingProvider: Array<{
        provider?: 'urn:flux:billing:provider:aib' | 'urn:flux:billing:provider:core' | 'urn:flux:billing:provider:external';
        /**
         * The earliest date/time this billingProvider becomes valid
         */
        validFrom?: string;
        /**
         * The earliest date/time this billingProvider ceases to be valid
         */
        validTo?: string;
    }>;
    address?: {
        id?: string;
        '@id'?: string;
    };
    parties: {
        owner?: {
            id?: string;
        };
    };
    party?: {
        id?: string;
        '@id'?: string;
    };
    surcharges?: Array<{
        /**
         * The earliest date/time this surcharge becomes valid
         */
        validFrom?: string;
        /**
         * The earliest date/time this surcharge ceases to be valid
         */
        validTo?: string;
        value?: string;
    }>;
    discounts?: Array<{
        /**
         * The earliest date/time this discount becomes valid
         */
        validFrom?: string;
        /**
         * The earliest date/time this discount ceases to be valid
         */
        validTo?: string;
        value?: string;
        amount?: number;
    }>;
    pricingPlan?: {
        id?: string;
        '@id'?: string;
        name?: string;
    };
    consumerPlans?: Array<{
        /**
         * The earliest date/time this consumerPlan becomes valid
         */
        validFrom?: string;
        /**
         * The earliest date/time this consumerPlan ceases to be valid
         */
        validTo?: string;
        id?: string;
        identifier?: string;
        name?: string;
        visibleDiscount?: number;
    }>;
    /**
     * Used to group connections that receive similar prices, often to abstract network tariffs.
     */
    pricingCodes?: Array<{
        /**
         * Description of grouping
         */
        name?: string;
        /**
         * Code used to group prices
         */
        code?: string;
        /**
         * The earliest date/time this pricingCode becomes valid
         */
        validFrom?: string;
        /**
         * The earliest date/time this pricingCode ceases to be valid
         */
        validTo?: string;
        /**
         * The date/time this pricingCode was invalidated
         */
        invalidatedAt?: string;
    }>;
};

export namespace SupplyAgreement {

    export enum _type {
        LEDGER_SUPPLY_AGREEMENT_UPDATED = 'ledger.supply_agreement.updated',
    }

    export enum status {
        URN_FLUX_LEDGER_SUPPLY_AGREEMENT_STATUS_VALID = 'urn:flux:ledger:supply_agreement:status:valid',
        URN_FLUX_LEDGER_SUPPLY_AGREEMENT_STATUS_CANCELLED = 'urn:flux:ledger:supply_agreement:status:cancelled',
    }


}

