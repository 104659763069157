/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Error } from '../models/Error';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class TestSupplyAgreementAttributesService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @param id
     * @returns string OK
     * @returns Error Error
     * @throws ApiError
     */
    public getTestSupplyAgreementAttributesById(
        id: string,
    ): CancelablePromise<Array<string> | Error> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/test/configuration/supply-agreements/{id}/attributes',
            path: {
                'id': id,
            },
        });
    }

}
