import React, { FunctionComponent } from 'react';
import {
  PlanLinkBody,
  SupplyPeriodPlanSummary,
  useUpdatePlanLink,
  useUpdatePlanLinkV3,
} from '../../connectionsApi';
import { ErrorModel } from '../../../../api/ErrorDisplay';
import PlanLinkForm from './PlanLinkForm';
import { ErrorPage, LoadingPage } from '../../../layout/Page';
import { usePlanSummary } from '../../../providers/planSummaryApi';
import { PlanSummary } from '../../../../api/openapi/rating-config';
import { useFlags } from 'launchdarkly-react-client-sdk';

interface UpdatePlanLinkProps {
  planLink: SupplyPeriodPlanSummary;
  connectionId: string;
  supplyPeriodId: string;
  supplyPeriodStartDate: string;
  supplyPeriodEndDate: string;
  close: () => void;
}

const UpdatePlanLink: FunctionComponent<UpdatePlanLinkProps> = ({
  planLink,
  connectionId,
  supplyPeriodId,
  supplyPeriodStartDate,
  supplyPeriodEndDate,
  close,
}) => {
  const {
    data: plans,
    isInitialLoading: isLoading,
    isError,
  } = usePlanSummary(planLink.providerId, { enabled: !!planLink?.providerId });
  const { lobi796ShowPlanLinkPurposeOptions } = useFlags();
  const {
    mutateAsync,
    isLoading: isSubmitting,
    error,
  } = lobi796ShowPlanLinkPurposeOptions
    ? useUpdatePlanLinkV3(supplyPeriodId)
    : useUpdatePlanLink(connectionId, supplyPeriodId);

  if (isLoading) {
    return <LoadingPage />;
  }

  if (isError) {
    return <ErrorPage />;
  }

  const existingPlan = (plans.planSummaries as PlanSummary[]).find(
    (summary) => summary.id === planLink.pricingPlanId
  );

  const updatePlanLink = async (
    pricingPlanId: string,
    startDate: string,
    endDate: string,
    purpose: string
  ) => {
    const newPlanLink: PlanLinkBody = {
      pricingPlanId,
      startDate,
      endDate,
      purpose,
    };

    const result = await mutateAsync({
      planLink: newPlanLink,
      planLinkId: planLink.id,
    });

    if (result) {
      close();
    }
  };

  return (
    <div className="apl-form-layout-v1" data-testid="add-plan-form">
      <h2>Edit plan</h2>

      <PlanLinkForm
        planLink={planLink}
        existingPlan={existingPlan}
        close={close}
        error={error as ErrorModel}
        isUpdating={isSubmitting}
        submitFunction={updatePlanLink}
        supplyPeriodStartDate={supplyPeriodStartDate}
        supplyPeriodEndDate={supplyPeriodEndDate}
      />
    </div>
  );
};

export default UpdatePlanLink;
