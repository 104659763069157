import React, { FunctionComponent, useState } from 'react';
import Modal from 'react-modal';
import AuthorizedAction from '../common/AuthorizedAction';
import { modalStyles } from '../layout/Modal';
import { Permission } from '../../auth/getPermissions';
import { useDeleteMetric } from './metricsApi';
import { Form, Formik } from 'formik';

interface DeleteMetricProps {
  metricId: string | undefined;
}

const DeleteMetric: FunctionComponent<DeleteMetricProps> = ({ metricId }) => {
  const [deleteMetricIsOpen, setDeleteMetricIsOpen] = useState(false);

  const closeDeleteMetric = () => setDeleteMetricIsOpen(false);
  const openDeleteMetric = () => setDeleteMetricIsOpen(true);

  const { mutateAsync } = useDeleteMetric();

  const handleDelete = async () => {
    await mutateAsync({ metricId });
    closeDeleteMetric();
  };

  return (
    <>
      <AuthorizedAction
        onClick={() => {
          openDeleteMetric();
        }}
        permission={Permission.METRIC_EDIT}
      >
        Delete
      </AuthorizedAction>
      <Modal
        isOpen={deleteMetricIsOpen}
        onRequestClose={closeDeleteMetric}
        style={modalStyles}
        shouldCloseOnOverlayClick={true}
      >
        <Formik initialValues={{}} onSubmit={() => handleDelete()}>
          {() => (
            <Form className="apl-form-layout-v1">
              <div>Are you sure you want to delete this metric?</div>
              <div className="apl-display-flex apl-justify-content-end">
                <button className="apl-button-v1" onClick={closeDeleteMetric} type={'button'}>
                  Cancel
                </button>
                <AuthorizedAction
                  extraClasses="is-primary"
                  permission={Permission.METRIC_EDIT}
                  removeDefaultWeight={true}
                  type="submit"
                >
                  Confirm
                </AuthorizedAction>
              </div>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
};

export default DeleteMetric;
