export const modalStyles = {
  content: {
    borderRadius: '4px',
    borderTop: 'none',
    bottom: 'auto',
    filter: 'drop-shadow(0 0 1.75rem #000000)',
    left: '50%',
    marginRight: '-50%',
    maxHeight: '90%',
    minWidth: '600px',
    padding: '24px',
    right: 'auto',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 9999,
  },
  overlay: {
    background: 'rgba(51, 51, 51, 0.9)',
    zIndex: 9998,
  },
};

export const chargeModalStyles = {
  content: {
    borderRadius: '4px',
    border: 'none',
    bottom: 'auto',
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.15), 0px 10px 40px rgba(0, 0, 0, 0.5)',
    left: '50%',
    maxHeight: '80%',
    minWidth: '938px',
    padding: '24px',
    right: 'auto',
    top: '50px',
    transform: 'translateX(-50%)',
    zIndex: 9999,
  },
  overlay: {
    background: 'rgba(51, 51, 51, 0.9)',
    zIndex: 9998,
  },
};
