import React, { FunctionComponent, useEffect } from 'react';
import { differenceInMinutes } from 'date-fns';
import { useFlags, useLDClient } from 'launchdarkly-react-client-sdk';
import Modal from 'react-modal';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { BrowserRouter as Router, Outlet, Route, Routes } from 'react-router-dom';
import {
  checkStorageForTokens,
  getTokenData,
  isTokenStillValid,
  refreshTokens,
} from './auth/authClient';
import { Permission } from './auth/getPermissions';
import LoginCallback, { RedirectToLogin } from './auth/LoginCallback';
import { PermissionContextProvider } from './auth/PermissionContext';
import Unauthorised from './auth/Unauthorised';
import Dashboard from './components/dashboard/Dashboard';
import Header from './components/header/Header';
import { AppUpdateChecker } from './components/update/AppUpdateChecker';
import { useConfig } from './config/ConfigContext';
import './flux-styles/responsive.scss';
import { useStore } from './store';
import { setLDClient } from './util/launchdarkly.util';
import { ListDataContextProvider } from 'list-data/ListDataContext';
import ProviderSummaryList from 'components/providers/ProviderSummaryList';
import BillingReadiness from 'components/dashboard/billing-readiness/BillingReadiness';
import ConnectionsWithoutEnergyPlans from 'components/dashboard/missing-plans/ConnectionsWithoutEnergyPlans';
import ConnectionsWithoutNetworkPlans from 'components/dashboard/missing-plans/ConnectionsWithoutNetworkPlans';
import FailedRatingRequests from 'components/dashboard/rating-requests/FailedRatingRequests';
import ErrorFeed from 'components/errors/ErrorFeed';
import ConnectionList from 'components/connections/ConnectionList';
import ProtectedRoute from 'auth/ProtectedRoute';
import ConnectionDashboard from 'components/connections/dashboard/ConnectionDashboard';
import ConnectionSummary from 'components/connections/supply-periods/ConnectionSummary';
import InvoiceDetails from 'components/connections/charges/InvoiceDetails';
import ChargeSetDetails from 'components/connections/charges/ChargeSetDetails';
import ChargeSets from 'components/connections/charges/ChargeSets';
import ConnectionPlanDetails from 'components/plans/ConnectionPlanDetails';
import MeteringMonthlySummary from 'components/connections/metering/MeteringMonthlySummary';
import MeteringMonthlySummaryV2 from 'components/connections/metering/MeteringMonthlySummaryV2';
import ContractedPartySelector from 'components/connections/ContractedPartySelector';
import { isProd } from 'environment';
import PlanCalendar from 'components/plans/PlanCalendar';
import PlanDetails from 'components/plans/PlanDetails';
import { UsageChargeFiltersContextProvider } from 'components/usage-charge-filters/UsageChargeFiltersContext';
import ProviderPlans from 'components/providers/ProviderPlans';
import ManageProviders from 'components/providers/ManageProviders';
import ProvisioningExclusions from 'components/provisioning/ProvisioningExclusions';
import QualifierDetails from 'components/qualifiers/QualifierDetails';
import QualifierList from 'components/qualifiers/QualifierList';
import SettingsMenu from 'components/settings/SettingsMenu';
import HolidayList from 'components/holidays/HolidayList';
import NotFound from 'components/common/NotFound';
import Broker from 'components/providers/Broker';

import './App.css';
import ReportsView from 'components/reports/ReportsView';
import MeteringDailySummaryV2 from './components/connections/metering/MeteringDailySummaryV2';
import MeteringDailySummary from './components/connections/metering/MeteringDailySummary';
import MeteringMonthlySummaryMerxV2 from './components/connections/metering/MeteringMonthlySummaryMerxV2';
import MeteringDailySummaryMerxV2 from './components/connections/metering/MeteringDailySummaryMerxV2';
import moment from 'moment/moment';
import LossFactorCodesPage from 'components/providers/LossFactorCodesPage';
import LossFactorCodesProvider from 'components/providers/LossFactorCodesProvider';
import { EuiGlobalToastList } from '@elastic/eui';
import PlanLinkHistory from './components/plan-link-history/PlanLinkHistory';

process.env.NODE_ENV !== 'test' && Modal.setAppElement('#root');

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

function App() {
  const config = useConfig();
  if (!isProd()) {
    console.debug('current config', config);
  }
  // @todo remove me when auth is no longer feature flagged
  if (!config.useAuth) {
    localStorage.clear();
  }

  moment.tz.setDefault(config.marketTimezone);

  // initialise the internal memory with tokens from localStorage
  checkStorageForTokens();

  const client = useLDClient();

  // Set the LD client for use outside of components
  setLDClient(client);

  const { cfd212UsageV2Apis, cfd838UsageV2ApisForMerx } = useFlags();

  // useListData(['MARKET_FUNCTION']) is always undefined. So use config.lossFactorKey instead, to determine if it should use AU loss factors or not.
  // const [marketFunctionFlags] = useListData(['MARKET_FUNCTION']);
  // const viewAuLossFactors =
  //     marketFunctionFlags != undefined &&
  //     getMarketFunction('urn:flux:rating-config:market', undefined, marketFunctionFlags) ===
  //     MarketMap.AU_MARKET;
  const viewAuLossFactors = config.lossFactorKey == 'JURISDICTION';

  if (!isProd()) {
    console.debug('all flags', client?.allFlags());
  }

  return (
    <QueryClientProvider client={queryClient}>
      <Router basename={process.env.PUBLIC_URL}>
        <AppUpdateChecker />
        <Routes>
          <Route path="unauthorised" element={<Unauthorised />} />
          <Route path="authCallback" element={<LoginCallback />} />
          <Route path="/" element={<AuthenticatedApp />}>
            <Route path="*" element={<ProtectedRoute permission={Permission.CONNECTION_VIEW} />}>
              <Route index element={<Dashboard />} />

              {/* dashboard routes */}
              <Route path="dashboard/*">
                <Route index element={<Dashboard />} />
                <Route path="billing-readiness/:tab" element={<BillingReadiness />} />
                <Route
                  path="connections-without-energy-plans/:month"
                  element={<ConnectionsWithoutEnergyPlans />}
                />
                <Route
                  path="connections-without-network-plans/:month"
                  element={<ConnectionsWithoutNetworkPlans />}
                />
                <Route path="failed-rating-requests" element={<FailedRatingRequests />} />
                <Route path="error-feed/:tab" element={<ErrorFeed />} />
                <Route path="*" element={<NotFound />} />
              </Route>

              {/* connections routes */}
              <Route path="connections/*">
                <Route index element={<ConnectionList />} />
                <Route
                  path=":id/supply-periods/:supplyPeriodId/contracted-parties/:contractedPartyId"
                  element={<ConnectionDashboard />}
                />
                <Route
                  path=":id/supply-periods/contracted-parties/:contractedPartyId"
                  element={<ConnectionSummary />}
                />
                <Route path=":id/supply-periods" element={<ConnectionSummary />} />
                <Route path=":id/invoice/:invoiceId" element={<InvoiceDetails />} />
                <Route path=":id/charges/:chargeId" element={<ChargeSetDetails />} />
                <Route path=":id/charges" element={<ChargeSets />} />
                <Route path=":id/plan/:planId" element={<ConnectionPlanDetails />} />
                <Route
                  path=":id/metering/month"
                  element={
                    cfd212UsageV2Apis ? (
                      <MeteringMonthlySummaryV2 />
                    ) : cfd838UsageV2ApisForMerx ? (
                      <MeteringMonthlySummaryMerxV2 />
                    ) : (
                      <MeteringMonthlySummary />
                    )
                  }
                />
                <Route
                  path=":id/metering/day"
                  element={
                    cfd212UsageV2Apis ? (
                      <MeteringDailySummaryV2 />
                    ) : cfd838UsageV2ApisForMerx ? (
                      <MeteringDailySummaryMerxV2 />
                    ) : (
                      <MeteringDailySummary />
                    )
                  }
                />
                <Route
                  path=":id/contracted-parties/:contractedPartyId"
                  element={<ConnectionDashboard />}
                />
                <Route path=":id" element={<ContractedPartySelector />} />
                <Route path="*" element={<NotFound />} />
              </Route>

              {/* plans routes */}
              <Route path="plans/*" element={<ProtectedRoute permission={Permission.PLAN_VIEW} />}>
                {!isProd() && <Route path=":planId/calendar" element={<PlanCalendar />} />}
                <Route
                  path=":planId"
                  element={
                    <UsageChargeFiltersContextProvider>
                      <PlanDetails />
                    </UsageChargeFiltersContextProvider>
                  }
                />
                <Route path="*" element={<NotFound />} />
              </Route>

              {/* plan link history routes */}
              <Route
                path="plan-link-history/*"
                element={<ProtectedRoute permission={Permission.PLAN_VIEW} />}
              >
                <Route path=":supplyPeriodId" element={<PlanLinkHistory />} />
                <Route path="*" element={<NotFound />} />
              </Route>

              {/* providers routes */}
              <Route
                path="providers/*"
                element={<ProtectedRoute permission={Permission.PROVIDERS_VIEW} />}
              >
                <Route index element={<ProviderSummaryList />} />
                <Route path=":providerId/plans" element={<ProviderPlans />} />
                <Route path=":providerId/broker" element={<Broker />} />
                <Route path=":providerId/loss-factor-codes" element={<LossFactorCodesProvider />} />
                <Route path="manage" element={<ManageProviders />} />
                <Route path="*" element={<NotFound />} />
              </Route>

              {/* AU loss factors routes */}
              {viewAuLossFactors && (
                <Route
                  path="loss-factor-codes/*"
                  element={<ProtectedRoute permission={Permission.PROVIDERS_VIEW} />}
                >
                  <Route index element={<LossFactorCodesPage />} />
                </Route>
              )}

              {/* provisioning routes */}
              <Route
                path="provisioning/*"
                element={<ProtectedRoute permission={Permission.PROVIDERS_VIEW} />}
              >
                <Route path="exclusions" element={<ProvisioningExclusions />} />
                <Route path="*" element={<NotFound />} />
              </Route>

              {/* qualifiers routes */}
              <Route
                path="qualifiers/*"
                element={<ProtectedRoute permission={Permission.QUALIFIERS_VIEW} />}
              >
                <Route index element={<QualifierList />} />
                <Route path=":qualifierId" element={<QualifierDetails />} />
                <Route path="*" element={<NotFound />} />
              </Route>

              {/* settings routes */}
              <Route
                path="settings/*"
                element={<ProtectedRoute permission={Permission.OBSERVANCE_VIEW} />}
              >
                <Route index element={<SettingsMenu />} />
                <Route path="holidays" element={<HolidayList />} />
                <Route path="*" element={<NotFound />} />
              </Route>

              {/* settings routes */}
              <Route
                path="reports/*"
                element={<ProtectedRoute permission={Permission.OBSERVANCE_VIEW} />}
              >
                <Route index element={<ReportsView />} />
                <Route path="*" element={<NotFound />} />
              </Route>

              {/* catch all route */}
              <Route path="*" element={<NotFound />} />
            </Route>
          </Route>
        </Routes>
      </Router>
    </QueryClientProvider>
  );
}

const AuthenticatedApp: FunctionComponent = () => {
  const config = useConfig();
  const notifications = useStore((state) => state.notifications);

  // keep checking localStorage to see if our token will expire soon, and if so,
  // refresh it behind the scenes to prevent user being logged out unexpectedly
  useEffect(() => {
    if (config.useRefreshToken) {
      const checkTokenExpiry = setInterval(async () => {
        const { expires, refresh } = getTokenData();

        if (
          expires &&
          refresh &&
          refresh !== 'null' &&
          differenceInMinutes(expires, new Date()) <= 30
        ) {
          await refreshTokens();
        }
      }, 1000 * 60 * 5);

      return () => clearInterval(checkTokenExpiry);
    }
  }, [config.useRefreshToken]);

  const { auth, expires } = getTokenData();
  // @todo remove auth bypass when auth is no longer switched
  if (config.useAuth && !isTokenStillValid(auth, expires)) {
    return <RedirectToLogin />;
  }

  return (
    <PermissionContextProvider>
      <ListDataContextProvider>
        <Header />
        <Outlet />
        <EuiGlobalToastList
          toasts={notifications}
          dismissToast={useStore.getState().removeNotification}
          toastLifeTimeMs={30000} // Lasts for 30s
          side="left"
          showClearAllButtonAt={1}
        />
      </ListDataContextProvider>
    </PermissionContextProvider>
  );
};

export default App;
