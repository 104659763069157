import { findMeasurement } from '../dashboard/MeteringDataComponent';
import { getReadingQualityDisplayText } from './MeteringSummaryHelper';
import { copyToClipboard, utcDateTimeToMarketTime } from '../../../util/helper-func';
import copyIcon from '../../../icons/copy.svg';
import React from 'react';
import { IntervalReadingV2, UsageDetailItem } from '../../../api/openapi/telemetry';

interface MeteringDailySummaryItemV2Params {
  measurementPointType: string;
  item: UsageDetailItem;
  reading: IntervalReadingV2;
}

const MeteringDailySummaryItemV2 = (params: MeteringDailySummaryItemV2Params) => {
  const {
    filename,
    intervalEnds,
    intervalNumber: number,
    intervalStarts,
    measurements,
    readingQuality: quality,
  } = params.reading;

  // Convert from UTC to Local (Market Time Zone) time
  const end = utcDateTimeToMarketTime(intervalEnds);
  const start = utcDateTimeToMarketTime(intervalStarts);

  return (
    <tr key={`interval-${number}`}>
      <td className="apl-text-left sticky-col">{`${start} - ${end}`}</td>
      <td className="apl-text-left">{number}</td>

      <td className="apl-text-right">
        {measurements ? `${findMeasurement('kWh', measurements)} kWh` : '-'}
      </td>
      <td className="apl-text-right">
        {measurements ? `${findMeasurement('kVAh', measurements)} kVAh` : '-'}
      </td>
      <td className="apl-text-right">
        {measurements ? `${findMeasurement('kVArh', measurements)} kVArh` : '-'}
      </td>
      <td className="apl-text-right">
        {measurements ? `${findMeasurement('loss_adjusted_kWh', measurements)} kWh` : '-'}
      </td>
      <td className="apl-text-right">
        {measurements ? `${findMeasurement('loss_adjusted_kVAh', measurements)} kVAh` : '-'}
      </td>
      <td className="apl-text-right">
        {measurements ? `${findMeasurement('loss_adjusted_kVArh', measurements)} kVArh` : '-'}
      </td>
      <td className="apl-text-left">{getReadingQualityDisplayText(quality as string)}</td>
      <td className="apl-text-left">
        {measurements ? findMeasurement('power_factor', measurements) : '-'}
      </td>
      {params.measurementPointType === 'Meter' && (
        <td className="apl-text-left" data-testid="row-1-source">
          {filename ?? '-'}
        </td>
      )}
      <td className="apl-text-left">{params.item.measurementPoint.usageChargeFilter as string}</td>
      <td>
        {params.measurementPointType === 'Meter' && filename && (
          <button
            className="apl-button-v1 apl-display-flex"
            onClick={() => copyToClipboard(filename)}
          >
            <img className="apl-mr-xs" alt="copy icon" src={copyIcon} /> Copy source name
          </button>
        )}
      </td>
    </tr>
  );
};

export default MeteringDailySummaryItemV2;
