import React, { FunctionComponent } from 'react';
import { Outlet } from 'react-router-dom';
import { usePermission } from 'auth/PermissionContext';
import { Permission } from 'auth/getPermissions';
import SplashScreen from '../components/layout/SplashScreen';
import NotAuthorised from '../components/layout/NotAuthorised';

interface ProtectedRouteProps {
  permission: Permission;
}

const ProtectedRoute: FunctionComponent<ProtectedRouteProps> = ({ permission }) => {
  const permissions = usePermission();

  if (permissions.length === 0) {
    return <SplashScreen />;
  }

  return <>{permissions.includes(permission) ? <Outlet /> : <NotAuthorised />}</>;
};

export default ProtectedRoute;
