import React from 'react';
import CalculationMetrics from './CalculationMetrics';
import { RatingCalculationMetricsResponse } from '../../../../api/openapi/rating-calculator/models/RatingCalculationMetricsResponse';

interface Props {
  calculations?: RatingCalculationMetricsResponse;
  isCalculationsError?: boolean;
  isCalculationsLoading?: boolean;
}

const CalculationsCountComponent: React.FC<Props> = ({
  calculations,
  isCalculationsError,
  isCalculationsLoading,
}) => {
  let content = '0';
  if (isCalculationsLoading) {
    content = 'Loading...';
  } else if (isCalculationsError) {
    content = 'Sorry, there was an error.';
  } else if (calculations) {
    const metrics = new CalculationMetrics(calculations);
    content = metrics.getCalculationCount().toString();
  }

  return (
    <div
      className="apl-field-v1 apl-display-flex apl-flex-column apl-align-items-start"
      style={{ marginRight: '20px' }}
    >
      <label className="apl-field__label apl-mr" htmlFor="calculation-count-label">
        Calculations
      </label>
      <p className="apl-my-none" id="calculation-count-field" data-testid="calculation-count-field">
        {content}
      </p>
    </div>
  );
};

export default CalculationsCountComponent;
