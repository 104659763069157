import React from 'react';
import { EnrichedPlanLinks } from './PlanLinkHistory';
import { EuiCard, EuiBasicTable, EuiLink } from '@elastic/eui';
import { formatPlanLinkRemovalDate } from './PlanLinkRemovalDate';

interface PlanLinkHistoryTableProps {
  data: EnrichedPlanLinks;
}

const columns = [
  {
    field: 'planName',
    name: 'Plan name',
    render: (planName: string, item: any) => (
      <EuiLink href={`/plans/${item.planId}`} className="euiLink">
        {planName || 'N/A'}
      </EuiLink>
    ),
  },
  {
    field: 'providerName',
    name: 'Provider',
    render: (providerName: string, item: any) => (
      <EuiLink href={`/providers/${item.providerId}/plans`} className="euiLink">
        {providerName}
      </EuiLink>
    ),
  },
  {
    field: 'removedAt',
    name: 'Removed at',
    render: (removedAt: string) => (removedAt ? formatPlanLinkRemovalDate(removedAt) : 'N/A'),
  },
];

const PlanLinkHistoryTable = ({ data }: PlanLinkHistoryTableProps) => {
  return (
    <EuiCard title="" paddingSize="none">
      <EuiBasicTable items={data.planLinks} columns={columns} rowHeader="planName" />
    </EuiCard>
  );
};

export default PlanLinkHistoryTable;
