import { useTranslation } from 'react-i18next';

/**
 * Hook that builds on the i18n translation hook useTranslation
 * to return the tax type.
 *
 * @returns {string} the tax type
 *
 * @example
 * // returns "GST"
 * useTaxTypeTranslation();
 *
 * @example
 * // returns "VAT"
 * useTaxTypeTranslation();
 */
export default function useTaxTypeTranslation(): string {
  const { t: translate } = useTranslation();

  function translateTaxType() {
    return translate('monetary.taxType');
  }

  return translateTaxType();
}
