/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ImportResult } from '../models/ImportResult';
import type { PlanExport } from '../models/PlanExport';
import type { PlanExportV1 } from '../models/PlanExportV1';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ImportService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @deprecated
     * Imports Plans from an exported json file
     * @param requestBody Json file data describing the Plans, Qualifiers and Providers to import. Must include at least one Plan or one Provider to import.
     * @returns ImportResult A plan import result object containing details of the succeeding and failing imports for each of the Plans, Qualifiers and Providers.
     * @throws ApiError
     */
    public importPlans(
        requestBody: PlanExportV1,
    ): CancelablePromise<ImportResult> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/import',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Imports Plans from an exported json file
     * @param requestBody Json file data describing the Plans, Qualifiers and Providers to import. Must include at least one Plan or one Provider to import.
     * @returns ImportResult A plan import result object containing details of the succeeding and failing imports for each of the Plans, Qualifiers and Providers.
     * @throws ApiError
     */
    public importPlansV2(
        requestBody: PlanExport,
    ): CancelablePromise<ImportResult> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/import',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
