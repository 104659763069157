/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ReadingImport } from '../models/ReadingImport';
import type { ReadingImportCountResponse } from '../models/ReadingImportCountResponse';
import type { ReadingImportsResponse } from '../models/ReadingImportsResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ReadingImportService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * List reading_import records
     * @returns ReadingImportsResponse OK
     * @throws ApiError
     */
    public listReadingImports(): CancelablePromise<ReadingImportsResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/reading-imports',
        });
    }

    /**
     * Get reading_import record
     * @param id
     * @returns ReadingImport OK
     * @throws ApiError
     */
    public getReadingImport(
        id: number,
    ): CancelablePromise<ReadingImport> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/reading-imports/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `Unauthorized`,
                404: `The specified resource was not found`,
            },
        });
    }

    /**
     * Count reading_import records
     * @returns ReadingImportCountResponse OK
     * @throws ApiError
     */
    public countReadingImports(): CancelablePromise<ReadingImportCountResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/reading-imports/count',
        });
    }

}
