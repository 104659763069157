import React from 'react';
import cx from 'classnames';
import { useCurrencyTranslation } from 'hooks';
import { format, subSeconds } from 'date-fns';
import { Link, useNavigate } from 'react-router-dom';
import { Permission } from '../../../auth/getPermissions';
import { newMarketDate } from '../../../util/helper-func';
import {
  Invoice,
  INVOICE_STATUS_PENDING,
  INVOICE_STATUS_REVERSED,
  useReverseBill,
} from '../../billing/billingInvoicesApi';
import AuthorizedAction from '../../common/AuthorizedAction';
import ContextMenuButton from '../../common/ContextMenuButton';
import LoadingSpinner from '../../common/LoadingSpinner';
import { SupplyPeriodSummary } from '../connectionsApi';

const dateFormat = 'd MMM yyyy';

type InvoiceRowProps = Invoice & {
  index: number;
  connection: string;
  period: SupplyPeriodSummary;
};

const InvoiceRow = ({
  connection,
  context,
  data,
  index,
  period,
  status,
  _links,
}: InvoiceRowProps) => {
  const { translateCurrency } = useCurrencyTranslation();
  const navigate = useNavigate();

  const invoiceId = _links[0].href.split('/').pop()?.split('?')[0];
  const created = data?.date ? format(newMarketDate(data.date), dateFormat) : '-';

  // convert to date objects to format as required
  const fromDate = context?.startsAt ? newMarketDate(context.startsAt) : null;
  const toDate = context?.endsAt ? subSeconds(newMarketDate(context.endsAt), 1) : null;

  // used for display in table
  const from = fromDate ? format(fromDate, dateFormat) : '';
  const to = toDate ? format(toDate, dateFormat) : '';

  const chargesFrom = fromDate ? format(fromDate, 'yyyy-MM-dd') : '';

  const chargesTo = toDate ? format(toDate, 'yyyy-MM-dd') : '';

  const formattedTotalAmount = translateCurrency(data?.totals?.total);

  const { mutateAsync, error, isLoading } = useReverseBill(invoiceId);

  if (error) {
    let errorText = error;
    if (!(error instanceof String)) {
      errorText = JSON.stringify(error);
    }
    console.error(new Error(errorText as any));
  }

  const invoiceLinkSearch = new URLSearchParams({
    agreementId: period.externalSupplyAgreementId,
  });
  invoiceLinkSearch.set('spid', period.id);

  return (
    <tr className="apl-table-v1__row" data-testid={`invoice-row-${invoiceId}`} key={invoiceId}>
      <td>
        {invoiceId ? (
          <Link
            className="apl-color-primary"
            to={`/connections/${connection}/invoice/${invoiceId}?${invoiceLinkSearch.toString()}`}
          >
            {data.invoiceNumber}
          </Link>
        ) : status === INVOICE_STATUS_PENDING ? (
          'Pending'
        ) : (
          data.invoiceNumber
        )}
      </td>
      <td>
        {data.invoiceType === 'finance'
          ? 'Finance Invoice'
          : data.isCustomerGenerated
          ? 'Customer Generated'
          : 'Invoice'}
      </td>
      <td>{created}</td>
      <td>{`${from} - ${to}`}</td>
      <td>{`${formattedTotalAmount}`}</td>
      <td className="apl-display-flex apl-flex-row">
        <button
          className={cx(
            'apl-button-v1',
            'apl-display-flex',
            'apl-flex-row',
            'apl-align-items-center'
          )}
          disabled={status === INVOICE_STATUS_REVERSED}
          onClick={async () => {
            await mutateAsync();
          }}
        >
          Reverse invoice
          {isLoading && <LoadingSpinner extraClasses="apl-ml-xs" />}
        </button>
        <ContextMenuButton testIdSuffix={`row-${index}`} width="180px">
          <div className="context-menu__block">
            <AuthorizedAction
              extraClasses="context-menu__button"
              onClick={() => {
                const search = new URLSearchParams({
                  from: chargesFrom,
                  to: chargesTo,
                  external_supply_agreement_id: period.externalSupplyAgreementId,
                  contracted_party: period.owner,
                });
                search.set('spid', period.id);
                navigate(`/connections/${connection}/charges?${search.toString()}`);
              }}
              permission={Permission.CONNECTION_VIEW}
              removeDefaultClass={true}
              removeDefaultWeight={true}
            >
              View charge sets
            </AuthorizedAction>
          </div>
        </ContextMenuButton>
      </td>
    </tr>
  );
};

export default InvoiceRow;
