/**
 * Method to get the market function given its functionName.
 * @param functionName the function name.
 * @param defaultValue the value to return if the functionName is not present.
 * @param marketFunctionFlags the market function flags list.
 * @returns the function value.
 */
function getMarketFunction(
  functionName: string,
  defaultValue: boolean | string | undefined,
  marketFunctionFlags: { name: string; data: Record<string, string | boolean> }
): boolean | string | undefined {
  const marketFunctions: Map<string, boolean | string> = new Map<string, boolean | string>(
    Object.entries(marketFunctionFlags?.data || {})
  );

  // if it has the data but the requested key is not present this is a configuration error
  // and will need to be added to the genericList DB table
  // genericList.name='MARKET_FUNCTION', genericList.data += functionName(string):value(boolean)
  if (marketFunctionFlags?.data && marketFunctions.get(functionName) == undefined) {
    console.error(
      new Error(
        'Configuration error value is missing from generic_list MARKET_FUNCTION ' + functionName
      )
    );
  }
  const result = marketFunctions.get(functionName) ?? defaultValue;

  if (result == undefined) console.error('Market Not Found');
  return result;
}

export { getMarketFunction };
