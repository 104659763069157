/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { LossFactorRecord } from '../models/LossFactorRecord';
import type { LossFactorRecordList } from '../models/LossFactorRecordList';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class TestService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Lists loss factor records
     * @returns LossFactorRecordList OK
     * @throws ApiError
     */
    public listLossFactorRecords(): CancelablePromise<LossFactorRecordList> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/test/loss-factor-records',
            errors: {
                401: `Error response`,
                500: `Error response`,
            },
        });
    }

    /**
     * Create loss factor record
     * @param requestBody
     * @returns LossFactorRecord Created
     * @throws ApiError
     */
    public createLossFactorRecord(
        requestBody: LossFactorRecord,
    ): CancelablePromise<LossFactorRecord> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/test/loss-factor-records',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Error response`,
                401: `Error response`,
                500: `Error response`,
            },
        });
    }

    /**
     * Gets a loss factor record.
     * @param id
     * @returns LossFactorRecord OK
     * @throws ApiError
     */
    public getLossFactorRecord(
        id: string,
    ): CancelablePromise<LossFactorRecord> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/test/loss-factor-records/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `Error response`,
                404: `Error response`,
                500: `Error response`,
            },
        });
    }

    /**
     * Deletes the loss factor record with the specified id
     * @param id
     * @returns void
     * @throws ApiError
     */
    public deleteLossFactorRecord(
        id: string,
    ): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/test/loss-factor-records/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `Error response`,
                404: `Error response`,
                500: `Error response`,
            },
        });
    }

}
