import React, { FunctionComponent } from 'react';
import './ButtonAsLinkStyles.scss';

interface ButtonAsLinkProps {
  children?: React.ReactNode;
  onClick: () => void;
  className?: string;
  disabled: boolean;
  type: 'button' | 'submit' | 'reset' | undefined;
}

const buttonLinkStyles = {
  background: 'none',
  border: 'none',
  padding: 0,
  textDecoration: 'underline',
  cursor: 'pointer',
};

const ButtonAsLink: FunctionComponent<ButtonAsLinkProps> = ({
  disabled,
  children,
  className,
  onClick,
  type,
}) => {
  return (
    <button
      style={buttonLinkStyles}
      disabled={disabled}
      className={`${className ? className : ''} button_as_link`}
      onClick={onClick}
      type={type}
    >
      {children}
    </button>
  );
};

export default ButtonAsLink;
