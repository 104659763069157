import cx from 'classnames';
import React, { FunctionComponent, useState } from 'react';
import PlanSummaryRow from './PlanSummaryRow';
import Card from '../layout/Card';
import { capitaliseFirstText } from '../../util/helper-func';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { PlanSummary } from '../../api/openapi/rating-config';
import getConfig from '../../config/getConfig';

interface PlansListProps {
  planSummaries: PlanSummary[];
}

const PlanSummaryList: FunctionComponent<PlansListProps> = ({ planSummaries }) => {
  const [states, setStates] = useState<Record<string, boolean>>({
    published: true,
    draft: true,
    deprecated: true,
    archived: false,
  });

  const {
    fbau370AddTimezoneParameterToPlanDefinition, // LD client key for fbau-370-add-timezone-parameter-to-plan-definition
  } = useFlags();
  const { shareablePlans } = getConfig();

  return (
    <Card className="half_xl">
      <div className="apl-pb">
        <fieldset
          className={cx('apl-fieldset__fieldset', 'apl-p-none', 'apl-m-none')}
          style={{
            border: 'none',
          }}
        >
          <div className={cx('apl-display-flex', 'apl-flex-row', 'apl-flex-nowrap')}>
            <legend
              className="apl-p-none apl-mr apl-mb-none"
              style={{
                fontSize: '14px',
                fontWeight: 600,
              }}
            >
              Show plan states
            </legend>
            <div className="apl-fieldset__fields">
              <div className="apl-checkbox-group">
                {Object.keys(states).map((key) => {
                  return (
                    <div
                      className="apl-checkbox-v1 apl-mr-xs"
                      key={`plan-state-filter-option-${key}`}
                    >
                      <input
                        className="apl-checkbox__input"
                        id={`${key}-field`}
                        name={key}
                        onChange={(e) =>
                          setStates({
                            ...states,
                            [key]: e.target.checked,
                          })
                        }
                        type="checkbox"
                        checked={states[key]}
                      />
                      <label className="apl-checkbox__label" htmlFor={`${key}-field`}>
                        {capitaliseFirstText(key)}
                      </label>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </fieldset>
      </div>
      <table className="apl-table-v1">
        <thead>
          <tr>
            <th>Plan name</th>
            {shareablePlans && <th>Plan scope</th>}
            {fbau370AddTimezoneParameterToPlanDefinition && <th>Timezone</th>}
            <th>Charges</th>
            <th>Plan state</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {planSummaries
            .filter((s) => s?.planState && states[s.planState.toLowerCase()])
            .map((s: PlanSummary, index: number) => (
              <PlanSummaryRow key={index} planSummary={s} />
            ))}
        </tbody>
      </table>
    </Card>
  );
};

export default PlanSummaryList;
