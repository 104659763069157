/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type {BaseHttpRequest} from './core/BaseHttpRequest';
import type {OpenAPIConfig} from './core/OpenAPI';
import {AxiosHttpRequest} from './core/AxiosHttpRequest';
import {MeasurementPointsService} from './services/MeasurementPointsService';
import {MonitoringService} from './services/MonitoringService';
import {ReadingImportService} from './services/ReadingImportService';
import {TestService} from './services/TestService';
import {UsageService} from './services/UsageService';
import {UsageChargeFiltersService} from './services/UsageChargeFiltersService';
import {UsageV2Service} from './services/UsageV2Service';
import {TestCacheEntriesService} from "./services/TestCacheEntriesService";
import {TruncateService} from "./services/TruncateService";

type HttpRequestConstructor = new (config: OpenAPIConfig) => BaseHttpRequest;

export class TelemetryClient {

    public readonly measurementPoints: MeasurementPointsService;
    public readonly monitoring: MonitoringService;
    public readonly readingImport: ReadingImportService;
    public readonly test: TestService;
    public readonly testCacheEntries: TestCacheEntriesService;
    public readonly truncate: TruncateService;
    public readonly usage: UsageService;
    public readonly usageChargeFilters: UsageChargeFiltersService;
    public readonly usageV2: UsageV2Service;

    public readonly request: BaseHttpRequest;

    constructor(config?: Partial<OpenAPIConfig>, HttpRequest: HttpRequestConstructor = AxiosHttpRequest) {
        this.request = new HttpRequest({
            BASE: config?.BASE ?? 'http://localhost:8083',
            VERSION: config?.VERSION ?? '0',
            WITH_CREDENTIALS: config?.WITH_CREDENTIALS ?? false,
            CREDENTIALS: config?.CREDENTIALS ?? 'include',
            TOKEN: config?.TOKEN,
            USERNAME: config?.USERNAME,
            PASSWORD: config?.PASSWORD,
            HEADERS: config?.HEADERS,
            ENCODE_PATH: config?.ENCODE_PATH,
        });

        this.measurementPoints = new MeasurementPointsService(this.request);
        this.monitoring = new MonitoringService(this.request);
        this.readingImport = new ReadingImportService(this.request);
        this.test = new TestService(this.request);
        this.testCacheEntries = new TestCacheEntriesService(this.request);
        this.truncate = new TruncateService(this.request);
        this.usage = new UsageService(this.request);
        this.usageChargeFilters = new UsageChargeFiltersService(this.request);
        this.usageV2 = new UsageV2Service(this.request);
    }
}

