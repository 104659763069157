import React, { FunctionComponent } from 'react';
import AuthorizedAction from '../common/AuthorizedAction';
import { Permission } from '../../auth/getPermissions';

import './Buttons.scss';

interface EditButtonGroupProps {
  isDisabled: boolean;
  isEdit: boolean;
  onCancel: () => void;
  onEdit: () => void;
  onSave: () => any;
  permission: Permission;
  testIdPrefix?: string;
}

export const EditButtonGroup: FunctionComponent<EditButtonGroupProps> = ({
  isDisabled,
  isEdit,
  onCancel,
  onEdit,
  onSave,
  permission,
  testIdPrefix,
}) => {
  const dataTestIdPrefix = testIdPrefix ? testIdPrefix : 'edit-group';

  if (isEdit) {
    return (
      <div>
        <AuthorizedAction
          data-testid={`${dataTestIdPrefix}-btn-cancel`}
          isDisabled={isDisabled}
          onClick={onCancel}
          permission={permission}
          title={isDisabled ? 'Sorry, this is not allowed.' : undefined}
        >
          Cancel
        </AuthorizedAction>
        <AuthorizedAction
          extraClasses="is-primary"
          isDisabled={isDisabled}
          onClick={onSave}
          permission={permission}
          testId={`${dataTestIdPrefix}-btn-save`}
          title={isDisabled ? 'Sorry, this is not allowed.' : undefined}
        >
          Save
        </AuthorizedAction>
      </div>
    );
  }

  return (
    <AuthorizedAction
      onClick={onEdit}
      permission={permission}
      testId={`${dataTestIdPrefix}-btn-edit`}
    >
      Edit
    </AuthorizedAction>
  );
};
