/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { RangeToken } from './RangeToken';

export type Operator = (RangeToken & {
    operatorType: Operator.operatorType;
});

export namespace Operator {

    export enum operatorType {
        INTERSECT = 'INTERSECT',
        MINUS = 'MINUS',
        UNION = 'UNION',
    }


}

