/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Error } from '../models/Error';
import type { TestDiscount } from '../models/TestDiscount';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class TestSupplyAgreementDiscountsService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @param id
     * @returns TestDiscount OK
     * @returns Error Error
     * @throws ApiError
     */
    public getTestSupplyAgreementDiscountsById(
        id: string,
    ): CancelablePromise<Array<TestDiscount> | Error> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/test/configuration/supply-agreements/{id}/discounts',
            path: {
                'id': id,
            },
        });
    }

}
