/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Error } from '../models/Error';
import type { Invoice } from '../models/Invoice';
import type { InvoiceContext } from '../models/InvoiceContext';
import type { InvoicesResult } from '../models/InvoicesResult';
import type { UpdateInvoice } from '../models/UpdateInvoice';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class InvoiceService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @param limit
     * @param offset
     * @param externalSupplyAgreementId A single external Supply Agreement ID
     * @param externalSupplyAgreementIds An array of external Supply Agreement IDs
     * @param account
     * @param startsAt
     * @param endsAt
     * @returns InvoicesResult OK
     * @returns Error Error
     * @throws ApiError
     */
    public getInvoices(
        limit: number = 20,
        offset?: number,
        externalSupplyAgreementId?: string,
        externalSupplyAgreementIds?: Array<string>,
        account?: string,
        startsAt?: string,
        endsAt?: string,
    ): CancelablePromise<InvoicesResult | Error> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/billing/invoices',
            query: {
                'limit': limit,
                'offset': offset,
                'externalSupplyAgreementId': externalSupplyAgreementId,
                'externalSupplyAgreementIds': externalSupplyAgreementIds,
                'account': account,
                'startsAt': startsAt,
                'endsAt': endsAt,
            },
        });
    }

    /**
     * @param requestBody
     * @returns Invoice OK
     * @returns Error Error
     * @throws ApiError
     */
    public requestInvoiceGeneration(
        requestBody: InvoiceContext,
    ): CancelablePromise<Invoice | Error> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/billing/invoices',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id
     * @returns Invoice OK
     * @returns Error Error
     * @throws ApiError
     */
    public getInvoiceById(
        id: string,
    ): CancelablePromise<Invoice | Error> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/billing/invoices/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id
     * @param requestBody
     * @returns Invoice OK
     * @returns Error Error
     * @throws ApiError
     */
    public updateInvoiceById(
        id: string,
        requestBody: UpdateInvoice,
    ): CancelablePromise<Invoice | Error> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/billing/invoices/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id
     * @returns Invoice OK
     * @returns Error Error
     * @throws ApiError
     */
    public manualInvoiceGeneration(
        id: string,
    ): CancelablePromise<Invoice | Error> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/billing/invoices/{id}',
            path: {
                'id': id,
            },
        });
    }

}
