import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import apiClient from '../../api/apiClient';
import { ChargeTag } from '../connections/connectionsApi';

const providersBaseEndpoint = '/providers';

export interface Provider {
  code: string;
  defaultChargeTag: ChargeTag;
  id: string;
  name: string;
}

export const useProviders = () => {
  return useQuery(['providers'], async () => {
    return apiClient().get(providersBaseEndpoint);
  });
};

export interface ProviderSummary {
  providerId: string;
  providerName: string;
  plansCount: number;
  code: string;
  defaultChargeTag: ChargeTag;
}

export const useProviderSummary = () => {
  return useQuery(['providers-summary'], async () => {
    return apiClient().get(`${providersBaseEndpoint}/summaries`);
  });
};

export interface ProviderBody {
  code: string;
  defaultChargeTag: ChargeTag;
  name: string;
}

export const useCreateProvider = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (providerBody: ProviderBody) => {
      return apiClient().post(providersBaseEndpoint, JSON.stringify(providerBody));
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['providers']);
        queryClient.invalidateQueries(['providers-summary']);
      },
    }
  );
};

export const useUpdateProvider = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ id, code, defaultChargeTag, name }: Provider) => {
      return apiClient().put(
        `${providersBaseEndpoint}/${id}`,
        JSON.stringify({
          code,
          defaultChargeTag,
          name,
        })
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['providers']);
        queryClient.invalidateQueries(['providers-summary']);
      },
    }
  );
};
