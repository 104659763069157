import { SupplyPeriodPlanSummary } from '../connectionsApi';

export const filterSupplyPeriodPlanSummaries = (
  planSummaries: SupplyPeriodPlanSummary[],
  isBestOfferProvider: boolean,
  bestOfferProviderIds: string
): SupplyPeriodPlanSummary[] => {
  const bestOfferProviderIdsArray = bestOfferProviderIds.split(',').map((id) => id.trim());
  return planSummaries.filter((plan: SupplyPeriodPlanSummary) =>
    bestOfferProviderIdsArray.includes(plan.providerId) ? isBestOfferProvider : !isBestOfferProvider
  );
};

export const sortSupplyPeriodPlanSummaries = (
  planSummaries: SupplyPeriodPlanSummary[]
): SupplyPeriodPlanSummary[] => {
  return planSummaries.sort((a, b) => {
    // Convert startDate and endDate strings to Date objects for comparison
    const aEndDate = a.endDate ? new Date(a.endDate) : null;
    const bEndDate = b.endDate ? new Date(b.endDate) : null;

    // Check if endDate is null for both objects
    if (!aEndDate && !bEndDate) {
      // Sort by planName ascending
      if (a.planName > b.planName) {
        return 1;
      }
      if (a.planName < b.planName) {
        return -1;
      }
      return 0;
    }

    // If a.endDate is null, it should come before b.endDate
    if (!aEndDate) {
      return -1;
    }
    // If b.endDate is null, it should come before a.endDate
    if (!bEndDate) {
      return 1;
    }

    // Both have non-null endDate, sort by endDate descending then by planName ascending
    if (aEndDate < bEndDate) {
      return 1;
    }
    if (aEndDate > bEndDate) {
      return -1;
    }
    if (a.planName > b.planName) {
      return 1;
    }
    if (a.planName < b.planName) {
      return -1;
    }
    return 0;
  });
};
