import React, { useMemo, useState } from 'react';
import cx from 'classnames';
import { endOfMonth, formatISO, startOfMonth } from 'date-fns';
import { useErrorTranslation } from 'hooks';
import { useNavigate } from 'react-router';
import { exceptionCategory, generateCategories } from 'components/errors/errorCategories';
import { PublishedError, useErrors } from 'components/errors/errorsApi';
import { Widget } from 'components/dashboard/Dashboard';
import { Permission } from 'auth/getPermissions';
import { FieldOption, formatTimestamp, generateDateOptions } from 'util/helper-func';
import AuthorizedAction from 'components/common/AuthorizedAction';
import getConfig from 'config/getConfig';

const config = getConfig();
const ErrorFeedWidget = () => {
  const { translateError } = useErrorTranslation();

  const categories = useMemo(() => exceptionCategory.concat(generateCategories()), []);
  const options = useMemo(() => generateDateOptions(true, 24), []);

  const initialDate = new Date((options.slice().shift() as FieldOption).value);
  const [date, setDate] = useState<Date>(initialDate);

  const fromDate = formatISO(startOfMonth(date), { representation: 'date' });
  const toDate = formatISO(endOfMonth(date), { representation: 'date' });

  const navigate = useNavigate();

  const {
    data,
    isInitialLoading: isLoading,
    isError,
    error,
  } = useErrors({
    fromDate,
    toDate,
    showSystemErrors: config.showSystemErrors,
  });

  if (error) console.error(error as any);

  const items = data?.publishedErrors.slice(0, 5) ?? [];
  const totalItems = data?.publishedErrors ? data.publishedErrors.length : 0;

  return (
    <Widget
      headerComponent={
        <>
          <h3 className="widget__title widget__title--inline apl-h3">
            Error feed
            <select
              className="apl-select-v1_0"
              onChange={(event) => setDate(new Date(event?.target.value))}
              value={formatISO(date, { representation: 'date' })}
            >
              {options.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </h3>
        </>
      }
      width="default"
    >
      <div className="widget__list">
        {isError && <p className="widget__list-item">Sorry, there was an error</p>}
        {isLoading && <p className="widget__list-item">Loading...</p>}
        {items &&
          items.length > 0 &&
          items.map((err: PublishedError, i: number) => {
            if (!config.showSystemErrors) {
              const foundExceptionCategory = exceptionCategory.find((category) =>
                category.urns?.some((categoryPrependUrn) => err.urn?.includes(categoryPrependUrn))
              );
              if (foundExceptionCategory) {
                return;
              }
            }

            const foundCategory = categories.find((category) =>
              category.urns?.some((categoryPrependUrn) => err.urn?.includes(categoryPrependUrn))
            );

            const errorCategory = foundCategory ? foundCategory.tabName : 'Other';

            return (
              <div
                className={cx(
                  'widget__list-item',
                  'apl-display-flex',
                  'apl-flex-row',
                  'apl-justify-content-between',
                  'apl-align-items-start'
                )}
                key={`error-${i}`}
              >
                <div>
                  <p className="apl-my-none">
                    <strong>{errorCategory ?? ''}</strong>
                  </p>
                  <p className="apl-my-none">
                    {err?.message
                      ? `${translateError(err)
                          .substring(0, 25)
                          .replaceAll(/\{\{.*\}\}/g, '<Not Supplied>')}...`
                      : '<Not Supplied>'}
                  </p>
                </div>
                <span style={{ color: 'var(--muted-color)' }}>
                  {formatTimestamp(err._publishedAt)}
                </span>
              </div>
            );
          })}
        {items && items.length === 0 && <p className="widget__list-item">There are no errors.</p>}
      </div>
      <div
        className={cx(
          'apl-display-flex',
          'apl-flex-row',
          'apl-justify-content-between',
          'apl-align-items-center',
          'apl-p'
        )}
      >
        <AuthorizedAction
          onClick={() =>
            navigate(
              `/dashboard/error-feed/all?date=${formatISO(date, { representation: 'date' })}`
            )
          }
          permission={Permission.CONNECTION_VIEW}
          removeDefaultWeight={true}
        >
          View all errors
        </AuthorizedAction>
        <span>
          Showing {items.length} of {totalItems}
        </span>
      </div>
    </Widget>
  );
};

export default ErrorFeedWidget;
