import React, { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';

interface LinkProps {
  children?: React.ReactNode;
  to: string;
}

const PrimaryLink: FunctionComponent<LinkProps> = ({ to, children }) => {
  return (
    <Link to={to}>
      <span className="apl-color-primary">{children}</span>
    </Link>
  );
};

export default PrimaryLink;
