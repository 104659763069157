/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { Error } from './Error';

export type ErrorModel = {
    message?: string;
    /**
     * Where SERVER indicates  that there was an internal problem processing the request, ENTITY means there was a problem (either business or formatting) with the object, and CONSTRAINT means one or more fields were not valid
     */
    type?: ErrorModel.type;
    /**
     * status code of response
     */
    status?: number;
    errors?: Array<Error>;
};

export namespace ErrorModel {

    /**
     * Where SERVER indicates  that there was an internal problem processing the request, ENTITY means there was a problem (either business or formatting) with the object, and CONSTRAINT means one or more fields were not valid
     */
    export enum type {
        SERVER = 'SERVER',
        ENTITY = 'ENTITY',
        CONSTRAINT = 'CONSTRAINT',
    }


}

