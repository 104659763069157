import '../dashboard/missing-plans/SearchFilter.scss';
import { useListData } from '../../list-data/ListDataContext';
import { FieldOption } from '../../util/helper-func';
import LossFactorCodes, { JURISDICTION_CODE_URN_BASE, lossFactorKeyUrn } from './LossFactorCodes';
import React from 'react';

const LossFactorCodesPage = () => {
  // construct array of all loss factor keys, e.g., ACT, NSW *****************************
  // for AU, get jurisdictions from https://git.fluxfederation.com/powerapps/commercial-industrial/-/blob/master/rating-config/rating-api/src/main/resources/com/fluxfederation/aib/rating/config/services/genericlist/implementation/AU_MARKET_FUNCTION.json?ref_type=heads
  const [marketFunction] = useListData(['MARKET_FUNCTION']);
  const marketJurisdictions: FieldOption[] =
    lossFactorKeyUrn == JURISDICTION_CODE_URN_BASE // only populate if AU env
      ? (marketFunction?.data['urn:flux:rating-config:market:jurisdictions'] as FieldOption[])
      : [];
  // marketJurisdictions value and label from the rating-config database are the wrong way around
  // e.g., {"label": "ACT", "value": "Australian Capital Territory"}
  const auLossFactorKeyOptions: FieldOption[] =
    marketJurisdictions == undefined // useListData unreliable and can be undefined until auto-fetched again
      ? []
      : marketJurisdictions.map((obj) => {
          return {
            value: obj.label,
            label: obj.value,
          };
        });

  const lossFactorKeyOptions: FieldOption[] = [
    {
      value: '-',
      label: '-', // do not filter by loss factor key
    },
  ].concat(auLossFactorKeyOptions);

  return (
    <LossFactorCodes
      backLink={`/dashboard`}
      title={`Loss factor codes`}
      lossFactorKeyOptions={lossFactorKeyOptions}
      isProviderSet={false}
    />
  );
};

export default LossFactorCodesPage;
