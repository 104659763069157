import React, { FunctionComponent } from 'react';
import { ErrorModel } from '../../../api/ErrorDisplay';
import { MeasurementPoint as MeasurementPointType } from 'api/openapi/telemetry';
import MeasurementPointForm from './MeasurementPointForm';
import { useUpdateMeasurementPoint } from 'components/telemetry/telemetryUsageApi';

interface EditMeasurementPointProps {
  id: string;
  connectionId: string;
  measurementPoint: MeasurementPointType;
  close: () => void;
}

const EditMeasurementPoint: FunctionComponent<EditMeasurementPointProps> = ({
  id,
  connectionId,
  measurementPoint,
  close,
}) => {
  const { mutateAsync, isLoading: isSubmitting, error } = useUpdateMeasurementPoint();

  const editMeasurementPoint = async (measurementPoint: MeasurementPointType) => {
    const result = await mutateAsync(measurementPoint);

    if (result) {
      close();
    }
  };

  return (
    <div className="apl-form-layout-v1" data-testid="edit-measurement-point-form">
      <h2>Edit Measurement Point</h2>

      <MeasurementPointForm
        id={id}
        connectionId={connectionId}
        measurementPoint={measurementPoint}
        close={close}
        error={error as ErrorModel}
        isUpdating={isSubmitting}
        submitFunction={editMeasurementPoint}
        readOnly={false}
      />
    </div>
  );
};

export default EditMeasurementPoint;
