import React, { useEffect, useMemo, useState } from 'react';
import {
  differenceInCalendarDays,
  format,
  getDaysInMonth,
  startOfMonth,
  subMonths,
} from 'date-fns';
import { Link, useParams } from 'react-router-dom';
import EmptyTableRow from '../../common/EmptyTableRow';
import { Widget } from '../../dashboard/Dashboard';
import {
  Measurement,
  useMeasurementPoints,
  useUsageSummaryV2,
} from '../../telemetry/telemetryUsageApi';
import {
  capitaliseFirstText,
  FieldOption,
  generateDateOptionsBaseFromSupplyPeriod,
  newMarketDate,
  newUTCDate,
  startOfDayFormatted,
} from '../../../util/helper-func';
import { SupplyPeriodSummary } from '../connectionsApi';
import {
  AggregatedSummary,
  MeasurementPoint,
  UsageSummaryV2Item,
} from '../../../api/openapi/telemetry';
import {
  buildScopedToQueryField,
  ItemXMeasurementPoint,
  itemXMeasurementPointComparator,
} from '../measurement-points/measurementPointHelpers';
import { getMeasurementPointOption, measurementsByUnit } from '../metering/MeteringSummaryHelper';
import getConfig from 'config/getConfig';
import { addDaysInZone, addMonthsInZone, startOfDayInZone } from '@developers/flux-date-util';

export type FlowDirection = 'IN' | 'OUT';

export const generateFlowOptions = () => {
  return ['IN', 'OUT'].map((direction) => ({
    label: capitaliseFirstText(direction),
    value: direction,
  }));
};

export const findMeasurement = (unit: string, measurements: Measurement[]) => {
  const measurement =
    measurements && measurements.length ? measurements.find((m) => m.unit === unit)?.value : null;

  return measurement ?? '-';
};

interface MetricDataComponentProps {
  supplyPeriod: SupplyPeriodSummary;
}

const MeteringDataComponentV2 = ({ supplyPeriod }: MetricDataComponentProps) => {
  const flowOptions = useMemo(() => generateFlowOptions(), []);
  const [fromOptions, setFromOptions] = useState<FieldOption[]>([]);
  const [from, setFrom] = useState<string>('');
  const [flow, setFlow] = useState<FlowDirection>('IN');
  const [startsAt, setStartsAt] = useState<Date>();
  const [endsAt, setEndsAt] = useState<Date>();

  useEffect(() => {
    const dateOptions = generateDateOptionsBaseFromSupplyPeriod(supplyPeriod);
    setFromOptions(dateOptions);
    setFrom(getStartDateFromOption(dateOptions));
  }, [JSON.stringify(supplyPeriod)]);

  const { id } = useParams<{ id: string }>();

  const isSupplyPeriodCancelled = supplyPeriod.status === 'CANCELLED';

  // We call it without startsAt and endsAt to use already cached information
  const scopedToQueryField = buildScopedToQueryField(id ?? '');
  const {
    data: measurementPointData,
    isError: isMeasurementPointDataError,
    isInitialLoading: isMeasurementPointDataLoading,
  } = useMeasurementPoints(
    {
      scopedTo: scopedToQueryField,
      limit: 10,
      offset: 0,
    },
    {
      enabled: !!scopedToQueryField,
    }
  );

  const {
    data,
    isInitialLoading: isLoading,
    isError,
  } = useUsageSummaryV2(
    {
      startsAt: startOfDayFormatted(startsAt?.toISOString() ?? ''),
      endsAt: startOfDayFormatted(endsAt?.toISOString() ?? ''),
      connectionId: id,
      limit: 31,
      isEnergyGoingToConnection: flow === 'IN',
    },
    {
      enabled: !!startsAt && !!endsAt,
    }
  );

  // adjust the metering data date range base from the supply period
  useEffect(() => {
    if (from && supplyPeriod) {
      const config = getConfig();

      const monthStart = newMarketDate(from);
      const supplyPeriodStartDate = newMarketDate(supplyPeriod.startDate);
      setStartsAt(monthStart > supplyPeriodStartDate ? monthStart : supplyPeriodStartDate);

      const monthEnd = addMonthsInZone(monthStart, 1, config.marketTimezone);
      if (supplyPeriod.endDate) {
        const supplyPeriodEndDate = startOfDayInZone(
          addDaysInZone(new Date(supplyPeriod.endDate), 1, config.marketTimezone),
          config.marketTimezone
        );
        setEndsAt(monthEnd < supplyPeriodEndDate ? monthEnd : supplyPeriodEndDate);
      } else {
        setEndsAt(monthEnd);
      }
    }
  }, [from, supplyPeriod]);

  const daysInMonth =
    startsAt && endsAt
      ? differenceInCalendarDays(endsAt, startsAt)
      : getDaysInMonth(newUTCDate(from));
  const measurementPointsCount = data?.items?.length ?? 0;
  const hasSummaries =
    data && data.items.flatMap((i: UsageSummaryV2Item) => i.summaries)?.length > 0;
  const monthAsDate = startOfMonth(newMarketDate(startsAt));
  const month = format(monthAsDate, 'yyyy-MM-dd') ?? '';
  const displayMonth = monthAsDate ? format(monthAsDate, 'MMM yyyy') : '-';

  return (
    <Widget className="widget--full" title="Metering data" width="full">
      <div className="table-filter apl-display-flex apl-flex-row apl-align-items-center apl-justify-content-between">
        <div
          className="apl-display-flex apl-flex-row apl-align-items-center"
          style={{
            paddingLeft: '5px',
          }}
        >
          <div className="apl-field-v1 apl-display-flex apl-flex-row apl-align-items-center apl-mr-xs">
            <label className="apl-field__label apl-mr-xs apl-mb-none" htmlFor="from-field">
              Show
            </label>
            <select
              className="apl-select-v1_0"
              id="from-field"
              name="from"
              data-testid="from"
              onChange={(e) => setFrom(e.target.value)}
              value={from}
            >
              {fromOptions.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>
          <div className="apl-field-v1 apl-display-flex apl-flex-row apl-align-items-center">
            <label className="apl-field__label apl-mr-xs apl-mb-none" htmlFor="flow-field">
              Flow
            </label>
            <select
              className="apl-select-v1_0"
              id="flow-field"
              name="flow"
              onChange={(e) => setFlow(e.target.value as FlowDirection)}
              value={flow}
            >
              {flowOptions.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
      <table
        className="apl-table-v1 apl-width-full is-plain zebra-table"
        style={{ borderRadius: 0 }}
      >
        {(isLoading || isMeasurementPointDataLoading) && (
          <tbody>
            <tr>
              <td>Loading...</td>
            </tr>
          </tbody>
        )}
        {(isError || isMeasurementPointDataError) && (
          <tbody>
            <tr>
              <td>Sorry, there was an error.</td>
            </tr>
          </tbody>
        )}
        {data && hasSummaries && !isSupplyPeriodCancelled && (
          <>
            <thead>
              <tr>
                <th className="apl-text-left" rowSpan={2} style={{ verticalAlign: 'bottom' }}>
                  Month
                </th>
                <th className="apl-text-left" rowSpan={2} style={{ verticalAlign: 'bottom' }}>
                  Measurement Point
                </th>
                <th className="apl-text-left" rowSpan={2} style={{ verticalAlign: 'bottom' }}>
                  Complete days
                </th>
                <th className="apl-text-left" rowSpan={2} style={{ verticalAlign: 'bottom' }}>
                  Incomplete days
                </th>
                <th className="apl-text-right" colSpan={3}>
                  Raw consumption
                </th>

                <th className="apl-text-right" colSpan={3}>
                  Distribution loss adjusted consumption
                </th>
              </tr>
              <tr>
                <th className="apl-text-right" style={{ verticalAlign: 'bottom' }}>
                  kWh
                </th>
                <th className="apl-text-right" style={{ verticalAlign: 'bottom' }}>
                  kVAh
                </th>
                <th className="apl-text-right" style={{ verticalAlign: 'bottom' }}>
                  kVArh
                </th>
                <th className="apl-text-right" style={{ verticalAlign: 'bottom' }}>
                  kWh
                </th>
                <th className="apl-text-right" style={{ verticalAlign: 'bottom' }}>
                  kVAh
                </th>
                <th className="apl-text-right" style={{ verticalAlign: 'bottom' }}>
                  kVArh
                </th>
              </tr>
            </thead>
            <tbody>
              {measurementPointData &&
                data.items
                  .map((item: UsageSummaryV2Item) => {
                    const measurementPoint = measurementPointData?.items?.find(
                      (i: MeasurementPoint) => i.id === item.measurementPoint?.id
                    );

                    return new ItemXMeasurementPoint(item, measurementPoint);
                  })
                  .sort((itemXMPA: ItemXMeasurementPoint, itemXMPB: ItemXMeasurementPoint) =>
                    itemXMeasurementPointComparator(itemXMPA, itemXMPB)
                  )
                  .map((itemXMeasurementPoint: ItemXMeasurementPoint, index: number) => {
                    const { item, measurementPoint } = itemXMeasurementPoint;

                    /* Because our business rules has changed on 45 of the second half we
                     implemented the method bellow to calculate the totals on the frontend per measurement point
                     as the API does not provide this functionality.
                   */
                    const itemMeasurements: Array<Measurement> =
                      item.summaries?.flatMap((s: AggregatedSummary) => s.measurements) ??
                      new Array<Measurement>();

                    // calculate and return it by unit
                    const totalValues = measurementsByUnit(itemMeasurements);

                    return (
                      <tr key={`month-summary-${index}`} className="apl-table-v1__row">
                        {index == 0 && (
                          <td
                            className="apl-text-left"
                            rowSpan={measurementPointsCount}
                            style={{
                              borderRight: '1px solid #e6e6e6',
                            }}
                          >
                            {displayMonth}
                          </td>
                        )}
                        <td
                          key={'div-measurement-point-' + item.measurementPoint?.id}
                          className={'table-column'}
                        >
                          <Link
                            className="apl-color-primary"
                            to={`/connections/${id}/metering/month?date=${month}&measurementPointId=${
                              measurementPoint && measurementPoint.id
                            }&measurementPointType=${
                              measurementPoint && measurementPoint.externalIdentifier
                                ? 'Meter'
                                : 'Calculated'
                            }&supply_period=${supplyPeriod.id}`}
                          >
                            {measurementPoint && getMeasurementPointOption(measurementPoint).label}
                          </Link>
                        </td>
                        <td
                          key={'div-complete-days-' + item.measurementPoint?.id}
                          className={'table-column'}
                        >
                          {`${item.numberOfCompleteSummaries ?? 0}/${daysInMonth}`}
                        </td>
                        <td
                          key={'div-incomplete-days-' + item.measurementPoint?.id}
                          className={'table-column'}
                        >
                          {daysInMonth - (item.numberOfCompleteSummaries ?? 0)}
                        </td>
                        <td className="apl-text-right">{findMeasurement('kWh', totalValues)}</td>
                        <td className="apl-text-right">{findMeasurement('kVAh', totalValues)}</td>
                        <td className="apl-text-right">{findMeasurement('kVArh', totalValues)}</td>
                        <td className="apl-text-right">
                          {findMeasurement('loss_adjusted_kWh', totalValues)}
                        </td>
                        <td className="apl-text-right">
                          {findMeasurement('loss_adjusted_kVAh', totalValues)}
                        </td>
                        <td className="apl-text-right">
                          {findMeasurement('loss_adjusted_kVArh', totalValues)}
                        </td>
                      </tr>
                    );
                  })}
            </tbody>
          </>
        )}
        {((data && measurementPointData && !hasSummaries) || isSupplyPeriodCancelled) && (
          <EmptyTableRow message="Sorry, no metering data found." />
        )}
      </table>
    </Widget>
  );
};

export default MeteringDataComponentV2;

/**
 * Returns either '', the first entry or second entry of the given array.
 * @param fromOptions
 */
const getStartDateFromOption = (fromOptions: FieldOption[]): string => {
  if (!fromOptions || fromOptions.length === 0) {
    return '';
  }
  const startLastMonth = format(startOfMonth(subMonths(new Date(), 1)), 'yyyy-MM-dd');
  const from = fromOptions.find((option) => option.value === startLastMonth);
  if (from) {
    return from.value;
  }
  return fromOptions[0].value;
};
