import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import apiClient from '../../api/apiClient';

export interface GetEquationLinksRequest {
  connection?: string;
}

export interface GetEquationLinksResponse {
  items: Array<EquationLink>;
  totalCount: number;
}

export interface LinkConnectionsRequest {
  equationId: string;
  connections: Array<LinkConnection>;
}

export interface LinkConnection {
  id: string;
  startsAt: string;
  endsAt?: string;
}

export interface EquationLink {
  id: string;
  connectionId: string;
  startsAt: string;
  endsAt: string;
  equation: Equation;
}

export interface Equation {
  id: string;
  name: string;
  description: string;
  expression: string;
}

export interface EquationsResponse {
  equations: Array<Equation>;
}

export const useEquationLinks = (request: GetEquationLinksRequest) => {
  return useQuery<GetEquationLinksResponse>(['equation-links', request.connection], async () => {
    const search = new URLSearchParams();

    (Object.keys(request) as Array<keyof GetEquationLinksRequest>).forEach((key) => {
      if (request[key]) {
        search.set(key, `${request[key]}`);
      }
    });

    return apiClient('telemetry-calculator').get(`/equation-links?${search.toString()}`);
  });
};

export const useEquations = () => {
  return useQuery<EquationsResponse>(['equations'], async () => {
    return apiClient('telemetry-calculator').get(`/equations`);
  });
};

export const useCreateEquationLinks = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (linkConnectionsRequest: LinkConnectionsRequest) => {
      return apiClient('telemetry-calculator').post(
        `/equations/${linkConnectionsRequest.equationId}/connections`,
        JSON.stringify(linkConnectionsRequest)
      );
    },
    {
      onSuccess: async () => {
        return queryClient.invalidateQueries(['equation-links']);
      },
    }
  );
};
