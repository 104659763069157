import React, { FunctionComponent, useState } from 'react';
import Modal from 'react-modal';
import { modalStyles } from '../layout/Modal';
import PrimaryLink from '../page/Links';
import CopyPlan from '../plans/CopyPlan';
import AuthorizedAction from '../common/AuthorizedAction';
import { Permission } from '../../auth/getPermissions';
import { capitaliseFirstText } from '../../util/helper-func';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { PlanSummary } from '../../api/openapi/rating-config';
import getConfig from '../../config/getConfig';
import { planScopeDisplayConversion } from '../plans/planApi';

interface PlanSummaryRowProps {
  planSummary: PlanSummary;
}

const PlanSummaryRow: FunctionComponent<PlanSummaryRowProps> = ({ planSummary }) => {
  const { id, planName, planScope, timezone, chargesCount, planState } = planSummary;
  const {
    fbau370AddTimezoneParameterToPlanDefinition, // LD client key for fbau-370-add-timezone-parameter-to-plan-definition
  } = useFlags();
  const { shareablePlans } = getConfig();
  const [copyPlanIsOpen, setCopyPlanIsOpen] = useState(false);

  const closeCopyPlan = () => setCopyPlanIsOpen(false);
  const openCopyPlan = () => setCopyPlanIsOpen(true);

  return (
    <tr>
      <td>
        <PrimaryLink to={`/plans/${id}`}>{planName}</PrimaryLink>
      </td>
      {shareablePlans && <td>{planScopeDisplayConversion(planScope)}</td>}
      {fbau370AddTimezoneParameterToPlanDefinition && <td>{timezone}</td>}
      <td>{chargesCount}</td>
      <td>{capitaliseFirstText(planState)}</td>
      <td>
        <AuthorizedAction onClick={openCopyPlan} permission={Permission.PLAN_EDIT}>
          Make a copy
        </AuthorizedAction>
      </td>
      <Modal
        isOpen={copyPlanIsOpen}
        onRequestClose={closeCopyPlan}
        shouldCloseOnOverlayClick={false}
        style={modalStyles}
      >
        <CopyPlan close={closeCopyPlan} planId={id} />
      </Modal>
    </tr>
  );
};

export default PlanSummaryRow;
