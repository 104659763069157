/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BaseHttpRequest } from './core/BaseHttpRequest';
import type { OpenAPIConfig } from './core/OpenAPI';
import { AxiosHttpRequest } from './core/AxiosHttpRequest';

import { MonitoringService } from './services/MonitoringService';
import { RatingsService } from './services/RatingsService';
import { TestService } from './services/TestService';
import {RatingCalculatorMetricsService} from "./services/RatingCalculatorMetricsService";

type HttpRequestConstructor = new (config: OpenAPIConfig) => BaseHttpRequest;

export class RatingCalculatorClient {

    public readonly monitoring: MonitoringService;
    public readonly ratings: RatingsService;
    public readonly ratingCalculatorMetricsService: RatingCalculatorMetricsService;
    public readonly test: TestService;

    public readonly request: BaseHttpRequest;

    constructor(config?: Partial<OpenAPIConfig>, HttpRequest: HttpRequestConstructor = AxiosHttpRequest) {
        this.request = new HttpRequest({
            BASE: config?.BASE ?? 'http://localhost:8089',
            VERSION: config?.VERSION ?? '0',
            WITH_CREDENTIALS: config?.WITH_CREDENTIALS ?? false,
            CREDENTIALS: config?.CREDENTIALS ?? 'include',
            TOKEN: config?.TOKEN,
            USERNAME: config?.USERNAME,
            PASSWORD: config?.PASSWORD,
            HEADERS: config?.HEADERS,
            ENCODE_PATH: config?.ENCODE_PATH,
        });

        this.monitoring = new MonitoringService(this.request);
        this.ratings = new RatingsService(this.request);
        this.ratingCalculatorMetricsService = new RatingCalculatorMetricsService(this.request)
        this.test = new TestService(this.request);
    }
}

