import React, { FunctionComponent } from 'react';

import './Form.scss';

interface FormRowProps {
  className?: string;
  children?: React.ReactNode;
}

export const FormRow: FunctionComponent<FormRowProps> = ({ children, className }) => {
  return (
    <div
      className={`apl-form-layout-v1 is-horizontal apl-display-flex apl-flex-row apl-align-items-start ${
        className ? className : ''
      }`}
    >
      {children}
    </div>
  );
};

export const typeaheadStyles = {
  clearIndicator: () => ({
    cursor: 'pointer',
    display: 'flex',
    lineHeight: 1,
    marginRight: '5px',
    width: '15px',
  }),
  container: () => ({
    backgroundColor: 'var(--base-color)',
    border: '1px solid #d3d8da',
    borderRadius: '0.1875rem',
    boxShadow: 'inset 0 1px 1px rgb(0, 0, 0, 0.075)',
    color: 'var(--content-color)',
    display: 'flex',
    flexDirection: 'row' as const,
    fontSize: '0.875rem',
    height: '31px',
    lineHeight: '1.225rem',
    padding: '4px 4px 4px 8px',
    position: 'relative' as const,
  }),
  control: () => ({
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row' as const,
    flexGrow: 1,
    justifyContent: 'space-between',
  }),
  dropdownIndicator: () => ({
    display: 'flex',
    lineHeight: 1,
    width: '16px',
  }),
  group: () => ({}),
  groupHeading: () => ({}),
  indicatorsContainer: () => ({
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row' as const,
  }),
  indicatorSeparator: () => ({}),
  input: () => ({}),
  loadingIndicator: () => ({}),
  loadingMessage: () => ({}),
  menu: () => ({
    backgroundColor: 'var(--tint-bg)',
    borderRadius: '5px',
    boxShadow: '0px 5px 20px rgba(0, 0, 0, 0.3)',
    fontSize: '14px',
    left: '0',
    overflowY: 'scroll' as const,
    padding: '4px 0',
    position: 'absolute' as const,
    top: 'calc(100% + 3px)',
    width: '100%',
    zIndex: 999,
  }),

  menuList: (provided: any) => ({
    ...provided,
  }),
  menuPortal: () => ({}),
  multiValue: () => ({}),
  multiValueLabel: () => ({}),
  multiValueRemove: () => ({}),
  noOptionsMessage: () => ({
    fontSize: '14px',
    padding: '0 8px',
  }),
  option: (provided: any, { isSelected, isFocused }: any) => ({
    backgroundColor: isSelected ? 'var(--content-color)' : isFocused ? '#001AFF' : undefined,
    color: isSelected || isFocused ? 'var(--base-color)' : undefined,
    cursor: 'pointer',
    marginBottom: '2px',
    ':hover': {
      backgroundColor: '#001AFF',
      color: 'var(--base-color)',
    },
    padding: '2px 8px',
  }),
  placeholder: (provided: any, state: { isFocused: any }) => ({
    display: state.isFocused ? 'none' : 'block',
  }),
  singleValue: () => ({}),
  valueContainer: () => ({
    display: 'flex',
  }),
};
