import React, { useEffect, useMemo, useState } from 'react';
import {
  differenceInCalendarDays,
  format,
  getDaysInMonth,
  startOfMonth,
  subMonths,
} from 'date-fns';
import { Link, useParams } from 'react-router-dom';
import EmptyTableRow from '../../common/EmptyTableRow';
import { Widget } from '../../dashboard/Dashboard';
import { Measurement, useUsageSummaryV2 } from '../../telemetry/telemetryUsageApi';
import {
  capitaliseFirstText,
  FieldOption,
  generateDateOptionsBaseFromSupplyPeriod,
  newMarketDate,
  newUTCDate,
  startOfDayFormatted,
} from '../../../util/helper-func';
import { SupplyPeriodSummary } from '../connectionsApi';
import { UsageSummaryV2Item } from '../../../api/openapi/telemetry';
import moment from 'moment/moment';
import getConfig from 'config/getConfig';
import { addDaysInZone, addMonthsInZone, startOfDayInZone } from '@developers/flux-date-util';

export type FlowDirection = 'IN' | 'OUT';

export const generateFlowOptions = () => {
  return ['IN', 'OUT'].map((direction) => ({
    label: capitaliseFirstText(direction),
    value: direction,
  }));
};

export const findMeasurement = (unit: string, measurements: Measurement[]) => {
  const measurement =
    measurements && measurements.length ? measurements.find((m) => m.unit === unit)?.value : null;

  return measurement ?? '-';
};

interface MetricDataComponentProps {
  supplyPeriod: SupplyPeriodSummary;
}

const MeteringDataComponentMerxV2 = ({ supplyPeriod }: MetricDataComponentProps) => {
  const flowOptions = useMemo(() => generateFlowOptions(), []);
  const [fromOptions, setFromOptions] = useState<FieldOption[]>([]);
  const [from, setFrom] = useState<string>('');
  const [flow, setFlow] = useState<FlowDirection>('IN');
  const [startsAt, setStartsAt] = useState<Date>();
  const [endsAt, setEndsAt] = useState<Date>();

  useEffect(() => {
    const dateOptions = generateDateOptionsBaseFromSupplyPeriod(supplyPeriod);
    setFromOptions(dateOptions);
    setFrom(getStartDateFromOption(dateOptions));
  }, [JSON.stringify(supplyPeriod)]);

  const { id } = useParams<{ id: string }>();

  const isSupplyPeriodCancelled = supplyPeriod.status === 'CANCELLED';

  // The time in a day always fixed, it's not up to browser or libraries do choose

  const {
    data,
    isInitialLoading: isLoading,
    isError,
  } = useUsageSummaryV2(
    {
      startsAt: startOfDayFormatted(startsAt?.toISOString() ?? ''),
      endsAt: startOfDayFormatted(endsAt?.toISOString() ?? ''),
      connectionId: id,
      limit: 31,
      isEnergyGoingToConnection: flow === 'IN',
    },
    {
      enabled: !!startsAt && !!endsAt,
    }
  );

  // adjust the metering data date range base from the supply period
  useEffect(() => {
    if (from && supplyPeriod) {
      const config = getConfig();

      const monthStart = newMarketDate(from);
      const supplyPeriodStartDate = newMarketDate(supplyPeriod.startDate);
      setStartsAt(monthStart > supplyPeriodStartDate ? monthStart : supplyPeriodStartDate);

      const monthEnd = addMonthsInZone(monthStart, 1, config.marketTimezone);
      if (supplyPeriod.endDate) {
        const supplyPeriodEndDate = startOfDayInZone(
          addDaysInZone(new Date(supplyPeriod.endDate), 1, config.marketTimezone),
          config.marketTimezone
        );
        setEndsAt(monthEnd < supplyPeriodEndDate ? monthEnd : supplyPeriodEndDate);
      } else {
        setEndsAt(monthEnd);
      }
    }
  }, [from, supplyPeriod]);

  const daysInMonth =
    startsAt && endsAt
      ? differenceInCalendarDays(endsAt, startsAt)
      : getDaysInMonth(newUTCDate(from));

  const hasSummaries =
    data && data.items.flatMap((i: UsageSummaryV2Item) => i.summaries)?.length > 0;
  const monthAsDate = startOfMonth(newMarketDate(startsAt));
  const month = format(monthAsDate, 'yyyy-MM-dd') ?? '';
  const displayMonth = monthAsDate ? format(monthAsDate, 'MMM yyyy') : '-';
  const totalValues =
    (flow == 'IN'
      ? data?.totalsToConnection?.totalValues
      : data?.totalsFromConnection?.totalValues) ?? new Array<Measurement>();

  const numberOfCompleteSummaries =
    data && hasSummaries && !isSupplyPeriodCancelled
      ? data.items
          .map((item: UsageSummaryV2Item) => item.numberOfCompleteSummaries)
          .reduce((a: number, b: number) => Math.max(a, b))
      : 0;

  return (
    <Widget className="widget--full" title="Metering data" width="full">
      <div className="table-filter apl-display-flex apl-flex-row apl-align-items-center apl-justify-content-between">
        <div
          className="apl-display-flex apl-flex-row apl-align-items-center"
          style={{
            paddingLeft: '5px',
          }}
        >
          <div className="apl-field-v1 apl-display-flex apl-flex-row apl-align-items-center apl-mr-xs">
            <label className="apl-field__label apl-mr-xs apl-mb-none" htmlFor="from-field">
              Show
            </label>
            <select
              className="apl-select-v1_0"
              id="from-field"
              name="from"
              data-testid="from"
              onChange={(e) => setFrom(e.target.value)}
              value={from}
            >
              {fromOptions.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>
          <div className="apl-field-v1 apl-display-flex apl-flex-row apl-align-items-center">
            <label className="apl-field__label apl-mr-xs apl-mb-none" htmlFor="flow-field">
              Flow
            </label>
            <select
              className="apl-select-v1_0"
              id="flow-field"
              name="flow"
              onChange={(e) => setFlow(e.target.value as FlowDirection)}
              value={flow}
            >
              {flowOptions.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
      <table className="apl-table-v1 apl-width-full" style={{ borderRadius: 0 }}>
        {isLoading && (
          <tbody>
            <tr>
              <td>Loading...</td>
            </tr>
          </tbody>
        )}
        {isError && (
          <tbody>
            <tr>
              <td>Sorry, there was an error.</td>
            </tr>
          </tbody>
        )}
        {data && hasSummaries && !isSupplyPeriodCancelled && (
          <>
            <thead>
              <tr>
                <th className="apl-text-left" rowSpan={2} style={{ verticalAlign: 'bottom' }}>
                  Month
                </th>
                <th className="apl-text-left" rowSpan={2} style={{ verticalAlign: 'bottom' }}>
                  Complete days
                </th>
                <th className="apl-text-left" rowSpan={2} style={{ verticalAlign: 'bottom' }}>
                  Incomplete days
                </th>
                <th className="apl-text-right" colSpan={3}>
                  Metered consumption
                </th>
                <th className="apl-text-right" colSpan={3}>
                  Loss adjusted consumption
                </th>
              </tr>
              <tr>
                <th className="apl-text-right" style={{ verticalAlign: 'bottom' }}>
                  kWh
                </th>
                <th className="apl-text-right" style={{ verticalAlign: 'bottom' }}>
                  kVAh
                </th>
                <th className="apl-text-right" style={{ verticalAlign: 'bottom' }}>
                  kVArh
                </th>
                <th className="apl-text-right" style={{ verticalAlign: 'bottom' }}>
                  kWh
                </th>
                <th className="apl-text-right" style={{ verticalAlign: 'bottom' }}>
                  kVAh
                </th>
                <th className="apl-text-right" style={{ verticalAlign: 'bottom' }}>
                  kVArh
                </th>
              </tr>
            </thead>
            <tbody>
              {data && hasSummaries && (
                <tr key={`month-summary-${month}`}>
                  <td className="apl-text-left">
                    <Link
                      className="apl-color-primary"
                      to={`/connections/${id}/metering/month?date=${month}&flow=${flow}&supply_period=${supplyPeriod.id}`}
                    >
                      {displayMonth}
                    </Link>
                  </td>
                  <td className="apl-text-left">{`${numberOfCompleteSummaries}/${daysInMonth}`}</td>
                  <td className="apl-text-left">{daysInMonth - numberOfCompleteSummaries}</td>
                  <td className="apl-text-right">{findMeasurement('kWh', totalValues)}</td>
                  <td className="apl-text-right">{findMeasurement('kVAh', totalValues)}</td>
                  <td className="apl-text-right">{findMeasurement('kVArh', totalValues)}</td>
                  <td className="apl-text-right">
                    {findMeasurement('loss_adjusted_kWh', totalValues)}
                  </td>
                  <td className="apl-text-right">
                    {findMeasurement('loss_adjusted_kVAh', totalValues)}
                  </td>
                  <td className="apl-text-right">
                    {findMeasurement('loss_adjusted_kVArh', totalValues)}
                  </td>
                </tr>
              )}
            </tbody>
          </>
        )}
        {((data && !hasSummaries) || isSupplyPeriodCancelled) && (
          <EmptyTableRow message="Sorry, no metering data found." />
        )}
      </table>
    </Widget>
  );
};

export default MeteringDataComponentMerxV2;

/**
 * Returns either '', the first entry or second entry of the given array.
 * @param fromOptions
 */
const getStartDateFromOption = (fromOptions: FieldOption[]): string => {
  if (!fromOptions || fromOptions.length === 0) {
    return '';
  }
  const startLastMonth = format(startOfMonth(subMonths(moment().toDate(), 1)), 'yyyy-MM-dd');
  const from = fromOptions.find((option) => option.value === startLastMonth);
  if (from) {
    return from.value;
  }
  return fromOptions[0].value;
};
