import React, { FunctionComponent } from 'react';
import './Errors.scss';

interface ErrorTextProps {
  children?: React.ReactNode;
}

export const ErrorText: FunctionComponent<ErrorTextProps> = (props: ErrorTextProps) => {
  return <div className="error">{props.children}</div>;
};
