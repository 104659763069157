import { useQuery } from '@tanstack/react-query';
import apiClient from '../../api/apiClient';
import { ChargeTag } from '../connections/connectionsApi';

const reportsBaseEndpoint = '/connection-reports';

export interface MissingPlansSummaryResult {
  startsAt: string;
  endsAt: string;
  totalConnections: number;
}

export interface MissingPlansSummaryChargeType {
  chargeTag: string;
  results: MissingPlansSummaryResult;
}

export interface MissingPlansSummary {
  missingPlans: MissingPlansSummaryChargeType[];
}

export interface MissingPlans {
  missingPlans: MissingPlan[];
}

export interface MissingPlan {
  chargeTag: ChargeTag;
  startsAt: string;
  endsAt: string;
  connections: MissingPlanItem[];
}

export interface MissingPlanItem {
  id: string;
  externalConnectionIdentifier: string;
  supplyPeriod: MissingPlanSupplyPeriod;
  pricingGap: MissingPlanPricingGap;
}

export interface MissingPlanSupplyPeriod {
  id: string;
  startsAt: string;
  endsAt: string;
  contractedParty: string;
  owner: string;
}

export interface MissingPlanPricingGap {
  expiringPlan: MissingPlanExpiringPlan;
  gapStartsAt: string;
  gapEndsAt: string;
}

export interface MissingPlanExpiringPlan {
  id: string;
  name: string;
}

/**
 * Returns the total amount of connections with missing plans.
 * @param from
 * @param to
 * @param chargeTag
 * @param attributes
 */
export const useMissingPlansSummary = (
  from: string,
  to: string,
  chargeTag: ChargeTag,
  attributes?: string[]
) => {
  const params = new URLSearchParams();
  params.set('startsAt', from);
  params.set('endsAt', to);
  params.set('tag', chargeTag);
  attributes?.forEach((atr) => params.append('attributes', atr));

  return useQuery<MissingPlansSummary, Error>(
    ['missing-plans-summary', from, to, chargeTag, attributes],
    async () => {
      const url = `${reportsBaseEndpoint}/missing-plans-summary?${params.toString()}`;
      return apiClient('rating-config-report').get(url);
    }
  );
};

/**
 * Returns the missing plans for the given parameters.
 * @param from
 * @param to
 * @param chargeTag
 * @param attributes
 */
export const useMissingPlans = (
  from: string,
  to: string,
  chargeTag: ChargeTag,
  attributes?: string[]
) => {
  const params = new URLSearchParams();
  params.set('startsAt', from);
  params.set('endsAt', to);
  params.set('tag', chargeTag);
  attributes?.forEach((atr) => params.append('attributes', atr));

  return useQuery<MissingPlans, Error>(
    ['missing-plans', from, to, chargeTag, attributes],
    async () => {
      const url = `${reportsBaseEndpoint}/missing-plans?${params.toString()}`;
      return apiClient('rating-config-report').get(url);
    }
  );
};
