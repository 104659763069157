import React from 'react';
import { Usage } from '../../billing/billingInvoicesApi';
import { Charge } from '../../../api/openapi/rating-config';

interface PlanTableUsageFieldProps {
  charge?: Charge;
  precision?: number;
  usage: Usage;
}

/*
 * The order is important because includes() is being used
 * as a comparator.
 * Valid values for `adjustmentToUse` array are:
 *   null
 *   [Distribution, Transmission]
 *   [Distribution]
 *   []
 * Any other combination is a warning condition.
 */
const getAdjustmentToUseLabel = (adjustmentToUse: Array<string> | undefined): string => {
  if (Array.isArray(adjustmentToUse)) {
    if (
      adjustmentToUse.includes('urn:flux:rating:charge:adjustment:distribution') &&
      adjustmentToUse?.includes('urn:flux:rating:charge:adjustment:transmission')
    ) {
      return 'Total';
    } else if (adjustmentToUse.includes('urn:flux:rating:charge:adjustment:distribution')) {
      return 'Distribution';
    } else if (adjustmentToUse.includes('urn:flux:rating:charge:adjustment:none')) {
      //Only None should support 'Raw'. [] not in use.
      return 'Raw';
    }
  }
  return '';
};

export const PlanTableUsageFieldFbau476 = ({
  charge,
  usage,
  precision = 2,
}: PlanTableUsageFieldProps) => {
  const adjustmentToUse = charge?.adjustmentToUse;
  const { value, unit } = usage;
  const usageValue = value ? `${parseFloat(value).toFixed(precision)} ${unit}` : '-';
  const lfLabel = getAdjustmentToUseLabel(adjustmentToUse);

  return (
    <>
      {usageValue}
      {lfLabel && <span className="badge apl-ml-s">{lfLabel}</span>}
    </>
  );
};
