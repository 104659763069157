import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

/**
 * Hook that builds on useLocation to parse the query string for you
 *
 * @returns {URLSearchParams} URLSearchParams object containing query params if present
 */
export default function useSearchQuery(): URLSearchParams {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}
