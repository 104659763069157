import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import apiClient from '../../api/apiClient';

const baseEndpoint = '/billing/invoices';

export interface InvoicesResult {
  items: Invoice[];
  links: Link[];
  total: number;
}

export interface InvoiceContext {
  endsAt: string;
  scopedTo: string;
  startsAt: string;
}

export type InvoiceStatus =
  | 'urn:flux:billing:invoice:status:pending'
  | 'urn:flux:billing:invoice:status:failed'
  | 'urn:flux:billing:invoice:status:available'
  | 'urn:flux:billing:invoice:status:paid'
  | 'urn:flux:billing:invoice:status:reversed';

export const INVOICE_STATUS_AVAILABLE = 'urn:flux:billing:invoice:status:available';
export const INVOICE_STATUS_PENDING = 'urn:flux:billing:invoice:status:pending';
export const INVOICE_STATUS_REVERSED = 'urn:flux:billing:invoice:status:reversed';

export interface Invoice {
  context: InvoiceContext;
  status: InvoiceStatus;
  messages: string[];
  data: InvoiceData;
  _links: Link[];
}

export interface InvoiceData {
  billingReadiness: string;
  creatingParty: string;
  currency: string;
  date: string;
  externalConnectionReference: string;
  externalDeviceReference: string;
  externalSupplyAgreementId: string;
  invoicedParty: string;
  invoiceNumber: string;
  invoicingParty: string;
  isCustomerGenerated: boolean;
  invoiceType?: string;
  lineItems: LineItem[];
  paymentDueDate: string;
  pdfUrl: string;
  ratingResultId: string;
  totals: InvoiceDataTotal;
}

export interface InvoiceDataTotal {
  subTotal: UnitValue;
  discounts?: UnitValue;
  taxes: UnitValue;
  total: UnitValue;
}

export interface UnitValue {
  unit: string | undefined;
  value: number | undefined;
}
export interface Amount {
  unit: string | undefined;
  value: number | undefined;
  cost?: number;
}
export type ChargeSetChargeTag =
  | 'energy'
  | 'network'
  | 'other'
  | 'discount'
  | 'energy:connection:direction:to'
  | 'energy:connection:direction:from';

export enum PriceSource {
  Contracted = 'urn:flux:pricing:source:contracted',
  SpotMarket = 'urn:flux:pricing:source:spot_market',
}

export type LineItemTag =
  | 'urn:flux:billing:line-item-tag:category:energy:v1'
  | 'urn:flux:billing:line-item-tag:category:network:v1'
  | 'urn:flux:billing:line-item-tag:category:other:v1'
  | 'urn:flux:billing:line-item-tag:category:energy:fee:v1'
  | 'urn:flux:billing:line-item-tag:category:energy:other:v1'
  | 'urn:flux:billing:line-item-tag:energy-flow:to-connection'
  | 'urn:flux:billing:line-item-tag:energy-flow:from-connection'
  | 'urn:flux:billing:line-item-tag:type:charge'
  | 'urn:flux:billing:line-item-tag:type:discount'
  | 'urn:flux:billing:line-item-tag:discount:v1';

export interface LineItem {
  amount: Amount;
  chargeId?: string;
  chargeRateId?: string;
  chargeTags?: ChargeSetChargeTag[];
  description?: string;
  /**
   * @deprecated by fbau593NewLossFactorLabels
   */
  expandedSummarisationGroup?: string;
  externalChargeReference: string;
  lineItemTags?: LineItemTag[];
  planId?: string;
  priceSource?: PriceSource;
  proportionOfVolume?: number;
  provider?: string;
  rate?: Rate;
  subGroupingReference?: string;
  summarisationGroup: string;
  usage?: BaseUsage;
}

export const AdjustmentSource = {
  Transmission: 'urn:flux:rating:charge:adjustment:transmission',
  Distribution: 'urn:flux:rating:charge:adjustment:distribution',
  None: 'urn:flux:rating:charge:adjustment:none',
} as const;
export type AdjustmentSource = (typeof AdjustmentSource)[keyof typeof AdjustmentSource];

export interface Adjustment {
  source: AdjustmentSource;
  value: number;
}

export interface BaseUsage {
  lossAdjusted?: number;
  raw?: number;
  unit: string;
  value: string;
}

export interface Usage extends BaseUsage {
  adjustments: Adjustment[];
  adjustmentsNet?: number;
}

/**
 * @deprecated by fbau593NewLossFactorLabels
 */
export interface UsageV1 extends BaseUsage {
  isLossAdjusted: boolean;
}

export interface Rate {
  basis?: RateBasis;
  unit: string;
  value: number;
  cost?: number;
}

export interface RateBasis {
  unit: string;
  value: number;
}

export interface Link {
  rel: string;
  href: string;
}

export interface InvoicesQueryParams {
  externalSupplyAgreementId?: string;
  endsAt?: string;
  limit?: number;
  offset?: number;
  startsAt?: string;
  externalSupplyAgreementIds?: string[];
  provider?: string;
  status?: string;
}

export const useGetInvoices = (params: InvoicesQueryParams, options?: Record<string, unknown>) => {
  const { externalSupplyAgreementId, startsAt, endsAt, externalSupplyAgreementIds } = params;

  return useQuery(
    ['invoices', externalSupplyAgreementId, startsAt, endsAt, externalSupplyAgreementIds],
    async () => {
      const search = new URLSearchParams();
      (Object.keys(params) as Array<keyof InvoicesQueryParams>).forEach((key) => {
        if (params[key] && key !== 'externalSupplyAgreementIds') {
          search.set(key, `${params[key]}`);
        }
      });

      externalSupplyAgreementIds?.forEach((id) => search.append('externalSupplyAgreementIds', id));
      return apiClient('billing').get(`${baseEndpoint}?${search.toString()}`);
    },
    {
      ...options,
    }
  );
};

export const useBillingInvoice = (id: string | undefined, options?: Record<string, unknown>) => {
  const data = useQuery(
    ['invoice', id],
    async () => {
      return apiClient('billing').get(`${baseEndpoint}/${id}`);
    },
    { ...options }
  );
  return data;
};

export const useBillCreation = (ratingResultId: string) => {
  const queryClient = useQueryClient();

  return useMutation(
    () => {
      return apiClient('billing').post(`${baseEndpoint}/${ratingResultId}`, '');
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['invoices']);
        queryClient.invalidateQueries(['credit-notes']);
      },
    }
  );
};

export const useReverseBill = (invoiceId?: string) => {
  const queryClient = useQueryClient();

  return useMutation(
    () => {
      return apiClient('billing').put(
        `${baseEndpoint}/${invoiceId}`,
        JSON.stringify({
          status: 'urn:flux:billing:invoice:status:reversed',
        })
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['invoices']);
        queryClient.invalidateQueries(['credit-notes']);
      },
    }
  );
};
