/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { DateTimeType } from './DateTimeType';
import type { HoldStatus } from './HoldStatus';

export type BaseSupplyAgreementConfiguration = {
    status?: BaseSupplyAgreementConfiguration.status;
    startDate: DateTimeType;
    endDate?: DateTimeType;
    billingPeriod: {
        duration: BaseSupplyAgreementConfiguration.duration;
        startDay: number;
    };
    acceptability: {
        ratedUsageQuality: BaseSupplyAgreementConfiguration.ratedUsageQuality;
        ratedPriceQuality: Array<'urn:flux:billing:acceptability:usage:final' | 'urn:flux:billing:acceptability:usage:contracted' | 'urn:flux:billing:acceptability:usage:provisional' | 'urn:flux:billing:acceptability:usage:interim'>;
        ratedChargeCompletenessQuality?: BaseSupplyAgreementConfiguration.ratedChargeCompletenessQuality;
    };
    holdStatus?: HoldStatus;
    /**
     * The days after the end of the billing period before the retry is triggered
     */
    expectBillWithin?: number;
};

export namespace BaseSupplyAgreementConfiguration {

    export enum status {
        URN_FLUX_BILLING_SUPPLY_AGREEMENT_ACTIVE = 'urn:flux:billing:supply_agreement:active',
        URN_FLUX_BILLING_SUPPLY_AGREEMENT_CANCELLED = 'urn:flux:billing:supply_agreement:cancelled',
    }

    export enum duration {
        URN_FLUX_BILLING_DURATION_WEEK = 'urn:flux:billing:duration:week',
        URN_FLUX_BILLING_DURATION_FORTNIGHT = 'urn:flux:billing:duration:fortnight',
        URN_FLUX_BILLING_DURATION_MONTH = 'urn:flux:billing:duration:month',
        URN_FLUX_BILLING_DURATION_QUARTERLY = 'urn:flux:billing:duration:quarterly',
    }

    export enum ratedUsageQuality {
        URN_FLUX_BILLING_ACCEPTABILITY_USAGE_ACTUAL = 'urn:flux:billing:acceptability:usage:actual',
        URN_FLUX_BILLING_ACCEPTABILITY_USAGE_CALCULATED = 'urn:flux:billing:acceptability:usage:calculated',
        URN_FLUX_BILLING_ACCEPTABILITY_USAGE_FORECAST = 'urn:flux:billing:acceptability:usage:forecast',
    }

    export enum ratedChargeCompletenessQuality {
        URN_FLUX_BILLING_ACCEPTABILITY_CHARGE_COMPLETE = 'urn:flux:billing:acceptability:charge:complete',
        URN_FLUX_BILLING_ACCEPTABILITY_CHARGE_INCOMPLETE = 'urn:flux:billing:acceptability:charge:incomplete',
    }


}

