import React, { useEffect, useMemo, useState } from 'react';
import {
  getDaysInMonth,
  endOfMonth,
  format,
  startOfMonth,
  isWithinInterval,
  differenceInCalendarDays,
} from 'date-fns';
import { Link, useNavigate, useLocation, useParams } from 'react-router-dom';
import MeteringConsumptionSummary from './MeteringConsumptionSummary';
import { SupplyPeriodSummary, useSupplyPeriodSummary } from '../connectionsApi';
import EmptyTableRow from '../../common/EmptyTableRow';
import {
  FlowDirection,
  findMeasurement,
  generateFlowOptions,
} from '../dashboard/MeteringDataComponent';
import Card from '../../layout/Card';
import Page, { PageHeader } from '../../layout/Page';
import {
  UsageSummaryRow,
  useUsageMeters,
  useUsageSummary,
} from '../../telemetry/telemetryUsageApi';
import {
  FieldOption,
  generateDateOptions,
  generateDateOptionsBaseFromSupplyPeriod,
} from '../../../util/helper-func';

import caretRight from '../../../icons/caret-right.svg';
import { useExternalBillingPresentation } from 'components/core/coreExternalBillingApi';

const MeteringMonthlySummary = () => {
  const flowOptions = useMemo(() => generateFlowOptions(), []);
  const navigate = useNavigate();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);

  const queryDate = searchParams.get('date');
  const initialDate = queryDate ? startOfMonth(new Date(queryDate)) : startOfMonth(new Date());
  const initialMeter: string | undefined = searchParams.get('meter') || undefined;
  const initialFlow: FlowDirection = (searchParams.get('flow') as FlowDirection) || 'IN';

  const [from, setFrom] = useState<string>(format(initialDate, 'yyyy-MM-dd'));
  const [meter, setMeter] = useState<string | undefined>(initialMeter);
  const [flow, setFlow] = useState<FlowDirection>(initialFlow);
  const [startsAt, setStartsAt] = useState<string>('');
  const [endsAt, setEndsAt] = useState<string>('');
  const [fromOptions, setFromOptions] = useState<FieldOption[]>([]);

  const { id } = useParams<{ id: string }>();

  const {
    data: supplyPeriodData,
    isError: supplyPeriodDataError,
    isInitialLoading: supplyPeriodDataLoading,
  } = useSupplyPeriodSummary(id, { enabled: !!id });

  const {
    data: meterData,
    isError: meterDataError,
    isInitialLoading: meterDataLoading,
  } = useUsageMeters(
    {
      startsAt: startsAt,
      endsAt: endsAt,
      connectionId: id,
    },
    {
      enabled: !!startsAt && !!endsAt,
    }
  );

  const {
    data,
    isError,
    isInitialLoading: isLoading,
  } = useUsageSummary(
    {
      connectionId: id,
      meterId: meter,
      startsAt: startsAt,
      endsAt: endsAt,
      limit: 31,
      isEnergyGoingToConnection: flow === 'IN',
    },
    {
      enabled: !!id && !!meter && !!startsAt && !!endsAt,
    }
  );

  const supplyPeriodId = searchParams && searchParams.get('supply_period');
  const supplyPeriod: SupplyPeriodSummary =
    !supplyPeriodDataLoading &&
    supplyPeriodData &&
    supplyPeriodId &&
    supplyPeriodData?.supplyPeriodSummaries?.find(
      (period: SupplyPeriodSummary) => period.id === supplyPeriodId
    );

  useEffect(() => {
    // update the URL whenever meter, date or flow changes
    const params = new URLSearchParams();

    meter && params.set('meter', meter);
    params.set('date', from);
    params.set('flow', flow);
    params.set('supply_period', supplyPeriodId ? supplyPeriodId : '');

    navigate({ search: params.toString() }, { replace: true });
  }, [meter, from, flow]);

  useEffect(() => {
    // if the meter was not passed in the query, default to the first meter in the list
    if (meter === undefined && meterData?.meterIds?.[0]) {
      setMeter(meterData.meterIds[0]);
    }
  }, [meterData]);

  useEffect(() => {
    // set the date options base from the supply period
    if (supplyPeriod) {
      setFromOptions(generateDateOptionsBaseFromSupplyPeriod(supplyPeriod));
    } else {
      setFromOptions(generateDateOptions(true, 24, 'MMMM yyyy', 'yyyy-MM-dd'));
    }
  }, [supplyPeriod]);

  // adjust the metering data date range base from the supply period
  useEffect(() => {
    if (from) {
      // create a date range object for the selected month
      const monthRange = { start: new Date(from), end: endOfMonth(new Date(from)) };

      // if the supply period start date is within the selected month,
      // use it as startAt value for getting metering data.
      const supplyPeriodStartDate = supplyPeriod?.startDate;
      if (supplyPeriodStartDate && isWithinInterval(new Date(supplyPeriodStartDate), monthRange)) {
        setStartsAt(format(new Date(supplyPeriodStartDate), 'yyyy-MM-dd'));
      } else {
        setStartsAt(format(monthRange.start, 'yyyy-MM-dd'));
      }

      // if the supply period end date is within the selected month,
      // use it as endAt value for getting metering data.
      const supplyPeriodEndDate = supplyPeriod?.endDate;
      if (supplyPeriodEndDate && isWithinInterval(new Date(supplyPeriodEndDate), monthRange)) {
        setEndsAt(format(new Date(supplyPeriodEndDate), 'yyyy-MM-dd'));
      } else {
        setEndsAt(format(monthRange.end, 'yyyy-MM-dd'));
      }
    }
  }, [from, supplyPeriod]);

  const daysInMonth =
    startsAt && endsAt
      ? differenceInCalendarDays(new Date(endsAt), new Date(startsAt)) + 1
      : getDaysInMonth(new Date(from));

  const meterOptions = meterData?.meterIds.length
    ? meterData.meterIds.map((meter: string) => ({
        label: meter,
        value: meter,
      }))
    : [];

  const connectionId = supplyPeriodData?.connectionId ?? id;
  const completeSummaries = data?.summaries?.[0]?.numberOfCompleteSummaries ?? 0;

  // pull the contracted party name from core external billing
  const periodAgreementId = supplyPeriod?.externalSupplyAgreementId;
  const { data: externalData } = useExternalBillingPresentation(periodAgreementId ?? '', {
    enabled: !!periodAgreementId,
  });

  const contractedParty =
    externalData && externalData?.parties?.owner?.name ? externalData.parties.owner.name : '-';
  const contractedPartyId =
    externalData && externalData?.parties?.owner?.id ? externalData.parties.owner.id : undefined;

  return (
    <>
      <PageHeader
        title={() => (
          <>
            <Link
              className="page__title-link"
              to={`/connections/${id}/supply-periods/${supplyPeriodId}/contracted-parties/${contractedPartyId}`}
            >
              {connectionId}
            </Link>
            <img src={caretRight} alt="" />
            Monthly metering data
          </>
        )}
      />
      <Page>
        <Card>
          <div className="apl-display-flex apl-flex-row">
            <div className="apl-field-v1 apl-display-flex apl-flex-column apl-align-items-start">
              <label className="apl-field__label apl-mr" htmlFor="contracted-party-field">
                Contracted party
              </label>
              <p className="apl-my-none apl-h3" id="contracted-party-field">
                {contractedParty}
              </p>
            </div>
          </div>
        </Card>
        <Card>
          <div className="card__inner apl-display-flex apl-flex-row apl-align-items-center apl-justify-content-between apl-px">
            <div className="apl-display-flex apl-flex-row apl-align-items-center">
              <div className="apl-field-v1 apl-display-flex apl-flex-row apl-align-items-center apl-mr-s">
                <label className="apl-field__label apl-mr-s apl-mb-none" htmlFor="date-field">
                  Month
                </label>
                <select
                  className="apl-select-v1_0 apl-mb-none"
                  data-testid="from field"
                  id="from-field"
                  name="from"
                  onChange={(e) => setFrom(e.target.value)}
                  value={from}
                >
                  {fromOptions.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
              </div>
              <div className="apl-field-v1 apl-display-flex apl-flex-row apl-align-items-center apl-mr-s">
                <label className="apl-field__label apl-mr-s apl-mb-none" htmlFor="meter-field">
                  Meter
                </label>
                <select
                  className="apl-select-v1_0 apl-mb-none"
                  data-testid="meter field"
                  id="meter-field"
                  name="meter"
                  onChange={(e) => setMeter(e.target.value)}
                  value={meter}
                >
                  {meterOptions.map((option: Record<string, string>) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
              </div>
              <div className="apl-field-v1 apl-display-flex apl-flex-row apl-align-items-center apl-mr-s">
                <label className="apl-field__label apl-mr-s apl-mb-none" htmlFor="flow-field">
                  Flow
                </label>
                <select
                  className="apl-select-v1_0"
                  data-testid="flow field"
                  id="flow-field"
                  name="flow"
                  onChange={(e) => setFlow(e.target.value as FlowDirection)}
                  value={flow}
                >
                  {flowOptions.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
              </div>
              <p className="apl-my-none apl-mr-s">
                <strong>Complete days:</strong> {`${completeSummaries}/${daysInMonth}`}
              </p>
              <p className="apl-my-none">
                <strong>Incomplete days:</strong> {daysInMonth - completeSummaries}
              </p>
            </div>
          </div>
        </Card>
        <MeteringConsumptionSummary
          flow={flow}
          measurements={data?.summaries?.[0]?.totals?.totalValues}
          title="Monthly total consumption"
        />
        <Card className="card--square-top apl-pb-none">
          <table className="apl-table-v1 apl-mb-none">
            <thead>
              <tr>
                <th className="apl-text-left" rowSpan={2} style={{ verticalAlign: 'bottom' }}>
                  Date
                </th>
                <th className="apl-text-left" rowSpan={2} style={{ verticalAlign: 'bottom' }}>
                  Meter ID
                </th>
                <th className="apl-text-left" rowSpan={2} style={{ verticalAlign: 'bottom' }}>
                  Intervals
                </th>
                <th className="apl-text-right" colSpan={3}>
                  Metered
                </th>

                <th className="apl-text-right" colSpan={3}>
                  Loss adjusted
                </th>
              </tr>

              <tr>
                <th className="apl-text-right" style={{ verticalAlign: 'bottom' }}>
                  kWh
                </th>
                <th className="apl-text-right" style={{ verticalAlign: 'bottom' }}>
                  kVAh
                </th>
                <th className="apl-text-right" style={{ verticalAlign: 'bottom' }}>
                  kVArh
                </th>
                <th className="apl-text-right" style={{ verticalAlign: 'bottom' }}>
                  kWh
                </th>
                <th className="apl-text-right" style={{ verticalAlign: 'bottom' }}>
                  kVAh
                </th>
                <th className="apl-text-right" style={{ verticalAlign: 'bottom' }}>
                  kVArh
                </th>
              </tr>
            </thead>
            {(isLoading || supplyPeriodDataLoading || meterDataLoading) && (
              <tbody>
                <tr>
                  <td>Loading...</td>
                </tr>
              </tbody>
            )}
            {(isError || supplyPeriodDataError || meterDataError) && (
              <tbody>
                <tr>
                  <td>Sorry, there was an error.</td>
                </tr>
              </tbody>
            )}
            {data && data?.summaries?.[0]?.summaries?.length > 0 && (
              <tbody data-testid="usage-summary-rows">
                {data.summaries[0].summaries.map((summary: UsageSummaryRow) => {
                  const { numberOfReadingsContributed, summaryPeriod, summaryValues } = summary;

                  return (
                    <tr key={`row-${summaryPeriod}`}>
                      <td className="apl-text-left">
                        <Link
                          className="apl-color-primary"
                          to={`/connections/${id}/metering/day?meter=${meter}&date=${summaryPeriod}&flow=${flow}&supply_period=${supplyPeriod.id}`}
                        >
                          {format(new Date(summaryPeriod), 'dd MMM yyyy')}
                        </Link>
                      </td>
                      <td className="apl-text-left">{meter}</td>
                      <td className="apl-text-left">{numberOfReadingsContributed}</td>

                      <td className="apl-text-right">
                        {findMeasurement('kWh', summaryValues)} kWh
                      </td>
                      <td className="apl-text-right">
                        {findMeasurement('kVAh', summaryValues)} kVAh
                      </td>
                      <td className="apl-text-right">
                        {findMeasurement('kVArh', summaryValues)} kVArh
                      </td>
                      <td className="apl-text-right">
                        {findMeasurement('loss_adjusted_kWh', summaryValues)} kWh
                      </td>
                      <td className="apl-text-right">
                        {findMeasurement('loss_adjusted_kVAh', summaryValues)} kVAh
                      </td>
                      <td className="apl-text-right">
                        {findMeasurement('loss_adjusted_kVArh', summaryValues)} kVArh
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            )}
            {data && data?.summaries?.[0]?.summaries?.length === 0 && (
              <EmptyTableRow message="Sorry, there are no summaries for this month." />
            )}
          </table>
        </Card>
      </Page>
    </>
  );
};

export default MeteringMonthlySummary;
