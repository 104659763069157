import React, { useRef, useState } from 'react';
import kebabIcon from '../../icons/kebab.svg';
import './ContextMenu.scss';

export interface ContextMenuButtonRenderProps {
  closeMenu: () => void;
}

interface ContextMenuButtonProps {
  align?: 'left' | 'right';
  children: JSX.Element | ((props: ContextMenuButtonRenderProps) => JSX.Element);
  testIdSuffix: string;
  width?: string;
  hidden?: boolean;
}

const ContextMenuButton = ({
  align = 'left',
  children,
  testIdSuffix,
  width,
  hidden = false,
}: ContextMenuButtonProps) => {
  const ref = useRef<HTMLButtonElement>(null);
  const [open, setOpen] = useState(false);

  const closeMenu = () => setOpen(false);

  return (
    <>
      {open && (
        <span
          onClick={() => setOpen(false)}
          style={{
            bottom: 0,
            left: 0,
            position: 'fixed',
            right: 0,
            top: 0,
            zIndex: 10,
          }}
        />
      )}
      <button
        className="context-menu-button"
        data-testid={`context-menu-button--${testIdSuffix}`}
        hidden={hidden}
        onClick={() => setOpen(!open)}
        ref={ref}
      >
        <img alt="context menu icon" className="context-menu-button__icon" src={kebabIcon} />
      </button>
      {open && ref.current && (
        <div
          className="context-menu"
          data-testid={`context-menu--${testIdSuffix}`}
          style={{
            left: align === 'left' ? `${ref.current?.offsetLeft}px` : 'auto',
            right: align === 'right' ? '15px' : undefined,
            top: `${ref.current.offsetTop + ref.current.clientHeight + 5}px`,
            width: width,
          }}
        >
          {typeof children === 'function' ? children({ closeMenu }) : children}
        </div>
      )}
    </>
  );
};

export default ContextMenuButton;
