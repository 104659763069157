import { useQuery } from '@tanstack/react-query';
import apiClient from '../../api/apiClient';

export const usePlanSummary = (
  providerId: string | undefined,
  options?: Record<string, unknown>
) => {
  return useQuery(
    ['plans-summary', { providerId: providerId }],
    async () => {
      const url = `/providers/${providerId}/plans`;
      return apiClient().get(url);
    },
    { ...options }
  );
};
