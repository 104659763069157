import { useExternalBillingPresentation } from 'components/core/coreExternalBillingApi';
import { endOfMonth, format } from 'date-fns';
import { groupBy } from 'ramda';
import React from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import caretRight from '../../../icons/caret-right.svg';
import { useConnectionCharges } from '../../billing/billingChargesApi';
import { useSearchQuery } from 'hooks';
import { InvoiceContext, LineItem } from '../../billing/billingInvoicesApi';
import Page, { ErrorPage, LoadingPage, PageHeader } from '../../layout/Page';
import { useConnectionDetails } from '../connectionsApi';
import DiscountsTable from './DiscountsTable';
import InvoiceSummary from './InvoiceSummary';
import PlanTable from './PlanTable';
import { newMarketDate } from 'util/helper-func';

export type GroupedCharges = {
  [index: string]: LineItem[];
};

const ChargeSetDetails = () => {
  const { chargeId, id } = useParams<{ chargeId: string | undefined; id: string }>();
  const { search } = useLocation();

  const query = search ? new URLSearchParams(search) : null;
  const queryDate = query && query.get('date');
  const date = queryDate !== null ? format(new Date(queryDate), 'dd MMM yyyy') : '';

  const {
    data: connectionData,
    isError: connectionDataError,
    isInitialLoading: connectionDataLoading,
  } = useConnectionDetails(id, { enabled: !!id });

  const {
    data,
    isError,
    isInitialLoading: isLoading,
  } = useConnectionCharges(chargeId, { enabled: !!chargeId });

  let contractedParty = '-';
  // let externalId: string | null = null;
  let billingPeriod: InvoiceContext | null = null;

  // IMPORTANT: Currently the update of a supplyPeriod is not working properly. The following
  // code is to get the contractedParty via a separate API call to get the updated value. Core
  // is not sending an update event in case the contractedParty (and others?) gets updated.
  // If the above issue is fixed we can then use the connectionData object which we have anyway.
  // see:https://jira.fluxfederation.com/browse/MX-11215
  const queryParams = useSearchQuery();
  const periodAgreementId = queryParams.get('agreementId');
  const spid = queryParams.get('spid');
  const cpid = queryParams.get('cpid');
  const { data: externalData } = useExternalBillingPresentation(periodAgreementId ?? '', {
    enabled: !!periodAgreementId,
  });

  // Switch the following lines if the currently flaky supplyPeriod update from Core is working
  // const supplyPeriod = connectionData[0]?.supplyPeriods[0]
  contractedParty =
    externalData && externalData?.parties?.owner?.name ? externalData.parties.owner.name : '-';

  const billingStartDate = data && data?.period?.startsAt ? data.period.startsAt : null;

  const billingEndDate = date && data?.period?.endsAt ? data.period.endsAt : null;

  billingPeriod = { startsAt: billingStartDate, endsAt: billingEndDate, scopedTo: '' };

  // rating period from the billing ApplicableTimePeriodChargeTypes.

  if (connectionDataLoading || isLoading) {
    return <LoadingPage />;
  }

  if (connectionDataError || isError) {
    return <ErrorPage />;
  }

  const groupFn = groupBy((item) => (item as LineItem).planId || '');
  const groupedCharges = data?.lineItems?.length
    ? (groupFn(data.lineItems) as GroupedCharges)
    : null;

  const discounts = data?.lineItems?.length
    ? (data.lineItems.filter(
        // TODO remove reference to summarisationGroup and urn:flux:billing:line-item-tag:discount:v1 when these are retired
        (item: LineItem) =>
          item.planId === null &&
          (item.summarisationGroup === 'DISCOUNT' ||
            item.lineItemTags?.includes('urn:flux:billing:line-item-tag:type:discount') ||
            item.lineItemTags?.includes('urn:flux:billing:line-item-tag:discount:v1'))
      ) as unknown as LineItem[])
    : [];

  const dateFormat = 'yyyy-MM-dd';

  const from = data?.period?.startsAt
    ? format(newMarketDate(data.period.startsAt), dateFormat)
    : '';
  const to = data?.period?.endsAt
    ? format(endOfMonth(newMarketDate(data.period.endsAt)), dateFormat)
    : '';

  const viewChargeSetLinkParams = new URLSearchParams({
    from,
    to,
  });
  if (cpid) {
    viewChargeSetLinkParams.set('contracted_party', cpid);
  }
  if (spid) {
    viewChargeSetLinkParams.set('spid', spid);
  }

  return (
    <>
      <PageHeader
        title={() => (
          <>
            <Link
              className="page__title-link"
              to={`/connections/${id}/supply-periods/${spid}/contracted-parties/${cpid}`}
            >
              {connectionData[0]?.connectionId ?? id}
            </Link>
            <img src={caretRight} alt="navigation breadcrumb separator" />
            <Link
              className="page__title-link"
              to={`/connections/${id}/charges?${viewChargeSetLinkParams.toString()}`}
            >
              Charge sets
            </Link>
            <img src={caretRight} alt="navigation breadcrumb separator" />
            {date}
          </>
        )}
      />
      <Page>
        <InvoiceSummary
          totals={data?.totals}
          context={billingPeriod as InvoiceContext}
          contractedParty={contractedParty}
        />
        {groupedCharges &&
          Object.keys(groupedCharges)
            // it is a string because we are using Object.keys
            .filter((planId) => planId !== 'null' && planId !== '')
            .map((planId, index) => (
              <PlanTable
                key={`plan-table-${planId}-${index}`}
                index={index}
                planId={planId}
                spid={spid ? spid : undefined}
                cpid={cpid ? cpid : undefined}
                lineItems={groupedCharges[planId]}
              />
            ))}
        {discounts && discounts.length > 0 && <DiscountsTable discounts={discounts} />}
      </Page>
    </>
  );
};

export default ChargeSetDetails;
