import { addSeconds, formatISO, isBefore, parseISO } from 'date-fns';
import { isEmpty, isNil } from 'ramda';
import { Config } from '../config/getConfig';

import { v4 as uuidv4 } from 'uuid';
import { getFlagValue } from 'util/launchdarkly.util';

export interface TokenData {
  auth: string;
  refresh: string;
  expires: Date | null;
}

let clientConfig: Config;

let tokenData: TokenData = {
  auth: '',
  refresh: '',
  expires: null,
};

const setConfig = (config: Config): void => {
  clientConfig = config;
};

export const setTokenData = ({ auth, expires, refresh }: TokenData): void => {
  tokenData = {
    ...tokenData,
    auth,
    expires,
    refresh: !isNil(refresh) ? refresh : '',
  };
};

export const hasToken = (): boolean => !isNil(tokenData.auth) && !isEmpty(tokenData.auth);

/**
 * Checks if a date has expired or not
 * @param {Date | null} expiry - the expiry date
 * @returns {boolean} if the date provided has expired or not
 */
export const hasExpired = (expiry: Date | null): boolean =>
  isNil(expiry) || isBefore(expiry, new Date());

/**
 * Checks if a token is valid and if it has expired or not
 * @param {string} auth - the auth token
 * @param {Date | null} expiry - the expiry date
 * @returns {boolean} if a token is valid and if it has expired or not
 */
export const isTokenStillValid = (auth: string, expiry: Date | null): boolean =>
  !isNil(auth) && !isEmpty(auth) && !hasExpired(expiry);

export const getTokenData = (): TokenData => tokenData;

export const processAccessCode = async (
  code: string | null,
  signal?: AbortSignal | null
): Promise<any> => {
  if (isNil(code)) {
    return Promise.reject('invalid access code');
  }

  const authenticationRequest = JSON.stringify([
    {
      auth_code: code,
      redirect_uri:
        process.env.NODE_ENV === 'development'
          ? `${window.location.origin}${window.location.pathname}`
          : undefined,
    },
  ]);

  return processTokenRequest(authenticationRequest, '', signal);
};

export const getAccessToken = async (clientConfig: Config) => {
  const { auth, expires } = tokenData;

  // @todo remove when auth isn't feature switched
  if (clientConfig && !clientConfig.useAuth) {
    return Promise.resolve('');
  }

  // the token hasn't expired yet, return it
  if (getFlagValue('mx-11400-authentication-error-fix')) {
    if (isTokenStillValid(auth, expires)) {
      return Promise.resolve(tokenData.auth);
    } else {
      // try to refresh the tokens, wait until that is done an pass the refreshed
      // token
      try {
        await refreshTokens();
      } catch (e) {
        return redirectToLogin();
      }

      return Promise.resolve(tokenData.auth);
    }
  } else {
    if (auth && !isNil(expires) && isBefore(new Date(), expires)) {
      return Promise.resolve(tokenData.auth);
    } else {
      // try to refresh the tokens, wait until that is done an pass the refreshed
      // token
      try {
        await refreshTokens();
      } catch (e) {
        return redirectToLogin();
      }

      return Promise.resolve(tokenData.auth);
    }
  }
};

export const refreshTokens = async (): Promise<any> => {
  const { refresh } = tokenData;

  if (getFlagValue('mx-11400-authentication-error-fix')) {
    if (isNil(refresh) || isEmpty(refresh)) {
      return Promise.reject('invalid refresh token');
    }
  } else {
    if (isNil(refresh)) {
      return Promise.reject('invalid refresh token');
    }
  }

  const authenticationRequest = JSON.stringify([
    {
      refresh_token: refresh,
    },
  ]);

  return processTokenRequest(authenticationRequest, refresh);
};

const processTokenRequest = async (
  body: string,
  existingRefreshToken: string | null,
  signal?: AbortSignal | null
) => {
  const processUri = `${clientConfig.apiBasePath}/authentication`;
  const response = await fetch(processUri, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body,
    ...(signal && { signal }),
  });

  if (response.ok) {
    const { access_token, expires_in, refresh_token } = await response.json();

    const expiresAt = addSeconds(new Date(), expires_in);

    const tokenData = {
      refresh: refresh_token ? refresh_token : existingRefreshToken,
      auth: access_token,
      expires: expiresAt,
    };

    storeTokenData(tokenData);
    setTokenData(tokenData);

    return Promise.resolve();
  } else {
    console.error(new Error('Authentication error ' + response.status));

    return Promise.reject('Authentication error: ' + response.statusText);
  }
};

export const checkStorageForTokens = () => {
  const tokenData = retrieveTokenData();

  if (!isNil(tokenData)) {
    setTokenData(tokenData);
  }
};

export const NONCE = 'nonce';

export const redirectToLogin = async (): Promise<any> => {
  localStorage.clear();

  // assumes that config has been set
  if (isNil(clientConfig)) {
    console.error('Could not redirect');
    return Promise.reject('Could not redirect');
  }

  // set URL to where a user entered the app, ex. /connections
  // the path will be used for redirecting after a successful auth
  // avoid the /unauthorised which will cause a redirect loop
  const nonce = uuidv4();
  window.sessionStorage.setItem(NONCE, nonce);
  let redirectUrl = window.location.href.substring(window.location.origin.length);
  if (process.env.PUBLIC_URL) {
    // we strip out the public url which is used as the base path for the app's routing
    // ex. if the public url is set to /aib and the redirect url is /aib/connections
    // the app will redirect to /aib/aib/connections which doesn't route as expected
    // after stripping out the public url we should get /connections
    redirectUrl = redirectUrl.substring(process.env.PUBLIC_URL.length);
  }
  const state = {
    [nonce]: { redirectUrl: redirectUrl !== '/unauthorised' ? redirectUrl : '/dashboard' },
  };

  const params = new URLSearchParams();
  params.append('client_id', clientConfig.clientId);
  params.append('redirect_uri', clientConfig.redirectUri);
  params.append('response_type', 'code');
  params.append('scope', clientConfig.scope);
  params.append('state', encodeURIComponent(JSON.stringify(state)));

  window.location.href = `${clientConfig.authUri}?${params.toString()}`;

  return Promise.resolve();
};

const ACCESS_KEY = 'ACCESS_KEY';
const REFRESH_KEY = 'REFRESH_KEY';
const EXPIRES_KEY = 'EXPIRES_KEY';

const storeTokenData = ({ auth, expires, refresh }: TokenData) => {
  localStorage.clear();
  localStorage.setItem(ACCESS_KEY, auth);
  localStorage.setItem(EXPIRES_KEY, formatISO(expires as Date));

  if (clientConfig.useRefreshToken) {
    localStorage.setItem(REFRESH_KEY, refresh);
  }
};

const retrieveTokenData = (): TokenData | null => {
  const dateString = localStorage.getItem(EXPIRES_KEY);
  const auth = localStorage.getItem(ACCESS_KEY);
  const refresh = localStorage.getItem(REFRESH_KEY);

  if (isNil(dateString) || isNil(auth) || isNil(refresh)) {
    localStorage.clear();
    return null;
  }

  const expires = parseISO(dateString);

  if (isNil(expires)) {
    localStorage.clear();
    return null;
  }

  return {
    expires,
    auth,
    refresh,
  };
};

const authClient = {
  setConfig,
};

export default authClient;
