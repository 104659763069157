/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { Artefacts } from './Artefacts';
import type { EventInvoiceData } from './EventInvoiceData';
import type { InvoiceContext } from './InvoiceContext';

export type InvoiceGenerationComplete = {
    id?: string;
    artefacts?: Artefacts;
    _correlationId?: string;
    _type?: InvoiceGenerationComplete._type;
    _publishedAt?: string;
    version?: string;
    context: InvoiceContext;
    status: InvoiceGenerationComplete.status;
    /**
     * Populated when invoice status is failed to describe why it failed
     */
    messages?: Array<string>;
    data?: EventInvoiceData;
    /**
     * Unique identifier for the transaction
     */
    externalTransactionReference?: string;
    /**
     * The id of the supply agreement linked to this invoice
     */
    externalSupplyAgreementId?: string;
};

export namespace InvoiceGenerationComplete {

    export enum _type {
        URN_FLUX_EVENT_BILLING_INVOICE_CREATED = 'urn:flux:event:billing:invoice:created',
        URN_FLUX_EVENT_BILLING_INVOICE_UPDATED = 'urn:flux:event:billing:invoice:updated',
        URN_FLUX_EVENT_BILLING_INVOICE_FAILED = 'urn:flux:event:billing:invoice:failed',
    }

    export enum status {
        URN_FLUX_BILLING_INVOICE_STATUS_PENDING = 'urn:flux:billing:invoice:status:pending',
        URN_FLUX_BILLING_INVOICE_STATUS_FAILED = 'urn:flux:billing:invoice:status:failed',
        URN_FLUX_BILLING_INVOICE_STATUS_AVAILABLE = 'urn:flux:billing:invoice:status:available',
        URN_FLUX_BILLING_INVOICE_STATUS_PAID = 'urn:flux:billing:invoice:status:paid',
        URN_FLUX_BILLING_INVOICE_STATUS_REVERSED = 'urn:flux:billing:invoice:status:reversed',
    }


}

