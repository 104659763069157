/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateProvisioningExclusionRequest } from '../models/CreateProvisioningExclusionRequest';
import type { ProvisioningExclusionSummary } from '../models/ProvisioningExclusionSummary';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ProvisioningService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns ProvisioningExclusionSummary OK
     * @throws ApiError
     */
    public getProvisioningExclusions(): CancelablePromise<Array<ProvisioningExclusionSummary>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v2/provisioning/exclusions',
        });
    }

    /**
     * Create a provisioning exclusion.
     * @param requestBody The new provisioning exclusion to be created.
     * @returns ProvisioningExclusionSummary Created provisioning exclusion successfully.
     * @throws ApiError
     */
    public createProvisioningExclusion(
        requestBody: CreateProvisioningExclusionRequest,
    ): CancelablePromise<ProvisioningExclusionSummary> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/provisioning/exclusions',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                422: `Could not create the provisioning exclusion due to a validation error.`,
            },
        });
    }

    /**
     * Delete a provisioning exclusion.
     * @param provisioningExclusionId The ID of the provisioning exclusion to be deleted.
     * @returns void
     * @throws ApiError
     */
    public deleteProvisioningExclusion(
        provisioningExclusionId: string,
    ): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/v2/provisioning/exclusions/{provisioningExclusionId}',
            path: {
                'provisioningExclusionId': provisioningExclusionId,
            },
            errors: {
                404: `No existing provisioning exclusion found.`,
                422: `Could not delete the provisioning exclusion due to a validation error.`,
            },
        });
    }

}
