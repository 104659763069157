import React, { createContext, FunctionComponent, useContext, useEffect, useState } from 'react';
import { getUsageChargeFilters } from './getUsageChargeFilters';
import { UsageChargeFilter } from 'api/openapi/telemetry';
import { useFlags } from 'launchdarkly-react-client-sdk';

const UsageChargeFiltersContext = createContext<UsageChargeFilter[]>([]);

UsageChargeFiltersContext.displayName = 'UsageChargeFiltersContext';

interface UsageChargeFiltersContextProviderProps {
  children?: React.ReactNode;
}

const UsageChargeFiltersContextProvider: FunctionComponent<
  UsageChargeFiltersContextProviderProps
> = ({ children }) => {
  const { fbau1150UiViewMeasurementPoints, fbau1079AddUsageChargeFilterToVolumeBasedCharges } =
    useFlags();
  const [usageChargeFilters, setUsageChargeFilters] = useState<UsageChargeFilter[]>([]);

  useEffect(() => {
    if (fbau1150UiViewMeasurementPoints || fbau1079AddUsageChargeFilterToVolumeBasedCharges) {
      // note: the usage charge filters API supports pagination.
      // there is a limit of 100 hard coded for now as we want
      // to retrieve all of the usage charge filters in one request.
      getUsageChargeFilters()
        .then((data) => {
          if (data.items) {
            setUsageChargeFilters(data.items);
          }
        })
        .catch((error) => {
          console.error(new Error(`Error while fetching usage charge filters: ${error}`));
        });
    }
  }, []);

  return (
    <UsageChargeFiltersContext.Provider value={usageChargeFilters}>
      {children}
    </UsageChargeFiltersContext.Provider>
  );
};

const useUsageChargeFilters = () => {
  const context = useContext<UsageChargeFilter[]>(UsageChargeFiltersContext);

  if (context === undefined) {
    throw Error('usage charge filters context must be used with usage charge filters provider');
  }

  return context;
};

export { UsageChargeFiltersContextProvider, useUsageChargeFilters };
