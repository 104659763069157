/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AutoProvisioningQueryRequest } from '../models/AutoProvisioningQueryRequest';
import type { Connection } from '../models/Connection';
import type { ConnectionBody } from '../models/ConnectionBody';
import type { ConnectionDetails } from '../models/ConnectionDetails';
import type { ConnectionMessage } from '../models/ConnectionMessage';
import type { Plan } from '../models/Plan';
import type { PlanBody } from '../models/PlanBody';
import type { PlanBodyV1 } from '../models/PlanBodyV1';
import type { PlanLink } from '../models/PlanLink';
import type { PlanLinkBody } from '../models/PlanLinkBody';
import type { PlanState } from '../models/PlanState';
import type { PlanV1 } from '../models/PlanV1';
import type { PublishedError } from '../models/PublishedError';
import type { SupplyAgreement } from '../models/SupplyAgreement';
import type { SupplyPeriod } from '../models/SupplyPeriod';
import type { SupplyPeriodBody } from '../models/SupplyPeriodBody';
import type { SupplyPeriodDetails } from '../models/SupplyPeriodDetails';
import type { SupplyPeriodSummaryList } from '../models/SupplyPeriodSummaryList';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
import {PlanLinks} from "../models/PlanLinks";

export class ConnectionsService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Returns a list of basic information for all Connections
     * @returns Connection List of Connections
     * @throws ApiError
     */
    public getConnections(): CancelablePromise<Array<Connection>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v2/connections',
        });
    }

    /**
     * Creates a new Connection
     * @param requestBody Details of the new Connection to be created.
     * @returns Connection Connection created successfully. Returns details of the new Connection.
     * @throws ApiError
     */
    public createConnection(
        requestBody: ConnectionBody,
    ): CancelablePromise<Connection> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/connections',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                422: `Could not process request due to a validation error. See ConnectionBody schema for validation rules.`,
            },
        });
    }

    /**
     * Retrieves a Connection by ID
     * @param id Id of the Connection requested
     * @returns ConnectionDetails Details of the Connection. Null is returned if no Connection matching the ID supplied is found.
     * @throws ApiError
     */
    public getConnectionById(
        id: string,
    ): CancelablePromise<Array<ConnectionDetails>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v2/connections/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * Updates an existing Connection
     * @param id Id of the Connection to be updated.
     * @param requestBody Updated details for the Connection
     * @returns ConnectionDetails Connection updated successfully. Returns Connection details, with the updates applied.
     * @throws ApiError
     */
    public updateConnection(
        id: string,
        requestBody: ConnectionBody,
    ): CancelablePromise<ConnectionDetails> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/v2/connections/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Connection not found`,
            },
        });
    }

    /**
     * Returns a list of all SupplyPeriods within a Connection, with summary information of each SupplyPeriod
     * @param connectionId Id of the Connection to list SupplyPeriods for
     * @param activeAt A date in which the supplyPeriod wanted is/was active
     * @param from Initial date of a time range in which the supplyPeriod is/was active
     * @param to Final date of a time range in which the supplyPeriod is/was active
     * @returns SupplyPeriodSummaryList List of summary information for all SupplyPeriods within the Connection, together with basic information of the Connection
     * @throws ApiError
     */
    public getSupplyPeriodSummaryListForConnection(
        connectionId: string,
        activeAt?: string,
        from?: string,
        to?: string,
    ): CancelablePromise<SupplyPeriodSummaryList> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v2/connections/{connectionId}/supply-periods',
            path: {
                'connectionId': connectionId,
            },
            query: {
                'activeAt': activeAt,
                'from': from,
                'to': to,
            },
        });
    }

    /**
     * Adds a SupplyPeriod to an existing Connection
     * @param connectionId Id of the Connection to add the SupplyPeriod to
     * @param requestBody Supply Period to be added
     * @returns SupplyPeriod SupplyPeriod successfully added to Connection. Returns details of the new SupplyPeriod.
     * @throws ApiError
     */
    public createSupplyPeriod(
        connectionId: string,
        requestBody: SupplyPeriodBody,
    ): CancelablePromise<SupplyPeriod> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/connections/{connectionId}/supply-periods',
            path: {
                'connectionId': connectionId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Could not process request due to a validation error. See SupplyPeriodBody schema for validation rules.`,
            },
        });
    }

    /**
     * Retrieves a SupplyPeriod by Id
     * @param connectionId Id of the Connection the SupplyPeriod belongs to
     * @param supplyPeriodId Id of the SupplyPeriod requested
     * @param planState
     * @returns SupplyPeriodDetails Details of the SupplyPeriod, including summary information of the Plans attached to the SupplyPeriod.
     * @throws ApiError
     */
    public getSupplyPeriodById(
        connectionId: string,
        supplyPeriodId: string,
        planState?: Array<PlanState>,
    ): CancelablePromise<SupplyPeriodDetails> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v2/connections/{connectionId}/supply-periods/{supplyPeriodId}',
            path: {
                'connectionId': connectionId,
                'supplyPeriodId': supplyPeriodId,
            },
            query: {
                'planState': planState,
            },
        });
    }

    /**
     * Updates a Supply Period for a Connection
     * @param connectionId Id of the Connection the SupplyPeriod belongs to
     * @param supplyPeriodId Id of the SupplyPeriod to be updated
     * @param requestBody Updated details for the SupplyPeriod
     * @returns SupplyPeriod SupplyPeriod updated successfully. Returns SupplyPeriod details, with the updates applied.
     * @throws ApiError
     */
    public updateSupplyPeriod(
        connectionId: string,
        supplyPeriodId: string,
        requestBody: SupplyPeriodBody,
    ): CancelablePromise<SupplyPeriod> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/v2/connections/{connectionId}/supply-periods/{supplyPeriodId}',
            path: {
                'connectionId': connectionId,
                'supplyPeriodId': supplyPeriodId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                404: `SupplyPeriod not found`,
                422: `Could not process request due to a validation error. See SupplyPeriodBody schema for validation rules.`,
            },
        });
    }

    /**
     * Retrieves a SupplyPeriod by SupplyAgreementId
     * @param connectionId Id of the Connection the SupplyPeriod belongs to
     * @param supplyAgreementId Id of the external SupplyAgreement the SupplyPeriod is associated with
     * @param planState
     * @returns SupplyPeriodDetails Details of the SupplyPeriod, including summary information of the Plans attached to the SupplyPeriod.
     * @throws ApiError
     */
    public getSupplyPeriodsBySupplyAgreementId(
        connectionId: string,
        supplyAgreementId: string,
        planState?: Array<PlanState>,
    ): CancelablePromise<Array<SupplyPeriodDetails>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v2/connections/{connectionId}/supply-agreements/{supplyAgreementId}/supply-periods',
            path: {
                'connectionId': connectionId,
                'supplyAgreementId': supplyAgreementId,
            },
            query: {
                'planState': planState,
            },
        });
    }

    /**
     * Creates a PlanLink linking a Plan to a SupplyPeriod
     * @param connectionId Id of the Connection the SupplyPeriod belongs to
     * @param supplyPeriodId Id of the SupplyPeriod for the Plan to be linked to
     * @param requestBody PlanLink to be added
     * @returns PlanLink PlanLink successfully added to SupplyPeriod. Returns details of the new PlanLink.
     * @throws ApiError
     */
    public createPlanLink(
        connectionId: string,
        supplyPeriodId: string,
        requestBody: PlanLinkBody,
    ): CancelablePromise<PlanLink> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/connections/{connectionId}/supply-periods/{supplyPeriodId}/plan-links',
            path: {
                'connectionId': connectionId,
                'supplyPeriodId': supplyPeriodId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Could not process request due to a validation error. See PlanLinkBody schema for validation rules.`,
            },
        });
    }

    /**
     * Updates a PlanLink for a SupplyPeriod
     * @param connectionId Id of the Connection the SupplyPeriod belongs to
     * @param supplyPeriodId Id of the SupplyPeriod the PlanLink belongs to
     * @param id Id of the PlanLink to be updated
     * @param requestBody Updated details for the PlanLink
     * @returns PlanLink PlanLink updated successfully. Returns PlanLink details, with the updates applied.
     * @throws ApiError
     */
    public updatePlanLink(
        connectionId: string,
        supplyPeriodId: string,
        id: string,
        requestBody: PlanLinkBody,
    ): CancelablePromise<PlanLink> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/v2/connections/{connectionId}/supply-periods/{supplyPeriodId}/plan-links/{id}',
            path: {
                'connectionId': connectionId,
                'supplyPeriodId': supplyPeriodId,
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                404: `PlanLink not found`,
                422: `Could not process request due to a validation error. See PlanLinkBody schema for validation rules.`,
            },
        });
    }

    /**
     * Delete an existing PlanLink
     * @param connectionId Id of the Connection the SupplyPeriod belongs to
     * @param supplyPeriodId Id of the SupplyPeriod the PlanLink belongs to
     * @param id Id of the PlanLink to be deleted
     * @returns void
     * @throws ApiError
     */
    public deletePlanLink(
        connectionId: string,
        supplyPeriodId: string,
        id: string,
    ): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/v2/connections/{connectionId}/supply-periods/{supplyPeriodId}/plan-links/{id}',
            path: {
                'connectionId': connectionId,
                'supplyPeriodId': supplyPeriodId,
                'id': id,
            },
            errors: {
                404: `PlanLink not found`,
            },
        });
    }

    /**
     * Get a collection of PlanLinks
     * @param supplyPeriodId Filter plan links by this supply period
     * @param includeRemoved If false, the resulting collection excludes plan links that have been removed.
     * If true, the resulting collection includes plan links that have been removed.
     *
     * @returns PlanLinks A list of PlanLink meeting the query criteria.
     * @throws ApiError
     */
    public getPlanLinksV3(
        supplyPeriodId: string,
        includeRemoved: string = 'false',
    ): CancelablePromise<PlanLinks> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v3/plan-links',
            query: {
                'supplyPeriodId': supplyPeriodId,
                'includeRemoved': includeRemoved,
            },
            errors: {
                422: `Could not process request due to a validation error. See query parameter schema for validation rules.`,
            },
        });
    }

    /**
     * @deprecated
     * Creates a connection specific plan
     * A connection specific plan is created as a copy of a Shared Plan that is already linked to the SupplyPeriod, with a new planName and providerReference. Creating a Connection-Specific Plan will create this new Plan, remove the PlanLink to the existing Shared Plan, and create a new PlanLink to the Connection-Specific Plan. The new PlanLink will have the startDate of the new Plan, and the lower of the endDate of the new Plan and the endDate of the SupplyPeriod.
     *
     * @param connectionId Id of the Connection the SupplyPeriod belongs to
     * @param supplyPeriodId Id of the SupplyPeriod the PlanLink belongs to
     * @param planLinkId Id of the PlanLink that links the SupplyPeriod to the existing Shared Plan that is to be copied
     * @param requestBody Details of the Plan to be created - a copy of the Shared Plans details with a new planName and providerReference.
     * @returns PlanV1 New Connection-Specific Plan created successfully. Returns details of the new Plan.
     * @throws ApiError
     */
    public createConnectionSpecificPlan(
        connectionId: string,
        supplyPeriodId: string,
        planLinkId: string,
        requestBody: PlanBodyV1,
    ): CancelablePromise<PlanV1> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/connections/{connectionId}/supply-periods/{supplyPeriodId}/plan-links/{planLinkId}/connection-specific-plans',
            path: {
                'connectionId': connectionId,
                'supplyPeriodId': supplyPeriodId,
                'planLinkId': planLinkId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                404: `PlanLink of the existing Shared PlanLink not found`,
                422: `Could not process request due to a validation error. Validation rules for createPlan, deletePlanLink and createPlanLink all apply.`,
            },
        });
    }

    /**
     * Creates a connection specific plan
     * A connection specific plan is created as a copy of a Shared Plan that is already linked to the SupplyPeriod, with a new planName and providerReference. Creating a Connection-Specific Plan will create this new Plan, remove the PlanLink to the existing Shared Plan, and create a new PlanLink to the Connection-Specific Plan. The new PlanLink will have the startDate of the new Plan, and the lower of the endDate of the new Plan and the endDate of the SupplyPeriod.
     *
     * @param connectionId Id of the Connection the SupplyPeriod belongs to
     * @param supplyPeriodId Id of the SupplyPeriod the PlanLink belongs to
     * @param planLinkId Id of the PlanLink that links the SupplyPeriod to the existing Shared Plan that is to be copied
     * @param requestBody Details of the Plan to be created - a copy of the Shared Plans details with a new planName and providerReference.
     * @returns Plan New Connection-Specific Plan created successfully. Returns details of the new Plan.
     * @throws ApiError
     */
    public createConnectionSpecificPlanV2(
        connectionId: string,
        supplyPeriodId: string,
        planLinkId: string,
        requestBody: PlanBody,
    ): CancelablePromise<Plan> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/connections/{connectionId}/supply-periods/{supplyPeriodId}/plan-links/{planLinkId}/connection-specific-plans',
            path: {
                'connectionId': connectionId,
                'supplyPeriodId': supplyPeriodId,
                'planLinkId': planLinkId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                404: `PlanLink of the existing Shared PlanLink not found`,
                422: `Could not process request due to a validation error. Validation rules for createPlan, deletePlanLink and createPlanLink all apply.`,
            },
        });
    }

    /**
     * Provisions a Connection according to the SupplyAgreement
     * @param requestBody SupplyAgreement to be actioned
     * @returns SupplyAgreement SupplyAgreement actioned successfully.
     * @throws ApiError
     */
    public supplyAgreement(
        requestBody: SupplyAgreement,
    ): CancelablePromise<SupplyAgreement> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/v2/connections/supplyagreement',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                422: `Could not process request due to a validation error. See SupplyAgreement schema for validation rules.`,
            },
        });
    }

    /**
     * Returns a list of errors for a given connection
     * @param connectionId ID of the connection
     * @param from Date range to query errors on or after
     * @param to Date range to query errors on or before
     * @returns PublishedError List of errors for a given connection
     * @throws ApiError
     */
    public getConnectionErrors(
        connectionId: string,
        from?: string,
        to?: string,
    ): CancelablePromise<Array<PublishedError>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v2/connections/{connectionId}/errors',
            path: {
                'connectionId': connectionId,
            },
            query: {
                'from': from,
                'to': to,
            },
        });
    }


    /**
     * Returns a list of errors for a given connection
     * @param connectionId ID of the connection
     * @param from Start of a range to query errors (stored in UTC)
     * @param to End of a range to query errors (stored in UTC)
     * @returns PublishedError List of errors for a given connection
     * @throws ApiError
     */
    public getConnectionErrorsV3(
        connectionId: string,
        from?: string,
        to?: string,
    ): CancelablePromise<Array<PublishedError>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v3/connections/{connectionId}/errors',
            path: {
                'connectionId': connectionId,
            },
            query: {
                'from': from,
                'to': to,
            },
        });
    }



    /**
     * Updates a Connection according to the ConnectionMessage
     * @param requestBody ConnectionMessage to be actioned
     * @returns ConnectionMessage ConnectionMessage actioned successfully.
     * @throws ApiError
     */
    public connectionMessage(
        requestBody: ConnectionMessage,
    ): CancelablePromise<ConnectionMessage> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/v2/connections/connectionmessage',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                422: `Could not process request due to a validation error. See ConnectionMessage schema for validation rules.`,
            },
        });
    }

    /**
     * Retrieves a Connection by ID from Core
     * @param id Id of the Connection requested
     * @returns Connection Details of the Connection. Null is returned if no Connection matching the ID supplied is found.
     * @throws ApiError
     */
    public getConnectionByIdFromCore(
        id: string,
    ): CancelablePromise<Connection> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v2/connections/{id}/connectionmessage',
            path: {
                'id': id,
            },
        });
    }

    /**
     * Updates connections and sets the auto provisioning flag to true or false
     * @param requestBody Details of the charge set query
     * @returns any List of successfully updated connection ids
     * @throws ApiError
     */
    public bulkChangeAutoProvisionFlag(
        requestBody: AutoProvisioningQueryRequest,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/v2/connections/bulk/auto-provisioning',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
            },
        });
    }

}
