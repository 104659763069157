/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * the type of observance
 */
export enum ObservanceType {
    NATIONAL_HOLIDAY = 'NATIONAL_HOLIDAY',
    LOCAL_HOLIDAY = 'LOCAL_HOLIDAY',
    OBSERVANCE = 'OBSERVANCE',
}
