/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type BaseSupplyAgreementEvent = {
    _version?: string;
    _type?: BaseSupplyAgreementEvent._type;
    _publishedAt?: string;
    /**
     * An system generated identifier used for tracing the event as it flows through the system
     */
    _correlationId?: string;
    /**
     * The id of the supply period.
     */
    id?: string;
    connection?: {
        id?: string;
    };
};

export namespace BaseSupplyAgreementEvent {

    export enum _type {
        LEDGER_SUPPLY_AGREEMENT_UPDATED = 'ledger.supply_agreement.updated',
        LEDGER_SUPPLY_AGREEMENT_CANCELLED = 'ledger.supply_agreement.cancelled',
    }


}

