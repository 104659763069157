import { Form, Formik } from 'formik';
import AuthorizedAction from '../../../common/AuthorizedAction';
import { Permission } from '../../../../auth/getPermissions';
import React from 'react';
import { Connection, useEditConnection } from '../../connectionsApi';

interface AutoProvisioningConfirmationProps {
  connection: Connection;
  close: () => void;
}

const AutoProvisioningConfirmation = ({ connection, close }: AutoProvisioningConfirmationProps) => {
  const { mutateAsync, isLoading } = useEditConnection();
  const handleSubmit = async () => {
    await mutateAsync({
      connectionId: connection.connectionId,
      id: connection.id,
      expectedChargeTags: connection.expectedChargeTags,
      autoProvisioning: !connection.autoProvisioning,
    });
    close();
  };
  return (
    <Formik initialValues={{}} onSubmit={() => handleSubmit()}>
      {() => (
        <Form className="apl-form-layout-v1">
          <div>
            {connection.autoProvisioning
              ? "This will disable auto provisioning of plans for this connection. Existing plans won't be removed automatically. Do you want to proceed?"
              : 'This will enable auto provisioning of plans for this connection. Auto provisioning ' +
                'will only happen if the connection or the current supply period ' +
                'gets updated by Core. Any existing plans will then be overridden. Do you want to proceed?'}
          </div>
          <div className="apl-display-flex apl-justify-content-end">
            <button className="apl-button-v1" onClick={close} type={'button'}>
              Cancel
            </button>
            <AuthorizedAction
              extraClasses="is-primary"
              permission={Permission.CONNECTION_EDIT}
              removeDefaultWeight={true}
              type="submit"
              isDisabled={isLoading}
            >
              Confirm
            </AuthorizedAction>
          </div>
        </Form>
      )}
    </Formik>
  );
};
export default AutoProvisioningConfirmation;
