import { isBefore, isDate } from 'date-fns';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { isNil } from 'ramda';
import React, { FunctionComponent, useState } from 'react';
import { date, object, string } from 'yup';
import { usePlanTags, useReferencePlan } from './planApi';
import AuthorizedAction from '../common/AuthorizedAction';
import { FormRow } from '../form';
import FluxDatePicker from '../form/DatePicker';
import FieldWrapper, {
  FieldWidth,
  inputClassNameBuilder,
  selectClassNameBuilder,
} from '../form/FieldWrapper';
import Alert, { AlertEnum } from '../layout/Alert';
import { ErrorText } from '../layout/Errors';
import { Provider, useProviders } from '../providers/providersApi';
import ErrorDisplay, { ErrorModel } from '../../api/ErrorDisplay';
import { Permission } from '../../auth/getPermissions';
import { alphabeticalSortByObjectProp, FieldOption } from '../../util/helper-func';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useListData } from 'list-data/ListDataContext';
import { Plan, PlanBody, PlanState } from '../../api/openapi/rating-config';
import getConfig from '../../config/getConfig';
import { EuiComboBox, EuiComboBoxOptionOption } from '@elastic/eui';
import { PlanTagReference } from 'api/openapi/rating-config/models/PlanTagReference';

interface PlanFormProps {
  close: () => void;
  error?: ErrorModel;
  isCopy?: boolean;
  isUpdating: boolean;
  plan?: Plan;
  selectedProviderId?: string;
  submitFunction: (plan: PlanBody) => Promise<any>;
  isConnectionSpecificPlan?: boolean;
}

const planSchema = object().shape({
  planName: string().label('Plan name').trim().required('Please enter a plan name').max(200),
  providerId: string().label('Provider').required('Please select a provider'),
  providerReference: string().label('Provider reference').max(200),
  structure: string().label('Structure').required('Please select a structure'),
  jurisdiction: string().label('Jurisdiction'),
  startDate: date()
    .typeError('Must be a valid date')
    .max(new Date('9999-12-31'))
    .label('Start date')
    .required('Please select a start date'),
  endDate: date()
    .typeError('Must be a valid date')
    .max(new Date('9999-12-31'))
    .label('End date')
    .when('startDate', (startDate: Date, schema: any) => {
      if (isDate(startDate) && isBefore(startDate, new Date('9999-12-31'))) {
        return schema.min(startDate, 'End date must be after start date');
      } else {
        return schema;
      }
    })
    .when('structure', (structure: string, schema: any) => {
      if (structure === 'EASI_144' || structure === 'EASI_48') {
        return schema.required();
      } else {
        return schema;
      }
    }),
  timezone: string().label('Timezone'),
});

const PlanForm: FunctionComponent<PlanFormProps> = ({
  close,
  error,
  isCopy,
  isUpdating,
  isTimezoneUpdated,
  plan,
  selectedProviderId,
  submitFunction,
  isConnectionSpecificPlan,
}: any) => {
  const {
    fbau370AddTimezoneParameterToPlanDefinition, // LD client key for fbau-370-add-timezone-parameter-to-plan-definition
    oci710PlanJurisdictions, // LD client key for oci-710-plan-jurisdictions
    lobi515AddPlanTagsToUi, // LD client key is lobi-515-add-plan-tags-to-ui
  } = useFlags();
  const { shareablePlans } = getConfig();
  const [marketFunctionFlags] = useListData(['MARKET_FUNCTION']);
  const { data: planReference, isInitialLoading: isLoadingReference } =
    useReferencePlan(marketFunctionFlags);

  const { data: planTags } = usePlanTags();
  const options: EuiComboBoxOptionOption[] = [];
  planTags?.items.forEach((tag) => {
    const group = options.find((group) => group.label == tag.tagKey);
    const tagOption = { label: tag.tagValue, value: tag.tag, key: tag.tag };
    if (group && group.options) {
      group.options.push(tagOption);
    } else {
      options.push({ label: tag.tagKey, options: [tagOption] });
    }
  });
  const [selectedOptions, setSelected] = useState<EuiComboBoxOptionOption[]>(
    (plan?.tags ?? []).map((tag: PlanTagReference) => ({
      label: tag.tag.split(':')[1],
      value: tag.tag,
      key: tag.tag,
    }))
  );

  const [resultSelectedOptions, setResultSelectedOptions] = useState<PlanTagReference[]>(
    plan?.tags
  );

  const onChange = (selectedOptions: EuiComboBoxOptionOption[]) => {
    setSelected(selectedOptions);
    setResultSelectedOptions(
      selectedOptions.map((option) => {
        return { tag: option.value as string };
      })
    );
  };

  const { data: providers, isError, isInitialLoading: isLoading } = useProviders();

  const isEdit = !isNil(plan);

  const [marketfunction] = useListData(['MARKET_FUNCTION']);
  const jurisdictions = marketfunction?.data[
    'urn:flux:rating-config:market:jurisdictions'
  ] as FieldOption[];

  if (isError) {
    return <p>Something went wrong</p>;
  }

  if (isLoading || isLoadingReference) {
    return <p>Loading</p>;
  }

  function getShareable(plan: PlanBody): boolean {
    if (isConnectionSpecificPlan) {
      return false;
    }

    if (isCopy) {
      return true;
    }

    return plan?.shareable != null ? plan?.shareable : true;
  }

  function getDefaultTimezone(): string {
    if (plan?.timezone) {
      return plan.timezone;
    } else {
      /* When the Timezone on a Plan has not been specified (New or existing Plan) and dealing in a multi Timezone context, the 1st entry of
         planReference?.timezones would be used as the initial value of the DropDown. Implicitly causing isDirty for the Timezone field.
         This is done because the "empty/blank" Option may cause null String values to be persisted to the Database and as such has been removed.
         More explicit handling of AU and others with multiple timezones would require a different approach */
      isTimezoneUpdated = true;

      /* Attempting to get the User's local timezone */
      const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;

      return planReference?.timezones.includes(tz as any)
        ? tz
        : (planReference?.timezones[0] as string);
    }
  }

  return (
    <Formik
      initialValues={{
        planName: isEdit ? `${plan?.planName}` : '',
        providerId: isEdit ? `${plan?.providerId}` : `${selectedProviderId}`,
        providerReference: isEdit ? `${plan?.providerReference}` : '',
        planScope: isEdit
          ? `${plan?.planScope}`
          : isConnectionSpecificPlan
          ? 'CONNECTION_SPECIFIC'
          : 'SHARED',
        structure: isEdit ? `${plan?.structure}` : '',
        shareable: isEdit ? getShareable(plan) : true,
        jurisdiction: isEdit ? `${plan?.jurisdiction}` : '',
        planState: isEdit && !isCopy ? `${plan?.planState}` : PlanState.DRAFT,
        startDate: isEdit ? `${plan?.startDate}` : '',
        endDate: isEdit && plan?.endDate ? `${plan?.endDate}` : '',
        timezone: getDefaultTimezone(),
        tags: isEdit ? resultSelectedOptions : [],
      }}
      validationSchema={planSchema}
      onSubmit={(formValues) => {
        formValues.tags = resultSelectedOptions;
        submitFunction(formValues);
      }}
    >
      {({ errors, touched, dirty, isValid }) => (
        <Form className="apl-form-layout-v1">
          {error && error?.errors?.length && <ErrorDisplay error={error} />}
          {error && (!error.errors || error?.errors.length === 0) && (
            <Alert type={AlertEnum.DANGER}>
              {error?.message
                ? error.message
                : isEdit
                ? 'Could not update plan'
                : 'Could not create plan'}
            </Alert>
          )}
          <FieldWrapper fieldWidth={FieldWidth.FULL} htmlFor="plan-name-field" label="Plan name">
            <Field
              autoFocus={isCopy}
              autoComplete="off"
              data-testid="plan-name-field"
              name="planName"
              id="plan-name-field"
              className={inputClassNameBuilder('planName', errors, touched)}
            />
            <ErrorMessage component={ErrorText} name="planName" />
          </FieldWrapper>

          <FormRow>
            <FieldWrapper fieldWidth={FieldWidth.HALF} htmlFor="provider-field" label="Provider">
              <Field
                disabled={isEdit && !isCopy}
                autoComplete="off"
                data-testid="provider-field"
                name="providerId"
                id="provider-field"
                className={selectClassNameBuilder('providerId', errors, touched)}
                as="select"
              >
                <option value="" />
                {alphabeticalSortByObjectProp('name')(providers).map(
                  (p: Provider | any, index: number) => {
                    return (
                      <option key={index} value={p.id}>
                        {p.name}
                      </option>
                    );
                  }
                )}
              </Field>
              <ErrorMessage component={ErrorText} name="providerId" />
            </FieldWrapper>
            <FieldWrapper
              htmlFor="provider-reference-field"
              label="Provider reference"
              fieldWidth={FieldWidth.HALF}
            >
              <Field
                autoComplete="off"
                data-testid="provider-reference-field"
                name="providerReference"
                id="provider-reference-field"
                className={inputClassNameBuilder('providerReference', errors, touched)}
              />
              <ErrorMessage component={ErrorText} name="providerReference" />
            </FieldWrapper>
          </FormRow>
          {!(isCopy || isConnectionSpecificPlan) && (
            <FormRow>
              <FieldWrapper fieldWidth={FieldWidth.HALF} htmlFor="start-date" label="Start date">
                <Field
                  autoComplete="off"
                  data-testid="start-date"
                  name="startDate"
                  id="start-date"
                  component={FluxDatePicker}
                />
                <ErrorMessage component={ErrorText} name="startDate" />
              </FieldWrapper>
              <FieldWrapper fieldWidth={FieldWidth.HALF} htmlFor="end-date" label="End date">
                <Field
                  autoComplete="off"
                  data-testid="end-date"
                  name="endDate"
                  id="end-date"
                  component={FluxDatePicker}
                />
                {errors.endDate && <ErrorText>{errors.endDate}</ErrorText>}
              </FieldWrapper>
            </FormRow>
          )}

          {!isConnectionSpecificPlan && (
            <FormRow>
              <FieldWrapper
                fieldWidth={FieldWidth.HALF}
                htmlFor="structure-field"
                label="Structure"
              >
                <Field
                  disabled={isEdit}
                  autoComplete="off"
                  data-testid="structure-field"
                  name="structure"
                  id="structure-field"
                  className={selectClassNameBuilder('structure', errors, touched)}
                  as="select"
                >
                  <option value="" />
                  {alphabeticalSortByObjectProp('value')(
                    planReference?.planStructures as readonly { id: string; value: string }[]
                  ).map((s, index: number) => (
                    <option key={index} value={s.id}>
                      {s.value}
                    </option>
                  ))}
                </Field>
                <ErrorMessage component={ErrorText} name="structure" />
              </FieldWrapper>

              {oci710PlanJurisdictions && (
                <FieldWrapper
                  fieldWidth={FieldWidth.HALF}
                  htmlFor="jurisdiction-field"
                  label="Jurisdiction"
                >
                  <Field
                    autoComplete="off"
                    data-testid="jurisdiction-field"
                    name="jurisdiction"
                    id="jurisdiction-field"
                    className={selectClassNameBuilder('jurisdiction', errors, touched)}
                    as="select"
                  >
                    <option value="" />
                    {jurisdictions?.map((option) => (
                      <option key={option.label} value={option.label}>
                        {option.label}
                      </option>
                    ))}
                  </Field>
                  <ErrorMessage component={ErrorText} name="jurisdiction" />
                </FieldWrapper>
              )}
            </FormRow>
          )}
          {(fbau370AddTimezoneParameterToPlanDefinition || shareablePlans) && (
            <FormRow>
              {fbau370AddTimezoneParameterToPlanDefinition && (
                <FieldWrapper
                  htmlFor="timezone-field"
                  label="Timezone"
                  fieldWidth={FieldWidth.HALF}
                >
                  <Field
                    autoComplete="off"
                    data-testid="timezone-field"
                    name="timezone"
                    id="timezone-field"
                    className={selectClassNameBuilder('timezone', errors, touched)}
                    as="select"
                  >
                    {planReference?.timezones.map(
                      (s: string | number | readonly string[], index: number) => (
                        <option key={index} value={s}>
                          {s}
                        </option>
                      )
                    )}
                  </Field>
                  <ErrorMessage component={ErrorText} name="timezone" />
                </FieldWrapper>
              )}

              {shareablePlans &&
                (isConnectionSpecificPlan || plan?.planScope === 'CONNECTION_SPECIFIC') && (
                  <FieldWrapper
                    fieldWidth={FieldWidth.NONE}
                    htmlFor="shareable-field"
                    label="Shareable"
                  >
                    <Field
                      disabled={isCopy}
                      className={selectClassNameBuilder('shareable', errors, touched)}
                      data-testid="shareable-field"
                      name="shareable"
                      id="shareable-field"
                      as="select"
                    >
                      <option key={'shareable-option-yes'} value="true">
                        Yes
                      </option>
                      <option key={'shareable-option-no'} value="false">
                        No
                      </option>
                    </Field>
                    <ErrorMessage component={ErrorText} name="shareable" />
                  </FieldWrapper>
                )}
            </FormRow>
          )}

          {lobi515AddPlanTagsToUi && (
            <FormRow>
              <FieldWrapper htmlFor="tags-field" label="Plan Tags" fieldWidth={FieldWidth.HALF}>
                <Field
                  autoComplete="off"
                  data-testid="tags-field"
                  id="tags-field"
                  name="tags"
                  value={resultSelectedOptions}
                  as={() => (
                    <EuiComboBox
                      aria-label="Accessible screen reader label"
                      placeholder="Search Plan Tags"
                      options={options}
                      selectedOptions={selectedOptions}
                      onChange={onChange}
                    />
                  )}
                />
              </FieldWrapper>
            </FormRow>
          )}

          <div className="apl-display-flex apl-justify-content-end">
            <button
              className="apl-button-v1"
              data-testid="cancel-button"
              disabled={isUpdating}
              onClick={close}
              type="button"
            >
              Cancel
            </button>
            <AuthorizedAction
              extraClasses="is-primary"
              isDisabled={
                isUpdating ||
                !isValid ||
                (!dirty && isEdit && !isTimezoneUpdated && resultSelectedOptions == plan.tags)
              }
              permission={Permission.PLAN_EDIT}
              removeDefaultWeight={true}
              testId="add-plan-button"
              title={isUpdating || !isValid || (!dirty && isEdit) ? '' : undefined}
              type="submit"
            >
              {isEdit ? 'Save' : 'Next'}
            </AuthorizedAction>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default PlanForm;
