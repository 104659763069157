import { useQuery } from '@tanstack/react-query';
import { billingLifecycleApiClient } from '../../api';
import { DiscountVariation } from '../../api/openapi/billing-lifecycle-api/models/DiscountVariation';

export const useDiscountVariations = (scopedTo: string, options?: Record<string, unknown>) => {
  return useQuery(
    ['get-discount-variations', scopedTo],
    async () => await billingLifecycleApiClient.discountVariations.getDiscountVariations(scopedTo),
    {
      ...options,
    }
  );
};

export const toString = (discountVariations: Array<DiscountVariation>) => {
  return discountVariations
    .map((dv) => dv.displayName)
    .filter((pv) => pv && pv.length > 0)
    .join(', ');
};
