/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type ErrorDetails = {
    _type?: ErrorDetails._type;
    /**
     * The overarching description of the error
     */
    urn?: string;
    attributes?: Array<{
        attribute?: string;
        urn?: string;
    }>;
};

export namespace ErrorDetails {

    export enum _type {
        ERROR = 'error',
    }


}

