import React, { KeyboardEvent } from 'react';
import searchIcon from '../../icons/search.svg';
import '../dashboard/missing-plans/SearchFilter.scss';

interface ConnectionSearchProps {
  handleOnKeyUp: (event: KeyboardEvent<HTMLInputElement>) => void;
}

const ConnectionSearch = ({ handleOnKeyUp }: ConnectionSearchProps) => {
  return (
    <div className="search-filter">
      <input
        className="apl-text-input-v1 search-filter__input"
        onKeyUp={(event) => handleOnKeyUp(event)}
        placeholder="Find a connection"
        type="text"
      />
      <img alt="search icon" className="search-filter__icon" src={searchIcon} />
    </div>
  );
};

export default ConnectionSearch;
