export function formatPlanLinkRemovalDate(isoDateStr: string): string {
  const date = new Date(isoDateStr);

  const options: Intl.DateTimeFormatOptions = {
    day: '2-digit',
    month: 'short',
    year: 'numeric',
    hour: 'numeric',
    minute: '2-digit',
    hour12: true,
  };

  const formattedDate = new Intl.DateTimeFormat(undefined, options).format(date);

  const dateParts = formattedDate.split(', ');
  if (dateParts.length === 2) {
    const [datePart, timePart] = dateParts;
    return `${datePart} ${timePart}`;
  } else if (dateParts.length === 1) {
    return dateParts[0]; // Fallback in case only date is returned
  }

  return formattedDate;
}
