import React, { FunctionComponent } from 'react';
import { ErrorModel } from '../../../api/ErrorDisplay';
import { MeasurementPoint as MeasurementPointType } from 'api/openapi/telemetry';
import MeasurementPointForm from './MeasurementPointForm';
import { useUpdateMeasurementPoint } from 'components/telemetry/telemetryUsageApi';

interface ViewMeasurementPointProps {
  id: string;
  connectionId: string;
  measurementPoint: MeasurementPointType;
  close: () => void;
}

const ViewMeasurementPoint: FunctionComponent<ViewMeasurementPointProps> = ({
  id,
  connectionId,
  measurementPoint,
  close,
}) => {
  const { mutateAsync, isLoading: isSubmitting, error } = useUpdateMeasurementPoint();

  const viewMeasurementPoint = async (measurementPoint: MeasurementPointType) => {
    const result = await mutateAsync(measurementPoint);

    if (result) {
      close();
    }
  };

  return (
    <div className="apl-form-layout-v1" data-testid="view-measurement-point-form">
      <h2>View Measurement Point</h2>

      <MeasurementPointForm
        id={id}
        connectionId={connectionId}
        measurementPoint={measurementPoint}
        close={close}
        error={error as ErrorModel}
        isUpdating={isSubmitting}
        submitFunction={viewMeasurementPoint}
        readOnly={true}
      />
    </div>
  );
};

export default ViewMeasurementPoint;
