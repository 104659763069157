/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { ChargeV1 } from './ChargeV1';
import type { PlanScope } from './PlanScope';
import type { PlanState } from './PlanState';

/**
 * @deprecated
 */
export type PlanBodyV1 = {
    /**
     * Must be unique among Plans with the same Provider.
     */
    planName: string;
    /**
     * Must be a valid ID of an existing Provider.
     */
    providerId: string;
    shareable: boolean;
    structure: PlanBodyV1.structure;
    planState?: PlanState;
    planScope?: PlanScope;
    /**
     * If PlanScope is SHARED, then the providerReference must be unique among Plans that have the same Provider and have overlapping dates.
     */
    providerReference?: string;
    startDate: string;
    /**
     * If end date is provided, it must be after start date.
     */
    endDate?: string;
    charges?: Array<ChargeV1>;
    /**
     * Timezone being used in the Market.
     */
    timezone?: string;
};

export namespace PlanBodyV1 {

    export enum structure {
        SIMPLE_CHARGE = 'SIMPLE_CHARGE',
        EASI_144 = 'EASI_144',
        EASI_48 = 'EASI_48',
    }


}

