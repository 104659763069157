import cx from 'classnames';
import React, { useState } from 'react';
import searchIcon from '../../icons/search.svg';
import AdvancedPagination from '../common/AdvancedPagination';
import EmptyTableRow from '../common/EmptyTableRow';
import '../dashboard/missing-plans/SearchFilter.scss';
import Card from '../layout/Card';
import Page, { PageHeader } from '../layout/Page';
import { useLossFactorCodes } from '../telemetry/lossFactorApi';
import { useJurisdictionCode } from '../../environment';
import { FieldOption } from '../../util/helper-func';
import downloadAllLossFactorCsvHook, {
  mapApiData,
  csvHeaders,
} from '../../hooks/downloadAllLossFactorCsvHook';

const NETWORK_CODE_URN_BASE = 'urn:flux:network:code';
export const JURISDICTION_CODE_URN_BASE = 'urn:flux:network:jurisdiction:code';
export const LOSS_FACTOR_CODE_URN_BASE = 'urn:flux:network:loss_factor:code';

export const lossFactorKeyUrn = useJurisdictionCode()
  ? JURISDICTION_CODE_URN_BASE
  : NETWORK_CODE_URN_BASE;

const generateExternalIdentifiers = (
  codes: string | undefined,
  searchCode: string,
  searchLossFactorKey: string | undefined
) => {
  const externalIdentifiers = [];

  if (codes) {
    codes
      .split(',')
      .filter((c) => c !== '')
      .forEach((code) => externalIdentifiers.push(lossFactorKeyUrn + `:${code}`));
  }

  if (searchCode) {
    externalIdentifiers.push(`${LOSS_FACTOR_CODE_URN_BASE}:${searchCode}`);
  }

  if (searchLossFactorKey && searchLossFactorKey != '-') {
    // filter not selected
    externalIdentifiers.push(`${lossFactorKeyUrn}:${searchLossFactorKey}`);
  }

  return externalIdentifiers;
};

type LossFactorCodesProps = {
  backLink: string;
  title: string;
  lossFactorKeyOptions: Array<FieldOption>;
  isProviderSet: boolean;
  providerCodes?: string;
};

const LossFactorCodes = ({
  backLink,
  title,
  lossFactorKeyOptions,
  isProviderSet,
  providerCodes,
}: LossFactorCodesProps) => {
  const [search, setSearch] = useState('');
  const [limit, setLimit] = useState(100);
  const [page, setPage] = useState(0);
  const [sortAsc, setSortAsc] = useState(true);

  const offset = page !== 0 ? page * limit : 0;

  const [lossFactorKeyOption, setLossFactorKeyOption] = useState<string>('-');

  const {
    // data contains paginated loss factors meeting the search filter requirements
    // contains only a page's worth of data - e.g., 100/886 loss factors
    data,
    isError,
    isInitialLoading: isLoading,
  } = isProviderSet
    ? useLossFactorCodes(
        {
          externalIdentifiers: generateExternalIdentifiers(providerCodes, search, undefined),
          limit,
          offset,
        },
        {
          enabled: !!providerCodes,
        }
      )
    : useLossFactorCodes(
        {
          externalIdentifiers: generateExternalIdentifiers('', search, lossFactorKeyOption),
          limit,
          offset,
        },
        {
          enabled: true,
        }
      );

  // this code creates a UI table displaying a selection of loss factors, with a button at the bottom of the UI table to download a CSV file of all loss factors (in the backend)
  // The UI table and CSV file share the same headers.
  const uiTableHeaders = csvHeaders;
  const { downloadAllLossFactorCsvHandler, isCsvLoading } = downloadAllLossFactorCsvHook();
  const uiTableData = mapApiData(data, sortAsc, isProviderSet); // UI table uses same API data mapper as CSV

  return (
    <>
      <PageHeader backLink={backLink} title={title} />
      <Page>
        <Card className="apl-pb-none">
          <div
            className={cx('apl-display-flex', 'apl-flex-row', 'apl-align-items-center', 'apl-pb')}
          >
            {!isProviderSet && (
              <div className="apl-field-v1">
                <label className="apl-field__label apl-mr-s apl-mb-none">Loss code:</label>
              </div>
            )}
            <div className="search-filter apl-mr-s">
              <input
                className="apl-text-input-v1 search-filter__input"
                onKeyUp={(event) => {
                  if (event.key === 'Enter') {
                    const { value } = event.target as any;
                    setSearch(value);
                    setPage(0); // search filtered results will not be on the current page, so reset to first page. E.g., if only 4 results returned, will not be on currently viewed page 9 (801-900 results) but will be on page 1
                  }
                }}
                placeholder="Find a loss code"
                type="text"
              />
              <img alt="search icon" className="search-filter__icon" src={searchIcon} />
            </div>
            {!isProviderSet && (
              <div className="apl-field-v1">
                <label className="apl-field__label apl-mr-s apl-mb-none">Jurisdiction:</label>
              </div>
            )}
            {!isProviderSet && (
              <div className="search-filter apl-mr-s">
                <select
                  className="apl-select-v1_0"
                  data-testid="loss factor key option filter field"
                  id="loss-factor-key-option-field"
                  name="loss-factor-key-option"
                  onChange={(event) => {
                    setLossFactorKeyOption(event?.target.value);
                    setPage(0);
                  }}
                  value={lossFactorKeyOption}
                >
                  {lossFactorKeyOptions.map((option: FieldOption) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
              </div>
            )}
          </div>
          <table className="apl-table-v1 apl-mb-none">
            {isError && (
              <tbody>
                <tr>
                  <td>Sorry, there was an error.</td>
                </tr>
              </tbody>
            )}
            {isLoading && (
              <tbody>
                <tr>
                  <td>Loading...</td>
                </tr>
              </tbody>
            )}
            {data && data.totalCount > 0 && (
              <>
                <thead>
                  <tr>
                    <th
                      className={cx('is-sortable', {
                        'is-sorted-asc': sortAsc,
                        'is-sorted-desc': !sortAsc,
                      })}
                      onClick={() => setSortAsc(!sortAsc)}
                    >
                      {uiTableHeaders[0]}
                    </th>
                    {!isProviderSet && <th>{csvHeaders[1]}</th>}
                    <th>{uiTableHeaders[2]}</th>
                    <th>{uiTableHeaders[3]}</th>
                    <th>{uiTableHeaders[4]}</th>
                    <th>{uiTableHeaders[5]}</th>
                    <th>{uiTableHeaders[6]}</th>
                    <th>{uiTableHeaders[7]}</th>
                    <th>{uiTableHeaders[8]}</th>
                    <th>{uiTableHeaders[9]}</th>
                  </tr>
                </thead>
                <tbody>
                  {uiTableData?.map((item) => {
                    return item == undefined ? undefined : (
                      <tr key={`loss-factor-code-${item.id}-${item.index}`}>
                        <td>{item.rowData[0]}</td>
                        {!isProviderSet && <td>{item.rowData[1]}</td>}
                        <td>{item.rowData[2]}</td>
                        <td>{item.rowData[3]}</td>
                        <td>{item.rowData[4]}</td>
                        <td>{item.rowData[5]}</td>
                        <td>{item.rowData[6]}</td>
                        <td>{item.rowData[7]}</td>
                        <td>{item.rowData[8]}</td>
                        <td>{item.rowData[9]}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </>
            )}
            {data &&
              data.totalCount === 0 &&
              (isProviderSet ? (
                <EmptyTableRow message="Sorry, there are no loss factor codes for this provider." />
              ) : (
                <EmptyTableRow message="Sorry, there are no loss factor codes for this selection." />
              ))}
          </table>
          <div className="card__inner apl-py-none">
            {data && data.totalCount > 0 && (
              <div>
                <AdvancedPagination
                  limit={limit}
                  limitOptions={[100]}
                  onLimitChange={setLimit}
                  onPageChange={setPage}
                  page={page}
                  totalItems={data?.totalCount ?? 0}
                />
                {!isProviderSet && ( // AU only
                  <div className="apl-display-flex apl-flex-column apl-align-items-end apl-mx-s apl-my-s">
                    <button className="apl-button-v1" onClick={downloadAllLossFactorCsvHandler}>
                      {isCsvLoading ? 'Loading...' : 'Download CSV'}
                    </button>
                  </div>
                )}
              </div>
            )}
          </div>
        </Card>
      </Page>
    </>
  );
};

export default LossFactorCodes;
