import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

import ConnectionsMissingPlanTabs from './ConnectionsMissingPlanTabs';
import { generateDates } from './missingPlansUtil';
import Card from '../../layout/Card';
import Page, { ErrorPage, LoadingPage, PageHeader } from '../../layout/Page';
import { useSupplyPeriodAttribute } from '../../../hooks';

import searchIcon from '../../../icons/search.svg';
import './SearchFilter.scss';
import { MissingPlanItem, useMissingPlans } from '../../reports/reportsApi';
import ConnectionsMissingPlanTable from './ConnectionsMissingPlanTable';

const ConnectionsWithoutNetworkPlans = () => {
  const [search, setSearch] = useState('');
  const { SupplyPeriodAttributeSelect, supplyPeriodAttribute } = useSupplyPeriodAttribute();

  const { month } = useParams<{ month: string }>();
  const dates = generateDates();

  // find data for the given month we are viewing
  const monthData = dates.find((date) => date.monthShort === month);

  const {
    data,
    isError,
    isInitialLoading: isLoading,
  } = useMissingPlans(
    monthData?.firstDOM || '',
    monthData?.lastDOM || '',
    'NETWORK',
    supplyPeriodAttribute
  );

  // in case url has invalid month as the parameter
  if (monthData === undefined || isError) {
    return <ErrorPage />;
  }

  if (isLoading) {
    return <LoadingPage />;
  }

  // Returns only ENERGY, save to grab first entry
  const missingPlans = data && data.missingPlans.length > 0 ? data.missingPlans[0].connections : [];

  return (
    <>
      <PageHeader showBack title="Connections without network plans" />
      <Page>
        <Card>
          <ConnectionsMissingPlanTabs
            chargeTag="NETWORK"
            dates={dates}
            month={month}
            attributes={supplyPeriodAttribute}
          />
          <div className="card__inner card__column-gap table-filter apl-py apl-display-flex">
            <div className="table-filter__input search-filter">
              <input
                className="apl-text-input-v1 search-filter__input"
                onChange={(event) => setSearch(event.target.value)}
                placeholder="Find a connection"
                type="text"
              />
              <img alt="search icon" className="search-filter__icon" src={searchIcon} />
            </div>
            {SupplyPeriodAttributeSelect}
          </div>
          {data && (
            <ConnectionsMissingPlanTable
              data={missingPlans.filter((item: MissingPlanItem) =>
                item.externalConnectionIdentifier.toLowerCase().includes(search.toLowerCase())
              )}
            />
          )}
        </Card>
      </Page>
    </>
  );
};

export default ConnectionsWithoutNetworkPlans;
