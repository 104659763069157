import React from 'react';
import { UsageV1 } from '../../billing/billingInvoicesApi';

interface PlanTableUsageFieldProps {
  precision?: number;
  usage: UsageV1;
}

export const PlanTableUsageField = ({ usage, precision = 2 }: PlanTableUsageFieldProps) => {
  const { value, unit, isLossAdjusted } = usage;

  const usageValue = value ? `${parseFloat(value).toFixed(precision)} ${unit}` : '-';
  const lfLabel = isLossAdjusted ? 'Loss-adjusted' : 'Raw';

  return (
    <>
      {usageValue}
      {lfLabel && <span className="badge apl-ml-s">{lfLabel}</span>}
    </>
  );
};
