/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PlanExport } from '../models/PlanExport';
import type { PlanExportV1 } from '../models/PlanExportV1';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ExportService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @deprecated
     * Exports Plans by list of Provider Ids or list of Plan Ids
     * @param providerIds List of Provider ids to export Plans for. At least one Provider Id or one Plan Id is required to process the export.
     * @param planIds List of Plan ids to export Plans for. At least one Provider Id or one Plan Id is required to process the export.
     * @returns PlanExportV1 A plan export object containing a list of Plans, a list of the Qualifiers on those Plans and a list of the Providers for those Plans.
     * @throws ApiError
     */
    public exportPlans(
        providerIds?: Array<string>,
        planIds?: Array<string>,
    ): CancelablePromise<PlanExportV1> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/export',
            query: {
                'providerIds': providerIds,
                'planIds': planIds,
            },
            errors: {
                404: `No Plans were found to match the Plan or Provider Ids provided.`,
            },
        });
    }

    /**
     * Exports Plans by list of Provider Ids or list of Plan Ids
     * @param providerIds List of Provider ids to export Plans for. At least one Provider Id or one Plan Id is required to process the export.
     * @param planIds List of Plan ids to export Plans for. At least one Provider Id or one Plan Id is required to process the export.
     * @returns PlanExport A plan export object containing a list of Plans, a list of the Qualifiers on those Plans and a list of the Providers for those Plans.
     * @throws ApiError
     */
    public exportPlansV2(
        providerIds?: Array<string>,
        planIds?: Array<string>,
    ): CancelablePromise<PlanExport> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v2/export',
            query: {
                'providerIds': providerIds,
                'planIds': planIds,
            },
            errors: {
                404: `No Plans were found to match the Plan or Provider Ids provided.`,
            },
        });
    }

}
