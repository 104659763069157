import React, { FunctionComponent, useState } from 'react';
import { Permission } from 'auth/getPermissions';
import AuthorizedAction from 'components/common/AuthorizedAction';
import ContextMenuButton, {
  ContextMenuButtonRenderProps,
} from 'components/common/ContextMenuButton';
import { EquationLink } from '../../telemetry-calculator/teleCalcApi';

interface EquationLinkProps {
  connectionId: string;
  equationLink: EquationLink;
}

const EquationLinkItem: FunctionComponent<EquationLinkProps> = (props: EquationLinkProps) => {
  const { equationLink } = props;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_modalOpen, setModalOpen] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_editItem, setEditItem] = useState<EquationLink>();

  return (
    <>
      <span>
        {' '}
        {equationLink.equation.name}{' '}
        <span className="badge apl-ml-s">{equationLink.equation.description}</span>
      </span>
      <ContextMenuButton align="right" testIdSuffix={`${equationLink.id}`} width="180px">
        {({ closeMenu }: ContextMenuButtonRenderProps) => (
          <div className="context-menu__block">
            <AuthorizedAction
              extraClasses="context-menu__button"
              onClick={() => {
                closeMenu();
                setEditItem(equationLink);
                setModalOpen(true);
              }}
              permission={Permission.METRIC_EDIT}
              removeDefaultClass={true}
              removeDefaultWeight={true}
              {...{
                isDisabled: true,
                title: 'Can not edit equation link',
              }}
            >
              Edit
            </AuthorizedAction>
          </div>
        )}
      </ContextMenuButton>
    </>
  );
};

export default EquationLinkItem;
