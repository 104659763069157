import React, { FunctionComponent, useState } from 'react';
import cx from 'classnames';
import Modal from 'react-modal';
import { Link, useParams } from 'react-router-dom';
import AddSimpleCharge from './AddSimpleCharge';
import ChargeDetails from './ChargeDetails';
import PlanActionsBar from '../PlanActionsBar';
import PlanHeader from '../PlanHeader';
import UpdatePlan from '../UpdatePlan';
import { Connection } from '../../connections/connectionsApi';
import { chargeModalStyles, modalStyles } from '../../layout/Modal';
import Page, { PageHeader } from '../../layout/Page';
import caretRight from '../../../icons/caret-right.svg';
import { Charge, Plan, PlanUsage } from '../../../api/openapi/rating-config';

interface SimplePlanDetailsProps {
  connection?: Connection;
  plan: Plan | undefined;
  usage: PlanUsage | undefined;
  supplyPeriodId?: string;
  contractedPartyId?: string;
}

const SimplePlanDetails: FunctionComponent<SimplePlanDetailsProps> = ({
  connection,
  plan,
  usage,
  supplyPeriodId,
  contractedPartyId,
}) => {
  const [addChargeIsOpen, setAddChargeIsOpen] = useState(false);

  const closeAddCharge = () => setAddChargeIsOpen(false);
  const openAddCharge = () => setAddChargeIsOpen(true);

  const { planId } = useParams<{ planId: string }>();

  const provider = {
    id: plan?.providerId,
    name: plan?.providerName || '',
  };

  const [editPlanIsOpen, setEditPlanIsOpen] = useState(false);
  const closeEditPlan = () => setEditPlanIsOpen(false);

  return (
    <>
      <PageHeader
        isSticky={true}
        showBack={!connection}
        title={
          connection
            ? () => (
                <>
                  <Link
                    className="page__title-link"
                    to={`/connections/${connection?.id}/supply-periods/${supplyPeriodId}/contracted-parties/${contractedPartyId}`}
                  >
                    {connection?.connectionId}
                  </Link>
                  <img src={caretRight} alt="" />
                  {plan?.planName}
                </>
              )
            : plan?.planName
        }
      >
        <PlanActionsBar
          onAddCharge={openAddCharge}
          onEditPlan={() => setEditPlanIsOpen(true)}
          plan={plan}
          usage={usage}
        />
      </PageHeader>
      <Page>
        <div
          className={cx('apl-display-flex', 'apl-align-items-start', 'apl-justify-content-between')}
        >
          <div
            className={cx(
              'apl-display-flex',
              'apl-flex-column',
              'apl-align-items-center',
              'apl-width-full'
            )}
          >
            <div className="half_xl apl-mb">
              <PlanHeader plan={plan} planId={planId} />
            </div>

            <div className="half_xl">
              {plan?.charges
                ? plan.charges
                    .sort((a, b) => {
                      const aType = a.chargeType.toLowerCase();
                      const bType = b.chargeType.toLowerCase();

                      return aType < bType ? -1 : aType > bType ? 1 : 0;
                    })
                    .map((c: Charge, index: number) => (
                      <ChargeDetails
                        charge={c}
                        index={index + 1}
                        key={c.id}
                        plan={plan}
                        planStatus={plan?.planState}
                        provider={provider}
                      />
                    ))
                : []}
            </div>
          </div>
        </div>

        <Modal
          isOpen={addChargeIsOpen}
          onRequestClose={closeAddCharge}
          style={chargeModalStyles}
          shouldCloseOnOverlayClick={false}
        >
          <AddSimpleCharge close={closeAddCharge} plan={plan} provider={provider} />
        </Modal>

        <Modal
          isOpen={editPlanIsOpen}
          onRequestClose={closeEditPlan}
          style={modalStyles}
          shouldCloseOnOverlayClick={false}
        >
          <UpdatePlan close={closeEditPlan} planId={planId} plan={plan} />
        </Modal>
      </Page>
    </>
  );
};

export default SimplePlanDetails;
