import { ChangeEvent } from 'react';
import { addDays, format, isBefore, isDate } from 'date-fns';
import { isNil } from 'ramda';
import { v4 } from 'uuid';
import { array, date, number, object, string, ValidationError } from 'yup';
import { AibUiLocaleCurrencyUnit, AibUiLocaleMassTonne } from 'locales/locale';
import { capitaliseFirstText } from 'util/helper-func';
import { SimpleRate } from './SimpleChargeForm';
import {
  chargeRequiresFlowDirection,
  chargeRequiresHistoricalMeasure,
  chargeRequiresIntervalSize,
  chargeRequiresLookbackMonths,
  chargeRequiresLossAdjustment,
  chargeRequiresPowerFactorThreshold,
  chargeRequiresSummarisationFunction,
  chargeRequiresTopNPeriods,
  chargeRequiresUsageChargeFilter,
  ChargeTypeConfig,
  ChargeTypesConfig,
  findChargeTypeByValue,
} from 'list-data/charge-types/chargeTypesConfigUtils';
import { UsageChargeFilter as UsageChargeFilterType } from '../../../api/openapi/telemetry';
import { Charge, QualifierLink, Plan, Rate, RateSchedule } from 'api/openapi/rating-config';

export const generateSchedules = (charge: Charge) =>
  charge?.schedules?.map((schedule: RateSchedule) => ({
    validFrom: schedule.validFrom || '',
    validTo: schedule?.validTo || '',
    rates: schedule?.rates?.map((rate: Rate) => ({
      rate: rate.rate || 0,
      cost: rate?.cost || 0,
      minThreshold: rate?.minThreshold || 0,
      summarisationGroup: rate?.summarisationGroup || '',
      volumeAdjustmentFactor: !isNil(rate?.volumeAdjustmentFactor)
        ? rate?.volumeAdjustmentFactor
        : null,
      spotPercentage: !isNil(rate?.spotPercentage) ? rate?.spotPercentage : null,
      rateQualifiers: rate?.rateQualifiers?.map((rateQualifier: QualifierLink) => ({
        id: rateQualifier?.qualifierId,
        name: rateQualifier?.name,
      })),
      applicableTimePeriods: rate?.applicableTimePeriods?.map(
        (applicableTimePeriod: QualifierLink) => ({
          id: applicableTimePeriod?.qualifierId,
          name: applicableTimePeriod?.name,
        })
      ),
    })),
  }));

/**
 * @deprecated since version 3.7.2
 * remove method when FF cfd872SteppedTariffs is removed
 */
export const generateSimpleRates = (charge: Charge) =>
  charge?.schedules?.map(
    (s) =>
      ({
        key: v4(),
        validFrom: s.validFrom || '',
        validTo: s.validTo || '',
        rate: s?.rates?.[0] ? s.rates[0].rate : 0,
        rateQualifiers: s.rates?.[0]
          ? s?.rates?.[0].rateQualifiers?.map((q) => ({
              id: q.qualifierId,
              name: q.name,
            }))
          : [],
        applicableTimePeriods: s.rates?.[0]
          ? s.rates?.[0].applicableTimePeriods?.map((a) => ({
              id: a.qualifierId,
              name: a.name,
            }))
          : [],
        summarisationGroup: s.rates?.[0].summarisationGroup || '',
        volumeAdjustmentFactor: s.rates?.[0]
          ? isNil(s.rates?.[0].volumeAdjustmentFactor)
            ? null
            : s.rates[0].volumeAdjustmentFactor
          : null,
        spotPercentage: s.rates?.[0]
          ? isNil(s.rates[0].spotPercentage)
            ? null
            : s.rates[0].spotPercentage
          : null,
      } as SimpleRate)
  );

export const generateChargeTagOptions = (isAu = false, cfd866OnBillFinance = false) => {
  const tags = ['NETWORK', 'ENERGY', 'EQUIPMENT', 'LEVY', 'ENERGYFEE'];
  if (isAu) {
    tags.push('METRIC');
  }
  if (isAu && cfd866OnBillFinance) {
    tags.push('FINANCE');
  }
  return tags.map((tag) => ({
    label: tag === 'ENERGYFEE' ? 'Energy fee' : capitaliseFirstText(tag),
    value: tag,
  }));
};

export const generateChargeCalcOptions = () => {
  return ['REQUIRED', 'OPTIONAL'].map((option) => ({
    label: capitaliseFirstText(option),
    value: option,
  }));
};

export const generateAggregationMethodOptions = () => {
  return [
    {
      label: 'Coincidental',
      value: 'urn:flux:rating:charge:aggregation:coincidental',
    },
    {
      label: 'Summated',
      value: 'urn:flux:rating:charge:aggregation:summated',
    },
  ];
};

/**
 * @deprecated since version 3.7.2
 * remove method when FF cfd872SteppedTariffs is removed
 */
export const simpleRateListToScheduleList = (rateList: SimpleRate[]): RateSchedule[] => {
  return rateList.map(
    ({
      applicableTimePeriods,
      cost,
      rate,
      rateQualifiers,
      spotPercentage,
      summarisationGroup,
      validFrom,
      validTo,
      volumeAdjustmentFactor,
    }: SimpleRate) => {
      const rateQualifierLinks = rateQualifiers.map((q) => ({
        qualifierId: q.id,
        name: q.name,
      }));

      const applicableTimePeriodQualfierLinks = applicableTimePeriods.map((a) => ({
        qualifierId: a.id,
        name: a.name,
      }));

      const schedule: RateSchedule = {
        validTo,
        validFrom,
        rates: [
          {
            applicableTimePeriods: applicableTimePeriodQualfierLinks,
            ...(cost !== null && { cost: cost }),
            rate,
            rateQualifiers: rateQualifierLinks,
            ...(spotPercentage !== null && { spotPercentage: spotPercentage }),
            summarisationGroup: summarisationGroup,
            timeRanges: [],
            ...(volumeAdjustmentFactor !== null && {
              volumeAdjustmentFactor: volumeAdjustmentFactor,
            }),
          },
        ],
      };

      return schedule;
    }
  );
};

export function formatRateUnit({
  chargeBasis,
  rate,
  flags,
  tonneUnit,
  currencyUnit,
}: {
  chargeBasis: string;
  rate: number;
  flags: any;
  tonneUnit: AibUiLocaleMassTonne;
  currencyUnit: AibUiLocaleCurrencyUnit;
}) {
  const { fbau721ExpandVolumeChargeToIncludeNewAttributesAndChargeBasis } = flags;

  if (
    fbau721ExpandVolumeChargeToIncludeNewAttributesAndChargeBasis &&
    chargeBasis.includes(tonneUnit.base.singular)
  ) {
    if (Number(rate) === 1) {
      return tonneUnit.base.singular;
    } else {
      return tonneUnit.base.plural;
    }
  }
  if (Number(rate) === 1) {
    return currencyUnit.minor.singular;
  } else {
    return currencyUnit.minor.plural;
  }
}

export const onlyNumberChange =
  (handleChange: (change: any) => void) => (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const numValue = Number(value);

    if (value === '-' || (!isNil(numValue) && !isNaN(numValue))) {
      handleChange(e);
    }
  };

type YupTestContext = {
  options: any;
  path: string;
  parent: any;
  createError: (params: any) => Error;
};

export const simpleChargeFormSchema = (plan: Plan | undefined, defaults: any, flags: any) => {
  const {
    fbau635BillingCategoryForInvoicePresentation,
    fbau802HistoricalPeakDemandUi,
    fbau1079AddUsageChargeFilterToVolumeBasedCharges,
    oci1482EnableMinimumThresholdMethod,
    cfd872SteppedTariffs,
  } = flags;
  const { chargeTypesConfig } = defaults;

  // we have a dynamic form validation as we need to validate dates against
  // the associated plan
  const baseSchema = object().shape({
    billingDescriptor: string()
      .label('Billing descriptor')
      .trim()
      .required('Please enter a billing descriptor')
      .max(255),
    chargeType: string().label('Charge').required('Please select a charge type'),
    chargeBasis: string().label('Charge basis').required('Please select a basis'),
    chargeName: string()
      .label('Charge name')
      .trim()
      .required('Please enter a charge name')
      .max(255),
    chargeTags: string().label('Charge tag').required('Please select a charge tag'),
    chargeCalculation: string().label('Charge calculation').required(),
    chargeReference: string().max(255),
    flowDirection: string()
      .label('Flow direction')
      .when('chargeType', {
        is: (type: string) => chargeRequiresFlowDirection(type, chargeTypesConfig),
        then: string().required('Please select a direction'),
      }),
    powerFactor: number()
      .label('Power factor')
      .min(0)
      .max(1)
      .when('chargeType', {
        is: (type: string) => chargeRequiresPowerFactorThreshold(type, chargeTypesConfig),
        then: number().required(),
      }),
    topPeriods: number()
      .integer()
      .label('Top N periods')
      .max(500)
      .min(1)
      .when('chargeType', {
        is: (type: string) => chargeRequiresTopNPeriods(type, chargeTypesConfig),
        then: number().required(),
      }),
    sumFunction: string()
      .label('Summarisation function')
      .when('chargeType', {
        is: (type: string) => chargeRequiresSummarisationFunction(type, chargeTypesConfig),
        then: string().required(),
      }),
    lookbackMonths: number()
      .integer()
      .label('Lookback months')
      .max(24)
      .min(1)
      .when('chargeType', {
        is: (type: string) => chargeRequiresLookbackMonths(type, chargeTypesConfig),
        then: number().required('Please enter lookback months'),
      }),
    intervalSize: number()
      .integer()
      .label('Interval size')
      .min(1)
      .when('chargeType', {
        is: (type: string) => chargeRequiresIntervalSize(type, chargeTypesConfig),
        then: number().required('Please enter an interval size'),
      }),
    minimumChargeable: number().integer().label('Minimum chargeable demand').min(0),
    readingQuality: string().max(255),
    isTaxed: string().max(255),
  });

  let simpleChargeSchema = baseSchema.shape({
    adjustmentToUse: string()
      .label('Losses')
      .when('chargeType', {
        is: (type: string) => chargeRequiresLossAdjustment(type, chargeTypesConfig),
        then: string().required('Please select an option'),
      }),
  });

  simpleChargeSchema = fbau635BillingCategoryForInvoicePresentation
    ? simpleChargeSchema.shape({
        billingCategory: string()
          .label('Billing category')
          .trim()
          .required('Please enter a billing category')
          .max(255),
      })
    : simpleChargeSchema;

  simpleChargeSchema = oci1482EnableMinimumThresholdMethod
    ? simpleChargeSchema.shape({
        minimumThresholdMethod: string()
          .label('Minimum Threshold Method')
          .when('minimumChargeable', (minimumChargeable: any, schema: any) => {
            if (minimumChargeable && minimumChargeable != null) {
              return schema.required(
                'Because you have a Minimum Chargeable Demand, you must enter a Minimum Threshold Method'
              );
            } else {
              return schema;
            }
          }),
      })
    : simpleChargeSchema;

  simpleChargeSchema = fbau802HistoricalPeakDemandUi
    ? simpleChargeSchema.shape({
        historicalMeasure: string()
          .label('Demand type')
          .when('chargeType', {
            is: (type: string) => chargeRequiresHistoricalMeasure(type, chargeTypesConfig),
            then: string().required('Please select a demand type'),
          }),
      })
    : simpleChargeSchema;

  simpleChargeSchema = fbau1079AddUsageChargeFilterToVolumeBasedCharges
    ? simpleChargeSchema.shape({
        usageChargeFilter: string()
          .label('Usage Charge')
          .when('chargeType', {
            is: (type: string) => chargeRequiresUsageChargeFilter(type, chargeTypesConfig),
            then: string().required('Please select a usage charge filter'),
          }),
      })
    : simpleChargeSchema;

  simpleChargeSchema = cfd872SteppedTariffs
    ? simpleChargeSchema.shape({
        schedules: array().of(
          object().shape({
            validFrom: date()
              .label('Valid from')
              .max(
                plan?.endDate ? plan.endDate : new Date('9999-12-31'),
                plan?.endDate
                  ? `Must be before ${format(new Date(plan.endDate), 'dd/MM/yyyy')}`
                  : `Must be before ${format(new Date('9999-12-31'), 'dd/MM/yyyy')}`
              )
              .min(
                plan?.startDate,
                `Must be after ${format(
                  plan?.startDate ? new Date(plan?.startDate) : new Date(),
                  'dd/MM/yyyy'
                )}`
              )
              .required('Please select a date'),
            validTo: date()
              .label('Valid to')
              .max(
                plan?.endDate ? plan.endDate : new Date('9999-12-31'),
                plan?.endDate
                  ? `Must be before ${format(new Date(plan.endDate), 'dd/MM/yyyy')}`
                  : `Must be before ${format(new Date('9999-12-31'), 'dd/MM/yyyy')}`
              )
              .when('validFrom', (validFrom: Date, schema: any) => {
                if (isDate(validFrom) && isBefore(validFrom, new Date('9999-12-31'))) {
                  return schema.min(
                    addDays(new Date(validFrom), 1),
                    'Must be after valid from date'
                  );
                } else {
                  return schema;
                }
              }),
            rates: array()
              .of(
                object().shape({
                  rate: number()
                    .label('Rate')
                    .required('Please enter a rate')
                    .typeError('Must be a number')
                    .max(100000000),
                  minThreshold: number()
                    .label('Min daily threshold')
                    .min(0)
                    .required()
                    .typeError('Must be a positive number'),
                  summarisationGroup: string().max(255),
                  volumeAdjustmentFactor: number()
                    .label('Adjustment factor')
                    .nullable()
                    .typeError('Must be a number')
                    .max(100000000),
                  spotPercentage: number()
                    .label('Spot Percentage')
                    .nullable()
                    .typeError('Must be a number')
                    .max(100),
                })
              )
              .test(
                'are-min-daily-thresholds-in-ascending-order',
                'Min daily threshold must be in ascending order',
                function (rates) {
                  if (!rates || rates.length === 0) {
                    return true; // No rates, validation should pass
                  }

                  const errors = [];
                  if (rates.length >= 2) {
                    for (let i = 1; i < rates.length; i++) {
                      const currentRate = rates[i];
                      const previousRate = rates[i - 1];

                      if (currentRate !== undefined && previousRate !== undefined) {
                        if (
                          currentRate.minThreshold !== undefined &&
                          previousRate.minThreshold !== undefined
                        ) {
                          if (currentRate.minThreshold <= previousRate.minThreshold) {
                            // Validation fails if current min daily threshold is not higher than previous min threshold
                            errors.push(
                              this.createError({
                                path: `${(this as YupTestContext).options.path}[${i}].minThreshold`,
                                message: `Must be in ascending order`,
                              })
                            );
                          }
                        }
                      }
                    }
                  }

                  return errors.length > 0 ? new ValidationError(errors) : true;
                }
              )
              .test(
                'is-first-min-daily-threshold-zero',
                'The first min daily threshold must be 0',
                function (rates) {
                  if (!rates || rates.length === 0) {
                    return true; // No rates, validation should pass
                  }

                  // Validation fails if the first min daily threshold is not 0
                  if (rates[0].minThreshold !== 0) {
                    return this.createError({
                      path: `${(this as YupTestContext).options.path}[0].minThreshold`,
                      message: `First min daily threshold must be zero`,
                    });
                  }

                  return true;
                }
              ),
          })
        ),
      })
    : simpleChargeSchema.shape({
        rates: array().of(
          object().shape({
            rate: number()
              .label('Rate')
              .required('Please enter a rate')
              .typeError('Must be a number')
              .max(100000000),
            validFrom: date()
              .label('Valid from')
              .max(
                plan?.endDate ? plan.endDate : new Date('9999-12-31'),
                plan?.endDate
                  ? `Must be before ${format(new Date(plan.endDate), 'dd/MM/yyyy')}`
                  : `Must be before ${format(new Date('9999-12-31'), 'dd/MM/yyyy')}`
              )
              .min(
                plan?.startDate,
                `Must be after ${format(
                  plan?.startDate ? new Date(plan?.startDate) : new Date(),
                  'dd/MM/yyyy'
                )}`
              )
              .required('Please select a date'),
            validTo: date()
              .label('Valid to')
              .max(
                plan?.endDate ? plan.endDate : new Date('9999-12-31'),
                plan?.endDate
                  ? `Must be before ${format(new Date(plan.endDate), 'dd/MM/yyyy')}`
                  : `Must be before ${format(new Date('9999-12-31'), 'dd/MM/yyyy')}`
              )
              .when('validFrom', (validFrom: Date, schema: any) => {
                if (isDate(validFrom) && isBefore(validFrom, new Date('9999-12-31'))) {
                  return schema.min(
                    addDays(new Date(validFrom), 1),
                    'Must be after valid from date'
                  );
                } else {
                  return schema;
                }
              }),
            summarisationGroup: string().max(255),
            volumeAdjustmentFactor: number()
              .label('Adjustment factor')
              .nullable()
              .typeError('Must be a number')
              .max(100000000),
            spotPercentage: number()
              .label('Spot Percentage')
              .nullable()
              .typeError('Must be a number')
              .max(100),
          })
        ),
      });

  return simpleChargeSchema;
};

export const initialValues = (charge: Charge | undefined, defaults: any, flags: any) => {
  const {
    cfd872SteppedTariffs,
    fbau1079AddUsageChargeFilterToVolumeBasedCharges,
    fbau635BillingCategoryForInvoicePresentation,
    fbau802HistoricalPeakDemandUi,
  } = flags;

  const {
    defaultChargeTag,
    defaultChargeCalculation,
    aggregationMethodValue,
    defaultDemandType,
    defaultUsageChargeFilter,
  } = defaults;

  const simpleRates: SimpleRate[] | undefined = charge ? generateSimpleRates(charge) : undefined;
  const schedules: any[] | undefined = charge ? generateSchedules(charge) : undefined;
  const chargeTags: string = charge?.chargeTags?.[0] || defaultChargeTag || '';

  const fbau635Properties = fbau635BillingCategoryForInvoicePresentation
    ? {
        billingCategory:
          charge?.display?.category?.toLocaleLowerCase() ||
          (charge?.chargeTags?.[0].toLocaleLowerCase() === 'finance'
            ? 'other'
            : charge?.chargeTags?.[0].toLocaleLowerCase()) ||
          (defaultChargeTag.toLocaleLowerCase() === 'finance'
            ? 'other'
            : defaultChargeTag.toLocaleLowerCase()),
        billingDescriptor: charge?.display?.descriptor || charge?.billingDescriptor || '', // remove billingDescriptor default with fbau635
      }
    : {
        billingDescriptor: charge?.billingDescriptor || '',
      };

  const fbau802properties = fbau802HistoricalPeakDemandUi
    ? { historicalMeasure: charge?.historicalMeasure || defaultDemandType }
    : {};

  const fbau1079properties = fbau1079AddUsageChargeFilterToVolumeBasedCharges
    ? { usageChargeFilter: charge?.usageChargeFilter || defaultUsageChargeFilter }
    : {};

  const cfd872Properties = cfd872SteppedTariffs
    ? {
        schedules: isNil(schedules)
          ? [
              {
                validTo: '',
                validFrom: '',
                rates: [
                  {
                    applicableTimePeriods: [],
                    minThreshold: 0.0,
                    rate: 0,
                    rateQualifiers: [],
                    spotPercentage: null,
                    summarisationGroup: '',
                    volumeAdjustmentFactor: null,
                  },
                ],
              },
            ]
          : schedules,
      }
    : {
        rates: isNil(simpleRates)
          ? [
              {
                applicableTimePeriods: [],
                key: v4(),
                minThreshold: 0.0,
                rate: 0,
                rateQualifiers: [],
                spotPercentage: null,
                summarisationGroup: '',
                validFrom: '',
                validTo: '',
                volumeAdjustmentFactor: null,
              },
            ]
          : simpleRates,
      };

  return {
    chargeType: charge?.chargeType || '',
    chargeBasis: charge?.chargeBasis || '',
    chargeTags,
    chargeCalculation: charge?.chargeCalculation || defaultChargeCalculation || '',
    aggregationMethod: charge?.aggregationMethod || aggregationMethodValue,
    chargeReference: charge?.chargeReference || '',
    ...cfd872Properties,
    chargeName: charge?.chargeName || '',
    flowDirection: charge?.flowDirection || Charge.flowDirection.INTO,
    powerFactor: charge?.powerFactor || 0.95,
    topPeriods: charge?.topPeriods || '',
    sumFunction: charge?.sumFunction || Charge.sumFunction.AVERAGE,
    adjustmentToUse: JSON.stringify(
      charge?.adjustmentToUse || ['urn:flux:rating:charge:adjustment:none']
    ),
    ...fbau635Properties,
    lookbackMonths: charge?.lookbackMonths || '',
    intervalSize: charge?.intervalSize || '',
    minimumChargeable: charge?.minimumChargeable || '',
    minimumThresholdMethod: charge?.minimumThresholdMethod || '',
    ...fbau802properties,
    ...fbau1079properties,
    readingQuality: charge?.readingQuality || 'BOTH',
    isTaxed: charge?.isTaxed || 'TAXED',
  };
};

export function isTonneRateOverlay(param: any, tonneUnit: any) {
  return param.includes(tonneUnit.base.singular);
}

/**
 * @deprecated since version 3.7.2
 * remove method when FF cfd872SteppedTariffs is removed
 */
export function resetVolumeAdjustmentFactor(rates: any) {
  rates.forEach((rate: { volumeAdjustmentFactor: number | null }) => {
    rate.volumeAdjustmentFactor = null;
  });
}

export function getTextOverlay(
  param: string,
  tonneUnit: AibUiLocaleMassTonne,
  currencyUnit: AibUiLocaleCurrencyUnit,
  flags: Record<string, boolean>
) {
  const { fbau721ExpandVolumeChargeToIncludeNewAttributesAndChargeBasis } = flags;
  if (
    fbau721ExpandVolumeChargeToIncludeNewAttributesAndChargeBasis &&
    isTonneRateOverlay(param, tonneUnit)
  ) {
    return tonneUnit.base.plural;
  } else {
    return currencyUnit.minor.plural;
  }
}

/**
 * @deprecated since version 3.7.2
 * remove method when FF cfd872SteppedTariffs is removed
 */
export function resetSpotPercentage(rates: any) {
  rates.forEach((rate: { spotPercentage: number | null }) => {
    rate.spotPercentage = null;
  });
}

/**
 * @deprecated since version 3.7.2
 * remove method when FF cfd872SteppedTariffs is removed
 */
export function resetHistoricalMeasure(rates: any) {
  rates.forEach((rate: { historicalMeasure: string | null }) => {
    rate.historicalMeasure = null;
  });
}

export function getDefaultUsageChargeFilter(
  usageChargeFilterList: UsageChargeFilterType[]
): string {
  let returnVal = '';
  usageChargeFilterList.map((filter: UsageChargeFilterType) => {
    if (filter.filterName === 'market') {
      returnVal = filter.filterName;
    }
  });
  return returnVal;
}

export function findAndSanitiseChargeTypeByValue(
  chargeTypeVal: string | undefined,
  chargeTypesConf: ChargeTypesConfig,
  flags: any
): ChargeTypeConfig | undefined {
  const { fbau721ExpandVolumeChargeToIncludeNewAttributesAndChargeBasis } = flags;
  const cType = findChargeTypeByValue(chargeTypeVal, chargeTypesConf);

  if (!fbau721ExpandVolumeChargeToIncludeNewAttributesAndChargeBasis && cType?.basis) {
    cType.basis = cType.basis.filter((basis) => !basis.includes('tonnes/MWh'));
  }
  return cType;
}
