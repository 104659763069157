import { useListData } from 'list-data/ListDataContext';
import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSearchQuery } from '.';

type SupplyPeriodAttributes = {
  label: string;
  urns: string[];
  defaultSelection?: boolean;
};

type SupplyPeriodAttribute = {
  SupplyPeriodAttributeSelect: JSX.Element;
  supplyPeriodAttribute: string[];
  supplyPeriodAttributeURLSearchParams: string;
};

/**
 * @typedef {Object} SupplyPeriodAttribute
 * @property {JSX.Element} SupplyPeriodAttributeSelect select filter element to choose supply period attribute
 * @property {Array} supplyPeriodAttribute selected filter values
 * @property {string} supplyPeriodAttributeURLSearchParams selected filter values
 */

/**
 * Hook that provides a UML supply period attribute filter element and the list of corresponding values.
 * A default option will be selected if none or non-matching values are provided on load.
 * The URL is updated with the chosen supply period attribute's values and supports being used across tabs.
 *
 * @returns {SupplyPeriodAttribute} Object containing the filter and chosen values
 */
export default function useSupplyPeriodAttribute(): SupplyPeriodAttribute {
  const navigate = useNavigate();
  const replace = (url: string) => {
    navigate(url, { replace: true });
  };
  const { pathname, search } = useLocation();
  const query = useSearchQuery();

  const [supplyPeriodAttributes, setSupplyPeriodAttributes] = useState<
    Record<string, SupplyPeriodAttributes>
  >({
    loading: {
      label: 'loading',
      urns: [],
      defaultSelection: true,
    },
  });

  const [supplyPeriodAttributesRaw] = useListData(['CONNECTION_TYPES']);
  useEffect(() => {
    if (supplyPeriodAttributesRaw && supplyPeriodAttributes.loading) {
      setSupplyPeriodAttributes(supplyPeriodAttributesRaw?.data);
    }
  }, [supplyPeriodAttributesRaw]);

  const supplyPeriodAttributeDefault = query.has('attributes')
    ? query.getAll('attributes').sort()
    : Object.values(supplyPeriodAttributes).find(
        (supplyPeriodAttribute) => supplyPeriodAttribute.defaultSelection === true
      )?.urns ?? Object.values(supplyPeriodAttributes)[0].urns;
  const [supplyPeriodAttribute, setSupplyPeriodAttribute] = useState(supplyPeriodAttributeDefault);

  function setSupplyPeriodAttributeAndUpdateHistory(supplyPeriodAttributes: Array<string>) {
    if (supplyPeriodAttributes && supplyPeriodAttributes.length > 0) {
      const params = new URLSearchParams(search);
      // keep other search params
      params.delete('attributes');
      supplyPeriodAttributes.forEach((spa) => params.append('attributes', spa));
      replace(`${pathname}?${params.toString()}`);

      setSupplyPeriodAttribute(supplyPeriodAttributes);
    }
  }

  useEffect(() => {
    setSupplyPeriodAttributeAndUpdateHistory(supplyPeriodAttributeDefault);
  }, [supplyPeriodAttributes]);

  useEffect(() => {
    setSupplyPeriodAttributeAndUpdateHistory(supplyPeriodAttribute);
  }, [pathname]);

  const SupplyPeriodAttributeSelect = useMemo(() => {
    return (
      <div>
        <select
          className="apl-select-v1_0"
          data-testid="uml-supply-period-attributes-select"
          style={{ minWidth: '145px' }}
          onChange={(event) =>
            setSupplyPeriodAttributeAndUpdateHistory(event?.target?.value?.split(',').sort())
          }
          value={supplyPeriodAttribute.toString()}
        >
          {Object.entries(supplyPeriodAttributes).map(([key, value]) => (
            <option key={key} value={value.urns.sort()}>
              {value.label}
            </option>
          ))}
        </select>
      </div>
    );
  }, [supplyPeriodAttribute, supplyPeriodAttributes, pathname]);

  const supplyPeriodAttributeURLSearchParams = useMemo(() => {
    const params = new URLSearchParams();
    supplyPeriodAttribute.forEach((attribute) => params.append('attributes', attribute));
    return params.toString();
  }, [supplyPeriodAttribute]);

  return {
    SupplyPeriodAttributeSelect,
    supplyPeriodAttribute,
    supplyPeriodAttributeURLSearchParams,
  };
}
