import React from 'react';
import { useCurrencyTranslation } from 'hooks';
import { LineItem, UnitValue } from '../../billing/billingInvoicesApi';

interface DiscountsTableProps {
  discounts: LineItem[];
}

const DiscountsTable = ({ discounts }: DiscountsTableProps) => {
  const { translateCurrency } = useCurrencyTranslation();

  return (
    <div data-testid="discounts table">
      <div className="table-filter">
        <h3 className="table-filter__heading">Discounts</h3>
      </div>
      <table className="apl-table-v1 apl-width-full">
        <thead>
          <tr>
            <th>Description</th>
            <th>Quantity</th>
            <th>Rate</th>
            <th>Total</th>
          </tr>
        </thead>
        <tbody>
          {discounts &&
            discounts.map((discount, index) => {
              const { description, usage, rate, amount } = discount;

              const unit = usage?.unit;
              const value = usage?.value ? parseFloat(usage?.value) : undefined;

              const usageUnitValue: UnitValue = {
                unit,
                value,
              };

              return (
                <tr key={`${description}-${index}`}>
                  <td>{description}</td>
                  <td>{translateCurrency(usageUnitValue)}</td>
                  <td>{rate?.value && rate?.unit && `${rate.value} ${rate.unit}`}</td>
                  <td>{translateCurrency(amount)}</td>
                </tr>
              );
            })}
        </tbody>
      </table>
    </div>
  );
};

export default DiscountsTable;
