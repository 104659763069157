import React from 'react';
import {
  EuiButtonEmpty,
  EuiButtonIcon,
  EuiComboBox,
  EuiComboBoxOptionOption,
  EuiDatePicker,
  EuiFieldText,
  EuiFlexGrid,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFormRow,
  EuiPanel,
  EuiSpacer,
  EuiText,
} from '@elastic/eui';
import { FieldArray } from 'formik';
import { getTextOverlay, onlyNumberChange } from './simpleChargeUtils';
import moment, { Moment } from 'moment';
import { SummarisationGroup } from 'api/openapi/rating-config';
import { CurrencyUnit } from 'hooks';
import { AibUiLocaleMassTonne } from 'locales/locale';

type SteppedTariffFormProps = {
  addBillGroupName: (newValue: string) => Promise<void>;
  currencyUnit: CurrencyUnit;
  errors: any;
  flags: Record<string, any>;
  handleChange: any;
  setFieldValue: any;
  summarisationGroupList: any;
  tonneUnit: AibUiLocaleMassTonne;
  touched: any;
  values: any;
};

function SteppedTariffForm({
  addBillGroupName,
  currencyUnit,
  errors,
  flags,
  handleChange,
  setFieldValue,
  summarisationGroupList,
  tonneUnit,
  touched,
  values,
}: SteppedTariffFormProps) {
  const onCreateSummarisationGroup = (searchValue: string, fieldName: string) => {
    const normalizedSearchValue = searchValue.trim();

    if (!normalizedSearchValue) {
      return;
    }

    addBillGroupName(normalizedSearchValue);
    setFieldValue(fieldName, normalizedSearchValue);
  };

  return (
    <FieldArray
      name="schedules"
      validateOnChange={false}
      render={(schedulesArrayHelpers) => (
        <React.Fragment>
          <EuiFlexGroup alignItems="baseline" gutterSize="s" style={{ marginBottom: 0 }}>
            <EuiFlexItem grow={false}>
              <EuiText grow={false}>
                <h4>Rate details</h4>
              </EuiText>
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
              <EuiButtonEmpty
                data-testid="add-rate"
                id="add-rate"
                type="button"
                formNoValidate={true}
                onClick={() =>
                  schedulesArrayHelpers.unshift({
                    validFrom: '',
                    validTo: '',
                    rates: [
                      {
                        applicableTimePeriods: [],
                        minThreshold: 0,
                        rate: 0,
                        rateQualifiers: [],
                        spotPercentage: null,
                        volumeAdjustmentFactor: null,
                      },
                    ],
                  })
                }
              >
                Add rate
              </EuiButtonEmpty>
            </EuiFlexItem>
          </EuiFlexGroup>
          <EuiSpacer size="s" style={{ marginBottom: 0 }} />
          <div style={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
            {values?.schedules?.map((schedule: any, scheduleIndex: number) => {
              const summarisationGroupOptions: EuiComboBoxOptionOption[] =
                summarisationGroupList.map((summarisationGroup: SummarisationGroup) => ({
                  value: summarisationGroup.id,
                  label: summarisationGroup.name,
                }));

              return (
                <EuiFlexItem
                  key={`schedule-${scheduleIndex}`}
                  data-testid={`schedule-${scheduleIndex}`}
                  grow={false}
                >
                  <EuiPanel hasBorder={true} hasShadow={false} formNoValidate={true} grow={false}>
                    <EuiFlexGrid>
                      <EuiFlexGroup gutterSize="s">
                        {/* Grid spacer */}
                        <EuiFlexItem style={{ minWidth: '60px' }} />

                        {/* Valid from */}
                        <EuiFlexItem grow={6}>
                          <EuiFormRow
                            label="Valid from"
                            display="rowCompressed"
                            data-testid={`schedule-${scheduleIndex}-valid-from`}
                            isInvalid={
                              !!errors?.schedules?.[scheduleIndex]?.validFrom &&
                              !!touched?.schedules?.[scheduleIndex]?.validFrom
                            }
                            error={errors?.schedules?.[scheduleIndex]?.validFrom}
                          >
                            <EuiDatePicker
                              placeholder="DD/MM/YYYY"
                              autoComplete="off"
                              compressed={true}
                              id={`schedule-${scheduleIndex}-valid-from`}
                              name={`schedules.${scheduleIndex}.validFrom`}
                              dateFormat="DD/MM/YYYY"
                              selected={schedule.validFrom ? moment(schedule.validFrom) : null}
                              onClear={() => {
                                setFieldValue(`schedules.${scheduleIndex}.validFrom`, '');
                              }}
                              onChange={(date: Moment) => {
                                setFieldValue(
                                  `schedules.${scheduleIndex}.validFrom`,
                                  date ? date.format('YYYY-MM-DD') : ''
                                );
                              }}
                            />
                          </EuiFormRow>
                        </EuiFlexItem>

                        {/* Valid to */}
                        <EuiFlexItem grow={6}>
                          <EuiFormRow
                            label="Valid to (optional)"
                            display="rowCompressed"
                            data-testid={`schedule-${scheduleIndex}-valid-to`}
                            isInvalid={
                              !!errors?.schedules?.[scheduleIndex]?.validTo &&
                              !!touched?.schedules?.[scheduleIndex]?.validTo
                            }
                            error={errors?.schedules?.[scheduleIndex]?.validTo}
                          >
                            <EuiDatePicker
                              placeholder="DD/MM/YYYYY"
                              autoComplete="off"
                              compressed={true}
                              id={`schedule-${scheduleIndex}-valid-to`}
                              name={`schedules.${scheduleIndex}.validTo`}
                              dateFormat="DD/MM/YYYY"
                              selected={schedule.validTo ? moment(schedule.validTo) : null}
                              onClear={() => {
                                setFieldValue(`schedules.${scheduleIndex}.validTo`, '');
                              }}
                              onChange={(date: Moment) => {
                                setFieldValue(
                                  `schedules.${scheduleIndex}.validTo`,
                                  date ? date.format('YYYY-MM-DD') : ''
                                );
                              }}
                            />
                          </EuiFormRow>
                        </EuiFlexItem>

                        {/* Remove schedule */}
                        <EuiFlexItem grow={4}>
                          <EuiFlexGroup alignItems="flexStart" justifyContent="flexEnd">
                            <EuiFlexItem grow={false}>
                              {scheduleIndex !== 0 && (
                                <EuiButtonIcon
                                  id={`remove-rate-${scheduleIndex + 1}`}
                                  data-testid={`remove-rate-${scheduleIndex + 1}`}
                                  iconType="cross"
                                  onClick={() => schedulesArrayHelpers.remove(scheduleIndex)}
                                  aria-label={`Remove rate ${scheduleIndex + 1}`}
                                />
                              )}
                            </EuiFlexItem>
                          </EuiFlexGroup>
                        </EuiFlexItem>
                      </EuiFlexGroup>

                      <EuiFlexGrid columns={1} gutterSize="s">
                        <FieldArray
                          name={`schedules.${scheduleIndex}.rates`}
                          validateOnChange={false}
                          render={(ratesArrayHelper) =>
                            schedule.rates.map((rate: any, rateIndex: number) => {
                              const selectedSummarisationGroup = summarisationGroupOptions.filter(
                                (summarisationGroupOption) =>
                                  summarisationGroupOption.label === rate.summarisationGroup
                              );

                              return (
                                <EuiFlexGroup
                                  key={`schedule-${scheduleIndex}-rate-${rateIndex}`}
                                  data-testid={`schedule-${scheduleIndex}-rate-${rateIndex}`}
                                  gutterSize="s"
                                >
                                  {/* Usage block text */}
                                  <EuiFlexItem grow={2} style={{ justifyContent: 'center' }}>
                                    <EuiFormRow display="rowCompressed">
                                      <EuiText
                                        id={`schedule-${scheduleIndex}-rate-${rateIndex}-usage-block`}
                                        data-testid={`schedule-${scheduleIndex}-rate-${rateIndex}-usage-block`}
                                        className="eui-textCenter"
                                        style={{
                                          textAlign: 'center',
                                          /* Align the usage block label with the row. 
                                          Only required for the first row as its fields have labels at the top. 
                                          16px input label font-size and 4px for the label's bottom margin. */
                                          ...(rateIndex === 0 && { marginTop: 'calc(16px + 4px)' }),
                                        }}
                                        size="xs"
                                      >{`Usage block ${rateIndex + 1}`}</EuiText>
                                    </EuiFormRow>
                                  </EuiFlexItem>

                                  {/* Rate */}
                                  <EuiFlexItem grow={4}>
                                    <EuiFormRow
                                      label={rateIndex === 0 ? 'Rate' : ''}
                                      display="rowCompressed"
                                      isInvalid={
                                        !!errors?.schedules?.[scheduleIndex]?.rates?.[rateIndex]
                                          ?.rate &&
                                        !!touched?.schedules?.[scheduleIndex]?.rates?.[rateIndex]
                                          ?.rate
                                      }
                                      error={
                                        errors?.schedules?.[scheduleIndex]?.rates?.[rateIndex]?.rate
                                      }
                                    >
                                      <EuiFieldText
                                        autoComplete="off"
                                        compressed={true}
                                        id={`schedule-${scheduleIndex}-rate-${rateIndex}-rate`}
                                        data-testid={`schedule-${scheduleIndex}-rate-${rateIndex}-rate`}
                                        name={`schedules.${scheduleIndex}.rates.${rateIndex}.rate`}
                                        value={rate.rate}
                                        onChange={onlyNumberChange(handleChange)}
                                        append={getTextOverlay(
                                          values.chargeBasis,
                                          tonneUnit,
                                          currencyUnit,
                                          flags
                                        )}
                                      />
                                    </EuiFormRow>
                                  </EuiFlexItem>

                                  {/* Bill group */}
                                  <EuiFlexItem grow={4}>
                                    <EuiFormRow
                                      label={rateIndex === 0 ? 'Bill group (optional)' : ''}
                                      display="rowCompressed"
                                    >
                                      <EuiComboBox
                                        id={`schedule-${scheduleIndex}-rate-${rateIndex}-summarisation-group`}
                                        data-testid={`schedule-${scheduleIndex}-rate-${rateIndex}-summarisation-group`}
                                        placeholder="Select"
                                        compressed={true}
                                        singleSelection={{ asPlainText: true }}
                                        options={summarisationGroupOptions}
                                        onCreateOption={(searchValue) =>
                                          onCreateSummarisationGroup(
                                            searchValue,
                                            `schedules.${scheduleIndex}.rates.${rateIndex}.summarisationGroup`
                                          )
                                        }
                                        isClearable={false}
                                        selectedOptions={selectedSummarisationGroup}
                                        onChange={(selectedOption) =>
                                          setFieldValue(
                                            `schedules.${scheduleIndex}.rates.${rateIndex}.summarisationGroup`,
                                            selectedOption.length !== 0
                                              ? selectedOption[0].label
                                              : ''
                                          )
                                        }
                                      />
                                    </EuiFormRow>
                                  </EuiFlexItem>

                                  {/* Min threshold */}
                                  <EuiFlexItem grow={4}>
                                    <EuiFormRow
                                      label={rateIndex === 0 ? 'Min daily threshold' : ''}
                                      display="rowCompressed"
                                      isInvalid={
                                        !!errors?.schedules?.[scheduleIndex]?.rates?.[rateIndex]
                                          ?.minThreshold &&
                                        !!touched?.schedules?.[scheduleIndex]?.rates?.[rateIndex]
                                          ?.minThreshold
                                      }
                                      error={
                                        errors?.schedules?.[scheduleIndex]?.rates?.[rateIndex]
                                          ?.minThreshold
                                      }
                                    >
                                      <EuiFieldText
                                        id={`schedule-${scheduleIndex}-rate-${rateIndex}-min-threshold`}
                                        data-testid={`schedule-${scheduleIndex}-rate-${rateIndex}-min-threshold`}
                                        autoComplete="off"
                                        compressed={true}
                                        name={`schedules.${scheduleIndex}.rates.${rateIndex}.minThreshold`}
                                        value={rate.minThreshold}
                                        onChange={onlyNumberChange(handleChange)}
                                        disabled={rateIndex === 0}
                                        append={
                                          values.chargeBasis
                                            ? values.chargeBasis.split('/')[1]
                                            : undefined
                                        }
                                      />
                                    </EuiFormRow>
                                  </EuiFlexItem>

                                  {/* Add or remove rates */}
                                  <EuiFlexItem grow={1}>
                                    <EuiFlexGroup
                                      gutterSize="s"
                                      alignItems="flexEnd"
                                      direction={
                                        schedule?.rates?.length === 1 ? 'row' : 'rowReverse'
                                      }
                                    >
                                      <EuiFlexItem grow={1}>
                                        {rateIndex === schedule?.rates?.length - 1 && (
                                          <EuiButtonIcon
                                            id={`schedule-${scheduleIndex}-add-usage-block-${
                                              schedule?.rates?.length + 1
                                            }`}
                                            data-testid={`schedule-${scheduleIndex}-add-usage-block-${
                                              schedule?.rates?.length + 1
                                            }`}
                                            iconType="plusInCircle"
                                            onClick={() =>
                                              ratesArrayHelper.push({
                                                applicableTimePeriods: [],
                                                minThreshold: 0,
                                                rate: 0,
                                                rateQualifiers: [],
                                                spotPercentage: null,
                                                volumeAdjustmentFactor: null,
                                              })
                                            }
                                            aria-label={`Add usage block`}
                                          />
                                        )}
                                      </EuiFlexItem>

                                      <EuiFlexItem grow={1}>
                                        {rateIndex !== 0 && (
                                          <EuiButtonIcon
                                            id={`schedule-${scheduleIndex}-remove-usage-block-${
                                              rateIndex + 1
                                            }`}
                                            data-testid={`schedule-${scheduleIndex}-remove-usage-block-${
                                              rateIndex + 1
                                            }`}
                                            iconType="minusInCircle"
                                            onClick={() => ratesArrayHelper.remove(rateIndex)}
                                            aria-label={`Remove usage block ${rateIndex + 1}`}
                                          />
                                        )}
                                      </EuiFlexItem>
                                    </EuiFlexGroup>
                                  </EuiFlexItem>
                                </EuiFlexGroup>
                              );
                            })
                          }
                        />
                      </EuiFlexGrid>
                    </EuiFlexGrid>
                  </EuiPanel>
                </EuiFlexItem>
              );
            })}
          </div>
        </React.Fragment>
      )}
    />
  );
}

export default SteppedTariffForm;
