import React, { useMemo, useState } from 'react';
import { EuiBreadcrumb, EuiBreadcrumbs, EuiPanel, EuiPageHeader, EuiSpacer } from '@elastic/eui';
import { useNavigate, useParams } from 'react-router-dom';
import {
  InvoiceContext,
  InvoicesResult,
  useGetInvoices,
} from 'components/billing/billingInvoicesApi';
import { EuiBasicTable, EuiBasicTableColumn, EuiSelect } from '@elastic/eui';
import {
  FieldOption,
  generateDateOptions,
  marketEndOfMonth,
  marketStartOfMonth,
  newMarketDate,
} from 'util/helper-func';
import { formatISO, format } from 'date-fns';

interface DisplayInvoice {
  invoicedParty: string;
  invoiceNumber: string;
  context: InvoiceContext;
  value: number;
}

export default () => {
  const navigate = useNavigate();
  const { providerId } = useParams<{ providerId: string }>();
  const options = useMemo(() => generateDateOptions(true, 24), []);
  const initialDate = (options.slice(1).shift() as FieldOption).value;
  const [date, setDate] = useState<Date>(new Date(initialDate));
  const optionsSelect = options.map((option) => ({
    value: option.value,
    text: option.label,
  }));

  const requestedAtFrom = formatISO(marketStartOfMonth(date));
  const requestedAtTo = formatISO(marketEndOfMonth(date));
  const { data }: { data: InvoicesResult | undefined; error: unknown } = useGetInvoices({
    startsAt: requestedAtFrom,
    endsAt: requestedAtTo,
    status: 'urn:flux:billing:invoice:status:available',
    provider: providerId,
    limit: 100,
  });
  const displayData: DisplayInvoice[] | undefined =
    data &&
    data.items.map((invoice) => {
      return {
        invoicedParty: invoice.data.invoicedParty,
        invoiceNumber: invoice.data.invoiceNumber,
        context: invoice.context,
        value: invoice.data.lineItems
          .map((li) => li.amount.cost ?? 0)
          .reduce((partialSum, a) => partialSum + a, 0),
      };
    });
  const total: number | undefined = displayData?.reduce((partialSum, a) => partialSum + a.value, 0);
  const breadcrumbs: EuiBreadcrumb[] = [
    {
      text: 'Provider',
      href: '#',
      onClick: () => {
        navigate(`/providers/${providerId}/plans`, { replace: true });
      },
    },
    {
      text: 'Broker Information',
    },
  ];
  const columns: Array<EuiBasicTableColumn<DisplayInvoice>> = [
    {
      field: 'invoicedParty',
      name: 'Invoiced Party',
    },
    {
      field: 'invoiceNumber',
      name: 'Invoice',
    },
    {
      field: 'value',
      name: 'Commission',
      render: (value: number) => `$${value / 100}`,
    },

    {
      field: 'context',
      name: 'Period',
      render: (value: any) =>
        format(newMarketDate(value.startsAt), 'yyyy-MM-dd') +
        ' to ' +
        format(newMarketDate(value.endsAt), 'yyyy-MM-dd'),
    },
  ];

  return (
    <>
      <EuiPanel>
        <EuiBreadcrumbs breadcrumbs={breadcrumbs} truncate={false} aria-label="" />
        <EuiSpacer size="xs" />
        <EuiPageHeader role="" pageTitle="Broker Information" />
        <EuiSelect
          options={optionsSelect}
          value={formatISO(date, { representation: 'date' })}
          onChange={(event) => setDate(newMarketDate(event?.target.value))}
        />
        {'Total: $' + (total ?? 0) / 100}
      </EuiPanel>
      <EuiBasicTable items={displayData ?? []} columns={columns} />
    </>
  );
};
