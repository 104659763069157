import React, { createRef } from 'react';
import { isEmpty } from 'ramda';
import { useNavigate } from 'react-router-dom';
import { useMissingPlansSummary } from '../../reports/reportsApi';
import { LoadingPage } from '../../layout/Page';
import { SupplyPeriodAttributeProps, Widget } from '../Dashboard';
import ConnectionsMissingPlanGraph from './ConnectionsMissingPlanGraph';
import { DateData, generateDates } from './missingPlansUtil';

const ref = createRef<HTMLDivElement>();
type ConnectionsWithoutEnergyPlansWidgetProps = SupplyPeriodAttributeProps;

const ConnectionsWithoutEnergyPlansWidget = (props: ConnectionsWithoutEnergyPlansWidgetProps) => {
  const navigate = useNavigate();
  const { supplyPeriodAttribute, supplyPeriodAttributeURLSearchParams } = props;

  const dates = generateDates();

  const results = dates.map((date: DateData) =>
    useMissingPlansSummary(date.firstDOM, date.lastDOM, 'ENERGY', supplyPeriodAttribute)
  );

  // if any of the results are loading, we consider this still loading
  const isLoading = results.find((period) => period.isInitialLoading);
  if (isLoading) {
    return (
      <Widget title="Connections without energy plans">
        <LoadingPage />
      </Widget>
    );
  }

  // generate the graph data from results
  const graphData = results.map((result, index) => ({
    x: dates[index].month.slice(0, 3),
    y: result.data?.missingPlans[0]?.results.totalConnections ?? 0,
  }));

  return (
    <Widget title="Connections without energy plans" ref={ref}>
      <div
        data-testid="connections-without-energy-plans-graph"
        style={{ height: 220, marginTop: 25 }}
      >
        <ConnectionsMissingPlanGraph
          data={graphData}
          handleOnClick={(data) => {
            const month = data.indexValue.toString();
            navigate(
              `/dashboard/connections-without-energy-plans/${month.toLowerCase()}${
                isEmpty(supplyPeriodAttributeURLSearchParams)
                  ? ''
                  : '?' + supplyPeriodAttributeURLSearchParams
              }`
            );
          }}
        />
      </div>
    </Widget>
  );
};

export default ConnectionsWithoutEnergyPlansWidget;
