import React from 'react';
import Page from 'components/layout/Page';
import { useNavigate } from 'react-router-dom';
import './NotFound.scss';

function NotFound() {
  const navigate = useNavigate();

  const redirectToDashboard = () => {
    navigate('/');
  };

  return (
    <Page className="not-found-page">
      <h1 className="not-found-heading">Page not found</h1>
      <div className="apl-display-flex apl-flex-row apl-justify-content-center">
        <button onClick={redirectToDashboard} className="apl-button-v1 is-primary">
          Go to Dashboard
        </button>
      </div>
    </Page>
  );
}

export default NotFound;
