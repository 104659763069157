import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import apiClient from '../../api/apiClient';
import { isNil } from 'ramda';

export interface MetricResponse {
  _type: string;
  _itemType?: unknown;
  _links?: MetricResponseLinks;
  totalCount: number;
  items: Metric[];
}

export interface Metric {
  id?: string;
  type: string;
  tags: string[];
  source: string;
  externalIdentifier: string;
  startsAt: string;
  endsAt: string;
  value: MetricUnitValue;
  historicalMeasure?: string;
  unit?: string;
  lineRecords: any;
}

export interface MetricUnitValue {
  unit: string;
  value: number;
  groups?: MetricGroups[];
}

interface MetricGroups {
  qualifier: string;
  unit: string;
  value: number;
}

interface MetricResponseLinks {
  self: string;
  next: string;
  prev: string;
}

export interface MetricRequest {
  id?: string;
  startsAt?: string;
  endsAt?: string;
  anyType?: string[];
  tags?: string[];
  source?: string;
  externalIdentifier?: string;
  top?: number;
}

interface DeleteMetricArgs {
  metricId: string | undefined;
}

export enum MetricConfigEnum {
  baseEndpoint = '/metrics',
  appType = 'metrics',
  metricType = 'urn:flux:metric:historical-peak-demand',
  metricSource = 'urn:flux:service:aib-ui',
  metricExternalIdentifier = 'urn:flux:account:contracts:supply-agreement',
  metricConnectionUrn = 'urn:flux:network:connection',
  metricMonthsRange = 12,
  metricDemandTypeDefault = 'DEMAND_ANYTIME_ACTUAL',
  metricUOMList = 'kVA,kVAh,kW,kWh',
}

export const useGetMetricData = (params: MetricRequest, options?: Record<string, unknown>) => {
  const { startsAt, endsAt, anyType, tags, top } = params;
  params = sanitiseInputs(params);

  return useQuery<MetricResponse>(
    [MetricConfigEnum.appType, startsAt, endsAt, anyType, tags, top],
    async () => {
      const search = new URLSearchParams();

      (Object.keys(params) as Array<keyof MetricRequest>).forEach((key) => {
        if (params[key] !== undefined) {
          search.set(key, `${params[key]}`);
        }
      });

      const url = `${MetricConfigEnum.baseEndpoint}?${search.toString()}`;
      return apiClient(MetricConfigEnum.appType).get(url);
    },
    { ...options }
  );
};

export const useAddMetricData = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (item: Metric) => {
      const url = `${MetricConfigEnum.baseEndpoint}`;
      return apiClient(MetricConfigEnum.appType).post(url, JSON.stringify(item));
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([MetricConfigEnum.appType]);
      },
      onError: (error: Error) => {
        throw error;
      },
    }
  );
};

export const useEditMetric = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (item: Metric) => {
      const url = `${MetricConfigEnum.baseEndpoint}/${item.id}`;
      return apiClient(MetricConfigEnum.appType).put(url, JSON.stringify(item));
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([MetricConfigEnum.appType]);
      },
      onError: (error: Error) => {
        throw error;
      },
    }
  );
};

export const useDeleteMetric = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ metricId }: DeleteMetricArgs) => {
      const url = `${MetricConfigEnum.baseEndpoint}/${metricId}`;
      return apiClient(MetricConfigEnum.appType).del(url, '');
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([MetricConfigEnum.appType]);
      },
      onError: (error: Error) => {
        throw error;
      },
    }
  );
};

function sanitiseInputs(params: MetricRequest) {
  const newParams = params;

  if (!params.startsAt || isNil(params.startsAt)) {
    delete newParams.startsAt;
  }
  if (!params.endsAt || isNil(params.endsAt)) {
    delete newParams.endsAt;
  }
  if (!params.anyType || isNil(params.anyType) || params.anyType?.length < 1) {
    delete newParams.anyType;
  }
  if (!params.tags || isNil(params.tags) || params.tags?.length < 1) {
    delete newParams.tags;
  }

  delete newParams.id;
  delete newParams.source;
  delete newParams.top;
  delete newParams.externalIdentifier;

  return newParams;
}
