import jwtDecode from 'jwt-decode';
import { LDUser } from 'launchdarkly-js-sdk-common';
import { LDClient } from 'launchdarkly-js-client-sdk';

let client: LDClient | undefined;
export const setLDClient = (ldClient: LDClient | undefined) => {
  client = ldClient;
};

export const getLDClient = (): LDClient | undefined => client;

export const getFlagValue = (key: string): boolean => client?.variation(key);

// See git history to enable user segmenting
export const getLDUserFromJwt = (auth: string): LDUser => {
  let user: LDUser = {
    key: undefined,
    anonymous: true,
  };
  try {
    if (auth) {
      const jwt: any = jwtDecode(auth) || {};

      user = {
        key: jwt?.user?.id || undefined,
        anonymous: jwt?.user?.id ? false : true,
      };
    }
  } catch (e: any) {
    console.warn('jwt is not valid', auth);
    console.error(e, { auth });
  }
  return user;
};
