import React, { FunctionComponent } from 'react';
import './Alert.scss';

export enum AlertEnum {
  DANGER,
  SUCCESS,
  WARNING,
}

interface AlertProps {
  type: AlertEnum;
  children?: React.ReactNode;
}

const Alert: FunctionComponent<AlertProps> = ({ children, type }) => {
  switch (type) {
    case AlertEnum.DANGER:
      return <div className="alert is-danger">{children}</div>;
    case AlertEnum.SUCCESS:
      return <div className="alert is-success">{children}</div>;
    case AlertEnum.WARNING:
      return <div className="alert is-warning">{children}</div>;
    default:
      return <div className="alert">{children}</div>;
  }
};

export default Alert;
