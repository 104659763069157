import React, { FunctionComponent } from 'react';
import { ErrorModel } from '../../api/ErrorDisplay';
import HolidayForm from './HolidayForm';
import { Observance, useAddObservance } from './observancesApi';

interface AddHolidayProps {
  close: () => void;
}

const AddHoliday: FunctionComponent<AddHolidayProps> = ({ close }) => {
  const { mutateAsync: addObservance, error: addObservanceError } = useAddObservance();
  const submit = async (data: Observance): Promise<any> => {
    try {
      await addObservance({ data });
      close();
    } catch (e: any) {
      console.error(e);
    }
  };

  return (
    <div>
      <h2>Add holiday</h2>
      <HolidayForm submitFunction={submit} error={addObservanceError as ErrorModel} close={close} />
    </div>
  );
};

export default AddHoliday;
