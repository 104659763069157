export enum ChargeTypeAttribute {
  AggregationMethod = 'AGGREGATION_METHOD',
  ApplicableTimePeriod = 'APPLICABLE_TIME_PERIOD',
  BasisAggregationMethod = 'BASIS_AGGREGATION_METHOD',
  EnergyGoingToConnection = 'ENERGY_GOING_TO_CONNECTION',
  HistoricalMeasure = 'HISTORICAL_MEASURE',
  IntervalSize = 'INTERVAL_SIZE',
  LookbackMonths = 'LOOKBACK_MONTHS',
  LossAdjusted = 'LOSS_ADJUSTED',
  MinimumChargeable = 'MIN_CHARGEABLE_DEMAND',
  MinimumThresholdMethod = 'MIN_THRESHOLD_METHOD',
  Optional = 'OPTIONAL',
  PowerFactorThreshold = 'POWER_FACTOR_THRESHOLD',
  ReadingQuality = 'READING_QUALITY',
  SpotPercentage = 'SPOT_PERCENTAGE',
  SteppedTariff = 'STEPPED_TARIFF',
  SummarisationFunction = 'SUMMARISATION_FUNCTION',
  TopNPeriods = 'TOP_N_PERIODS',
  UsageChargeFilter = 'USAGE_CHARGE_FILTER',
  IsTaxed = 'IS_TAXED',
  VolumeAdjustmentFactor = 'VOLUME_ADJUSTMENT_FACTOR',
}

export type ChargeTypeConfig = {
  type: string;
  typeDisplayText: string;
  sortOrder: number;
  structure: string;
  basis: string[];
  attributes: ChargeTypeAttribute[];
  basisAggregationMethod?: string[];
};

export type ChargeCategoryConfig = {
  categoryDisplayText: string;
  sortOrder: number;
  types: ChargeTypeConfig[];
};

export type ChargeTypesConfig = ChargeCategoryConfig[];

/**
 * Find a charge by its type.
 * @param chargeTypeValue the charge type.
 * @param chargeTypesConfig the charge types configuration.
 * @returns a charge by its type or undefined if none are found.
 */
const findChargeTypeByValue = (
  chargeTypeValue: string | undefined,
  chargeTypesConfig: ChargeTypesConfig
): ChargeTypeConfig | undefined => {
  return chargeTypesConfig
    .flatMap((chargeTypeConfig) => chargeTypeConfig.types)
    .find((chargeTypeConfig: ChargeTypeConfig) => chargeTypeConfig.type === chargeTypeValue);
};

/**
 * Checks if a charge is optional.
 * @param chargeType the charge type.
 * @param chargeTypesConfig the charge types configuration.
 * @returns if a charge is optional.
 */
function chargeIsOptional(chargeType: string | undefined, chargeTypesConfig: ChargeTypesConfig) {
  const chargeTypeByValue = findChargeTypeByValue(chargeType, chargeTypesConfig);

  return chargeTypeByValue?.attributes.includes(ChargeTypeAttribute.Optional);
}

/**
 * Checks if a charge requires an aggregation method.
 * @param chargeType the charge type.
 * @param chargeTypesConfig the charge types configuration.
 * @returns if a charge requires an aggregation method.
 */
function chargeRequiresAggregationMethod(
  chargeType: string | undefined,
  chargeTypesConfig: ChargeTypesConfig
) {
  const chargeTypeByValue = findChargeTypeByValue(chargeType, chargeTypesConfig);

  return chargeTypeByValue?.attributes.includes(ChargeTypeAttribute.AggregationMethod);
}

/**
 * Checks if a charge requires an aggregation method and if the basis is included in the given list.
 * @param chargeType the charge type.
 * @param chargeBasis the charge basis.
 * @param chargeTypesConfig the charge types configuration.
 * @returns if a charge requires an aggregation method and if the basis is included in the given list.
 */
function chargeRequiresAggregationMethodAndBasis(
  chargeType: string | undefined,
  chargeBasis: string | undefined,
  chargeTypesConfig: ChargeTypesConfig
) {
  const chargeTypeByValue = findChargeTypeByValue(chargeType, chargeTypesConfig);

  return (
    chargeBasis &&
    chargeTypeByValue?.attributes.includes(ChargeTypeAttribute.AggregationMethod) &&
    chargeTypeByValue.basisAggregationMethod &&
    chargeTypeByValue?.basisAggregationMethod.includes(chargeBasis)
  );
}

/**
 * Checks if a charge requires an applicable time period.
 * @param chargeType the charge type.
 * @param chargeTypesConfig the charge types configuration.
 * @returns if a charge requires an applicable time period.
 */
function chargeRequiresApplicableTimePeriod(
  chargeType: string | undefined,
  chargeTypesConfig: ChargeTypesConfig
) {
  const chargeTypeByValue = findChargeTypeByValue(chargeType, chargeTypesConfig);

  return chargeTypeByValue?.attributes.includes(ChargeTypeAttribute.ApplicableTimePeriod);
}

/**
 * Checks if a charge requires an adjustment factor.
 * @param chargeType the charge type.
 * @param chargeTypesConfig the charge types config
 * @returns if a charge requires an adjustment factor.
 */
function chargeRequiresVolumeAdjustmentFactor(
  chargeType: string | undefined,
  chargeTypesConfig: ChargeTypesConfig
) {
  const chargeTypeByValue = findChargeTypeByValue(chargeType, chargeTypesConfig);
  return chargeTypeByValue?.attributes.includes(ChargeTypeAttribute.VolumeAdjustmentFactor);
}

/**
 * Checks if a charge requires a flow direction.
 * @param chargeType the charge type.
 * @param chargeTypesConfig the charge types configuration.
 * @returns if a charge requires a flow direction.
 */
function chargeRequiresFlowDirection(
  chargeType: string | undefined,
  chargeTypesConfig: ChargeTypesConfig
) {
  const chargeTypeByValue = findChargeTypeByValue(chargeType, chargeTypesConfig);

  return chargeTypeByValue?.attributes.includes(ChargeTypeAttribute.EnergyGoingToConnection);
}

/**
 * Checks if a charge requires an interval size.
 * @param chargeType the charge type.
 * @param chargeTypesConfig the charge types configuration.
 * @returns if a charge requires an interval size.
 */
function chargeRequiresIntervalSize(
  chargeType: string | undefined,
  chargeTypesConfig: ChargeTypesConfig
) {
  const chargeTypeByValue = findChargeTypeByValue(chargeType, chargeTypesConfig);

  return chargeTypeByValue?.attributes.includes(ChargeTypeAttribute.IntervalSize);
}

/**
 * Checks if a charge requires lookback months.
 * @param chargeType the charge type.
 * @param chargeTypesConfig the charge types configuration.
 * @returns if a charge requires lookback months.
 */
function chargeRequiresLookbackMonths(
  chargeType: string | undefined,
  chargeTypesConfig: ChargeTypesConfig
) {
  const chargeTypeByValue = findChargeTypeByValue(chargeType, chargeTypesConfig);

  return chargeTypeByValue?.attributes.includes(ChargeTypeAttribute.LookbackMonths);
}

/**
 * Checks if a charge requires loss adjustment.
 * @param chargeType the charge type.
 * @param chargeTypesConfig the charge types configuration.
 * @returns if a charge requires loss adjustment.
 */
function chargeRequiresLossAdjustment(
  chargeType: string | undefined,
  chargeTypesConfig: ChargeTypesConfig
) {
  const chargeTypeByValue = findChargeTypeByValue(chargeType, chargeTypesConfig);

  return chargeTypeByValue?.attributes.includes(ChargeTypeAttribute.LossAdjusted);
}

/**
 * Checks if a charge requires a minimum chargeable demand.
 * @param chargeType the charge type.
 * @param chargeTypesConfig the charge types configuration.
 * @returns if a charge requires a minimum chargeable demand.
 */
function chargeRequiresMinimumChargeable(
  chargeType: string | undefined,
  chargeTypesConfig: ChargeTypesConfig
) {
  const chargeTypeByValue = findChargeTypeByValue(chargeType, chargeTypesConfig);

  return chargeTypeByValue?.attributes.includes(ChargeTypeAttribute.MinimumChargeable);
}

/**
 * Checks if a charge requires a power factor threshold.
 * @param chargeType the charge type.
 * @param chargeTypesConfig the charge types configuration.
 * @returns if a charge requires a power factor threshold.
 */
function chargeRequiresPowerFactorThreshold(
  chargeType: string | undefined,
  chargeTypesConfig: ChargeTypesConfig
) {
  const chargeTypeByValue = findChargeTypeByValue(chargeType, chargeTypesConfig);

  return chargeTypeByValue?.attributes.includes(ChargeTypeAttribute.PowerFactorThreshold);
}

/**
 * Checks if a charge requires a stepped tariff.
 * @param chargeType the charge type.
 * @param chargeTypesConfig the charge types configuration.
 * @returns if a charge requires a stepped tariff.
 */
function chargeRequiresSteppedTariff(
  chargeType: string | undefined,
  chargeTypesConfig: ChargeTypesConfig
) {
  const chargeTypeByValue = findChargeTypeByValue(chargeType, chargeTypesConfig);

  return chargeTypeByValue?.attributes.includes(ChargeTypeAttribute.SteppedTariff);
}

/**
 * Checks if a charge requires a summarisation function.
 * @param chargeType the charge type.
 * @param chargeTypesConfig the charge types configuration.
 * @returns if a charge requires a summarisation function.
 */
function chargeRequiresSummarisationFunction(
  chargeType: string | undefined,
  chargeTypesConfig: ChargeTypesConfig
) {
  const chargeTypeByValue = findChargeTypeByValue(chargeType, chargeTypesConfig);

  return chargeTypeByValue?.attributes.includes(ChargeTypeAttribute.SummarisationFunction);
}

/**
 * Checks if a charge requires top N periods.
 * @param chargeType the charge type.
 * @param chargeTypesConfig the charge types configuration.
 * @returns if a charge requires top N periods.
 */
function chargeRequiresTopNPeriods(
  chargeType: string | undefined,
  chargeTypesConfig: ChargeTypesConfig
) {
  const chargeTypeByValue = findChargeTypeByValue(chargeType, chargeTypesConfig);

  return chargeTypeByValue?.attributes.includes(ChargeTypeAttribute.TopNPeriods);
}

/**
 * Checks if a charge requires a historical measure.
 * @param chargeType the charge type.
 * @param chargeTypesConfig the charge types configuration.
 * @returns if a charge requires a historical measure.
 */
function chargeRequiresHistoricalMeasure(
  chargeType: string | undefined,
  chargeTypesConfig: ChargeTypesConfig
) {
  const chargeTypeByValue = findChargeTypeByValue(chargeType, chargeTypesConfig);

  return chargeTypeByValue?.attributes.includes(ChargeTypeAttribute.HistoricalMeasure);
}

/**
 * Checks if a spot percentage per rate is applicable for this charge.
 * @param chargeType the charge type.
 * @param chargeTag the charge tag.
 * @param chargeTypesConfig the charge type config
 * @returns if a spot percentage per rate is applicable for this charge.
 */
function chargeRequiresSpotPercentage(
  chargeType: string | undefined,
  chargeTag: string | undefined,
  chargeTypesConfig: ChargeTypesConfig
) {
  const chargeTypeByValue = findChargeTypeByValue(chargeType, chargeTypesConfig);
  //TODO - Possibly create a 'ChargeTag' GenericList Category for future development
  return (
    chargeTypeByValue?.attributes.includes(ChargeTypeAttribute.SpotPercentage) &&
    'ENERGY' === chargeTag
  );
}

/**
 * Checks if a charge requires a Usage Charge Filter.
 * @param chargeType the charge type.
 * @param chargeTypesConfig the charge types configuration.
 * @returns if a charge requires a Usage Charge Filter.
 */
function chargeRequiresUsageChargeFilter(
  chargeType: string | undefined,
  chargeTypesConfig: ChargeTypesConfig
) {
  const chargeTypeByValue = findChargeTypeByValue(chargeType, chargeTypesConfig);

  return chargeTypeByValue?.attributes.includes(ChargeTypeAttribute.UsageChargeFilter);
}

/**
 * Checks if a charge requires a Usage Charge Filter.
 * @param chargeType the charge type.
 * @param chargeTypesConfig the charge types configuration.
 * @returns if a charge requires a Usage Charge Filter.
 */
function chargeRequiresMinimumThresholdMethod(
  chargeType: string | undefined,
  chargeTypesConfig: ChargeTypesConfig
) {
  const chargeTypeByValue = findChargeTypeByValue(chargeType, chargeTypesConfig);

  return chargeTypeByValue?.attributes.includes(ChargeTypeAttribute.MinimumThresholdMethod);
}

/**
 * Checks if a charge requires a Reading Quality.
 * @param chargeType the charge type.
 * @param chargeTypesConfig the charge types configuration.
 * @returns if a charge requires a Reading Quality.
 */
function chargeRequiresReadingQuality(
  chargeType: string | undefined,
  chargeTypesConfig: ChargeTypesConfig
) {
  const chargeTypeByValue = findChargeTypeByValue(chargeType, chargeTypesConfig);

  return chargeTypeByValue?.attributes.includes(ChargeTypeAttribute.ReadingQuality);
}
function chargeRequiresIsTaxed(
  chargeType: string | undefined,
  chargeTypesConfig: ChargeTypesConfig
) {
  const chargeTypeByValue = findChargeTypeByValue(chargeType, chargeTypesConfig);

  return chargeTypeByValue?.attributes.includes(ChargeTypeAttribute.IsTaxed);
}

export {
  findChargeTypeByValue,
  chargeIsOptional,
  chargeRequiresAggregationMethod,
  chargeRequiresAggregationMethodAndBasis,
  chargeRequiresApplicableTimePeriod,
  chargeRequiresFlowDirection,
  chargeRequiresHistoricalMeasure,
  chargeRequiresIntervalSize,
  chargeRequiresLookbackMonths,
  chargeRequiresLossAdjustment,
  chargeRequiresMinimumChargeable,
  chargeRequiresMinimumThresholdMethod,
  chargeRequiresPowerFactorThreshold,
  chargeRequiresReadingQuality,
  chargeRequiresSpotPercentage,
  chargeRequiresSteppedTariff,
  chargeRequiresSummarisationFunction,
  chargeRequiresTopNPeriods,
  chargeRequiresUsageChargeFilter,
  chargeRequiresVolumeAdjustmentFactor,
  chargeRequiresIsTaxed,
};
