import React, { FunctionComponent } from 'react';
import { ErrorModel } from '../../api/ErrorDisplay';

import QualifierForm from './QualifierForm';
import { useAddQualifier } from './qualifiersApi';
import { MultiRangeQualifierBody } from '../../api/openapi/rating-config';

interface AddQualifierProps {
  close: () => void;
}

const AddQualifier: FunctionComponent<AddQualifierProps> = ({ close }) => {
  const {
    mutateAsync: addQualifier,
    isLoading: isAddQualifierLoading,
    error: addQualifierError,
  } = useAddQualifier();
  const submit = async (data: MultiRangeQualifierBody): Promise<any> => {
    try {
      await addQualifier({ data });
      close();
    } catch (error: any) {
      console.error(error);
    }
  };

  return (
    <div>
      <h2>Add qualifier</h2>
      <QualifierForm
        submitFunction={submit}
        isUpdating={isAddQualifierLoading}
        error={addQualifierError as ErrorModel}
        close={close}
      />
    </div>
  );
};

export default AddQualifier;
