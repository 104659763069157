/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type SupplyAgreementBillingProvider = {
    validFrom?: string;
    /**
     * Must be after validFrom date
     */
    validTo?: string;
    provider?: SupplyAgreementBillingProvider.provider;
};

export namespace SupplyAgreementBillingProvider {

    export enum provider {
        URN_FLUX_BILLING_PROVIDER_AIB = 'urn:flux:billing:provider:aib',
        URN_FLUX_BILLING_PROVIDER_CORE = 'urn:flux:billing:provider:core',
        URN_FLUX_BILLING_PROVIDER_EXTERNAL = 'urn:flux:billing:provider:external',
    }


}

