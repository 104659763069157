import { useTranslation } from 'react-i18next';
import { UnitValue } from 'components/billing/billingInvoicesApi';
import { useFlags } from 'launchdarkly-react-client-sdk';
/**
 * Helper method to prepare a unit value for translation.
 * It will convert a minor currency value into a major value,
 * i.e. cents/pence to dollars/pounds.
 *
 * @param {UnitValue} unitValue the unit value to be translated
 * @returns {number} the unit value converted to major currency value
 */
const prepareUnitValueForTranslation = (unitValue: UnitValue): number => {
  if (!unitValue || !unitValue.value || isNaN(unitValue.value)) {
    return 0;
  }

  switch (unitValue.unit) {
    case 'pounds':
    case 'dollars':
    case 'euros': {
      return unitValue.value;
    }
    case 'pence':
    case 'cents':
    case 'fils': {
      return unitValue.value / 100;
    }
    default: {
      return unitValue.value;
    }
  }
};

type UseCurrencyTranslation = {
  translateCurrency: (unitValue: UnitValue) => string;
};

/**
 * Hook that builds on the i18n translation hook useTranslation
 * to convert a value to a specific currency.
 *
 * @returns {string} the value translated to a specific currency
 *
 * @example
 * // returns "$420.00"
 * translateCurrency( amount: { unit: 'cents', value: 42000.00 });
 *
 * @example
 * // returns "£420.00"
 * translateCurrency( amount: { unit: 'pounds', value: 420.00 });
 *
 * @example
 * // returns "420.00"
 * translateCurrency( amount: { unit: 'tonnes', value: 420.00 });
 */
export default function useCurrencyTranslation(): UseCurrencyTranslation {
  const { fbau1272ChangeUnitForCarbonChargesOnInvoiceAndChargesetDetailsScreens } = useFlags();
  const { t: translate } = useTranslation();

  function translateCurrency(unitValue: UnitValue) {
    if (!fbau1272ChangeUnitForCarbonChargesOnInvoiceAndChargesetDetailsScreens) {
      return translate('monetary.currency', { amount: prepareUnitValueForTranslation(unitValue) });
    }
    switch (unitValue?.unit) {
      case 'tonnes':
        return !unitValue.value ? '' : Number(unitValue.value).toFixed(2) + ' tonnes';
      default: {
        return translate('monetary.currency', {
          amount: prepareUnitValueForTranslation(unitValue),
        });
      }
    }
  }

  return {
    translateCurrency,
  };
}
