import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import apiClient from '../../api/apiClient';

export interface SummarisationGroupList {
  summarisationGroups: SummarisationGroup[];
}

export interface SummarisationGroupBody {
  id?: string;
  name: string;
}

export interface SummarisationGroupType extends SummarisationGroupBody {
  id: string;
}

export interface SummarisationGroup {
  id: string;
  name: string;
}

export interface AddSummarisationGroupArgs {
  name: string;
}

type ErrorResponse = {
  errors: [];
  message: string;
  status: number;
  type: string;
};

const summarisationGroupBaseEndpoint = '/summarisation-groups';

export const useSummarisationGroup = (summarisationGroupId: string) => {
  return useQuery(['summarisationGroups', summarisationGroupId], async () => {
    const url = `${summarisationGroupBaseEndpoint}/${summarisationGroupId}`;
    return apiClient().get(url);
  });
};

export const useSummarisationGroupList = () => {
  return useQuery(
    ['summarisationGroups'],
    async () => {
      const url = `${summarisationGroupBaseEndpoint}`;
      return apiClient().get(url);
    },
    {
      staleTime: 30000,
    }
  );
};

export const useSummarisationGroupListCreation = () => {
  const queryClient = useQueryClient();

  return useMutation<any, ErrorResponse, AddSummarisationGroupArgs, unknown>(
    ({ name }: AddSummarisationGroupArgs) => {
      const url = `${summarisationGroupBaseEndpoint}`;

      return apiClient().post(url, JSON.stringify({ id: null, name }));
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['summarisationGroups']);
      },
      onError: (error) => {
        console.log(error);
      },
    }
  );
};
