import { isBefore, isDate } from 'date-fns';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { isNil } from 'ramda';
import React, { FunctionComponent } from 'react';
import { date, object, string } from 'yup';
import { SupplyPeriodSummary } from '../connectionsApi';
import AuthorizedAction from '../../common/AuthorizedAction';
import { FormRow } from '../../form';
import FluxDatePicker from '../../form/DatePicker';
import FieldWrapper, { FieldWidth, inputClassNameBuilder } from '../../form/FieldWrapper';
import Alert, { AlertEnum } from '../../layout/Alert';
import { ErrorText } from '../../layout/Errors';
import ErrorDisplay, { ErrorModel } from '../../../api/ErrorDisplay';
import { Permission } from '../../../auth/getPermissions';

interface SupplyPeriodFormProps {
  title: string;
  isUpdating: boolean;
  close: () => void;
  submitFunction: (
    contractedParty: string,
    startDate: string,
    endDate: string,
    supplyPeriodId?: string
  ) => Promise<any>;
  existingSupplyPeriod?: SupplyPeriodSummary;
  error?: ErrorModel;
}

const supplyPeriodSchema = object().shape({
  contractedParty: string().label('Contracted party').trim().required().max(200),
  startDate: date()
    .typeError('Must be a valid date')
    .max(new Date('9999-12-31'))
    .label('Start date')
    .required(),
  endDate: date()
    .typeError('Must be a valid date')
    .max(new Date('9999-12-31'))
    .label('End date')
    .nullable()
    .transform((curVal, origVal) => (origVal === 'null' ? null : curVal))
    .when('startDate', (startDate: Date, schema: any) => {
      if (isDate(startDate) && isBefore(startDate, new Date('9999-12-31'))) {
        return schema.min(startDate, 'End date must be after start date');
      } else {
        return schema;
      }
    }),
});

export const SupplyPeriodForm: FunctionComponent<SupplyPeriodFormProps> = ({
  title,
  isUpdating,
  close,
  submitFunction,
  existingSupplyPeriod,
  error,
}) => {
  const isEdit = !isNil(existingSupplyPeriod);

  return (
    <Formik
      initialValues={{
        contractedParty: isEdit ? `${existingSupplyPeriod?.contractedParty}` : '',
        startDate: isEdit ? `${existingSupplyPeriod?.startDate}` : '',
        endDate: isEdit ? `${existingSupplyPeriod?.endDate}` : '',
      }}
      validationSchema={supplyPeriodSchema}
      onSubmit={({ contractedParty, startDate, endDate }) => {
        // need to convert null dates to empty strings to comply with the API
        // and this is required due to a limitation of the yup library not
        // allowing an empty string if it is meant to be a date()
        const getStartDate = startDate !== 'null' ? startDate : '';
        const getEndDate = endDate !== 'null' ? endDate : '';

        submitFunction(contractedParty, getStartDate, getEndDate);
      }}
    >
      {({ errors, touched, dirty, isValid }) => (
        <Form className="apl-form-layout-v1">
          <h2>{title}</h2>
          {error && (
            <Alert type={AlertEnum.DANGER}>
              {isEdit ? 'Could not update supply period' : 'Could not create supply period'}
            </Alert>
          )}
          <FieldWrapper
            fieldWidth={FieldWidth.FULL}
            htmlFor="contracted-party-field"
            label="Contracted party"
          >
            <Field
              autoComplete="off"
              disabled={existingSupplyPeriod}
              data-testid="contracted-party-field"
              name="contractedParty"
              id="contracted-party-field"
              className={inputClassNameBuilder('contractedParty', errors, touched)}
            />
            <ErrorMessage component={ErrorText} name="contractedParty" />
          </FieldWrapper>

          <FormRow>
            <FieldWrapper fieldWidth={FieldWidth.HALF} htmlFor="start-date" label="Start date">
              <Field
                autoComplete="off"
                data-testid="start-date"
                name="startDate"
                id="start-date"
                component={FluxDatePicker}
              />
              <ErrorMessage component={ErrorText} name="startDate" />
            </FieldWrapper>
            <FieldWrapper
              fieldWidth={FieldWidth.HALF}
              htmlFor="end-date"
              label="End date"
              optional={true}
            >
              <Field
                autoComplete="off"
                data-testid="end-date"
                name="endDate"
                id="end-date"
                component={FluxDatePicker}
              />
              <ErrorMessage component={ErrorText} name="endDate" />
            </FieldWrapper>
          </FormRow>
          <div className="apl-display-flex apl-justify-content-end">
            <button
              data-testid="cancel-button"
              className="apl-button-v1"
              onClick={close}
              disabled={isUpdating}
              type="button"
            >
              Cancel
            </button>
            <AuthorizedAction
              extraClasses="is-primary"
              isDisabled={isUpdating || !isValid || (!dirty && isEdit)}
              permission={Permission.CONNECTION_EDIT}
              testId="add-plan-button"
              type="submit"
            >
              Save
            </AuthorizedAction>
          </div>
          <ErrorDisplay error={error} />
        </Form>
      )}
    </Formik>
  );
};

export default SupplyPeriodForm;
