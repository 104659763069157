/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Provider } from '../models/Provider';
import type { ProviderBody } from '../models/ProviderBody';
import type { ProviderList } from '../models/ProviderList';
import type { ProviderPlansList } from '../models/ProviderPlansList';
import type { ProviderSummary } from '../models/ProviderSummary';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ProvidersService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Returns a list of all Providers
     * @returns ProviderList List of Providers
     * @throws ApiError
     */
    public getAllProviders(): CancelablePromise<ProviderList> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v2/providers',
        });
    }

    /**
     * Creates a new Provider
     * @param requestBody Details of the new Provider to be created.
     * @returns Provider Provider created successfully. Returns details of the new Provider.
     * @throws ApiError
     */
    public createProvider(
        requestBody: ProviderBody,
    ): CancelablePromise<Provider> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/providers',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Could not process request due to a validation error. See ProviderBody schema for validation rules.`,
            },
        });
    }

    /**
     * Returns a list of all Providers, with summary information of each
     * @returns ProviderSummary List of summary information for all Providers
     * @throws ApiError
     */
    public getProviderSummaries(): CancelablePromise<Array<ProviderSummary>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v2/providers/summaries',
        });
    }

    /**
     * Updates an existing Provider
     * @param id Id of the existing Provider to be updated
     * @param requestBody Updated details for provider.
     * @returns Provider Provider updated successfully. Returns Provider details, with the updates applied.
     * @throws ApiError
     */
    public updateProvider(
        id: string,
        requestBody: ProviderBody,
    ): CancelablePromise<Provider> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/v2/providers/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Provider not found`,
                422: `Could not process request due to a validation error. See ProviderBody schema for validation rules.`,
            },
        });
    }

    /**
     * Returns a list of all Plans within a Provider, with summary information of each Plan
     * @param id Id of the Provider to list Plans for
     * @returns ProviderPlansList List of summary information for all Plans within the Provider
     * @throws ApiError
     */
    public getProviderPlansList(
        id: string,
    ): CancelablePromise<ProviderPlansList> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v2/providers/{id}/plans',
            path: {
                'id': id,
            },
        });
    }

}
