/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BaseHttpRequest } from './core/BaseHttpRequest';
import type { OpenAPIConfig } from './core/OpenAPI';
import { AxiosHttpRequest } from './core/AxiosHttpRequest';

import { ApiDocsService } from './services/ApiDocsService';
import { AuthenticationService } from './services/AuthenticationService';
import { ChargesService } from './services/ChargesService';
import { ConnectionsService } from './services/ConnectionsService';
import { ErrorsService } from './services/ErrorsService';
import { ExclusionsService } from './services/ExclusionsService';
import { ExportService } from './services/ExportService';
import { HasExpectedChargesQueriesService } from './services/HasExpectedChargesQueriesService';
import { ImportService } from './services/ImportService';
import { ListDataService } from './services/ListDataService';
import { MonitoringService } from './services/MonitoringService';
import { ObservancesService } from './services/ObservancesService';
import { PermissionsService } from './services/PermissionsService';
import { PlansService } from './services/PlansService';
import { ProvidersService } from './services/ProvidersService';
import { ProvisioningService } from './services/ProvisioningService';
import { QualifiersService } from './services/QualifiersService';
import { RolesService } from './services/RolesService';
import { SummarisationGroupsService } from './services/SummarisationGroupsService';
import { TestService } from './services/TestService';

type HttpRequestConstructor = new (config: OpenAPIConfig) => BaseHttpRequest;

export class RatingConfigClient {

    public readonly apiDocs: ApiDocsService;
    public readonly authentication: AuthenticationService;
    public readonly charges: ChargesService;
    public readonly connections: ConnectionsService;
    public readonly errors: ErrorsService;
    public readonly exclusions: ExclusionsService;
    public readonly export: ExportService;
    public readonly hasExpectedChargesQueries: HasExpectedChargesQueriesService;
    public readonly import: ImportService;
    public readonly listData: ListDataService;
    public readonly monitoring: MonitoringService;
    public readonly observances: ObservancesService;
    public readonly permissions: PermissionsService;
    public readonly plans: PlansService;
    public readonly providers: ProvidersService;
    public readonly provisioning: ProvisioningService;
    public readonly qualifiers: QualifiersService;
    public readonly roles: RolesService;
    public readonly summarisationGroups: SummarisationGroupsService;
    public readonly test: TestService;

    public readonly request: BaseHttpRequest;

    constructor(config?: Partial<OpenAPIConfig>, HttpRequest: HttpRequestConstructor = AxiosHttpRequest) {
        this.request = new HttpRequest({
            BASE: config?.BASE ?? 'http://localhost:8086',
            VERSION: config?.VERSION ?? '1.0.0',
            WITH_CREDENTIALS: config?.WITH_CREDENTIALS ?? false,
            CREDENTIALS: config?.CREDENTIALS ?? 'include',
            TOKEN: config?.TOKEN,
            USERNAME: config?.USERNAME,
            PASSWORD: config?.PASSWORD,
            HEADERS: config?.HEADERS,
            ENCODE_PATH: config?.ENCODE_PATH,
        });

        this.apiDocs = new ApiDocsService(this.request);
        this.authentication = new AuthenticationService(this.request);
        this.charges = new ChargesService(this.request);
        this.connections = new ConnectionsService(this.request);
        this.errors = new ErrorsService(this.request);
        this.exclusions = new ExclusionsService(this.request);
        this.export = new ExportService(this.request);
        this.hasExpectedChargesQueries = new HasExpectedChargesQueriesService(this.request);
        this.import = new ImportService(this.request);
        this.listData = new ListDataService(this.request);
        this.monitoring = new MonitoringService(this.request);
        this.observances = new ObservancesService(this.request);
        this.permissions = new PermissionsService(this.request);
        this.plans = new PlansService(this.request);
        this.providers = new ProvidersService(this.request);
        this.provisioning = new ProvisioningService(this.request);
        this.qualifiers = new QualifiersService(this.request);
        this.roles = new RolesService(this.request);
        this.summarisationGroups = new SummarisationGroupsService(this.request);
        this.test = new TestService(this.request);
    }
}

