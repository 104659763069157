import React, { FunctionComponent, useEffect, useState } from 'react';
import { EuiFormRow, EuiSelect, EuiSpacer } from '@elastic/eui';

import { useStore } from '../../../store';
import {
  useBillingConfigurationSupplyAgreement,
  useBillingConfigurationSupplyAgreementUpdate,
} from '../../billing/billingConfigurationApi';
import { Widget } from 'components/dashboard/Dashboard';

interface BillingFrequencyProps {
  externalSupplyAgreementId: string;
}

const BillingFrequency: FunctionComponent<BillingFrequencyProps> = ({
  externalSupplyAgreementId,
}) => {
  const [calMonth, setCalMonth] = useState<'calMonth' | 'month'>('calMonth');

  const { mutateAsync } = useBillingConfigurationSupplyAgreementUpdate(externalSupplyAgreementId, {
    enabled: !!externalSupplyAgreementId,
  });
  const { data: preferencesData } = useBillingConfigurationSupplyAgreement(
    externalSupplyAgreementId,
    {
      enabled: !!externalSupplyAgreementId,
    }
  );

  useEffect(() => {
    if (preferencesData?.billingPeriod.startDay != '1') {
      setCalMonth('month');
    } else {
      setCalMonth('calMonth');
    }
  }, [preferencesData]);

  return (
    <Widget>
      <EuiFormRow label="Billing frequency">
        <EuiSelect
          data-testid="billing-frequency-dropdown"
          placeholder="Select a billing frequency"
          options={[
            {
              text: 'Calendar Month',
              value: 'calMonth',
            },
            {
              text: 'Monthly',
              value: 'month',
            },
          ]}
          value={calMonth}
          onChange={(event) => {
            setCalMonth(event.target.value as 'calMonth' | 'month');
            if (event.target.value === 'calMonth') {
              if (preferencesData && preferencesData.billingPeriod) {
                const newConfig = preferencesData;
                newConfig.billingPeriod.startDay = '1';
                mutateAsync(newConfig);
              } else {
                showNoSupplyAgreementFoundMessage();
              }
            }
          }}
        />
      </EuiFormRow>
      {calMonth === 'month' && (
        <React.Fragment>
          <EuiSpacer size="s" />
          <EuiFormRow label="Starting billing date">
            <EuiSelect
              data-testid="start-billing-date-dropdown"
              placeholder="Select a Starting billing date"
              options={[...Array(28).keys()].map((num) => {
                return { text: num + 1, value: num + 1 };
              })}
              value={preferencesData?.billingPeriod.startDay}
              onChange={(event) => {
                if (preferencesData && preferencesData.billingPeriod) {
                  const newConfig = preferencesData;
                  newConfig.billingPeriod.startDay = event.target.value;
                  mutateAsync(newConfig);
                } else {
                  showNoSupplyAgreementFoundMessage();
                }
              }}
            />
          </EuiFormRow>
        </React.Fragment>
      )}
    </Widget>
  );
};

const showNoSupplyAgreementFoundMessage = () => {
  useStore.getState().addNotification({
    color: 'primary',
    id: 'NoSupplyAgreement',
    title: 'No Supply Agreement Configuration found for this connection.',
  });
};

export default BillingFrequency;
