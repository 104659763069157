import React from 'react';
import { useCurrencyTranslation } from 'hooks';
import { format, subSeconds } from 'date-fns';
import { CreditNote } from '../../billing/billingCreditNotesApi';
import { newMarketDate } from 'util/helper-func';

const dateFormat = 'd MMM yyyy';

type CreditNoteRowProps = CreditNote & {
  index: number;
  connection: string;
};

const CreditNoteRow = ({
  context,
  creditNoteNumber,
  issuedAt,
  amount,
}: CreditNoteRowProps): JSX.Element => {
  const { translateCurrency } = useCurrencyTranslation();

  const created = issuedAt ? format(newMarketDate(issuedAt), dateFormat) : '';

  // convert to date objects to format as required
  const fromDate = context?.startsAt ? newMarketDate(context.startsAt) : null;
  const toDate = context?.endsAt ? subSeconds(newMarketDate(context.endsAt), 1) : null;

  // used for display in table
  const from = fromDate ? format(fromDate, dateFormat) : '';
  const to = toDate ? format(toDate, dateFormat) : '';

  const formattedTotalAmount = translateCurrency(amount?.value);

  return (
    <tr
      className="apl-table-v1__row"
      data-testid={`credit-note-row-${creditNoteNumber?.toLowerCase()}`}
      key={creditNoteNumber}
    >
      <td>{creditNoteNumber}</td>
      <td>Credit note</td>
      <td>{created}</td>
      <td>{`${from} - ${to}`}</td>
      <td>{`${formattedTotalAmount}`}</td>
      <td></td>
    </tr>
  );
};

export default CreditNoteRow;
