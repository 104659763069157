/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { LossFactorExternalIdentifier } from '../models/LossFactorExternalIdentifier';
import type { LossFactorPageResponse } from '../models/LossFactorPageResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class LossFactorsService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Paginated list of loss factors
     * @param startsAt
     * @param endsAt
     * @param externalIdentifiers
     * @param limit
     * @param offset
     * @returns LossFactorPageResponse OK
     * @throws ApiError
     */
    public getLossFactors(
        startsAt?: string,
        endsAt?: string,
        externalIdentifiers?: Array<LossFactorExternalIdentifier>,
        limit: number = 100,
        offset?: number,
    ): CancelablePromise<LossFactorPageResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/loss-factors',
            query: {
                'startsAt': startsAt,
                'endsAt': endsAt,
                'externalIdentifiers': externalIdentifiers,
                'limit': limit,
                'offset': offset,
            },
            errors: {
                400: `Error response`,
                401: `Error response`,
                500: `Error response`,
            },
        });
    }

}
