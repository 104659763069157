import React from 'react';
import cx from 'classnames';
import { useCurrencyTranslation, useTaxTypeTranslation } from 'hooks';
import { format, subSeconds } from 'date-fns';
import { InvoiceContext, InvoiceDataTotal } from '../../billing/billingInvoicesApi';
import Card from '../../layout/Card';
import { newMarketDate } from 'util/helper-func';

// Important: contractedParty gets handed over from InvoiceDetails.tsx because the
// SupplyPeriod table doesn't get updated if this value changes in Core. This is a known
// bug which should be fixed hopefully soon. If this is fixed we can get rid of the separate
// contractedParty and use the supplyPeriod object which also has this field instead.
// see:https://jira.fluxfederation.com/browse/MX-11215

interface InvoiceSummaryProps {
  totals: InvoiceDataTotal;
  context: InvoiceContext;
  contractedParty: string;
}

const InvoiceSummary = ({ totals, context, contractedParty }: InvoiceSummaryProps) => {
  const { translateCurrency } = useCurrencyTranslation();
  const taxType = useTaxTypeTranslation();

  const from = context?.startsAt ? format(newMarketDate(context.startsAt), 'd MMM yyyy') : '';
  const to = context?.endsAt
    ? format(subSeconds(newMarketDate(context.endsAt), 1), 'd MMM yyyy')
    : '';

  return (
    <Card className="apl-mb-l">
      <div
        className={cx('apl-display-flex', 'apl-flex-row')}
        style={{ margin: '5px', justifyContent: 'space-between' }}
        data-testid="invoice summary"
      >
        <div className={cx('apl-display-flex', 'apl-flex-row')} style={{ marginRight: '5px' }}>
          <div
            className={cx('apl-display-flex', 'apl-flex-column')}
            style={{ marginRight: '60px' }}
          >
            <p className={cx('apl-my-none')}>Billing period</p>
            <p
              className={cx('apl-my-none')}
              style={{ fontSize: '32px', fontWeight: '700', lineHeight: '42px' }}
            >
              {`${from} - ${to}`}
            </p>
          </div>
          <div
            className={cx('apl-display-flex', 'apl-flex-column')}
            style={{ marginRight: '60px' }}
          >
            <p className={cx('apl-my-none')}>Contracting party</p>
            <p
              className={cx('apl-my-none')}
              style={{ fontSize: '32px', fontWeight: '700', lineHeight: '42px' }}
            >
              {contractedParty}
            </p>
          </div>
        </div>
        <div className={cx('apl-display-flex', 'apl-flex-row')} style={{ marginRight: '5px' }}>
          <div
            className={cx('apl-display-flex', 'apl-flex-column')}
            style={{ marginRight: '60px' }}
          >
            <p className={cx('apl-my-none')}>Subtotal</p>
            <p
              className={cx('apl-my-none')}
              style={{ fontSize: '32px', fontWeight: '700', lineHeight: '42px' }}
            >
              {totals?.subTotal ? translateCurrency(totals.subTotal) : '-'}
            </p>
          </div>
          <div
            className={cx('apl-display-flex', 'apl-flex-column')}
            style={{ marginRight: '60px' }}
          >
            <p className={cx('apl-my-none')}>{taxType}</p>
            <p
              className={cx('apl-my-none')}
              style={{ fontSize: '32px', fontWeight: '700', lineHeight: '42px' }}
            >
              {totals?.taxes ? translateCurrency(totals.taxes) : '-'}
            </p>
          </div>
          <div className={cx('apl-display-flex', 'apl-flex-column')}>
            <p className={cx('apl-my-none')}>Total</p>
            <p
              className={cx('apl-my-none')}
              style={{ fontSize: '32px', fontWeight: '700', lineHeight: '42px' }}
            >
              {totals?.total ? translateCurrency(totals.total) : '-'}
            </p>
          </div>
        </div>
      </div>
    </Card>
  );
};

export default InvoiceSummary;
