import React, { useState, FunctionComponent } from 'react';
import Modal from 'react-modal';
import ProviderForm from './ProviderForm';
import { ProviderSummary, useProviderSummary } from './providersApi';
import AuthorizedAction from '../common/AuthorizedAction';
import Card from '../layout/Card';
import { modalStyles } from '../layout/Modal';
import Page, { ErrorPage, LoadingPage, PageHeader } from '../layout/Page';
import { Permission } from '../../auth/getPermissions';
import searchIcon from '../../icons/search.svg';

const ManageProviders: FunctionComponent = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [search, setSearch] = useState('');
  const [currentProvider, setCurrentProvider] = useState<ProviderSummary>();
  const [sortAsc, setSortAsc] = useState(true);

  const { data, isInitialLoading: isLoading, isError } = useProviderSummary();

  const handleModalClose = () => {
    setCurrentProvider(undefined);
    setModalOpen(false);
  };

  if (isError) {
    return <ErrorPage />;
  }

  if (isLoading) {
    return <LoadingPage />;
  }

  return (
    <>
      <PageHeader backLink="/providers" title="Manage providers">
        <AuthorizedAction
          extraClasses="is-primary"
          onClick={() => setModalOpen(true)}
          permission={Permission.PROVIDERS_EDIT}
        >
          Add a provider
        </AuthorizedAction>
      </PageHeader>
      <Page>
        <Card className="apl-pt-none">
          <div className="card__inner table-filter apl-py">
            <div className="table-filter__input search-filter">
              <input
                className="apl-text-input-v1 search-filter__input"
                onChange={(event) => setSearch(event.target.value.toLowerCase())}
                placeholder="Find a provider"
                type="text"
              />
              <img alt="search icon" className="search-filter__icon" src={searchIcon} />
            </div>
          </div>
          <table className="apl-table-v1">
            <thead>
              <tr>
                <th
                  className={sortAsc ? 'is-sortable is-sorted-desc' : 'is-sortable is-sorted-asc'}
                  onClick={() => setSortAsc(!sortAsc)}
                >
                  Provider name
                </th>
                <th>Codes</th>
                <th>Charge component</th>
                <th>Plans</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {data &&
                (data as ProviderSummary[])
                  .filter((provider) => provider.providerName.toLowerCase().includes(search))
                  .sort((a, b) => {
                    const providerNameA = a.providerName;
                    const providerNameB = b.providerName;

                    if (providerNameA < providerNameB) {
                      return sortAsc ? -1 : 1;
                    } else if (providerNameA > providerNameB) {
                      return sortAsc ? 1 : -1;
                    }

                    return 0;
                  })
                  .map((provider) => {
                    const { code, defaultChargeTag, providerName, plansCount } = provider;
                    const chargeComponent = defaultChargeTag
                      ? defaultChargeTag.substr(0, 1) + defaultChargeTag.substring(1).toLowerCase()
                      : '';

                    const name = providerName.replace(/ /g, '-').toLowerCase();

                    return (
                      <tr data-testid={`provider-row-${name}`} key={`provider-${providerName}`}>
                        <td>{providerName}</td>
                        <td>{code}</td>
                        <td>{chargeComponent}</td>
                        <td>{plansCount}</td>
                        <td>
                          <AuthorizedAction
                            onClick={() => {
                              setCurrentProvider(provider);
                              setModalOpen(true);
                            }}
                            permission={Permission.PROVIDERS_EDIT}
                          >
                            Edit
                          </AuthorizedAction>
                        </td>
                      </tr>
                    );
                  })}
            </tbody>
          </table>
        </Card>
      </Page>
      <Modal
        isOpen={modalOpen}
        onRequestClose={handleModalClose}
        style={modalStyles}
        shouldCloseOnOverlayClick={true}
      >
        <ProviderForm
          onCancel={handleModalClose}
          onAfterSubmit={handleModalClose}
          provider={currentProvider}
        />
      </Modal>
    </>
  );
};

export default ManageProviders;
