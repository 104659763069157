import React, { forwardRef, ReactNode } from 'react';
import cx from 'classnames';
import { useSupplyPeriodAttribute } from '../../hooks';
import BillingReadinessWidget from './billing-readiness/BillingReadinessWidget';
import ConnectionSearchWidget from './ConnectionSearchWidget';
import BillingSummaryWidget from './billing-summary/BillingSummaryWidget';
import ErrorFeedWidget from './error-feed/ErrorFeedWidget';
import ConnectionsWithoutEnergyPlansWidget from './missing-plans/ConnectionsWithoutEnergyPlansWidget';
import ConnectionsWithoutNetworkPlansWidget from './missing-plans/ConnectionsWithoutNetworkPlansWidget';
import FailedRatingRequestsWidget from './rating-requests/FailedRatingRequestsWidget';
import Page from '../layout/Page';
import getConfig from 'config/getConfig';

const config = getConfig();

import './Dashboard.scss';
import RatingCalculatorMetricsWidget from './rating-calculator-metrics/RatingCalculatorMetricsWidget';
import { EuiPanel, EuiText } from '@elastic/eui';

type WidgetProps = {
  children?: ReactNode;
  className?: string;
  headerComponent?: ReactNode;
  style?: object;
  title?: string;
  width?: 'full' | 'default';
};

export const Widget = forwardRef<HTMLDivElement, WidgetProps>(
  ({ children, className, headerComponent, title }) => {
    return (
      <EuiPanel grow={false} className={cx('widget', className)}>
        <EuiText>
          {headerComponent ? (
            headerComponent
          ) : title ? (
            <h3 className="widget__title apl-h3 apl-mb-none">{title}</h3>
          ) : null}
        </EuiText>
        {children}
      </EuiPanel>
    );
  }
);

export interface SupplyPeriodAttributeProps {
  supplyPeriodAttribute: string[];
  supplyPeriodAttributeURLSearchParams: string;
}

interface DashboardProps {
  children?: React.ReactNode;
}

const Dashboard: React.FunctionComponent<DashboardProps> = () => {
  const {
    SupplyPeriodAttributeSelect,
    supplyPeriodAttribute,
    supplyPeriodAttributeURLSearchParams,
  } = useSupplyPeriodAttribute();
  const supplyPeriodAttributeProps = {
    supplyPeriodAttribute,
    supplyPeriodAttributeURLSearchParams,
  };

  return (
    <>
      <Page>
        <div className="widgets apl-display-flex apl-flex-row apl-flex-nowrap">
          <div className="widgets__column apl-display-flex apl-flex-column">
            <ConnectionSearchWidget />
          </div>
          <div className="widgets__column apl-display-flex apl-flex-column"></div>
          <div className="widgets__column apl-display-flex apl-flex-column">
            {SupplyPeriodAttributeSelect && (
              <div className="uml-filter">{SupplyPeriodAttributeSelect}</div>
            )}
          </div>
        </div>
        <div className="widgets apl-display-flex apl-flex-row apl-flex-nowrap">
          <div className="widgets__column apl-display-flex apl-flex-column">
            {config.showRatingCalculatorMetrics && <RatingCalculatorMetricsWidget />}
            <BillingReadinessWidget {...supplyPeriodAttributeProps} />
            <FailedRatingRequestsWidget />
          </div>
          <div className="widgets__column apl-display-flex apl-flex-column">
            <BillingSummaryWidget {...supplyPeriodAttributeProps} />
            <ErrorFeedWidget />
          </div>
          <div className="widgets__column apl-display-flex apl-flex-column">
            <ConnectionsWithoutEnergyPlansWidget {...supplyPeriodAttributeProps} />
            <ConnectionsWithoutNetworkPlansWidget {...supplyPeriodAttributeProps} />
          </div>
        </div>
      </Page>
    </>
  );
};

export default Dashboard;
