import React from 'react';
import { Adjustment, AdjustmentSource, Usage } from '../../billing/billingInvoicesApi';

interface PlanTableUsageFieldProps {
  precision?: number;
  usage: Usage;
}

/*
 * Convert the array of objects into a Map which is easier to use when doing boolean logic.
 */
const getMapFromAdjustments = (adjustments: Adjustment[]): Map<AdjustmentSource, number> => {
  const kvArray = adjustments.map((adjustment): [AdjustmentSource, number] => [
    adjustment.source,
    adjustment.value,
  ]);
  const map = new Map(kvArray);
  return map;
};

/*
 * The order is important because includes() is being used
 * as a comparator.
 * Valid values for `adjustments` are:
 *   [Distribution, Transmission]
 *   [Distribution]
 *   [None]
 */
const getAdjustmentsLabel = (adjustments: Adjustment[]): string => {
  const { Transmission, Distribution, None } = AdjustmentSource;

  if (Array.isArray(adjustments)) {
    const map = getMapFromAdjustments(adjustments);

    if (map.has(Distribution) && map.has(Transmission)) {
      return 'Total';
    } else if (map.has(Distribution)) {
      return 'Distribution';
    } else if (map.has(None)) {
      return 'Raw';
    }
  }

  return '';
};

export const PlanTableUsageFieldFbau1002 = ({ usage, precision = 2 }: PlanTableUsageFieldProps) => {
  const { value, unit, adjustments } = usage;
  const usageValue = value ? `${parseFloat(value).toFixed(precision)} ${unit}` : '-';
  const lfLabel = getAdjustmentsLabel(adjustments);

  return (
    <>
      {usageValue}
      {lfLabel && <span className="badge apl-ml-s">{lfLabel}</span>}
    </>
  );
};
