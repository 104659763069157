import { useTranslation } from 'react-i18next';
import { PublishedError } from 'components/errors/errorsApi';

/**
 * Helper method to prepare the error's attributes for translation.
 *
 * @param {PublishedError} error the error to be translated
 * @returns {Record<string, string>} the object containing an error's attributes
 */
function constructErrorMessageItemData(error: PublishedError): Record<string, string> {
  const defaultMessage = `${error.message ?? '<Not Supplied>'}${
    error.attributes?.length > 0
      ? '\n' +
        error.attributes
          .map(
            (attr) =>
              `${attr.attribute} : ${
                attr.message == null || attr.message == '' ? '<Not Supplied>' : attr.message
              }`
          )
          .join(' ')
      : ''
  }`;
  let data = { defaultValue: defaultMessage, message: error.message ?? '<Not Supplied>' };

  for (const attribute of error.attributes) {
    data = {
      ...data,
      [attribute.urn.substring(attribute.urn.lastIndexOf(':') + 1)]:
        attribute.message == null || attribute.message == '' ? '<Not Supplied>' : attribute.message,
    };
  }

  return data;
}

/**
 * Helper method to construct the translation key based on the error's urn.
 *
 * @param {PublishedError} error the error to be translated
 * @returns {string} the key value corresponding to an error translation
 */
function generateErrorMessageKey(error: PublishedError): string {
  return `error.${error.urn
    .substring(error.urn.indexOf('urn:') + 'urn:'.length)
    .split(':')
    .join('.')}`;
}

type UseErrorTranslation = {
  translateError: (error: PublishedError) => string;
};

/**
 * Hook that converts an error into a more user friendly version.
 *
 * @returns {Function} the function to translate an error
 */
export default function useErrorTranslation(): UseErrorTranslation {
  const { t: translate } = useTranslation();

  function translateError(error: PublishedError) {
    return translate(generateErrorMessageKey(error), constructErrorMessageItemData(error));
  }

  return {
    translateError,
  };
}
