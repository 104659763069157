import React, { FunctionComponent } from 'react';
import { ErrorModel } from '../../api/ErrorDisplay';

import QualifierForm from './QualifierForm';
import { QualifierDetailsType, useUpdateQualifier } from './qualifiersApi';
import { MultiRangeQualifierBody } from '../../api/openapi/rating-config';

interface UpdateQualifierProps {
  close: () => void;
  qualifier: QualifierDetailsType;
  qualifierId: string | undefined;
}

const UpdateQualifier: FunctionComponent<UpdateQualifierProps> = ({
  qualifier,
  close,
  qualifierId,
}) => {
  const { mutateAsync, error } = useUpdateQualifier();
  const submit = async (multiRangeQualifierBody: MultiRangeQualifierBody): Promise<any> => {
    try {
      await mutateAsync({ qualifierId, multiRangeQualifierBody });
      close();
    } catch (e) {
      console.error(e as Error);
    }
  };

  return (
    <div>
      <h2>Edit qualifier</h2>
      <QualifierForm
        submitFunction={submit}
        isUpdating={false}
        close={close}
        qualifier={qualifier}
        error={error as ErrorModel}
      />
    </div>
  );
};

export default UpdateQualifier;
