import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { isEmpty } from 'ramda';
import { endOfMonth, format, startOfMonth } from 'date-fns';
import { useSupplyPeriodSummaryBetween } from '../../connections/connectionsApi';
import { Permission } from '../../../auth/getPermissions';
import AuthorizedAction from '../../common/AuthorizedAction';

const BillingReadinessLine = (props: {
  connectionIcp: string;
  item: any;
  index: number;
  selectedDate: Date;
}) => {
  const { connectionIcp, item, index, selectedDate } = props;

  const navigate = useNavigate();
  const { connection: connectionId } = item;

  const dateFormat = 'yyyy-MM-dd';
  const from = format(startOfMonth(selectedDate), dateFormat);
  const to = format(endOfMonth(selectedDate), dateFormat);
  const connectionUrl = `/connections/${connectionId}`;
  let connectionLink = connectionUrl;
  let viewChargeSetsLink = `${connectionUrl}/charges?from=${from}&to=${to}`;

  const {
    data,
    isError,
    isInitialLoading: isLoading,
  } = useSupplyPeriodSummaryBetween(connectionId, from, to);

  if (isLoading) {
    return (
      <tr>
        <td colSpan={4}>Loading...</td>
      </tr>
    );
  }

  if (!isError) {
    if (data?.supplyPeriodSummaries?.length === 1 && data.supplyPeriodSummaries[0]?.owner) {
      const owner = data.supplyPeriodSummaries[0].owner;
      if (!isEmpty(owner)) {
        connectionLink += `/contracted-parties/${owner}`;
        viewChargeSetsLink += `&contracted_party=${owner}`;
      }
    }
  }

  return (
    <tr className="zebra-table__row" key={connectionId + index}>
      <td>
        <Link className="apl-color-primary" to={connectionLink}>
          {connectionIcp}
        </Link>
      </td>
      <td className="apl-display-flex apl-flex-row">
        <AuthorizedAction
          onClick={() => navigate(viewChargeSetsLink)}
          permission={Permission.CONNECTION_VIEW}
        >
          View charge sets
        </AuthorizedAction>
      </td>
    </tr>
  );
};

export default BillingReadinessLine;
