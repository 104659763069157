import cx from 'classnames';
import { format } from 'date-fns';
import React, { KeyboardEvent, useEffect, useMemo, useState } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import {
  useParallelBillingReadiness,
  BillingReadinesses,
  NotReadyEnum,
} from '../../billing/billingReadinessApi';
import BillingReadinessLine from './BillingReadinessLine';
import AdvancedPagination from '../../common/AdvancedPagination';
import EmptyTableRow from '../../common/EmptyTableRow';
import { useConnections, Connection } from '../../connections/connectionsApi';
import Card from '../../layout/Card';
import Page, { ErrorPage, LoadingPage, PageHeader } from '../../layout/Page';
import {
  generateDateOptions,
  marketEndOfMonth,
  marketStartOfMonth,
} from '../../../util/helper-func';
import searchIcon from '../../../icons/search.svg';

import '../../common/Table.scss';
import { useSupplyPeriodAttribute } from '../../../hooks';

export const generateCategories = () => {
  const categories: Record<NotReadyEnum, string> = {
    'urn:flux:billing:readiness:not_ready:on_hold': 'Connections on hold',
    'urn:flux:billing:readiness:not_ready:missing_charges': 'Connections with missing invoices',
    'urn:flux:billing:readiness:not_ready:estimated_usage': 'Estimated metering data',
    'urn:flux:billing:readiness:not_ready:non_final_spot_prices': 'Spot prices not final',
  };

  return (Object.keys(categories) as NotReadyEnum[]).map((key) => ({
    name: categories[key],
    url: categories[key].replace(/ /g, '-').toLowerCase(),
    enum: key,
  }));
};

const findMatchingCategoryData = (item: BillingReadinesses, category: NotReadyEnum) => {
  // find the self link data
  const request = item._links.find((link) => link.rel === 'self');

  // split the href into URL and query params
  const parts = request?.href ? decodeURI(request.href).split('?') : [];
  const search = parts.length === 2 ? new URLSearchParams(parts[1]) : null;

  // extract the value used for request
  const categoryVal = search?.get('not_ready');

  // check if it matches the given category
  return categoryVal && decodeURIComponent(categoryVal) === category;
};

const BillingReadiness = () => {
  const { SupplyPeriodAttributeSelect, supplyPeriodAttribute } = useSupplyPeriodAttribute();

  const options = useMemo(() => generateDateOptions(true, 24), []);
  const categories = useMemo(() => generateCategories(), []);

  const { search: searchParams } = useLocation();
  const query = searchParams ? new URLSearchParams(searchParams) : null;
  const queryDate = query && query.get('date');
  const initialDate = queryDate !== null ? new Date(queryDate) : new Date();

  const [date, setDate] = useState<Date>(initialDate);

  // we want to have the search input controlled by state so if the component
  // is destroyed by an error or loading state, it holds the search value
  // previously set by the user
  const [search, setSearch] = useState('');

  // we also want a separate state to hold the value to actually search against
  // because we only want to search against the API once the ENTER key is
  // pressed
  const [connectionSearch, setConnectionSearch] = useState<string>();
  const [notFound, setNotFound] = useState(false);

  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(25);

  const offset = page !== 0 ? page * limit : 0;

  const { tab } = useParams<{ tab: string }>();

  // reset offset to 0 when changing categories
  useEffect(() => setPage(0), [tab]);

  // billing api works with UTC dates so we convert to match and the endOfMonth
  // method is 1 ms before the next day, but we want to add that back on, so
  // when we convert to UTC it includes the correct billing end date/time
  const billingData = useParallelBillingReadiness(
    categories.map((category) => ({
      startDate: marketStartOfMonth(date).toISOString(),
      endDate: marketEndOfMonth(date).toISOString(),
      notReady: category.enum,
      id: connectionSearch,
      limit: category.url === tab ? limit : 1,
      offset: category.url === tab ? offset : 0,
      attributes: supplyPeriodAttribute,
    }))
  );

  const isBillingDataError = billingData.find((billing) => billing.isError);
  const isBillingDataLoading = billingData.find((billing) => billing.isLoading);
  const tabEnum = categories.find((category) => category.url === tab)?.enum ?? '';

  const {
    data: connectionData,
    isError: isConnectionDataError,
    isInitialLoading: isConnectionDataLoading,
  } = useConnections();

  if (isBillingDataError || isConnectionDataError) {
    return <ErrorPage />;
  }

  if (isBillingDataLoading || isConnectionDataLoading) {
    return <LoadingPage />;
  }

  const notReadyData = billingData.find((result) =>
    findMatchingCategoryData(result.data as BillingReadinesses, tabEnum as NotReadyEnum)
  )?.data;

  const handleOnKeyUp = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      const { value } = event.currentTarget;

      if (value === '') {
        setConnectionSearch(undefined);
        setNotFound(false);

        return;
      }

      const existingConnection = (connectionData as Connection[]).find(
        (connection) => connection.connectionId === value || connection.id === value
      );

      if (existingConnection) {
        setConnectionSearch(existingConnection.id);
        setNotFound(false);
      } else {
        setConnectionSearch(undefined);
        setNotFound(true);
      }
    }
  };

  return (
    <>
      <PageHeader showBack title="Billing readiness" />
      <Page>
        <Card className="apl-pb-none">
          <nav className="tabs card__inner">
            <ul className="tabs__list">
              {categories.map((category) => {
                const categoryData = billingData.find((result) =>
                  findMatchingCategoryData(result.data as BillingReadinesses, category.enum)
                )?.data;

                const count = notFound
                  ? 0
                  : (categoryData as BillingReadinesses)?.totals?.[0]?.total;

                return (
                  <li className="tabs__list-item" key={category.name}>
                    <Link
                      className={
                        tab === category.url
                          ? 'tabs__list-link tabs__list-link--active'
                          : 'tabs__list-link'
                      }
                      to={`../${category.url}`}
                      relative="path"
                    >
                      {category.name} ({count ?? 0})
                    </Link>
                  </li>
                );
              })}
            </ul>
          </nav>
          <div
            className={cx(
              'card__inner',
              'apl-display-flex',
              'apl-flex-row',
              'apl-justify-content-between',
              'apl-align-items-center',
              'apl-p-s'
            )}
            style={{
              borderBottom: '1px solid #D3D8DA',
            }}
          >
            <div
              className={cx(
                'apl-field-v1',
                'apl-display-flex',
                'apl-flex-row',
                'apl-align-items-center',
                'apl-justify-content-center'
              )}
              style={{ columnGap: '20px' }}
            >
              <label className="apl-field__label apl-mr apl-mb-none" htmlFor="date-field">
                Show
              </label>
              <select
                className="apl-select-v1_0"
                id="date-field"
                onChange={(event) => setDate(new Date(event?.target.value))}
                value={`${format(date, 'yyyy-MM-dd')}`}
              >
                {options.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
              <div style={{ marginBottom: '8px' }}>{SupplyPeriodAttributeSelect}</div>
            </div>
            <div className="search-filter">
              <input
                className="apl-text-input-v1 search-filter__input"
                onChange={(e) => setSearch(e.target.value)}
                onKeyUp={handleOnKeyUp}
                placeholder="Find a connection"
                type="text"
                value={search}
              />
              <img alt="search icon" className="search-filter__icon" src={searchIcon} />
            </div>
          </div>
          <table className="apl-table-v1 is-plain zebra-table apl-mb-none">
            {notFound && <EmptyTableRow message="Sorry, no connections found." />}
            {!notFound && (
              <>
                <thead>
                  <tr>
                    <th>Connection ID</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {(notReadyData as BillingReadinesses) &&
                    (notReadyData as BillingReadinesses).items.map((item, index) => {
                      const { connection: connectionId } = item;

                      const existingConnection = (connectionData as Connection[]).find(
                        (connection) => connection.id === connectionId
                      );

                      const connectionIcp = existingConnection?.connectionId ?? connectionId;

                      return (
                        <BillingReadinessLine
                          key={`billing-readiness-line-${index}`}
                          selectedDate={date}
                          item={item}
                          index={index}
                          connectionIcp={connectionIcp}
                        />
                      );
                    })}
                </tbody>
              </>
            )}
          </table>
          {!notFound && (notReadyData as BillingReadinesses)?.total > 0 && (
            <div className="card__inner">
              <AdvancedPagination
                limit={limit}
                limitOptions={[25]}
                onLimitChange={setLimit}
                onPageChange={setPage}
                page={page}
                totalItems={(notReadyData as BillingReadinesses)?.totals?.[0]?.total}
              />
            </div>
          )}
        </Card>
      </Page>
    </>
  );
};

export default BillingReadiness;
