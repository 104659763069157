/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { ChargeCalculation } from './ChargeCalculation';
import type { ChargeTag } from './ChargeTag';
import type { Display } from './Display';
import type { RateSchedule } from './RateSchedule';

export type Charge = {
    id?: string;
    planId?: string;
    supplyPeriodId?: string;
    chargeType: Charge.chargeType;
    /**
     * Must be a valid chargeBasis for the chargeType.
     */
    chargeBasis: string;
    chargeReference?: string;
    aggregationMethod?: Charge.aggregationMethod;
    chargeTags?: Array<ChargeTag>;
    chargeCalculation?: ChargeCalculation;
    schedules?: Array<RateSchedule>;
    chargeName: string;
    billingDescriptor: string;
    /**
     * Required if chargeType is CALCULATED_PEAK_DEMAND or CALCULATED_POWER_FACTOR.
     */
    flowDirection?: Charge.flowDirection;
    /**
     * Required if chargeType is CALCULATED_POWER_FACTOR.
     */
    powerFactor?: number;
    /**
     * Required if chargeType is CALCULATED_PEAK_DEMAND.
     */
    topPeriods?: number;
    /**
     * Required if chargeType is CALCULATED_PEAK_DEMAND.
     */
    sumFunction?: Charge.sumFunction;
    /**
     * Type of adjustment applied to the charge
     */
    adjustmentToUse?: Array<'urn:flux:rating:charge:adjustment:distribution' | 'urn:flux:rating:charge:adjustment:transmission' | 'urn:flux:rating:charge:adjustment:none'>;
    /**
     * The number of periods that we look back to in order to identify the peak demand - units in number of months. Required if chargeType is CALCULATED_PEAK_DEMAND.
     */
    lookbackMonths?: number;
    /**
     * Required if chargeType is CALCULATED_PEAK_DEMAND.
     */
    intervalSize?: number;
    /**
     * The minimum chargeable demand. Required if chargeType is CALCULATED_PEAK_DEMAND.
     */
    minimumChargeable?: number;
    /**
     * Required if chargeType is CALCULATED_PEAK_DEMAND
     */
    minimumThresholdMethod?: Charge.minimumThresholdMethod;
    /**
     * Required if chargeType is CALCULATED_PEAK_DEMAND.
     */
    historicalMeasure?: string;
    /**
     * The Usage Charge Filter for a VOLUME Based Charge. Required if VOLUME Based Charge.
     */
    usageChargeFilter?: string;
    display?: Display;
    provider?: string;
    billingCategory?: Charge.billingCategory;
    isDeleted?: boolean;
    readingQuality?: Charge.ReadingQuality;
    isTaxed?: string;
};

export namespace Charge {

    export enum chargeType {
        ADDITIONAL_CONNECTIONS_DAILY = 'ADDITIONAL_CONNECTIONS_DAILY',
        CALCULATED_EXCESS_DEMAND = 'CALCULATED_EXCESS_DEMAND',
        CALCULATED_OFF_PEAK_EXCESS_DEMAND = 'CALCULATED_OFF_PEAK_EXCESS_DEMAND',
        CALCULATED_PEAK_DEMAND = 'CALCULATED_PEAK_DEMAND',
        CALCULATED_POWER_FACTOR = 'CALCULATED_POWER_FACTOR',
        CALCULATED_EXCESS_REACTIVE_NPOW = 'CALCULATED_EXCESS_REACTIVE_NPOW',
        CALCULATED_POWER_FACTOR_COUP = 'CALCULATED_POWER_FACTOR_COUP',
        CALCULATED_POWER_FACTOR_NELS = 'CALCULATED_POWER_FACTOR_NELS',
        CALCULATED_POWER_FACTOR_WAIK = 'CALCULATED_POWER_FACTOR_WAIK',
        CAPACITY = 'CAPACITY',
        CAPACITY_BASED_SWITCHES = 'CAPACITY_BASED_SWITCHES',
        CONTROL_PERIOD_DEMAND = 'CONTROL_PERIOD_DEMAND',
        DAILY = 'DAILY',
        DISTANCE_OVERHEAD = 'DISTANCE_OVERHEAD',
        DISTANCE_OVERHEAD_CAPACITY = 'DISTANCE_OVERHEAD_CAPACITY',
        DISTANCE_UNDERGROUND = 'DISTANCE_UNDERGROUND',
        DISTANCE_UNDERGROUND_CAPACITY = 'DISTANCE_UNDERGROUND_CAPACITY',
        FITTINGS = 'FITTINGS',
        INTERRUPTIBILITY = 'INTERRUPTIBILITY',
        METERED_DEMAND = 'METERED_DEMAND',
        METERING_CHARGE = 'METERING_CHARGE',
        MONTHLY = 'MONTHLY',
        NOMINATED_EXCESS_DEMAND = 'NOMINATED_EXCESS_DEMAND',
        NOMINATED_VOLUME = 'NOMINATED_VOLUME',
        PEAK_DEMAND = 'PEAK_DEMAND',
        POWER_FACTOR = 'POWER_FACTOR',
        TOTALISED_ICPS = 'TOTALISED_ICPS',
        TRANSFORMER_CAPACITY = 'TRANSFORMER_CAPACITY',
        TRANSMISSION_CONTROL_PERIOD = 'TRANSMISSION_CONTROL_PERIOD',
        TRANSMISSION_NOMINATED_PEAK_DEMAND = 'TRANSMISSION_NOMINATED_PEAK_DEMAND',
        VOLUME = 'VOLUME',
        STEPPED_TARIFF = 'STEPPED_TARIFF',
    }

    export enum aggregationMethod {
        URN_FLUX_RATING_CHARGE_AGGREGATION_COINCIDENTAL = 'urn:flux:rating:charge:aggregation:coincidental',
        URN_FLUX_RATING_CHARGE_AGGREGATION_SUMMATED = 'urn:flux:rating:charge:aggregation:summated',
    }

    /**
     * Required if chargeType is CALCULATED_PEAK_DEMAND or CALCULATED_POWER_FACTOR.
     */
    export enum flowDirection {
        INTO = 'INTO',
        OUT = 'OUT',
    }

    export enum ReadingQuality {
        BOTH = 'BOTH',
        ACTUAL = 'ACTUAL',
        CALCULATED = 'CALCULATED'
    }

    /**
     * Required if chargeType is CALCULATED_PEAK_DEMAND.
     */
    export enum sumFunction {
        AVERAGE = 'AVERAGE',
        AVERAGE_OF_DAILY_HIGHEST = 'AVERAGE_OF_DAILY_HIGHEST',
        HIGHEST_OF_DAILY_AVERAGE = 'HIGHEST_OF_DAILY_AVERAGE',
    }

    /**
     * Required if chargeType is CALCULATED_PEAK_DEMAND
     */
    export enum minimumThresholdMethod {
        ZERO = 'ZERO',
        MINIMUM = 'MINIMUM',
    }

    export enum billingCategory {
        ENERGY = 'ENERGY',
        NETWORK = 'NETWORK',
        LEVY = 'LEVY',
        EQUIPMENT = 'EQUIPMENT',
        ENERGYFEE = 'ENERGYFEE',
        ENVIRONMENTAL = 'ENVIRONMENTAL',
        REGULATOR = 'REGULATOR',
        DEDUCTIONS = 'DEDUCTIONS',
        FINANCE = 'FINANCE',
        OTHER = 'OTHER',
    }


}

