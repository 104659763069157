import React, { ChangeEvent, FunctionComponent, useMemo, useState } from 'react';
import { useCurrencyUnitTranslation, useMassUnitTranslation } from 'hooks';
import { useListData } from 'list-data/ListDataContext';
import { ErrorMessage, Field, FieldArray, Form, Formik } from 'formik';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { v4 } from 'uuid';
import { isNil } from 'ramda';
import { Permission } from '../../../auth/getPermissions';
import { FieldOption } from '../../../util/helper-func';
import AuthorizedAction from '../../common/AuthorizedAction';
import { FormRow } from '../../form';
import SteppedTariffForm from './SteppedTariffForm';
import ButtonAsLink from '../../form/ButtonAsLink';
import CreatableLookup from '../../form/CreatableLookup';
import FluxDatePicker from '../../form/DatePicker';
import FieldWrapper, { FieldWidth } from '../../form/FieldWrapper';
import '../../form/Form.scss';
import MultiSelect, { MultiSelectGroup, MultiSelectOption } from '../../form/MultiSelect';
import TextOverlay from '../../form/TextOverlay';
import { ErrorText } from '../../layout/Errors';
import { useProviders } from '../../providers/providersApi';
import { Qualifier, useQualifierList } from '../../qualifiers/qualifiersApi';
import {
  SummarisationGroup,
  useSummarisationGroupList,
  useSummarisationGroupListCreation,
} from '../summarisationGroupApi';
import {
  findAndSanitiseChargeTypeByValue,
  generateAggregationMethodOptions,
  generateChargeCalcOptions,
  generateChargeTagOptions,
  getDefaultUsageChargeFilter,
  getTextOverlay,
  initialValues,
  onlyNumberChange,
  resetHistoricalMeasure,
  resetSpotPercentage,
  resetVolumeAdjustmentFactor,
  simpleChargeFormSchema,
} from './simpleChargeUtils';
import {
  chargeIsOptional,
  chargeRequiresAggregationMethod,
  chargeRequiresAggregationMethodAndBasis,
  chargeRequiresApplicableTimePeriod,
  chargeRequiresFlowDirection,
  chargeRequiresHistoricalMeasure,
  chargeRequiresIntervalSize,
  chargeRequiresLookbackMonths,
  chargeRequiresLossAdjustment,
  chargeRequiresMinimumChargeable,
  chargeRequiresMinimumThresholdMethod,
  chargeRequiresPowerFactorThreshold,
  chargeRequiresReadingQuality,
  chargeRequiresIsTaxed,
  chargeRequiresSpotPercentage,
  chargeRequiresSteppedTariff,
  chargeRequiresSummarisationFunction,
  chargeRequiresTopNPeriods,
  chargeRequiresUsageChargeFilter,
  chargeRequiresVolumeAdjustmentFactor,
  ChargeTypeAttribute,
  ChargeTypesConfig,
} from 'list-data/charge-types/chargeTypesConfigUtils';
import { SummarisationFunctionOptions } from 'list-data/summarisation-function/summarisationFunctionUtils';
import { SimpleChargeFormBillingCategoryFieldFBAU635 } from './SimpleChargeFormBillingCategoryFieldFBAU635';
import { useUsageChargeFilters } from '../../usage-charge-filters/UsageChargeFiltersContext';
import { getMarketFunction } from '../../../list-data/getMarketFunction';
import { MarketMap } from '../../connections/connectionsApi';
import { Charge, Plan, Provider } from '../../../api/openapi/rating-config';
import { SimpleChargeFormLossesField } from './SimpleChargeFormLossesField';

interface SimpleChargeFormProps {
  close: () => void;
  charge?: Charge;
  formAction: any;
  isLoading: boolean;
  isChargeError: boolean;
  qualifierErrorMessage?: string;
  plan: Plan | undefined;
  provider: Provider;
}

export interface SimpleRate {
  applicableTimePeriods: MultiSelectOption[];
  cost: number | null;
  key: string;
  rate: number;
  rateQualifiers: MultiSelectOption[];
  spotPercentage: number | null;
  summarisationGroup: string;
  validFrom: string;
  validTo: string;
  volumeAdjustmentFactor: number | null;
}

const SimpleChargeForm: FunctionComponent<SimpleChargeFormProps> = ({
  close,
  formAction,
  isLoading,
  isChargeError,
  qualifierErrorMessage,
  charge,
  plan,
  provider,
}) => {
  const {
    fbau721ExpandVolumeChargeToIncludeNewAttributesAndChargeBasis, // LD Client Key is fbau-721-expand-volume-charge-to-include-new-attributes-and-charge-basis: true
    fbau689SpotPercentageOnVolumeEnergyCharge, // LD Client Key is fbau-689-spot-percentage-on-volume-energy-charge
    fbau635BillingCategoryForInvoicePresentation, // LD Client Key is fbau-635-billing-category-for-invoice-presentation
    fbau802HistoricalPeakDemandUi, // LD Client Key is fbau-802-historical-peak-demand-ui
    fbau1079AddUsageChargeFilterToVolumeBasedCharges, // LD Client Key is // fbau-1079-add-usage-charge-filter-to-volume-based-charges
    nz1683AddCostToUi, // LD Client Key is nz-1683-add-cost-to-ui
    cfd866OnBillFinance, // LD client key cfd-866-on-bill-finance
    cfd872SteppedTariffs,
  } = useFlags();

  const currencyUnit = useCurrencyUnitTranslation();
  const tonneUnit = useMassUnitTranslation('T');

  const [chargeTypesList, summarisationFunctionList, billingCategoryList, historicalMeasureList] =
    useListData([
      'CHARGE_TYPES',
      'SUMMARISATION_FUNCTION',
      'BILLING_CATEGORIES',
      'HISTORICAL_MEASURE',
    ]);

  const chargeTypesConfig = chargeTypesList?.data as ChargeTypesConfig;
  const sumFunctionOptions = summarisationFunctionList?.data as SummarisationFunctionOptions;
  const historicalMeasureOptions = historicalMeasureList?.data as FieldOption[];
  const usageChargeFilterList = useUsageChargeFilters();
  const [marketFunctionFlags] = useListData(['MARKET_FUNCTION']);
  const currentMarket = getMarketFunction(
    'urn:flux:rating-config:market',
    undefined,
    marketFunctionFlags
  );
  const chargeTagOptions = useMemo(
    () => generateChargeTagOptions(currentMarket === MarketMap.AU_MARKET, cfd866OnBillFinance),
    []
  );
  const chargeCalcOptions = useMemo(() => generateChargeCalcOptions(), []);
  const aggregationMethodOptions = useMemo(() => generateAggregationMethodOptions(), []);

  const { data: qualifierResponse, isInitialLoading: qualifierLoading } = useQualifierList();

  const { data: summarisationGroupResponse, isInitialLoading: summarisationGroupsLoading } =
    useSummarisationGroupList();

  const { mutateAsync, error } = useSummarisationGroupListCreation();

  if (error) {
    console.error(new Error(error.message), error);
  }

  const { data: providerData, isInitialLoading: providerDataLoading } = useProviders();

  const defaultChargeTag =
    providerData && providerData.find((p: Provider) => p.id === provider.id)?.defaultChargeTag;

  const defaultAggregationMethodUrn = 'urn:flux:rating:charge:aggregation:coincidental';
  let aggregationMethodValue: string | undefined = undefined;
  if (
    chargeRequiresAggregationMethodAndBasis(
      charge?.chargeType,
      charge?.chargeBasis,
      chargeTypesConfig
    )
  ) {
    aggregationMethodValue = defaultAggregationMethodUrn;
  }

  const defaultChargeCalculation = 'REQUIRED';

  const [locked, setLocked] = useState({
    aggregationMethod: !charge || charge.aggregationMethod === defaultAggregationMethodUrn,
    chargeTags: !charge || charge.chargeTags?.[0] === defaultChargeTag,
    chargeCalculation: !charge || charge.chargeCalculation === defaultChargeCalculation,
  });

  if (qualifierLoading || summarisationGroupsLoading || providerDataLoading) {
    return <p>Loading</p>;
  }

  const summarisationGroupList: SummarisationGroup[] =
    summarisationGroupResponse.summarisationGroups;

  const addBillGroupName = async (newValue: string) => {
    await mutateAsync({ name: newValue });
  };

  const qualifierOptions: MultiSelectGroup[] = [
    {
      label: 'Global',
      options: qualifierResponse.items
        .filter((qualifier: Qualifier) => isNil(qualifier.providerName))
        .map(
          (qualifier: Qualifier): MultiSelectOption => ({
            id: qualifier.id,
            name: qualifier.name,
          })
        ),
    },
    {
      label: `${provider.name}`,
      options: qualifierResponse.items.filter(
        (qualifier: Qualifier) => qualifier.providerId === provider.id
      ),
    },
  ];

  const flags = {
    cfd872SteppedTariffs,
    fbau1079AddUsageChargeFilterToVolumeBasedCharges,
    fbau635BillingCategoryForInvoicePresentation,
    fbau721ExpandVolumeChargeToIncludeNewAttributesAndChargeBasis,
    fbau802HistoricalPeakDemandUi,
  };

  const getDefaultDemandType = (historicalMeasureOptions: FieldOption[]): string => {
    let returnVal = '';
    historicalMeasureOptions.map((label: FieldOption) => {
      if (label.value === 'DEMAND_ANYTIME_ACTUAL') {
        returnVal = label.value;
      }
    });
    return returnVal;
  };
  const defaultDemandType = getDefaultDemandType(historicalMeasureOptions);

  const defaultUsageChargeFilter = getDefaultUsageChargeFilter(usageChargeFilterList);

  const defaults = {
    defaultChargeTag,
    defaultChargeCalculation,
    aggregationMethodValue,
    defaultDemandType,
    defaultUsageChargeFilter,
  };

  const initialFormValues = initialValues(charge, defaults, flags);

  return (
    <Formik
      initialValues={initialFormValues}
      onSubmit={formAction()}
      validationSchema={simpleChargeFormSchema(plan, { chargeTypesConfig }, flags)}
    >
      {({ errors, handleChange, resetForm, setFieldValue, setValues, touched, values }) => {
        const chargeType = findAndSanitiseChargeTypeByValue(
          values.chargeType,
          chargeTypesConfig,
          flags
        );

        return (
          <Form className="apl-form-layout-v1 is-full-width">
            <h3 className="form__subheading apl-mb-l">Charge details</h3>
            <FormRow>
              {chargeTypesConfig && (
                <FieldWrapper
                  htmlFor="charge-type"
                  label="Charge type"
                  fieldWidth={FieldWidth.QUARTER}
                >
                  <Field
                    autoComplete="off"
                    data-testid="charge-type"
                    name="chargeType"
                    id="charge-type"
                    className="apl-select-v1_0"
                    as="select"
                    onChange={(event: ChangeEvent<HTMLSelectElement>) => {
                      if (cfd872SteppedTariffs) {
                        const selectedChargeType = event.target.value;
                        resetForm();
                        setValues(() => ({
                          ...initialFormValues, // Reset all values to initial values
                          chargeType: selectedChargeType, // Set only the selected field to the new value
                        }));
                      } else {
                        handleChange(event);

                        setFieldValue('chargeBasis', '');
                        resetVolumeAdjustmentFactor(values.rates);
                        resetSpotPercentage(values.rates);
                        resetHistoricalMeasure(values.rates);
                        if (chargeIsOptional(event.target.value, chargeTypesConfig)) {
                          setFieldValue('chargeCalculation', defaultChargeCalculation);
                        }
                        if (
                          !chargeRequiresAggregationMethod(event.target.value, chargeTypesConfig)
                        ) {
                          setFieldValue('aggregationMethod', undefined);
                        }
                        if (fbau1079AddUsageChargeFilterToVolumeBasedCharges) {
                          chargeRequiresUsageChargeFilter(event.target.value, chargeTypesConfig)
                            ? setFieldValue(
                                'usageChargeFilter',
                                getDefaultUsageChargeFilter(usageChargeFilterList)
                              )
                            : setFieldValue('usageChargeFilter', undefined);
                        }
                        if (chargeRequiresIntervalSize(event.target.value, chargeTypesConfig)) {
                          setFieldValue('intervalSize', '30');
                        } else {
                          setFieldValue('intervalSize', undefined);
                        }
                      }
                    }}
                  >
                    <option value="" />
                    {chargeTypesConfig
                      .sort(
                        (chargeCategoryA, chargeCategoryB) =>
                          chargeCategoryA.sortOrder - chargeCategoryB.sortOrder
                      )
                      .map(
                        (chargeCategory) =>
                          (chargeCategory.categoryDisplayText === 'fixed' ||
                            values.chargeTags !== 'FINANCE') && (
                            <optgroup
                              key={chargeCategory.categoryDisplayText}
                              label={chargeCategory.categoryDisplayText}
                            >
                              {chargeCategory.types
                                .sort(
                                  (chargeTypeA, chargeTypeB) =>
                                    chargeTypeA.sortOrder - chargeTypeB.sortOrder
                                )
                                .map((chargeType) => (
                                  <option key={chargeType.type} value={chargeType.type}>
                                    {chargeType.typeDisplayText}
                                  </option>
                                ))}
                            </optgroup>
                          )
                      )}
                  </Field>
                  <ErrorMessage component={ErrorText} name="chargeType" />
                </FieldWrapper>
              )}
              <FieldWrapper
                htmlFor="charge-basis"
                label="Charge basis"
                fieldWidth={FieldWidth.QUARTER}
              >
                <Field
                  autoComplete="off"
                  data-testid="charge-basis"
                  name="chargeBasis"
                  id="charge-basis"
                  className="apl-select-v1_0 "
                  as="select"
                  onChange={(event: ChangeEvent<HTMLSelectElement>) => {
                    handleChange(event);
                    if (
                      chargeRequiresAggregationMethodAndBasis(
                        values.chargeType,
                        event.target.value,
                        chargeTypesConfig
                      )
                    ) {
                      setFieldValue('aggregationMethod', defaultAggregationMethodUrn);
                    } else {
                      setFieldValue('aggregationMethod', undefined);
                    }
                  }}
                >
                  <option value="" />
                  {chargeType?.basis?.map((basis: string) => (
                    <option key={basis} value={basis}>
                      {basis}
                    </option>
                  ))}
                </Field>
                <ErrorMessage component={ErrorText} name="chargeBasis" />
              </FieldWrapper>
              <FieldWrapper
                fieldWidth={FieldWidth.QUARTER}
                htmlFor="charge-tags"
                label="Charge tag"
                locked={locked.chargeTags}
                onUpdateLocked={() => {
                  setFieldValue('chargeTags', defaultChargeTag);
                  if (defaultChargeTag.toLocaleLowerCase() === 'finance') {
                    setFieldValue('billingCategory', 'other');
                    setFieldValue('chargeBasis', '');
                  }
                  setLocked({ ...locked, chargeTags: !locked.chargeTags });
                }}
              >
                <Field
                  autoComplete="off"
                  as="select"
                  className="apl-select-v1_0 "
                  data-testid="charge-tags"
                  disabled={locked.chargeTags && values.chargeTags === defaultChargeTag}
                  id="charge-tags"
                  name="chargeTags"
                  onChange={(event: ChangeEvent<HTMLSelectElement>) => {
                    const { value } = event.target;
                    handleChange(event);
                    if (!cfd872SteppedTariffs) {
                      resetVolumeAdjustmentFactor(values.rates);
                      resetSpotPercentage(values.rates);
                      resetHistoricalMeasure(values.rates);
                    }

                    if (value === 'FINANCE') {
                      setFieldValue('billingCategory', 'other');
                      setFieldValue('chargeBasis', ''); // reset charge basis
                    }
                  }}
                >
                  {chargeTagOptions.map((tag) => (
                    <option key={tag.value} value={tag.value}>
                      {tag.label}
                    </option>
                  ))}
                </Field>
                <ErrorMessage component={ErrorText} name="chargeTag" />
              </FieldWrapper>
              <FieldWrapper
                htmlFor="charge-name"
                label="Charge name"
                fieldWidth={FieldWidth.QUARTER}
              >
                <Field
                  autoComplete="off"
                  data-testid="charge-name"
                  name="chargeName"
                  id="charge-name"
                  className="apl-text-input-v1"
                />
                <ErrorMessage component={ErrorText} name="chargeName" />
              </FieldWrapper>
            </FormRow>
            <FormRow>
              {fbau635BillingCategoryForInvoicePresentation && (
                <SimpleChargeFormBillingCategoryFieldFBAU635
                  ErrorText={ErrorText}
                  displayOptions={
                    values.chargeTags !== 'FINANCE'
                      ? (billingCategoryList?.data as FieldOption[])
                      : (billingCategoryList?.data.filter(
                          (option: Record<string, any>) => option.value === 'other'
                        ) as FieldOption[])
                  }
                />
              )}
              <FieldWrapper
                htmlFor="billing-descriptor"
                label="Billing descriptor"
                fieldWidth={FieldWidth.QUARTER}
              >
                <Field
                  autoComplete="off"
                  data-testid="billing-descriptor"
                  name="billingDescriptor"
                  id="billing-descriptor"
                  className="apl-text-input-v1"
                />
                <ErrorMessage component={ErrorText} name="billingDescriptor" />
              </FieldWrapper>
              <FieldWrapper
                htmlFor="charge-reference"
                label="Provider reference"
                optional={true}
                fieldWidth={FieldWidth.QUARTER}
              >
                <Field
                  autoComplete="off"
                  data-testid="charge-reference"
                  name="chargeReference"
                  id="charge-reference"
                  className="apl-text-input-v1"
                />
                <ErrorMessage component={ErrorText} name="chargeReference" />
              </FieldWrapper>
            </FormRow>
            <FormRow>
              {chargeType?.attributes.includes(ChargeTypeAttribute.Optional) && (
                <FieldWrapper
                  fieldWidth={FieldWidth.QUARTER}
                  htmlFor="charge-calculation"
                  label="Charge calculation"
                  locked={locked.chargeCalculation}
                  onUpdateLocked={() => {
                    setFieldValue('chargeCalculation', defaultChargeCalculation);
                    setLocked({ ...locked, chargeCalculation: !locked.chargeCalculation });
                  }}
                >
                  <Field
                    autoComplete="off"
                    as="select"
                    className="apl-select-v1_0 "
                    data-testid="charge-calculation"
                    disabled={
                      locked.chargeCalculation &&
                      values.chargeCalculation === defaultChargeCalculation
                    }
                    id="charge-calculation"
                    name="chargeCalculation"
                  >
                    {chargeCalcOptions.map((tag) => (
                      <option key={tag.value} value={tag.value}>
                        {tag.label}
                      </option>
                    ))}
                  </Field>
                  <ErrorMessage component={ErrorText} name="chargeTag" />
                </FieldWrapper>
              )}
              {chargeRequiresFlowDirection(values.chargeType, chargeTypesConfig) && (
                <FieldWrapper
                  htmlFor="flow-direction"
                  label="Energy flow direction"
                  fieldWidth={FieldWidth.QUARTER}
                >
                  <Field
                    autoComplete="off"
                    data-testid="flow-direction"
                    name="flowDirection"
                    id="flow-direction"
                    className="apl-select-v1_0"
                    as="select"
                  >
                    <option value="" />
                    <option value="INTO">Into connection</option>
                    <option value="OUT">Out of connection</option>
                  </Field>
                  <ErrorMessage component={ErrorText} name="flowDirection" />
                </FieldWrapper>
              )}
              {chargeRequiresReadingQuality(values.chargeType, chargeTypesConfig) && (
                <FieldWrapper
                  htmlFor="reading-quality"
                  label="Reading Quality"
                  fieldWidth={FieldWidth.QUARTER}
                >
                  <Field
                    autoComplete="off"
                    data-testid="reading-quality"
                    name="readingQuality"
                    id="reading-quality"
                    className="apl-select-v1_0"
                    as="select"
                  >
                    <option value="BOTH">Both</option>
                    <option value="ACTUAL">Actual</option>
                    <option value="CALCULATED">Calculated</option>
                  </Field>
                  <ErrorMessage component={ErrorText} name="readingQuality" />
                </FieldWrapper>
              )}
              {chargeRequiresIsTaxed(values.chargeType, chargeTypesConfig) && (
                <FieldWrapper htmlFor="is-taxed" label="Is Taxed" fieldWidth={FieldWidth.QUARTER}>
                  <Field
                    autoComplete="off"
                    data-testid="is-taxed"
                    name="isTaxed"
                    id="is-taxed"
                    className="apl-select-v1_0"
                    as="select"
                  >
                    <option value="TAXED">Yes</option>
                    <option value="NOT_TAXED">No</option>
                  </Field>
                  <ErrorMessage component={ErrorText} name="isTaxed" />
                </FieldWrapper>
              )}
              {chargeRequiresLossAdjustment(values.chargeType, chargeTypesConfig) && (
                <SimpleChargeFormLossesField ErrorText={ErrorText} />
              )}
              {chargeRequiresTopNPeriods(values.chargeType, chargeTypesConfig) && (
                <FieldWrapper
                  htmlFor="top-periods"
                  label="Top N periods"
                  fieldWidth={FieldWidth.QUARTER}
                >
                  <Field
                    autoComplete="off"
                    data-testid="top-periods"
                    name="topPeriods"
                    id="top-periods"
                    className="apl-text-input-v1"
                    onChange={onlyNumberChange(handleChange)}
                  />
                  <ErrorMessage component={ErrorText} name="topPeriods" />
                </FieldWrapper>
              )}
              {chargeRequiresSummarisationFunction(values.chargeType, chargeTypesConfig) && (
                <FieldWrapper
                  htmlFor="sum-function"
                  label="Summarisation function"
                  fieldWidth={FieldWidth.QUARTER}
                >
                  <Field
                    autoComplete="off"
                    data-testid="sum-function"
                    name="sumFunction"
                    id="sum-function"
                    className="apl-select-v1_0"
                    as="select"
                  >
                    {sumFunctionOptions.map((option) => {
                      return (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      );
                    })}
                  </Field>
                  <ErrorMessage component={ErrorText} name="sumFunction" />
                </FieldWrapper>
              )}
            </FormRow>
            <FormRow>
              {fbau1079AddUsageChargeFilterToVolumeBasedCharges &&
                chargeRequiresUsageChargeFilter(values.chargeType, chargeTypesConfig) && (
                  <FieldWrapper
                    htmlFor="usage-charge-filter"
                    label="Usage Charge"
                    fieldWidth={FieldWidth.QUARTER}
                  >
                    <Field
                      autoComplete="off"
                      data-testid="usage-charge-filter"
                      name="usageChargeFilter"
                      id="usage-charge-filter"
                      className="apl-select-v1_0 "
                      as="select"
                      onChange={(event: ChangeEvent<HTMLSelectElement>) => {
                        handleChange(event);
                      }}
                    >
                      <option value="" />
                      {usageChargeFilterList?.map((usageCharge) => (
                        <option key={usageCharge.filterName} value={usageCharge.filterName}>
                          {usageCharge.filterName}
                        </option>
                      ))}
                    </Field>
                    <ErrorMessage component={ErrorText} name="usageChargeFilter" />
                  </FieldWrapper>
                )}
              {chargeRequiresLookbackMonths(values.chargeType, chargeTypesConfig) && (
                <FieldWrapper
                  htmlFor="lookback-months"
                  label="Lookback Months"
                  fieldWidth={FieldWidth.QUARTER}
                >
                  <Field
                    autoComplete="off"
                    data-testid="lookback-months"
                    name="lookbackMonths"
                    id="lookback-months"
                    className="apl-text-input-v1"
                    onChange={onlyNumberChange(handleChange)}
                  />
                  <ErrorMessage component={ErrorText} name="lookbackMonths" />
                </FieldWrapper>
              )}
              {chargeRequiresMinimumChargeable(values.chargeType, chargeTypesConfig) && (
                <FieldWrapper
                  htmlFor="min-chargeable-demand"
                  label="Minimum Chargeable Demand"
                  fieldWidth={FieldWidth.QUARTER}
                  optional={true}
                >
                  <Field
                    autoComplete="off"
                    data-testid="min-chargeable-demand"
                    name="minimumChargeable"
                    id="min-chargeable-demand"
                    className="apl-text-input-v1"
                    onChange={onlyNumberChange(handleChange)}
                  />
                  <ErrorMessage component={ErrorText} name="minimumChargeable" />
                </FieldWrapper>
              )}
              {chargeRequiresMinimumThresholdMethod(values.chargeType, chargeTypesConfig) && (
                <FieldWrapper
                  htmlFor="minimum-threshold-method"
                  label="Minimum Threshold Method"
                  fieldWidth={FieldWidth.QUARTER}
                  optional={true}
                >
                  <Field
                    autoComplete="off"
                    data-testid="minimum-threshold-method"
                    name="minimumThresholdMethod"
                    id="minimum-threshold-method"
                    className="apl-select-v1_0"
                    as="select"
                    onChange={handleChange}
                  >
                    <option value="" />
                    <option value="ZERO" label="Bill Zero if below minimum" />
                    <option value="MINIMUM" label="Bill Minimum if below minimum" />
                  </Field>
                  <ErrorMessage component={ErrorText} name="minimumThresholdMethod" />
                </FieldWrapper>
              )}
              {fbau802HistoricalPeakDemandUi &&
                chargeRequiresHistoricalMeasure(values.chargeType, chargeTypesConfig) && (
                  <FieldWrapper
                    htmlFor="historical-measure"
                    label="Demand type"
                    fieldWidth={FieldWidth.QUARTER}
                  >
                    <Field
                      autoComplete="off"
                      data-testid="historical-measure"
                      name="historicalMeasure"
                      id="historical-measure"
                      className="apl-select-v1_0 "
                      as="select"
                      onChange={(event: ChangeEvent<HTMLSelectElement>) => {
                        handleChange(event);
                      }}
                    >
                      <option value="" />
                      {historicalMeasureOptions?.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </Field>
                    <ErrorMessage component={ErrorText} name="historicalMeasure" />
                  </FieldWrapper>
                )}
              {chargeRequiresPowerFactorThreshold(values.chargeType, chargeTypesConfig) && (
                <FieldWrapper
                  htmlFor="power-factor"
                  label="Below power factor threshold"
                  fieldWidth={FieldWidth.QUARTER}
                >
                  <Field
                    autoComplete="off"
                    data-testid="power-factor"
                    name="powerFactor"
                    id="power-factor"
                    className="apl-text-input-v1"
                    onChange={onlyNumberChange(handleChange)}
                  />
                  <ErrorMessage component={ErrorText} name="powerFactor" />
                </FieldWrapper>
              )}
            </FormRow>
            <FormRow>
              {chargeRequiresAggregationMethodAndBasis(
                values.chargeType,
                values.chargeBasis,
                chargeTypesConfig
              ) && (
                <>
                  <FieldWrapper
                    fieldWidth={FieldWidth.QUARTER}
                    htmlFor="aggregation-method"
                    label="Aggregation method"
                    locked={locked.aggregationMethod}
                    onUpdateLocked={() => {
                      setFieldValue('aggregationMethod', aggregationMethodValue);
                      setLocked({ ...locked, aggregationMethod: !locked.aggregationMethod });
                    }}
                  >
                    <Field
                      autoComplete="off"
                      as="select"
                      className="apl-select-v1_0 "
                      data-testid="aggregation-method"
                      disabled={
                        locked.aggregationMethod &&
                        values.aggregationMethod === aggregationMethodValue
                      }
                      id="aggregation-method"
                      name="aggregationMethod"
                    >
                      {aggregationMethodOptions.map((tag) => (
                        <option key={tag.value} value={tag.value}>
                          {tag.label}
                        </option>
                      ))}
                    </Field>
                    <ErrorMessage component={ErrorText} name="aggregationMethod" />
                  </FieldWrapper>
                </>
              )}
              {chargeRequiresIntervalSize(values.chargeType, chargeTypesConfig) && (
                <FieldWrapper
                  htmlFor="interval-size"
                  label="Interval Size"
                  fieldWidth={FieldWidth.QUARTER}
                >
                  <Field
                    autoComplete="off"
                    data-testid="interval-size"
                    name="intervalSize"
                    id="interval-size"
                    className="apl-select-v1_0"
                    as="select"
                    onChange={onlyNumberChange(handleChange)}
                  >
                    <option value="" />
                    <option value="15">15 min</option>
                    <option value="30">30 min</option>
                  </Field>
                  <ErrorMessage component={ErrorText} name="intervalSize" />
                </FieldWrapper>
              )}
            </FormRow>
            {cfd872SteppedTariffs ? (
              chargeRequiresSteppedTariff(values.chargeType, chargeTypesConfig) ? (
                <SteppedTariffForm
                  addBillGroupName={addBillGroupName}
                  currencyUnit={currencyUnit}
                  errors={errors}
                  flags={flags}
                  handleChange={handleChange}
                  setFieldValue={setFieldValue}
                  summarisationGroupList={summarisationGroupList}
                  tonneUnit={tonneUnit}
                  touched={touched}
                  values={values}
                />
              ) : (
                <FieldArray
                  name="schedules"
                  validateOnChange={false}
                  render={(arrayHelpers) => (
                    <div>
                      <div className="apl-display-flex apl-flex-row apl-mb-l">
                        <h3 className="form__subheading apl-mb-none">Rate details</h3>
                        <ButtonAsLink
                          data-testid="add-rate"
                          disabled={false}
                          type="button"
                          className="apl-my-m apl-ml-s"
                          onClick={() =>
                            arrayHelpers.unshift({
                              validFrom: '',
                              validTo: '',
                              rates: [
                                {
                                  applicableTimePeriods: [],
                                  minThreshold: 0,
                                  rate: 0,
                                  rateQualifiers: [],
                                  spotPercentage: null,
                                  volumeAdjustmentFactor: null,
                                },
                              ],
                            })
                          }
                        >
                          Add rate
                        </ButtonAsLink>
                      </div>
                      {values?.schedules?.map((schedule: any, scheduleIndex: number) =>
                        schedule?.rates?.map((rate: any, rateIndex: number) => (
                          <FormRow key={`rate-${rateIndex}`} className="apl-mb apl-flex-nowrap">
                            <FieldWrapper
                              fieldWidth={FieldWidth.NONE}
                              hideLabel={scheduleIndex !== 0}
                              htmlFor={`rate-${scheduleIndex}`}
                              label="Rate"
                              style={{
                                boxSizing: 'content-box',
                                width: '136px',
                              }}
                            >
                              <TextOverlay
                                text={getTextOverlay(
                                  values.chargeBasis,
                                  tonneUnit,
                                  currencyUnit,
                                  flags
                                )}
                              >
                                <Field
                                  autoComplete="off"
                                  data-testid={`rate-${scheduleIndex}`}
                                  name={`schedules.${scheduleIndex}.rates.${rateIndex}.rate`}
                                  id={`rate-${scheduleIndex}`}
                                  className="apl-text-input-v1"
                                  onChange={onlyNumberChange(handleChange)}
                                />
                              </TextOverlay>
                              <ErrorMessage
                                component={ErrorText}
                                name={`schedules.${scheduleIndex}.rates.${rateIndex}.rate`}
                              />
                            </FieldWrapper>
                            {nz1683AddCostToUi && (
                              <FieldWrapper
                                fieldWidth={FieldWidth.NONE}
                                hideLabel={scheduleIndex !== 0}
                                htmlFor={`cost-${scheduleIndex}`}
                                label="Input Cost"
                                optional={true}
                                style={{
                                  boxSizing: 'content-box',
                                  width: '136px',
                                }}
                              >
                                <TextOverlay
                                  text={getTextOverlay(
                                    values.chargeBasis,
                                    tonneUnit,
                                    currencyUnit,
                                    flags
                                  )}
                                >
                                  <Field
                                    autoComplete="off"
                                    data-testid={`cost-${scheduleIndex}`}
                                    name={`schedules.${scheduleIndex}.rates.${rateIndex}.cost`}
                                    id={`cost-${scheduleIndex}`}
                                    className="apl-text-input-v1"
                                    onChange={onlyNumberChange(handleChange)}
                                  />
                                </TextOverlay>
                                <ErrorMessage
                                  component={ErrorText}
                                  name={`schedules.${scheduleIndex}.rates.${rateIndex}.cost`}
                                />
                              </FieldWrapper>
                            )}
                            <FieldWrapper
                              htmlFor={`valid-from-${scheduleIndex}`}
                              label="Valid from"
                              fieldWidth={FieldWidth.NONE}
                              hideLabel={scheduleIndex !== 0}
                              style={{
                                boxSizing: 'content-box',
                                width: '150px',
                              }}
                            >
                              <Field
                                autoComplete="off"
                                data-testid={`valid-from-${scheduleIndex}`}
                                name={`schedules.${scheduleIndex}.validFrom`}
                                id={`valid-from-${scheduleIndex}`}
                                component={FluxDatePicker}
                              />
                              <ErrorMessage
                                component={ErrorText}
                                name={`schedules.${scheduleIndex}.validFrom`}
                              />
                            </FieldWrapper>
                            <FieldWrapper
                              htmlFor={`valid-to-${scheduleIndex}`}
                              label="Valid to"
                              fieldWidth={FieldWidth.NONE}
                              hideLabel={scheduleIndex !== 0}
                              optional={true}
                              style={{
                                boxSizing: 'content-box',
                                width: '150px',
                              }}
                            >
                              <Field
                                autoComplete="off"
                                data-testid={`valid-to-${scheduleIndex}`}
                                name={`schedules.${scheduleIndex}.validTo`}
                                id={`valid-to-${scheduleIndex}`}
                                component={FluxDatePicker}
                              />
                              <ErrorMessage
                                component={ErrorText}
                                name={`schedules.${scheduleIndex}.validTo`}
                              />
                            </FieldWrapper>
                            <CreatableLookup
                              fieldWidth={FieldWidth.NONE}
                              hideLabel={scheduleIndex !== 0}
                              label="Bill group"
                              name={`schedules.${scheduleIndex}.rates.${rateIndex}.summarisationGroup`}
                              optional={true}
                              valueFieldName="id"
                              labelFieldName="name"
                              options={summarisationGroupList}
                              selectedOption={rate.summarisationGroup}
                              style={{
                                boxSizing: 'content-box',
                                width: '165px',
                              }}
                              handleAddEvent={addBillGroupName}
                            />
                            <MultiSelect
                              fieldWidth={FieldWidth.NONE}
                              name={`schedules.${scheduleIndex}.rates.${rateIndex}.rateQualifiers`}
                              optional={true}
                              label="Rate qualifiers"
                              optionGroups={qualifierOptions}
                              hideLabel={scheduleIndex !== 0}
                              style={{
                                boxSizing: 'content-box',
                                width: '220px',
                              }}
                            />
                            {chargeRequiresApplicableTimePeriod(
                              values.chargeType,
                              chargeTypesConfig
                            ) && (
                              <MultiSelect
                                fieldWidth={FieldWidth.NONE}
                                name={`schedules.${scheduleIndex}.rates.${rateIndex}.applicableTimePeriods`}
                                optional={true}
                                label="Applicable time periods"
                                optionGroups={qualifierOptions}
                                hideLabel={scheduleIndex !== 0}
                                style={{
                                  boxSizing: 'content-box',
                                  width: '220px',
                                }}
                              />
                            )}
                            {fbau721ExpandVolumeChargeToIncludeNewAttributesAndChargeBasis &&
                              chargeRequiresVolumeAdjustmentFactor(
                                values.chargeType,
                                chargeTypesConfig
                              ) && (
                                <FieldWrapper
                                  label="Adjustment factor"
                                  htmlFor={`volumeAdjustmentFactor-${scheduleIndex}`}
                                  hideLabel={scheduleIndex !== 0}
                                  fieldWidth={FieldWidth.NONE}
                                  optional={true}
                                  style={{
                                    boxSizing: 'content-box',
                                    width: '186px',
                                  }}
                                >
                                  <Field
                                    autoComplete="off"
                                    data-testid={`volumeAdjustmentFactor-${scheduleIndex}`}
                                    name={`schedules.${scheduleIndex}.rates.${rateIndex}.volumeAdjustmentFactor`}
                                    id={`volumeAdjustmentFactor-${scheduleIndex}`}
                                    className="apl-text-input-v1"
                                    onChange={onlyNumberChange(handleChange)}
                                  />
                                  <ErrorMessage
                                    component={ErrorText}
                                    name={`schedules.${scheduleIndex}.rates.${rateIndex}.volumeAdjustmentFactor`}
                                  />
                                </FieldWrapper>
                              )}
                            {fbau689SpotPercentageOnVolumeEnergyCharge &&
                              chargeRequiresSpotPercentage(
                                values.chargeType,
                                values.chargeTags,
                                chargeTypesConfig
                              ) && (
                                <FieldWrapper
                                  fieldWidth={FieldWidth.NONE}
                                  hideLabel={scheduleIndex !== 0}
                                  htmlFor={`rate-${scheduleIndex}`}
                                  label="Spot Percentage"
                                  optional={true}
                                  style={{
                                    boxSizing: 'content-box',
                                    width: '170px',
                                  }}
                                >
                                  <TextOverlay text={'%'}>
                                    <Field
                                      autoComplete="off"
                                      data-testid={`rate-${scheduleIndex}`}
                                      name={`schedules.${scheduleIndex}.rates.${rateIndex}.spotPercentage`}
                                      id={`rate-${scheduleIndex}`}
                                      className="apl-text-input-v1"
                                      onChange={onlyNumberChange(handleChange)}
                                    />
                                  </TextOverlay>
                                  <ErrorMessage
                                    component={ErrorText}
                                    name={`schedules.${scheduleIndex}.rates.${rateIndex}.spotPercentage`}
                                  />
                                </FieldWrapper>
                              )}
                            <div
                              style={{
                                marginRight: '-16px',
                                textAlign: 'right',
                                width: '30px',
                              }}
                            >
                              {scheduleIndex !== 0 && (
                                <button
                                  disabled={false}
                                  onClick={() => arrayHelpers.remove(scheduleIndex)}
                                  className="form__delete-rate"
                                  type="button"
                                >
                                  <span className="material-icons" style={{ fontSize: '18px' }}>
                                    close
                                  </span>
                                </button>
                              )}
                            </div>
                          </FormRow>
                        ))
                      )}
                    </div>
                  )}
                />
              )
            ) : (
              <FieldArray
                name="rates"
                render={(arrayHelpers) => (
                  <div>
                    <div className="apl-display-flex apl-flex-row apl-mb-l">
                      <h3 className="form__subheading apl-mb-none">Rate details</h3>
                      <ButtonAsLink
                        data-testid="add-rate"
                        disabled={false}
                        type="button"
                        className="apl-my-m apl-ml-s"
                        onClick={() =>
                          arrayHelpers.unshift({
                            key: v4(),
                            rate: 0,
                            validFrom: '',
                            validTo: '',
                            rateQualifiers: [],
                            applicableTimePeriods: [],
                            volumeAdjustmentFactor: null,
                            spotPercentage: null,
                          })
                        }
                      >
                        Add rate
                      </ButtonAsLink>
                    </div>
                    {values?.rates?.map((r: any, index: number) => (
                      <FormRow key={r.key} className="apl-mb apl-flex-nowrap">
                        <FieldWrapper
                          fieldWidth={FieldWidth.NONE}
                          hideLabel={index !== 0}
                          htmlFor={`rate-${index}`}
                          label="Rate"
                          style={{
                            boxSizing: 'content-box',
                            width: '136px',
                          }}
                        >
                          <TextOverlay
                            text={getTextOverlay(
                              values.chargeBasis,
                              tonneUnit,
                              currencyUnit,
                              flags
                            )}
                          >
                            <Field
                              autoComplete="off"
                              data-testid={`rate-${index}`}
                              name={`rates.${index}.rate`}
                              id={`rate-${index}`}
                              className="apl-text-input-v1"
                              onChange={onlyNumberChange(handleChange)}
                            />
                          </TextOverlay>
                          <ErrorMessage component={ErrorText} name={`rates.${index}.rate`} />
                        </FieldWrapper>
                        {nz1683AddCostToUi && (
                          <FieldWrapper
                            fieldWidth={FieldWidth.NONE}
                            hideLabel={index !== 0}
                            htmlFor={`cost-${index}`}
                            label="Input Cost"
                            optional={true}
                            style={{
                              boxSizing: 'content-box',
                              width: '136px',
                            }}
                          >
                            <TextOverlay
                              text={getTextOverlay(
                                values.chargeBasis,
                                tonneUnit,
                                currencyUnit,
                                flags
                              )}
                            >
                              <Field
                                autoComplete="off"
                                data-testid={`cost-${index}`}
                                name={`rates.${index}.cost`}
                                id={`cost-${index}`}
                                className="apl-text-input-v1"
                                onChange={onlyNumberChange(handleChange)}
                              />
                            </TextOverlay>
                            <ErrorMessage component={ErrorText} name={`rates.${index}.cost`} />
                          </FieldWrapper>
                        )}
                        <FieldWrapper
                          htmlFor={`valid-from-${index}`}
                          label="Valid from"
                          fieldWidth={FieldWidth.NONE}
                          hideLabel={index !== 0}
                          style={{
                            boxSizing: 'content-box',
                            width: '150px',
                          }}
                        >
                          <Field
                            autoComplete="off"
                            data-testid={`valid-from-${index}`}
                            name={`rates.${index}.validFrom`}
                            id={`valid-from-${index}`}
                            component={FluxDatePicker}
                          />
                          <ErrorMessage component={ErrorText} name={`rates.${index}.validFrom`} />
                        </FieldWrapper>
                        <FieldWrapper
                          htmlFor={`valid-to-${index}`}
                          label="Valid to"
                          fieldWidth={FieldWidth.NONE}
                          hideLabel={index !== 0}
                          optional={true}
                          style={{
                            boxSizing: 'content-box',
                            width: '150px',
                          }}
                        >
                          <Field
                            autoComplete="off"
                            data-testid={`valid-to-${index}`}
                            name={`rates.${index}.validTo`}
                            id={`valid-to-${index}`}
                            component={FluxDatePicker}
                          />
                          <ErrorMessage component={ErrorText} name={`rates.${index}.validTo`} />
                        </FieldWrapper>
                        <CreatableLookup
                          fieldWidth={FieldWidth.NONE}
                          hideLabel={index !== 0}
                          label="Bill group"
                          name={`rates.${index}.summarisationGroup`}
                          optional={true}
                          valueFieldName="id"
                          labelFieldName="name"
                          options={summarisationGroupList}
                          selectedOption={r.summarisationGroup}
                          style={{
                            boxSizing: 'content-box',
                            width: '165px',
                          }}
                          handleAddEvent={addBillGroupName}
                        />
                        <MultiSelect
                          fieldWidth={FieldWidth.NONE}
                          name={`rates.${index}.rateQualifiers`}
                          optional={true}
                          label="Rate qualifiers"
                          optionGroups={qualifierOptions}
                          hideLabel={index !== 0}
                          style={{
                            boxSizing: 'content-box',
                            width: '220px',
                          }}
                        />
                        {chargeRequiresApplicableTimePeriod(
                          values.chargeType,
                          chargeTypesConfig
                        ) && (
                          <MultiSelect
                            fieldWidth={FieldWidth.NONE}
                            name={`rates.${index}.applicableTimePeriods`}
                            optional={true}
                            label="Applicable time periods"
                            optionGroups={qualifierOptions}
                            hideLabel={index !== 0}
                            style={{
                              boxSizing: 'content-box',
                              width: '220px',
                            }}
                          />
                        )}
                        {fbau721ExpandVolumeChargeToIncludeNewAttributesAndChargeBasis &&
                          chargeRequiresVolumeAdjustmentFactor(
                            values.chargeType,
                            chargeTypesConfig
                          ) && (
                            <FieldWrapper
                              label="Adjustment factor"
                              htmlFor={`volumeAdjustmentFactor-${index}`}
                              hideLabel={index !== 0}
                              fieldWidth={FieldWidth.NONE}
                              optional={true}
                              style={{
                                boxSizing: 'content-box',
                                width: '186px',
                              }}
                            >
                              <Field
                                autoComplete="off"
                                data-testid={`volumeAdjustmentFactor-${index}`}
                                name={`rates.${index}.volumeAdjustmentFactor`}
                                id={`volumeAdjustmentFactor-${index}`}
                                className="apl-text-input-v1"
                                onChange={onlyNumberChange(handleChange)}
                              />
                              <ErrorMessage
                                component={ErrorText}
                                name={`rates.${index}.volumeAdjustmentFactor`}
                              />
                            </FieldWrapper>
                          )}
                        {fbau689SpotPercentageOnVolumeEnergyCharge &&
                          chargeRequiresSpotPercentage(
                            values.chargeType,
                            values.chargeTags,
                            chargeTypesConfig
                          ) && (
                            <FieldWrapper
                              fieldWidth={FieldWidth.NONE}
                              hideLabel={index !== 0}
                              htmlFor={`rate-${index}`}
                              label="Spot Percentage"
                              optional={true}
                              style={{
                                boxSizing: 'content-box',
                                width: '170px',
                              }}
                            >
                              <TextOverlay text={'%'}>
                                <Field
                                  autoComplete="off"
                                  data-testid={`rate-${index}`}
                                  name={`rates.${index}.spotPercentage`}
                                  id={`rate-${index}`}
                                  className="apl-text-input-v1"
                                  onChange={onlyNumberChange(handleChange)}
                                />
                              </TextOverlay>
                              <ErrorMessage
                                component={ErrorText}
                                name={`rates.${index}.spotPercentage`}
                              />
                            </FieldWrapper>
                          )}
                        <div
                          style={{
                            marginRight: '-16px',
                            textAlign: 'right',
                            width: '30px',
                          }}
                        >
                          {index !== 0 && (
                            <button
                              disabled={false}
                              onClick={() => arrayHelpers.remove(index)}
                              className="form__delete-rate"
                              type="button"
                            >
                              <span className="material-icons" style={{ fontSize: '18px' }}>
                                close
                              </span>
                            </button>
                          )}
                        </div>
                      </FormRow>
                    ))}
                  </div>
                )}
              />
            )}
            <div className="apl-display-flex apl-justify-content-end">
              <button
                data-testid="cancel-button"
                className="apl-button-v1"
                onClick={close}
                type="button"
              >
                Cancel
              </button>
              <AuthorizedAction
                extraClasses="is-primary"
                isDisabled={isLoading}
                permission={Permission.PLAN_EDIT}
                removeDefaultWeight={true}
                testId="add-simple-charge-button"
                type="submit"
              >
                Save
              </AuthorizedAction>
            </div>
            {isChargeError && (
              <ErrorText>
                <span>Could not edit charge.</span>
                <span> {qualifierErrorMessage}</span>
              </ErrorText>
            )}
          </Form>
        );
      }}
    </Formik>
  );
};

export default SimpleChargeForm;
