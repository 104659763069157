/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AccountConfiguration } from '../models/AccountConfiguration';
import type { BaseAccountConfiguration } from '../models/BaseAccountConfiguration';
import type { ConfigList } from '../models/ConfigList';
import type { Error } from '../models/Error';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class AccountConfigurationService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @param limit
     * @param offset
     * @returns ConfigList OK
     * @returns Error Error
     * @throws ApiError
     */
    public getAccounts(
        limit: number = 20,
        offset?: number,
    ): CancelablePromise<ConfigList | Error> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/billing/configuration/accounts',
            query: {
                'limit': limit,
                'offset': offset,
            },
        });
    }

    /**
     * @param id
     * @returns AccountConfiguration OK
     * @returns Error Error
     * @throws ApiError
     */
    public getAccountsById(
        id: string,
    ): CancelablePromise<AccountConfiguration | Error> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/billing/configuration/accounts/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id
     * @returns BaseAccountConfiguration OK
     * @returns Error Error
     * @throws ApiError
     */
    public putAccountsById(
        id: string,
    ): CancelablePromise<BaseAccountConfiguration | Error> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/billing/configuration/accounts/{id}',
            path: {
                'id': id,
            },
        });
    }

}
