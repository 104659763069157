import cx from 'classnames';
import React from 'react';
import { findMeasurement, FlowDirection } from '../dashboard/MeteringDataComponent';
import Card from '../../layout/Card';
import { Measurement } from '../../telemetry/telemetryUsageApi';
import { useFlags } from 'launchdarkly-react-client-sdk';

interface MeteringConsumptionSummaryProps {
  flow: FlowDirection;
  measurements?: Measurement[];
  title: string;
}

const MeteringConsumptionSummary = ({
  flow,
  measurements,
  title,
}: MeteringConsumptionSummaryProps) => {
  const { cfd212UsageV2Apis } = useFlags();

  const usageWording = cfd212UsageV2Apis ? 'Raw' : 'Metered';
  const lossWording = cfd212UsageV2Apis ? 'Distribution loss adjusted' : 'Loss adjusted';

  return (
    <Card className="apl-pb-none">
      <div
        className={cx(
          'card__inner',
          'apl-display-flex',
          'apl-flex-row',
          'apl-align-items-center',
          'apl-justify-content-between',
          'apl-px',
          'apl-pb'
        )}
        style={{
          borderBottom: '1px solid #D3D8DA',
        }}
      >
        <h3>{`${title} (flow ${flow.toLowerCase()})`}</h3>
      </div>
      <div className={cx('metering-table-summary', 'apl-display-flex', 'apl-flex-row', 'apl-pb')}>
        <div className={cx('apl-display-flex', 'apl-flex-row')}>
          <div className={cx('apl-display-flex', 'apl-flex-column', 'apl-mr-l')}>
            <p className="apl-mb-none">{usageWording} kWh</p>
            <p className="apl-mb-none">{usageWording} kVAh</p>
            <p className="apl-mb-none">{usageWording} kVArh</p>
          </div>
          <div className={cx('apl-display-flex', 'apl-flex-column', 'apl-mr-l')}>
            {measurements && (
              <>
                <p className="apl-mb-none apl-pl-l" style={{ fontWeight: 600 }}>
                  {findMeasurement('kWh', measurements)} kWh
                </p>
                <p className="apl-mb-none apl-pl-l" style={{ fontWeight: 600 }}>
                  {findMeasurement('kVAh', measurements)} kVAh
                </p>
                <p className="apl-mb-none apl-pl-l" style={{ fontWeight: 600 }}>
                  {findMeasurement('kVArh', measurements)} kVArh
                </p>
              </>
            )}
          </div>
          <div className={cx('apl-display-flex', 'apl-flex-column', 'apl-mr-l')}>
            <p className="apl-mb-none">{lossWording} kWh</p>
            <p className="apl-mb-none">{lossWording} kVAh</p>
            <p className="apl-mb-none">{lossWording} kVArh</p>
          </div>
          <div className={cx('apl-display-flex', 'apl-flex-column')}>
            {measurements && (
              <>
                <p className="apl-mb-none apl-pl-l" style={{ fontWeight: 600 }}>
                  {findMeasurement('loss_adjusted_kWh', measurements)} kWh
                </p>
                <p className="apl-mb-none apl-pl-l" style={{ fontWeight: 600 }}>
                  {findMeasurement('loss_adjusted_kVAh', measurements)} kVAh
                </p>
                <p className="apl-mb-none apl-pl-l" style={{ fontWeight: 600 }}>
                  {findMeasurement('loss_adjusted_kVArh', measurements)} kVArh
                </p>
              </>
            )}
          </div>
        </div>
      </div>
    </Card>
  );
};

export default MeteringConsumptionSummary;
