import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import translationEN_NZ from './locales/en-NZ/translation.json';
import translationEN_AU from './locales/en-AU/translation.json';
import translationEN_GB from './locales/en-GB/translation.json';
import translationEN_EU from './locales/en-EU/translation.json';

const resources = {
  'en-NZ': {
    translation: translationEN_NZ,
  },
  'en-AU': {
    translation: translationEN_AU,
  },
  'en-GB': {
    translation: translationEN_GB,
  },
  'en-EU': {
    translation: translationEN_EU,
  },
};

const LANGUAGE_FALLBACK = 'en-NZ';

i18n.use(initReactI18next).init({
  resources,
  lng: process.env.REACT_APP_LANGUAGE || LANGUAGE_FALLBACK,
  debug: process.env.NODE_ENV === 'development',
  interpolation: {
    escapeValue: false, // not needed for react as it escapes by default
  },
});

export default i18n;
