import React, { FunctionComponent, useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { NONCE, processAccessCode, redirectToLogin } from './authClient';

export const RedirectToLogin: FunctionComponent = () => {
  const [errorInRedirect, setErrorInRedirect] = useState(false);

  redirectToLogin().catch(() => {
    setErrorInRedirect(true);
  });

  if (errorInRedirect) {
    return <p>Error</p>;
  }

  return null;
};

const LoginCallback: FunctionComponent = () => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const accessCode = queryParams.get('code');
  // get the URL where a user entered the app, ex. /connections
  // if it exists, redirect to there instead of the landing page
  const nonce: string = window.sessionStorage.getItem(NONCE) ?? '';
  const stateQueryParam: string = decodeURIComponent(queryParams.get('state') ?? '');

  let state: any;
  let redirectUrl: string;

  if (stateQueryParam != '') {
    state = JSON.parse(stateQueryParam);
    redirectUrl = state[nonce]?.redirectUrl ?? '/dashboard';
  } else {
    redirectUrl = '/dashboard';
  }

  useEffect(() => {
    //  AbortController is used to cancel any in-flight authentication requests
    //  while the user is redirected back to the homepage.
    //  This is required since the authentication method could be invoked multiple times
    //  due to re-rendering LoginCallback when it should only be invoked once.
    //  If invoked more than once an error is thrown by the IDP server.
    //  For more info see https://developer.mozilla.org/en-US/docs/Web/API/AbortController
    const abortController = new AbortController();

    processAccessCode(accessCode, abortController.signal)
      .then(() => {
        navigate(redirectUrl, { replace: true });
      })
      .catch((e) => {
        if (!abortController.signal.aborted) {
          console.error('Error in callback', e);
        }
      });

    return () => {
      abortController.abort();
    };
  }, []);

  return null;
};

export default LoginCallback;
