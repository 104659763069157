/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Charges } from '../models/Charges';
import type { Error } from '../models/Error';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ChargesService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @param id
     * @returns Charges OK
     * @returns Error Error
     * @throws ApiError
     */
    public getChargesById(
        id: string,
    ): CancelablePromise<Charges | Error> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/billing/charges/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id
     * @returns Error Error
     * @throws ApiError
     */
    public deleteCharges(
        id: string,
    ): CancelablePromise<Error> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/billing/charges/{id}',
            path: {
                'id': id,
            },
        });
    }

}
