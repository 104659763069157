import { useField } from 'formik';
import { includes, isNil, without } from 'ramda';
import React, { ChangeEvent, FunctionComponent, useEffect, useState } from 'react';
import FieldWrapper, { FieldWidth } from './FieldWrapper';

import '../../flux-styles/badge.scss';

export interface MultiSelectOption {
  id: string;
  name: string;
}

export interface MultiSelectGroup {
  label: string;
  options: MultiSelectOption[];
}

interface MultiSelectProps {
  className?: string;
  fieldWidth: FieldWidth;
  hideLabel: boolean;
  label: string;
  name: string;
  optionGroups: MultiSelectGroup[];
  optional: boolean;
  style?: object;
}

const MultiSelect: FunctionComponent<MultiSelectProps> = ({
  className,
  fieldWidth,
  hideLabel,
  label,
  name,
  optional,
  optionGroups,
  style,
}) => {
  const options = optionGroups.reduce((accumulator, current) => {
    return [...accumulator, ...current.options];
  }, [] as MultiSelectOption[]);

  const [, meta, helpers] = useField({ name });
  const [selectedValues, setSelectedValues] = useState(meta.initialValue || []);

  // this is important if this component is in a list where its name may change,
  // it will keep the state of the component in sync with the form
  useEffect(() => {
    setSelectedValues(meta.value);
  }, [name, meta.value]);

  const addSelectedValue = (event: ChangeEvent<HTMLSelectElement>) => {
    const selectedOption = options.find((option) => option.id === event.target.value);

    // the option that was picked is not in the list
    if (isNil(selectedOption)) {
      return;
    }

    // if an option with that id is already in the list don't add it
    if (
      includes(
        selectedOption.id,
        selectedValues.map((m: MultiSelectOption) => m.id)
      )
    ) {
      return;
    }

    const newValues = [...selectedValues, selectedOption];

    setSelectedValues(newValues);
    helpers.setValue(newValues);
  };

  const removeOption = (itemToRemove: MultiSelectOption) => {
    const updatedList = without([itemToRemove], selectedValues);

    setSelectedValues(updatedList);
    helpers.setValue(updatedList);
  };

  return (
    <FieldWrapper
      className={className}
      fieldWidth={fieldWidth}
      hideLabel={hideLabel}
      htmlFor={`${name}-multiselect`}
      label={label}
      optional={optional}
      style={style}
    >
      <div
        className="apl-display-flex apl-flex-wrap"
        style={{
          minHeight: '31px',
          padding: '0 15px 3px 3px',
          position: 'relative',
        }}
      >
        <select
          className="apl-width-full apl-select-v1_0"
          id={`${name}-multiselect`}
          onChange={addSelectedValue}
          style={{
            backgroundColor: 'transparent',
            bottom: 0,
            left: 0,
            position: 'absolute',
            right: 0,
            top: 0,
          }}
          value=""
        >
          <option value="" disabled={true} />
          {optionGroups.map((optionGroup, index) => (
            <optgroup label={optionGroup.label} key={index}>
              {optionGroup.options.map((option, index) => (
                <option key={index} value={option.id}>
                  {option.name}
                </option>
              ))}
            </optgroup>
          ))}
        </select>
        {selectedValues.map((option: MultiSelectOption, index: number) => (
          <span
            className="badge apl-display-flex apl-flex-row apl-align-items-center apl-mb-none"
            key={index}
            onClick={() => removeOption(option)}
            style={{
              cursor: 'pointer',
              marginRight: '3px',
              marginTop: '3px',
              zIndex: 1,
            }}
          >
            {option.name}
            <span className="apl-ml-xs material-icons" style={{ fontSize: '10px' }}>
              close
            </span>
          </span>
        ))}
      </div>
    </FieldWrapper>
  );
};
export default MultiSelect;
