/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Error } from '../models/Error';
import type { Invoice } from '../models/Invoice';
import type { TestInvoice } from '../models/TestInvoice';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class TestInvoiceService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @param requestBody
     * @returns Invoice OK
     * @returns Error Error
     * @throws ApiError
     */
    public testCreateInvoice(
        requestBody: TestInvoice,
    ): CancelablePromise<Invoice | Error> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/test/invoices',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
